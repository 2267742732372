import React from "react";
import { Link } from "react-router-dom";
import { Page } from "home/page";
import { addRubyText, addImage } from "common/util/generalUtil";

const Practice = () => {
  return <>
    <h2>{addRubyText("練習","れんしゅう")} (Practice)</h2>
    <br />
    <table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td colSpan="1" rowSpan="1" style={{verticalAlign: "top"}}>
          <ul>
            <li>
              <Link to={Page.BasicGrammar.Verbs.Practice.P1}>{addRubyText("練習","れんしゅう")}1 : Non-past Negative Formation</Link>
            </li>
            <li>
              <Link to={Page.BasicGrammar.Verbs.Practice.Practice}>{addRubyText("練習","れんしゅう")}2 : た form Formation</Link>
            </li>
            <li>
              <Link to={Page.BasicGrammar.Verbs.Practice.P3}>{addRubyText("練習","れんしゅう")}3 : Past Negative Formation</Link>
            </li>
          </ul>
          </td>
          <td colSpan="1" rowSpan="1" style={{verticalAlign: "top", textAlign: "center"}}>
            {addImage("tooroo.jpg")}<br />
            <small>石灯籠 "Ishi-dooroo"Stone Lantern (Kyoto)</small><br />
          </td>
        </tr>
        <tr>
        </tr>
      </tbody>
    </table>
    <br />
    <br />
  </>;
}

export default Practice;
