import React from "react";
import { addRubyText, addImage } from "common/util/generalUtil";
import { Link } from "react-router-dom";
import { Page } from "home/page";

const Practice = () => {
  return <>
    <h2>{addRubyText("練習","れんしゅう")} (Practice)</h2>
    <table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>
          <ul>
            <li>
              <Link to={Page.BasicGrammar.Adjectives.Practice.P1}>
                {addRubyText("練習","れんしゅう")}1 : Adjectives (Non-past negative)
              </Link>
            </li>
            <li>
              <Link to={Page.BasicGrammar.Adjectives.Practice.P2}>
                {addRubyText("練習","れんしゅう")}2 : Adjectives (Past negative)
              </Link>
            </li>
            <li>
              <Link to={Page.BasicGrammar.Adjectives.Practice.P3}>
                {addRubyText("練習","れんしゅう")}3 : Adjective て form
              </Link>
            </li>
            <li>
              <Link to={Page.BasicGrammar.Adjectives.Practice.P4}>
                {addRubyText("練習","れんしゅう")}4 : Adverbial form
              </Link>
            </li>
          </ul>
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addImage("hyakkin.jpg")}
            <br/>
            <small>
              百円ショップ 100-yen Shop (Osaka)
            </small>
            <br/>
          </td>
        </tr>
      </tbody>
    </table>
  </>;
}

export default Practice;