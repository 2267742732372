import React from "react";
import { addVideoPlayer } from "common/util/generalUtil";

const EricInEnglish = () => {
  return (
    <>
      <h2>エリックさんへのインタビュー Interview to Eric-san (in English)</h2>
      <table style={{textAlign: "center", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>
            Q: How did you learn Kansai-ben?
          </td>
          <td style={{verticalAlign: "top"}}>
            Q: Which did you learn first, Standard or Kansai-ben?
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addVideoPlayer("Eric.e1.mp4")}
          </td>
          <td style={{verticalAlign: "top"}}>
            {addVideoPlayer("Eric.e2.mp4")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            Q: Any benefits from speaking Kansai-ben?
          </td>
          <td style={{verticalAlign: "top"}}>
            Q: Any characteristic differences between Kansai-people and Tokyoite?
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addVideoPlayer("Eric.e3.mp4")}</td>
          <td style={{verticalAlign: "top"}}>
            {addVideoPlayer("Eric.e4.mp4")}</td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            Q: Can foreigners get by in Kansai without any knowledge of Kansai-ben?
          </td>
          <td style={{verticalAlign: "top"}}>
            Q: Your favorite Kansai word?
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addVideoPlayer("Eric.e5.mp4")}</td>
          <td style={{verticalAlign: "top"}}>
            {addVideoPlayer("Eric.e6.mp4")}</td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            Q: Should foreigners learn a Japanese dialect?
          </td>
          <td style={{verticalAlign: "top"}}>
            Q: Any differences within Kansai-ben in terms of difficulty?
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addVideoPlayer("Eric.e7.mp4")}
          </td>
          <td style={{verticalAlign: "top"}}>
            {addVideoPlayer("Eric.e8.mp4")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            Q: Will this Kansai-ben study site be beneficial?
          </td>
          <td style={{verticalAlign: "top"}}>
            Q: Any personality difference when speaking standard Japanese / Kansai-ben / English?
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addVideoPlayer("Eric.e9.mp4")}
          </td>
          <td style={{verticalAlign: "top"}}>
            {addVideoPlayer("Eric.e10.mp4")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            Q: Any suggestions to Japanese (Kansai-ben) learners?
          </td>
          <td style={{verticalAlign: "top"}} />
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addVideoPlayer("Eric.e11.mp4")}
          </td>
          <td style={{verticalAlign: "top"}} />
        </tr>
        </tbody>
      </table>
    </>
  );
};

export default EricInEnglish;