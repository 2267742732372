export const messages = {
  common: {
    kansaiben: "Kansai Dialect Self Study Site",
    location: "Location",
    checkNext: "Please check out the next section!",
    chapNum: (num: number) => `Chapter ${num}`,
  },
  basicGrammar: {

  },
  characteristics: {
    characteristicsHeader: "Characteristics",
    chap1: {
      chap1Header: "How Kansai-ben sounds like",
      part1: "Have you ever heard people speaking Kansai dialect (Kansai-ben)? You have heard it but are not sure how different it is from Standard Japanese? We have prepared two sets of sample video clips for you. Please compare each set of conversations; one conducted in Standard Japanese and the other in Kansai-ben.",
      part2: "Now you know Standard Japanese and Kansai dialect sound quite different from each other. You may wonder \"What aspects are different? Is the Kansai dialect just a regional dialect? What are the relationship between Standard Japanese and the Kansai dialect? ...",
    },
    chap2: {
      header: "The Standard Japanese VS the Kansai dialect",
      part1: "The Japanese taught as a foreign language is what we call “Standard Japanese.” Descriptively speaking, the Standard Japanese is the variety used in the Tokyo area. The original ‘Standard Japanese” was in large part the product of political considerations during the last half of the Meiji era (1868 -1912) under the Genbun itchi (the unification of the spoken and written language) movement, a movement dedicated to present a united face to the world so that Japan would be perceived as a civilized nation. Standard Japanese, based on the dialect of Tokyo Yamanote area, has personified prestige and power since the Meiji government adopted it as the national language and began standardizing language education in schools. Although the situation has not been the same since WWII, Standard Japanese is still used as the representative Japanese language on public occasions.",
      part2: "However, this does not mean that the regional dialects have disappeared. Japan is a small island country, yet people from different regions of the country speak differently even nowadays. Differences among dialects can be found in phonetic (sounds), accentual, lexical (words), morphological (word formation), or morpho-syntactic (word conjugation in sentences) properties or any combination of them. The Kansai dialect in Japan is considered to be a “powerful” dialect historically and culturally, spoken by dozens of millions of people who live or used to live in the Kansai area, including major cities such as Osaka and Kyoto. In fact, the modern Kansai dialect has inherited and still holds many characteristics of classical Japanese, which is the origin of the modern Kansai dialect and was the most powerful language when Kyoto and Nara were at their respective times the seat of Japan’s early capitals and the center of Japanese politics, economy and culture. Tokyo dialect was integrated as a result of a change from the ancient Kansai dialect and gradually became powerful as the political power in the Edo (Tokyo) increased.",
      part3: "The Kansai dialect is thought to symbolize the strong local identity of the people within the Kansai domestic culture. They tend to think of their dialect as the “opposite” of the standard Japanese, rather than merely one of the regional dialects in Japan. They are proud to be of the Kansai people, and of the Kansai local culture. If you go to bookstores in Japan, you will be surprised to find so many books deal with the topic of Kansai – Tokyo rivalry. The Kansai people seem to prefer local midnight TV shows in which the Kansai dialect is spoken to other nationwide night shows. As a matter of fact, the Kansai dialect cannot be separated from the local identity of the Kansai people. The dialect is irreplaceable in some cases because of the strong connection to its culture. For instance, the Kansai comedians retain and even take advantage of the accent to express a sense of humor originating in the Kansai identity and its culture. Even if it is the same joke, we could never laugh if it was said in Standard Japanese!",
      part4Header: "鴨川 Kamo River (Kyoto)",
      part4: "As you know, all language experiences some changes as time goes by, and so has Kansai dialect. The Kansai dialect nowadays is different from what it used to be a decade ago. Young people nowadays tend to use what is called “neo-dialect” (the dialect affected by the Standard Japanese). However, it still belongs to the Kansai dialect as long as it retains the typical Kansai intonation pattern.",
      part5: "It is also true that some Kansai dialect speakers acquire Standard Japanese through public education (usually through written materials), through media, through employee training in corporations, during migration, from friends who speak the Standard, or by themselves out of necessity. However, this does not mean they stop using the dialect. They become “bidialectals,” having the Kansai dialect as a “we code,” or as a code which represents their local identity.",
      part6: "Therefore, if you live in or visit the Kansai area and want to belong to the Kansai people, you should try to greet them in their own language - - i.e. to use their \"we code.\""
    }
  },
  talkInTown: {
    QandA: {
      correct: "Correct!",
      almost: "Partially correct...",
      incorrect: "Nope"
    }
  },
  firstRun: {
    announceHeader: "Announcements",
  },
  index: {
  },
  readFirst: {
    readFirstHeader: "READ FIRST"
  },
  footer: {
    copyRight: "Copyright 2007–2018 Ikue Shingu. All Rights Reserved.",
    copyRightBody: "No Reproduction or Republication is allowed without written permission.",
    lastUpdate: (time: Date) => `Last published on ${time}` //TODO: time type change to time like Wednesday, May 02 20:48:10 EDT 2018
  }
};

export const labels = {

};