import React from "react";
import { addRubyText, addAudio } from "common/util/generalUtil";

const SentenceParticles = () => {
  return <>
    <h2>Sentence Particles (in Casual Conversation)</h2>
    <small>*Moce the mouse over the speaker icon for sound</small>
    <br />
    <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}><br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Standard<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Kansai<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            Confirmation<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            ね<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            な／（のう）<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            Assertion<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            よ<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            わ／で／（がな）<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            Question<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            ？(raising tone)<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            ？／か／（け）／（かいな）<br />
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    Confirmation marker ね becomes な (のう is also used by elderly people),
    and assertion marker よ becomes で or わ (light assertion in Kansai
    dialect used by both males and females, different from
    feminine particle わ in Standard!). がな is a strong, blunt
    assertive particle usually used by elderly people/local merchants.
    Questions can be expressed with a raising tone, but か with flat high
    pitch is often added for yes-no question. け is a blunt question marker
    usually used by male /elderly people. 
    <br />
    <br />
    {addRubyText("今日","きょう")}、カレー{addRubyText("食","た")}べる<span style={{textDecoration: "underline"}}>かあ</span>。→
    カレー？うん、食べる<span style={{textDecoration: "underline"}}>で</span>。<small></small>{addAudio("3.1.b.1.1.mp3")}
    <br />
    <small>(ST
    今日カレー食べる<span style={{textDecoration: "underline"}}>？</span>-&gt;</small><small>カレー？うん、食べる
    <span style={{textDecoration: "underline"}}>よ</span>。）</small><small>{addAudio("3.1.b.1.1.s.mp3")}</small>
    <br />
    <small>
      <br />
    </small>
    これ、食べる<span style={{textDecoration: "underline"}}>わ</span>。
    →そう<span style={{textDecoration: "underline"}}>かあ</span>。<small></small>{addAudio("3.1.b.1.2.mp3")}
    <br />
    <small>(ST これ、食べる<span style={{textDecoration: "underline"}}>よ</span>。ー＞あ、そう<span style={{textDecoration: "underline"}}>。</span>）</small>
    <small>{addAudio("3.1.b.1.2.s.mp3")}</small>
    <br />
    <br />
    かいな is usually used by elderly people, indicating simple wondering ("I
    wonder..."), doubt or strong negation (rhetorical question, "No way
    that...!"). Pay attention to the differences of the following two sentences in tone.
    <br />
    <br />
    こんな{addRubyText("古","ふる")}いのん、食べられる<span style={{textDecoration: "underline"}}>かいな</span>！&nbsp;
    {addAudio("3.1.b.1.3.mp3")} 
    <br />
    <small>
      (ST こんなに古いの、食べられ<span style={{textDecoration: "underline"}}>ないよ</span>！)
    </small>
    <small>
      {addAudio("3.1.b.1.3.s.mp3")}
    </small>
    <br />
    <br />
    今日の{addRubyText("新聞","しんぶん")}、どこ{addRubyText("置","お")}いた<span style={{textDecoration: "underline"}}>かいな</span>？<small>&nbsp;</small>{addAudio("3.1.b.1.4.mp3")}<br />
    <small>（ST 今日の新聞、どこに置いた<span style={{textDecoration: "underline"}}>かな</span>？）</small><small>{addAudio("3.1.b.1.4.s.mp3")}</small>
    <br />
    <br />
    <hr />
    <h3>Extra 1: When do Kansai people use よ／ね? 
    <br />
    </h3>
    Young people and women tend to use よ／ね nowadays, influenced by the
    Standard, but わ／で／な are still very common when talking casually and
    blunt.&nbsp; When talking with です／ます style, よ／ね is preferred except
    among elderly people and local merchants.
    <br />
    <br />
    <hr />
    <h3>Extra 2: ぼくが{addRubyText("作","つく")}るわ <br /></h3>
    わ is a Kansai-typical particle for light assertion, used by both male
    and female across the generations. The use of this particle is also
    common when talking with です／ます style.<br />
    <br />
    ほな、今日はもう{addRubyText("帰","かえ")}りますわ。{addAudio("3.1.b.1.5.mp3")}<br />
    <small>(ST じゃあ、今日はもう帰りますよ。）</small><small>{addAudio("3.1.b.1.5.s.mp3")}</small>
    <br />
    <br />
    <hr />
    <h3>Extra 3: More about かいな : はよ{addRubyText("起","お")}きんかい（な）<br />
    </h3>
    When かい（な） follows concentrated verb negatives such as 食べん, {addRubyText("寝","ね")}ん,
    {addRubyText("飲","の")}まん, it can be a command (usually used by older
    generation). Listen to the following two sentences carefully. Can you
    tell the difference of the tones?
    <br />
    <br />
    はよ起きんかいな！{addAudio("3.1.b.1.6.mp3")}<br />
    <small>&nbsp;
    (ST はやく起きなさい！)</small> <small>{addAudio("3.1.b.1.6.s.mp3")}</small>
    <br />
    <br />
    はよ起きんかいな。{addAudio("3.1.b.1.7.mp3")}<br />
    <small>&nbsp;
    (ST はやく起きないかなあ。) </small><small>{addAudio("3.1.b.1.7.s.mp3")}</small>
    <br />
    <br />
    <hr />
    <h3>Extra 4: もう{addRubyText("帰","かえ")}るえ〜<br /></h3>
    You may hear え at the end of the sentence when Kyoto women talk. It is
    a traditional feminine particle that is equivalent to a question
    marker か or assertion よ.
    <br />
    <br />
    {addRubyText("今晩","こんばん")}は、ぎょうさん{addRubyText("飲","の")}むえ ~。<small> </small>{addAudio("3.1.b.1.8.mp3")}
    <br />
    <small>（ST 今晩は、たくさん飲むわよ!）</small><small>{addAudio("3.1.b.1.8.s.mp3")}</small>
    <br />
    <br />
    <br />
  </>;
}

export default SentenceParticles;
