import React from "react";
import { Link } from "react-router-dom";
import { Page } from "home/page";
import { addRubyText, addImage } from "common/util/generalUtil";

const Foreigners = () => {
  return (
    <>
      <h2>{addRubyText("関西","かんさい")}の{addRubyText("外国人","がいこくじん")} Kansai Foreigners</h2>
      <div>
        We interviewed some foreigners who currently live or used to live in
        Kansai area. Interviews were conducted first in English, and then in
        Kansai-ben. Watch their video clips and see what they think about
        Kansai-ben and Kansai people!
      </div>
      <br />
      <table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>
            <b>リーさん </b><br />
            Voice actor / comedian who has lived in Osaka since 2000.
            Prior to his move to Osaka, he studied standard Japanese for 2 years at
            a high school in the US and stayed in Tokyo for 18 months. <br />
            <ul>
              <li>
                <Link to={Page.OtherResources.Foreigners.LeeEnglish}>Interview in English</Link>
              </li>
              <li>
                <Link to={Page.OtherResources.Foreigners.LeeKansaiben}>Interview in Kansai-ben</Link>
              </li>
            </ul>
          </td>
          <td style={{verticalAlign: "top"}}>
            {addImage("Lee.jpg")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            <b>エリックさん</b><br />
            Teacher at an university in Tokyo who has lived in Tokyo for a total of
            6 years and in Osaka for 10 months.
            He did formal study of standard Japanese for 3 years in the US and in
            Japan. Since his wife is from Nara Prefecture he has also spent time
            there off and on.<br />
            <ul>
              <li>
                <Link to={Page.OtherResources.Foreigners.EricEnglish}>Interview in English</Link>
              </li>
              <li>
                <Link to={Page.OtherResources.Foreigners.EricKansaiben}>Interview in Kansai-ben</Link>
              </li>
            </ul>
          </td>
          <td style={{verticalAlign: "top"}}>
            {addImage("Eric.jpg")}
          </td>
        </tr>
        </tbody>
      </table>
    </>
  );
};

export default Foreigners;