import React from "react";
import { addImage } from "common/util/generalUtil";

const LettersFromUsers = () => {
  return (
    <>
      <h2>Letters from users</h2>
      <h3>From Ms. Dainelys Oliva (Havana, Cuba)
      </h3>
      <table style={{textAlign: "left", width: "20%"}} align="right" border="0" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addImage("dainelys.bmp")}</td>
        </tr>
        </tbody>
      </table>
      <div style={{textAlign: "left"}}>
        <div>新宮先生、畑中先生、</div>
        <br />
        <div>
          はじめまして。私はダイネリスと申します。キューバ人でキューバに住んでいます。ハバナ
          大学の外国語学部の学生で日本語とフランス語とドイツ語を研究しています。日本語は３年生です。９月は国際交流基金の日本語成績優秀者訪日研修というコー
          スに参加しました。関西国際センターで１０日間ぐらい生活しました。その関西国際センターは静かできれいなリンクタウンという町に位置しています。現地市
          民がいつも関西弁で話しているので話しかける機会のあったときは面白かったです。私は日本へ行く前に関西弁を聞いたことがあって、最初は関西弁をよく聞い
          てびっくりしませんでした。一方、興味を持っていて大分好きになってきました。
        </div>
      </div>
      <div>
        今日、「関西弁 self-study site for Japanese Language Learners」というウェブサイトを見つけて喜びました。キューバで教えられる日本語は公用語であるので日本語を勉強している人では関西弁のことを知
        らない人が大多数です。私は関西弁が日本語の学生に人気が出るようになったらいいと思っています。それは自分の目標の一つになりました。将来は、関西
        弁の教師になりたいと思っています。<br />
        私のお礼の気持ちをこのメールでおくります。そのウェブサイトを、どうもありがとうございました。<br />
        お体を大切に。
      </div>
      <div style={{textAlign: "left"}}>
        <div>ダイネリス</div>
        <h3>From Mr. Mieszko Lis (MIT)</h3>
        <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
          <tbody>
          <tr>
            <td style={{verticalAlign: "top"}}>
              先生、<br />
              <br />
              この関西弁サイトを作って下さって、誠に有難うございました。面白い教材がいっぱいで、関西弁を学びたい人は本当に助かると思います。<br />
              <br />
              実は、初めて日本に行った時は大阪にしばらく住んでおりましたが、時々関西弁を喋る人と話してもほとんど理解出来ませんでした。しかし、先月久しぶりに日
              本に行く機会があり、しばらく関西に滞在しました。京都の紅葉を見に行った時、ある関西弁で喋るお祖父さんに声を掛けられて、前より理解出来ましてすごく
              びっくりしました。確かに関西弁が混じった日本語だったんですが、それでも・・・後で考えれば、行く前に仕事をやりながらこの関西弁サイトを見たりして、
              知らないうちになんとか勉強になったみたいです。本当にお世話になっております。<br />
              <br />
              方言ですが、とっても大事な文化遺産だと思います（実は私の育った所も方言で話すところです）。この世界が狭い時代には色々な方言は次第に亡くなりますの
              で、先生の様に守って下さる人は少なくて、本当に尊敬しております。<br />
              <br />
              いつもお世話になっておりますが、これからもどうぞ宜しくお願い致します。<br />
              <br />
              リス・ミエシコ</td>
            <td style={{verticalAlign: "top"}}>Dear Sensei,<br />
              <br />
              I wanted to thank you very much for creating this website. Students
              interested in learning Kansai-ben will, I think, find the wealth of
              study material really helpful.<br />
              <br />
              During my first trip to Japan I briefly lived in Ōsaka, and found it
              quite difficult to understand Kansai-ben when I occasionally
              encountered people speaking the dialect. Recently, I had the
              opportunity to visit Japan again; while in Kyōto to admire the autumn
              foliage, I chanced to meet an older gentleman speaking Kansai-ben and
              was astonished to find that I could understand quite a bit more than I
              could a year ago. Somehow, I realized, I must have unwittingly
              learned
              more of the dialect while browsing your site.<br />
              <br />
              Perhaps in part because I myself grew up speaking a dialect, I believe
              that local languages form an important part of our cultural
              heritage.
              In today's world, dialects are being forgotten and lost through
              globalization, and I have great respect for the few people who work to
              preserve them.<br />
              <br />
              Thank you!<br />
              <br />
              Mieszko Lis</td>
          </tr>
          </tbody>
        </table>
        <br />
        <h3>From Ms. Mariko Medlock (MIT)</h3>
        <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
          <tbody>
          <tr>
            <td style={{verticalAlign: "top"}}>先生、<br />
              <br />
              外国人に関西弁を教えるウェブサイトを作って下さってどうもありがとうございました。去年兵庫県で働いていた時にこのサイトでよく勉強していました。仕事
              中は皆が大体標準語でしゃべっていたんですけど、仕事の外では関西弁の方がよく使われていました。<br />
              <br />
              日本にいる間に週末に友達とよく日帰り旅行に行っていました。二人とも外国人ですからどこへ行けばいいのかよく分からなくて、いつも会社の寮の食堂で働い
              ていたおばさんたちに聞きに行きました。一生関西に住んでいるおばさんたちでしたから、いつも自分の家族の好きな場所を勧めて下さいました。それで関西弁
              しか話していなかったから最初は分かるのが難しかったけど、このサイトで習った言葉やパターンがすごく役に立ちました。旅行中も迷子になってしまったら誰
              かに道を聞く時にもすごく助かりました。（迷子になった事がよくあったんです）<br />
              <br />
              このサイトの勉強を始めたのは、関西にいる間に役に立つだろうと思っていたからです。けど今は、近いうちに日本に戻るつもりはなくても、このサイトのイン
              タビューや基本会話や聞き取り練習のビデオは面白いから時々見直しているですよ。特に子供たちのインタビューは笑わせますね。<br />
              <br />
              私はハワイ出身で、実家では関西と同じように、外から来たばかりの人にとっては分かりにくい方言がよく使われています。大体若い人は本土の英語とハワイの
              方言をどちらもしゃべれるけど、ハワイの方言しかしゃべれないお年寄りが多いです。外から来る人には、特にお年寄りとよく会っている人には、一番困るのは
              方言が分からないことだと思います。学校で習った言葉と方言の言葉がちょっと違うって言うことだけじゃなくて、やっぱり外国人に方言を教えると、もっと自
              分の気持ちを伝えられるようになるからこのようなサイトはとても大切なツールだと思います。<br />
              <br />
              どうもありがとうございました。<br />
              メドロックまりこ<br />
            </td>
            <td style={{verticalAlign: "top"}}>Sensei,<br />
              <br />
              Thank you very much for making this website to teach Kansai-ben to
              foreigners. Last year, while I was working in Hyogo-ken, I studied the
              materials on this site a lot. While many people spoke standard
              Tokyo-dialect in the workplace, most of the people I spoke with outside
              of work used Kansai-ben. <br />
              <br />
              I used to take day trips with a friend on the weekends. Neither of us
              knew the area, so we used to ask the ladies who worked in the dorm's
              cafeteria where we should go. They were older ladies who had lived in
              Kansai their whole lives, so they always recommended places that they
              liked to go with their families. They also spoke entirely in Kansai-ben
              so it was pretty hard to understand at first, but the expressions and
              speech patterns I learned on this site helped a lot. It also helped
              during the trips when we got lost and had to stop and ask for
              directions (this happened a lot).<br />
              <br />
              When I started studying the materials on this site, it was because I
              thought it would be useful to me during my stay in Kansai. But actually
              now, even though I'm not planning on going back to Japan anytime soon,
              I still like to watch the videos on the site sometimes because they're
              pretty funny. The interviews of the little kids especially made me
              laugh.<br />
              <br />
              My family is from Hawaii where, like Kansai, there is a local dialect
              that can be very hard for outsiders to understand. While younger people
              can usually switch between the local dialect and standard English, many
              older people speak only the dialect. This presents a real barrier for
              foreigners who come to the islands, particularly to those who spend any
              time with older people. So I think a site like this, that helps
              foreigners make the transition from the standard language they learned
              in school to a widely used local dialect, is a tool that helps improve
              communication between foreigners and local residents.<br />
              <br />
              Thank you.<br />
              Mariko Medlock<br />
            </td>
          </tr>
          </tbody>
        </table>
        <br />
        <h3>From Mr. Urban Stare Ito (Slovenia)</h3>
        Dear Sir or Madam,<br />
        <br />
        My name is Urban Stare Ito, and I am a student of Japanese language at
        University of Ljubljana in Slovenia, Europe. I am writing you just to
        compliment a great work you have done on your Kansai-ben learning
        site. <br />
        <br />
        Your site helps me understand Kansai dialect much more. My wife is
        speaking Kansai dialect, and sometimes it’s hard for me
        to understand
        since I only speak standard Japanese. That’s why I decided I should
        start learning Kansai dialect too, and your site is crucial for me.<br />
        <br />
        It is the only project I have seen until now on the internet,
        that really stands out, and give exceptional amount of knowledge
        about Kansai-ben. Not just text but also having multimedia like video
        and audio clips to choose from, is very helpful at study. Seeing that
        it is also updated regularly, and the explanation is excellent, this
        really makes it the best site for learning Kansai-ben out there.<br />
        <br />
        I thank you for being able to use your site, for my study of this
        beautiful dialect. Thank you, and keep up the good work!<br />
        <br />
        Yours sincerely,<br />
        Urban Stare Ito<br />
        <br />
      </div>
    </>
  );
};

export default LettersFromUsers;