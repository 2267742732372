import React from "react";
import { Link } from "react-router-dom";
import { addRubyText, addAudio } from "common/util/generalUtil";
import { Page } from "home/page";

const TeForm = () => {
  return <>
    <h2>4. て form of Adjectives</h2>
    The て form (gerund) of adjective is formed by adding て to the
    "original" adverbial form, e.g. {addRubyText("早","はよ")}うて, {addRubyText("白","しろ")}うて and {addRubyText("大","おお")}きゅうて. て
    form formed by lengthening (or not) the ending vowel of the stem and
    addingてsuch as {addRubyText("早","はや")}あて and {addRubyText("大","おお")}きいて&nbsp; is also widely used by
    younger generations. <br />
    <small>*Move the mouse over hte speaker icon for sound</small><br />
    <br />
    この{addRubyText("説明","せつめい")}、{addRubyText("字","じ")}がちっちょうて{addRubyText("読","よ")}めへんねん。<small> </small>{addAudio("3.4.4.1.mp3")}<br />
    <small>（ST この説明は字が小さくて読めないんだ。） </small><small>{addAudio("3.4.4.1.s.mp3")}</small><br />
    <br />
    あの{addRubyText("駅","えき")}のトイレ、{addRubyText("汚","きた")}のうて、かなんわ。{addAudio("3.4.4.2.mp3")}<br />
    <small>(ST あの駅のトイレは汚くて{addRubyText("嫌","いや")}だよ。) </small><small> </small><small>{addAudio("3.4.4.2.s.mp3")}</small><br />
    <br />
    <h3>Summary of Adverbial form and its
    application</h3>
    <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2"
    cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>Ending<br />
          </td>
          <td style={{verticalAlign: "top"}}>-ai<br />
          </td>
          <td style={{verticalAlign: "top"}}>-oi<br />
          </td>
          <td style={{verticalAlign: "top"}}>-ii<br />
          </td>
          <td style={{verticalAlign: "top"}}>-ui<br />
          </td>
          <td style={{verticalAlign: "top"}}><br />
          </td>
          <td style={{verticalAlign: "top"}}><br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>(Example)<br />
          </td>
          <td style={{verticalAlign: "top"}}>{addRubyText("高","たか")}い<br />
          </td>
          <td style={{verticalAlign: "top"}}>{addRubyText("黒","くろ")}い<br />
          </td>
          <td style={{verticalAlign: "top"}}>{addRubyText("大","おお")}きい<br />
          </td>
          <td style={{verticalAlign: "top"}}>{addRubyText("悪","わる")}い<br />
          </td>
          <td style={{verticalAlign: "top"}}>ええ(good)<br />
          </td>
          <td style={{verticalAlign: "top"}}>ない<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>Adverbial form<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            たこ（う）<br />
            たか<br />
          </td>
          <td style={{verticalAlign: "top"}}>くろ（う）<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            おおきゅ（う）<br />
            おおき<br />
          </td>
          <td style={{verticalAlign: "top"}}>わる（う）<br />
          </td>
          <td style={{verticalAlign: "top"}}>よう<br />
          </td>
          <td style={{verticalAlign: "top"}}>のう<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>Non-past negative<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            たこ（う）ない<br />
            たかない<br />
          </td>
          <td style={{verticalAlign: "top"}}>くろ（う）ない<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            おおきゅ（う）ない<br />
            おおきない<br />
          </td>
          <td style={{verticalAlign: "top"}}>わる（う）ない<br />
          </td>
          <td style={{verticalAlign: "top"}}>ようない<br />
          </td>
          <td style={{verticalAlign: "top"}}>-<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>TE-form<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            たこ（う）て<br />
            たか（あ）て<br />
          </td>
          <td style={{verticalAlign: "top"}}>くろ（う）て<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            おおきゅうて<br />
            おおきいて<br />
          </td>
          <td style={{verticalAlign: "top"}}>わるうて<br />
          </td>
          <td style={{verticalAlign: "top"}}>ようて<br />
          </td>
          <td style={{verticalAlign: "top"}}>のうて<br />
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    <hr />
    <h3>Extra 1: PAST て form of adjectives<br />
    </h3>
    As mentioned in earlier (<Link to={Page.BasicGrammar.Copula.Grammar.Conjugation}>
    Grammar 1: Copula Conjugation</Link> in Lesson 3. Copula), adjectives can
    become PAST てform (gerund) in Kansai-ben just like Copula and Verb
    negatives in the past tense. (*Remember there is no past gerund in
    Standard!!) Past て form of adjectives can be formed by changing た in
    the past affirmative to て.<br />
    <br />
    あっこの{addRubyText("温泉","おんせん")}<span style={{textDecoration: "underline"}}>{addRubyText("広","ひろ")}かって</span>、
    {addRubyText("気持","きも")}ちよかったわあ。{addAudio("3.4.4.3.mp3")}<br />
    <small>(ST あそこの温泉は広くて気持ちがよかったよ。)</small> <small> </small><small>{addAudio("3.4.4.3.s.mp3")}</small><br />
    <br />
    Negative pastてform of adjectives is used too.<br />
    <br />
    {addRubyText("彼女","かのじょ")}が{addRubyText("作","つく")}ってくれた{addRubyText("料理","りょうり")}、{addRubyText("全然","ぜんぜん")}<span
    style={{textDecoration: "underline"}}>おいしなかって</span>、{addRubyText("涙","なみだ")}{addRubyText("出","で")}そうやったわあ。
    {addAudio("3.4.4.4.mp3")}<br />
    <small>(ST 彼女が作ってくれた料理は全然おいしくなくて、涙が出そうだったよ。)</small>
    <small> </small><small>{addAudio("3.4.4.4.s.mp3")}</small>
  </>;
}

export default TeForm;
