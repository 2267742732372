import React from "react";
import { addRubyText, addImage } from "common/util/generalUtil";
import { Link } from "react-router-dom";
import { Page } from "home/page";

const Practice = () => {
  return <>
    <h2>{addRubyText("練習","れんしゅう")} (Practice)</h2>
    <br/>
    <table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td colSpan="1" rowSpan="1" style={{verticalAlign: "top"}}>
            <ul>
              <li>
                <Link to={Page.BasicGrammar.Copula.Practice.P1}>
                  {addRubyText("練習","れんしゅう")}1 : N+Copula (non-past negative)
                </Link>
              </li>
              <li>
                <Link to={Page.BasicGrammar.Copula.Practice.P2}>
                  {addRubyText("練習","れんしゅう")}2 : N+Copula (non-past negative and affirmative)
                </Link>
              </li>
              <li>
                <Link to={Page.BasicGrammar.Copula.Practice.P3}>
                  {addRubyText("練習","れんしゅう")}3 : N+Copula (past)
                </Link>
              </li>
              <li>
                <Link to={Page.BasicGrammar.Copula.Practice.P4}>
                  {addRubyText("練習","れんしゅう")}4 : N+Copula (negative-past)
                </Link>
              </li>
              <li>
                <Link to={Page.BasicGrammar.Copula.Practice.P5}>
                  {addRubyText("練習","れんしゅう")}5 : N+Copula + extended predicate
                </Link>
              </li>
            </ul>
          </td>
          <td colSpan="1" rowSpan="1" style={{verticalAlign: "top", textAlign: "center"}}>
            {addImage("osakajo.jpg")}
            <br/>
            <small>
              大阪城 Osaka Castle (Osaka)
            </small>
            <br/>
          </td>
        </tr>
        <tr align="center">
        </tr>
      </tbody>
    </table>
  </>;
}

export default Practice;