import React from "react";
import { addRubyText, addAudio } from "common/util/generalUtil";

const Conjugation = () => {
  return <>
    <h2>1. Copula Conjugation <br />
    </h2>
    <small>*Mouse over or click on the speaker icon for sound</small><br />
    <table style={{ textAlign: "left", width: "100%" }} border="1" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{ verticalAlign: "top" }}><br />
          </td>
          <td style={{ verticalAlign: "top", textAlign: "center" }}>Standard<br />
          </td>
          <td style={{ verticalAlign: "top", textAlign: "center" }}>Kansai<br />
          </td>
        </tr>
        <tr>
          <td style={{ verticalAlign: "top" }}>Non-past affeirmative<br />
          </td>
          <td style={{ verticalAlign: "top" }}>N（だ）<br />
          </td>
          <td style={{ verticalAlign: "top" }}>N（や）<br />
          </td>
        </tr>
        <tr>
          <td style={{ verticalAlign: "top" }}>Past affirmative<br />
          </td>
          <td style={{ verticalAlign: "top" }}>Nだった<br />
          </td>
          <td style={{ verticalAlign: "top" }}>Nやった<br />
          </td>
        </tr>
        <tr>
          <td style={{ verticalAlign: "top" }}>Non-past negative<br />
          </td>
          <td style={{ verticalAlign: "top" }}>Nじゃない<br />
          </td>
          <td style={{ verticalAlign: "top" }}>
            Nやあらへん<br />
            Nやない<br />
            N（と）ちゃう<br />
          </td>
        </tr>
        <tr>
          <td style={{ verticalAlign: "top" }}>Past negative<br />
          </td>
          <td style={{ verticalAlign: "top" }}>Nじゃなかった<br />
          </td>
          <td style={{ verticalAlign: "top" }}>
            Nやあらへんかった<br />
            Nやなかった<br />
            N（と）ちごた<br />
            N（と）ちゃうかった<br />
          </td>
        </tr>
        <tr>
          <td style={{ verticalAlign: "top" }}>Gerund (TE-form)<br />
          </td>
          <td style={{ verticalAlign: "top" }}>Nで<br />
          </td>
          <td style={{ verticalAlign: "top" }}>
            Nで<br />
            Nやって(*past tense only)<br />
          </td>
        </tr>
      </tbody>
    </table>
    <small>* “N” here stands for Noun (Nominal) and な adjective&nbsp;
    (な nominal) stem.</small><br />
    <br />
    The term "Copula" indictates 〜です／だ, the conjugation ending when Nouns
    (Nominals) and なadjectives (なNominals) are used as a predicate. This
    Copula だ becomes や in
    the Kansai dialect. In the same way, the perfective (past) affirmative
    form becomes from だった to やった. There are two types of negative forms;
    one
    is ~やあらへん (あらへん is the negative form of ある, hence is equal to ない), and
    the other is 〜（と）ちゃう ( ちがう "different"). Note that the younger
    generations tend to use Neo-dialect Nやない（じゃない）／Nやなかった（じゃなかった）in
    addition to the traditional pattern. <br />
    <br />
    {addRubyText("今日", "きょう")}、{addRubyText("雨", "あめ")}？→ううん、雨ちゃうで。{addAudio("3.3.1.1.mp3")}<br />
    <small>(ST 今日雨？ー＞ううん、雨じゃないよ。）</small><small>{addAudio("3.3.1.1.s.mp3")}</small><br />
    <br />
    今日、{addRubyText("月曜日", "げつようび")}やったっけ？→ちゃうちゃう。{addRubyText("火", "か")}曜日やがな。<small></small>{addAudio("3.3.1.2.mp3")}<br />
    <small>(ST
    今日、月曜日だったっけ？ー＞ちがうよ。火曜日だよ！）</small><small>{addAudio("3.3.1.2.s.mp3")}</small><br />
    <br />
    {addRubyText("宿題", "しゅくだい")}いっぱいやねん。→ほら、{addRubyText("大変", "たいへん")}やなあ。<small></small>{addAudio("3.3.1.3.mp3")}<br />
    <small>(ST 宿題がいっぱいなんだ。ー＞そりゃ、大変だねえ。）</small><small>{addAudio("3.3.1.3.s.mp3")}</small><br />
    <br />
    アルバイト{addRubyText("行", "い")}くの、{addRubyText("嫌", "いや")}やわあ。→あれ？{addRubyText("仕事", "しごと")}{addRubyText("好", "す")}きちごたん？{addAudio("3.3.1.4.mp3")}<br />
    <small>(ST
    アルバイトに行くの、嫌だよ。ー＞あれ？仕事、好きじゃなかったの？）</small><small>{addAudio("3.3.1.4.s.mp3")}</small><br />
    <br />
    It should be easy to guess that the Kansai equivalent of だろう、だったら can
    be formed by alternating だ with や (やろう is often shortened toやろ).<br />
    <br />
    {addRubyText("会議", "かいぎ")}、{addRubyText("五時", "ごじ")}からやろ？→え？{addRubyText("六時", "ろくじ")}からとちごた？<small> </small>{addAudio("3.3.1.5.mp3")}<br />
    <small>(ST
    会議は五時からだろう？ー＞え？六時からじゃなかった？）</small><small>{addAudio("3.3.1.5.s.mp3")}</small><br />
    <br />
    今日、{addRubyText("日", "にち")}曜日やったらええのになあ。→そやなあ。<small></small>{addAudio("3.3.1.6.mp3")}<br />
    <small>(ST 今日が日曜日だったらいいのにねえ。ー＞そうだねえ。）</small><small>{addAudio("3.3.1.6.s.mp3")}</small><br />
    <br />
    <hr />
    <h3>Extra 1: {addRubyText("休", "やす")}みやって、{addRubyText("困", "こま")}ったわ。</h3>
    Kansai-ben does have "Past Gerund (TE-form)" on copula, adjectives and
    verb negatives
    unlike Standard.<br />
    <br />
    <table style={{ textAlign: "left", width: "60%" }} border="1" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{ verticalAlign: "top" }}>KANSAI<br />
          </td>
          <td style={{ verticalAlign: "top" }}>Affirmative ending<br />
          </td>
          <td style={{ verticalAlign: "top" }}>Gerund (TE-form)<br />
          </td>
        </tr>
        <tr>
          <td style={{ verticalAlign: "top" }}>Non-past<br />
          </td>
          <td style={{ verticalAlign: "top" }}>N（や）<br />
          </td>
          <td style={{ verticalAlign: "top" }}>Nで<br />
          </td>
        </tr>
        <tr>
          <td style={{ verticalAlign: "top" }}>Past<br />
          </td>
          <td style={{ verticalAlign: "top" }}>Nやった<br />
          </td>
          <td style={{ verticalAlign: "top" }}>Nやって<br />
          </td>
        </tr>
      </tbody>
    </table>
    <small>*For Adjectives and Verb negatives, refer to Adjective てform and
    negative て-form of Verbs respectively.</small><br />
    <br />
    {addRubyText("銀行", "ぎんこう")}{addRubyText("行", "い")}ったら、{addRubyText("休", "やす")}み<span style={{ textDecoration: "underline" }}>やって</span>、
    {addRubyText("困", "こま")}ったわ。{addAudio("3.3.1.11.mp3")}<br />
    <small>(ST 銀行に行ったら休み<span style={{ textDecoration: "underline" }}>で</span>、
    困った
    よ。) </small><small>{addAudio("3.3.1.11.s.mp3")}</small><br />
    <small></small><br />
    Do not confuse this with copula (non-past) + quotation marker.<br />
    <br />
    {addRubyText("天気予報", "てんきよほう")}で{addRubyText("明日", "あした")}{addRubyText("雪", "ゆき")}<span style={{ textDecoration: "underline" }}>やっ
    て</span> {addRubyText("言", "ゆ")}うてたで。<small> </small>(Quotation)&nbsp; {addAudio("3.3.1.12.mp3")}<br />
    <small>(ST 天気予報で雪<span style={{ textDecoration: "underline" }}>だって</span>言って
    た
    よ。)</small><small>{addAudio("3.3.1.12.s.mp3")}</small><br />
    <small></small><br />
    {addRubyText("朝", "あさ")}{addRubyText("起", "お")}きたら雪<span style={{ textDecoration: "underline" }}>やって</span>、
    びっくりしたわ。<small> （</small>Past-TE
    form) {addAudio("3.3.1.13.mp3")}<br />
    <small>(ST 朝起きたら雪<span style={{ textDecoration: "underline" }}>で</span>、びっくり
    したよ。) </small><small>{addAudio("3.3.1.13.s.mp3")}</small><br />
    <br />
    It is also easy to imagine negative copula can become negative PAST
    copula gerund in the past tense.<br />
    <br />
    {addRubyText("大", "たい")}した{addRubyText("病気", "びょうき")}<span style={{ textDecoration: "underline" }}>やあらへんかって</span>、
    よかったなあ。<small>　</small>{addAudio("3.3.1.14.mp3")}<br />
    <small>(ST 大した病気<span style={{ textDecoration: "underline" }}>じゃなくて</span>、
    よかったねえ。）</small><small>{addAudio("3.3.1.14.s.mp3")}</small><br />
    <small></small><br />
    (*〜やあらへんかって can be alternated with 〜やなかって／〜（と）ちごて／〜（と）ちゃうかって.)<br />
    <br />
    <hr />
    <h3>Extra 2: 雨やったん？雨やってん。<br />
    </h3>
    When past affirmative やった is followed by extended predicate, やったん？is
    used for questions and やってん is used for statements. When it is an
    interrogative question, やってん？can be used to indicate strong emotional
    involvement. The same rule can be applied to the negative form. <br />
    <br />
    朝ご{addRubyText("飯", "はん")}、パンだけやっ<span style={{ textDecoration: "underline" }}>てん</span>。→あ、そう
    か。{addAudio("3.3.1.7.mp3")}<br />
    <small>(ST 朝ご飯はパンだけだったんだ。ー＞あ、そう。）</small><small>{addAudio("3.3.1.7.s.mp3")}</small><br />
    <br />
    その{addRubyText("魚", "さかな")}、なんぼやっ<span style={{ textDecoration: "underline" }}>たん</span>？→セールで
    {addRubyText("一匹", "いっぴき")}{addRubyText("百円", "ひゃくえん")}やっ<span style={{ textDecoration: "underline" }}>てん</span>。<small></small>{addAudio("3.3.1.8.mp3")}<br />
    <small>(ST
    その魚、いくらだったの？ー＞セールで一匹百円だったんだ。）</small><small>{addAudio("3.3.1.8.s.mp3")}</small><br />
    <br />
    {addRubyText("北海道", "ほっかいどう")}のお{addRubyText("土産", "みやげ")}、{addRubyText("何", "なん")}やっ<span style={{ textDecoration: "underline" }}>た
    ん</span>？→
    チョコやっ<span style={{ textDecoration: "underline" }}>てん</span>。<small></small>{addAudio("3.3.1.9.mp3")}<br />
    <small>(ST
    北海道のお土産は何だったの？ー＞チョコだったんだ。）</small><small>{addAudio("3.3.1.9.s.mp3")}</small><br />
    <br />
    ほんまはチョコちご<span style={{ textDecoration: "underline" }}>てん</span>。→ほな、<span
      style={{ textDecoration: "underline" }}>何やってん</span>？？！(stronger than
    何やったん？)&nbsp;<small></small>{addAudio("3.3.1.10.mp3")}<br />
    <small>(ST {addRubyText("本当", "ほんとう")}はチョコじゃなかったんだ。ー＞じゃあ、何だったの？？！）</small><small>{addAudio("3.3.1.10.s.mp3")}</small>
  </>;
}

export default Conjugation;
