import React from "react";
import { Link } from "react-router-dom";
import { Page } from "home/page";
import { addRubyText, addImage } from "common/util/generalUtil";

const Video = () => {
  return <>
    <h2>ビデオ{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}(Video Comprehension Practice)</h2>
    <table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2"
    cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>
          <ul>
            <li>
              <Link to={Page.FunctionalGrammar.NegativePermissionObligation.Video.V1}>
                ビデオ聞き取り練習１
              </Link>
            </li>
            <li>
              <Link to={Page.FunctionalGrammar.NegativePermissionObligation.Video.V2}>
                ビデオ聞き取り練習２: Authentic - short
              </Link>
            </li>
            <li>
              <Link to={Page.FunctionalGrammar.NegativePermissionObligation.Video.V3}>
                ビデオ聞き取り練習３: Authentic - short
              </Link>
            </li>
            <li>
              <Link to={Page.FunctionalGrammar.NegativePermissionObligation.Video.V4}>
                ビデオ聞き取り練習４: Authentic - short
              </Link>
            </li>
            <li>
              <Link to={Page.FunctionalGrammar.NegativePermissionObligation.Video.V5}>
                ビデオ聞き取り練習５: Authentic - short
              </Link>
            </li>
            <li>
              <Link to={Page.FunctionalGrammar.NegativePermissionObligation.Video.V6}>
                ビデオ聞き取り練習６: Authentic - short
              </Link>
            </li>
            <li>
              <Link to={Page.FunctionalGrammar.NegativePermissionObligation.Video.V7}>
                ビデオ聞き取り練習７: Authentic - short
              </Link>
            </li>
            <li>
              <Link to={Page.FunctionalGrammar.NegativePermissionObligation.Video.V8}>
                ビデオ聞き取り練習８: Authentic - short
              </Link>
            </li>
            <li>
              <Link to={Page.FunctionalGrammar.NegativePermissionObligation.Video.V9}>
                ビデオ聞き取り練習９: Authentic - short
              </Link>
            </li>
            <li>
              <Link to={Page.FunctionalGrammar.NegativePermissionObligation.Video.V10}>
                ビデオ聞き取り練習１０: Authentic - medium
              </Link>
            </li>
            <li>
              <Link to={Page.FunctionalGrammar.NegativePermissionObligation.Video.V11}>
                ビデオ聞き取り練習１１: Authentic - medium
              </Link>
            </li>
            <li>
              <Link to={Page.FunctionalGrammar.NegativePermissionObligation.Video.V12}>
                ビデオ聞き取り練習１２: Authentic - medium
              </Link>
            </li>
            <li>
              <Link to={Page.FunctionalGrammar.NegativePermissionObligation.Video.V13}>
                ビデオ聞き取り練習１３: Authentic - medium
              </Link>
            </li>
            <li>
              <Link to={Page.FunctionalGrammar.NegativePermissionObligation.Video.V14}>
                ビデオ聞き取り練習１４: Authentic - medium
              </Link>
            </li>
            <li>
              <Link to={Page.FunctionalGrammar.NegativePermissionObligation.Video.V15}>
                ビデオ聞き取り練習１５: Authentic - medium
              </Link>
            </li>
            <li>
              <Link to={Page.FunctionalGrammar.NegativePermissionObligation.Video.V16}>
                ビデオ聞き取り練習１６: Authentic - medium
              </Link>
            </li>
            <li>
              <Link to={Page.FunctionalGrammar.NegativePermissionObligation.Video.V17}>
                ビデオ聞き取り練習１７: Authentic - long
              </Link>
            </li>
            <li>
              <Link to={Page.FunctionalGrammar.NegativePermissionObligation.Video.V18}>
                ビデオ聞き取り練習１８: Authentic - long
              </Link>
            </li>
            <li>
              <Link to={Page.FunctionalGrammar.NegativePermissionObligation.Video.V19}>
                ビデオ聞き取り練習１９: Authentic - long
              </Link>
            </li>
          </ul>
          </td>
          <td style={{verticalAlign: "top"}}>
          <div style={{textAlign: "center"}}>{addImage("shigarakiyakitanuki.jpg")}<br />
          </div>
          <div style={{textAlign: "center"}}><small>信楽焼たぬき Racoon statue of Shigaraki ware (Shiga)</small><br />
          </div>
          </td>
        </tr>
      </tbody>
    </table>
  </>;
}

export default Video;
