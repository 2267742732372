import React from "react";
import { Link } from "react-router-dom";
import { Page } from "home/page";
import { addRubyText, addImage } from "common/util/generalUtil";

const PermissionProhibition = () => {
  return <>
    <h2>Lesson 1: {addRubyText("許可","きょか")}と{addRubyText("禁止","きんし")}&nbsp; (Permission and Prohibition)</h2>
    <h3>〜{addRubyText("辞書","じしょ")}は{addRubyText("使","つこ")}てもええけど、{addRubyText("教科書","きょうかしょ")}は{addRubyText("見","み")}たらあかん。〜</h3>
    <table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>
          <ul>
            <li>
              <Link to={Page.FunctionalGrammar.PermissionProhibition.Conversations}>
                {addRubyText("基 本会話","きほんかいわ")} (Core Conversations)
              </Link>
            </li>
            <li>
              <Link to={Page.FunctionalGrammar.PermissionProhibition.Grammar.Grammar}>
                {addRubyText("文 法","ぶんぽう")}ノート (Grammar Note)
              </Link>
            </li>
            <li>
              <Link to={Page.FunctionalGrammar.PermissionProhibition.Practice.Practice}>
                {addRubyText("練 習","れんしゅう")}(Practice)
              </Link>
            </li>
            <li>
              <Link to={Page.FunctionalGrammar.PermissionProhibition.Listening.Listening}>
                {addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")} (Listening Practice)
              </Link>
            </li>
            <li>
              <Link to={Page.FunctionalGrammar.PermissionProhibition.Video.Video}>
                ビデオ{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}（Video Comprehension Practice)
              </Link>
            </li>
          </ul>
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addImage("kazoku.jpg")}<br />
          <small>晩ご飯 Family Dinner (Shiga)</small><br />
          </td>
        </tr>
      </tbody>
    </table>
  </>;
}

export default PermissionProhibition;
