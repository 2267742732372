import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";

const V1 = () => {
  const transcript = <>
    ま、{addRubyText("知","し")}り{addRubyText("合","あ")}い{addRubyText("言","ゆ")}うたかって(Grammar
    2)、そんなにあれやけど，まあ，{addRubyText("年末","ねんまつ")}にちょっとこっち{addRubyText("来","き")}ゃはった{addRubyText("人","ひと")}とかとあれしてる、{addRubyText("会","あ")}ったりして、ちょっと
    ２、３{addRubyText("日","にち")}だけのお{addRubyText("付","つ")}き合いだけとかで、そんなに、、。（interviewer –
    {addRubyText("私","わたし")}の{addRubyText("学生","がくせい")}やね。）はいね。
  </>;


  const qaObjects = [
    {
      question: <span>Q1: Does she have any foreign acquaintances?</span>,
      answersCorrect: [
        <>She met some foreigners who visited her place for 2-3 days at the end of the year. </>
      ],
      answersIncorrect: [
        <>She has some foreign friends who annually visit her place for 2-3 days at the end of the year.</>,
        <>She wishes she had a foreign acquaintance whose place she can visit for 2-3 days at the end of the year.</>
      ]
    },
    {
      question: <span>Q2: What phrase would most likely complete her sentence２、３{addRubyText("日","にち")}だけのお{addRubyText("付","つ")}き合いだけとかで、そんなに、、、?</span>,
      answersCorrect: [
        <>{addRubyText("知","し")}り{addRubyText("合","あ")}いというほどの{addRubyText("外国人","がいこくじん")}はいない。</>
      ],
      answersIncorrect: [
        <>{addRubyText("外国人","がいこくじん")}の{addRubyText("知","し")}り{addRubyText("合","あ")}いがたくさんいる。</>,
        <>{addRubyText("外国人","がいこくじん")}ともっと{addRubyText("知","し")}り{addRubyText("合","あ")}いになりたい。</>,
        <>{addRubyText("外国人","がいこくじん")}と{addRubyText("知","し")}り{addRubyText("合","あ")}えて，{addRubyText("嬉","うれ")}しい。</>
      ]
    }
  ]

  return <>
    <h2>ビデオ{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}1 : Authentic - medium<br />
    </h2>
    Watch the video clip and choose the answer that matches the content of
    the clip.<br />
    <small>*Click "SHOW" if you need to refer to the transcript</small><br />
    <br />
    <table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>
          <div style={{textAlign: "left"}}> </div>
    {addVideoPlayer("4.5.pl0.1.toshiko.mp4")}</td>
          <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>&nbsp;Transcript
    <Hideable content={transcript}/><br />
    ＊{addRubyText("知","し")}り{addRubyText("合","あ")}い acquaintance<br />
    ＊{addRubyText("年末","ねんまつ")} year end<br />
    ＊{addRubyText("来","き")}ゃはった (Kansai honorific – refer to Ch4.L3.G1)<br />
    ＊２、３{addRubyText("日","にち")} two to three days<br />
    ＊{addRubyText("付","つ")}き{addRubyText("合","あ")}い association<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>トシコ (Nara)</td>
        </tr>
      </tbody>
    </table>
    <br />
    <QandAForm qaObjects={qaObjects}/>
  </>;
}

export default V1;
