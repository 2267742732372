
import React, { useState } from "react";

export const Hideable = (props) => {
  const [hidden, setHidden] = useState(true);
  if (hidden === true) {
    return <div onClick={() => setHidden(!hidden)}>
      SHOW
    </div>
  } else {
    return <div onClick={() => setHidden(!hidden)}>
      {props.content}
    </div>
  }
}
