import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";

const V11 = () => {
  const transcript = <>
    やっぱし{addRubyText("田舎","いなか")}やと{addRubyText("物","もの")}が{addRubyText("何","なん")}でもあるわな，それ{addRubyText("一","ひと")}つ。でそれ{addRubyText("食","た")}べられるし。でやっぱし、{addRubyText("関東","かんとう")}{addRubyText("行","い")}くと物がないさかい，やっぱしその、お{addRubyText("金","かね")}だして，な、よその{addRubyText("品物","しなもん")}、{addRubyText("全部","ぜんぶ")}<span
    style={{textDecoration: "underline"}}>{addRubyText("買","か")}わんならん</span>
    (Grammar 3 extra 1)し。ほの、ここらやとやっぱし、あの、{addRubyText("山","やま")}にあるやつでも{addRubyText("野","の")}にあるやつでも、とって食べられっしよ。
  </>;

  const qaObjects = [
    {
      question: <span>Q: What is the difference between life in the countryside in Kansai and in the Kanto metropolitan area?</span>,
      answersCorrect: [
        <>In the Kanto area you must buy all the goods, whereas in the countryside you can obtain all kinds of edible stuff from nature.</>
      ],
      answersIncorrect: [
        <>
          In the Kanto area you can buy anything, whereas in the countryside not
          all the goods are available nearby and you have to take the trouble to
          go buy them elsewhere.
        </>,
        <>
          In the Kanto area you must buy all the goods, where as in the
          countryside you can grow all kinds of edible stuff in the field.
        </>
      ]
    }
  ];

  return <>
    <h2>ビデオ{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}１１: Authentic - medium<br />
    </h2>
    Watch the video clip and choose the answer that matches the content of
    the clip. <br />
    <small>*Click "SHOW" if you need to refer to the transcript.</small><br />
    <br />
    Matsuda-san is comparing life in the countryside in Kansai with life
    in the Kanto (Tokyo) metropolitan area. <br />
    <table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>{addVideoPlayer("4.2.4.p.1.matsuda.mp4")}</td>
          <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>Transcript
            <Hideable content={transcript}/><br />
            ＊{addRubyText("田舎","いなか")} countryside<br />
            ＊よそ elsewhere, other place<br />
            ＊{addRubyText("品物","しなもん")}(Kansai)＝{addRubyText("品物","しなもの")} goods<br />
            ＊{addRubyText("野","の")}field
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>マツダさん (Shiga)</td>
        </tr>
      </tbody>
    </table>
    <br />
    <QandAForm qaObjects={qaObjects}/>
  </>;
}

export default V11;
