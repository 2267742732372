import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";

const Conversations = () => {
  const kansaiTranscript1 = <>
    <br />
    <ol className="transcript">
      <li><small>A: <span style={{fontWeight: "bold"}}>あのえ</span>いが、<span
        style={{fontWeight: "bold"}}>おもしろ</span>かったぁ？</small></li>
      <li><small>B: う<span style={{fontWeight: "bold"}}>う</span>ん、<span
        style={{fontWeight: "bold"}}>な</span>ごうて<span style={{fontWeight: "bold"}}>や
        や</span>こしい<span style={{fontWeight: "bold"}}>は</span>なしやのに、さ<span
        style={{fontWeight: "bold"}}>い</span>ごはしょー<span
        style={{fontWeight: "bold"}}>も</span>ないし。</small></li>
      <li><small>A: <span style={{fontWeight: "bold"}}>そ</span>うなん？ざっしで<span
        style={{fontWeight: "bold"}}>おもし</span>ろい<span style={{fontWeight: "bold"}}>ゆ
        うて</span>たけど。</small></li>
      <li><small>B: いっ<span style={{fontWeight: "bold"}}>こ</span>も<span
        style={{fontWeight: "bold"}}>おもしろな</span>かったわ。ほん<span
        style={{fontWeight: "bold"}}>ま</span>、おかね<span style={{fontWeight: "bold"}}>か
        えしてほ</span>しわ。</small></li>
      <li><small>A: せこ<span style={{fontWeight: "bold"}}>〜</span>。</small></li>
    </ol>
  </>;

  const standardTranscript1 = <>
    <br />
    <ol className="transcript">
      <li><small>A: あの{addRubyText("映画","えいが")}、{addRubyText("面白","おもしろ")}かった？</small></li>
      <li><small>B: ううん、{addRubyText("長","なが")}くて{addRubyText("複雑","ふくざつ")}な{addRubyText("話","はなし")}なのに、{addRubyText("最後","さいご")}はつまらないし。</small></li>
      <li><small>A: そうなの？{addRubyText("雑誌","ざっし")}で面白いって{addRubyText("言","い")}ってたけど。</small></li>
      <li><small>B: {addRubyText("全然","ぜんぜん")}面白くなかったよ。ほんとに、お{addRubyText("金","かね")}{addRubyText("返","かえ")}してほしいよ。</small></li>
      <li><small>A: けちね〜。</small></li>
    </ol>
  </>;

  const kansaiTranscript2 = <>
    <br />
    <ol className="transcript">
      <li><small>A: <span style={{fontWeight: "bold"}}>かいもん</span>、<span
        style={{fontWeight: "bold"}}>い</span>かへん？</small></li>
      <li><small>B: <span style={{fontWeight: "bold"}}>さ</span>ぶいし、<span
        style={{fontWeight: "bold"}}>やめとく</span>。</small></li>
      <li><small>A: きょ<span style={{fontWeight: "bold"}}>う</span>、<span
        style={{fontWeight: "bold"}}>さ</span>むいことあ<span
        style={{fontWeight: "bold"}}>ら</span>へんで。</small></li>
      <li><small>B: <span style={{fontWeight: "bold"}}>け</span>ど、<span
        style={{fontWeight: "bold"}}>でかけん</span>の、<span
        style={{fontWeight: "bold"}}>めんどく</span>さいもん。</small></li>
      <li><small>A: な<span style={{fontWeight: "bold"}}>に</span>、しょー<span
        style={{fontWeight: "bold"}}>も</span>ない<span style={{fontWeight: "bold"}}>こ</span>と<span
        style={{fontWeight: "bold"}}>ゆうて</span>んの！</small></li>
      <li><small>B: おねが<span style={{fontWeight: "bold"}}>い</span>、<span
        style={{fontWeight: "bold"}}>いく</span>にゃったら、<span
        style={{fontWeight: "bold"}}>ぎゅうにゅうこうてきて</span>。</small></li>
      <li><small>A: ずっこ<span style={{fontWeight: "bold"}}>〜</span>。</small></li>
    </ol>
  </>;

  const standardTranscript2 = <>
    <br />
    <ol className="transcript">
      <li><small>A: {addRubyText("買","か")}い{addRubyText("物","もの")}、{addRubyText("行","い")}かない？</small></li>
      <li><small>B: {addRubyText("寒","さむ")}いから、やめとく。</small></li>
      <li><small>A:{addRubyText("今日","きょう")}は寒くないよ。</small></li>
      <li><small>B: けど{addRubyText("出","で")}かけるの、{addRubyText("面倒","めんどう")}だもん。</small></li>
      <li><small>A: {addRubyText("何","なに")}つまらないことを{addRubyText("言","い")}ってるの！</small></li>
      <li><small>B: お{addRubyText("願","ねが")}い、行くんだったら、{addRubyText("牛乳","ぎゅうにゅう")}買ってきて。</small></li>
      <li><small>A: ずるいわね。</small></li>
    </ol>
  </>;

  const kansaiTranscript3 = <>
    <br />
    <ol className="transcript">
      <li><small>A: <span style={{fontWeight: "bold"}}>この</span>ス<span
        style={{fontWeight: "bold"}}>テ</span>ーキ、おい<span
        style={{fontWeight: "bold"}}>し</span>いなあ。<span style={{fontWeight: "bold"}}>や
        ら</span>かいし。</small></li>
      <li><small>B: た<span style={{fontWeight: "bold"}}>こ</span>うて<span
        style={{fontWeight: "bold"}}>う</span>まいのはあたりま<span
        style={{fontWeight: "bold"}}>え</span>やろ。</small></li>
      <li><small>A: た<span style={{fontWeight: "bold"}}>か</span>な<span
        style={{fontWeight: "bold"}}>い</span>で。これ<span style={{fontWeight: "bold"}}>も</span>ろ
        てんもん。</small></li>
      <li><small>B: <span style={{fontWeight: "bold"}}>あ</span>、<span
        style={{fontWeight: "bold"}}>それ</span>、はよ<span style={{fontWeight: "bold"}}>い
        わんか</span>い。、ほんま、<span style={{fontWeight: "bold"}}>う</span>まい<span
        style={{fontWeight: "bold"}}>な</span>あ。</small></li>
      <li><small>A: もう〜<span style={{fontWeight: "bold"}}>みみ</span>っちいて<span
        style={{fontWeight: "bold"}}>かな</span>わんわ。</small></li>
    </ol>
  </>;

  const standardTranscript3 = <>
    <br />
    <ol className="transcript">
      <li><small>A: このステーキ、おいしいね。やわらかいし。</small></li>
      <li><small>B: {addRubyText("高","たか")}くておいしいのは{addRubyText("当","あ")}たり{addRubyText("前","まえ")}だろ！</small></li>
      <li><small>A: 高くないわよ。これ、もらったんだもの。</small></li>
      <li><small>B: それを{addRubyText("早","はや")}く{addRubyText("言","い")}わないか！、、{addRubyText("本当","ほんとう")}にうまいなあ。</small></li>
      <li><small>A: もう〜けちでかなわないわね。</small></li>
    </ol>
  </>;

  const kansaiTranscript4 = <>
    <br />
    <ol className="transcript">
      <li><small>A: <span style={{fontWeight: "bold"}}>しゅくだい</span>、<span
        style={{fontWeight: "bold"}}>はやいことしてしまいな</span>さい！</small></li>
      <li><small>B: <span style={{fontWeight: "bold"}}>むずか</span>して、<span
        style={{fontWeight: "bold"}}>わか</span>らへんねん。お<span
        style={{fontWeight: "bold"}}>か</span>あさん、<span style={{fontWeight: "bold"}}>お
        しえて</span>ぇよ。</small></li>
      <li><small>A: え？<span style={{fontWeight: "bold"}}>あ</span>、<span
        style={{fontWeight: "bold"}}>あ</span>たま、<span style={{fontWeight: "bold"}}>いっ
        た〜</span>。</small></li>
      <li><small>B: いっつも<span style={{fontWeight: "bold"}}>う</span>まいことに
        げる<span style={{fontWeight: "bold"}}>な</span>あ。</small></li>
    </ol>
  </>;

  const standardTranscript4 = <>
    <br />
    <ol className="transcript">
      <li><small>A: {addRubyText("宿題","しゅくだい")}、{addRubyText("早","はや")}くしてしまいなさい！</small></li>
      <li><small>B: {addRubyText("難","むずか")}しくて{addRubyText("分","わ")}からないの。お{addRubyText("母","かあ")}さん、{addRubyText("教","おし")}えてよ。</small></li>
      <li><small>A: え？あ、{addRubyText("頭","あたま")}、{addRubyText("痛","いた")}〜い。</small></li>
      <li><small>B: いつも、うまく{addRubyText("逃","に")}げるわねえ。</small></li>
    </ol>
  </>;


  return <>
    <h2>{addRubyText("基本会話","きほんかいわ")} (Core Conversations)</h2>
    <hr />
    <h3>{addRubyText("会話","かいわ")}１ : How was the movie for B?</h3>
    *A=first speaker, B=second speaker<br />
    *Click "SHOW" for transcripts<br />
    <br />
    (A and B are friends, B just went see a movie, but...)
    <br />
    <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}} className="video" colSpan="1" rowSpan="2">
            {addVideoPlayer("3.4.cc.1.mp4")}<br />
          </td>
          <td style={{verticalAlign: "top"}} className="transcriptcell">
            Kansai transcript
            <Hideable content={kansaiTranscript1} />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}} className="transcriptcell">
            Standard transcript
            <Hideable content={standardTranscript1} />
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    <small>＊ややこしい：{addRubyText("複雑","ふくざつ")}な complicated<br />
    ＊せこい：けち tight in money<br />
    ＊{addRubyText("一個","いっこ")}も~neg：{addRubyText("全然","ぜんぜん")}~neg</small><br />
    <br />
    <h3>{addRubyText("会話","かいわ")}2 : Are they going for shopping together?</h3>
    (A and B are roommates at a dormitory)<br />
    <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2"
    cellSpacing="2">
      <tbody>
        <tr>
          <td className="video" colSpan="1" rowSpan="2" style={{verticalAlign: "top", textAlign: "center"}}>
            &nbsp;{addVideoPlayer("3.4.cc.2.mp4")}<br />
          </td>
          <td style={{verticalAlign: "top"}} className="transcriptcell">
            Kansai transcript
            <Hideable content={kansaiTranscript2} />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}} className="transcriptcell">
            Standard transcript
            <Hideable content={standardTranscript2} />
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    <small>＊めんどくさい：{addRubyText("面倒","めんどう")}な troublesome<br />
    ＊{addRubyText("牛乳","ぎゅうにゅう")} milk<br />
    ＊ずっこい：ずるい sly<br />
    </small><br />
    <h3>{addRubyText("会話","かいわ")}3 : Is B enjoying the steak?</h3>
    (Suppose B is A's husband! They are at the dinner table...)<br />
    <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td className="video" colSpan="1" rowSpan="2" style={{verticalAlign: "top", textAlign: "center"}}>
            {addVideoPlayer("3.4.cc.3.mp4")}<br />
          </td>
          <td style={{verticalAlign: "top"}} className="transcriptcell">
            Kansai transcript
            <Hideable content={kansaiTranscript3} />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}} className="transcriptcell">
            Standard transcript
            <Hideable content={standardTranscript3} />
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    <small>＊{addRubyText("当","あ")}たり{addRubyText("前","まえ")}&nbsp;&nbsp; common; normal; natural; reasonable<br />
    ＊{addRubyText("言","い")}わんかい（な）！：{addRubyText("言","い")}わないか！Why don't you say so!?<br />
    ＊うまい　blunt equivalent of おいしい<br />
    ＊みみっちい：けちな tight in money<br />
    ＊かなわん：{addRubyText("敵","かな")}わない cannot win; stand; bear</small><br />
    <br />
    <h3>{addRubyText("会話","かいわ")}4 : Mother can teach anything to her child..., can't she?</h3>
    (A is B's mother. B hasn't finished her homework yet...)<br />
    <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td className="video" colSpan="1" rowSpan="2" style={{verticalAlign: "top", textAlign: "center"}}>
            {addVideoPlayer("3.4.cc.4.mp4")}
          </td>
          <td style={{verticalAlign: "top"}} className="transcriptcell">
            Kansai transcript
            <Hideable content={kansaiTranscript4} />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}} className="transcriptcell">
            Standard transcript
            <Hideable content={standardTranscript4} />
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    <small>＊{addRubyText("逃","に")}げる run away; avoid</small><br />
    <br />
    <hr />
    <h3>{addRubyText("理解度","りかいど")}チェック(Check-up) <br />
    </h3>
    Figure out the Kansai-peculiar grammar and expressions appearing
    in the video clips. Refer to the standard and Kansai transcripts if necessary.
    <br />
    <br />
    <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
    </table>
    <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
      <tbody>
      </tbody>
    </table>
    <div style={{textAlign: "center"}}>
    <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr align="center">
          <td style={{verticalAlign: "top"}}>
            Check-up<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            Answer<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            1) What is the negative form of adjectives in Kansai-ben? (there are variations)
          </td>
          <td style={{verticalAlign: "top"}}>
            <Hideable content={<>Adj(stem)+ない、Adjいことあらへん</>} />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            2) What is the Kansai-equivalent of {addRubyText("長","なが")}くて? 
          </td>
          <td style={{verticalAlign: "top"}}>
            <Hideable content={<>{addRubyText("長","なご")}うて</>} />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            3) Is TE-form of adjectives in Kansai-ben the same as the standard?<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <Hideable content={<>No, euphonic change seems observed such as&nbsp; {addRubyText("長","なが")}くて
              →{addRubyText("長","なご")}うて,&nbsp;{addRubyText("高","たか")}くて→{addRubyText("高","たこ")}うて,&nbsp; みみっちくて→みみっちいて
            </>} />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            4) What is the equivalent of はよ in the standard? </td>
          <td style={{verticalAlign: "top"}}><Hideable content={<>{addRubyText("早","はや")}く</>} /></td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            5) What are the equivalents of {addRubyText("早","はや")}いこと／うまいこと in the standard? </td>
          <td style={{verticalAlign: "top"}}><Hideable content={<>{addRubyText("早","はや")}く／うまく</>} /></td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            6) How would you say {addRubyText("宿題","しゅくだい")}が{addRubyText("早","はや")}くできた in Kansai-ben? (Two possibilities)</td>
          <td style={{verticalAlign: "top"}}>
            <Hideable content={<>{addRubyText("宿題","しゅくだい")}がはよできた／{addRubyText("早","はや")}いことできた</>} />
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    <hr />
    <h3 style={{textAlign: "left"}}>{addRubyText("関西","かんさい")}{addRubyText("語彙","ごい")}チェック(Kansai Vocabulary check-up) </h3>
    <table style={{textAlign: "left", width: "60%"}} border="1" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>Kansai<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>Standard<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>ややこしい<br />
          </td>
          <td style={{verticalAlign: "top"}}><Hideable content={<>{addRubyText("複雑","ふくざつ")}な</>} />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>いっこも<br />
          </td>
          <td style={{verticalAlign: "top"}}><Hideable content={<>{addRubyText("全然","ぜんぜん")}</>} />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>せこい<br />
          </td>
          <td style={{verticalAlign: "top"}}><Hideable content={<>けちな</>} />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>さぶい<br />
          </td>
          <td style={{verticalAlign: "top"}}><Hideable content={<>{addRubyText("寒","さむ")}い</>} />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>めんどくさい<br />
          </td>
          <td style={{verticalAlign: "top"}}><Hideable content={<>{addRubyText("面倒","めんどう")}な</>} />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>ずっこい<br />
          </td>
          <td style={{verticalAlign: "top"}}><Hideable content={<>ずるい</>} />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>やらかい<br />
          </td>
          <td style={{verticalAlign: "top"}}><Hideable content={<>{addRubyText("柔","やわ")}らかい</>} />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>みみっちい<br />
          </td>
          <td style={{verticalAlign: "top"}}><Hideable content={<>けちな</>} />
          <br />
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    </div>
  </>;
}

export default Conversations;
