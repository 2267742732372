import React from "react";
import { addRubyText, addImage } from "common/util/generalUtil";
import { Link } from "react-router-dom";
import { Page } from "home/page";

const Practice = () => {
  return <>
    <h2>{addRubyText("練習","れんしゅう")} (Practice)</h2>
    <br/>
    <table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td colSpan="1" rowSpan="1" style={{verticalAlign: "top"}}>
          <ul>
            <li>
              <Link to={Page.BasicGrammar.VerbTeForm.Practice.P1}>
                {addRubyText("練習","れんしゅう")}1 : て form
              </Link>
            </li>
            <li>
              <Link to={Page.BasicGrammar.VerbTeForm.Practice.P1}>
                {addRubyText("練習","れんしゅう")}2 : Request &amp; 〜たげる／たる
              </Link>
            </li>
            <li>
              <Link to={Page.BasicGrammar.VerbTeForm.Practice.P1}>
                {addRubyText("練習","れんしゅう")}3 : Request: 〜てくれへん
              </Link>
            </li>
            <li>
              <Link to={Page.BasicGrammar.VerbTeForm.Practice.P1}>
                {addRubyText("練習","れんしゅう")}4 : Negative て form : 〜んと
              </Link>
            </li>
            <li>
              <Link to={Page.BasicGrammar.VerbTeForm.Practice.P1}>
                {addRubyText("練習","れんしゅう")}5 : Negative Request: 〜んといて
              </Link>
            </li>
          </ul>
          </td>
          <td colSpan="1" rowSpan="1" style={{verticalAlign: "top", textAlign: "center"}}>
            {addImage("jishujinja.jpg")}
            <br/>
            <small>
              地主神社 Jishu Shrine (Kyoto)
            </small>
            <br/>
          </td>
        </tr>
        <tr align="center">
        </tr>
      </tbody>
    </table>
  </>;
}

export default Practice;