import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";

const V3 = () => {
  const transcript = <>
    うん、あの、{addRubyText("自分","じぶん")}ら{addRubyText("友達","ともだち")}と{addRubyText("行","い")}った{addRubyText("時","とき")}は{addRubyText("話","はな")}してるけども、やっぱしおっきい{addRubyText("声","こえ")}とか{addRubyText("他","ほか")}の{addRubyText("人","ひと")}に{addRubyText("聞","き")}こえるような{addRubyText("場合","ばあい")}は、あんまり{addRubyText("関西弁","かんさいべん")}{addRubyText("丸出","まるだ")}しにせん(Grammar
    1)ようにしてたな。（interviewer -ちょっと{addRubyText("恥","は")}ずかし？）そうそう。
  </>;

  const qaObjects = [
    {
      question: <span>Q1: How did she talk when she was in Tokyo? Choose all that apply.</span>,
      answersCorrect: [
        <>She spoke in Kansai-ben when talking with her friends from Kansai.</>,
        <>She tried not to use a heavy Kansai-ben accent when others could hear her talk.</>
      ],
      answersIncorrect: [
        <>She spoke in a heavy Kansai-ben accent even when others could hear her talk.</>,
        <>She tried not to speak in Kansai-ben at all when talking with her friends from Kansai.</>
      ]
    },
    {
      question: <span>Q2: Why did she talk in that way (answer of Q1)?</span>,
      answersCorrect: [
        <>She felt a bit ashamed of showing her accent in the presence of standard-Japanese speakers.</>
      ],
      answersIncorrect: [
        <>She was so proud of her Kansai accent, she didn’t care about the presence of standard-Japanese speakers.</>,
        <>She felt ashamed of her friend’s accent, so she didn’t want to to be heard by standard-Japanese speakers.</>
      ]
    }
  ];

  return <>
    <h2>ビデオ{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}3: Authentic - medium<br/></h2>
    Watch the video clip and choose the answer that matches the content of
    the clip. <br/>
    <small>*Click "SHOW" if you need to refer to the transcript</small><br/>
    <br/>
    <div className="checkupquestion">
    <table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2"
    cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>
            {addVideoPlayer("3.1.a.pl0.3.toshiko.mp4")}
          </td>
          <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>
            Transcript
            <Hideable content={transcript} />
            <br/>
            ＊{addRubyText("自分","じぶん")}ら(Kansai) = {addRubyText("私","わたし")}たち we/us
            <br/>
            ＊やっぱし(Kansai) = やっぱり、やはり
            <br/>
            ＊{addRubyText("場合","ばあい")} case
            <br/>
            ＊{addRubyText("丸出","まるだ")}し exposed
            <br/>
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>
            トシコ (Nara) 
          </td>
        </tr>
      </tbody>
    </table>
    <br/>
    <QandAForm qaObjects={qaObjects} />
    </div>
  </>;
}

export default V3;
