import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";

const V14 = () => {
  const transcript = <>
      そやね、{addRubyText("家","うち")}で{addRubyText("食","た")}べる{addRubyText("方","ほう")}がええね。うん。やっぱりね{addRubyText("安心","あんしん")}するし，その{addRubyText("好","す")}きな{addRubyText("物","もの")}を，好きな物を
      ていうかなあ、その{addRubyText("気兼","きが")}ねなくって{addRubyText("言","ゆ")}うの？{addRubyText("格好","かっこう")}も<span
      style={{textDecoration: "underline"}}>{addRubyText("気","き")}にせんでええ (</span>Grammar 1)
      し，もう食べ{addRubyText("方","かた")}もね、あまり<span style={{textDecoration: "underline"}}>気い{addRubyText("使","つか")}わんでも
      ええ</span> (Grammar 1)
      言うのでまあ，{addRubyText("楽","らく")}や、というところやないかなあ。
  </>;


  const qaObjects = [
    {
      question: <span>Q: Why does he prefer eating at home? Choose all the applicable reasons.</span>,
      answersCorrect: [
        <>He feels ease.</>,
        <>He can eat whatever he wants free of constraint.</>,
        <>He doesn’t have to worry about what to wear.</>,
        <>He doesn’t have to care how he eats.</>
      ],
      answersIncorrect: [
        <>He can eat with whomever he likes.</>,
        <>He thinks homemade meals are safer.</>,
        <>He doesn’t have to care about people he eats with.</>
      ]
    }
  ];

  return <>
    <h2>ビデオ{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}１４: Authentic - medium<br />
    </h2>
    Watch the video clip and choose the answer that matches the content of
    the clip.<br />
    <small>*Click "SHOW" if you need to refer to the transcript.</small><br />
    <br />
    Akira was asked whether he prefers eating out or eating at home.<br />
    <table style={{textAlign: "left", width: "100%"}} border="0"cellPadding="2"
    cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>&nbsp;{addVideoPlayer("4.2.1.p.1.akira.mp4")} </td>
          <td colSpan="1" rowSpan="2" style={{verticalAlign: "top", textAlign: "left"}}>Transcript
            <Hideable content={transcript}/>
            <br />
            ＊{addRubyText("気兼","きが")}ね する be afraid of
            bothering, feel constraint <br />
            ＊{addRubyText("格好","かっこう")}form, shape
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>アキラ (Osaka) </td>
        </tr>
      </tbody>
    </table>
    <br />
    <QandAForm qaObjects={qaObjects}/>
  </>;
}

export default V14;
