import React from "react";
import { addRubyText, addAudio } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";

const P1 = () => {
  const transcript = <>
    <ol className="transcript">
      <li>A: 晩ご飯はカレー？</li>
      <li>B: ううん、カレーじゃないよ。</li>
    </ol>
  </>;

  return <>
    <h2>{addRubyText("練習","れんしゅう")}1 : N+Copula (non-past negative)</h2>
    Form a question and an answer for each cue according to
    a model. You may listen to the question, tryto answer it, and check
    your answer. *Answers vary; the recorded answers indicate those
    commonly
    used.<br />
    <small>*Mouse over or click on the speaker icon for sound</small><br />
    <br />
    <span style={{fontWeight: "bold"}}>Model</span> (Cue: {addRubyText("晩","ばん")}ご{addRubyText("飯","はん")}／カレー)<br />
    &nbsp;&nbsp;&nbsp; A: 晩ご飯、カレー？<br />
    &nbsp;&nbsp;&nbsp; B: ううん、カレー<span style={{textDecoration: "underline"}}>ちゃ
    う</span>で。（カレー<span style={{textDecoration: "underline"}}>やあらへん</span>で）
    {addAudio("3.3.p.1.mp3")}<br />
    <br />
    Point: Nじゃないー＞Nやあらへん／やない／ちゃう (refer to Grammar 1)<br />
    <br />
    ST transcript
    <br />
    <Hideable content={transcript} />
    <br />
    <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>Cue<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>A (Question)<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>B (Answer)<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>{addRubyText("晩","ばん")}ご{addRubyText("飯","はん")}／カレー<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.3.p.1.1.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.3.p.1.1.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>{addRubyText("明日","あした")}／{addRubyText("雪","ゆき")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.3.p.1.2.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.3.p.1.2.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>{addRubyText("明日","あした")}／{addRubyText("日曜日","にちようび")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.3.p.1.3.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.3.p.1.3.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>{addRubyText("薬","くすり")}／{addRubyText("好","す")}き<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.3.p.1.4.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.3.p.1.4.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>{addRubyText("先生","せんせい")}／きれい<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.3.p.1.5.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.3.p.1.5.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>アパート／{addRubyText("便利","べんり")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.3.p.1.6.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.3.p.1.6.b.mp3")}<br />
          </td>
        </tr>
      </tbody>
    </table>
  </>;
}

export default P1;
