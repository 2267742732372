import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";

const V10 = () => {
  const transcript = <>
    そらねえ，あの、{addRubyText("相手","あいて")}がよっぽど{addRubyText("高貴","こうき")}な{addRubyText("人","ひと")}っちゅうか、{addRubyText("上","うえ")}の人やったら、おやすみや
    さいとこう{addRubyText("言","い")}いますね。はい。高貴な人ん{addRubyText("時","とき")}は。ま，ミツエさんにはそう言わへんけど。、、え？ま〜ね〜高貴な人は、ちょっといやあらへ
    んさかいに、あんまそんな{addRubyText("言葉","ことば")}言うたことないけど、ね、お{addRubyText("客","きゃく")}さんの{addRubyText("中","なか")}でやっぱりちょっと{addRubyText("丁寧","ていねい")}に<span
    style={{textDecoration: "underline"}}>言わない
    かん</span> (Grammar 3) 人やったら {addRubyText("おやすみなさい")}って、{addRubyText("なさい")}って{addRubyText("最後","さいご")}に言います、はい。
  </>;

  const qaObjects = [
    {
      question: <span>Q: To whom does he use “おやすみなさい”?</span>,
      answersCorrect: [
        <>To noble people like some of his patrons, but not to Mitsue-san.</>
      ],
      answersIncorrect: [
        <>To noble people like Mitsue-san, but not to his patrons.</>,
        <>He would use it if there were noble people, but he does not know any.</>
      ]
    }
  ];

  return <>
    <h2>ビデオ{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}１０: Authentic - medium<br />
    </h2>
    Watch the video clip and choose the answer that matches the content of
    the clip.<br />
    <small>*Click "SHOW" if you need to refer to the transcript.</small><br />
    <br />
    Kusaka-san is talking about to whom he would say "おやすみなさい." <br />
    <table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>{addVideoPlayer("4.2.3.p.6.kusaka.mp4")} </td>
          <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>
            Transcript
            <Hideable content={transcript}/><br />
            ＊{addRubyText("相手","あいて")} the other party<br />
            ＊{addRubyText("高貴","こうき")} noble<br />
            ＊いやあらへん=いやはらへん　~やはる(honorific, refer to Ch4 L4 G1)<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>クサカさん
    (Shiga/Kyoto)</td>
        </tr>
      </tbody>
    </table>
    <br />
    <QandAForm qaObjects={qaObjects}/>
  </>;
}

export default V10;
