import React from "react";
import { addRubyText, addImage, addAudio } from "common/util/generalUtil";

const Films = () => {
  return (
    <>
      <h2>関西弁の映画 Kansai-ben Films</h2>
      <table style={{textAlign: "center", width: "30%"}} align="right" border="0" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addImage("7.movie.jpg")}
          </td>
        </tr>
        </tbody>
      </table>

      <p>
        As you notice from the list on this page, there are quite a few
        Japanese live action films in which Kansai-ben is used. This list is
        incomplete, but we will keep adding to it as we view more films.
      </p>
      <p>
        As it is the case with anime and manga, some of the films are set in
        the Kansai region and thus most characters speak Kansai-ben. In
        others, however, Kansai-ben is used to define certain
        characteristics of particular roles, such as laid-back, easy-going,
        etc.
      </p>
      <p>
        However, there are films in which Kansai-ben seems to be preferred to standard Japanese. For example,
        Kansai-ben is often used in yakuza (Japanese gangster) films such as <i>Cops vs.Thugs </i>
        （『{addRubyText("県警対組織暴力","けいんけいたいそしきぼうりょく")}』）
        directed by Kinji Fukasaku in 1975. The story takes place in an imaginary location
        somewhere in Japan but most cast members speak Kansai-ben or Hiroshima-ben. As a ‘non-native’
        speaker of Kansai-ben, it sounds more suitable for a yakuza to say ‘<i>Dare ni kuchi kiiton nya</i>?’
        （「誰に口きいとんにゃ？」{addAudio("7.2.1.mp3")}, 'who do you think you are talking to?' ）rather
        than ‘<i>Dare ni mukatte hanashite iru to omotte iru n da?</i>’（「誰に向かって話していると
        思っているんだ？」{addAudio("7.2.1.s.mp3")}）in standard Japanese.
        The Kansai-ben version sounds much more forceful, and thus more suitable in this particular situation.
      </p>
      <p>
        It may be interesting to observe what kind of characters and in what situations
        Kansai-ben is spoken in Japanese films.
      </p>
      <p style={{textAlign: "right"}}>(by "Tokyoite" Mayumi Ishida, Dartmouth College)</p>

      <p>*The list was updated on October 2016.</p>
      <p>
        **If you have more information, please contact Shingu at <a href="mailto:ikueshingu@gmail.com">
        ikueshingu@gmail.com</a>.
        Your input would be greatly appreciated.
      </p>

      <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{textAlign: "center"}}>
            Japanese Title
          </td>
          <td>
            English Title
          </td>
          <td style={{textAlign: "center"}}>
            Year
          </td>
          <td>
            Director
          </td>
          <td style={{textAlign: "center"}}>
            Information
          </td>
        </tr>
        <tr>
          <td valign="top">
            {addRubyText("夫婦善哉","めおとぜんざい")}
          </td>
          <td valign="top">
            Hooray for Marriage ; Sweet Beans for Two
          </td>
          <td valign="top">
            1955
          </td>
          <td valign="top">
            Shiro Toyoda
          </td>
          <td valign="top" />
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addRubyText("河内","かわち")}カルメン
          </td>
          <td style={{verticalAlign: "top"}}>
            Carmen from Kawachi
          </td>
          <td style={{verticalAlign: "top"}}>
            1966
          </td>
          <td style={{verticalAlign: "top"}}>
            Seijun Suzuki
          </td>
          <td style={{verticalAlign: "top"}} />
        </tr>
        <tr>
          <td>{addRubyText("県警対組織暴力","けいんけいたいそしきぼうりょく")}</td>
          <td>
            Cops vs. Thugs
          </td>
          <td>
            1975
          </td>
          <td>
            Kinji Fukasaku
          </td>
          <td>
            (Yakuza movie)
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            ガキ{addRubyText("帝国","ていこく")}
          </td>
          <td style={{verticalAlign: "top"}}>
            Empire of Kids
          </td>
          <td style={{verticalAlign: "top"}}>
            1981
          </td>
          <td style={{verticalAlign: "top"}}>
            Kazuyuki Izutsu
          </td>
          <td style={{verticalAlign: "top"}} />
        </tr>
        <tr>
          <td>
            じゃりん子チエ
          </td>
          <td>
            Chie the Brat
          </td>
          <td>
            1981
          </td>
          <td>
            Isao Takahata
          </td>
          <td />
        </tr>
        <tr>
          <td>{addRubyText("泥","どろ")}の{addRubyText("河","かわ")}</td>
          <td>
            Muddy River
          </td>
          <td>
            1981</td>
          <td>
            Kohei Oguri
          </td>
          <td />
        </tr>
        <tr>
          <td>{addRubyText("道頓堀川","どうとんぼりがわ")}
          </td>
          <td>Dohtonbori River</td>
          <td>1982
          </td>
          <td>Kinji Fukasaku
          </td>
          <td>
          </td>
        </tr>
        <tr>
          <td>{addRubyText("細雪","ささめゆき")}
          </td>
          <td>The Fine Snow/The Makioka Sisters</td>
          <td>1983</td>
          <td>Kon Ichikawa
          </td>
          <td>
          </td>
        </tr>
        <tr>
          <td valign="top">{addRubyText("唐獅子","からじし")}{addRubyText("株式","かぶしき")}{addRubyText("会社","がいしゃ")}
          </td>
          <td valign="top">
          </td>
          <td valign="top">1983
          </td>
          <td valign="top">Chisei Sone
          </td>
          <td valign="top">
          </td>
        </tr>
        <tr>
          <td valign="top">{addRubyText("彩","いろど")}り{addRubyText("河","がわ")}
          </td>
          <td valign="top">The Street of Desire
          </td>
          <td valign="top">1984
          </td>
          <td valign="top">Haruhiko Miura
          </td>
          <td valign="top">
          </td>
        </tr>
        <tr>
          <td>{addRubyText("極道","ごくどう")}の{addRubyText("妻","おんな")}たち
          </td>
          <td>Gokudo Wives : Wives of The Yakuza
          </td>
          <td>1986
          </td>
          <td>Hideo Gosha
          </td>
          <td>(Yakuza Movie)
          </td>
        </tr>
        <tr>
          <td>{addRubyText("極道","ごくどう")}の{addRubyText("妻","おんな")}たち 2
          </td>
          <td>Gokudo Wives 2
          </td>
          <td>1987
          </td>
          <td>Toru Dobashi
          </td>
          <td>(Yakuza Movie)
          </td>
        </tr>
        <tr>
          <td>{addRubyText("火垂","ほた")}るの{addRubyText("墓","はか")}
          </td>
          <td>The Grave of Fireflies</td>
          <td>1988
          </td>
          <td>Isao Takahata</td>
          <td>
          </td>
        </tr>
        <tr>
          <td valign="top">いこかもどろか
          </td>
          <td valign="top">
          </td>
          <td valign="top">1988
          </td>
          <td valign="top">Jiro Shono
          </td>
          <td valign="top">
          </td>
        </tr>
        <tr>
          <td>どついたるねん
          </td>
          <td>Knockout
          </td>
          <td>1989 </td>
          <td>Junji Sakamoto
          </td>
          <td>
          </td>
        </tr>
        <tr>
          <td>{addRubyText("極道","ごくどう")}の{addRubyText("妻","おんな")}たち {addRubyText("三代目姐","さんだいめあね")}
          </td>
          <td>Gokudo Wives 3: The third Generation Ane
          </td>
          <td>1989
          </td>
          <td>Yasuo Furuhata
          </td>
          <td>(Yakuza Movie)
          </td>
        </tr>
        <tr>
          <td>{addRubyText("極道","ごくどう")}の{addRubyText("妻","おんな")}たち　{addRubyText("最後","さいご")}の{addRubyText("戦","たたか")}い
          </td>
          <td>Gokudo Wives 4: Last War
          </td>
          <td>1990
          </td>
          <td>Kosaku Yamashita
          </td>
          <td>(Yakuza Movie)
          </td>
        </tr>
        <tr>
          <td>{addRubyText("王手","おうて")}</td>
          <td>Checkmate
          </td>
          <td> 1991</td>
          <td>Junji Sakamoto
          </td>
          <td>
          </td>
        </tr>
        <tr>
          <td>{addRubyText("新","しん")}{addRubyText("極道","ごくどう")}の{addRubyText("妻","おんな")}たち</td>
          <td>
          </td>
          <td>1991
          </td>
          <td>Sadao Nakajima
          </td>
          <td>(Yakuza Movie)
          </td>
        </tr>
        <tr>
          <td>{addRubyText("新","しん")}{addRubyText("極道","ごくどう")}の{addRubyText("妻","おんな")}たち　{addRubyText("覚悟","かくご")}しいや
          </td>
          <td>
          </td>
          <td>1993
          </td>
          <td>Kosaku Yamashita
          </td>
          <td>(Yakuza Movie)
          </td>
        </tr>
        <tr>
          <td>{addRubyText("新","しん")}{addRubyText("極道","ごくどう")}の{addRubyText("妻","おんな")}たち　{addRubyText("惚","ほ")}れら れたら{addRubyText("地獄","じごく")}</td>
          <td>
          </td>
          <td>1994
          </td>
          <td>Yasuo Furuhata
          </td>
          <td>(Yakuza Movie)
          </td>
        </tr>
        <tr>
          <td>{addRubyText("極道","ごくどう")}の{addRubyText("妻","おんな")}たち　{addRubyText("赤","あか")}い{addRubyText("絆","きずな")}
          </td>
          <td>
          </td>
          <td>1995
          </td>
          <td>Ikuo Sekimoto
          </td>
          <td>(Yakuza Movie)
          </td>
        </tr>
        <tr>
          <td valign="top">{addRubyText("二人","ふたり")}が{addRubyText("喋","しゃべ")}ってる。
          </td>
          <td valign="top">Two People Talking
          </td>
          <td valign="top">1995
          </td>
          <td valign="top">Isshin Inudou
          </td>
          <td valign="top">
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>{addRubyText("幻","まぼろし")}の{addRubyText("光","ひかり")}
          </td>
          <td style={{verticalAlign: "top"}}>Illusion / Maboroshi
          </td>
          <td style={{verticalAlign: "top"}}>1995
          </td>
          <td style={{verticalAlign: "top"}}>Hirokazu Koreeda
          </td>
          <td style={{verticalAlign: "top"}}>
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>ビリケン
          </td>
          <td style={{verticalAlign: "top"}}>Billiken
          </td>
          <td style={{verticalAlign: "top"}}>1996
          </td>
          <td style={{verticalAlign: "top"}}>Junji Sakamoto
          </td>
          <td style={{verticalAlign: "top"}}>
          </td>
        </tr>
        <tr>
          <td>{addRubyText("岸和田少年愚連隊","きしわだしょうねんぐれんたい")}
          </td>
          <td>Young Thugs/Innocent Blood</td>
          <td>1996
          </td>
          <td>Kazuyuki Izutsu
          </td>
          <td>
          </td>
        </tr>
        <tr>
          <td>{addRubyText("極道","ごくどう")}の{addRubyText("妻","おんな")}たち　{addRubyText("危険","きけん")}な{addRubyText("賭","か")}け</td>
          <td>
          </td>
          <td>1996
          </td>
          <td>Sadao Nakajima
          </td>
          <td>(Yakuza Movie)
          </td>
        </tr>
        <tr>
          <td>{addRubyText("萌","もえ")}の{addRubyText("朱雀","すざく")}
          </td>
          <td>The God Suzaku
          </td>
          <td>1997
          </td>
          <td>Naomi Kawase
          </td>
          <td>in Nara-ben</td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>ヒロイン！なにわボンバーズ
          </td>
          <td style={{verticalAlign: "top"}}>
          </td>
          <td style={{verticalAlign: "top"}}>1998
          </td>
          <td style={{verticalAlign: "top"}}>Mitsuhiro Mihara
          </td>
          <td style={{verticalAlign: "top"}}>
          </td>
        </tr>
        <tr>
          <td>{addRubyText("極道","ごくどう")}の{addRubyText("妻","おんな")}たち　{addRubyText("決着","けじめ")}
          </td>
          <td>Gang Wives: Decision
          </td>
          <td>1998
          </td>
          <td>Sadao Nakajima
          </td>
          <td>(Yakuza Movie)
          </td>
        </tr>
        <tr>
          <td>{addRubyText("大阪物語","おおさかものがたり")}
          </td>
          <td>Osaka Story</td>
          <td>1999
          </td>
          <td>Jun Ichikawa
          </td>
          <td>
          </td>
        </tr>
        <tr>
          <td valign="top">{addRubyText("新","しん")}{addRubyText("唐獅子","からじし")}{addRubyText("株式","かぶしき")}{addRubyText("会社","がいしゃ")}</td>
          <td valign="top">
          </td>
          <td valign="top">1999
          </td>
          <td valign="top">Yoichi Maeda
          </td>
          <td valign="top">
          </td>
        </tr>
        <tr>
          <td>{addRubyText("顔","かお")}</td>
          <td>Face</td>
          <td>2000
          </td>
          <td>Junji Sakamoto
          </td>
          <td>
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>あしたはきっと･･･</td>
          <td style={{verticalAlign: "top"}}>
          </td>
          <td style={{verticalAlign: "top"}}>2001
          </td>
          <td style={{verticalAlign: "top"}}>Mitsuhiro Mihara</td>
          <td style={{verticalAlign: "top"}}>
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>{addRubyText("桃源郷","とうげんきょう")}の{addRubyText("人々","ひとびと")}
          </td>
          <td style={{verticalAlign: "top"}}>Shangri-La
          </td>
          <td style={{verticalAlign: "top"}}>2002
          </td>
          <td style={{verticalAlign: "top"}}>Takashi Miike
          </td>
          <td style={{verticalAlign: "top"}}>
          </td>
        </tr>
        <tr>
          <td>きょうのできごと
          </td>
          <td>A day on the planet</td>
          <td>2003
          </td>
          <td>Isao Yukisada
          </td>
          <td>
          </td>
        </tr>
        <tr>
          <td>ゲロッパ
          </td>
          <td>Get up!
          </td>
          <td>2003
          </td>
          <td>Kazuyuki Izutsu
          </td>
          <td>
          </td>
        </tr>
        <tr>
          <td><a href="http://jozeetora.com/index_f.html">ジョゼと{addRubyText("虎","とら")}と{addRubyText("魚","さかな")}たち</a>
          </td>
          <td>Josee, the Tiger and the Fish
          </td>
          <td>2003
          </td>
          <td>Isshin Inudou
          </td>
          <td>Josee ( the main character, played by Chizuru Ikewaki)
            speaks Kansai-ben
          </td>
        </tr>
        <tr>
          <td><a href="http://www.shirous.com/canary/cast.html">カナリア</a>
          </td>
          <td>Canary
          </td>
          <td>2004
          </td>
          <td>Akihiko Shiota
          </td>
          <td>Yuki Niina (the main character, played by Mitsuki
            Tanimura) speaks Kansai-ben
          </td>
        </tr>
        <tr>
          <td>お{addRubyText("父","とう")}さ んのバックドロップ
          </td>
          <td>My Father’s Backdrop</td>
          <td>2004
          </td>
          <td>Toshio Lee
          </td>
          <td>
          </td>
        </tr>
        <tr>
          <td>パッチギ！
          </td>
          <td>Break Through! </td>
          <td>2004</td>
          <td>Kazuyuki Izutsu
          </td>
          <td>in Kyoto-ben and Korean</td>
        </tr>
        <tr>
          <td><a href="http://www.albatros-film.com/movie/michael/">{addRubyText("笑","わら")}う {addRubyText("大天使","ミカエル")}</a>
          </td>
          <td>Arch Angel
          </td>
          <td>2005
          </td>
          <td>Issei Oda
          </td>
          <td>Fumio Shijo ( the main character, played by Juri Ueno)
            speaks Kansai-ben
          </td>
        </tr>
        <tr>
          <td>{addRubyText("酒井家","さかいけ")}の{addRubyText("幸","しあわ")}せ
          </td>
          <td>Sakai’s Happiness</td>
          <td>2006
          </td>
          <td>Mipo Oh
          </td>
          <td>
          </td>
        </tr>
        <tr>
          <td><a href="http://www.shiawase-switch.com/">{addRubyText("幸福","しあわせ")}のスイッチ</a>

          </td>
          <td>Switch of Happiness</td>
          <td>2006
          </td>
          <td>Mana Yasuda
          </td>
          <td>in Wakayama-ben
          </td>
        </tr>
        <tr>
          <td><a href="http://www.lovecom-movie.com/">ラブ★コン</a>
          </td>
          <td>Lovely Complex</td>
          <td>2006
          </td>
          <td>Kitaji Ishikawa
          </td>
          <td>
          </td>
        </tr>
        <tr>
          <td>{addRubyText("手紙","てがみ")}</td>
          <td>Letters</td>
          <td>2006
          </td>
          <td>Jiro Shono
          </td>
          <td>Main character uses <i>ese</i>
            (fake) Kansai-ben ... </td>
        </tr>
        <tr>
          <td>{addRubyText("舞妓","まいこ")} Haaaan!!!
          </td>
          <td>Maiko Haaaan!!
          </td>
          <td>2007</td>
          <td>Nobuo Mizuta
          </td>
          <td>in Kyoto-ben
          </td>
        </tr>
        <tr>
          <td><a href="http://www.fullmedia.jp/inugoe/">イヌゴエ</a>
          </td>
          <td>Voice of Dog</td>
          <td>2007
          </td>
          <td>Kenji Yokoi
          </td>
          <td>The voice of French bulldog is somehow dubbed in
            Kansai-ben.</td>
        </tr>
        <tr>
          <td><a href="http://www.mogarinomori.com/index.html">{addRubyText("殯","もがり")}の
            {addRubyText("森","もり")}</a></td>
          <td>The Forrest of Mogari /The Mourning Forrest</td>
          <td>2007
          </td>
          <td>Naomi Kawase
          </td>
          <td>in Nara-ben
          </td>
        </tr>
        <tr>
          <td>オリオン{addRubyText("座","ざ")}からの{addRubyText("招待状","しょうたいじょう")}
          </td>
          <td>The Invitation from Cinema Orion</td>
          <td>2007
          </td>
          <td>Kenki Saegusa
          </td>
          <td>in Kyoto-ben
          </td>
        </tr>
        <tr>
          <td>
            <a href="http://www.shaberedomo.com/">しゃべれども　しゃべれども</a>
          </td>
          <td>Talk Talk Talk</td>
          <td>2007
          </td>
          <td>Hideyuki Hirayama
          </td>
          <td>Yuu Murabayashi, the youngest of the <i>rakugo</i> class in the
            movie, speaks Kansai-ben. He moved from Kansai to Tokyo, and
            was made fun of by his classmates due to his accents. His
            parents decided to made him learn <i>rakugo</i> so he can become popular at
            school...
          </td>
        </tr>
        <tr>
          <td>{addRubyText("天使","てんし")}がくれたもの
          </td>
          <td>The Gift from Heaven
          </td>
          <td>2007
          </td>
          <td>Shinichiro Nakata
          </td>
          <td>
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>ホームレス{addRubyText("中学生","ちゅうがくせい")}
          </td>
          <td style={{verticalAlign: "top"}}>The Homeless Student
          </td>
          <td style={{verticalAlign: "top"}}>2008
          </td>
          <td style={{verticalAlign: "top"}}>Tomoyuki Furumaya
          </td>
          <td style={{verticalAlign: "top"}}>
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>{addRubyText("大阪","おおさか")}ハムレット</td>
          <td style={{verticalAlign: "top"}}>Osaka Hamlet</td>
          <td style={{verticalAlign: "top"}}>2008
          </td>
          <td style={{verticalAlign: "top"}}>Fujio Mitsuishi</td>
          <td style={{verticalAlign: "top"}}>
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>おと・な・り
          </td>
          <td style={{verticalAlign: "top"}}>Oto-na-ri
          </td>
          <td style={{verticalAlign: "top"}}>2009
          </td>
          <td style={{verticalAlign: "top"}}>Naoto Kumazawa
          </td>
          <td style={{verticalAlign: "top"}}>One of the characters (by
            Mitsuki Tanimura) speaks Kansai-ben
          </td>
        </tr>
        <tr>
          <td valign="top">{addRubyText("阪急電車","はんきゅうでんしゃ")}　{addRubyText("片道","かたみち")}１５{addRubyText("分","ふん")}の{addRubyText("奇跡","きせき")}
          </td>
          <td valign="top">
            Hankyu Railways ‑ A 15‑minute ...</td>
          <td valign="top">2011
          </td>
          <td valign="top">Yoshishige Miyake
          </td>
          <td valign="top">
          </td>
        </tr>
        <tr>
          <td valign="top">きいろいゾウ
          </td>
          <td valign="top">Yellow Elephant
          </td>
          <td valign="top">2013
          </td>
          <td valign="top">Ryuichi HIroki
          </td>
          <td valign="top">
          </td>
        </tr>
        <tr>
          <td valign="top">{addRubyText("少年","しょうねん")}H
          </td>
          <td valign="top">A Boy Called H
          </td>
          <td valign="top">2013
          </td>
          <td valign="top">Yasuo Furuhata
          </td>
          <td valign="top">
          </td>
        </tr>
        <tr>
          <td valign="top">{addRubyText("神","かみ")}さまの{addRubyText("言","い")}うとおり
          </td>
          <td valign="top">As the Gods Will
          </td>
          <td valign="top">2014
          </td>
          <td valign="top">Takashi Miyake
          </td>
          <td valign="top">
          </td>
        </tr>
        <tr>
          <td valign="top"><a href="http://maestro-movie.com/">マエストロ</a>
          </td>
          <td valign="top">Maestro
          </td>
          <td valign="top">2015
          </td>
          <td valign="top">Shotaro Kobayashi
          </td>
          <td valign="top">
          </td>
        </tr>
        <tr>
          <td valign="top"><a href="http://raintree-movie.jp/">レインツリーの
            {addRubyText("国","くに")}</a>
          </td>
          <td valign="top">World of Delight
          </td>
          <td valign="top">2015
          </td>
          <td valign="top">Yoshishige Miyake
          </td>
          <td valign="top">
          </td>
        </tr>
        <tr>
          <td valign="top">
            <a href="http://www.ggvp.net/kochi/">{addRubyText("校庭","こうてい")}に{addRubyText("東風","こち")}{addRubyText("吹","ふ")}いて</a>
          </td>
          <td valign="top">Kotei ni Kochi Fuite
          </td>
          <td valign="top">2016
          </td>
          <td valign="top">Satoshi Kaneda
          </td>
          <td valign="top">
          </td>
        </tr>
        <tr>
          <td valign="top">
            <a href="http://www.gosaigyo.com/">{addRubyText("後妻業","ごさいぎょう")}の{addRubyText("女","おんな")}</a>
          </td>
          <td valign="top">Black Widow Business
          </td>
          <td valign="top">2016
          </td>
          <td valign="top">Yasuo Tsuruhashi
          </td>
          <td valign="top">
          </td>
        </tr>
        <tr>
          <td valign="top"><a href="http://www.ggvp.net/kochi/">セトウツミ</a>
          </td>
          <td valign="top">Setoutsumi
          </td>
          <td valign="top">2016
          </td>
          <td valign="top">Tatsushi Omori
          </td>
          <td valign="top">
          </td>
        </tr>
        <tr>
          <td valign="top"><a href="http://danchi-movie.com/">{addRubyText("団地","だんち")}</a>
          </td>
          <td valign="top">The Projects
          </td>
          <td valign="top">2016
          </td>
          <td valign="top">Junji Sakamoto
          </td>
          <td valign="top">
          </td>
        </tr>
        <tr>
          <td valign="top"><a href="http://hamon-movie.jp/">{addRubyText("破門","はもん")}ふたりの
            ヤクビョーガミ</a>
          </td>
          <td valign="top">Hamon : Yakuza Boogie
          </td>
          <td valign="top">2017
          </td>
          <td valign="top">Shotaro Kobayashi
          </td>
          <td valign="top">
          </td>
        </tr>
        </tbody>
      </table>
      <br />
      <br />
      <table style={{textAlign: "center", width: "30%"}} align="right" border="0" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>{addImage("7.fukikae.jpg")}
          </td>
        </tr>
        </tbody>
      </table>
      <h3>EXTRA: 外国映画・ドラマ・ゲームの関西弁での吹き替え Foreign Films, TV Dramas & Video Games Dubbed in Kansai-ben</h3>
      <br />
      <br />
      <table style={{textAlign: "left", width: "100%"}} border="1"
             cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>Title
          </td>
          <td style={{verticalAlign: "top"}}>Category
          </td>
          <td style={{verticalAlign: "top"}}>Year
          </td>
          <td style={{verticalAlign: "top"}}>Information
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>Shrek the Third</td>
          <td style={{verticalAlign: "top"}}>CG Animation
          </td>
          <td style={{verticalAlign: "top"}}>2007
          </td>
          <td style={{verticalAlign: "top"}}>Shrek is dubbed by Osaka
            comedian Masatoshi Hamada.</td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>Ice Age 2: The Meltdown</td>
          <td style={{verticalAlign: "top"}}>CG Animation
          </td>
          <td style={{verticalAlign: "top"}}>2006
          </td>
          <td style={{verticalAlign: "top"}}>Opossums are dubbed by Osaka
            female comedians.</td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>Without a Trace</td>
          <td style={{verticalAlign: "top"}}>TV drama series</td>
          <td style={{verticalAlign: "top"}}>2002
          </td>
          <td style={{verticalAlign: "top"}}>Danny came to NYC from Miami,
            and that is possibly why he speaks Kansai-ben.</td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>Destroy All Humans!
          </td>
          <td style={{verticalAlign: "top"}}>Video game
          </td>
          <td style={{verticalAlign: "top"}}>2005
          </td>
          <td style={{verticalAlign: "top"}}>
            All creatures from outer space speak Kansai-ben.
          </td>
        </tr>
        </tbody>
      </table>
    </>
  );
};

export default Films;