import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";

const PickleStore = () => {
  const vocabList = <ul style={{display: "block", overflow: "visible"}}>
    <li>
      {addRubyText("日保","ひも")}ちする preserve, keep for a long time
    </li>
    <li>
      {addRubyText("真空","しんくう")}パック vacuum packing
    </li>
    <li>
      {addRubyText("商品","しょうひん")} merchandize
    </li>
    <li>
      {addRubyText("常温","じょうおん")} room temperature
    </li>
    <li>
      {addRubyText("保","も")}つ last, be preserved
    </li>
    <li>
      {addRubyText("浅漬","あさづ")}け vegetables lightly pickled
    </li>
    <li>
      {addRubyText("冷蔵庫","れいぞうこ")} refrigerator
    </li>
    <li>
      お{addRubyText("薦","すす")}め recommendation
    </li>
    <li>
      ごぼう burdock
    </li>
    <li>
      きゅうり cucumber
    </li>
    <li>
      {addRubyText("刻","きざ")}む cut fine, chop up
    </li>
    <li>
      {addRubyText("香","かお")}り aroma
    </li>
    <li>
      {addRubyText("梅","うめ")} plum
    </li>
    <li>
      かつお bonito, dried bonito flake
    </li>
    <li>
      {addRubyText("柴漬","しばづ")}け pickled cucumber in plum vinegar
    </li>
    <li>
      〜{addRubyText("半","なか")}ば the middle of …
    </li>
    <li>
      {addRubyText("微妙","びみょう")} subtle, delicate, not sure (to negate indirectly)
    </li>
    <li>
      ちなみに incidentally, by the way, in this connection
    </li>
    <li>
      ご{addRubyText("予算","よさん")} estimate, budget
    </li>
    <li>
      {addRubyText("種類","しゅるい")} kind
    </li>
    <li>
      お{addRubyText("得","とく")}（な） economical, profitable
    </li>
    <li>
      {addRubyText("好","この")}み preference
    </li>
    <li>
      はずれる miss
    </li>
    <li>
      {addRubyText("当","あ")}たる hit, strike, be correct (&lt;-&gt;はずれる)
    </li>
    <li>
      {addRubyText("包","つつ")}む wrap
    </li>
    <li>
      {addRubyText("時期","じき")} time, period
    </li>
    <li>
      なす eggplant
    </li>
    <li>
      {addRubyText("瓜","うり")} melon
    </li>
    <li>
      {addRubyText("味見","あじみ")} tasting
    </li>
    <li>
      つる vine, tendril
    </li>
    <li>
      {addRubyText("定番","ていばん")}standard goods
    </li>
  </ul>;

  const qaObjects = [
    {
      question: <span>Q1: What did トモちゃん ask the sales clerk at the beginning of the clip?</span>,
      answersIncorrect: [
        <span>Is there any that can last two months?</span>,
        <span>Is there any that can last a couple days?</span>
      ],
      answersCorrect: [
        <span>Is there any that can last two weeks?</span>
      ]
    },
    {
      question: <span>Q2: What was the sales clerk’s answer to トモちゃん’s question?</span>,
      answersIncorrect: [
        <span>He recommended lightly pickled vegetables, because they preserve their flavor in refrigerator.</span>
      ],
      answersCorrect: [
        <span>He recommended vacuum-packed pickled vegetables, because they last longer in room temperature.</span>
      ]
    },
    {
      question: <span>Q3: Why did the sales clerk lead トモちゃん to the assortments of pickled vegetables? Choose all that apply.</span>,
      answersIncorrect: [
        <span>She can give each pack separately to several people.</span>,
        <span>They are a better deal than buying each pack individually.</span>
      ],
      answersCorrect: [
        <span>They are within her budget.</span>,
        <span>They are suitable when you don’t know the preference of the person to whom you will give them as a gift.</span>
      ]
    },
    {
      question: <span>Q4: What did トモちゃん decide to buy for her father after all? Choose all that apply.</span>,
      answersIncorrect: [
        <span>Pickled daikon radish (spicy).</span>,
        <span>Pickled daikon radish (soy sauce flavor).</span>,
        <span>Shibazuke (Pickled cucumber, plum flavor).</span>,
        <span>Assortment (Six different flavored pickles).</span>,
      ],
      answersCorrect: [
        <span>Pickled burdock and cucumber (soy sauce flavor).</span>,
        <span>Pickled daikon radish (plum and bonito flavor).</span>
      ]
    },
    {
      question: <span>Q5: What did the sales clerk mention when they moved to the {addRubyText("浅漬","あさづ")}け section? Choose all that apply.</span>,
      answersIncorrect: [
        <span>Pickled vine vegetables are not available because they are seasonal items.</span>,
        <span>Half-size pickled daikon is popular because its full size seems too big.</span>
      ],
      answersCorrect: [
        <span>Pickled vine vegetables are in season.</span>,
        <span>Lightly pickled daikon radish is the most popular item.</span>
      ]
    },
    {
      question: <span>Q6: What did トモちゃん buy for herself?</span>,
      answersIncorrect: [
        <span>Lightly pickled daikon radish (half size).</span>,
        <span>Vacuum packed daikon radish (full size).</span>,
        <span>Vacuum packed daikon radish (half size).</span>
      ],
      answersCorrect: [
        <span>Lightly pickled daikon radish (full size).</span>
      ]
    },
    {
      question: <span>Q7: How did トモちゃん want the purchased items to be wrapped?</span>,
      answersIncorrect: [
        <span>Wrapped all together.</span>,
        <span>Wrapped individually.</span>
      ],
      answersCorrect: [
        <span>For her father – together, for herself – separately.</span>
      ]
    },
    {
      question: <span>Q8: How much are they in total?</span>,
      answersIncorrect: [
        <span>3,165-yen.</span>,
        <span>1,356-yen.</span>,
        <span>3,156-yen.</span>
      ],
      answersCorrect: [
        <span>1,365-yen.</span>
      ]
    }
  ];

  return (
    <>
      <h2>{addRubyText("漬け物屋","つけものや")}で (At a pickle store in Kyoto)</h2>
      <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>
            [Context] トモちゃん is in a pickled
            vegetable store in Kyoto, thinking of buying some preserved vegetables
            for her father as a gift on Father’s day.
          </td>
        </tr>
        </tbody>
      </table>
      <br />
      <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addVideoPlayer("5.5.tsukemono.mp4")}<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            Vocabulary List <br />
            <Hideable content={vocabList} />
          </td>
        </tr>
        </tbody>
      </table>
      <h3>{addRubyText("内容質問","ないようしつもん")} (Content Questions)</h3>
      <QandAForm qaObjects={qaObjects} />
    </>
  );
};

export default PickleStore;
