import React from "react";
import { addRubyText, addAudio } from "common/util/generalUtil";

const Obligation = () => {
  return <>
<h2>3. Vないかん／あかん、Vんといかん／あかん (Obligation “One/I/you must…”)</h2>
<h3>Summary of Expressions of Obligation</h3>
<table style={{textAlign: "left", width: "100%"}} border="1"cellPadding="2"
 cellSpacing="2">
  <tbody>
    <tr>
      <td style={{verticalAlign: "top"}}>Standard<br />
      </td>
      <td style={{verticalAlign: "top"}}>Kansai<br />
      </td>
      <td style={{verticalAlign: "top"}}>Kansai (Polite)<br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>Vなければ（なきゃ）いけない／ならない／だめだ<br />
Vなくては（なくちゃ）いけない／ならない／だめだ<br />
      </td>
      <td style={{verticalAlign: "top"}}>V(negative stem) な + いかん／あかん<br />
      <br />
      </td>
      <td style={{verticalAlign: "top"}}>V(negative stem) な +
いけません／あきません／あきまへん／あかしまへん<br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>Vないといけない</td>
      <td style={{verticalAlign: "top"}}>V(short negative) と + いかん／あかん</td>
      <td style={{verticalAlign: "top"}}>V(short negative) と +
いけません／あきません／あきまへん／あかしまへん</td>
    </tr>
  </tbody>
</table>
<br />
If you know what Vな／Vんと mean as in (2), it is understandable that
obligations are expressed by using these two forms.<br />
&nbsp;<br />
Vなければいけないー＞Vないかん／あかん（ Vな＝Vなければ）<br />
&nbsp;{addRubyText("食","た")}べないかん、{addRubyText("買","か")}わなあかん、しな／せないかん、{addRubyText("来","こ")}なあかん {addAudio("4.2.3.1.mp3")}<br />
<br />
{addRubyText("朝七時","あさひちじ")}までに{addRubyText("駅","えき")}に{addRubyText("集","あつ")}まらなあかんかったん、{addRubyText("全然","ぜんぜん")}{addRubyText("知","し")}らんかった。{addAudio("4.2.3.2.mp3")}<br />
<small>(ST 朝七時までに駅に集まらなきゃいけなかったのを、全然知らなかった。)</small><small>{addAudio("4.2.3.2.s.mp3")}</small><br />
<br />
Vないといけないー＞Vん(V short negative)といかん／あかん（ Vんと=Vないと）<br />
&nbsp;食べんといかん、買わんとあかん、しん／せんといかん、来んとあかん {addAudio("4.2.3.3.mp3")}<br />
<br />
{addRubyText("来期","らいき")}は{addRubyText("遊","あそ")}びだけちごて、{addRubyText("仕事","しごと")}ももうちょっと{addRubyText("頑張","がんば")}ってくれんといかんで。{addAudio("4.2.3.4.mp3")}<br />
<small>(ST 来期は遊びだけじゃなくて、仕事ももう少し頑張ってくれないといけないよ。) </small><small>{addAudio("4.2.3.4.s.mp3")}</small><br />
<small><br />
</small><small>* 来期 next term</small><br />
<br />
These two patterns of expressions indicate “ONE/you/I must…” and are
mutually interchangeable.<br />
<br />
Note that mixtures with the standard expressions are sometimes observed
in the speech of younger generations these days such as
食べなきゃいかん／食べなくちゃいかん／食べないけない／食べなだめだ／食べんとだめだ.<br />
<br />
<hr />
<h3>Extra 1: Vんならん Obligation for oneself </h3>
Vん(short negative)+ならん is the equivalent of Vなければならない. However, this
expression is used only when expressing obligation for oneself, whereas
the standard expression can indicate someone else’s obligation as well
as one’s own.<br />
&nbsp; 食べんならん、{addRubyText("見","み")}んならん、{addRubyText("飲","の")}まんならん、{addRubyText("行","い")}かんならん、買わんならん、しん／せんならん、来んならん
{addAudio("4.2.3.5.mp3")}<br />
<br />
{addRubyText("私","あたし")}、{addRubyText("今日","きょう")}は{addRubyText("子供","こども")}の{addRubyText("面倒","めんどう")}見んならんし、{addRubyText("先","さき")}{addRubyText("帰","かえ")}るわ。{addAudio("4.2.3.6.mp3")}<br />
<small>(ST 私、今日は子供の面倒を見なきゃならないから、先に帰るね。)</small><small>{addAudio("4.2.3.6.s.mp3")}</small>
  </>;
}

export default Obligation;
