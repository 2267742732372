import React from "react";
import { addRubyText, addAudio } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";

const P1 = () => {
  const transcript = <>
    <ol>
      <li>A: このピーマン、食べなきゃだめ？</li>
      <li>B: いや、食べなくてもいいよ。／食べなくてもかまわないよ。</li>
    </ol>
  </>;
  return <>
<h2>{addRubyText("練習","れんしゅう")}1: Obligation &amp; Negative Permission - pattern practice<br />
</h2>
<br />
Form a question (asking a negative permission) and an answer (negative
permission) for each cue according to the model.&nbsp; You may listen
to the question, try to answer it, and then check your answer. *Answers
vary: the recorded answers indicate those commonly used.<br />
<small>*Move the mouse over to the speaker icon for sound</small><br />
<br />
<span style={{fontWeight: "bold"}}>Model</span>（Cue:このピーマン／{addRubyText("食","た")}べる） <br />
&nbsp;&nbsp;&nbsp; A: このピーマン、食べなあかん？<br />
&nbsp;&nbsp;&nbsp; B: いや、食べんでもええよ。／食べんでもかまへんよ。{addAudio("4.2.p.1.mp3")}<br />
<br />
ST transcript
<br />
<Hideable content={transcript} />
<br />
<table style={{textAlign: "left", width: "100%"}} border="1"cellPadding="2"
 cellSpacing="2">
  <tbody>
    <tr>
      <td style={{verticalAlign: "top", textAlign: "center"}}>Cue<br />
      </td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>A (Question)<br />
      </td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>B (Answer)<br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>この{addRubyText("本","ほん")}／{addRubyText("読","よ")}む<br />
      </td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("4.2.p.1.1.a.mp3")}<br />
      </td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("4.2.p.1.1.b.mp3")}</td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>あの{addRubyText("薬","くすり")}／{addRubyText("飲","の")}む</td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("4.2.p.1.2.a.mp3")}</td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("4.2.p.1.2.b.mp3")}</td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>{addRubyText("今日","きょう")}、{addRubyText("図書館","としょかん")}／{addRubyText("行","い")}く</td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("4.2.p.1.3.a.mp3")}</td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("4.2.p.1.3.b.mp3")}</td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>{addRubyText("晩","ばん")}ご{addRubyText("飯","はん")}／{addRubyText("作","つく")}る</td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("4.2.p.1.4.a.mp3")}</td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("4.2.p.1.4.b.mp3")}</td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>このビデオ／{addRubyText("見","み")}る<br />
      </td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("4.2.p.1.5.a.mp3")}</td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("4.2.p.1.5.b.mp3")}</td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>{addRubyText("白","しろ")}いシャツ／{addRubyText("洗濯","せんたく")}する</td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("4.2.p.1.6.a.mp3")}</td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("4.2.p.1.6.b.mp3")}</td>
    </tr>
  </tbody>
</table>
  </>;
}

export default P1;
