import React from "react";
import { addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";

const RiceCake = () => {
  const vocabList = <ul style={{display: "block", overflow: "visible"}}>
    <li>
      よいしょ oof!; yo-heave-ho!
    </li>
  </ul>;

  return (
    <>
      <h2>もちつき (Rice cake making)</h2>
      <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>
            [Context] A rice cake shop in Kyoto demonstrates rice-cake making. Just watch and enjoy!
          </td>
        </tr>
        </tbody>
      </table>
      <br />
      <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addVideoPlayer("5.13.mochi.mp4")}<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            Vocabulary List <br />
            <Hideable content={vocabList} />
          </td>
        </tr>
        </tbody>
      </table>
    </>
  );
};

export default RiceCake;
