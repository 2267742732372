import React from "react";
import { Link } from "react-router-dom";
import { Page } from "home/page";
import { addRubyText, addAudio } from "common/util/generalUtil";

const Pejoratives = () => {
  return <>
    <h2>2. Vよる／Vとる Pejoratives</h2>
    As indicated before (refer to 
    <Link to={Page.Characteristics.LinguisticAspects.Honorifics}>
      Ch1 2. g. Honorifics
    </Link>), humble expressions are used the same way as in the
    standard, however, おる／とる（＜—〜ておる）, the humble form of いる／〜ている is also used as a
    light pejorative verb/auxiliary verb, to look down on or to show
    hierarchical difference from the person/animals, or just to be blunt.
    (* Please note that おる is just an equivalent of いる in Kobe area)<br />
    <br />
    うわ〜！この{addRubyText("部屋","へや")}、めっちゃごっついゴキブリ<span style={{textDecoration: "underline"}}>おん</span>で
    （おるで）！{addAudio("4.4.2.1.mp3")}<br />
    <small>(ST うわ〜！この部屋にはものすごく{addRubyText("大","おお")}きいゴキブリがいるよ！) {addAudio("4.4.2.1.s.mp3")}<br />
    </small><br />
    <small>* ゴキブリ cockroach<br />
    * ごっつい(Kansai) ＝大きい<br />
    * むっちゃ(Kansai)（＜—めちゃくちゃ）＝すごく tremendously, enormously<br />
    </small><br />
    あの{addRubyText("犬","いぬ")}、{addRubyText("川","かわ")}で{addRubyText("何","なに")}<span style={{textDecoration: "underline"}}>しとん</span>にゃ
    ろ？{addAudio("4.4.2.2.mp3")}<br />
    <small>(ST あの犬は川で何をしてるんだろう？) </small><small> {addAudio("4.4.2.2.s.mp3")}</small><br />
    <br />
    よる, originated in おる, is an auxiliary verb used in the opposite way
    from はる, that is, to humble down or to ridicule somebody, or just to be
    blunt. This expression is commonly used by male speakers when talking
    about the third person/animals/thing’s action or state, but can be used
    to the addressee as well as to the third person to express strong
    emotions such as surprise, and anger. Also note that female speakers do
    use it when showing superiority or expressing strong emotions. <br />
    <br />
    V(Masu-form stem)+よる (not おる)<br />
    {addRubyText("食","た")}べよる、いよる、{addRubyText("見","み")}よる、{addRubyText("飲","の")}みよる、しよる、きよる {addAudio("4.4.2.3.mp3")}<br />
    <br />
    (Male, to a friend, about his friend)
    {addRubyText("山田","やまだ")}が{addRubyText("中国","ちゅうごく")}で{addRubyText("結婚","けっこん")}<span style={{textDecoration: "underline"}}>し
    よった</span>らしいで！{addAudio("4.4.2.4.mp3")}<br />
    <small>(ST 山田が中国で結婚したらしいよ！) </small>{addAudio("4.4.2.4.s.mp3")}<br />
    <br />
    (Male, about a cat) あのごっつい{addRubyText("猫","ねこ")}、ようけ<span
    style={{textDecoration: "underline"}}>{addRubyText("食","く")}いよる</span>わ。{addAudio("4.4.2.5.mp3")}<br />
    <small>(ST あの{addRubyText("大","おお")}きい猫、たくさん食うよ。)</small> 　{addAudio("4.4.2.5.s.mp3")}<br />
    <br />
    <small>*{addRubyText("食","く")}う (blunt)＝食べる</small><br />
    <br />
    (Female, to her mother, about her younger brother)<br />
    お{addRubyText("母","かあ")}さん、ひろし、{addRubyText("今日","きょう")}どこ<span style={{textDecoration: "underline"}}>{addRubyText("行","い")}き
    よん</span>の？{addAudio("4.4.2.6.mp3")}<br />
    <small>(ST お母さん、ひろしは今日どこに行くの？) </small>{addAudio("4.4.2.6.s.mp3")}<br />
    <br />
    (Male, to a client, about his subordinate)<br />
    うちの{addRubyText("高木","たかぎ")}ももうすぐ<span style={{textDecoration: "underline"}}>{addRubyText("来","き")}よる</span>
    {addRubyText("思","おも")}いますし、もうちょい{addRubyText("待","ま")}ってくれはりますか。{addAudio("4.4.2.7.mp3")}<br />
    <small>(ST うちの高木ももうすぐ参ると思いますので、もう{addRubyText("少々","しょうしょう")}お待ちになってくださいますか。) </small>{addAudio("4.4.2.7.s.mp3")}<br />
    <br />
    * ちょい (Kansai) = ちょっと、少し<br />
    <br />
    Please be reminded that V(て form)+ よる is Vとる（＜—〜ておる）, not Vてよる.<br />
    <br />
    あの猫、ようけ<span style={{textDecoration: "underline"}}>食いよる</span>わ。(ST 食うよ)
    {addAudio("4.4.2.8.mp3")}<br />
    あの猫、ようけ<span style={{textDecoration: "underline"}}>食うとる</span>わ。(ST食っているよ)
    {addAudio("4.4.2.9.mp3")}<br />
    <br />
    <hr />
    <h3>Extra 1: Vよる for one’s own action</h3>
    There are some situations where 〜よる is used for one’s own action,
    whether the speaker is male or female.<br />
    <br />
    (1) V(non-continuous verb)+よる／とる could mean “almost”<br />
    あたし、もうちょっとで<span style={{textDecoration: "underline"}}>{addRubyText("死","し")}によった</span>／死ん
    どったで。{addAudio("4.4.2.10.mp3")}<br />
    <small>（ST {addRubyText("私","わたし")}、もう少しで死ぬ／死んでいるところだったわ。）</small><small>{addAudio("4.4.2.10.s.mp3")}</small><br />
    <br />
    (2) V(continuous verb)+よる could mean “almost/ was ~ing..”<br />
    {addRubyText("俺","おれ")}、もうちょっとで{addRubyText("腐","くさ")}った{addRubyText("寿司","すし")}<span style={{textDecoration: "underline"}}>食
    べよった</span>／食べとったで。{addAudio("4.4.2.11.mp3")}<br />
    <small>(ST 俺、もうちょっとで腐った寿司を食べる／食べているところだったよ。) {addAudio("4.4.2.11.s.mp3")}<br />
    <br />
    </small>&nbsp;ちっちゃい{addRubyText("時","とき")}は、私、よう{addRubyText("一日中","いちにちじゅう")}<span
    style={{textDecoration: "underline"}}>{addRubyText("寝","ね")}よった</span>／寝とったもんやわ。{addAudio("4.4.2.12.mp3")}<br />
    <small>(ST 小さい時は、私、よく一日中寝た／寝ていたものよ。) {addAudio("4.4.2.12.s.mp3")}<br />
    </small>
  </>;
}

export default Pejoratives;
