require('dotenv').config()
const dbAddress = process.env.REACT_APP_DB_ADDRESS;

function listInterviewees() {
  return fetch(dbAddress + `/api/interviewees/list`, {
    accept: 'application/json'
  })
    .then(checkStatus)
    .then(parseJSON);
}

function getInterviewQuestionCount() {
  return fetch(dbAddress + `/api/interviewQuestions/count`, {
    accept: 'application/json'
  })
    .then(checkStatus)
    .then(parseJSON);
}

function listInterviewQuestions() {
  return fetch(dbAddress + `/api/interviewQuestions/list`, {
    accept: 'application/json'
  })
    .then(checkStatus)
    .then(parseJSON);
}

function getInterviewClipCount() {
  return fetch(dbAddress + `/api/interviewClips/count`, {
    accept: 'application/json'
  })
    .then(checkStatus)
    .then(parseJSON);
}

function listInterviewClips(start, count) {
  return fetch(dbAddress + `/api/interviewClips/list?start=${start}&n=${count}`, {
    accept: 'application/json'
  })
    .then(checkStatus)
    .then(parseJSON);
}

function getInterviewClipQandA(clipID) {
  return fetch(dbAddress + `/api/interviewClipQandA/get?clipID=${clipID}`, {
    accept: 'application/json'
  })
    .then(checkStatus)
    .then(parseJSON);
}

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    const error = new Error(`HTTP Error ${response.statusText}`);
    error.status = response.statusText;
    error.response = response;
    console.log(error); // eslint-disable-line no-console
    throw error;
  }
}

function parseJSON(response) {
  return response.json();
}

const Client = { listInterviewees, getInterviewQuestionCount, listInterviewQuestions, 
                 getInterviewClipCount, listInterviewClips, getInterviewClipQandA };
                 
export default Client;
  