import React from "react";
import { Link } from "react-router-dom";
import { Page } from "home/page";
import { addRubyText, addImage } from "common/util/generalUtil";

const Even = () => {
  return <>
    <h2>Lesson 5:&nbsp; ~ かて "Even / Even if..."<br /></h2>
    <h3>〜そんなこと{addRubyText("言","ゆ")}うたかて、{addRubyText("私","わたし")}かてかなんわ！〜 <br /></h3>
    <table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>
          <ul>
            <li>
              <Link to={Page.FunctionalGrammar.Even.Conversations}>
                {addRubyText("基本会話","きほんかいわ")} (Core Conversations)
              </Link>
            </li>
            <li>
              <Link to={Page.FunctionalGrammar.Even.Grammar.Grammar}>
                {addRubyText("文法","ぶんぽう")}ノート (Grammar Note)
              </Link>
            </li>
            <li>
              {addRubyText("練習","れんしゅう")}(Practice)
            </li>
            <li>
              {addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}(Listening Practice)
            </li>
            <li>
              <Link to={Page.FunctionalGrammar.Even.Video.Video}>
                ビデオ{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}（Video Comprehension Practice)
              </Link>
            </li>
          </ul>
          </td>
          <td style={{verticalAlign: "top"}}>
          <div style={{textAlign: "center"}}>
            {addImage("tobidashisign2.jpg")}<br />
          </div>
          <div style={{textAlign: "center"}}>
            <small>
              飛び出し注意の看板 Signborad of "Caution against rones dush!" (Kinosaki, Hyogo)
            </small>
            <br />
          </div>
          </td>
        </tr>
      </tbody>
    </table>
  </>;
}

export default Even;
