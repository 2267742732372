import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";

const V1 = () => {
  const transcript = <>
    ほなこと、{addRubyText("考","かんが")}えてることある<span style={{textDecoration: "underline"}}>かいな</span>(Grammar 1)！
  </>;

  const qaObjects = [
    {
      question: <>Q: He is asked if he was surprised when he went to Tokyo for the first time. What is his response?</>,
      answersCorrect: [
        <>I was too stunned to even think about it!</>
      ],
      answersIncorrect: [
        <>I really don't remember.</>,
        <>I wasn't surprised at all.</>
      ]
    }
  ]

  return <>
    <h2>ビデオ{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}１: Authentic - short<br />
    </h2>
    <span style={{fontWeight: "bold"}}></span>Watch the
    video clip and choose the statement that best expresses what the person meant. 
    <br />
    <small>* Click "SHOW" if you need to refer to the transcript.</small>
    <br />
    <br />
    <div className="checkupquestion">
      <table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2" cellSpacing="2">
        <tbody>
          <tr>
            <td style={{verticalAlign: "top"}}>
              {addVideoPlayer("3.1.b.pl0.1.mp4")}
            </td>
            <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>
              Transcript
              <Hideable content={transcript} />
              <br />
              ＊{addRubyText("考","かんが")}える think; consider</td>
          </tr>
          <tr>
            <td style={{verticalAlign: "top"}}>
              マツダさん (Shiga)
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <QandAForm qaObjects={qaObjects} />
    </div>
  </>;
}

export default V1;
