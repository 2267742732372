import React from "react";
import { addRubyText, addAudio } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";

const L3 = () => {
  const transcript = <>
    <ol>
      <li><small>A:そんなゆっくりご{addRubyText("飯","はん")}{addRubyText("混","ま")}ぜなや。</small></li>
      <li><small>B: こう？ご飯、{addRubyText("固","かた")}いなあ。{addRubyText("水","みず")}{addRubyText("入","い")}れよか。</small></li>
      <li><small>A:あかん。水なんか入れなや。はよ、混ぜぇな。</small></li>
      <li><small>B: は〜い。せやけど、えらい{addRubyText("熱","あつ")}いで。</small></li>
      <li><small>A: ほしたら、うちわ{addRubyText("使","つか")}いぃや。</small></li>
      <li><small>B: こうか？</small></li>
      <li><small>A: そんな{addRubyText("遠","とお")}くから{addRubyText("振","ふら")}んと。</small></li>
      <li><small>B：もう！あ〜めんどくさ！</small></li>
    </ol>
  </>;

  const qaObjects = [
    {
      question: <>Q: Choose all applicable requests from A to B from the following statements.</>,
      answersCorrect: [
        <>Mix the rice quickly</>,
        <>Cool down the rice with a fan</>
      ],
      answersIncorrect: [
        <>Add water</>,
        <>Cool down the rice from a bit far</>
      ]
    }
  ]

  return <>
    <h2>{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}３</h2>
    Ms. B is learning how to make sushi rice from Ms. A. <br />
    <small>* Click "SHOW" if you need to refer to the transcript. <br />
    *Move the mouse over to the speaker icon for sound.</small>
    <br />
    <br />
    {addAudio("4.3.pl0.3.mp3")} 
    <Hideable content={transcript} />
    <br />
    *うちわ：(round) fan<br />
    <br />
    <QandAForm qaObjects={qaObjects}/>
  </>;
}

export default L3;
