import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";

const RawOyster = () => {
  const vocabList = <ul style={{display: "block", overflow: "visible"}}>
    <li>
      {addRubyText("身","み")} the body
    </li>
    <li>
      {addRubyText("肥","こ")}える grow fat, put on weight
    </li>
    <li>
      {addRubyText("美味","おい")}しんぼ the title of a popular manga about gourmet foods
    </li>
    <li>
      {addRubyText("時期","じき")} time, period
    </li>
    <li>
      {addRubyText("季節","きせつ")}season
    </li>
    <li>
      かつお bonito
    </li>
    <li>
      {addRubyText("一概","いちがい")}に indiscriminately, sweepingly
    </li>
    <li>
      ちなみに incidentally, by the way, in this connection
    </li>
    <li>
      {addRubyText("生","なま")} raw
    </li>
    <li>
      {addRubyText("火","ひ")}を{addRubyText("通","とお")}す cook, heat up
    </li>
    <li>
      {addRubyText("牡蠣鍋","かきなべ")} oyster hot pot
    </li>
    <li>
      {addRubyText("出汁","だし")} broth, soup, stock
    </li>
    <li>
      ぐつぐつ{addRubyText("煮","に")}る simmer hard
    </li>
    <li>
      ちっちゃいちっちゃい (Kansai) とても{addRubyText("小","ちい")}さな
    </li>
    <li>
      {addRubyText("豆","まめ")} beans
    </li>
    <li>
      {addRubyText("出涸","でが")}らし tea leaves used over and over until they have no scent or taste
    </li>
    <li>
      {addRubyText("冷凍","れいとう")} frozen
    </li>
    <li>
      牡蠣フライ fried oyster
    </li>
    <li>
      {addRubyText("状態","じょうたい")} state, condition
    </li>
    <li>
      くさい smelly
    </li>
    <li>
      {addRubyText("実践","じっせん")}するpractice, put into practice
    </li>
    <li>
      {addRubyText("信用","しんよう")}する trust
    </li>
    <li>
      しんどい (Kansai) hard, tired
    </li>
    <li>
      {addRubyText("一切","いっさい")}(- neg) never, not – at all
    </li>
    <li>
      {addRubyText("職業病","しょくぎょうびょう")} occupational disease
    </li>
    <li>
      めちゃ(Kansai) =とても、すごく
    </li>
    <li>
      ことわざ proverb
    </li>
    <li>
      {addRubyText("外","はず")}れ a miss, a failure
    </li>
  </ul>;

  const qaObjects = [
    {
      question: <span>Q1: When is the best season for raw oysters, according to the fish dealer?</span>,
      answersIncorrect: [
        <span>Spring.</span>,
        <span>Summer.</span>,
        <span>Fall.</span>
      ],
      answersCorrect: [
        <span>Winter.</span>
      ]
    },
    {
      question: <span>Q2: トモちゃん expressed doubt about the answer of Q1. Why?</span>,
      answersIncorrect: [
        <span>She heard she should avoid oysters harvested during the months spelled with ‘R.’</span>
      ],
      answersCorrect: [
        <span>She heard that months spelled with ‘R’ are the best season for oysters.</span>
      ]
    },
    {
      question: <span>Q3: What was the fish dealer’s answer to Q2? Choose all that apply.</span>,
      answersIncorrect: [
        <span>It entirely depends on the kind of oysters.</span>,
        <span>It entirely depends on where they grow.</span>,
        <span>All others except me are liars.</span>
      ],
      answersCorrect: [
        <span>It depends on the kind of oysters, where they grow and such.</span>,
        <span>You should not trust everything others say.</span>
      ]
    },
    {
      question: <span>Q4: What are the good ways to eat oysters according to the fish dealer? Choose all that apply.</span>,
      answersIncorrect: [
        <span>Cook thoroughly.</span>
      ],
      answersCorrect: [
        <span>Eat raw.</span>,
        <span>Cook lightly.</span>,
        <span>Use it as an ingredient of another dish.</span>
      ]
    },
    {
      question: <span>Q5: What is the best way to enjoy oyster hot pot according to the fish dealer?</span>,
      answersIncorrect: [
        <span>Cook the oysters thoroughly until they become really tiny.</span>,
        <span>Enjoy the broth, not the oysters themselves.</span>
      ],
      answersCorrect: [
        <span>Eat the oysters immediately after cooking them in the pot lightly.</span>
      ]
    },
    {
      question: <span>Q6: What was the fish dealer’s comment on frozen fried oysters?</span>,
      answersIncorrect: [
        <span>He eats them only when frozen oysters are not smelly.</span>,
        <span>He eats them because the smell of frozen oysters disappears when fried.</span>,
        <span>He doesn’t eat them because he believes raw oysters have the best aroma.</span>
      ],
      answersCorrect: [
        <span>He doesn’t eat them because frozen oysters smell bad.</span>
      ]
    },
    {
      question: <span>Q7: Why is it that the fish dealer wouldn’t try oyster dishes at restaurants?</span>,
      answersIncorrect: [
        <span>He doesn’t trust restaurants because they may be using illegally imported oysters.</span>,
        <span>He can eat as many good oysters as he wants at his fish shop so he doesn’t need to eat them at restaurants.</span>
      ],
      answersCorrect: [
        <span>He doesn’t trust restaurants because he doesn’t know if oysters are handled properly.</span>
      ]
    },
    {
      question: <span>Q8: What fact did トモちゃん indicate by saying {addRubyText("職業病","しょくぎょうびょう")}みたい?</span>,
      answersIncorrect: [
        <span>That he keeps enthusiastically talking about oysters.</span>,
        <span>That he can no longer eat any oysters at all.</span>
      ],
      answersCorrect: [
        <span>That he cannot eat oysters at other restaurants.</span>
      ]
    },
    {
      question: <span>Q9: The video shooter made up a fake proverb
        {addRubyText("牡蠣","かき")}{addRubyText("食","た")}べたら{addRubyText("大","おお")}きなる. Why?</span>,
      answersIncorrect: [
        <span>The fish dealer has grown big because he started eating oysters at age one.</span>,
        <span>She herself grew big because she started eating oysters at age one.</span>,
        <span>Her friend’s baby is growing big because he started eating oysters at age one.</span>
      ],
      answersCorrect: [
        <span>The fish dealer’s baby is growing big because he started eating oysters at age one.</span>
      ]
    },
    {
      question: <span>Q10: What was fish dealer’s comment on the fake proverb?</span>,
      answersIncorrect: [
        <span>It must be wrong because my baby is not growing big!</span>,
        <span>I wish it were a real proverb so I could sell more oysters!</span>
      ],
      answersCorrect: [
        <span>It must be wrong because I didn’t grow big!</span>
      ]
    }
  ];

  return (
    <>
      <h2>{addRubyText("生牡蠣","なまがき")} (Raw oyster at a fish shop in Kyoto)</h2>
      <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>
            [Context] A fish dealer was shucking oysters at his fish shop in Kyoto, and it caught トモちゃん’s attention.
          </td>
        </tr>
        </tbody>
      </table>
      <br />
      <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addVideoPlayer("5.7.kaki1.mp4")}<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            Vocabulary List <br />
            <Hideable content={vocabList} />
          </td>
        </tr>
        </tbody>
      </table>
      <h3>{addRubyText("内容質問","ないようしつもん")} (Content Questions)</h3>
      <QandAForm qaObjects={qaObjects} />
    </>
  );
};

export default RawOyster;
