import React from "react";
import { addVideoPlayer } from "common/util/generalUtil";

const EricInKansaiben = () => {
  return (
    <>
      <h2>エリックさんへのインタビュー Interview to Eric-san (in Kansai-ben)</h2>
      <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Q: How did you learn Kansai-ben?
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Q: Which did you learn first, Standard or Kansai-ben?
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addVideoPlayer("Eric.j1.mp4")}
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addVideoPlayer("Eric.j2.mp4")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Q: Any benefits from speaking Kansai-ben?
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Q: Any characteristic differences between Kansai people and Tokyoite?
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addVideoPlayer("Eric.j3.mp4")}
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addVideoPlayer("Eric.j4.mp4")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Q: Can foreigners get by in Kansai without any knowledge of Kansai-ben?
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Q: Your favorite Kansai word?
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addVideoPlayer("Eric.j5.mp4")}
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addVideoPlayer("Eric.j6.mp4")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Q: Should foreigners learn a Japanese dialect?
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Q: Any differences within Kansai-ben in terms of difficulty?
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addVideoPlayer("Eric.j7.mp4")}
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addVideoPlayer("Eric.j8.mp4")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Q: Will this Kansai-ben study site be beneficial?
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Q: Personality differences when speaking Kansai-ben / Standard / English?
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addVideoPlayer("Eric.j9.mp4")}
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addVideoPlayer("Eric.j10.mp4")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Q: Any suggestions to Japanese (Kansai-ben) learners?
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Q: Are you glad that you can speak Kansai-ben?
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addVideoPlayer("Eric.j11.mp4")}
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addVideoPlayer("Eroc.j12.mp4")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Q: Is Kansai-ben suitable for TV news?
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Q: What do you think about Kansai-ben in media? (the reason <i>manzai</i> in Kansai-ben is funny)
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addVideoPlayer("Eric.j13.mp4")}
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addVideoPlayer("Eric.j14.mp4")}
          </td>
        </tr>
        </tbody>
      </table>
    </>
  );
};

export default EricInKansaiben;