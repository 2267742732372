import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { DraggableForm } from "common/util/draggableForm";

const V1 = () => {
  const transcriptLeft = <>
    <br />
      <ol>
        <li><small>A: あんな、{addRubyText("今","いま")}{addRubyText("新","あたら")}しいダイエットしてんねん。</small></li>
        <li><small>B: どんなん？</small></li>
        <li><small>A: お{addRubyText("酒","さけ")}は{addRubyText("飲","の")}んだらあかんねけど、{addRubyText("水","みず")}はいっぱいまなあかんねん。</small></li>
        <li><small>B: それで？</small></li>
        <li><small>A:
{addRubyText("野菜","やさい")}と{addRubyText("果物","くだもん")}は、なんぼ{addRubyText("食","た")}べてもかまへんけど、{addRubyText("肉","にく")}は{addRubyText("全然","ぜんぜん")}食べたらあかんねん。</small></li>
        <li><small>B: ふ〜ん、そしたら{addRubyText("焼","や")}き肉食べながらビール飲まれへんやん。つまらんなあ。</small></li>
        <li><small>A: 、、、あ、{addRubyText("昨日","きのう")}それやってもた。</small></li>
      </ol>
  </>;

  const transcriptRight = <>
    <br />
      <ol>
        <li><small>B: {addRubyText("実","じつ")}は、{addRubyText("私","わたし")}もダイエット{addRubyText("始","はじ")}めてん。</small></li>
        <li><small>A:どんなん、どんなん？</small></li>
        <li><small>B:
まず、ようけ{addRubyText("歩","ある")}あるかなあかんねん。{addRubyText("運動","うんどう")}{addRubyText("言","い")}うても、ジョギングとか{addRubyText("水泳","すいえい")}とか{addRubyText("激","はげ")}しい運動はしたら
あかんねんで。</small></li>
        <li><small>A: ふ〜ん。食べ{addRubyText("物","もん")}は？</small></li>
        <li><small>B: {addRubyText("熱","あつ")}い物は食べてもええけど、{addRubyText("冷","つめ")}たい物は食べたらあかんねん。</small></li>
        <li><small>A:そしたら、アイスクリームあるねんけど、あんたの{addRubyText("分","ぶん")}も食べたるわ。</small></li>
        <li><small>A: あっ！食べんといて食べんといて〜！</small></li>
      </ol>
  </>;

  const draggableFormData = {
    prompts: {
      texts: [
        <span>いく’s Diet Method</span>,
        <span>じゅん’s Diet Method </span>,
        <span>Neither</span>
      ]
    },
    answers: {
      labels: [
        <>Drink a lot of water.</>,
        <>Enjoy fruits and vegetables.</>,
        <>Don’t drink any alcohol.</>,
        <>Don’t eat cold foods.</>,
        <>You may eat hot grilled meat.</>,
        <>Walk a lot.</>,
        <>Swim as much as possible.</>,
        <>Jog everyday.</>,
        <>Eat ice cream.</>
      ]
    },
    matchGraph: [
      new Set([0,1,2]),
      new Set([3,4,5]),
      new Set([6,7,8])
    ]
  }

  return <>
    <h2>ビデオ{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}１</h2>
    What are the new diet methods of いく and じゅん? Listen to the two sets of
    conversation and drag&nbsp; and drop the appropriate descriptions onto
    either "いく’s Diet Method " or "じゅん’s Diet Method ". If it does not
    match either, drag it to "Neither". If you get the right answer, you
    will hear "Right!!" and the illustration will stick to the chosen box.
    If it's wrong, the illustration will bounce back to the original
    location.)<br />
    <small>*Click "SHOW" if you need to refer to the transcripts</small><br />
    <br />
    <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>いく’s Diet <br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>じゅん’s Diet <br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addVideoPlayer("4.1.vc.1.1.mp4")}</td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addVideoPlayer("4.1.vc.1.2.mp4")}</td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}><Hideable content={transcriptLeft}/>
          </td>
          <td style={{verticalAlign: "top"}}><Hideable content={transcriptRight}/>
          </td>
        </tr>
      </tbody>
    </table>
    <DraggableForm prompts={draggableFormData.prompts} answers={draggableFormData.answers} matchGraph={draggableFormData.matchGraph}/>
  </>;
}

export default V1;
