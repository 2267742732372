import React from "react";
import { addRubyText, addAudio } from "common/util/generalUtil";

const Adverbs = () => {
  return <>
    <h2>3. Adverbial form of Adjectives</h2>
    Kansai adverbial form (くform) used to have an euphonic change in all
    the adverbial form, such as;<br />
    <small>*Mouse over or click on the speaker icon for sound<br />
    <br />
    </small>{addRubyText("早","はや")}い→{addRubyText("早","はよ")}う<small> (ST 早く)</small><br />
    {addRubyText("寒","さむ")}い→{addRubyText("寒","さむ")}う<small> (ST 寒く)</small><br />
    {addRubyText("大","おお")}きい→{addRubyText("大","おお")}きゅう<small> (ST 大きく)</small><br />
    ない→のう <small>(ST なく)</small><br />
    いい→よう <small>(ST よく)</small> {addAudio("3.4.3.1.mp3")}<br />
    <br />
    These expressions are still used among the older generation.<br />
    <br />
    お{addRubyText("醤油","しょゆ")}、のうなったし、{addRubyText("買","こ")}うてきてんか。<small>&nbsp;</small> {addAudio("3.4.3.2.mp3")}<br />
    <small>（ST お{addRubyText("醤油","しょうゆ")}がなくなったから、買ってきてよ。）</small><small>{addAudio("3.4.3.2.s.mp3")}</small><br />
    <br />
    Nowadays, young people prefer the short form ; that is, the adjective
    stem itself (or the stem part after the euphonic change)<br />
    <br />
    {addRubyText("早","はや")}い→{addRubyText("早","はよ")}う→はよ／はや <small>(ST 早く)</small><br />
    {addRubyText("寒","さむ")}→{addRubyText("寒","さむ")}う→さむ <small>(ST 寒く)</small><br />
    {addRubyText("大","おお")}きい→{addRubyText("大","おお")}きゅう→おおき<small> (ST 大きく)</small><br />
    <br />
    <small>* Adverbial form of いい remains よう (ST よく)<br />
    * Regular くform なくinstead of のう is used as くform ofない among younger
    generation</small><br />
    <br />
    In fact, this short form of the adverbial is the same as the stem of
    its negative form!<br />
    <br />
    <span style={{textDecoration: "underline"}}>はよ</span>来る(adv.) V.S. <span
    style={{textDecoration: "underline"}}>はよ</span>ない(neg.)<br />
    <span style={{textDecoration: "underline"}}>おおき</span>なる (adv.) V.S. <span
    style={{textDecoration: "underline"}}>おおき</span>ない (neg.)<br />
    <br />
    お{addRubyText("母","かあ")}さん、<span style={{textDecoration: "underline"}}>はよ</span>こっち来て。
    {addAudio("3.4.3.3.mp3")}<br />
    <small>(ST
    お母さん、早くこっちに来て。）</small><small>{addAudio("3.4.3.3.s.mp3")}</small><br />
    <br />
    ちょっと{addRubyText("見","み")}いひん{addRubyText("間","あいだ")}に<span style={{textDecoration: "underline"}}>大き</span>なっ
    たなあ。{addAudio("3.4.3.4.mp3")}<br />
    <small>(ST ちょっと見ない間に大きくなったねえ。）</small><small>{addAudio("3.4.3.4.s.mp3")}</small><br />
    <br />
    {addRubyText("漫画","まんが")}は<span style={{textDecoration: "underline"}}>よう</span>{addRubyText("読","よ")}むけど、
    {addRubyText("新聞","しんぶん")}は読まんなあ。{addAudio("3.4.3.5.mp3")}<br />
    <small>(ST 漫画はよく読むけど、新聞は読まないねえ。）</small><small>{addAudio("3.4.3.5.s.mp3")}</small><br />
    <br />
    {addRubyText("足","あし")}、どうしたん？<span style={{textDecoration: "underline"}}>{addRubyText("赤","あこ")}</span>（<span
    style={{textDecoration: "underline"}}>{addRubyText("赤","あか")}</span>）なってるで。{addAudio("3.4.3.6.mp3")}<br />
    <small>(ST 足、どうしたの？赤くなってるよ。)</small><small>{addAudio("3.4.3.6.s.mp3")}</small><br />
    <br />
    <hr />
    <h3>Extra 1: うまいこと=うまく<br />
    </h3>
    Another way to indicate a manner/degree by using adjectives in Kansai
    is [dictionary form]+こと. However, this expression is used only with the
    following adjectives;<br />
    <br />
    {addRubyText("結婚式","けっこんしき")}のスピーチ、<span style={{textDecoration: "underline"}}>うまいこと</span>
    {addRubyText("言","い")}えるやろか。<small> </small>{addAudio("3.4.3.7.mp3")}<br />
    <small>(ST 結婚式のスピーチ、うまく[={addRubyText("上手","じょうず")}に]言えるかなあ。）</small><small>{addAudio("3.4.3.7.s.mp3")}</small><br />
    <br />
    {addRubyText("大阪","おおさか")}にはもう<span style={{textDecoration: "underline"}}>{addRubyText("長","なが")}いこと</span>お
    るさかい、{addRubyText("大阪人","おおさかじん")}みたいなもんやな。<small> </small>{addAudio("3.4.3.8.mp3")}<br />
    <small>(ST 大阪にはもう長く[=長い間] いるから、大阪人みたいなもんだね！）</small><small>{addAudio("3.4.3.8.s.mp3")}</small><br />
    <br />
    {addRubyText("宿題","しゅくだい")}、<span style={{textDecoration: "underline"}}>早いこと</span>やって
    しもて、{addRubyText("遊","あそ")}びに{addRubyText("行","い")}こ！<small>&nbsp; </small>{addAudio("3.4.3.9.mp3")}<br />
    <small>(ST 宿題を早くやってしまって遊びに行こう！）</small><small>{addAudio("3.4.3.9.s.mp3")}</small>
  </>;
}

export default Adverbs;
