import React from "react";
import { Link } from "react-router-dom";
import { Page } from "home/page";
import { addImage } from "common/util/generalUtil";

const Index = () => {
  return <>
    <h2>INDEX</h2>
    <br />
    <table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>
            <Link to={Page.Index.Glossary}>
              Kansai Glossary
            </Link>
            <br />
            <Link to={Page.Index.GrammarIndex}>
              Kansai Grammar Index
            </Link>
            <br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addImage("osakaoudanhodoo.jpg")}
            <br />
            <small>横断歩道 crosswalk (Umeda, Osaka)</small>
            <br />
          </td>
        </tr>
      </tbody>
    </table>
  </>;
}

export default Index;