import React from "react";
import { addRubyText, addImage, addAudio } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";

const P5 = () => {
  return <>
<h2>{addRubyText("練習","れんしゅう")}5: Application Practice (Negative Permission &amp;
Obligation)</h2>
You and your Kansai friend are invited to Lisa’s birthday party. As all
the information about the party is in English, your Kansai friend asks
you some questions. Listen to her questions and answer them based on
the given information.
*Answers vary: example answers indicate those commonly used. <br />
<small>*Click SHOW if you need to refer to the transcript. <br />
*Move the mouse over to the speaker icon for sound.<br />
<br />
</small>
<table style={{textAlign: "center", width: "30%"}} align="right" border="0"
cellPadding="2" cellSpacing="2">
  <tbody>
    <tr>
      <td style={{verticalAlign: "top"}}>{addImage("4.2.p.5.1.jpg")}</td>
    </tr>
  </tbody>
</table>
<table style={{textAlign: "left", width: "60%"}} border="2"cellPadding="2"
 cellSpacing="2">
  <tbody>
    <tr>
      <td style={{verticalAlign: "top"}}><span style={{fontWeight: "bold"}}>Lisa’s
Birthday Party!</span><br />
Date&amp; Time: Oct.10 7:00pm<br />
Place:&nbsp; Restaurant Umeda (1 minute walk from Umeda station)<br />
Fee:&nbsp; 2000 yen/person (Children: under 12&nbsp; free!)<br />
Dress code: casual<br />
R.S.V.P&nbsp; by Oct. 1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
06-123-4567&nbsp; Tanaka<br />
      </td>
    </tr>
  </tbody>
</table>
<br />
<br />
<br />
<table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2"
 cellSpacing="2">
  <tbody>
    <tr>
      <td style={{verticalAlign: "top", textAlign: "center"}}>Friend's
Question <br />
      </td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>Example
Answer<br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>1. {addAudio("4.2.p.5.1.a.mp3")}
<Hideable content={<><small>ちゃんとした{addRubyText("服","ふく")}、{addRubyText("着","き")}ていかなあかんの？</small></>}/><br />
      </td>
      <td style={{verticalAlign: "top"}}>{addAudio("4.2.p.5.1.b.mp3")} <Hideable content={<>
      <small>いや、ちゃんとした{addRubyText("服","ふく")}は、{addRubyText("別","べつ")}に着んでもええよ。／いや、{addRubyText("普通","ふつう")}の服でええよ。</small></>}/><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>2. {addAudio("4.2.p.5.2.a.mp3")}
<Hideable content={<><small>お{addRubyText("金","かね")}{addRubyText("払","はら")}わんでもええんやんな？</small></>}/><br />
      </td>
      <td style={{verticalAlign: "top"}}>{addAudio("4.2.p.5.2.b.mp3")} <Hideable content={<>
      <small>いや、{addRubyText("一人","ひとり")}{addRubyText("二千円","にせんえん")}{addRubyText("払","はら")}わなあかんで。</small></>}/><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>3. {addAudio("4.2.p.5.3.a.mp3")}
<Hideable content={<><small>{addRubyText("子供","こども")}{addRubyText("連","つ")}れてってもかまへんにゃろか？</small></>}/><br />
      </td>
      <td style={{verticalAlign: "top"}}>{addAudio("4.2.p.5.3.b.mp3")} <Hideable content={<>
      <small>うん、{addRubyText("連","つ")}れてってもかまへんよ。</small></>}/><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>4. {addAudio("4.2.p.5.4.a.mp3")}
<Hideable content={<><small>{addRubyText("子供","こども")}もお{addRubyText("金","かね")}{addRubyText("払","はら")}わなあかんの？</small></>}/><br />
      </td>
      <td style={{verticalAlign: "top"}}>{addAudio("4.2.p.5.4.b.mp3")} <Hideable content={<>
      <small>{addRubyText("十二歳","じゅうにさい")}までの{addRubyText("子供","こども")}やったら{addRubyText("払","はら")}わんでもええよ。</small></>}/><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>5. {addAudio("4.2.p.5.5.a.mp3")}
<Hideable content={<><small>{addRubyText("駅","えき")}からタクシー、{addRubyText("乗","の")}らなあかんにゃろか？</small></>}/><br />
      </td>
      <td style={{verticalAlign: "top"}}>{addAudio("4.2.p.5.5.b.mp3")} <Hideable content={<>
      <small>{addRubyText("乗","の")}らんでもええよ。{addRubyText("駅","えき")}から{addRubyText("歩","ある")}いて{addRubyText("一分","いっぷん")}やし。</small></>}/><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>6. {addAudio("4.2.p.5.6.a.mp3")}
<Hideable content={<>&nbsp;<small> プレゼント、{addRubyText("持","も")}ってかなあかんのかなあ？</small></>}/><br />
      </td>
      <td style={{verticalAlign: "top"}}>{addAudio("4.2.p.5.6.b.mp3")} <Hideable content={<>
      <small>さあ、{addRubyText("書","か")}いたらへんし、{addRubyText("持","も")}ってっても{addRubyText("持","も")}ってかんでもええんちゃう？</small></>}/><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>7. {addAudio("4.2.p.5.7.a.mp3")}
<Hideable content={<><small>これ{addRubyText("別","べつ")}に{addRubyText("返事","へんじ")}せんと{addRubyText("行","い")}ってもええんやんな？</small></>}/><br />
      </td>
      <td style={{verticalAlign: "top"}}>{addAudio("4.2.p.5.7.b.mp3")} <Hideable content={<>
      <small>いや、{addRubyText("行","い")}きたかったら、{addRubyText("十月一日","じゅうがつついたち")}までに{addRubyText("返事","へんじ")}せんといかんよ。</small></>}/><br />
      </td>
    </tr>
  </tbody>
</table>
  </>;
}

export default P5;
