export const Page = {
    FirstRun: "/home", // kansaiben.com home page

    Characteristics: {
        Characteristics: "/characteristics",
        StandardVsKansai: "/characteristics/standardvskansai",
        HowKansaibenSoundsLike: "/characteristics/howkansaibensoundslike",
        LinguisticAspects: {
            LinguisticAspects: "/characteristics/linguisticaspects",
            Sound: "/characteristics/linguisticaspects/sound",
            Accent: "/characteristics/linguisticaspects/accent",
            Words: "/characteristics/linguisticaspects/words",
            Conjugation: "/characteristics/linguisticaspects/conjugation",
            Particles: "/characteristics/linguisticaspects/particles",
            SpeechStyle: "/characteristics/linguisticaspects/speechstyle",
            Honorifics: "/characteristics/linguisticaspects/honorifics"
        }
    },
    Expressions: {
        Expressions: "/expressions",
        Greetings: "/expressions/greetings",
        LeaveTakings: "/expressions/leavetakings",
        GratitudeApology: "/expressions/apology",
        OtherExpressions: "/expressions/otherexpressions"
    },
    BasicGrammar: {
        BasicGrammar: "/basicgrammar",
        Verbs: {
            Verbs: "/basicgrammar/verbs",
            Conversations: "/basicgrammar/verbs/conversations",
            Grammar: {
                Grammar: "/basicgrammar/verbs/grammar",
                NonPastNegative: "/basicgrammar/verbs/grammar/nonpastnegative",
                PastNegative: "/basicgrammar/verbs/grammar/pastnegative",
                TaForm: "/basicgrammar/verbs/grammar/taform",
                YouNegative: "/basicgrammar/verbs/grammar/younegative"
            },
            Practice: {
                Practice: "/basicgrammar/verbs/practice",
                P1: "/basicgrammar/verbs/practice/1",
                P2: "/basicgrammar/verbs/practice/2",
                P3: "/basicgrammar/verbs/practice/3",
            },
            Listening: {
                Listening: "/basicgrammar/verbs/listening",
                L1: "/basicgrammar/verbs/listening/1",
                L2: "/basicgrammar/verbs/listening/2",
                L3: "/basicgrammar/verbs/listening/3"
            },
            Video: {
                Video: "/basicgrammar/verbs/videocomprehension",
                V1: "/basicgrammar/verbs/videocomprehension/1",
                V2: "/basicgrammar/verbs/videocomprehension/2",
                V3: "/basicgrammar/verbs/videocomprehension/3"
            }
        },
        SentenceEndings: {
            SentenceEndings: "/basicgrammar/sentenceendings",
            Conversations: "/basicgrammar/sentenceendings/conversations",
            Grammar: {
                Grammar: "/basicgrammar/sentenceendings/grammar",
                SentenceParticles: "/basicgrammar/sentenceendings/grammar/sentenceparticles",
                ExtendedPredicate: "/basicgrammar/sentenceendings/grammar/extendedpredicate",
                Yanka: "/basicgrammar/sentenceendings/grammar/yanka",
                QuotationMarker: "/basicgrammar/sentenceendings/grammar/quotationmarker"
            },
            Practice: {
                Practice: "/basicgrammar/sentenceendings/practice",
                P1: "/basicgrammar/sentenceendings/practice/P1",
                P2: "/basicgrammar/sentenceendings/practice/P2",
                P3: "/basicgrammar/sentenceendings/practice/P3",
                P4: "/basicgrammar/sentenceendings/practice/P4",
                P5: "/basicgrammar/sentenceendings/practice/P5",
                P6: "/basicgrammar/sentenceendings/practice/P6",
                P7: "/basicgrammar/sentenceendings/practice/P7",
                P8: "/basicgrammar/sentenceendings/practice/P8"
            },
            Listening: {
                Listening: "/basicgrammar/sentenceendings/listening",
                L1: "/basicgrammar/sentenceendings/listening/1"
            },
            Video: {
                Video: "/basicgrammar/sentenceendings/videocomprehension",
                V1: "/basicgrammar/sentenceendings/videocomprehension/1"
            }
        },
        VerbTeForm: {
            VerbTeForm: "/basicgrammar/verbteform",
            Conversations: "/basicgrammar/verbteform/conversations",
            Grammar: {
                Grammar: "/basicgrammar/verbteform/grammar",
                TeFormation: "/basicgrammar/verbteform/grammar/teformation",
                TeContraction: "/basicgrammar/verbteform/grammar/tecontraction",
                TeFunctions: "/basicgrammar/verbteform/grammar/tefunctions",
                CasualRequest: "/basicgrammar/verbteform/grammar/casualrequest",
                NegativeRequest: "/basicgrammar/verbteform/grammar/negativerequest"
            },
            Practice: {
                Practice: "/basicgrammar/verbteform/practice",
                P1: "/basicgrammar/verbteform/practice/1",
                P2: "/basicgrammar/verbteform/practice/2",
                P3: "/basicgrammar/verbteform/practice/3",
                P4: "/basicgrammar/verbteform/practice/4",
                P5: "/basicgrammar/verbteform/practice/5"
            },
            Listening: {
                Listening: "/basicgrammar/verbteform/listening",
                L1: "/basicgrammar/verbteform/listening/1",
                L2: "/basicgrammar/verbteform/listening/2"
            },
            Video: {
                Video: "/basicgrammar/verbteform/videocomprehension",
                V1: "/basicgrammar/verbteform/videocomprehension/1"
            }
        },
        Copula: {
            Copula: "/basicgrammar/copula",
            Conversations: "/basicgrammar/copula/conversations",
            Grammar: {
                Grammar: "/basicgrammar/copula/grammar",
                Conjugation: "/basicgrammar/copula/grammar/conjugation",
                Houkaa: "/basicgrammar/copula/grammar/houkaa"
            },
            Practice: {
                Practice: "/basicgrammar/copula/practice",
                P1: "/basicgrammar/copula/practice/1",
                P2: "/basicgrammar/copula/practice/2",
                P3: "/basicgrammar/copula/practice/3",
                P4: "/basicgrammar/copula/practice/4",
                P5: "/basicgrammar/copula/practice/5"
            },
            Listening: {
                Listening: "/basicgrammar/copula/listening",
                L1: "/basicgrammar/copula/listening/1"
            }
        },
        Adjectives: {
            Adjectives: "/basicgrammar/adjectives",
            Conversations: "/basicgrammar/adjectives/conversations",
            Grammar: {
                Grammar: "/basicgrammar/adjectives/grammar",
                KansaiAdjectives: "/basicgrammar/adjectives/grammar/kansaiadjectives",
                Negative: "/basicgrammar/adjectives/grammar/negative",
                Adverbs: "/basicgrammar/adjectives/grammar/adverbs",
                TeForm: "/basicgrammar/adjectives/grammar/teform"
            },
            Practice: {
                Practice: "/basicgrammar/adjectives/practice",
                P1: "/basicgrammar/adjectives/practice/1",
                P2: "/basicgrammar/adjectives/practice/2",
                P3: "/basicgrammar/adjectives/practice/3",
                P4: "/basicgrammar/adjectives/practice/4",
                P5: "/basicgrammar/adjectives/practice/5"
            },
            Listening: {
                Listening: "/basicgrammar/adjectives/listening",
                L1: "/basicgrammar/adjectives/listening/1"
            },
            Video: {
                Video: "/basicgrammar/adjectives/videocomprehension",
                V1: "/basicgrammar/adjectives/videocomprehension/1",
                V2: "/basicgrammar/adjectives/videocomprehension/2"
            }
        }
    },
    FunctionalGrammar: {
        FunctionalGrammar: "/functionalgrammar",
        PermissionProhibition: {
            PermissionProhibition: "/functionalgrammar/permissionprohibition",
            Conversations: "/functionalgrammar/permissionprohibition/conversations",
            Grammar: {
                Grammar: "/functionalgrammar/permissionprohibition/grammar",
                Prohibition: "/functionalgrammar/permissionprohibition/grammar/prohibition",
                Permission: "/functionalgrammar/permissionprohibition/grammar/permission"
            },
            Practice: {
                Practice: "/functionalgrammar/permissionprohibition/practice",
                P1: "/functionalgrammar/permissionprohibition/practice/1",
                P2: "/functionalgrammar/permissionprohibition/practice/2",
                P3: "/functionalgrammar/permissionprohibition/practice/3",
                P4: "/functionalgrammar/permissionprohibition/practice/4"
            },
            Listening: {
                Listening: "/functionalgrammar/permissionprohibition/listening",
                L1: "/functionalgrammar/permissionprohibition/listening/1",
                L2: "/functionalgrammar/permissionprohibition/listening/2",
                L3: "/functionalgrammar/permissionprohibition/listening/3"
            },
            Video: {
                Video: "/functionalgrammar/permissionprohibition/videocomprehension",
                V1: "/functionalgrammar/permissionprohibition/videocomprehension/1",
                V2: "/functionalgrammar/permissionprohibition/videocomprehension/2"
            }
        },
        NegativePermissionObligation: {
            NegativePermissionObligation: "/functionalgrammar/negativepermissionobligation",
            Conversations: "/functionalgrammar/negativepermissionobligation/conversations",
            Grammar: {
                Grammar: "/functionalgrammar/negativepermissionobligation/grammar",
                NegativePermission: "/functionalgrammar/negativepermissionobligation/grammar/negativepermission",
                Unless: "/functionalgrammar/negativepermissionobligation/grammar/unless",
                Obligation: "/functionalgrammar/negativepermissionobligation/grammar/obligation"
            },
            Practice: {
                Practice: "/functionalgrammar/negativepermissionobligation/practice",
                P1: "/functionalgrammar/negativepermissionobligation/practice/1",
                P2: "/functionalgrammar/negativepermissionobligation/practice/2",
                P3: "/functionalgrammar/negativepermissionobligation/practice/3",
                P4: "/functionalgrammar/negativepermissionobligation/practice/4",
                P5: "/functionalgrammar/negativepermissionobligation/practice/5"
            },
            Listening: {
                Listening: "/functionalgrammar/negativepermissionobligation/listening",
                L1: "/functionalgrammar/negativepermissionobligation/listening/1",
                L2: "/functionalgrammar/negativepermissionobligation/listening/2"
            },
            Video: {
                Video: "/functionalgrammar/negativepermissionobligation/videocomprehension",
                V1: "/functionalgrammar/negativepermissionobligation/videocomprehension/1",
                V2: "/functionalgrammar/negativepermissionobligation/videocomprehension/2",
                V3: "/functionalgrammar/negativepermissionobligation/videocomprehension/3",
                V4: "/functionalgrammar/negativepermissionobligation/videocomprehension/4",
                V5: "/functionalgrammar/negativepermissionobligation/videocomprehension/5",
                V6: "/functionalgrammar/negativepermissionobligation/videocomprehension/6",
                V7: "/functionalgrammar/negativepermissionobligation/videocomprehension/7",
                V8: "/functionalgrammar/negativepermissionobligation/videocomprehension/8",
                V9: "/functionalgrammar/negativepermissionobligation/videocomprehension/9",
                V10: "/functionalgrammar/negativepermissionobligation/videocomprehension/10",
                V11: "/functionalgrammar/negativepermissionobligation/videocomprehension/11",
                V12: "/functionalgrammar/negativepermissionobligation/videocomprehension/12",
                V13: "/functionalgrammar/negativepermissionobligation/videocomprehension/13",
                V14: "/functionalgrammar/negativepermissionobligation/videocomprehension/14",
                V15: "/functionalgrammar/negativepermissionobligation/videocomprehension/15",
                V16: "/functionalgrammar/negativepermissionobligation/videocomprehension/16",
                V17: "/functionalgrammar/negativepermissionobligation/videocomprehension/17",
                V18: "/functionalgrammar/negativepermissionobligation/videocomprehension/18",
                V19: "/functionalgrammar/negativepermissionobligation/videocomprehension/19"
            }
        },
        Imperatives: {
            Imperatives: "/functionalgrammar/imperatives",
            Conversations: "/functionalgrammar/imperatives/conversations",
            Grammar: {
                Grammar: "/functionalgrammar/imperatives/grammar",
                Imperatives: "/functionalgrammar/imperatives/grammar/imperatives",
                NegativeImperatives: "/functionalgrammar/imperatives/grammar/negativeimperatives"
            },
            Practice: {
                Practice: "/functionalgrammar/imperatives/practice",
                P1: "/functionalgrammar/imperatives/practice/1",
                P2: "/functionalgrammar/imperatives/practice/2",
                P3: "/functionalgrammar/imperatives/practice/3",
                P4: "/functionalgrammar/imperatives/practice/4",
                P5: "/functionalgrammar/imperatives/practice/5"
            },
            Listening: {
                Listening: "/functionalgrammar/imperatives/listening",
                L1: "/functionalgrammar/imperatives/listening/1",
                L2: "/functionalgrammar/imperatives/listening/2",
                L3: "/functionalgrammar/imperatives/listening/3",
                L4: "/functionalgrammar/imperatives/listening/4"
            },
            Video: {
                Video: "/functionalgrammar/imperatives/video",
                V1: "/functionalgrammar/imperatives/video/1",
                V2: "/functionalgrammar/imperatives/video/2",
                V3: "/functionalgrammar/imperatives/video/3",
                V4: "/functionalgrammar/imperatives/video/4",
                V5: "/functionalgrammar/imperatives/video/5",
                V6: "/functionalgrammar/imperatives/video/6",
                V7: "/functionalgrammar/imperatives/video/7",
                V8: "/functionalgrammar/imperatives/video/8"
            }
        },
        Honorifics: {
            Honorifics: "/functionalgrammar/honorifics",
            Conversations: "/functionalgrammar/honorifics/conversations",
            Grammar: {
                Grammar: "/functionalgrammar/honorifics/grammar/grammar",
                Honorifics: "/functionalgrammar/honorifics/grammar/honorifics",
                Pejoratives: "/functionalgrammar/honorifics/grammar/pejoratives",
                ThirdPersonAction: "/functionalgrammar/honorifics/grammar/thirdpersonaction"
            },
            Video: {
                Video: "/functionalgrammar/honorifics/video",
                V1: "/functionalgrammar/honorifics/video/1",
                V2: "/functionalgrammar/honorifics/video/2",
                V3: "/functionalgrammar/honorifics/video/3",
                V4: "/functionalgrammar/honorifics/video/4",
                V5: "/functionalgrammar/honorifics/video/5",
                V6: "/functionalgrammar/honorifics/video/6",
                V7: "/functionalgrammar/honorifics/video/7",
                V8: "/functionalgrammar/honorifics/video/8",
                V9: "/functionalgrammar/honorifics/video/9",
                V10: "/functionalgrammar/honorifics/video/10",
                V11: "/functionalgrammar/honorifics/video/11",
                V12: "/functionalgrammar/honorifics/video/12"
            }
        },
        Even: {
            Even: "/functionalgrammar/even",
            Conversations: "/functionalgrammar/even/conversations",
            Grammar: {
                Grammar: "/functionalgrammar/even/grammar",
                Even: "/functionalgrammar/even/grammar/even",
                EvenIf: "/functionalgrammar/even/grammar/evenif"
            },
            Video: {
                Video: "/functionalgrammar/even/videocomprehension",
                V1: "/functionalgrammar/even/videocomprehension/1",
                V2: "/functionalgrammar/even/videocomprehension/2"
            }
        }
    },
    TalkInTown: {
        TalkInTown: "/talkintown",
        FishShop: "/talkintown/fishshop",
        VegetableShop: "/talkintown/vegetableshop",
        EelShop: "/talkintown/eelshop",
        Peddler: "/talkintown/peddler",
        PickleStore: "/talkintown/picklestore",
        FruitShop: "/talkintown/fruitshop",
        RawOyster: "/talkintown/rawoyster",
        GrilledOyster: "/talkintown/grilledoyster",
        ConfectionStore: "/talkintown/confectionStore",
        BlackbeanTea: "/talkintown/blackbeantea",
        BlackbeanTea2: "/talkintown/blackbeantea2",
        Strawberry: "/talkintown/strawberry",
        RiceCake: "/talkintown/ricecake",
        FishShopKyoto: "/talkintown/fishshopkyoto"
    },

    Interviews: "/interviews",
    Interviewees: "/interviews/interviewees",
    InterviewQuestions: "/interviews/questions",
    InterviewClips: "/interviews/clips",

    Resources: "/resources",
    OtherResources: {
        OtherResources: "/otherresources",
        AnimeManga: "/otherresources/animemanga",
        Films: "/otherresources/films",
        JPop: "/otherresources/jpop",
        Links: "/otherresources/links",
        Foreigners: {
            Foreigners: "/otherresources/foreigners",
            LeeEnglish: "/otherresources/leeenglish",
            LeeKansaiben: "/otherresources/leekansaiben",
            EricEnglish: "/otherresources/ericenglish",
            EricKansaiben: "/otherresources/erickansaiben",
        },
        LettersFromUsers: "/otherresources/lettersfromusers",
        HowToUse: "/otherresources/howtouse"
    },
    About: {
        About: "/about",
        AboutThisProject: "/about/thisproject",
        AboutPeople: "/about/people",
        ContactInformation: "/about/contactinfo",
        References: "/about/references"
    },
    Index: {
        Index: "/index",
        Glossary: "/index/glossary",
        GrammarIndex: "/index/grammar"
    },
    ReadFirst: "/readFirst",
    Sitemap: "/sitemap"
};