import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";

const Conversations = () => {
  const KansaiTranscript = <ol className="transcript">
    <li>
      <small>
        A: きょう、<span style={{fontWeight: "bold"}}>え</span>いが
        <span style={{fontWeight: "bold"}}>いくな</span>あ。<br/>
      </small>
    </li>
    <li>
      <small>
        B: <span style={{fontWeight: "bold"}}>え</span>いが？う
        <span style={{fontWeight: "bold"}}>う</span>ん、<span style={{fontWeight: "bold"}}>い</span>けへんわ。<br/>
      </small>
    </li>
    <li>
      <small>
        A: え<span style={{fontWeight: "bold"}}>ぇ</span>？
        <span style={{fontWeight: "bold"}}>き</span>のう<span style={{fontWeight: "bold"}}>いく</span>、
        <span style={{fontWeight: "bold"}}>ゆ</span>うたやんか。なんで
        <span style={{fontWeight: "bold"}}>い</span>かへんのん？<br/>
      </small>
    </li>
    <li>
      <small>
        B: <span style={{fontWeight: "bold"}}>あした</span>、テストあ<span style={{fontWeight: "bold"}}>る</span>ねん。<br/>
      </small>
    </li>
    <li>
      <small>
        A: あ、<span style={{fontWeight: "bold"}}>そうかあ</span>。
        <span style={{fontWeight: "bold"}}>そ</span>んなら、ら
        <span style={{fontWeight: "bold"}}>い</span>しゅう
        <span style={{fontWeight: "bold"}}>い</span>かへん<span style={{fontWeight: "bold"}}>かあ</span>。<br/>
      </small>
    </li>
    <li>
      <small>
        B: ら<span style={{fontWeight: "bold"}}>い</span>しゅう？え<span style={{fontWeight: "bold"}}>え</span>で。
      </small>
    </li>
  </ol>;

  const StandardTranscript =  <ol className="transcript">
    <li>
      <small>
        A: {addRubyText("今日","きょう")}{addRubyText("映画","えいが")}{addRubyText("行","い")}くよね？<br/>
      </small>
    </li>
    <li>
      <small>
        B: 映画？ううん、{addRubyText("行","い")}けないよ。<br/>
      </small>
    </li>
    <li>
      <small>
        A: え？{addRubyText("昨日","きのう")}行くって言ったじゃない。どうして行かないの？ 
      </small>
    </li>
    <li>
      <small>
        B: {addRubyText("明日","あした")}、テストがあるの。<br/>
      </small>
    </li>
    <li>
      <small>
        A: あ、そう。じゃあ、{addRubyText("来週","らいしゅう")}行かない？<br/>
      </small>
    </li>
    <li>
      <small>
        B: 来週？いいよ。
      </small>
    </li>
  </ol>;

  return <>
    <h2>{addRubyText("基本会話","きほんかいわ")} (Core Conversations)</h2>
    <hr/>
    <h3>{addRubyText("会話","かいわ")}１: Will they go see a movie today?</h3>
    *A=first speaker, B=second speaker<br/>
    *Click "SHOW" for transcripts<br/>
    <br/>
    (A and B are friends in college)<br/>
    <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}} className="video" colSpan="1" rowSpan="2">
            {addVideoPlayer("3.1.b.cc.1.mp4")}
          </td>
          <td style={{verticalAlign: "top"}} className="transcriptcell">
            Kansai transcript 
            <br/>
            <Hideable content={KansaiTranscript}/>
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}} className="transcriptcell">
            Standard transcript 
            <br/> 
            <Hideable content={StandardTranscript}/>
          </td>
        </tr>
      </tbody>
    </table>
    <br/>
    <hr/>
    <h3>{addRubyText("理解度","りかいど")}チェック (Check-up)<br/></h3>
    Figure out which Kansai-peculiar sentence ending expressions function
    as the following. Refer to the standard and Kansai scripts if necessary.<br/>
    <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Check-up
            <br/>
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Answer
            <br/>
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            Tag Question/Conformation (ね)<br/>
          </td>
          <td style={{verticalAlign: "top"}}>
            <Hideable content={<>な</>}/><br/>
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            Assertion (よ)<br/>
          </td>
          <td style={{verticalAlign: "top"}}>
            <Hideable content={<>で／わ</>}/><br/>
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            When asking for a reason (どうして〜の？)<br/>
          </td>
          <td style={{verticalAlign: "top"}}>
            <Hideable content={<>なんで〜のん／の？</>}/><br/>
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            When explaining a reason (〜の／んだ)<br/>
          </td>
          <td style={{verticalAlign: "top"}}>
            <Hideable content={<>〜ねん</>}/><br/>
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            Strong conformation 〜じゃない／じゃん！<br/>
          </td>
          <td style={{verticalAlign: "top"}}>
            <Hideable content={<>〜やんか／やん！</>}/><br/>
          </td>
        </tr>
      </tbody>
    </table>
  </>;
}

export default Conversations;
