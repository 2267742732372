import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";

const V7 = () => {
  const transcript = <>
    うちの{addRubyText("家","いえ")}にはな、あの、いや、アパートしてっさかいな、{addRubyText("中国人","ちゅうごくじん")}ばっかり<span
    style={{textDecoration: "underline"}}>いやん</span>(Grammar
    3)にゃ。うん、中国人でも、まあまあ、中国人、{addRubyText("日本語","にほんご")}<span
    style={{textDecoration: "underline"}}>しゃべってらっ</span>(Grammar 1 Extra
    5)けどな、やっぱしちょっと{addRubyText("聞","き")}きにくいとこがある。、、あんまり{addRubyText("僕","ぼく")}ら、あの、しゃべらへんけど、お{addRubyText("母","かあ")}さん(=his
    wife)が、みな、もうな、あの、ちょっとちょっとお{addRubyText("金","かね")}<span
    style={{textDecoration: "underline"}}>{addRubyText("持","も")}ってきてくれらっ</span>(Grammar 1
    Extra 5)と、みな、{addRubyText("十分","じゅっぷん")}か{addRubyText("十五分","じゅうごふん")}<span
    style={{textDecoration: "underline"}}>しゃべってらっ</span>(Grammar 1 Extra
    5)さかいな。その{addRubyText("時以外","ときいがい")}は僕らもうしゃべらへんわな。もう{addRubyText("出会","であ")}わへんわ。
  </>;

  const qaObjects = [
    {
      question: <span>Q1: What people who came from overseas does he know?</span>,
      answersCorrect: [
        <>Chinese people who live in the apartment he owns</>
      ],
      answersIncorrect: [
        <>Chinese people who live in the same apartment building as him</>,
        <>Chinese people who live near his apartment</>
      ]
    },
    {
      question: <span>Q2: Do they speak Japanese fluently?</span>,
      answersCorrect: [
        <>They do speak Japanese, but there are some parts that are hard to understand.</>
      ],
      answersIncorrect: [
        <>They speak a mixture of Japanese and Chinese, which makes their talk hard to understand.</>,
        <>They hardly speak Japanese, and that makes it hard for him to communicate with them.</>,
        <>They speak perfect Japanese, and it’s not hard to understand at all.</>
      ]
    },
    {
      question: <span>Q3: For whom did he use らる?</span>,
      answersCorrect: [
        <>The Chinese people</>
      ],
      answersIncorrect: [
        <>His wife</>,
        <>Himself</>,
        <>Interviewer</>
      ]
    }
  ];

  return <>
<h2>ビデオ{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}７</h2>
Watch the video clip and choose the answer that matches the content of
the clip.
Submit your answers after answering all the questions.<br />
<small>*Click "SHOW" if you need to refer to the transcript</small><br />
<br />
Context: Matsuda-san was asked if he knew
any foreigners in Japan.<br />
<table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2" cellSpacing="2">
  <tbody>
    <tr>
      <td style={{verticalAlign: "top", textAlign: "left"}}>{addVideoPlayer("4.4.1.p.10.matsuda.mp4")}&nbsp; <br />
      </td>
      <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>Transcript
<Hideable content={transcript}/><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top", textAlign: "left"}}>マツダさん (Shiga)</td>
    </tr>
  </tbody>
</table>
<br />
<QandAForm qaObjects={qaObjects}/>
  </>;
}

export default V7;
