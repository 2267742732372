import React from "react";
import { Link } from "react-router-dom";
import { Page } from "home/page";
import { addRubyText, addImage } from "common/util/generalUtil";

const Listening = () => {
  return <>
    <h2>{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")} (Listening Practice)</h2>
    <br />
    <table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td colSpan="1" rowSpan="1" style={{verticalAlign: "top"}}>
            <ul>
              <li>
                <Link to={Page.BasicGrammar.Verbs.Listening.L1}>{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}1</Link>
              </li>
              <li>
                <Link to={Page.BasicGrammar.Verbs.Listening.L1}>{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}2</Link>
              </li>
              <li>
                <Link to={Page.BasicGrammar.Verbs.Listening.L1}>{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}3</Link>
              </li>
            </ul>
          </td>
          <td colSpan="1" rowSpan="1" style={{verticalAlign: "top", textAlign: "center"}}>
            {addImage("shinsaibashi2.jpg")}<br />
            <small>心斎橋筋 Shinsai-bashi street (Osaka)</small><br />
          </td>
        </tr>
        <tr align="center">
        </tr>
      </tbody>
    </table>
    <br />
    <br />
  </>;
}

export default Listening;
