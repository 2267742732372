import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";

const V1 = () => {
  const kansaiTranscript = <>
    <br />
    <ol>
      <li>A：<span style={{fontWeight: "bold"}}>き</span>のう、<span
style={{fontWeight: "bold"}}>う</span>ちに<span style={{fontWeight: "bold"}}>で
んわし</span>たら、<span style={{fontWeight: "bold"}}>ははおやにま</span>た<span
style={{fontWeight: "bold"}}>おこら</span>れてん。</li>
      <li>B：な<span style={{fontWeight: "bold"}}>ん</span>て？</li>
      <li>A：<span style={{fontWeight: "bold"}}>も</span>っと<span
style={{fontWeight: "bold"}}>よ</span>う<span style={{fontWeight: "bold"}}>でん
わしてきぃ</span>って。</li>
      <li>B：ふ〜ん。<span style={{fontWeight: "bold"}}>それだけ？</span></li>
      <li>A：う<span style={{fontWeight: "bold"}}>う</span>ん。ほか<span
style={{fontWeight: "bold"}}>に</span>も、<span style={{fontWeight: "bold"}}>ちゃ
ん</span>とた<span style={{fontWeight: "bold"}}>べ</span>、とか、<span
style={{fontWeight: "bold"}}>へ</span>やそうじ<span style={{fontWeight: "bold"}}>しぃ</span>、
とか、おさ<span style={{fontWeight: "bold"}}>け</span>ばっ<span
style={{fontWeight: "bold"}}>か</span>りの<span style={{fontWeight: "bold"}}>み</span>な
とか、もう<span style={{fontWeight: "bold"}}>うる</span>さい、<span
style={{fontWeight: "bold"}}>うる</span>さい。</li>
      <li>B ：まあ、いっぱい<span style={{fontWeight: "bold"}}>お</span>やは<span
style={{fontWeight: "bold"}}>ゆうこ</span>とあ<span style={{fontWeight: "bold"}}>る</span>ん
やって。</li>
      <li>A：<span style={{fontWeight: "bold"}}>せ</span>やけど、<span
style={{fontWeight: "bold"}}>でんわだいもったい</span>ないから、<span
style={{fontWeight: "bold"}}>だ</span>らだら<span style={{fontWeight: "bold"}}>しゃ
べり</span>なやってき<span style={{fontWeight: "bold"}}>ら</span>れてん。</li>
      <li>B：な<span style={{fontWeight: "bold"}}>ん</span>や、それ。</li>
    </ol>
  </>;

  const standardTranscript = <>
    <br />
    <ol>
      <li>A:{addRubyText("昨日","きのう")}、うちに{addRubyText("電話","でんわ")}したら{addRubyText("母親","ははおや")}にまたしかられたの。</li>
      <li>B: なんて？</li>
      <li>A:もっとよく電話して{addRubyText("来","き")}なさいって。</li>
      <li>B: ふ〜ん、それだけ？</li>
      <li>A:
ううん、ちゃんと{addRubyText("食","た")}べなさい、お{addRubyText("酒","さけ")}あんまり{addRubyText("飲","の")}んじゃだめとか、もううるさい、うるさい。</li>
      <li>B: まあ、いっぱい親は{addRubyText("言","い")}うことがあるんだって。</li>
      <li>A:
だけど、電話{addRubyText("代","だい")}もったいないから、だらだら{addRubyText("話","はな")}さないで、って{addRubyText("切","き")}られたの。</li>
      <li>B:なんなの？それ。</li>
    </ol>
  </>;

  const qaObjects = [
    {
      question: <span>Q: What did いく mother tell her to do? Choose all the
      applicable answers.</span>,
      answersCorrect: [
        <>Call home more often.</>,
        <>Eat appropriately.</>,
        <>Don’t drink alcohol all the time.</>,
        <>Clean the room.</>,
        <>Don’t waste money by chatting so long on the phone.</>
      ],
      answersIncorrect: [
        <>Don’t eat too much.</>,
        <>Talk more on phone.</>,
        <>Do dishes.</>,
        <>Drink healthy drinks.</>,
        <>Come home more often.</>,
        <>Don’t waste money by calling home so often.</>
      ]
    }
  ]

  return <>
    <h2>ビデオ{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}１</h2>
    This is a conversation between いく(first speaker) and じゅん (second
    speaker).<br />
    <br />
    <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2"
    cellSpacing="2">
      <tbody>
        <tr>
          <td colSpan="1" rowSpan="2"
    style={{verticalAlign: "top", textAlign: "center"}}>{addVideoPlayer("4.3.cv.1.mp4")}</td>
          <td style={{verticalAlign: "top"}}>Kansai transcript
    <Hideable content={kansaiTranscript}/>
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>Standard transcript <Hideable content={standardTranscript}/>
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    <QandAForm qaObjects={qaObjects}/>
  </>;
}

export default V1;
