import React from "react";
import { addRubyText } from "common/util/generalUtil";
import { DraggableForm } from "common/util/draggableForm";

const L1 = () => {
  const draggableForm1 = {
    prompts: {
      texts: [
        <>{addRubyText("行","い")}くよ。</>,
        <>{addRubyText("行","い")}くね？</>,
        <>{addRubyText("行","い")}くの？</>,
        <>{addRubyText("行","い")}く？</>
      ]
    },
    answers: {
      audioFiles: [
        "3.1.b.pl0.1.1.c.mp3",
        "3.1.b.pl0.1.1.b.mp3",
        "3.1.b.pl0.1.1.d.mp3",
        "3.1.b.pl0.1.1.a.mp3"
      ],
      texts: [
        <>バーゲン{addRubyText("行","い")}くで。</>,
        <>バーゲン{addRubyText("行","い")}くなあ。</>,
        <>バーゲン{addRubyText("行","い")}くん？</>,
        <>バーゲン{addRubyText("行","い")}くかあ。</>
      ]
    }
  };

  const draggableForm2 = {
    prompts: {
      texts: [
        <>あるんだよ。</>,
        <>あるんだ。</>,
        <>あるかなあ。</>,
        <>あるんだよね。</>,
        <>ないんだよ。</>
      ]
    },
    answers: {
      audioFiles: [
        "3.1.b.pl0.1.2.d.mp3",
        "3.1.b.pl0.1.2.a.mp3",
        "3.1.b.pl0.1.2.b.mp3",
        "3.1.b.pl0.1.2.c.mp3",
        "3.1.b.pl0.1.2.e.mp3"
      ],
      texts: [
        <>{addRubyText("今日","きょう")}{addRubyText("仕事","しごと")}あんにゃで。</>,
        <>{addRubyText("今日","きょう")}{addRubyText("仕事","しごと")}あんねん。</>,
        <>{addRubyText("今日","きょう")}{addRubyText("仕事","しごと")}あるかいな。</>,
        <>{addRubyText("今日","きょう")}{addRubyText("仕事","しごと")}あるねんなあ。</>,
        <>{addRubyText("今日","きょう")}{addRubyText("仕事","しごと")}あらんにゃわ。</>
      ] 
    }
  }

  const draggableForm3 = {
    prompts: {
      texts: [
        <>{addRubyText("見","み")}ない？</>,
        <>{addRubyText("見","み")}なかったの？</>,
        <>{addRubyText("見","み")}ないの？</>,
        <>{addRubyText(" 見","み")}なさい！</>,
        <>{addRubyText("見","み")}るんだよ。</>
      ]
    },
    answers: {
      audioFiles: [
        "3.1.b.pl0.1.3.a.mp3",
        "3.1.b.pl0.1.3.d.mp3",
        "3.1.b.pl0.1.3.b.mp3",
        "3.1.b.pl0.1.3.e.mp3",
        "3.1.b.pl0.1.3.c.mp3"
      ],
      texts: [
        <>{addRubyText("映画","えいが")}{addRubyText("見","み")}いひん？</>,
        <>{addRubyText("映画","えいが")}{addRubyText("見","み")}んかったん？</>,
        <>{addRubyText("映画","えいが")}{addRubyText("見","み")}いひんのん？</>,
        <>{addRubyText("映画","えいが")}{addRubyText("見","み")}んかいな！</>,
        <>{addRubyText("映画","えいが")}{addRubyText("見","み")}るねんわ。</>
      ]
    }
  }

  const draggableForm4 = {
    prompts: {
      texts: [
        <>{addRubyText("買","か")}ったの？</>,
        <>{addRubyText("買","か")}うわけないだろ！</>,
        <>{addRubyText("買","か")}ったんだ。</>,
        <>{addRubyText("買","か")}ったじゃん！</>,
        <>{addRubyText("買","か")}わなかったんだ。</>
      ]
    },
    answers: {
      audioFiles: [
        "3.1.b.pl0.1.4.b.mp3",
        "3.1.b.pl0.1.4.e.mp3",
        "3.1.b.pl0.1.4.a.mp3",
        "3.1.b.pl0.1.4.d.mp3",
        "3.1.b.pl0.1.4.c.mp3"
      ],
      texts: [
        <>{addRubyText("車","くるま")}{addRubyText("買","こ")}うたん？</>,
        <>{addRubyText("車","くるま")}？{addRubyText("買","か")}うかいな！</>,
        <>{addRubyText("車","くるま")}{addRubyText("買","こ")}うてん。</>,
        <>{addRubyText("車","くるま")}？{addRubyText("買","こ")}うたやんか。</>,
        <>{addRubyText("車","くるま")}{addRubyText("買","か")}わんかってん。</>
      ]
    }
  }

  return <>
    <h2>{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}1</h2>
    Listen to the audio and drag and drop it to the standard expression
    that conveys the same meaning. If you get a right answer, you will hear
    "Right!!" and the audio will stick to the standard expression. If it's
    wrong, the audio will bounce back to the original location.
    <br />
    <small>*Click "SHOW" if you need to refer to the transcript
      <br />
      *Mouse over or click on the speaker icon for sound
    </small>
    <br />
    <br />
    <hr />
    <h4>(1)</h4>
    <DraggableForm prompts={draggableForm1.prompts} answers={draggableForm1.answers} />
    <br />
    <h4>(2)</h4>
    <DraggableForm prompts={draggableForm2.prompts} answers={draggableForm2.answers} />
    <br />
    <h4>(3)</h4>
    <DraggableForm prompts={draggableForm3.prompts} answers={draggableForm3.answers} />
    <br />
    <h4>(4)</h4>
    <DraggableForm prompts={draggableForm4.prompts} answers={draggableForm4.answers} />
  </>;
}

export default L1;
