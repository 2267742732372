import React from "react";
import { Link } from "react-router-dom";
import { Page } from "home/page";
import { addRubyText, addAudio } from "common/util/generalUtil";

const NegativePermission = () => {
  return <>
<h2>1.&nbsp; 〜んでもいい／ええ (Negative Permission) <br />
</h2>
<h3>Summary of expressions of negative permission</h3>
<table style={{textAlign: "left", width: "100%"}} border="1"cellPadding="2"
 cellSpacing="2">
  <tbody>
    <tr>
      <td style={{verticalAlign: "top", textAlign: "center"}}>Standard<br />
      </td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>Kansai<br />
      </td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>Kansai
(Polite)<br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜なくてもいい<br />
      </td>
      <td style={{verticalAlign: "top"}}>V(short negative) でもいい／ええ<br />
      </td>
      <td style={{verticalAlign: "top"}}>〜V(short negative)
でもいいです／ええです／よろし（い）です／よろしおます(Osaka)／よろしおす(Kyoto)<br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜なくてもかまわない</td>
      <td style={{verticalAlign: "top"}}>V(short negative)
でもかまわん／かまへん／かめへん(Osaka)</td>
      <td style={{verticalAlign: "top"}}>〜V(short negative)
でもかまいません／かまいまへん／かましまへん／かめしまへん(Osaka)</td>
    </tr>
  </tbody>
</table>
<br />
When giving negative permission, the short negative form of verbs is
used in Kansai instead of てform of negatives（〜なくて）in Standard. Refer to
<Link to={Page.BasicGrammar.Verbs.Grammar.NonPastNegative}>
Verb negatives in Ch3 Lesson 1a
</Link> for the details of V short negative
forms. <br />
<br />
Vなくてもいいー＞Vん(V short negative)+でもいい／ええ／かまわん／かまへん（かめへん in Osaka) <br />
{addRubyText("借","か")}りんでもええ、{addRubyText("見","み")}んでもいい、{addRubyText("行","い")}かんでもかまへん、せんでもええ、{addRubyText("来","こ")}んでもええ {addAudio("4.2.1.1.mp3")}<br />
&nbsp;<br />
* せ in せんでもええ is a classical negative stem of する used for its short
negative form.<br />
<br />
この{addRubyText("教科書","きょうかしょ")}{addRubyText("高","たか")}いし、{addRubyText("買","か")}わんでもええやんなあ。→うん、{addRubyText("別","べつ")}に{addRubyText("買","こ")}うても{addRubyText("買","か")}わんでもええんちゃ
う？{addAudio("4.2.1.2.mp3")}<br />
<small>(ST この教科書は高いから買わなくてもいいんだよね。—＞うん、別に買っても買わなくてもいいんじゃない？)</small><small>{addAudio("4.2.1.2.s.mp3")}</small><br />
<br />
For Nouns and Adjectives, Kansai negative gerund (てform) is commonly
used. Refer to 
<Link to={Page.BasicGrammar.Copula.Grammar.Conjugation}>
  Copula Conjugation in Ch3 Lesson 3
</Link>, and 
<Link to={Page.BasicGrammar.Adjectives.Grammar.TeForm}>
  て form of Adjectives in Ch3 Lesson 4
</Link> for detailed information.<br />
<br />
{addRubyText("安","やす")}のうても{addRubyText("大","おお")}きのうてもええから、いっぺんぐらいいいホテル{addRubyText("泊","と")}まりたいわあ。{addAudio("4.2.1.3.mp3")}<br />
<small>(ST 安くなくても大きくなくてもいいから、{addRubyText("一度","いちど")}ぐらいいいホテルに泊まりたいよ。)</small><small>{addAudio("4.2.1.3.s.mp3")}</small><br />
<br />
{addRubyText("服","ふく")}なんかきれいでもきれいちごてもかまへん。{addRubyText("男","おとこ")}は{addRubyText("中身","なかみ")}が{addRubyText("大事","だいじ")}や。{addAudio("4.2.1.4.mp3")}<br />
<small>(ST 服なんかきれいでもきれいじゃなくてもかまわない。男は中身が大事だ。)</small><small>{addAudio("4.2.1.4.s.mp3")}</small><br />
<br />
<small>* 中身 inside, contents, substance</small>
  </>;
}

export default NegativePermission;
