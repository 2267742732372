import React from "react";
import { Link } from "react-router-dom";
import { Page } from "home/page";
import { addImage } from "common/util/generalUtil";
import "css/common.scss";

const About = () => {
  return (
    <>
      <h2>ABOUT</h2>
      <table className="custom-table">
        <tbody>
        <tr>
          <td>
            <ul>
              <li>
                <Link to={Page.About.AboutThisProject}>About This Project</Link>
              </li>
              <li>
                <Link to={Page.About.AboutPeople}>About People</Link>
              </li>
              <li>
                <Link to={Page.About.ContactInformation}>Contact Information</Link>
              </li>
              <li>
                <Link to={Page.About.References}>References</Link>
              </li>
            </ul>
          </td>
          <td>
            <div>{addImage("kofukuji5juunotoo.jpg")}</div>
            <div>
              <small>興福寺の五重塔　Five-story Pagota at Kofuku-ji Temple (Nara)</small>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
      <br />
      <br />
    </>
  );
};

export default About;
