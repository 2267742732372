import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";

const V19 = () => {
  const transcript = <>
    でもなあ、{addRubyText("親","おや")}も{addRubyText("大変","たいへん")}や，{addRubyText("今","いま")}、う〜ん。今、{addRubyText("大学","だいがく")}{addRubyText("言","ゆ")}うても，{addRubyText("結構","けっこう")}、なあ，
    {addRubyText("国公立","こっこうりつ")}でも結構{addRubyText("高","たこ")}うつくわな。{addRubyText("授業料","じゅぎょうりょう")}{addRubyText("高","たこ")}なったらしいで。うん、あのな、{addRubyText("二人","ふたり")}、
    あの、
    {addRubyText("大阪外大","おおさかがいだい")}も、あ、あの、{addRubyText("国立","こくりつ")}か、なんかしらん、やし、{addRubyText("兄貴","あにき")}の{addRubyText("方","ほう")}は、あの、大阪{addRubyText("市大","いちだい")}
    やで{addRubyText("公立","こうりつ")}やなあ。ほで、まあまあ国公立やさかい、二人で{addRubyText("年間","ねんかん")}１４０{addRubyText("万","まん")}ぐらい、７０万ほど<span
    style={{textDecoration: "underline"}}>{addRubyText("払","はら")}わんならん</span> (Grammar
    3)。、、な。も一人{addRubyText("京都","きょうと")}の{addRubyText("妹","いもうと")}の{addRubyText("子","こ")}なんか、あの、{addRubyText("私立","わたくしりつ")}やさかいに、
    {addRubyText("東海大平塚","とうかいだいひらつか")}ゆうて、{addRubyText("神奈川県","かながわけん")}の東海大{addRubyText("行","い")}っとるけど、、、それは{addRubyText("私","わたくし")}や。
    {addRubyText("中学校","ちゅうがっこう")}から{addRubyText("付属","ふぞく")}の中学校{addRubyText("入","はい")}ってこう行ってそのまま、もう、それは年間{addRubyText("馬鹿","ばか")}ほど<span
    style={{textDecoration: "underline"}}>払わんならん</span> (Grammar 3)。
  </>;

  const qaObjects = [
    {
      question: <span>Q1: How much is the tuition per student for national and public
      universities?</span>,
      answersCorrect: [
        <>700,000-yen per year</>
      ],
      answersIncorrect: [
        <>1,400,000-yen per year</>,
        <>140,000-yen per year</>,
        <>7,000,000-yen per year </>
      ]
    },
    {
      question: <span>Q2: What is the situation of college tuition nowadays according to Ishioh-san?</span>,
      answersCorrect: [
        <>Tuition for national and public universities has gotten quite expensive, but that of private universities is not in comparison.</>
      ],
      answersIncorrect: [
        <>Tuition for private universities has gotten quite expensive, but national and public universities are still affordable.</>,
        <>Tuition for national and public universities has been raised to the same level as that of private universities.</>
      ]
    }
  ];

  return <>
    <h2>ビデオ{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}１９: Authentic - long<br />
    </h2>
    Watch the video clips and choose the answer that matches the content of
    each clip.
    Submit your answers after answering all the questions.<br />
    <small>*Click "SHOW" if you need to refer to the transcript.</small><br />
    <br />
    Ishioh-san is talking about college tuitions nowadays.<br />
    <table style={{textAlign: "left", width: "100%"}} border="0"cellPadding="2"
    cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addVideoPlayer("4.2.4.p.4.ishioh.mp4")}</td>
          <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>
            Transcript
            <Hideable content={transcript}/>
            <br />
            ＊{addRubyText("国公立","こっこうりつ")}national and
            public schools <br />
            ＊{addRubyText("授業料","じゅぎょうりょう")} tuition<br />
            ＊{addRubyText("国立","こくりつ")} national (schools) <br />
            ＊{addRubyText("兄貴","あにき")}older brother<br />
            ＊{addRubyText("公立","こうりつ")} public (schools) <br />
            ＊{addRubyText("私立","わたくしりつ")} private (schools)<br />
            ＊{addRubyText("付属","ふぞく")}attached (school) <br />
            ＊{addRubyText("馬鹿","ばか")}ほど to the ridiculous extent<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
          <div style={{textAlign: "left"}}>イシオウさん (Shiga)</div>
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    <QandAForm qaObjects={qaObjects}/>
  </>;
}

export default V19;
