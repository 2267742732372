import React from "react";
import { addRubyText } from "common/util/generalUtil";

const TeFormation = () => {
  return <>
    <h2>1. て form Formation <br />
    </h2>
    Kansai Verb てform is formed exactly the same way as たform. Therefore,
    Verbs that end withうhave an euphonic change when formingてform.
    <br />
    <br />
    <table style={{textAlign: "left", width: "60%"}} border="1" cellPadding="2"
    cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>a/o-う→ -oo-て</td>
          <td style={{verticalAlign: "top"}}>
            {addRubyText("買","か")}う→こうて <small>(ST 買って）</small><br />
            {addRubyText("払","はら")}う→はろ（う）て <small>(ST 払って）</small><br />
            {addRubyText("酔","よ")}う→ようて <small>(ST 酔って）</small><br />
            {addRubyText("通","かよ")}う→かよ（う）て <small>(ST 通って）</small><br />
            {addRubyText("使","つか")}う→つこ（う）て <small>(ST 使って）</small><br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>-i-う→ -iu-て</td>
          <td style={{verticalAlign: "top"}}>{addRubyText("言","い／ゆ")}う→いうて／ゆうて <small>(ST 言って）</small><br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>-u-う→ -uu-て</td>
          <td style={{verticalAlign: "top"}}>
            {addRubyText("吸","す")}う→すうて <small>(ST 吸って</small>）<br />
            ふるう→ふる（う）て<small>（ST ふるって）</small><br />
          </td>
        </tr>
      </tbody>
    </table>
  </>;
}

export default TeFormation;
