import React from "react";
import { Link } from "react-router-dom";
import { Page } from "home/page";
import { addRubyText, addImage } from "common/util/generalUtil";

const Practice = () => {
  return <>
    <h2>{addRubyText("練習","れんしゅう")} (Practice)</h2>
    <table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2"
    cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>
          <ul>
            <li>
              <Link to={Page.FunctionalGrammar.Imperatives.Practice.P1}>
                {addRubyText("練習","れんしゅう")}1: Imperatives (gentle) - Pattern Practice
              </Link>
            </li>
            <li>
              <Link to={Page.FunctionalGrammar.Imperatives.Practice.P2}>
                {addRubyText("練習","れんしゅう")}2: Imperatives (stronger)- Pattern Practice
              </Link>
            </li>
            <li>
              <Link to={Page.FunctionalGrammar.Imperatives.Practice.P3}>
                {addRubyText("練習","れんしゅう")}3: Negative Imperatives (gentle) - Pattern Practice
              </Link>
            </li>
            <li>
              <Link to={Page.FunctionalGrammar.Imperatives.Practice.P4}>
                {addRubyText("練習","れんしゅう")}4: Imperatives and Negative Imperatives
              </Link>
            </li>
            <li>
              <Link to={Page.FunctionalGrammar.Imperatives.Practice.P5}>
                {addRubyText("練習","れんしゅう")}5: Utilization
              </Link>
            </li>
          </ul>
          </td>
          <td style={{verticalAlign: "top"}}>
          <div style={{textAlign: "center"}}>{addImage("kinosakiashiyu2.jpg")}<br />
          </div>
          <div style={{textAlign: "center"}}><small>足湯 Patio foot spa (Kinosaki, Hyogo)</small><br />
          </div>
          </td>
        </tr>
      </tbody>
    </table>
  </>;
}

export default Practice;
