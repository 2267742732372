import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";

const FishShopKyoto = () => {
  const vocabList = <ul style={{display: "block", overflow: "visible"}}>
    <li>
      たい sea bream, red snapper
    </li>
    <li>
      ひらめ flounder
    </li>
    <li>
      {addRubyText("身","み")} body
    </li>
    <li>
      やらかい (Kansai) = {addRubyText("柔","やわ")}らかい soft, tender
    </li>
    <li>
      たこ octopus
    </li>
    <li>
      よこわ young tuna
    </li>
    <li>
      お{addRubyText("造","つく")}り (Kansai) = さしみ
    </li>
    <li>
      お{addRubyText("買","か")}い{addRubyText("得","どく")} a bargain
    </li>
    <li>
      さば mackerel
    </li>
    <li>
      うなぎ eel
    </li>
    <li>
      はも pike conger (eel)
    </li>
    <li>
      トロ fatty meat of tuna
    </li>
    <li>
      {addRubyText("鉄火巻","てっかまき")} tuna roll
    </li>
    <li>
      お{addRubyText("値打","ねう")}ち value
    </li>
    <li>
      {addRubyText("価格","かかく")} price
    </li>
    <li>
      ありまっせ (Kansai) = ありますよ
    </li>
    <li>
      〜{addRubyText("貫","かん")} counter for sushi
    </li>
    <li>
      {addRubyText("割","わ")}り{addRubyText("勘","かん")} fifty-fifty, going Dutch
    </li>
  </ul>;

  const qaObjects = [
    {
      question: <span>Q1: How much is お{addRubyText("造","つく")}り?</span>,
      answersIncorrect: [
        <span>1000-yen for three pieces.</span>,
        <span>300-yen for 10 pieces.</span>
      ],
      answersCorrect: [
        <span>1000-yen for three packs.</span>
      ]
    },
    {
      question: <span>Q2: How many pieces of トロの{addRubyText("鉄火巻","てっかまき")} per package, and for how much?</span>,
      answersIncorrect: [
        <span>500-yen per pack (20 pieces).</span>,
        <span>150-yen per pack (12 pieces).</span>,
        <span>150-yen per pack (20 pieces).</span>
      ],
      answersCorrect: [
        <span>500-yen per pack (12 pieces).</span>
      ]
    }
  ];

  return (
    <>
      <h2>{addRubyText("京都","きょうと")}の{addRubyText("魚屋","さかなや")}で (At a fish shop in Kyoto)</h2>
      <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>
            [Context] A fish dealer is trying hard to sell fish.
          </td>
        </tr>
        </tbody>
      </table>
      <br />
      <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addVideoPlayer("5.14.tekka.mp4")}<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            Vocabulary List <br />
            <Hideable content={vocabList} />
          </td>
        </tr>
        </tbody>
      </table>
      <h3>{addRubyText("内容質問","ないようしつもん")} (Content Questions)</h3>
      <QandAForm qaObjects={qaObjects} />
    </>
  );
};

export default FishShopKyoto;
