import React from "react";
import { Link } from "react-router-dom";
import { Page } from "home/page";
import { addRubyText, addImage, addAudio } from "common/util/generalUtil";

const Imperatives = () => {
  return <>
<h2>1. Kansai Imperatives “Do…!” <br />
</h2>
When you want somebody to do something, you can use either the request
form or imperatives, depending on the addressee and the situation. It’s
the same in Kansai-ben, however, there are Kansai-specific imperatives
indicated in the chart below. <br />
(As for casual request form using てform, refer to 
<Link to={Page.BasicGrammar.VerbTeForm.Grammar.CasualRequest}>
Grammar 4 in Ch3 Lesson 2
</Link>) <br />
<br />
<table style={{textAlign: "left", width: "100%"}} border="1"cellPadding="2"
 cellSpacing="2">
  <tbody>
    <tr>
      <td style={{verticalAlign: "top"}}><br />
      </td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>Standard<br />
      </td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>(example)<br />
      </td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>Kansai<br />
      </td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>(example)<br />
      </td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>(sound)<br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>Request (favor)<br />
      </td>
      <td style={{verticalAlign: "top"}}>〜てくれない？</td>
      <td style={{verticalAlign: "top"}}>{addRubyText("書","か")}いてくれない？<br />
      </td>
      <td style={{verticalAlign: "top"}}>〜てくれへん？<br />
〜てんか。<br />
      <br />
      </td>
      <td style={{verticalAlign: "top"}}>書いて<span
 style={{fontWeight: "bold"}}>く</span>れへん？<br />
書いてん<span style={{fontWeight: "bold"}}>か</span>。<br />
      </td>
      <td style={{verticalAlign: "top"}}>{addAudio("4.3.1.1.mp3")}<br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>Request</td>
      <td style={{verticalAlign: "top"}}>〜て（ね／よ）</td>
      <td style={{verticalAlign: "top"}}>書いて。<br />
書いてね。<br />
書いてよ。<br />
      </td>
      <td style={{verticalAlign: "top"}}>〜て。<br />
〜てな／や。</td>
      <td style={{verticalAlign: "top"}}>書いて。<br />
書いて<span style={{fontWeight: "bold"}}>な</span>。<br />
書いて<span style={{fontWeight: "bold"}}>や</span>。</td>
      <td style={{verticalAlign: "top"}}>{addAudio("4.3.1.2.mp3")}<br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>Request (stronger)</td>
      <td style={{verticalAlign: "top"}}>-<br />
      </td>
      <td style={{verticalAlign: "top"}}>-<br />
      </td>
      <td style={{verticalAlign: "top"}}>〜てぇな／や。<br />
      </td>
      <td style={{verticalAlign: "top"}}>書い<span
 style={{fontWeight: "bold"}}>て</span>ぇな。<br />
書い<span style={{fontWeight: "bold"}}>て</span>ぇや。<br />
      </td>
      <td style={{verticalAlign: "top"}}>{addAudio("4.3.1.3.mp3")}<br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>Command (parents to children)</td>
      <td style={{verticalAlign: "top"}}>Masu-stem+なさい</td>
      <td style={{verticalAlign: "top"}}>書きなさい。<br />
      </td>
      <td style={{verticalAlign: "top"}}>-<br />
      </td>
      <td style={{verticalAlign: "top"}}>-<br />
      </td>
      <td style={{verticalAlign: "top"}}><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>Command<br />
“Unless you do…/You must…”</td>
      <td style={{verticalAlign: "top"}}>V(negative stem) + なくちゃ／なきゃ。<br />
      </td>
      <td style={{verticalAlign: "top"}}>書かなくちゃ。<br />
書かなきゃ。<br />
      <br />
      </td>
      <td style={{verticalAlign: "top"}}>V(negative stem) んと／な<br />
(refer to G2 in Ch4 Lesson 2)<br />
      <br />
      </td>
      <td style={{verticalAlign: "top"}}>書かん<span
 style={{fontWeight: "bold"}}>と</span>。<br />
書<span style={{fontWeight: "bold"}}>か</span>な。<br />
      <br />
      </td>
      <td style={{verticalAlign: "top"}}>{addAudio("4.3.1.4.mp3")}<br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>Kansai Imperatives (gentle)</td>
      <td style={{verticalAlign: "top"}}>-<br />
      </td>
      <td style={{verticalAlign: "top"}}>-<br />
      </td>
      <td style={{verticalAlign: "top"}}>Masu-stem （+ な／や／よし: Kyoto,
feminine）<br />
      </td>
      <td style={{verticalAlign: "top"}}>書<span style={{fontWeight: "bold"}}>き</span>。<br />
書き<span style={{fontWeight: "bold"}}>な</span>。<br />
書き<span style={{fontWeight: "bold"}}>や</span>。<br />
（書<span style={{fontWeight: "bold"}}>き</span>よし。）<br />
      </td>
      <td style={{verticalAlign: "top"}}>{addAudio("4.3.1.5.mp3")}<br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>Kansai Imperatives (stronger)<br />
      </td>
      <td style={{verticalAlign: "top"}}>-<br />
      </td>
      <td style={{verticalAlign: "top"}}>-<br />
      </td>
      <td style={{verticalAlign: "top"}}>Masu-stem (longer-vowel)
+な／や&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>書きぃな。<br />
書きぃや。</td>
      <td style={{verticalAlign: "top"}}> {addAudio("4.3.1.6.mp3")}</td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>Imperatives</td>
      <td style={{verticalAlign: "top"}}>Ru: Stem+ろ<br />
U: Stem-U, +E<br />
Irr:しろ, こい</td>
      <td style={{verticalAlign: "top"}}>{addRubyText("食","た")}べろ。<br />
書け。<br />
{addRubyText("勉強","べんきょう")}しろ。<br />
{addRubyText("行","い")}って{addRubyText("来","こ")}い。<br />
      </td>
      <td style={{verticalAlign: "top"}}><br />
      </td>
      <td style={{verticalAlign: "top"}}><br />
      </td>
      <td style={{verticalAlign: "top"}}><br />
      </td>
    </tr>
  </tbody>
</table>
<br />
<table style={{textAlign: "left", width: "100%"}} border="0"cellPadding="2"
 cellSpacing="2">
  <tbody>
    <tr>
      <td style={{verticalAlign: "top", textAlign: "center"}}>{addImage("4.3.1.1.jpg")}</td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>{addImage("4.3.1.2.jpg")}</td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>{addImage("4.3.1.3.jpg")}</td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>{addImage("4.3.1.4.jpg")}</td>
    </tr>
  </tbody>
</table>
<br />
The formation of the Kansai imperatives is as follows;<br />
<table style={{textAlign: "left", width: "100%"}} border="1"cellPadding="2"
 cellSpacing="2">
  <tbody>
    <tr>
      <td style={{verticalAlign: "top"}}><br />
      </td>
      <td style={{verticalAlign: "top"}}><br />
      </td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>(example)<br />
      </td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>(sound)<br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>Ru-Verb<br />
      </td>
      <td style={{verticalAlign: "top"}}>Masu-stem<br />
(*one mora stem tends to be lengthened)<br />
      </td>
      <td style={{verticalAlign: "top"}}>食べ、{addRubyText("起","お")}き、{addRubyText("見","み")}ぃ、{addRubyText("寝","ね")}ぇ、やってみぃ<br />
      </td>
      <td style={{verticalAlign: "top"}}>{addAudio("4.3.1.7.mp3")}<br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>U-Verb</td>
      <td style={{verticalAlign: "top"}}>Masu-stem</td>
      <td style={{verticalAlign: "top"}}>{addRubyText("飲","の")}み、行き、{addRubyText("帰","かえ")}り、書き、やめとき、やってしまい</td>
      <td style={{verticalAlign: "top"}}>{addAudio("4.3.1.8.mp3")}</td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>する</td>
      <td style={{verticalAlign: "top"}}>しぃ／せぇ(blunt)</td>
      <td style={{verticalAlign: "top"}}>勉強しぃ、{addRubyText("相談","そうだん")}せぇ</td>
      <td style={{verticalAlign: "top"}}>{addAudio("4.3.1.9.mp3")}</td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>来る</td>
      <td style={{verticalAlign: "top"}}>きぃ</td>
      <td style={{verticalAlign: "top"}}>{addRubyText("買","こ")}うてきぃ、もろてきぃ</td>
      <td style={{verticalAlign: "top"}}>{addAudio("4.3.1.10.mp3")}<br />
      </td>
    </tr>
  </tbody>
</table>
<br />
The Kansai Imperatives can be used more widely compared to the regular
imperatives. It can be an equivalent of&nbsp; 〜なさい, but can be used for
anybody who is close to the speaker unlike the standard in which&nbsp;
〜なさい is usually used by parents (especially mothers) for their
children. The Kansai imperatives can even indicate a gentle suggestion.<br />
<br />
(Mother to her child) ちょっと、いつまでもテレビ見てんと、はよ{addRubyText("消","け")}して寝ぇ。{addAudio("4.3.1.11.mp3")}<br />
<small>(ST ちょっと、いつまでもテレビを見ていないで、早く消して寝なさい。）{addAudio("4.3.1.11.s.mp3")}</small><br />
<br />
<table style={{textAlign: "left", width: "30%"}} align="right" border="0"
cellPadding="2" cellSpacing="2">
  <tbody>
    <tr>
      <td style={{verticalAlign: "top", textAlign: "center"}}>{addImage("4.3.1.5.jpg")} </td>
    </tr>
  </tbody>
</table>
(To one’s father)
そんなドラえもんのネクタイなんか{addRubyText("恥","は")}ずかしし、やめとき。{addAudio("4.3.1.12.mp3")}<br />
<small>(ST そんなドラえもんのネクタイなんか恥かしいから、やめといたら？）</small><small>{addAudio("4.3.1.12.s.mp3")}</small>　<br />
<small><br />
*ドラえもん Doraemon ( a famous Japanese comic character ) </small><br />
<br />
<table style={{textAlign: "left", width: "30%"}} align="right" border="0"
cellPadding="2" cellSpacing="2">
  <tbody>
    <tr>
      <td style={{verticalAlign: "top", textAlign: "center"}}>{addImage("4.3.1.6.jpg")}</td>
    </tr>
  </tbody>
</table>
(To one’s friend) あの{addRubyText("新","あたら")}しポカリのコマーシャル見てみぃ。おもろいで。{addAudio("4.3.1.13.mp3")}<br />
<small>(ST あの新しいポカリのコマーシャル見てみたら？おもしろいよ。) </small><small>{addAudio("4.3.1.13.s.mp3")}</small><br />
<br />
<small>* ポカリ＝ポカリスエット Pocari Sweat (a popular Japanese sport drink) </small><br />
<br />
(To one’s friend)
{addRubyText("危","あぶ")}な〜！もうちょっとで{addRubyText("電柱","でんちゅう")}ぶつかるとこやったやんか！ちゃんと{addRubyText("前","まえ")}見て{addRubyText("運転","うんてん")}しぃ！{addAudio("4.3.1.14.mp3")}<br />
<small>(ST 危ない！もうちょっとで電柱にぶつかるところだったじゃん！ちゃんと前見て運転しなさい／しろ！)</small><small>{addAudio("4.3.1.14.s.mp3")}</small><br />
<small><br />
* {addRubyText("電柱","でんちゅう")}: utility pole, telephone pole</small>
<br />
<br />
The Kansai imperatives are often used with assertive particles な／や, and
they can be stronger if the mora preceding the particles is accentuated.<br />
<br />
はよ<span style={{fontWeight: "bold"}}>{addRubyText("寝","ね")}ぇや</span>／はよ<span
 style={{fontWeight: "bold"}}>寝ぇな </span>＜ はよ<span
 style={{fontWeight: "bold"}}>寝</span>ぇや／はよ<span
 style={{fontWeight: "bold"}}>寝</span>ぇな {addAudio("4.3.1.15.mp3")}<br />
{addRubyText("食","た")}べ<span style={{fontWeight: "bold"}}>や</span>／食べ<span
 style={{fontWeight: "bold"}}>な</span> ＜ 食<span style={{fontWeight: "bold"}}>べ</span>ぇ
や／食<span style={{fontWeight: "bold"}}>べ</span>ぇな {addAudio("4.3.1.16.mp3")}<br />
<span style={{fontWeight: "bold"}}>{addRubyText("手伝","てつだ")}いや</span>／<span
 style={{fontWeight: "bold"}}>手伝いな</span> ＜<span
 style={{fontWeight: "bold"}}>手伝い</span>ぃや／<span
 style={{fontWeight: "bold"}}>手伝い</span>ぃな {addAudio("4.3.1.17.mp3")} <br />
<br />
<hr />
<h3>Extra 1: はよ{addRubyText("食","た")}べよし</h3>
You might hear women of the older generation in Kyoto add よし instead of
な／や. よし is a traditional feminine assertive particle in Kyoto used with
imperative expressions, and it functions the same as な／や. The accent
always comes preceding よし whether it is meant as a gentle suggestion or
a command.<br />
<br />
(Kyoto, female, to a friend) ちょこっとだけ{addRubyText("休","やす")}んで{addRubyText("行","い")}きよし。{addAudio("4.3.1.18.mp3")}<br />
<small>（ST ちょっとだけ休んで行ったら？）</small><small>{addAudio("4.3.1.18.s.mp3")}</small><br />
<br />
(Kyoto, female, to her child) {addRubyText("何","なに")}ゆっくりしてんの！はよ行きよし！{addAudio("4.3.1.19.mp3")}<br />
<small>（ST 何ゆっくりしてるの！早く行きなさい！）</small><small>{addAudio("4.3.1.19.s.mp3")}</small><br />
<br />
<hr />
<h3>Extra 2: Standard imperatives in Kansai</h3>
Kansai people do use standard despite the fact that they have their own
(maybe due to the recent invasion of standard Japanese into this
dialect.) Standard imperatives are really strong, blunt commands,
usually (but not limited to be) used by male speakers. The Kansai
assertive particle や (not な) may be added to the end.<br />
<br />
(Male to a friend) 何してんねん！はよ来い！{addAudio("4.3.1.20.mp3")}<br />
<small>（ST 何してんのさ！早く来い！）</small><small>{addAudio("4.3.1.20.s.mp3")}</small><br />
<br />
(Father to his child) {addRubyText("寝","ね")}るまでにちゃんと{addRubyText("宿題","しゅくだい")}やっとけや。{addAudio("4.3.1.21.mp3")}<br />
<small>(ST 寝るまでにちゃんと宿題やっておけよ。)</small><small>{addAudio("4.3.1.21.s.mp3")}</small><br />
<br />
(Kansai yakuza) {addRubyText("金","かね")}{addRubyText("出","だ")}せちゅうてんのが{addRubyText("分","わ")}からんのか！ {addAudio("4.3.1.22.mp3")}<br />
<small>(ST 金を出せって言ってるのが分からないのか？)</small><small>{addAudio("4.3.1.22.s.mp3")}</small>
  </>;
}

export default Imperatives;
