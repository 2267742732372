import React from "react";
import { addRubyText, addAudio } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";

const L2 = () => {
  const qaObjects = [
    {
      question: <span>
        1. {addAudio("3.1.a.pl0.2.1.mp3")}
        <Hideable content={<>お{addRubyText("父","とう")}ちゃんの{addRubyText("言","い")}うことは、いっつもようわからん。</>} /> 
      </span>,
      answersIncorrect: [
        <>“I cannot do…”</>
      ],
      answersCorrect: [
        <>“not well/clear”</>
      ]
    },
    {
      question: <span>
        2. {addAudio("3.1.a.pl0.2.2.mp3")}
        <Hideable content={<>１{addRubyText("時間","じかん")}もよう{addRubyText("歩","ある")}かへんわ。</>} /> 
      </span>,
      answersIncorrect: [
        <>“not well/clear”</>
      ],
      answersCorrect: [
        <>“I cannot do...”</>
      ]
    },
    {
      question: <span>
        3. {addAudio("3.1.a.pl0.2.3.mp3")}
        <Hideable content={<>そんな{addRubyText("長","なが")}い{addRubyText("会話","かいわ")}、よう{addRubyText("覚","おぼ")}えん。</>} /> 
      </span>,
      answersIncorrect: [
        <>“not well/clear”</>
      ],
      answersCorrect: [
        <>“I cannot do...”</>
      ]
    },
    {
      question: <span>
        4. {addAudio("3.1.a.pl0.2.4.mp3")}
        <Hideable content={<>{addRubyText("前","まえ")}に{addRubyText("頭","あたま")}{addRubyText("大","おお")}きい人いて、よう{addRubyText("見","み")}えんかった。</>} /> 
      </span>,
      answersCorrect: [
        <>“not well/clear”</>
      ],
      answersIncorrect: [
        <>“I cannot do…”</>
      ]
    },
    {
      question: <span>
        5. {addAudio("3.1.a.pl0.2.5.mp3")}
        <Hideable content={<>{addRubyText("私","わたし")}、{addRubyText("東京","とうきょう")}のことはよう{addRubyText("知","し")}らんから、、、 </>} /> 
      </span>,
      answersCorrect: [
        <>“not well/clear”</>
      ],
      answersIncorrect: [
        <>“I cannot do…”</>
      ]
    },
    {
      question: <span>
        6. {addAudio("3.1.a.pl0.2.6.mp3")}
        <Hideable content={<>{addRubyText("社長","しゃちょう")}からのおみやげ？そんなん、ようもらわん。</>} /> 
      </span>,
      answersIncorrect: [
        <>“not well/clear”</>
      ],
      answersCorrect: [
        <>“I cannot do...”</>
      ]
    }
  ];

  return <>
    <h2>{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}2</h2>
    Listen to the audio, and decide whether it means <span style={{fontWeight: "bold"}}>a. “not well/clear”</span> or 
    <span style={{fontWeight: "bold"}}>b. “I cannot do…”</span> Submit your answers after finising all the questions.<br />
    <small>
      * Click "SHOW" if you need to refer to the transcript<br />
      * Move the mouse over the speaker icon for sound
    </small>
    <br />
    <br />
    {addRubyText("例","れい")}(example)：お{addRubyText("酒","さけ")}？{addRubyText("私","わたし")}、よう{addRubyText("飲","の")}まん。
    {addAudio("3.1.a.pl0.2.0.mp3")} <br />
    Ans. b. "I cannot do..."<br />
    <br />
    Point: You can distinguish two different types of よう by their pitch
    pattern and the type of verbs that follow (refer to Grammar 4)<br />
    <br />
    <QandAForm qaObjects={qaObjects} />
  </>;
  
}

export default L2;
