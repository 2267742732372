import React from "react";
import { Link } from "react-router-dom";
import { Page } from "home/page";
import { addRubyText, addImage } from "common/util/generalUtil";

const Copula = () => {
  return <>
    <h2>Lesson 3: Copula</h2>
    <h3>〜{addRubyText("前","まえ")}は{addRubyText("好","す")}きやってんけど、{addRubyText("今","いま")}は{addRubyText("好","す")}きちゃうねん。〜</h3>
    <table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td colSpan="1" rowSpan="1" style={{verticalAlign: "top"}}>
          <ul>
            <li>
              <Link to={Page.BasicGrammar.Copula.Conversations}>
                {addRubyText("基本会話","きほんかいわ")} (Core Conversations)
              </Link>
            </li>
            <li>
              <Link to={Page.BasicGrammar.Copula.Grammar.Grammar}>
                {addRubyText("文法","ぶんぽう")}ノート (Grammar Note)
              </Link>
            </li>
            <li>
              <Link to={Page.BasicGrammar.Copula.Practice.Practice}>
                {addRubyText("練習","れんしゅう")} (Practice)
              </Link>
            </li>
            <li>
              <Link to={Page.BasicGrammar.Copula.Listening.Listening}>
                {addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")} (Listening Practice)
              </Link>
            </li>
          </ul>
          </td>
          <td colSpan="1" rowSpan="1" style={{verticalAlign: "top", textAlign: "center"}}>
            {addImage("kinjo.jpg")}
            <br/>
            <small>
              家 Traditional houses (Otsu, Shiga)
            </small>
            <br/>
          </td>
        </tr>
        <tr align="center">
        </tr>
      </tbody>
    </table>
  </>;
}

export default Copula;
