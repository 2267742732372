import React from "react";
import { addAudio, addVideoPlayer } from "common/util/generalUtil";

const GratitudeApology = () => {
  return (
    <>
      <h2>Gratitude and Apology</h2>
      <p>
        <small>*<b>BOLD</b> type indicates high pitch<br />*Mouse over or click on the speaker icon for sound</small>
      </p>
      <table style={{textAlign: "left", width: "100%", minWidth: "600px"}} border="1" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Standard
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Kansai<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            どうぞ {addAudio("2.3.1.s.mp3")}
          </td>
          <td style={{verticalAlign: "top"}}>
            <b>どうぞぉ</b> {addAudio("2.3.1.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            どうも {addAudio("2.3.2.s.mp3")}
          </td>
          <td style={{verticalAlign: "top"}}>
            <b>ど</b>うも {addAudio("2.3.2.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            すみません {addAudio("2.3.3.s.mp3")}
          </td>
          <td style={{verticalAlign: "top"}}>
            すんま<b>せん</b>、すいま
            <b>せん</b>
            <b>、す</b>まん (blunt){addAudio("2.3.3.mp3")}</td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>どうもすみません {addAudio("2.3.4.s.mp3")}
          </td>
          <td style={{verticalAlign: "top"}}>
            <b>ど</b>うもすんま<b>せん</b>、
            <b>え</b>らいすんま
            <b>せん</b>*{addAudio("2.3.4.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            ごめんなさい、ごめん {addAudio("2.3.5.s.mp3")}
          </td>
          <td style={{verticalAlign: "top"}}>
            ごめん<b>な</b>さい、ごめ<b>ん </b>(blunt) {addAudio("2.3.5.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            ありがとう {addAudio("2.3.6.s.mp3")}
          </td>
          <td style={{verticalAlign: "top"}}>
            ありが<b>と</b>う、おお<b>き</b>に* {addAudio("2.3.6.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            ありがとうございます {addAudio("2.3.7.s.mp3")}
          </td>
          <td style={{verticalAlign: "top"}}>
            <b>ありが</b>と<b>ございますぅ</b>、おお<b>き</b>にすんま<b>せん</b> {addAudio("2.3.7.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            どうもありがとう{addAudio("2.3.8.s.mp3")}
          </td>
          <td style={{verticalAlign: "top"}}>
            <b>え</b>らいおお<b>き</b>に {addAudio("2.3.8.mp3")}
          </td>
        </tr>
        </tbody>
      </table>
      <br />

      <p>
        *えらい : has three meanings in the Kansai dialect; 1) splendid, 2) tired and 3) very much.<br />
        *おおきに：comes from おおきい＋に "very much". Often used by older generation and
        in local business occasions. The intonation varies from おお
        <b>き</b>に to <b>お</b>お<b>き</b>に。<br />
      </p>
      <hr />

      <h3>ビデオ１</h3>
      <p>
        Let's observe how Kansai people express their gratitude!
      </p>
      <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Standard
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Kansai
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addVideoPlayer("2.3.1.mp4")}
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addVideoPlayer("2.3.2.mp4")}
          </td>
        </tr>
        </tbody>
      </table>
      <br />

      <h3>ビデオ２</h3>
      <p>
        Here are a couple video clips of conversation involving expressions of gratitude.
      </p>
      <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Between neighbors (gift giving)
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            When a woman runs into a shop owner in her neighborhood
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addVideoPlayer("2.3.3.mp4")}
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addVideoPlayer("2.3.4.mp4")}
          </td>
        </tr>
        </tbody>
      </table>
    </>
  );
};

export default GratitudeApology;