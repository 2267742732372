import { Constants } from './constants';

export function changeInterviewees(data) {
  return {
    type: Constants.CHANGE_INTERVIEWEES,
    data
  };
}

export function changeInterviewQuestionCount(data) {
  return {
    type: Constants.CHANGE_INTERVIEW_QUESTION_COUNT,
    data
  };
}

export function changeInterviewQuestionList(data) {
  return {
    type: Constants.CHANGE_INTERVIEW_QUESTION_LIST,
    data
  };
}

export function changeInterviewClipsCount(data) {
  return {
    type: Constants.CHANGE_INTERVIEW_CLIP_COUNT,
    data
  };
}

export function changeInterviewClipList(data) {
  return {
    type: Constants.CHANGE_INTERVIEW_CLIP_LIST,
    data
  };
}

export function changeInterviewClipQandA(data) {
  return {
    type: Constants.CHANGE_INTERVIEW_CLIP_Q_AND_A,
    data
  };
}
