import React from "react";
import { addRubyText, addAudio } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";

const P1 = () => {
  const transcript = <>
    <ol className="transcript">
      <li>A: ジュース、買った？</li>
      <li>B: ジュース？ううん、まだ買ってない。</li>
    </ol>
  </>;
  return <>
    <h2>{addRubyText("練習","れんしゅう")}1 : て form</h2>
    Form a question and an answer for each cue according to
    a model. You may listen to the question, try to answer it, and check
    your answer.*Answers vary; the recorded answers indicate those commonly used.
    <br />
    <small>*Mouse over or click on the speaker icon for sound</small>
    <br />
    <br />
    <span style={{fontWeight: "bold"}}>Model</span> (Cue: ジュース／{addRubyText("買","か")}う)
    <br />
    &nbsp;&nbsp;&nbsp; A: ジュース、{addRubyText("買","こ")}うた？
    <br />
    &nbsp;&nbsp;&nbsp; B: ジュース？ううん、まだ{addRubyText("買","こ")}うてへん。{addAudio("3.2.p.1.mp3")}
    <br />
    <br />
    Point: Be careful for forming たform and てform from うverbs that end with
    -A-うsounds (refer to Grammar 1)
    <br />
    <span style={{textDecoration: "underline"}}></span>
    <br />
    ST transcript 
    <br />
    <Hideable content={transcript} />
    <br />
    <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Cue<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            A (Question)<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            B (Answer)<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            ジュース／{addRubyText("買","か")}う<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.1.1.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.1.1.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            あの{addRubyText("子","こ")}／{addRubyText("寝","ね")}る<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.1.2.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.1.2.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addRubyText("本","ほん")}／{addRubyText("借","か")}りてくる<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.1.3.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.1.3.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            あの{addRubyText("映画","えいが")}／{addRubyText("見","み")}る<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.1.4.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.1.4.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            ニュース／{addRubyText("聞","き")}く<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.1.5.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.1.5.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addRubyText("辞書","じしょ")}／{addRubyText("使","つか")}う<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.1.6.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.1.6.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            おみやげ／もらう<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.1.7.a.mp3")}
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.1.7.b.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addRubyText("友達","ともだち")}に／{addRubyText("言","い")}う<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.1.8.a.mp3")}
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.1.8.b.mp3")}
          </td>
        </tr>
      </tbody>
    </table>
  </>;
}

export default P1;
