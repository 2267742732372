import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";

const V5 = () => {
  const transcript = <>
    {addRubyText("怒","おこ")}らはる時もある。はよテレビ<span style={{textDecoration: "underline"}}>{addRubyText("終","お")}わりいや</span>(Grammar
    1)っていわはる。はよ、おばあちゃん{addRubyText("達","たち")}に<span style={{textDecoration: "underline"}}>{addRubyText("見","み")}
    せてあげえや</span> (Grammar 1) って言わはる。
  </>;

  const qaObjects = [
    {
      question: <span>Q: When does his mother scold him? Choose the most likable situation.</span>,
      answersCorrect: [
        <>When Takashi keeps watching the TV program he wants to watch and won’t let others watch their favorites.</>
      ],
      answersIncorrect: [
        <>When Takashi wouldn’t want to turn TV off when others want to go to bed.</>,
        <>When Takashi watches TV sitting right in front, and interrupts others watching it.</>,
        <>When Takashi tried to change the TV channel while others are watching.</>
      ]
    }
  ]

  return <>
<h2>ビデオ{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}５: Authentic – short</h2>
Watch the video clip and choose the answer that matches the content
ofthe clip.
<br />
<small>*Click "SHOW" if you need to refer to the transcript.
</small><br />
<br />
Takashi was asked if his mother scolds him.<br />
<table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2"
 cellSpacing="2">
  <tbody>
    <tr>
      <td style={{verticalAlign: "top", textAlign: "left"}}>{addVideoPlayer("4.3.1.p.3.takashi.mp4")}</td>
      <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>Transcript
        <Hideable content={transcript}/><br />
        ＊はる(honorific - refer to Ch4 L4 Grammar1)<br />
      </td>
    </tr>
    <tr align="center">
      <td style={{verticalAlign: "top", textAlign: "left"}}>&nbsp;タカシ
(Shiga) </td>
    </tr>
  </tbody>
</table>
<QandAForm qaObjects={qaObjects}/>
  </>;
}

export default V5;
