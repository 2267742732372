import React from "react";
import { addRubyText, addImage, addAudio } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";

const L2 = () => {
  const transcript = <>
    <ol>
      <li><small>A: こんどの{addRubyText("英語学校","えいごがっこう")}どない？</small></li>
      <li><small>B: しんどいでぇ。{addRubyText("日本語","にほんご")}しゃべったらあかんねん。</small></li>
      <li><small>A: へえ、そらかなんなあ。</small></li>
      <li><small>B:うん。{addRubyText("授業中","じゅぎょうちゅう")}は、ノート
    とったらあかんから、せんせの{addRubyText("話","はなし")}、よう{addRubyText("聞","き")}かなあかんし、{addRubyText("家","うち")}でも{addRubyText("毎日","まいにち")}{addRubyText("英語","えいご")}のビデオ{addRubyText("見","み")}なあ
    かんし、、。</small></li>
      <li><small>A:へえ。せやけど、もう５{addRubyText("時","じ")}やで。そのがっこ{addRubyText("行","い")}かなあかんのちゃうん？</small></li>
      <li><small>B: ええねん。ちょっとぐらい{addRubyText("休","やす")}んでもええねん。</small></li>
      <li><small>A: そんなわけないやろ。</small></li>
      <li><small>B: あ、わかる？</small></li>
    </ol>
  </>;

  const qaObjects = [
    {
      question: <>Q: Which statements describe the rules of her English class correctly? Choose all applicable answers.</>,
      answersCorrect: [
        <>Speaking in Japanese is prohibited.</>,
        <>You must watch English videos at home.</>,
        <>You should not take notes in class.</>
      ],
      answersIncorrect: [
        <>Do not speak in class.</>,
        <>You must memorize what the teacher says in class.</>,
        <>You must not talk to the teacher in class.</>,
        <>You should not take notes in class.</>,
        <>You are allowed to miss some classes.</>
      ]
    }
  ]

  return <>
<h2>{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}2</h2>
<table style={{textAlign: "center", width: "30%"}} align="right" border="0"
cellPadding="2" cellSpacing="2">
  <tbody>
    <tr>
      <td style={{verticalAlign: "top"}}>{addImage("4.1.pl0.2.jpg")}</td>
    </tr>
  </tbody>
</table>
Ms. B is talking about her new English school. Listen to the andio and
answer the following question. <small>* Click "SHOW" if you need to
refer to the transcript.</small> <br />
<br />
{addAudio("4.1.pl0.2.mp3")}
<br />
<Hideable content={transcript} />
<br />
<QandAForm qaObjects={qaObjects} />
  </>;
}

export default L2;
