import React, { useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { ItemTypes } from "../../constants";
import { addImage, addAudio } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";

const getNumEntries = (prompts) => {
  return Math.max(...Object.values(prompts).map((array) => array.length));
}

const DroppablePrompt = (props) => {
  const image = props.image;
  const text = props.text;
  const answers = props.answers;
  const numAnswers = getNumEntries(answers);
  const index = props.index;
  const matchedSet = props.matchedSet;
  const addToMatchedSet = props.addToMatchedSet;
  const matchGraph = props.matchGraph;
  const [, drop] = useDrop({
    accept: ItemTypes.DRAGGABLE_ANSWER,
    drop: (item) => {
      addToMatchedSet(index, item.index);
    },
    canDrop: (item) => {
      console.log(matchGraph);
      console.log(matchGraph[index]);
      console.log(item.index);
      return matchGraph[index].has(item.index); // item.index === index;
    }
  })
  return <>
    <div
      ref={drop}
      style={{position: "relative"}}
      key={index}>
      
      {/* Prompt */}
      {image == null ? "" : addImage(image)}
      {text || ""}

      <br />
      {/* Matched answers */}
      {[...Array(numAnswers).keys()].map((i) => {
        if ( ! matchedSet[index].has(i)) {
          return <div key={i}></div>;
        }
        return <div key={i}>
          {'images' in answers ? addImage(answers.images[i]) : null}
          {'audioFiles' in answers ? addAudio(answers.audioFiles[i]) : null}
          {'texts' in answers ? answers.texts[i] : null}
        </div>
      })}
    </div>
  </>;
}

const DraggableAnswer = (props) => {
  const image = props.image;
  const audioFile = props.audioFile;
  const text = props.text;
  const label = props.label;
  const index = props.index;
  const [{isDragging}, drag] = useDrag({
    item: { 
      type: ItemTypes.DRAGGABLE_ANSWER,
      index: index
    }
  });

  return <div 
    ref={drag} 
    style={{
      opacity: isDragging ? 0.5 : 1,
      cursor: 'move',
    }}>
    {image == null ? "" : addImage(image)}
    {audioFile == null ? "" : addAudio(audioFile)} 
    {label == null ? "" : <>{label}</>}
    {text == null ? "" : <Hideable content={text} />}
  </div>
}

const emptySets = (n) => {
  return [...Array(n).keys()].map((i) => new Set());
}

export const DraggableForm = (props) => {
  const prompts = props.prompts;
  const numPrompts = getNumEntries(prompts);
  const answers = props.answers;
  const numAnswers = getNumEntries(answers);
  const matchGraph = props.matchGraph || [...Array(numPrompts).keys()].map((i) => new Set([i]));

  const [matchedSet, setMatchedSet] = useState(emptySets(numPrompts));
  const addToMatchedSet = (indexPrompt, indexAnswer) => {
    const newMatchedSet = matchedSet.map((obj) => new Set(obj));
    newMatchedSet[indexPrompt].add(indexAnswer);
    setMatchedSet(newMatchedSet);
  }
  
  /* Randomize order of unmatched prompts */
  // Note: these prompts may actually be matched
  const [promptOrder,] = useState(Array.from({length: numPrompts}, () => Math.random())); 
  let allPrompts = [...Array(numPrompts).keys()].map((i) => {
    return <div key={i} style={{
      background: "lightgrey",
      padding: "5px",
      margin: "5px",
      border: "thin inset black"
    }}>
      <DroppablePrompt
        image={'images' in prompts ? prompts.images[i] : null}
        text={'texts' in prompts ? prompts.texts[i] : null}
        answers={answers}
        index={i}
        matchedSet={matchedSet}
        addToMatchedSet={addToMatchedSet}
        matchGraph={matchGraph}
      />
    </div>
  });
  allPrompts.sort((o1,o2) => promptOrder[parseInt(o1.key)] - promptOrder[parseInt(o2.key)]); 

  /* Randomize order of unmatched answers */
  const [answerOrder,] = useState(Array.from({length: numAnswers}, () => Math.random())); 
  let unmatchedAnswers = [...Array(numAnswers).keys()].map((i) => {
    if (matchedSet.some((subset) => subset.has(i))) {
      return <div key={i}></div>;
    }
    return <div key={i} style={{
      background: "lightgrey",
      padding: "5px",
      margin: "5px",
      border: "thin inset black",
      float: "left"
    }}>
      <DraggableAnswer
        image={'images' in answers ? answers.images[i] : null}
        audioFile={'audioFiles' in answers ? answers.audioFiles[i] : null}
        text={'texts' in answers ? answers.texts[i] : null}
        label={'labels' in answers ? answers.labels[i] : null}
        index={i}
      />
    </div>;
  });
  unmatchedAnswers.sort((o1,o2) => answerOrder[parseInt(o1.key)] - answerOrder[parseInt(o2.key)]);

  return <>
    <table>
      <tbody>
        <tr>
          <th width="60%">
            <div className="promptgroup">
              {allPrompts}
            </div>
          </th>
          <th width="40%">
            <div className="answergroup">
              {unmatchedAnswers}
            </div>
          </th>
        </tr>
      </tbody>
    </table>
  </>
}
