import React from "react";
import { Link } from "react-router-dom";
import { Page } from "home/page";
import { addRubyText, addImage } from "common/util/generalUtil";

const Adjectives = () => {
  return <>
    <h2>Lesson 4: {addRubyText("形容詞","けいようし")}(Adjectives) <br/></h2>
    <h3>〜{addRubyText("長","なご")}うてややこしい{addRubyText("映画","えいが")}なんか{addRubyText("見","み")}とないでぇ。〜</h3>
    <table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td colSpan="1" rowSpan="1" style={{verticalAlign: "top"}}>
          <ul>
            <li>
              <Link to={Page.BasicGrammar.Adjectives.Conversations}>
                {addRubyText("基本会話","きほんかいわ")} (Core Conversations)
              </Link>
            </li>
            <li>
              <Link to={Page.BasicGrammar.Adjectives.Grammar.Grammar}>
                {addRubyText("文法","ぶんぽう")}ノート (Grammar Note) 
              </Link>
            </li>
            <li>
              <Link to={Page.BasicGrammar.Adjectives.Practice.Practice}>
                {addRubyText("練習","れんしゅう")} (Practice)
              </Link>
            </li>
            <li>
              <Link to={Page.BasicGrammar.Adjectives.Listening.Listening}>
                {addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")} (Listening Practice)
              </Link>
            </li>
            <li>
              <Link to={Page.BasicGrammar.Adjectives.Video.Video}>
                ビデオ{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}(Video Comprehension Practice)
              </Link>
            </li>
          </ul>
          </td>
          <td colSpan="1" rowSpan="1" style={{verticalAlign: "top", textAlign: "center"}}>
            {addImage("denkigai.jpg")}
            <br/>
            <small>
              でんでんタウン Denden Electronic Town (Nihonbashi, Osaka)
            </small>
            <br/>
          </td>
        </tr>
        <tr>
        </tr>
      </tbody>
    </table>
  </>;
}

export default Adjectives;