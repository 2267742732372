import React from "react";
import { addRubyText, addAudio } from "common/util/generalUtil";

const CasualRequest = () => {
  return <>
    <h2>4. Casual Request <br />
    </h2>
    Verb て form is used as a casual request in Kansai-ben just like in
    standard, but with Kansai-peculiar sentence particles and expression.
    Here is the list of commonly used casual request expressions in Kansai.
    <br />
    <small><br />*Mouse over or click on the speaker icon for sound</small>
    <br />
    <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Standard<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Kansai<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            KansaiExamples<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            Vて<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            Vて<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            そこに{addRubyText("置","お")}いといて。<small></small>{addAudio("3.2.4.1.mp3")}<br />
            <small>(ST そこに置いといて。）</small><small>{addAudio("3.2.4.1.s.mp3")}</small> 
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            Vてね<br />
            Vてよ<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            Vて<span style={{fontWeight: "bold"}}>な（あ）</span><br />
            Vて<span style={{fontWeight: "bold"}}>や（あ）</span><br />
          </td>
          <td style={{verticalAlign: "top"}}>
            ちょっと{addRubyText("待","ま")}っててなあ。<small></small>{addAudio("3.2.4.2.mp3")}<br />
            <small>(ST ちょっと待っててね。）</small><small>{addAudio("3.2.4.2.s.mp3")}</small><br />
            うちの{addRubyText("子","こ")}、ちょっと{addRubyText("見","み")}といてや。<small></small>{addAudio("3.2.4.3.mp3")}<br />
            <small>(ST うちの子、ちょっと見といてね。）</small><small>{addAudio("3.2.4.3.s.mp3")}</small>
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            Vてね(stronger)<br />
            Vてよ(stronger)<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            V<span style={{fontWeight: "bold"}}>て</span>え
            な<br />
            V<span style={{fontWeight: "bold"}}>て</span>えや<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            はよこっち{addRubyText("来","き")}てえな。<small></small>{addAudio("3.2.4.4.mp3")}<br />
            <small>(ST 早くこっちに来てね！）</small><small>{addAudio("3.2.4.4.s.mp3")}</small><br />
            お{addRubyText("願","ねが")}いやし、{addRubyText("駅","えき")}まで{addRubyText("送","おく")}ってえや。<small></small>{addAudio("3.2.4.5.mp3")}<br />
            <small>(ST お願いだから、駅まで送ってよ！）</small><small>{addAudio("3.2.4.5.s.mp3")}</small>
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            Vてあげて<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            Vたげて／たって<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            お{addRubyText("父","とう")}さん、{addRubyText("手伝","てつだ")}ったげて。<small></small>{addAudio("3.2.4.6.mp3")}<br />
            <small>(ST お父さんを手伝ったげて。）</small><small>{addAudio("3.2.4.6.s.mp3")}</small> 
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            Vてやって<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            Vたって<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            たかしにおやつ、{addRubyText("買","こ")}うたって。<small></small>{addAudio("3.2.4.7.mp3")}<br />
            <small>(ST たかしにおやつを買ってやって。）</small><small>{addAudio("3.2.4.7.s.mp3")}</small> 
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            Vてくれない？<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            Vてくれへん（か）？<br />
            Vてんか（あ）<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            {addRubyText("手紙","てがみ")}、{addRubyText("出","だ")}してきてくれへん？<small></small>{addAudio("3.2.4.8.mp3")}<br />
            <small>(ST この手紙を出してきてくれない？）</small><small>{addAudio("3.2.4.8.s.mp3")}</small><br />
            この{addRubyText("荷物","にもつ")}、{addRubyText("持","も")}ってんかあ。<small></small>{addAudio("3.2.4.9.mp3")}<br />
            <small>(ST この荷物を持ってくれない？）</small><small>{addAudio("3.2.4.9.s.mp3")}</small>
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    Sentence particle や , an equivalent of standard よ, is often added on a
    request, but this particle is not used for a statement (で functions as
    よ in a statement).
    <br />
    <br />
    &nbsp;&nbsp;&nbsp; {addRubyText("今日","きょう")}お{addRubyText("造","つく")}り{addRubyText("安","やす")}いし<span
    style={{textDecoration: "underline"}}>{addRubyText("買","こ")}うてきてや</span>。{addAudio("3.2.4.10.mp3")}
    <br />
    <small>(ST 今日はお{addRubyText("刺身","さしみ")}が安いから、買ってきてよ。)</small>
    <small>{addAudio("3.2.4.10.s.mp3")}</small>
    <br />
    <br />
    &nbsp;&nbsp;&nbsp; 今日お造り安かったし<span style={{textDecoration: "underline"}}>買うてきたで</span>。<small> </small>{addAudio("3.2.4.11.mp3")}
    <br />
    <small>(ST 今日はお刺身が安かったから、買ってきたよ。)</small> <small>{addAudio("3.2.4.11.s.mp3")}</small>
    <br />
    &nbsp; <small></small>
    <br />
    As in the list above, Kansai people express the eagerness of the
    request by changing accentual pattern, that is, by putting an accent on
    てand lengthening the sound.
    <br />
    <br />
    &nbsp;&nbsp;&nbsp; こっち<span
    style={{fontWeight: "bold", textDecoration: "underline"}}>{addRubyText("来","き")}てな</span>。
    ＜こっち<span style={{textDecoration: "underline"}}><span
    style={{fontWeight: "bold"}}>来て</span>えな</span>。(stronger)
    {addAudio("3.2.4.12.mp3")}<br />
    <small>(ST
    こっちに来てよ。</small><small>＜こっちに来てよ！</small><small>）</small><small>{addAudio("3.2.4.12.s.mp3")}</small>
    <br />
    <br />
    &nbsp;&nbsp;&nbsp; おかし、<span
    style={{textDecoration: "underline", fontWeight: "bold"}}>{addRubyText("買","こ")}うたってや</span>。<small></small>
    ＜おかし、<span style={{textDecoration: "underline"}}><span
    style={{fontWeight: "bold"}}>買うたって</span>えや</span>。(stronger) {addAudio("3.2.4.13.mp3")}
    <br />
    <small>(ST おかしを買ってやってよ。＜</small><small>
    おかしを買ってやってよ！！）</small><small>{addAudio("3.2.4.13.s.mp3")}</small>
    <br />
    <br />
    <hr />
    <h3>Extra 1: {addRubyText("私","わたし")}のかばん、{addRubyText("持","も")}ったって <br /></h3>
    Vたって originally comes from Vてあげる／Vてやる, hence this is supposed to be a
    request for the benefit of somebody else.<br />
    <br />
    たかしにおかし、買うたって。(for the benefit of Takashi) {addAudio("3.2.4.14.mp3")}
    <br />
    <small>(ST たかしにおかしを買ってあげて／買ってやって。）</small><small>{addAudio("3.2.4.14.s.mp3")}</small>
    <br />
    <br />
    However, you can express a request for the benefit of yourself using this form in Kansai.
    <br />
    <br />
    {addRubyText("私","わたし")}のかばん、{addRubyText("重","おも")}いし、{addRubyText("持","も")}ってえや。<small></small>{addAudio("3.2.4.15.mp3")}
    <br />
    ＝私のかばん、重いし、持ったってえや。{addAudio("3.2.4.16.mp3")}
    <br />
    <small>(ST 私のかばん、重いから持ってよ！）</small><small>{addAudio("3.2.4.15.s.mp3")}</small>
    <br />
    &nbsp;&nbsp;&nbsp; <br />
    Both of the examples above mean "Please carry my bag," and the
    difference between them is very small. However, the second sentence
    sounds as if she projects herself as a third person to soften the
    request, to avoid directly making a rude request for herself. This type
    of communication strategy to avoid direct confrontation is widely used
    in Kansai-ben by Kansai people, and you will find more examples in the
    real Kansai world!
  </>;
}

export default CasualRequest;
