import React from "react";
import { Link } from "react-router-dom";
import { addRubyText, addAudio } from "common/util/generalUtil";
import { Page } from "home/page";

const TeFunctions = () => {
  return <>
    <h2>3. Functions of Verb て form</h2>
    As you know, the basic function of て form is to connect the verb to the following element. 
    <br />
    <small>*Move the mouse ove rthe speaker icon for sound</small>
    <br />
    <br />
    {addRubyText("今日","きょう")}は{addRubyText("本屋","ほんや")}<span style={{textDecoration: "underline"}}>{addRubyText("行","い")}って</span>、
    {addRubyText("雑誌","ざっし")}<span style={{textDecoration: "underline"}}>{addRubyText("買","こ")}うて</span>から、うち{addRubyText("帰","かえ")}んねん。{addAudio("3.2.3.1.mp3")}
    <br />
    <small>(ST　今日は本屋に行って雑誌を買ってから、うちに帰るんだよ。）</small><small>{addAudio("3.2.3.1.s.mp3")}</small>
    <br />
    <br />
    You also know that て form can make compounds by linking other verbs as
    seen in <Link to={Page.BasicGrammar.VerbTeForm.Grammar.TeContraction}>Grammar 2. Verb て form contraction.</Link>
    <br />
    <br />
    ドア<span style={{textDecoration: "underline"}}>{addRubyText("開","あ")}いてる</span>思たけど、<span
    style={{textDecoration: "underline"}}>開いてへんかった</span>。<small> </small>{addAudio("3.2.3.2.mp3")}
    <br />
    <small>(ST&nbsp; ドアは開いていると思ったけど、開いていなかった。) </small><small>{addAudio("3.2.3.2.s.mp3")}</small>
    <br />
    <br />
    はよ、{addRubyText("宿題","しゅくだい")}<span style={{textDecoration: "underline"}}>やってしもて</span>、{addRubyText("寝","ね")}なさい！{addAudio("3.2.3.3.mp3")} 
    <br />
    <small>(ST 早く宿題をやってしまって、寝なさい！)&nbsp; </small><small>{addAudio("3.2.3.3.s.mp3")}</small><br />
    <br />
    なんか{addRubyText("庭","にわ")}で{addRubyText("音","おと")}したけど、<span style={{textDecoration: "underline"}}>{addRubyText("見","み")}てきてく
    れへん</span>？<small>&nbsp; </small>{addAudio("3.2.3.4.mp3")}
    <br />
    <small>(ST&nbsp; 何か庭で音がしたけど、見てきてくれない？) </small><small>{addAudio("3.2.3.4.s.mp3")}</small>
    <br />
    <br />
    <br />
    We also introduced a Kansai specific use of て form in Extra 1 at <Link to={Page.BasicGrammar.SentenceEndings.Grammar.ExtendedPredicate}>
      Lesson1b.Sentence Endings: Grammar 2. Extended Predicates. 
    </Link>
    <br />
    <ul style={{fontWeight: "bold"}}>
      <li>
        Ta-form + extended predicate (statement) →（〜たねん）→〜てん
      </li>
    </ul>
    {addRubyText("結婚","けっこん")}したん ?→うん、<span style={{textDecoration: "underline"}}>してん</span>。{addAudio("3.2.3.5.mp3")}<br />
    <small>&nbsp;
    (ST 結婚したの？ー＞うん、したんだ／したの。) </small><small>{addAudio("3.2.3.5.s.mp3")}</small>
    <ul style={{fontWeight: "bold"}}>
      <li>In interrogative questions about the past event with extended
    predicate</li>
    </ul>
    A: <span style={{textDecoration: "underline"}}>なんで</span>{addRubyText("単語","たんご")}{addRubyText("覚","おぼ")}えて<span
    style={{textDecoration: "underline"}}>{addRubyText("来","こ")}うへんかってん</span>？
    <br />
    B: あの、{addRubyText("忘","わす")}れててん。 {addAudio("3.2.3.6.mp3")}
    <br />
    <small>（ST
    どうして単語を覚えて来なかったの？</small><small>ー＞ あの、忘れてたんだ／の。</small><small>）</small><small>{addAudio("3.2.3.6.s.mp3")}</small>
    <br />
    <br />
    This 〜てん expression is a contraction from 〜たねん , hence the meaning is
    different from regular て form,&nbsp; although the form is exactly the
    same. Therefore, don't get confused when someone uses て form this way.
    <br />
    <br />
    <hr />
    <h3>Extra 1: ここにいてる<br /></h3>
    いる indicates current or future existence of animate object.&nbsp; In
    Kansai-ben, there are some other ways to describe existence.<br />
    <br />
    &nbsp;&nbsp;&nbsp; (ST){addRubyText("私","わたし")}、{addRubyText("今日","きょう")}は{addRubyText("家","うち")}にいるよ。<small>{addAudio("3.2.3.7.s.mp3")}</small>
    <br />
    =今日は家に<span style={{textDecoration: "underline"}}>いる</span>わ。{addAudio("3.2.3.7.mp3")}
    <br />
    =今日は家に<span style={{textDecoration: "underline"}}>いてる</span>わ。{addAudio("3.2.3.8.mp3")}
    <br />
    =今日は家に<span style={{textDecoration: "underline"}}>おる</span>わ。{addAudio("3.2.3.9.mp3")}<br />
    <br />
    (ST) みかちゃん？ここにはいないよ？<small>{addAudio("3.2.3.10.s.mp3")}</small>
    <br />
    =ここには、<span style={{textDecoration: "underline"}}>い〜ひん／いん／いやへん</span>で。{addAudio("3.2.3.10.mp3")}
    <br />
    =ここには、<span style={{textDecoration: "underline"}}>いてへん</span>で。{addAudio("3.2.3.11.mp3")}
    <br />
    =ここには、<span style={{textDecoration: "underline"}}>おらん</span>で。{addAudio("3.2.3.12.mp3")}
    <br />
    <br />
    As indicated in the examples, いて+いる→いてる can be used the same way as いる
    (Please remember that いている is ungrammatical in standard!)
    <br />
    <br />
    おる is a humble polite form of いる. In standard おるusually occurs as a
    ますform to describe in-group person's existence to exalt out-group,
    therefore, it rarely occurs in casual speech as a plain/direct form.
    However, Kansai people often use おる just as a blunt expression of いる.
    おる is used as well as a humble/pejorative expression (we will deal with
    this function of おる later in <Link to={Page.FunctionalGrammar.Honorifics.Grammar.Pejoratives}>Ch 4. Lesson 4. G2</Link>.
    <br />
    <br />
    <hr />
    <h3>Extra 2: おる in Kobe<br /></h3>
    おる／〜とう（とる） is an equivalent of いる／〜てる in Kobe, so don't be surprised
    when someone uses おる to describe your action, or even a teacher's
    action.
    <br />
    <br />
    {addRubyText("今晩","こんばん")}、{addRubyText("何","なに")}しとん？<small> </small>{addAudio("3.2.3.13.mp3")}
    <br />
    <small>（ST 今晩、何してるの？）</small><small>{addAudio("3.2.3.13.s.mp3")}</small>
    <br />
    <br />
    テレビ？うん、{addRubyText("見","み")}とう{addRubyText("見","み")}とう！<small>&nbsp;&nbsp; </small>{addAudio("3.2.3.14.mp3")}
    <br />
    <small>（ST テレビ？うん、もちろん見てるよ！）</small><small>{addAudio("3.2.3.14.s.mp3")}</small>
    <br />
    <br />
    {addRubyText("先生","せんせい")}、おってですか？<small>&nbsp; </small>{addAudio("3.2.3.15.mp3")}
    <br />
    <small>(ST 先生、いらっしゃいますか？)&nbsp; </small><small>{addAudio("3.2.3.15.s.mp3")}</small>
    <br />
    &nbsp;
  </>;
}

export default TeFunctions;
