export const Constants = {
  FETCH_INTERVIEW_QUESTION_COUNT: 'FETCH_INTERVIEW_QUESTION_COUNT',
  FETCH_INTERVIEW_QUESTION_LIST: 'FETCH_INTERVIEW_QUESTION_LIST',
  CHANGE_INTERVIEW_QUESTION_COUNT: 'CHANGE_INTERVIEW_QUESTION_COUNT',
  CHANGE_INTERVIEW_QUESTION_LIST: 'CHANGE_INTERVIEW_QUESTION_LIST',

  FETCH_INTERVIEWEES: 'FETCH_INTERVIEWEES',
  CHANGE_INTERVIEWEES: 'CHANGE_INTERVIEWEES',
  
  FETCH_INTERVIEW_CLIP_COUNT: 'FETCH_INTERVIEW_CLIP_COUNT',
  FETCH_INTERVIEW_CLIP_LIST: 'FETCH_INTERVIEW_CLIP_LIST',
  FETCH_MORE_INTERVIEW_CLIP_LIST: 'FETCH_MORE_INTERVIEW_CLIP_LIST',
  FETCH_INTERVIEW_CLIP_Q_AND_A: 'FETCH_INTERVIEW_CLIP_Q_AND_A',
  CHANGE_INTERVIEW_CLIP_COUNT: 'CHANGE_INTERVIEW_CLIP_COUNT',
  CHANGE_INTERVIEW_CLIP_LIST: 'CHANGE_INTERVIEW_CLIP_LIST',
  CHANGE_INTERVIEW_CLIP_Q_AND_A: 'CHANGE_INTERVIEW_CLIP_Q_AND_A',
  INTERVIEW_CLIP_PAGE_SIZE : 20
}

export const ItemTypes = {
    DRAGGABLE_ANSWER: 'draggable_answer',
    DRAGGABLE_PROMPT: 'draggable_prompt'
}
