import React from "react";
import { Link } from "react-router-dom";
import { Page } from "home/page";
import { addRubyText, addAudio } from "common/util/generalUtil";

const EvenIf = () => {
  return <>
<h2>2.&nbsp; ~かて&nbsp; “Even if/though…”</h2>
We talked about Noun+かて combination in former section. You can apply a
similar pattern to alternate 〜ても／〜たって form to say “even…/even if….”.<br />
<br />
<table style={{textAlign: "left", width: "100%"}} border="1"cellPadding="2"
 cellSpacing="2">
  <tbody>
    <tr>
      <td style={{verticalAlign: "top"}}>Standard<br />
      </td>
      <td style={{verticalAlign: "top"}}>Kansai<br />
      </td>
      <td style={{verticalAlign: "top"}}>Kansai example<br />
      </td>
      <td style={{verticalAlign: "top"}}>Kansai example (sentence)<br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>V(てform)+も<br />
V(たform)+って<br />
      </td>
      <td style={{verticalAlign: "top"}}>V（たform)+かて<br />
      </td>
      <td style={{verticalAlign: "top"}}>{addRubyText("食","た")}<span
 style={{fontWeight: "bold"}}>べ</span>たかて<br />
      <span style={{fontWeight: "bold"}}>{addRubyText("言","ゆ")}</span>うたかて<br />
{addRubyText("読","よ")}んだ<span style={{fontWeight: "bold"}}>か</span>て<br />
      <span style={{fontWeight: "bold"}}>し</span>たかて<br />
      <span style={{fontWeight: "bold"}}>き</span>たかて{addAudio("4.5.2.1.mp3")}<br />
      <br />
      </td>
      <td style={{verticalAlign: "top"}}>そんなこと<span
 style={{textDecoration: "underline"}}>言うたかて</span>あかんわ。{addAudio("4.5.2.7.mp3")}<br />
      <small>(ST そんなこと言っても/言ったってだめだよ。) {addAudio("4.5.2.7.s.mp3")}</small><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>V(neg. stem)+なくても／なくたって<br />
      </td>
      <td style={{verticalAlign: "top"}}>V(neg. stem)んかて*<br />
      </td>
      <td style={{verticalAlign: "top"}}>食べん<span
 style={{fontWeight: "bold"}}>か</span>て<br />
      <span style={{fontWeight: "bold"}}>言わんか</span>て<br />
読まん<span style={{fontWeight: "bold"}}>か</span>て<br />
      <span style={{fontWeight: "bold"}}>せんか</span>て*(&lt;-する)<br />
      <span style={{fontWeight: "bold"}}>こんか</span>て {addAudio("4.5.2.2.mp3")}<br />
      <br />
      </td>
      <td style={{verticalAlign: "top"}}>{addRubyText("教科書","きょうかしょ")}なんか読んだかて<span
 style={{textDecoration: "underline"}}>読まんかて</span>おんなじやん。{addAudio("4.5.2.8.mp3")}<br />
      <small>(ST 教科書なんか読んでも読まなくても/ 読んだって読まなくたって{addRubyText("同","おな")}じじゃん。) {addAudio("4.5.2.8.s.mp3")}</small><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>Adj(root)**+くても／たって<br />
      </td>
      <td style={{verticalAlign: "top"}}>Adj(root)+かったかて<br />
      </td>
      <td style={{verticalAlign: "top"}}><span style={{fontWeight: "bold"}}>{addRubyText("寒","さむ")}</span>かった<span style={{fontWeight: "bold"}}>か</span>て<br />
      <span style={{fontWeight: "bold"}}>かわい</span>かった<span
 style={{fontWeight: "bold"}}>か</span>て {addAudio("4.5.2.3.mp3")}<br />
      <br />
      </td>
      <td style={{verticalAlign: "top"}}>{addRubyText("欲","ほ")}しいもんは、<span
 style={{textDecoration: "underline"}}>{addRubyText("高","たか")}かったかて</span>{addRubyText("買","か")}う。{addAudio("4.5.2.9.mp3")}<br />
      <small>(ST 欲しい物は高くても / 高くたって買う。) {addAudio("4.5.2.9.s.mp3")}</small><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>Adj(root)+くなくても／くなくたって<br />
      </td>
      <td style={{verticalAlign: "top"}}>Adj (root)+なかったかて<br />
      </td>
      <td style={{verticalAlign: "top"}}>{addRubyText("安",<>や<span style={{fontWeight: "bold"}}>す</span></>)}<span style={{fontWeight: "bold"}}>な</span>かっ
た<span style={{fontWeight: "bold"}}>か</span>て {addAudio("4.5.2.4.mp3")}<br />
      </td>
      <td style={{verticalAlign: "top"}}>{addRubyText("出","だ")}されたもんは<span
 style={{textDecoration: "underline"}}>おいしなかったかて</span>{addRubyText("全部","ぜんぶ")}食べなあかん。
{addAudio("4.5.2.10.mp3")}<br />
      <small>(ST 出されたものはおいしくなくても / おいしくなくたって全部食べなきゃだめ。) {addAudio("4.5.2.11.s.mp3")}</small><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>N***で（あって）も<br />
Nだって<br />
      </td>
      <td style={{verticalAlign: "top"}}>Nやったかて<br />
      </td>
      <td style={{verticalAlign: "top"}}>{addRubyText("雪",<><span style={{fontWeight: "bold"}}>ゆ</span>き</>)}やった<span
 style={{fontWeight: "bold"}}>か</span>て<br />
{addRubyText("静",<>し<span style={{fontWeight: "bold"}}>ず</span></>)}かやった<span
 style={{fontWeight: "bold"}}>か</span>て {addAudio("4.5.2.5.mp3")}<br />
      </td>
      <td style={{verticalAlign: "top"}}><span
 style={{textDecoration: "underline"}}>{addRubyText("大雨","おおあめ")}やったかて</span>{addRubyText("仕事","しごと")}は{addRubyText("行","い")}
かな。 {addAudio("4.5.2.11.mp3")}<br />
      <small>(ST 大雨でも / だって仕事は行かなきゃ。) {addAudio("4.5.2.11.s.mp3")}</small><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>Nでなくても／なくたって<br />
      </td>
      <td style={{verticalAlign: "top"}}>Nやなかったかて<br />
Nちごたかて</td>
      <td style={{verticalAlign: "top"}}>{addRubyText("雪",<><span style={{fontWeight: "bold"}}>ゆ</span>き</>)}や<span style={{fontWeight: "bold"}}>な</span>かっ
た<span style={{fontWeight: "bold"}}>か</span>て<br />
{addRubyText("静",<>し<span style={{fontWeight: "bold"}}>ず</span></>)}か<span
 style={{fontWeight: "bold"}}>ち</span>ごた<span style={{fontWeight: "bold"}}>か</span>て
{addAudio("4.5.2.6.mp3")}<br />
      </td>
      <td style={{verticalAlign: "top"}}>{addRubyText("明日","あした")}<span
 style={{textDecoration: "underline"}}>雪ちごたかて</span>、スキーはできるて。 {addAudio("4.5.2.12.mp3")}<br />
      <small>(ST 明日雪じゃなくても / 雪じゃなくたってスキーはできるってば。){addAudio("4.5.2.12.s.mp3")}</small><br />
      </td>
    </tr>
  </tbody>
</table>
<small><br />
* Kansai short negative forms of verbs V(neg. stem) +ん ( refer to
<Link to={Page.BasicGrammar.Verbs.Grammar.NonPastNegative}>
Grammar 1 in Ch3 Lesson 1a
</Link>) originated in classical negative form
V(neg.stem)+ぬ.
Therefore, traditional negative stem せ is used for irregular verbする. <br />
** Adj. stands for い Adjectives / Adjectivals.<br />
*** N stands for Nouns and な Adjectives / Nominals and な Nominals.<br />
</small><br />
As you know, 〜てもいい（ええ）／かまへん（かめへん in Osaka) pattern is used to express
permission in Kansai-ben (refer to 
<Link to={Page.FunctionalGrammar.PermissionProhibition.Grammar.Permission}>
Grammar
2 in Ch4 Lesson 2
</Link>). Actually ,
〜かてええ／かまへん（かめへん） is the traditional expression of permission in Kansai
such as in;<br />
<br />
その{addRubyText("映画","えいが")}、{addRubyText("小学生","しょうがくせい")}が<span style={{textDecoration: "underline"}}>{addRubyText("見","み")}
たかて</span>かまへんやつなんちゃうん？ {addAudio("4.5.2.13.mp3")}<br />
<small>(ST その映画，小学生が見てもかまわないやつなんじゃないの？) {addAudio("4.5.2.13.s.mp3")}</small><br />
<br />
<small>* やつ (colloquial, blunt) = こと、もの、人</small><br />
&nbsp;<br />
{addRubyText("私","わたし")}の{addRubyText("作","つく")}ったご{addRubyText("飯","はん")}がそんな{addRubyText("嫌","いや")}やったら、もう<span
 style={{textDecoration: "underline"}}>食べんかて</span>ええ！{addAudio("4.5.2.14.mp3")}<br />
<small>（ST 私の作ったご飯がそんなに嫌なら、食べなくていい！）{addAudio("4.5.2.14.s.mp3")}</small><br />
<br />
Nowadays かて expressions seem to be used mainly among middle to older
generation, since neo-dialect (standard-like Kansai-ben) expands its
power in younger generation. Therefore, it would be safer for
non-natives to keep the information on かて as passive/comprehensive
knowledge rather than trying to use it verbally.<br />
<br />
<hr />
<h3>Extra 1: きれいやのうたかて、かまへん。</h3>
なく, Adverbial form of ない, traditionally has an euphonic change to のう
(refer to 
<Link to={Page.BasicGrammar.Adjectives.Grammar.Adverbs}>
Grammar 3. in Ch3 Lesson 4
</Link>). This change also appears in the
negative form of Adjectives and Copula, therefore, Adj (root)
のうたかて/Nやのうたかて are traditional expressions equivalent to Adj
なかったかて／Nやなかったかて.<br />
<br />
{addRubyText("字","じ")}は、<span style={{textDecoration: "underline"}}>きれいやのうたかて</span>，読めたらええ
んや。（＝きれいやなかったかて）{addAudio("4.5.2.15.mp3")}<br />
<small>(ST 字は，きれいじゃなくたって，読めればいいんだ。) {addAudio("4.5.2.15.s.mp3")}</small><br />
<br />
This expression remains among older generation only, but it is worth
keeping it in mind for comprehensive purposes.<br />
<br />
<hr />
<h3>Extra 2: そやかて</h3>
でも／それでも／だって in Standard are sometimes used to indicate “but; and yet;
still”, to express disagreement/ opposite stance against the addressee.
The equivalent expressions in Kansai ben are そやかて／せやかて／ほやかて／そうかて. The
choice depends on regions and individuals.<br />
<br />
A: {addRubyText("何","なに")}ゆっくりしてんの！はよ{addRubyText("着替","きが")}え！{addAudio("4.5.2.16.mp3")}<br />
B: そ<span style={{textDecoration: "underline"}}>やかて</span>、この{addRubyText("服","ふく")}きつうて入らへ
んねん。{addAudio("4.5.2.17.mp3")}<br />
<small>(ST 何ゆっくりしてるの？{addRubyText("早","はや")}く着替えなさい！ー＞</small><small>だって、この服きつくて入らないの。）
{addAudio("4.5.2.16.s.mp3")}</small><br />
<small><br />
* きつい tight</small>
  </>;
}

export default EvenIf;
