import React from 'react';
import { Link } from "react-router-dom";
import { Popup } from 'semantic-ui-react'
import { messages } from "i18n/lang/en";
import { addImage } from 'common/util/generalUtil';
import { Page } from 'home/page';

const cm = messages.common;
const fm = messages.firstRun;

const FirstRun = () => {
  return (
    <div>
      <h1>{cm.kansaiben}</h1>
      {addImage("testImageMain.jpg")}
      <table style={{border: "medium solid black", margin: "10px auto", borderSpacing: "1px"}} border="0" cellPadding="10" width="690">
        <tbody>
          <tr>
            <td colSpan="2" bgcolor="#ff9933">
              <div align="center">
                <Link to={Page.ReadFirst} target="_blank" style={{ color: "white" }}>
                  Read First
                </Link>
              </div>
            </td>
          </tr>
          <tr>
            <td bgcolor="#ff9933">
              <div align="center">
                <Popup 
                  trigger={
                    <Link to={Page.Characteristics.Characteristics} target="_blank" style={{ color: "white" }}>
                      Ch1. Characteristics
                    </Link>
                  }
                  content="Ch1 briefly explains the overall characteristics of the Kansai dialect in terms of sound, grammar and history."
                  basic></Popup>
              </div>
            </td>
            <td bgcolor="#ff9933">
              <div align="center">
                <Popup
                  trigger={
                    <Link to={Page.Expressions.Expressions} target="_blank" style={{ color: "white" }}>
                      Ch 2. Expressions
                    </Link>
                  }
                  content="Ch2 provides a list of common greetings, leave-takings and useful expressions in Kansai-ben. Audio and video greetings are included."
                  basic></Popup>
              </div>
            </td>
          </tr>
          <tr>
            <td bgcolor="#ff9933">
              <div align="center">
                <Popup
                  trigger={
                    <Link to={Page.BasicGrammar.BasicGrammar} target="_blank" style={{ color: "white" }}>
                      Ch 3. Basic Grammar
                    </Link>
                  }
                  content="Ch3 provides the basics of Kansai grammar. Short video clips, grammar notes, aural/oral exercises are provided."
                  basic></Popup>
              </div>
            </td>
            <td bgcolor="#ff9933">
              <div align="center">
                <Popup
                  trigger={
                    <Link to={Page.FunctionalGrammar.FunctionalGrammar} target="_blank" style={{ color: "white" }}>
                      Ch 4. Functional Grammar
                    </Link>
                  }
                  content="Ch4 introduces the important functional structure patterns that you will encounter in daily life, and are distinct and different from the standard."
                  basic></Popup>
              </div>
            </td>
          </tr>
          <tr>
            <td bgcolor="#ff9933">
              <div align="center">
                <Popup
                  trigger={
                    <Link to={Page.TalkInTown.TalkInTown} target="_blank" style={{ color: "white" }}>
                      Ch 5. Talk in Town
                    </Link>
                  }
                  content="Ch5 contains a collection of video clips of interactions between local people in town, in locations such as local retail stores."
                  basic></Popup>
              </div>
            </td>
            <td bgcolor="#ff9933">
              <div align="center">
                <Popup
                  trigger={
                    <Link to={Page.Interviews} target="_blank" style={{ color: "white" }}>
                      Ch 6. Interviews to Locals
                    </Link>
                  }
                  content="Ch6 consists of a collection of video clips of interviews with a variety of local Kansai people. Clips can be sorted as you like!"
                  basic></Popup>
              </div>
            </td>
          </tr>
          <tr>
            <td bgcolor="#ff9933">
              <div align="center">
                <Popup
                  trigger={
                    <Link to={Page.OtherResources.OtherResources} target="_blank" style={{ color: "white" }}>
                      Ch 7. Other Resources
                    </Link>
                  }
                  content="Ch7 introduces kansai-related information such as Kansai-ben anime, movies/films and songs as well as useful links."
                  basic></Popup>
              </div>
            </td>
            <td bgcolor="#ff9933">
            </td>
          </tr>
        </tbody>
      </table>
      <h3>{fm.announceHeader}</h3>
      <p>
        Mar. 2020 » Ch7 will be soon updated. Your input on Kansai-related media resources are welcome! <br/>
        Oct. 2016 » Ch6 Interviews, and Ch3 & 4 Listening Practices are being fixed. We appreciate your patience! <br/>
        Oct. 2016 » Ch7 (Kansai films & JPOP) updated <br/>
        Oct. 2016 » Site mostly fixed and back online again! <br/>
        Mar. 2011 » Ch7 - Kansai song list updated <br/>
        June 2010 » Ch7 - "How to use this site in class (for teachers)" added <br/>
        Jan. 2010 » Ch7 -  More "Letters from Users" added <br/>
        Nov. 2009 » Ch7 - "Letters from Users" section added <br/>
        July 2009 » Ch5 (Talk in town) - 11 new video clips added! <br/>
        July 2009 » Ch6 (Interview) - more than 200 video clips added! <br/>
        Apr. 2009 » Ch7 "Interviews to Kansai Foreigners" uploaded <br/>
        Feb. 2009 » INDEX uploaded (finally!) <br/>
        Dec. 2008 » Ch4 L4 Video comprehension exercises uploaded <br/>
        Sept 2008 » Ch7 "Kansai-ben films" revised <br/>
        Aug. 2008 » Ch4 L4 & L5 (Core Conversations and Grammar explanations) uploaded <br/>
        Aug. 2008 » Sound files added for the standard Japanese sentences in Ch3 and Ch4 
      </p>
    </div>
  )
};

export default FirstRun;
