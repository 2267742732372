import React from "react";
import { addRubyText, addImage } from "common/util/generalUtil";
import { Link } from "react-router-dom";
import { Page } from "home/page";

const Grammar = () => {
  return <>
    <h2>{addRubyText("文法","ぶんぽう")}ノート (Grammar Note)</h2>
    <table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td colSpan="1" rowSpan="1" style={{verticalAlign: "top"}}>
            <ol>
              <li>
                <Link to={Page.BasicGrammar.Copula.Grammar.Conjugation}>
                  Copula Conjugation
                </Link>
              </li>
              <li>
                <Link to={Page.BasicGrammar.Copula.Grammar.Houkaa}>
                  ほうかあ
                </Link>
              </li>
            </ol>
          </td>
          <td colSpan="1" rowSpan="1" style={{verticalAlign: "top", textAlign: "center"}}>
            {addImage("kiyomizuniomon.jpg")}<br/>
            <small>
              清水寺仁王門　Gate to Kiyomizu Temple (Kyoto)
            </small>
            <br/>
          </td>
        </tr>
        <tr align="center">
        </tr>
      </tbody>
    </table>
  </>;
}

export default Grammar;