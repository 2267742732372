import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";

const ConfectionStore = () => {
  const vocabList = <ul style={{display: "block", overflow: "visible"}}>
    <li>
      かりんとう fried dough cake (Japanese traditional sweet snack)
    </li>
    <li>
      {addRubyText("売","う")}り{addRubyText("切","き")}れ sold out
    </li>
    <li>
      でっしゃろ (Kansai) =でしょう
    </li>
  </ul>;

  const qaObjects = [
    {
      question: <span>Q1: When should トモちゃん come to the store again to buy やわらかかりんとう?</span>,
      answersIncorrect: [
        <span>This evening or so.</span>,
        <span>Next Friday or so.</span>,
        <span>Should have come this morning.</span>
      ],
      answersCorrect: [
        <span>Tomorrow evening or so.</span>
      ]
    },
    {
      question: <span>Q2: How often are やわらかかりんとう delivered to the store?</span>,
      answersIncorrect: [
        <span>Once a week.</span>,
        <span>Once a month.</span>,
        <span>Once every two months.</span>
      ],
      answersCorrect: [
        <span>Once every two weeks.</span>
      ]
    }
  ];

  return (
    <>
      <h2>{addRubyText("和菓子屋","わがしや")}で (At a Japanese-style confection store in Kyoto)</h2>
      <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>
            [Context] トモちゃん is at a Japanese-style confection store in Kyoto.
          </td>
        </tr>
        </tbody>
      </table>
      <br />
      <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addVideoPlayer("5.9.wagashi.mp4")}<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            Vocabulary List <br />
            <Hideable content={vocabList} />
          </td>
        </tr>
        </tbody>
      </table>
      <h3>{addRubyText("内容質問","ないようしつもん")} (Content Questions)</h3>
      <QandAForm qaObjects={qaObjects} />
    </>
  );
};

export default ConfectionStore;
