import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";

const V8 = () => {
  const transcript = <>
  {addRubyText("東北","とうほく")}{addRubyText("行","い")}ったら、さっぱり{addRubyText("分","わ")}からん。あの、{addRubyText("弟","おとうと")}の{addRubyText("嫁","よめ")}さんが{addRubyText("東京","とうきょう")}、東京にいるけども、
  {addRubyText("秋田","あきた")}やんか。ほた、秋田へ行ったら、まあ、このごろはもう、おじちゃんも{addRubyText("慣","な")}れたやろさけ{addRubyText("何","なん")}やけど、嫁さんもろて{addRubyText("間","ま")}
  なしにね、{addRubyText("孫","まご")}(=&gt;{addRubyText("子供","こども")})を秋田で<span style={{textDecoration: "underline"}}>{addRubyText("産","う")}まはって</span>(Grammar
  1)ん。ほんでちゅうて、うちのおじいさんが、あの、{addRubyText("迎","むか")}いがてら嫁さんの{addRubyText("里","さと")}へ一ぺん行ってんや。、、{addRubyText("何","なに")}<span
  style={{textDecoration: "underline"}}>しゃべったはる</span>
  (Grammar 1)、さっぱり分からへんちゅうて、さっぱり分からへんゆうて、<span
  style={{textDecoration: "underline"}}>{addRubyText("帰","かえ")}ってきやはった</span>(Grammar
  1)けどな。
  </>;

  const qaObjects = [
    {
      question: <span>Q1:Which of her relatives is from Akita Prefecture?</span>,
      answersCorrect: [
        <>Her younger brother’s wife</>
      ],
      answersIncorrect: [
        <>Her husband</>,
        <>Her uncle’s wife</>,
        <>Her uncle</>
      ]
    },
    {
      question: <span>Q2: Who is it that didn’t understand the Akita dialect at all? </span>,
      answersCorrect: [
        <>Her husband</>
      ],
      answersIncorrect: [
        <>Her younger brother</>,
        <>Her younger brother’s wife</>,
        <>Her grandfather</>
      ]
    },
    {
      question: <span>
        Q3: Three はる expressions appeared in her conversation; first in
        (1){addRubyText("産","う")}まはってん, second in (2)しゃべったはる and lastly in (3){addRubyText("帰","かえ")}ってきやはった. For
        whom did she use はる respectively?
      </span>,
      answersCorrect: [
        <>(1)-for her younger brother’s wife, (2)-Akita people, and (3)-her husband</>
      ],
      answersIncorrect: [
        <>(1)-Akita people, (2)-her younger brother’s wife, (3)–her husband</>,
        <>(1)-Akita-people, (2)–her husband, (3)–her younger brother’s wife</>,
        <>(1)-her younger brother’s wife, (2)-her husband, (3)-Akita people</>,
        <>(1)-her husband, (2)-Akita people, (3)-her younger brother’s wife</>,
        <>(1)-her husband, (2)-her younger brother’s wife, (3)-Akita people</>
      ]
    }
  ];

  return <>
    <h2>ビデオ{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}８: Authentic - long<br />
    </h2>
    Watch the video clip and choose the answer that matches the content of
    the clip.
    Submit your answers after answering all the questions.<br />
    <small>*Click "SHOW" if you need to refer to the transcript</small><br />
    <br />
    Context: Ishioh-san was talking about
    regional dialects in Japan. <br />
    <table style={{textAlign: "left", width: "821px", height: "56px"}} border="0" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>{addVideoPlayer("4.4.1.p.8.ishioh.mp4")} <br />
          </td>
          <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>Transcript
            <Hideable content={transcript}/>
            <br />
            ＊{addRubyText("東北","とうほく")} Tohoku region in
            northern Honshu island in Japan<br />
            ＊{addRubyText("嫁","よめ")}さん wife<br />
            ＊{addRubyText("秋田","あきた")} Akita Prefecture (in the Tohoku area)<br />
            ＊ (Kansai) 〜して{addRubyText("間","ま")}なしに not long after ~ <br />
            ＊{addRubyText("孫","まご")} grandchild !!She mistakenly said 孫 (grandchild) to mean 子供(a
            child) in her conversation.<br />
            ＊〜がてら partly for ~, while ~<br />
            ＊{addRubyText("里","さと")}hometown<br />
            ＊さっぱり( -neg) not … in the least<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>イシオウさん
    (Shiga)<br />
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    <QandAForm qaObjects={qaObjects}/>
  </>;
}

export default V8;
