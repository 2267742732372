import React from "react";
import { Link } from "react-router-dom";
import { Page } from "home/page";
import { addRubyText, addAudio } from "common/util/generalUtil";

const OtherExpressions = () => {
  return (
    <>
      <h2>Other Useful Expressions</h2>
      <small>
        *<b>BOLD</b> type indicates high pitch<br />
        *Mouse over or click on the speaker icon for sound<br />
      </small>

      <h3>Really?</h3>
      <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Standard
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Kansai
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addRubyText("本当","ほんとう")}？ {addAudio("2.4.1.s.mp3")}
          </td>
          <td style={{verticalAlign: "top"}}>
            ほんま<b>ぁ</b>？ {addAudio("2.4.1.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            本当に？ {addAudio("2.4.2.s.mp3")}
          </td>
          <td style={{verticalAlign: "top"}}>
            ほんま<b>にぃ</b> {addAudio("2.4.2.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            本当？ (doubtful) {addAudio("2.4.3.s.mp3")}
          </td>
          <td style={{verticalAlign: "top"}}>
            ほんま<b>か</b>いな* {addAudio("2.4.3.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            本当だよ！ 、本当よ (gentle) {addAudio("2.4.5.s.mp3")}
          </td>
          <td style={{verticalAlign: "top"}}>
            ほん<b>ま</b>やで<b>ぇ</b> {addAudio("2.4.5.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            うそ！ "Not true/It's a lie!"{addAudio("2.4.6.s.mp3")}
          </td>
          <td style={{verticalAlign: "top"}}>
            う<b>そ</b>ぉ！ {addAudio("2.4.6.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            うそつくな！ "Don't lie!" {addAudio("2.4.7.s.mp3")}
          </td>
          <td style={{verticalAlign: "top"}}>
            う<b>そ</b>ぉ<b>つ</b>けぇ！ {addAudio("2.4.7.mp3")}
          </td>
        </tr>
        </tbody>
      </table>
      <br />
      *かいな： indictates a rhetorical question, and it can be used if a
      statement is doubtful such as;<br />
       ほんなこと、あるかいな！"No way that there is such
      a thing!"{addAudio("2.4.4.mp3")} <br />
      (More explanation will be given in
      <Link to={Page.BasicGrammar.SentenceEndings.Grammar.SentenceParticles}> Ch3.1b.2.1 Sentence Particles </Link>
      in Lesson 1b of Chapter 3.)<br />
      <br />
      <h3>Agree? Or Disagree?</h3>
      <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>Standard
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>Kansai
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            そうだ、そう {addAudio("2.4.8.s.mp3")}
          </td>
          <td style={{verticalAlign: "top"}}>
            <b>ほ</b>や
            <b>、そ</b>や、<b>せ</b>や、
            <b>そ</b>うや {addAudio("2.4.8.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            そうだ、そうだ！、そうそう！ {addAudio("2.4.9.s.mp3")}
          </td>
          <td style={{verticalAlign: "top"}}>
            <b>ほ</b>や
            <b>ほ</b>や、
            <b>そ</b>や
            <b>そ</b>や、<b>せ</b>や
            <b>せ</b>や {addAudio("2.4.9.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            そうだねえ、そうねえ(gentle) {addAudio("2.4.10.s.mp3")}
          </td>
          <td style={{verticalAlign: "top"}}>
            <b>ほ</b>や
            <b>な</b>ぁ<b>そ</b>や
            <b>な</b>ぁ<b>、せ</b>や
            <b>な</b>ぁ {addAudio("2.4.10.mp3")}</td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            そうだよ、そうよ(gentle)  {addAudio("2.4.11.s.mp3")}
          </td>
          <td style={{verticalAlign: "top"}}>
            <b>ほ</b>やで
            <b>ぇ、そ</b>やで
            <b>ぇ、せ</b>やで
            <b>ぇ</b> {addAudio("2.4.11.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            あ、そう {addAudio("2.4.12.s.mp3")}
          </td>
          <td style={{verticalAlign: "top"}}>
            あ、<b>ほうか〜</b>、あ、
            <b>そうか〜</b> {addAudio("2.4.12.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addRubyText("違","ちが")}う {addAudio("2.4.13.s.mp3")}
          </td>
          <td style={{verticalAlign: "top"}}>
            <b>ちゃう </b> {addAudio("2.4.13.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            違うよ {addAudio("2.4.14.s.mp3")}
          </td>
          <td style={{verticalAlign: "top"}}>
            <b>ちゃう
            </b>で<b>ぇ</b> {addAudio("2.4.14.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            違う？ {addAudio("2.4.15.s.mp3")}
          </td>
          <td style={{verticalAlign: "top"}}>
            <b>ちゃうか〜</b> {addAudio("2.4.15.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            違うって{addRubyText("言","い")}ってるんだよ！<br />
            違うって言ってるの！ {addAudio("2.4.16.s.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <b>ちゃう</b>っちゅうねん！ {addAudio("2.4.16.mp3")}
          </td>
        </tr>
        </tbody>
      </table>

      <div>
        *The choice of ほ／そ／せdepends on each region and generation.
      </div>
      <br />

      <h3>OK? OK!</h3>
      <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Standard
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Kansai
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            いいよ、いいわよ(gentle)　"Good, OK" {addAudio("2.4.17.s.mp3")}
          </td>
          <td style={{verticalAlign: "top"}}>
            え<b>え</b>で
            <b>ぇ</b>、え
            <b>え</b>よぉ、い
            <b>い</b>で
            <b>ぇ</b> {addAudio("2.4.17.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            いいよ、いいわよ(gentle) "No thanks" {addAudio("2.4.18.s.mp3")}
          </td>
          <td style={{verticalAlign: "top"}}>
            え<b>え</b>わぁ、い
            <b>い</b>わぁ {addAudio("2.4.18.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            いいねえ 、いいわねえ(gentle) {addAudio("2.4.19.s.mp3")}
          </td>
          <td style={{verticalAlign: "top"}}>
            ええ<b>な</b>ぁ、いい
            <b>な</b>ぁ {addAudio("2.4.19.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            いい？ {addAudio("2.4.20.s.mp3")}
          </td>
          <td style={{verticalAlign: "top"}}>
            ええ<b>かぁ</b>、いい
            <b>かぁ</b> {addAudio("2.4.20.mp3")}
          </td>
        </tr>
        </tbody>
      </table>
      <br />
      <h3>Tired & Troubled</h3>
      <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Standard
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Kansai
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addRubyText("疲","つか")}れた "Exhausted!" {addAudio("2.4.21.s.mp3")}
          </td>
          <td style={{verticalAlign: "top"}}>
            しん<b>どぉ</b>、えら
            <b>ぁ</b> {addAudio("2.4.21.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            疲れたねえ、疲れたわねえ(gentle) {addAudio("2.4.22.s.mp3")}
          </td>
          <td style={{verticalAlign: "top"}}>
            しん<b>ど</b>い
            <b>な</b>ぁ、
            <b>え</b>らい
            <b>な</b>ぁ {addAudio("2.4.22.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addRubyText("困","こま")}ったねえ、困ったわねえ(gentle) {addAudio("2.4.23.s.mp3")}
          </td>
          <td style={{verticalAlign: "top"}}>
            <b>な</b>んぎや
            <b>な</b>ぁ、
            <b>こ</b>まった
            <b>な</b>ぁ {addAudio("2.4.23.mp3")}
          </td>
        </tr>
        </tbody>
      </table>
      <br />

      <h3>Conjunctions</h3>
      <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Standard
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Kansai
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            そして {addAudio("2.4.24.s.mp3")}
          </td>
          <td style={{verticalAlign: "top"}}>
            <b>ほんで、そんで、ほて</b> {addAudio("2.4.24.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            それから {addAudio("2.4.25.s.mp3")}
          </td>
          <td style={{verticalAlign: "top"}}>
            <b>ほれから、ほんでから、それから、そんでから</b> {addAudio("2.4.25.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            だから {addAudio("2.4.26.s.mp3")}

          </td>
          <td style={{verticalAlign: "top"}}>
            <b>ほ</b>やから、
            <b>そ</b>やから、
            <b>せ</b>やから、
            <b>ほ</b>やし
            <b>、そ</b>やし、
            <b>せ</b>やし、
            <b>ほ</b>やさかい、
            <b>そ</b>やさかい、
            <b>せ</b>やさかい、
            <b></b>
            <b>ほ</b>やさけ {addAudio("2.4.26.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            けど、でも {addAudio("2.4.27.s.mp3")}
          </td>
          <td style={{verticalAlign: "top"}}>
            <b>ほ</b>やけど
            <b>、そ</b>やけど
            <b>、せ</b>やけど、
            <b>ほ</b>やかて
            <b>、そ</b>やかて、
            <b>せ</b>やかて
            <b>、ほんで</b>も
            <b>、そんで</b>も {addAudio("2.4.27.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            そうすると {addAudio("2.4.28.s.mp3")}
          </td>
          <td style={{verticalAlign: "top"}}>
            <b>ほと</b> {addAudio("2.4.28.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            じゃあ {addAudio("2.4.29.s.mp3")}
          </td>
          <td style={{verticalAlign: "top"}}>
            <b>ほ</b>な、
            <b>ほ</b>なら
            <b>、ほ</b>んなら
            <b>、そ</b>んなら、
            <b>ほ</b>んだら(used in Osaka/Nara) {addAudio("2.4.29.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            [Sentence]+から "because..."
          </td>
          <td style={{verticalAlign: "top"}}>
            〜から、〜し、〜さかい、〜さけ、〜よってに
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            [Sentence]+けど
          </td>
          <td style={{verticalAlign: "top"}}>
            〜けど、〜けんど、〜けども
          </td>
        </tr>
        </tbody>
      </table>
      <p>
        *The choice of ほ／そ／せ depends on each region and generation.
      </p>
    </>
  );
};

export default OtherExpressions;