import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";

const V12 = () => {
  const transcript = <>
    あの、{addRubyText("核家族","かくかぞく")}が、{addRubyText("多","おお")}いわな。ほて、{addRubyText("助","たす")}けてもらえる人、{addRubyText("今","いま")}は、ほんで、{addRubyText("世","よ")}の{addRubyText("中","なか")}、
    {addRubyText("保育所","ほいくしょ")}もあって、ええ{addRubyText("施設","しせつ")}ができたるんやけども，なかなかな。あの〜やっぱり{addRubyText("昔","むかし")}からおじ（い）おば（あ）{addRubyText("一緒","いっしょ")}
    に{addRubyText("住","す")}んで，うるさ〜いけど，うるさ〜い中にやっぱり一緒に<span style={{textDecoration: "underline"}}>住まなあかん </span>(Grammar 3)と{addRubyText("思","おも")}うわ，{addRubyText("私","わたし")}は。
  </>;

  const qaObjects = [
    {
      question: <span>Q: What is her opinion regarding the increase of nuclear families in Japan?</span>,
      answersCorrect: [
        <>Against it. She thinks families should live with extended family members even despite the noise.</>
      ],
      answersIncorrect: [
        <>Against it. She thinks families should take care of extended family members currently in the nursing homes.</>,
        <>She is appreciative, since more nursery schools and nursing homes have been established.</>,
        <>he is appreciative, since she doesn’t think it’s ideal to live with noisy extended family members.</>
      ]
    }
  ];

  return <>
    <h2>ビデオ{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}１２: Authentic - medium<br />
    </h2>
    Watch the video clip and choose the answer that matches the content of
    the clip.<br />
    <small>*Click "SHOW" if you need to refer to the transcript.</small><br />
    <br />
    Ishioh-san was talking about families nowadays.<br />
    <table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2"
    cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>{addVideoPlayer("4.2.3.p.8.ishioh.mp4")} </td>
          <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>Transcript
            <Hideable content={transcript}/>
            <br />
            ＊{addRubyText("核家族","かくかぞく")} nuclear family<br />
            ＊{addRubyText("世","よ")}の{addRubyText("中","なか")} the world<br />
            ＊{addRubyText("保育所","ほいくしょ")} day nursery, nursery school<br />
            ＊{addRubyText("施設","しせつ")} institution, (nursing) home<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>イシオウさん (Shiga)</td>
        </tr>
      </tbody>
    </table>
    <br />
    <QandAForm qaObjects={qaObjects}/>
  </>;
}

export default V12;
