import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";

const V6 = () => {
  const transcript = <>
    、、えーとなあ、{addRubyText("最初","さいしょ")}はなあ、もらったりしてたけどなあ、{addRubyText("後","あと")}から{addRubyText("見","み")}っけてきた。、、お{addRubyText("母","かあ")}さん。うん、お母さん
    が<span style={{textDecoration: "underline"}}>おいたはった</span>(Grammar
    1)、はったんかあ。それな、ほしいって{addRubyText("言","い")}ってな、もらったら、いいって<span
    style={{textDecoration: "underline"}}>言わはった</span>。、、{addRubyText("他","ほか")}の？他の、、はどうしたっ
    け？、、、{addRubyText("落","お")}ちてた。うん。ああ、、、それとな、えーっと、なんか{addRubyText("買","か")}いに<span
    style={{textDecoration: "underline"}}>{addRubyText("来","き")}はる</span>{addRubyText("時","とき")}にな、もらったことがある。う
    ん。そ
    こになんか買いに<span style={{textDecoration: "underline"}}>きゃはった</span>時にな、そこにな、お
    {addRubyText("金","かね")}見っけたからちょっと{addRubyText("頂戴","ちょうだい")}って言ったらおばあちゃんが<span
    style={{textDecoration: "underline"}}>くれはった</span>時あった、、、と{addRubyText("思","おも")}う。、、（{addRubyText("子供","こども")}は）{addRubyText("楽","らく")}やなあ。
  </>;

  const qaObjects = [
    {
      question: <span>Q1: How did Takashi get the money? Choose all that apply.</span>,
      answersCorrect: [
        <>His mother gave him some money that she had left and he found.</>,
        <>He found money here and there.</>,
        <>His grandmother let him keep the money he found at the office of the shrine.</>
      ],
      answersIncorrect: [
        <>His grandmother gave him some money when they went shopping. </>,
        <>His mother gave him some money when he helped her with choirs.</>
      ]
    },
    {
      question: <span>Q2: For whom did he use はる? Choose all that apply.</span>,
      answersCorrect: [
        <>His mother</>,
        <>His grandmother</>,
        <>Visitors to the shrine who came to buy charms and such</>
      ],
      answersIncorrect: [
        <>Interviewer</>,
        <>Himself</>
      ]
    }
  ];

  return <>
    <h2>ビデオ{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}６</h2>
    Watch the video clip and choose the answer that matches the content of
    the clip.
    Submit your answers after answering all the questions.<br />
    <small>*Click "SHOW" if you need to refer to the transcript</small><br />
    <br />
    Context: Takashi just said he had given
    his mother money on Mother’s
    Day. Then he was asked how he got the money. (This interview was
    conducted near the office of a shrine where his grandmother sells lucky
    charms and such.)<br />
    <table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>{addVideoPlayer("4.4.1.p.7.takashi.mp4")}&nbsp; <br />
          </td>
          <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>Transcript
    <Hideable content={transcript}/><br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>タカシ (Shiga)</td>
        </tr>
      </tbody>
    </table>
    <br />
    <QandAForm qaObjects={qaObjects}/>
  </>;
}

export default V6;
