import React from "react";
import { addRubyText, addAudio } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";

const P1 = () => {
  const transcript = <>
    <ol className="transcript">
      <li>A: テレビ、見る？ </li>
      <li>B: テレビ？うん、見るよ。</li>
    </ol>
  </>;
  return <>
    <h2>{addRubyText("練習","れんしゅう")}1: Assertion で</h2>
    Form a question and an answer for each cue according to
    a model. You may listen to the question,
    try to answer it, and check your answer. *Answers vary; the recorded
    answers indicate those commonly used.
    <br />
    <small>*Move the mouse over to the speaker icon for sound</small>
    <br />
    <span style={{fontWeight: "bold"}}><br />Model</span> (Cue: テレビ／{addRubyText("見","み")}る) 
    <br />
    &nbsp;&nbsp;&nbsp; A: テレビ、見る（<span style={{textDecoration: "underline"}}>かぁ</span>）？
    <br />
    &nbsp;&nbsp;&nbsp; B: テレビ？うん、見るで。{addAudio("3.1.b.p.1.mp3")}
    <br />
    <br />
    ST transcript
    <br />
    <Hideable content={transcript}/>;
    <br />
    <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Cue<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            A (Question)<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            B (Answer)<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            テレビ／{addRubyText("見","み")}る<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.1.b.p.1.1.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.1.b.p.1.1.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addRubyText("本","ほん")}／{addRubyText("借","か")}りる<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.1.b.p.1.2.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.1.b.p.1.2.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            このビデオ／{addRubyText("見","み")}る<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.1.b.p.1.3.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.1.b.p.1.3.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            コーヒー／{addRubyText("飲","の")}む<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.1.b.p.1.4.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.1.b.p.1.4.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            あそこ／{addRubyText("行","い")}く<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.1.b.p.1.5.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.1.b.p.1.5.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            あのおかし／{addRubyText("買","か")}う<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.1.b.p.1.6.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.1.b.p.1.6.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            たばこ／{addRubyText("吸","す")}う<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.1.b.p.1.7.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.1.b.p.1.7.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            お{addRubyText("金","かね")}／もらう<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.1.b.p.1.8.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.1.b.p.1.8.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            この{addRubyText("辞書","じしょ")}／{addRubyText("使","つか")}う<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.1.b.p.1.9.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.1.b.p.1.9.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            このゲーム／する<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.1.b.p.1.10.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.1.b.p.1.10.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            たかし{addRubyText("君","くん")}／{addRubyText("来","く")}る<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.1.b.p.1.11.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.1.b.p.1.11.b.mp3")}<br />
          </td>
        </tr>
      </tbody>
    </table>
  </>;
}

export default P1;
