import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";

const V1 = () => {
  const transcript = <>
    <br />
      <ol>
        <li><small>A：{addRubyText("明日","あした")}から{addRubyText("旅行","りょこう")}やろ？</small></li>
        <li><small>B：うん。{addRubyText("荷物","にもつ")}はもう{addRubyText("詰","つ")}めたんやけど、{addRubyText("他","ほか")}にせなあかんこといっぱいあってな〜、か
なんわ。</small></li>
        <li><small>A：ふうん。{addRubyText("何","なに")}せなあかんの？</small></li>
        <li><small>B：{addRubyText("洗濯","せんたく")}しなあかんし、{addRubyText("歯医者","はいしゃ")}さんも{addRubyText("行","い")}かんならんし。</small></li>
        <li><small>A：{addRubyText("今日","きょう")}やないといかんの？</small></li>
        <li><small>B：いや、歯医者は{addRubyText("帰","かえ")}ってきてからでもええねけど。あ、せやけど{addRubyText("図書館","としょかん")}の{addRubyText("本","ほん")}
{addRubyText("返","かえ")}
さないかんし、その本のレポート{addRubyText("書","か")}かんと〜。</small></li>
        <li><small>A：そんな{addRubyText("忙","いそが")}しかったら、旅行行かんでもええやん。</small></li>
        <li><small>B：いや、旅行は行かなあかんねん。</small></li>
        <li><small>A：なんで？</small></li>
        <li><small>B：キャンセル{addRubyText("代","だい")}{addRubyText("高","たか")}いねん。</small></li>
      </ol>
  </>;

  const qaObjects = [
    {
      question: <span>Q: Choose all the things that the second speaker has to do from the list. </span>,
      answersCorrect: [
        <>Do laundry</>,
        <>Return a library book</>,
        <>Write a report about a book</>
      ],
      answersIncorrect: [
        <>Pack the luggage</>,
        <>Go to see a dentist today</>,
        <>Read a library book</>,
        <>Check out a library book</>,
        <>Read a report about the library book</>,
        <>Cancel her trip</>
      ]
    }
  ]

  return <>
    <h2>ビデオ{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}１<br />
    </h2>
    This is a conversation between roommates at a college dormitory. Watch
    the clip, and answer the following question. <br />
    <small>*Click SHOW if you need to refer to the transcript.</small><br />
    <br />
    <table style={{textAlign: "left", width: "100%"}} border="1"cellPadding="2"
    cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addVideoPlayer("4.2.vc.1.mp4")}</td>
          <td style={{verticalAlign: "top"}}><Hideable content={transcript}/>
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    <QandAForm qaObjects={qaObjects}/>
  </>;
}

export default V1;
