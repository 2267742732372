import React from "react";
import { addRubyText, addAudio } from "common/util/generalUtil";

const Yanka = () => {
  return <>
    <h2>やんか</h2>
    <small>*Mouse over or click on the speaker icon for sound</small><br />
    <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>
            <br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Standard<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Kansai<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            Strong Confirmation<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            じゃない（か）<br />
            じゃん（か）<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            やん（か／かいさ）<br />
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    The expression “ X (direct/plain form) じゃない／じゃん（か）？”&nbsp; indicates
    that the preceding predicate is assumed by the speaker to represent
    something the person addressed must surely agree with. “Surely you
    agree that X is true!” “Don’t you agree…?” This becomes 〜やん（か／かいさ） in
    the Kansai dialect, and it is very commonly used.
    <br />
    <br />
    えらいなあ。 {addRubyText("野菜","やさい")}、{addRubyText("食","た")}べられる<span style={{textDecoration: "underline"}}>やんか</span>！{addAudio("3.1.b.3.1.mp3")}
    <br />
    <small>（ST&nbsp; えらいねえ、野菜が食べられる<span style={{textDecoration: "underline"}}>じゃない！</span>）</small><small>{addAudio("3.1.b.3.1.s.mp3")}</small>
    <br />
    <br />
    この{addRubyText("漫画","まんが")}、なかなかおもろい<span style={{textDecoration: "underline"}}>やん</span>。<small></small>{addAudio("3.1.b.3.2.mp3")}
    <br />
    <small>（ST この漫画、なかなかおもしろい<span style={{textDecoration: "underline"}}>じゃない！</span>）</small><small>{addAudio("3.1.b.3.2.s.mp3")}</small>
    <br />
    <br />
    ここで食べるのん？→まあ、ええ<span style={{textDecoration: "underline"}}>やんか</span>。<small></small>{addAudio("3.1.b.3.3.mp3")}
    <br />
    <small>（ST ここで食べるの？ー＞まあ、いい<span style={{textDecoration: "underline"}}>じゃん！</span>）</small><small>{addAudio("3.1.b.3.3.s.mp3")}</small>
    <br />
    <br />
    あ、あれたかし{addRubyText("君","くん")}<span style={{textDecoration: "underline"}}>やん</span>！<small>
    </small>{addAudio("3.1.b.3.4.mp3")}<br /><small>
    （ST あれ、たかし君<span style={{textDecoration: "underline"}}>じゃん！</span>）</small><small>{addAudio("3.1.b.3.4.s.mp3")}</small>
    <br />
    <br />
    <hr />
    <h3>Extra 1: です／ます＋やんか&nbsp;&nbsp;</h3>
    This expression やん（か） can be used with です／ます style as well (often used
    by older generation though). In this case all you have to do is add
    やん（か） after the whole sentence, unlike the standard (direct +じゃないですか). 
    <br />
    <br />
    この{addRubyText("仕事","しごと")}、{addRubyText("昨日","きのう")}やりました<span style={{textDecoration: "underline"}}>やんか</span>。<small></small>{addAudio("3.1.b.3.5.mp3")}
    <br />
    <small>(ST この仕事、昨日、やった<span style={{textDecoration: "underline"}}>じゃないですか！</span>）</small><small>{addAudio("3.1.b.3.5.s.mp3")}</small>
    <br />
    <br />
    この{addRubyText("犬","いぬ")}、ようけ食べます<span style={{textDecoration: "underline"}}>やんか</span>。<small></small>{addAudio("3.1.b.3.6.mp3")}
    <br />
    <small>（ST この犬、たくさん食べる<span style={{textDecoration: "underline"}}>じゃないですか！</span>）</small><small>{addAudio("3.1.b.3.6.s.mp3")}</small>
    <br />
    <br />
  </>;
}

export default Yanka;
