import React from "react";
import { addRubyText, addAudio } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";

const L4 = () => {
  const transcript = <>
    <ol>
      <li><small>A: ほんならゆっくり{addRubyText("行","い")}きや。</small></li>
      <li><small>B: よっしゃ。シートベルト、{addRubyText("締","し")}めや。</small></li>
      <li><small>A: もう{addRubyText("締","し")}めてるわ。ちょっと、ハンドルばっかり{addRubyText("見","み")}んと、{addRubyText("前","まえ")}見いな。</small></li>
      <li><small>B: わ、{addRubyText("分","わ")}かった。ふう、なんか{addRubyText("暑","あつ")}ない？</small></li>
      <li><small>A:
    ほんなら{addRubyText("窓","まど")}{addRubyText("開","あ")}けぇや。うわ、そないに{addRubyText("全部","ぜんぶ")}{addRubyText("開","あ")}けなや。あ、{addRubyText("信号","しんごう")}で{addRubyText("左","ひだり")}に{addRubyText("曲","ま")}がりぃ。</small></li>
      <li><small>B: 信号でやな。おっけー。</small></li>
      <li><small>A:
    あ〜、そっちちゃうちゃう。{addRubyText("右","みぎ")}曲がりなや。左やで。うわ〜、そんな{addRubyText("急","きゅう")}にハンドル{addRubyText("切","き")}りなや。</small></li>
      <li><small>B: ごめん、ごめん。</small></li>
      <li><small>A: {addRubyText("私","わたし")}もう{addRubyText("降","お")}りるわ。あんたの{addRubyText("横","よこ")}で{addRubyText("死","し")}にとない〜。</small></li>
    </ol>
  </>;

  const qaObjects = [
    {
      question: <>Q: Choose all applicable advice from A to B from the following statements.</>,
      answersCorrect: [
        <>Drive slowly.</>,
        <>Look forward.</>,
        <>Turn left at the traffic light.</>,
        <>Do not open the windows completely.</>
      ],
      answersIncorrect: [
        <>Fasten your seatbelt.</>,
        <>Look at the steering wheel.</>,
        <>Turn right at the traffic light</>,
        <>Turn at the corner quickly.</>,
        <>Open the window quickly.</>
      ]
    }
  ]

  return <>
    <h2>{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}４</h2>
    Ms. A is telling her&nbsp; friend Ms. B, who is a beginning
    driver,&nbsp; how to drive a car, while sitting on a passenger seat of
    B’s car. <br />
    <small>* Click "SHOW" if you need to refer to the transcript.</small><br />
    <small>*Move the mouse over to the speaker icon for sound</small><br />
    <br />
    {addAudio("4.3.pl0.4.mp3")} <Hideable content={transcript} />
    <br />
    <QandAForm qaObjects={qaObjects} />
  </>;
}

export default L4;
