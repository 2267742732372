import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";

const FruitShop = () => {
  const vocabList = <ul style={{display: "block", overflow: "visible"}}>
    <li>
      こってり thick, heavy, rich
    </li>
    <li>
      あっさり simple, plain, light (in taste)
    </li>
    <li>
      {addRubyText("種類","しゅるい")} kind
    </li>
    <li>
      りんご apple
    </li>
    <li>
      みかん mandarin orange
    </li>
    <li>
      いちご strawberry
    </li>
  </ul>;

  const qaObjects = [
    {
      question: <span>Q1: What is the main difference between two kinds of ミックスジュース?</span>,
      answersIncorrect: [
        <span>The amount of fruits that are mixed.</span>,
        <span>Whether they taste strong or light.</span>
      ],
      answersCorrect: [
        <span>The number of different fruits that are mixed.</span>
      ]
    },
    {
      question: <span>Q2: What are the shared ingredients of two different kinds of ミックスジュース? Choose all that apply.</span>,
      answersIncorrect: [
        <span>Strawberry.</span>,
        <span>Pineapple.</span>,
        <span>Melon.</span>,
        <span>Mango.</span>,
        <span>Lemon.</span>
      ],
      answersCorrect: [
        <span>Apple.</span>,
        <span>Banana.</span>,
        <span>Orange.</span>
      ]
    },
    {
      question: <span>Q3: Which ミックスジュース did トモちゃん order?</span>,
      answersIncorrect: [
        <span>あっさり{addRubyText("白","しろ")}.</span>
      ],
      answersCorrect: [
        <span>こってり{addRubyText("赤","あか")}.</span>
      ]
    },
    {
      question: <span>Q4: How much is the one トモちゃん ordered?</span>,
      answersIncorrect: [
        <span>400-yen.</span>,
        <span>350-yen.</span>,
        <span>540-yen</span>
      ],
      answersCorrect: [
        <span>450-yen.</span>
      ]
    }
  ];

  return (
    <>
      <h2>{addRubyText("果物屋","くだものや")}で (At a fruit shop in Kyoto)</h2>
      <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>
            [Context] トモちゃん is in front of a fruit shop in Kyoto that serves freshly squeezed fruit juice.
          </td>
        </tr>
        </tbody>
      </table>
      <br />
      <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addVideoPlayer("5.6.juice.mp4")}<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            Vocabulary List <br />
            <Hideable content={vocabList} />
          </td>
        </tr>
        </tbody>
      </table>
      <h3>{addRubyText("内容質問","ないようしつもん")} (Content Questions)</h3>
      <QandAForm qaObjects={qaObjects} />
    </>
  );
};

export default FruitShop;
