import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";

const V8 = () => {
  const transcript = <>
    まあNHKなんか、あのアナウンサーなんか{addRubyText("皆","みな")}{addRubyText("標準語","ひょうじゅんご")}でしゃべってんにゃろけど，そっから<span
    style={{textDecoration: "underline"}}>{addRubyText("勉強","べんきょう")}せな</span>
    (Grammar 2) 標準語は{addRubyText("知","し")}りませんね。知ってるようで知らないと{addRubyText("思","おも")}いますよ。
  </>;

  const qaObjects = [
    {
      question: <span>Q: Does he think he knows precise Standard Japanese ?</span>,
      answersCorrect: [
        <>No, unless he studies Standard referring to NHK announcers’ speech.</>
      ],
      answersIncorrect: [
        <>No, but he wants to be able to speak Standard just like NHK announcers.</>,
        <>No, unless he has some friend who works as a NHK announcer.</>,
        <>No, but he wants to study Standard from NHK announcers’ speech.</>
      ]
    }
  ];

  return <>
    <h2>ビデオ{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}８: Authentic - short<br />
    </h2>
    Watch the video clip and choose the answer that matches the content of
    the clip.<br />
    <small>*Click "SHOW" if you need to refer to the transcript.</small><br />
    <br />
    Kusaka-san was asked if he can speak Standard Japanese.<br />
    <table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>{addVideoPlayer("4.2.2.p.3.kusaka.mp4")} </td>
          <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>Transcript
    <Hideable content={transcript}/><br />
    ＊NHK - Japan Broadcasting Corporation</td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>クサカさん
    (Shiga/Kyoto)</td>
        </tr>
      </tbody>
    </table>
    <br />
    <QandAForm qaObjects={qaObjects}/>
  </>;
}

export default V8;
