import React from "react";
import { addRubyText } from "common/util/generalUtil";
import { DraggableForm } from "common/util/draggableForm";

const L1 = () => {
  const draggableFormData = {
    prompts: {
      images: [
        "3.2.pl0.1.a.jpg",
        "3.2.pl0.1.b.jpg",
        "3.2.pl0.1.c.jpg",
        "3.2.pl0.1.d.jpg",
        "3.2.pl0.1.e.jpg",
        "3.2.pl0.1.f.jpg",
        "3.2.pl0.1.g.jpg",
        "3.2.pl0.1.h.jpg",
        "3.2.pl0.1.i.jpg",
        "3.2.pl0.1.j.jpg",
        "3.2.pl0.1.k.jpg"
      ]
    },
    answers: {
      audioFiles: [
        "3.2.pl0.1.2.mp3",
        "3.2.pl0.1.3.mp3",
        "3.2.pl0.1.7.mp3",
        "3.2.pl0.1.5.mp3",
        "3.2.pl0.1.6.mp3",
        "3.2.pl0.1.8.mp3",
        "3.2.pl0.1.4.mp3",
        "3.2.pl0.1.1.mp3",
        "3.2.pl0.1.10.mp3",
        "3.2.pl0.1.11.mp3",
        "3.2.pl0.1.9.mp3",
      ],
      texts: [
        <>これ、{addRubyText("棚","たな")}にしもてや。</>,
        <>こっち、{addRubyText("来","き")}てんか。</>,
        <>これ、ほかしといてな。</>,
        <>それ、{addRubyText("取","と")}ってくれへん？</>,
        <>ちょっとこいつ、{addRubyText("外","そと")}{addRubyText("連","つ")}れてってんか。</>,
        <>あ、それは{addRubyText("触","さわ")}らんといてや！</>,
        <>ちょっと、{addRubyText("中","なか")}では{addRubyText("吸","す")}わんといて。</>,
        <>これ、{addRubyText("持","も")}ってえな。</>,
        <>じゃま！そこ、のいたって。</>,
        <>もうちょっと、きれいにしてくれんか。</>,
        <>そんなとこ、{addRubyText("置","お")}かんといてえや。</>,
      ]
    }
  };
  return <>
    <h2>{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}1</h2>
    Listen to your host-mother’s request, and drag and drop the auido onto
    the illustration that most appropriately describes what she wants/does not want you to do.
    If your get a right answer, you will hear "Right!!" and the audio will
    stick to the illustration. If it's wrong, the audio will bounce back to
    the original location. Click “SHOW” if you want to refer to the transcript.
    <br />
    <br />
    <small>＊しまう put away,{addRubyText("触","さわ")}る touch, のく step aside</small>
    <br />
    <DraggableForm prompts={draggableFormData.prompts} answers={draggableFormData.answers} />
  </>;
}

export default L1;
