import React from "react";
import { addRubyText } from "common/util/generalUtil";
import { DraggableForm } from "common/util/draggableForm";

const L1 = () => {
  const draggableFormData = {
    prompts: {
      images: [
        "4.2.pl0.1.b.jpg",
        "4.2.pl0.1.a.jpg",
        "4.2.pl0.1.c.jpg",
        "4.2.pl0.1.d.jpg",
        "4.2.pl0.1.f.jpg",
        "4.2.pl0.1.e.jpg",
        "4.2.pl0.1.h.jpg",
        "4.2.pl0.1.g.jpg"
      ]
    },
    answers: {
      texts: [
        <><small>{addRubyText("薬","くすり")}{addRubyText("飲","の")}みとなかったら飲まんでもええよ。</small></>,
        <><small>{addRubyText("薬","くすり")}{addRubyText("飲","の")}みとのうても飲まなあかんで！</small></>,
        <><small>{addRubyText("疲","つか")}れたら{addRubyText("歩","ある")}かんでもかまへんで。</small></>,
        <><small>さあ、しっかり{addRubyText("歩","ある")}かんと！</small></>,
        <><small>カーテン{addRubyText("閉","し")}めんことには{addRubyText("見","み")}えへんがな。</small></>,
        <><small>カーテン{addRubyText("閉","し")}めんでもよろしおすえ。{addRubyText("別","べつ")}に{addRubyText("眩","まぶ")}しないし。</small></>,
        <><small>ここは{addRubyText("靴","くつ")}{addRubyText("脱","ぬ")}がんとあかんらしいで。</small></>,
        <><small>ここは{addRubyText("靴","くつ")}{addRubyText("脱","ぬ")}がんでもええんやって。</small></>
      ], 
      audioFiles: [
        "4.2.pl0.1.1.mp3",
        "4.2.pl0.1.2.mp3",
        "4.2.pl0.1.3.mp3",
        "4.2.pl0.1.4.mp3",
        "4.2.pl0.1.5.mp3",
        "4.2.pl0.1.6.mp3",
        "4.2.pl0.1.7.mp3",
        "4.2.pl0.1.8.mp3"
      ]
    }
  }

  return <>
    <h2>{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}1</h2>
    Listen to the audio, and drag the audio box to the illustration that is
    most appropriate to describe the speaker’s situation. If you get the
    right answer, you will hear "Right!" and the audio will stick to the
    illustration. If it is not the right answer, the audio box will bounce
    back to the original location.<br />
    <small>* Click "SHOW" if you need to refer to the transcript.&nbsp; <br />
    *Move the mouse over to the speaker icon for sound.</small><br />
    <br />
    <DraggableForm prompts={draggableFormData.prompts} answers={draggableFormData.answers}/>
  </>;
}

export default L1;

