import React from "react";
import { Link } from "react-router-dom";
import { Page } from "home/page";
import { addAudio, addRubyText } from "common/util/generalUtil";

const Words = () => {
  return (
    <>
      <h2>Words (Lexical Feature)</h2>
      <div>
        There are various lexical differences between the Standard and Kansai
        dialect from traditional ones to those created recently, such as:
      </div>
      <div>
        <small>*Mouse over or click on the speaker icon for sound</small>
      </div>
      <table style={{textAlign: "left", width: "80%", minWidth: "600px"}} border="1" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top"}} />
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Tokyo
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Kansai
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            "stumble"
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            ころぶ {addAudio("1.2.c.1.s.mp3")}
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            こける {addAudio("1.2.c.1.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            "no good"
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            いけない {addAudio("1.2.c.2.s.mp3")}
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            あかん {addAudio("1.2.c.2.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            "strange; queer"
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addRubyText("奇妙", "きみょう")}な {addAudio("1.2.c.3.s.mp3")}
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            けったいな {addAudio("1.2.c.3.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            "boring"
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            つまらない {addAudio("1.2.c.4.s.mp3")}
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            しょうもない {addAudio("1.2.c.4.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            "many, a lot"
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            たくさん {addAudio("1.2.c.5.s.mp3")} </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            ぎょうさん、ようけ {addAudio("1.2.c.5.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            "chicken"
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addRubyText("鶏肉", "とりにく")} {addAudio("1.2.c.6.s.mp3")}
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            かしわ {addAudio("1.2.c.6.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            "rice porridge"
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            お{addRubyText("粥", "かゆ")} {addAudio("1.2.c.7.s.mp3")}
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            おかいさん {addAudio("1.2.c.7.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            "McDonald" (fast-food chain)
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            マック {addAudio("1.2.c.8.s.mp3")} </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            マクド {addAudio("1.2.c.8.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            "false" (in true-false)
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            X（ばつ）{addAudio("1.2.c.9.s.mp3")}
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            X（ぺけ）{addAudio("1.2.c.9.mp3")}
          </td>
        </tr>
        </tbody>
      </table>
      <br />
      <div>
        Kansai peculiar words and expressions that apprear in this site are
        listed under <Link to={Page.Index.Glossary}>Kansai Glossary.</Link>
      </div>
      <div>
        Please also refer to the following website which lists a variety of Kansai words.
      </div>
      <br />
      <div>
        <a href="http://www.nihongoresources.com/language/dialects/kansaiben/wordlist.html">
          <u>http://www.nihongoresources.com/language/dialects/kansaiben/wordlist.html</u>
        </a>
      </div>
    </>
  );
};


export default Words;