import React from "react";
import { Link } from "react-router-dom";
import { Page } from "home/page";
import { addRubyText, addImage } from "common/util/generalUtil";

const TeForm = () => {
  return <>
    <h2>Lesson 2:{addRubyText("動詞","どうし")}の て{addRubyText("形","けい")}(Verb て form) <br/></h2>
    <h3>〜お{addRubyText("酒","さけ")}{addRubyText("飲","の")}まんと{addRubyText("帰","かえ")}ってきてやぁ。〜</h3>
    <table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td colSpan="1" rowSpan="1" style={{verticalAlign: "top"}}>
            <ul>
              <li>
                <Link to={Page.BasicGrammar.VerbTeForm.Conversations}>
                  {addRubyText("基本会話","きほんかいわ")} (Core Conversations)
                </Link>
              </li>
              <li>
                <Link to={Page.BasicGrammar.VerbTeForm.Grammar.Grammar}>
                  {addRubyText("文法","ぶんぽう")}ノート (Grammar Note)
                </Link>
              </li>
              <li>
                <Link to={Page.BasicGrammar.VerbTeForm.Practice.Practice}>
                  {addRubyText("練習","れんしゅう")} (Practice)
                </Link>
              </li>
              <li>
                <Link to={Page.BasicGrammar.VerbTeForm.Listening.Listening}>
                  {addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")} (Listening Practice)
                </Link>
              </li>
              <li>
                <Link to={Page.BasicGrammar.VerbTeForm.Video.Video}>
                  ビデオ{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}(Video Comprehension Practice)
                </Link> 
              </li>
            </ul>
          </td>
          <td colSpan="1" rowSpan="1" style={{verticalAlign: "top", textAlign: "center"}}>
            {addImage("shika.jpg")}
            <br/>
            <small>
              奈良公園の鹿 Sacred Deers in Nara Park (Nara)
            </small>
            <br/>
          </td>
        </tr>
        <tr align="center">
        </tr>
      </tbody>
    </table>
  </>;
}

export default TeForm;