import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";

const VegetableShop = () => {
  const vocabList = <ul style={{display: "block", overflow: "visible"}}>
    <li>
      えんどう{addRubyText("豆","まめ")} peas
    </li>
    <li>
      むく peel
    </li>
    <li>
      {addRubyText("生豆","きまめ")} row peas
    </li>
    <li>
      {addRubyText("合","ごう")}<i>goh</i> (one <i>goh</i> =180 cc)
    </li>
    <li>
      {addRubyText("炊","た")}く cook
    </li>
    <li>
      おねぎ(Kansai)＝ねぎ scallion; green onion (polite)
    </li>
    <li>
      {addRubyText("大束","おおたば")} a big bunch ({addRubyText("束","たば")}a bunch)
    </li>
    <li>
      らっきょう shallot; scallion
    </li>
    <li>
      {addRubyText("漬","つ")}ける soak; marinade
    </li>
    <li>
      {addRubyText("塩","しお")}{addRubyText("漬","づ")}け food preserved in salt
    </li>
    <li>
      じかに directly
    </li>
    <li>
      ぐにゃぐにゃ flabby
    </li>
    <li>
      {addRubyText("淡竹","はちく")}a kind of {addRubyText("筍","たけのこ")}(bamboo shoot)
    </li>
    <li>
      ゆがく boil
    </li>
    <li>
      あく scum; harshness
    </li>
  </ul>;

  const qaObjects = [
    {
      question: <span>Q1: How many servings of Pea rice can one bag of peas make?</span>,
      answersIncorrect: [
        <span>One-<i>goh.</i></span>,
        <span>Four-<i>goh.</i></span>
      ],
      answersCorrect: [
        <span>Three-<i>goh.</i></span>
      ]
    },
    {
      question: <span>Q2: How much is a big bunch of ねぎ?</span>,
      answersIncorrect: [
        <span>83-yen.</span>,
        <span>830-yen.</span>
      ],
      answersCorrect: [
        <span>380-yen.</span>
      ]
    },
    {
      question: <span>Q3: How can you find the instruction to marinate らっきょう？</span>,
      answersIncorrect: [
        <span>The instruction is in the bag.</span>,
        <span>You have to check the URL written on the bag.</span>
      ],
      answersCorrect: [
        <span>The instruction is written on the bag.</span>
      ]
    },
    {
      question: <span>Q4: What is the best way to marinate らっきょう？</span>,
      answersIncorrect: [
        <span>Preserve them in salt before marinating.</span>
      ],
      answersCorrect: [
        <span>Not preserve them in salt, directly marinate them.</span>
      ]
    },
    {
      question: <span>Q5: Choose all the correct information regarding はちく.</span>,
      answersIncorrect: [
        <span>Never boil it when you cook it with peas.</span>,
        <span>You have to get rid of scum extensively while cooking.</span>
      ],
      answersCorrect: [
        <span>Boil it first and cook it with peas.</span>,
        <span>No need to get rid of scum while cooking.</span>
      ]
    },
    {
      question: <span>Q6: How much is はちく?</span>,
      answersIncorrect: [
        <span>45-yen.</span>,
        <span>50-yen.</span>,
        <span>540-yen.</span>
      ],
      answersCorrect: [
        <span>450-yen.</span>
      ]
    },
    {
      question: <span>Q7: How much does she have to pay?</span>,
      answersIncorrect: [
        <span>3,300-yen.</span>,
        <span>9,390-yen.</span>,
        <span>9,030-yen.</span>
      ],
      answersCorrect: [
        <span>3,930-yen.</span>
      ]
    },
    {
      question: <span>Q8: What did she buy? Choose all that she bought.</span>,
      answersIncorrect: [
        <span>Cabbage.</span>,
        <span>Cucumber.</span>,
        <span>Onion.</span>,
        <span>Broccoli.</span>
      ],
      answersCorrect: [
        <span>Peas.</span>,
        <span>Green onion.</span>,
        <span>Shallot.</span>,
        <span>Bamboo shoots.</span>
      ]
    }
  ]

  return (
    <>
      <h2>{addRubyText("八百屋","やおや")}で (At a vegetable shop in Otsu, Shiga)</h2>
      <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>
            [Context] ミツエさん is in a vegetable shop on the same shopping street as the fish shop.
          </td>
        </tr>
        </tbody>
      </table>
      <br />
      <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addVideoPlayer("5.2.mp4")}<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            Vocabulary List <br />
            <Hideable content={vocabList} />
          </td>
        </tr>
        </tbody>
      </table>
      <h3>{addRubyText("内容質問","ないようしつもん")} (Content Questions)</h3>
      <QandAForm qaObjects={qaObjects} />
    </>
  );
};

export default VegetableShop;
