import React from "react";
import { Link } from "react-router-dom";
import { Page } from "home/page";

const HowToUse = () => {
  return (
    <>
      <h2>How to use this site in class (for teachers)</h2>
      <div>このページではこのサイトを実際に授業で使用なさった経験のある先生方の使用例をご紹介します。</div>
      <hr />
      <h3>山中恵美先生 (元ハーバード大学４、５年生担当）</h3>
      <div>
          サイトは、ビデオ教材と一緒に使っています。４，５年生を教えているんですが、私自身が英語を理解する上で、文化との絡みが多すぎて一番分からな
          かった 「笑い」に敢えて挑戦してみたかったので、『しゃべれども、しゃべれども』という落語を扱った映画(2007)を使っています。その中に小学生の
          関西弁を 話す男の子が出てくるんですが、映画を見る前に「関西弁講座」をしておいて、クラス外で見させてきます。学生の中にも関西に行く予定の人もいます
          し、み んな結構興味深そうにクラスに参加します。
      </div>
      <br />
      私は広島弁の「ネイティブ」で、なかなか発音も正確にまねできないので、ハンドアウトとサイトの一部（ハンドアウト内のリンクとビデオクリップ）
      を使いながらルールを説明し、まずは映画の一部を聞き取り、その後、よりオーセンティックなものを聞くために、サイトの中からインタビュークリッ
      プをいく
      つか選んで、聞き取り練習をさせます。これでウォームアップをしたのち、学生は映画を見てきて、その後のクラス内でも少しその男の子のセリフに触
      れたりし
      ます。（口語や笑いの文化にももちろんフォーカスしているんですけど）<br />
      <br />
      <b>＜趣旨＞</b><br />
      ・日本語のバリエーションに触れる（直前に行ったトークポイントプロジェクト(<a
      href="http://www.talkpoint.org">www.talkpoint.org</a>)
      からの連動ーここでもスタイル、方
      言、性差などなど様々な側面について考察、学習していました）<br />
      ・関西方言を話す登場人物が出てくる映画を見る前の準備（このコースでは、一学期に２本程度映画をクラス外で学生に視聴させ、クラスでそれを使っ
      て様々な
      アクティビティをします）<br />
      ・関西方面に行く学生が多いので、その地方の方言の紹介<br />
      <br />
      <b>＜サイトの利用方法＞</b><br />
      １）理解のための一番基本的なルールのみを挙げたプリントを作成し（発音、代表的な語彙の違い、活用の違い）、その中で、私は関西弁ネイティブで
      はないの
      で、「発音」の部分で、音声ファイルがあるページを活用させてもらいました（以下の２ページ）。<br />
      <Link to={Page.Characteristics.LinguisticAspects.Sound}>Ch1.2.a Characteristics/LinguisticAspects/Sound</Link><br />
      <Link to={Page.Characteristics.LinguisticAspects.Accent}>Ch1.2.b Characteristics/LinguisticAspects/Accent</Link><br />
      ２）ルール紹介の後、映画の一部（関西弁の部分）やインタビュークリップの一部（<Link to={Page.InterviewClips}>Ch6 Interviews/Clips</Link>）
      を使って理解できるかどうかチャレンジさせます。<br />
      <br />
      <b>＜感想＞</b><br />
      ・趣旨に挙げた３つのことを満たせたと思う<br />
      ・関西弁の登場人物が出てくる映画を前準備なしで見させると理解が難しかったと思うが、この下準備で理解が比較的容易になったと思う<br />
      ・実際の関西人のクリップがあるのがよい。実際に関西に行ったときに聞く「生」の日本語に近いものに触れられる<br />
      ・学生は非常に楽しんでいた<br />
      <hr />
      <h3>畑中淳子先生 (テキサス大学オースティン校：２、３年生）</h3>
      <b>（１）３学期目の日本語クラス</b>（２年生の前半）<br />
      <br />
      教科書："Yookoso!"Continuing with Contemporary Japanese (『ようこそ』の２冊目）<br />
      <br />
      「ようこそ」の教科書では、時々『言語ノート』として日本語の言語学的な情報が英<br />
      語で紹介されています。第一章『旅行』の章の中には「方言」と題して、日本語の多<br />
      様性を述べるコラムがあり、その中に例として関西弁も少し紹介してあります。<br />
      <br />
      ３学期目のクラスでは、この『言語ノート』に触れる時、クラスでサイトを紹介し、ま<br />
      ずは、あいさつ（おはようございます、等）を先入観なしで標準語タイプと関西弁例の<br />
      ビデオを聞き比べさせ、どこか違うかを考えさせます。学生がまず気づくのがイント<br />
      ネーションの違いですが、それ以外にも最後の「す」の母音「u」が関西方言の方が強<br />
      い、など意外にきっちり聞き分けられるようです。<br />
      <br />
      その後、実際にこのサイトでの自習の仕方を紹介すべく、動詞の否定形（「〜へん」）<br />
      のドリルのやっています。例を聞かせた後、まずは練習問題にある動詞の関西弁での<br />
      否定形を皆でコーラスし、正解を聞いてみる。これを数問やってみた後で、時間があ<br />
      れば聞き取り問題の絵と音声とのマッチングなどをしてみます。<br />
      クラス内でサイトのURLは、勿論紹介しますが、授業終了後、学生への一斉メールでサ<br />
      イトのURLを送っています。授業内での時間は、練習問題に取る時間にもよりますが、<br />
      １５分から２０分程度です。<br />
      <br />
      <b>（２）６学期目の日本語クラス</b>（３年生の会話クラス）<br />
      <br />
      教科書：『中級の日本語』（The Japan Times)<br />
      <br />
      この教科書では第１３課に「日本語体験」という章があり、そこでは学習者にとっての<br />
      日本語学習の難しさとして発音や敬語、和製英語などにより学習者が日本で体験しう<br />
      るミスコミュニケーションを会話のエピソードとして紹介しています。<br />
      <br />
      この課で日本語の多様性の一例として「方言」の存在および「関西弁」を紹介してい<br />
      ます。２年生のクラスと同様、日本語の方言について概要を紹介し、その中から関西弁<br />
      サイトを見せて、あいさつのビデオを標準語と関西弁で比べさせ、違いや関西弁の特<br />
      徴を見つけさせます。そして、動詞や形容詞の活用練習と聞き取り練習を２、３やって<br />
      みます。ただ、このレベルでは関西弁の活用等を単独で理解するだけでなく、関西弁<br />
      の会話全体を聞いて、生きた日本語として理解してもらいたいので、アニメ『蛍の墓』<br />
      から数分を選んで、主人公らのセリフの一部を配布し、関西弁が強く現われた部分を<br />
      穴埋め問題にし、クラス内でディクテーションさせます。<br />
      老若男女の関西弁がコンテキストを持って登場するので、学生に感想を聞くと、全体<br />
      から来る関西弁独特のリズムなどに興味を持ったり、登場人物の特徴（善人か悪役か）<br />
      がわかりやすい、などの意見もありました。<br />
      こちらのクラスでの所要時間は、サイトでの聞き取り練習まで１０分程度、アニメを見<br />
      てディクテーションする部分でさらに１５分程度です。もちろんアニメを繰り返し見せ<br />
      たり、学生からのフィードバックや質問時間を取ると５０分の授業も可能です。<br />
      <br />
      （以下、URLを送った直後の学生からのメールです。）<br />
      ＊マジクール<br />
      ＊リンクありがとうございました。日本がアメリカより小さいのにたくさん方言があっ<br />
      てとても面白いと思います！<br />
      <br />
      <br />
    </>
  );
};

export default HowToUse;