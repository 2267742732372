import React from "react";
import { addVideoPlayer } from "common/util/generalUtil";

const LeeInKansaiben = () => {
  return (
    <>
      <h2>リーさんへのインタビュー Interview to Lee-san (in Kansai-ben)</h2>
      <table style={{textAlign: "center", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>
            Q: What do you think about male speakers of standard Japanese?
          </td>
          <td style={{verticalAlign: "top"}}>
            Q: What about female speakers of standard Japanese?
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addVideoPlayer("Lee.j1.mp4")}
          </td>
          <td style={{verticalAlign: "top"}}>
            {addVideoPlayer("Lee.j2.mp4")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            Q: Do you use Kansai-ben in Email/text message?
          </td>
          <td style={{verticalAlign: "top"}}>
            Q: Characteristices of Kansai people
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addVideoPlayer("Lee.j3.mp4")}
          </td>
          <td style={{verticalAlign: "top"}}>
            {addVideoPlayer("Lee.j4.mp4")}
          </td>
        </tr>
        </tbody>
      </table>
    </>
  );
};

export default LeeInKansaiben;