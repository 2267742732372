import { Page } from "home/page";
import React from 'react';
import ReactDOM from 'react-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import styled from 'styled-components';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import { Link } from "react-router-dom";
import createSagaMiddleware from 'redux-saga';
import mainReducer from './reducers';
import 'semantic-ui-css/semantic.min.css';
import {
  Container,
  Image,
  List,
  Segment
} from 'semantic-ui-react'
import Main from "./home/main";
import Navigation from "./components/navigation/navigation";
import { HashRouter } from "react-router-dom";
import watchAll from './sagas.js';
import { Constants } from './constants';

import './index.css';

// saga middleware
const sagaMiddleware = createSagaMiddleware();

// redux store with saga middleware
const store = createStore(mainReducer, applyMiddleware(sagaMiddleware));

// activate the saga(s)
sagaMiddleware.run(watchAll);

// fetch initial data
store.dispatch({ type: Constants.FETCH_INTERVIEWEES });
store.dispatch({ type: Constants.FETCH_INTERVIEW_QUESTION_COUNT });
store.dispatch({ type: Constants.FETCH_INTERVIEW_QUESTION_LIST });
store.dispatch({ type: Constants.FETCH_INTERVIEW_CLIP_COUNT });
store.dispatch({ 
  type: Constants.FETCH_INTERVIEW_CLIP_LIST,
  payload: {
    start: 0,
    n: Constants.INTERVIEW_CLIP_PAGE_SIZE
  }
});

const StyledSegment = styled.div`
  margin-left: 18rem;
  transition: margin-left 0.3s ease-in-out;
  @media (max-width: 1050px) and (min-width: 800px) {
    margin-left: ${({ open }) => open ? 'min(18rem, calc(100% - 400px))' : '0'};
    transition: margin-left 0.3s ease-in-out;
  }
  @media (max-width: 800px) {
    margin-left: 0;
    transition: margin-left 0.3s ease-in-out;
  }
`;

const LocationComponent = (props) => {
  const [hash, setHash] = React.useState(window.location.hash);
  React.useEffect(() => {
    const handler = () => {
      setHash((prev) => {
        const newHash = window.location.hash;
        if (prev !== newHash) {
          return newHash;
        }
        return prev;
      });
    };
    window.addEventListener("hashchange", handler);
    return () => {
      window.removeEventListener("hashchange", handler);
    };
  }, []);
  const basenames = hash.split('/').slice(1);
  let linkmap = [];
  let path = "";
  basenames.forEach((basename) => {
    path += "/" + basename;
    linkmap.push(<> / </>);
    linkmap.push(<Link to={path}>{basename}</Link>);
  });
  return <>
    Location: <Link to={Page.FirstRun}>Kansai Dialect Self-study</Link> {linkmap}
  </>
}

function App() {
  const state = {
    redirect : null
  };
  const [open, setOpen] = React.useState(false); 

  if (state.redirect) {
    return <redirect to={state.redirect}/>
  }
  return (
    <Provider store={store}>
      <HashRouter>
        <DndProvider backend={HTML5Backend}>
          <Navigation open={open} setOpen={setOpen}/>

          <div style={{ position: 'relative', minHeight: '100%' }}>
            <StyledSegment open={open}>
              <Container text style={{ paddingBottom: '8em'}}>
                <Image src='/testImageBanner.jpg' style={{paddingTop: '5px', paddingBottom: '5px'}}/>
                <LocationComponent/>
                <Main />
              </Container>
            </StyledSegment>
              
            <Segment inverted vertical style={{ margin: '5em 0em 0em', paddingBottom: '50px', position: 'absolute', bottom: '0', width: '100%', height: '5em'}}>
              <Container textAlign='right' style={{ marginRight: '10px' }}>
                <div className="ui grid" style={{textAlign: 'right'}}>
                  <div className="right floated" style={{marginLeft: 'auto', paddingTop: '0.5rem'}}>
                    <table>
                      <tbody>
                        <tr>
                          {/* Creates a warning that div cannot be child of <tr> */}
                          <List horizontal inverted divided link size='small'>
                            <List.Item as='a' href='#'>
                              Contact Us
                            </List.Item>
                            <List.Item as='a' href='#'>
                              Terms and Conditions
                            </List.Item>
                            <List.Item as='a' href='#'>
                              Privacy Policy
                            </List.Item>
                          </List>
                        </tr>
                        <tr>
                          ©2007-2021 Ikue Shingu. All Rights Reserved.
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="right floated column" style={{marginLeft: 0}}>
                    <Image src='/logo.png' />
                  </div>
                </div>
              </Container>
            </Segment>
          </div>
        </DndProvider>
      </HashRouter>
    </Provider>
  );
}

ReactDOM.render(<App />, document.getElementById('root'));
