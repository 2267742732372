import React from "react";
import { addRubyText, addAudio } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";

const L2 = () => {
  const qaObjects = [
    {
      superQuestion: <>
        (1) {addAudio("3.2.pl0.2.1.mp3")}
        <Hideable content={<>
          {addRubyText("会社","かいしゃ")}の{addRubyText("本","ほん")}は{addRubyText("持","も")}って{addRubyText("帰","かえ")}らんと{addRubyText("会社","かいしゃ")}に{addRubyText("置","お")}いてってや。
        </>} />
      </>
    },
    {
      question: <>You _____ take books home.</>,
      answersCorrect: [
        <>cannot</>
      ],
      answersIncorrect: [
        <>can</>
      ]
    },
    {
      superQuestion: <>
        (2) {addAudio("3.2.pl0.2.2.mp3")}
        <Hideable content={<>
          {addRubyText("電話","でんわ")}{addRubyText("入","い")}れんと{addRubyText("会社","かいしゃ")}{addRubyText("休","やす")}んだら、めっちゃ{addRubyText("怒","おこ")}おこられるし、{addRubyText("気","き")}い{addRubyText("付","つ")}けてや。
        </>} />
      </>
    },
    {
      question: <>You may have a day off _____.</>,
      answersCorrect: [
        <>if you make a call</>
      ],
      answersIncorrect: [
        <>without making a call</>
      ]
    },
    {
      superQuestion: <>
        (3) {addAudio("3.2.pl0.2.3.mp3")}
        <Hideable content={<>
          {addRubyText("僕","ぼく")}はコーヒーはミルク{addRubyText("入","い")}れんとブラックで{addRubyText("飲","の")}むねん。あ、けど{addRubyText("砂糖","さとう")}はスプーン{addRubyText("二杯","にはい")}ぐらい{addRubyText("入","い")}れてや。
        </>} />
      </>
    },
    {
      question: <>a) He drinks coffee _____ milk.</>,
      answersCorrect: [
        <>without</>
      ],
      answersIncorrect: [
        <>with</>
      ]
    },
    {
      question: <>b) He _____ sugar in his coffee.</>,
      answersCorrect: [
        <>wants</>
      ],
      answersIncorrect: [
        <>does not want</>
      ]
    },
    {
      superQuestion: <>
        (4) {addAudio("3.2.pl0.2.4.mp3")}
        <Hideable content={<>
          {addRubyText("仕事","しごと")}{addRubyText("終","お")}わったら{addRubyText("電気","でんき")}は{addRubyText("全部","ぜんぶ")}{addRubyText("消","け")}してもてな。あ、コンピュータは{addRubyText("消","け")}さんといてや。
        </>} />
      </>
    },
    {
      question: <>a) You should _____ after work.</>,
      answersCorrect: [
        <>turn the lights off</>
      ],
      answersIncorrect: [
        <>leave the lights on</>
      ]
    },
    {
      question: <>b) You should _____ after work.</>,
      answersCorrect: [
        <>leave computers on</>
      ],
      answersIncorrect: [
        <>turn computers off</>
      ] 
    }
  ]

  return <>
    <h2>{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}2</h2>
    Listen to the supervisor, and choose the statement that best describes
    what he says. Submit your answers after finishing all the questions.
    <br />
    <small>
      *Click "SHOW" to refer to the transcript
      <br />
      *Mouse over or click on the speaker icon for sound
      <br />
    </small>
    <br />
    <QandAForm qaObjects={qaObjects}/>
  </>;
}

export default L2;
