import React from "react";
import { addRubyText, addAudio } from "common/util/generalUtil";
import { DraggableForm } from "common/util/draggableForm";

const L3 = () => {

  const draggableFormData = {
    prompts: {
      texts: [
        <>{addRubyText("私","わたし")}もしなかったよ。</>,
        <>こっちには{addRubyText("来","き")}てないと{addRubyText("思","おも")}うけど。</>,
        <>{addRubyText("今日","きょう")}は{addRubyText("会","あ")}わなかった。</>,
        <>まだしまってないよ。</>,
        <>じゃ、{addRubyText("友達","ともだち")}と{addRubyText("行","い")}けば？</>,
        <>ううん、{addRubyText("今日","きょう")}は{addRubyText("使","つか")}ってない。</>,
        <>ええと、ないよ。</>,
        <>いや、{addRubyText("私","わたし")}、{addRubyText("吸","す")}わないんです。</>,
        <>あ、{addRubyText("買","か")}うの{addRubyText("忘","わす")}れてた！</>,
        <>あ、{addRubyText("捨","す")}てちゃったけど、、だめだった？</>,
        <>え？どうして{addRubyText("行","い")}かないの？</>,
        <>{addRubyText("知","し")}らない。</>,
        <>いや、{addRubyText("見","み")}てないよ。</>
      ]
    },
    answers: {
      audioFiles: [
        "3.1.a.pl0.3.7.mp3",
        "3.1.a.pl0.3.5.mp3",
        "3.1.a.pl0.3.1.mp3",
        "3.1.a.pl0.3.10.mp3",
        "3.1.a.pl0.3.8.mp3",
        "3.1.a.pl0.3.6.mp3",
        "3.1.a.pl0.3.2.mp3",
        "3.1.a.pl0.3.0.mp3",
        "3.1.a.pl0.3.4.mp3",
        "3.1.a.pl0.3.9.mp3",
        "3.1.a.pl0.3.3.mp3",
        "3.1.a.pl0.3.12.mp3",
        "3.1.a.pl0.3.11.mp3"
      ],
      texts: [
        <>{addRubyText("今日","きょう")}も{addRubyText("運動","うんどう")}せんかった〜。</>,
        <>{addRubyText("猫","ねこ")}、{addRubyText("来","け")}えへんかった？</>,
        <>ゆきちゃんに{addRubyText("会","お")}うた？</>,
        <>{addRubyText("写真","しゃしん")}しもた？</>,
        <>{addRubyText("外国","がいこく")}なんか{addRubyText("一人","ひとり")}でよう{addRubyText("行","い")}かん。</>,
        <>このパソコン、{addRubyText("使","つこ")}た？</>,
        <>その{addRubyText("辺","へん")}に、{addRubyText("鍵","かぎ")}あらへん？</>,
        <>{addRubyText("例","れい")}(example)タバコ{addRubyText("吸","す")}わへん？</>,
        <>ビール、{addRubyText("買","こ")}うた？</>,
        <>ここにあった{addRubyText("紙","かみ")}、ほかした？</>,
        <>{addRubyText("明日","あした")}は{addRubyText("仕事","しごと")}{addRubyText("行","い")}かへん。</>,
        <>お{addRubyText("砂糖","さとう")}、どこか{addRubyText("知","し")}らん？</>,
        <>{addRubyText("私","わたし")}のかばん、{addRubyText("見","み")}いひんた〜？</>
      ]
    }
  }

  return <>
    <h2>{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}3</h2>
    Listen to the audio and drag it to the most appropriate response in
    Standard Japanese (from the list <span style={{fontWeight: "bold"}}>a.</span>
    to <span style={{fontWeight: "bold"}}>m.)</span>. If you get a correct
    answer, you will hear "Right!!" and the audio will stick to the
    standard response. If it is wrong, the audio will bounce back to the
    original location.<br />
    <small>* Click "SHOW" if you
    need to refer to the transcript<br />
    * Move the mouse over the speaker icon for sound</small><br />
    <br />
    {addRubyText("例","れい")}(example)：タバコ{addRubyText("吸","す")}わへん？{addAudio("3.1.a.pl0.3.0.mp3")}---&gt;Ans. h.
    いや、{addRubyText("私","わたし")}、{addRubyText("吸","す")}わないんです。<br />
    &nbsp;&nbsp;&nbsp; <br />
    <br />

    <DraggableForm prompts={draggableFormData.prompts} answers={draggableFormData.answers} />
    
  </>;
}

export default L3;
