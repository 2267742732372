import React from "react";
import { addRubyText, addAudio } from "common/util/generalUtil";

const TaForm = () => {
  return <>
    <h2>た form (Past Affirmative Form)</h2>
    Kansai たform takes mostly the same form as the Standard (of course the
    pitch pattern is different) EXCEPT the たform of うVerbs that end with う
    , which experience an euphonic change.<small><br />
    *<span style={{fontWeight: "bold"}}>BOLD</span> type indicates
    high pitch<br />
    *Mouse over or click on the speaker icon for sound</small><br />
    <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>
            <br />
          </td>
          <td colSpan="3" rowSpan="1" style={{verticalAlign: "top", textAlign: "center"}}>
            Kansai<br />
          </td>
          <td colSpan="1" rowSpan="2" style={{verticalAlign: "top", textAlign: "center"}}>
            Standard<br />(audio)<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Dictionary form<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Past affirmative<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            (audio)<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            る Verb<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            {addRubyText("食","た")}べる<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            た<span style={{fontWeight: "bold"}}>べ</span>た<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            {addAudio("3.1.a.3.1.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            {addAudio("3.1.a.3.1.s.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            <br />
          </td>
          <td style={{verticalAlign: "top"}}>
            {addRubyText("見","み")}る<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <span style={{fontWeight: "bold"}}>み</span>た<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            {addAudio("3.1.a.3.2.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            {addAudio("3.1.a.3.2.s.mp3")}</td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            う Verb<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            ある<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            あっ<span style={{fontWeight: "bold"}}>た</span><br />
          </td>
          <td style={{verticalAlign: "top"}}>
            {addAudio("3.1.a.3.3.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            {addAudio("3.1.a.3.3.s.mp3")}</td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            <br />
          </td>
          <td style={{verticalAlign: "top"}}>
            {addRubyText("飲","の")}む<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            のん<span style={{fontWeight: "bold"}}>だ</span><br />
          </td>
          <td style={{verticalAlign: "top"}}>
            {addAudio("3.1.a.3.4.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            {addAudio("3.1.a.3.4.s.mp3")}</td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            <br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <span style={{fontWeight: "bold"}}>
              {addRubyText("行","い")}く
            </span>
            <br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <span style={{fontWeight: "bold"}}>い</span>った<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            {addAudio("3.1.a.3.5.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            {addAudio("3.1.a.3.5.s.mp3")}</td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            <br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <span style={{fontWeight: "bold"}}>{addRubyText("買","か")}う</span><br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <span style={{fontWeight: "bold"}}>こ</span>うた (ST かった）<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            {addAudio("3.1.a.3.6.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            {addAudio("3.1.a.3.6.s.mp3")}</td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            <br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <span style={{fontWeight: "bold"}}>{addRubyText("吸","す")}う</span>(smoke)<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <span style={{fontWeight: "bold"}}>す</span>うた (ST すった）<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            {addAudio("3.1.a.3.7.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            {addAudio("3.1.a.3.7.s.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            <br />
          </td>
          <td style={{verticalAlign: "top"}}>
            {addRubyText("酔","よ")}う(get drunk)<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            よう<span style={{fontWeight: "bold"}}>た </span>(ST よった）<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            {addAudio("3.1.a.3.8.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            {addAudio("3.1.a.3.8.s.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            <br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <span style={{fontWeight: "bold"}}>{addRubyText("言","い")}う</span>／<span style={{fontWeight: "bold"}}>{addRubyText("言","ゆ")}う</span>
            <br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <span style={{fontWeight: "bold"}}>ゆ</span>うた (ST いった）<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            {addAudio("3.1.a.3.9.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            {addAudio("3.1.a.3.9.s.mp3")}</td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            <br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <span style={{fontWeight: "bold"}}>もらう</span><br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <span style={{fontWeight: "bold"}}>も</span>ろ（う）た／<span style={{fontWeight: "bold"}}>も</span>うた<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            {addAudio("3.1.a.3.10.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            {addAudio("3.1.a.3.10.s.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            Irregular Verb<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <span style={{fontWeight: "bold"}}>する</span><br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <span style={{fontWeight: "bold"}}>し</span>た<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            {addAudio("3.1.a.3.11.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            {addAudio("3.1.a.3.11.s.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            <br />
          </td>
          <td style={{verticalAlign: "top"}}>
            {addRubyText("来","く")}る<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <span style={{fontWeight: "bold"}}>き</span>た<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            {addAudio("3.1.a.3.12.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            {addAudio("3.1.a.3.12.s.mp3")}</td>
        </tr>
      </tbody>
    </table>
    <br />
    To sum up, if the うverb ending with う is…<br />
    <ol>
      <li>
        &nbsp;-a-う, then the たform is –o-うた (e.g. <span style={{fontWeight: "bold"}}>かう</span>→
        <span style={{fontWeight: "bold"}}>こ</span>うた)
      </li>
      <li>
        &nbsp;-o-う, then the たform is –o-うた (e.g. よう→よう<span style={{fontWeight: "bold"}}>た</span>)
      </li>
      <li>
        &nbsp;-u-う, then the たform is –u-うた (e.g. <span style={{fontWeight: "bold"}}>すう</span>→
        <span style={{fontWeight: "bold"}}>す</span>うた)
      </li>
    </ol>
    <hr />
    <h3>Extra 1:&nbsp; ゆうた &amp; いった &nbsp;<br /></h3>
    言うis usually pronounced ゆうin Kansai, therefore, its たform is ゆうた, When
    you hear somebody say "いった" in Kansai-ben, it is the たform of {addRubyText("要","い")}る
    (to need) or 行く. However, when it comes to the Standard, it could
    be the たform of 言う, 行くor 要る.
    <br />
    <br />
    <hr />
    <h3>Extra 2: もろうたVS もろた <br /></h3>
    If the うVerb ending with う consists of three moras, then the shortened
    たform seems more commonly used like the following;
    <br />
    <br />
    <span style={{fontWeight: "bold"}}>もらう</span>　→　
    <span style={{fontWeight: "bold"}}>も</span>ろうた／
    <span style={{fontWeight: "bold"}}>も</span>ろた（<span style={{fontWeight: "bold"}}>も</span>うた）
    <br />
    <span style={{fontWeight: "bold"}}>{addRubyText("使","つか")}う</span>　→　
    <span style={{fontWeight: "bold"}}>つ</span>こうた／<span style={{fontWeight: "bold"}}>つ</span>こた
    <br />
    <br />
    A: ノート、{addRubyText("買","こ")}うた？<br />
    B: ううん、{addRubyText("友達","ともだち")}に<span style={{textDecoration: "underline"}}>もろた</span>し、
    買えへんかった ／買わへんかった。{addAudio("3.1.a.3.13.mp3")}<br />
    <small>
      (ST ノート、買った？ー＞ううん、友達にもらったから、買わなかった。）{addAudio("3.1.a.3.13.s.mp3")}
    </small>
  </>;
}

export default TaForm;