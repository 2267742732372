import React from "react";
import { addRubyText, addAudio } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";

const P3 = () => {
  const standardTranscript1 = <>
    <ol className="transcript">
      <li>A: 美香ちゃん、ずるいね。</li>
      <li>B: うん、ずるくてかなわないね。</li>
    </ol>
  </>;

  const standardTranscript2 = <>
    <ol className="transcript">
      <li>A: この番組、面白いねえ。</li>
      <li>B:&nbsp; うん、面白くていいね。</li>
    </ol>
  </>;

  return <>
    <h2>{addRubyText("練習","れんしゅう")}3 : Adjective て form</h2>
    Form a question and an answer for each cue according to
    a model. You may listen to the question, tryto answer it, and check
    your answer. *Answers vary; the recorded answers indicate those
    commonly
    used.<br />
    <small>*Mouse over or click on the speaker icon for sound</small><br />
    <br />
    Decide whether you should follow model 1 or 2 depending on each cue.<br />
    <br />
    <span style={{fontWeight: "bold"}}>Model 1 </span>(Cue:{addRubyText("美香","みか")}ちゃん／ずっこい
    "sly")<br />
    &nbsp;&nbsp;&nbsp; A: 美香ちゃん、ずっこいなあ。<br />
    &nbsp;&nbsp;&nbsp; B: うん、ずっこうて<span style={{textDecoration: "underline"}}>か
    なんな</span>。{addAudio("3.4.p.3.1.mp3")}<br />
    <br />
    ST transcript
    <br />
    <Hideable content={standardTranscript1} />
    <span style={{fontWeight: "bold"}}>Model 2 </span>(Cue:
    この{addRubyText("番組","ばんぐみ")}／{addRubyText("面白","おもしろ")}い)<br />
    &nbsp;&nbsp;&nbsp; A: この番組、面白いなあ。<br />
    &nbsp;&nbsp;&nbsp; B: うん、面白て<span style={{textDecoration: "underline"}}>ええ
    な</span>。{addAudio("3.4.p.3.2.mp3")}<br />
    <br />
    ST transcript 
    <Hideable content={standardTranscript2} />
    <br />
    
    Point: no need to add く in Kansai てform. A sound change may occur when
    the adj ends with -A-い/-I-い.(refer to Grammar 4)<br />
    <br />
    <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>Cue<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>A (Question)<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>B (Answer)<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>{addRubyText("美香","みか")}ちゃん／ずっこい<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.4.p.3.1.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.4.p.3.1.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>この{addRubyText("番組","ばんぐみ")}／{addRubyText("面白","おもしろ")}い</td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.4.p.3.2.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.4.p.3.2.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>この{addRubyText("頃","ごろ")}／{addRubyText("寒","さむ")}い<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.4.p.3.3.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.4.p.3.3.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>ここ／{addRubyText("暖","あった")}かい<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.4.p.3.4.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.4.p.3.4.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>はよご{addRubyText("飯","はん")}／{addRubyText("食","た")}べたい</td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.4.p.3.5.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.4.p.3.5.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>このはさみ (scissors)／{addRubyText("使","つか")}いにくい</td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.4.p.3.6.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.4.p.3.6.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>あの{addRubyText("子","こ")}／せこい<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.4.p.3.7.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.4.p.3.7.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>この{addRubyText("肉","にく")}／{addRubyText("軟","や")}らかい</td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.4.p.3.8.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.4.p.3.8.b.mp3")}<br />
          </td>
        </tr>
      </tbody>
    </table>
  </>;
}

export default P3;
