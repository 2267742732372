import React from "react";
import { addRubyText, addAudio } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";

const P8 = () => {
  const transcript = <>
    <ol className="transcript">
      <li>A: 田中さんっていう人、知ってる？ </li>
      <li>B: 田中さん？そんな人は知らないねえ。</li>
    </ol>
  </>;
  return <>                                                               
    <h2>{addRubyText("練習","れんしゅう")}8: XいうY</h2>
    Form a question and an answer for each cue according to
    a model. You may listen to the question,
    try to answer it, and check your answer. *Answers vary; the recorded
    answers indicate those commonly used.
    <br />
    <small>*Move the mouse over the icon for sound</small>
    <br />
    <br />
    <span style={{fontWeight: "bold"}}>Model</span> (Cue: {addRubyText("田中","たなか")}さん／{addRubyText("人","ひと")})
    <br />
    &nbsp;&nbsp;&nbsp; A: 田中さんちゅう人{addRubyText("知","し")}ってる？ 
    <br />
    &nbsp;&nbsp;&nbsp; B: 田中さん？そんな人知らんなあ。{addAudio("3.1.b.p.8.mp3")}
    <br />
    <br />
    Point: XというYー＞Xちゅう（いう／ゆう）Y (refer to "Notes on Quotation Markers")
    <br />
    <br />
    ST transcript 
    <br />
    <Hideable content={transcript} />
    <br />
    <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Cue<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            A (Question)<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            B (Answer)<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addRubyText("田中","たなか")}さん／{addRubyText("人","ひと")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.1.b.p.8.1.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.1.b.p.8.1.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addRubyText("洋子","ようこ")}ちゃん／{addRubyText("女","おんな")}の{addRubyText("子","こ")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.1.b.p.8.2.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.1.b.p.8.2.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            ボルボ／{addRubyText("車","くるま")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.1.b.p.8.3.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.1.b.p.8.3.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addRubyText("タイム")}／{addRubyText("雑誌","ざっし")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.1.b.p.8.4.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.1.b.p.8.4.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            キーウエスト／{addRubyText("所","ところ")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.1.b.p.8.5.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.1.b.p.8.5.b.mp3")}<br />
          </td>
        </tr>
      </tbody>
    </table>
  </>;
}

export default P8;
