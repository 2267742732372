import React from "react";
import { addRubyText, addAudio } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
const P2 = () => {
  const transcript = <>
    <ol>
      <li>A: これ、食べてもいい？</li>
      <li>B: ああ、食べないで。</li>
    </ol>
  </>;

  return <>
    <h2>{addRubyText("練習","れんしゅう")}2: Negative request – Pattern Practice</h2>
    Form a question (asking a permission by statement) and an answer
    (negative request) for each cue according to the model.&nbsp; You may
    listen to the question, try to answer it, and then check your answer.
    *Answers vary: the recorded answers indicate those commonly used.<br />
    <small>*Move the mouse over to the speaker icon for sound<br />
    <br />
    </small><span style={{fontWeight: "bold"}}>
    Model</span> (Cue: これ／{addRubyText("食","た")}べる） ) <br />
    &nbsp;&nbsp;&nbsp; A: これ、食べてもかまへん／ええ？<br />
    &nbsp;&nbsp;&nbsp; B: ああ、食べんといて。{addAudio("4.1.p.2.mp3")}<br />
    <br />
    ST transcript 
    <br />
    <Hideable content={transcript} />
    <table style={{textAlign: "left", width: "100%"}} border="1"cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>Cue<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>A (Question)<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>B (Answer)<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>{addRubyText("窓","まど")}／{addRubyText("開","あ")}ける<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("4.1.p.2.1.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("4.1.p.2.1.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>たばこ／{addRubyText("吸","す")}う </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("4.1.p.2.2.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("4.1.p.2.2.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>テレビ／つける </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("4.1.p.2.3.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("4.1.p.2.3.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>ペンで／{addRubyText("書","か")}く </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("4.1.p.2.4.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("4.1.p.2.4.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>この{addRubyText("部屋","へや")}／そうじする</td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("4.1.p.2.5.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("4.1.p.2.5.b.mp3")}<br />
          </td>
        </tr>
      </tbody>
    </table>
  </>;
}

export default P2;
