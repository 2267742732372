import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";

const V4 = () => {
  const transcript = <>
    <span style={{textDecoration: "underline"}}>{addRubyText("勉強","べんきょう")}せえ</span>
    (Grammar 1)、とは{addRubyText("言","い")}われましたけども、はい、そんながーがーがーがー言われませんでしたわ。
  </>;

  const qaObjects = [
    {
      question: <span>Q: How did his parents used to scold him?</span>,
      answersCorrect: [
        <>They used to tell him to study hard but not that persistent.</>
      ],
      answersIncorrect: [
        <>They used to tell him to study hard persistently.</>,
        <>They used to tell him not to study hard persistently.</>,
        <>They used to tell him not to study hard, but not that persistently.</>
      ]
    }
  ]

  return <>
    <h2>ビデオ{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}４：Authentic – short </h2>
    Watch the video clip and choose the answer that matches the content of
    the clip. <br />
    <small>*Click "SHOW" if you need to refer to the transcript.</small>
    <br />
    Ohtsuji-san was asked how his parents used to scold him when he was a
    child.<br />
    <table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>{addVideoPlayer("4.3.1.p.5.ohtsuji.mp4")} </td>
          <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>Transcript
            <Hideable content={transcript}/>
            <br />
            ＊ガーガー{addRubyText("言","い")}う say loud and
            persistent ( like a quack)<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>オオツジさん
    (Shiga)</td>
        </tr>
      </tbody>
    </table>
    <QandAForm qaObjects={qaObjects}/>
  </>;
}

export default V4;
