import React from "react";
import { addRubyText, addAudio } from "common/util/generalUtil";

const Prohibition = () => {
  return <>
<h2>1. 〜たらいかん／あかん (Prohibition)</h2>
<h3><span style={{fontWeight: "bold"}}></span><span
 style={{fontWeight: "bold"}}>Summary of expressions of prohibition and
related expressions</span></h3>
<span style={{fontWeight: "bold"}}>
</span>
<table style={{textAlign: "left", width: "100%"}} border="1"cellPadding="2"
 cellSpacing="2">
  <tbody>
    <tr>
      <td style={{verticalAlign: "top"}}><br />
      </td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>Standard<br />
      </td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>Kansai<br />
      </td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>Kansai
(Polite)<br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>Prohibition<br />
“You must not…”<br />
      </td>
      <td style={{verticalAlign: "top"}}>〜ては／ちゃいけない／だめだ<br />
      </td>
      <td style={{verticalAlign: "top"}}>〜た（ら）いかん／あかん<br />
      </td>
      <td style={{verticalAlign: "top"}}>〜た（ら）いけません／あきません／あきまへん／あかしまへん<br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>Negative request<br />
“Please don’t…”<br />
      </td>
      <td style={{verticalAlign: "top"}}>〜ないで<br />
      </td>
      <td style={{verticalAlign: "top"}}>~んといて<br />
      </td>
      <td style={{verticalAlign: "top"}}>〜んといてください<br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>Negative Command (Gentle)<br />
“Don’t…!”<br />
      </td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>-<br />
      </td>
      <td style={{verticalAlign: "top"}}>V (short negative)と<br />
(refer to C4.2.2)<br />
      </td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>-<br />
      </td>
    </tr>
  </tbody>
</table>
<br />
Prohibition is expressed by the combination of たら conditional and
あかん／いかん “No good/It doesn’t go that way” in Kansai.<br />
<br />
Vては／ちゃ いけない／だめだ (Standard) → Vたらいかん／あかん<br />
{addRubyText("食","た")}べたらあかん、{addRubyText("買","こ")}うたらあかん、したらいかん、{addRubyText("来","き")}たらあかん {addAudio("4.1.1.1.mp3")}<br />
<br />
ご{addRubyText("飯","はん")}食べながらテレビ{addRubyText("見","み")}たらあかんよ。 {addAudio("4.1.1.2.mp3")}<br />
<small>(ST ご飯食べながらテレビを見ちゃだめだよ。)</small><small> </small><small>{addAudio("4.1.1.2.s.mp3")}</small><br />
<br />
It is common thatら in 〜たらあかん／いかん is dropped.<br />
見たいかん、しゃべったあかん、したあかん、来たいかん {addAudio("4.1.1.3.mp3")} <br />
<br />
ああ、{addRubyText("危","あぶ")}ないし、そんなん{addRubyText("触","さわ")}ったいかんがな。{addAudio("4.1.1.4.mp3")}<br />
<small>(ST ああ、危ないから、そんなの触っちゃいけないよ！)</small><small>　</small><small>{addAudio("4.1.1.4.s.mp3")}</small><br />
<small><br />
* {addRubyText("触","さわ")}る touch</small><br />
<br />
<hr />
<h3>Extra 1: Polite prohibition</h3>
When mothers scold their children, there are times when they use polite
prohibition.<br />
<br />
{addRubyText("行儀悪","ぎょうぎわる")}いし、{addRubyText("手","て")}で食べたあきません！{addAudio("4.1.1.5.mp3")}<br />
“It is ill-mannered, so you must not eat with your hands!” <br />
<small>(ST 行儀が悪いから、手で食べちゃいけません！）</small><small>{addAudio("4.1.1.5.s.mp3")}</small><br />
<br />
You might hear this type of polite prohibition when someone explains
the rules of a house, apartments, a work place etc. Elderly people
still tend to use traditional form of polite prohibition such as
〜たらあきまへん／あかしまへん.<br />
<br />
<hr />
<h3>Extra 2: Prohibition and Negative request</h3>
Since 〜たらあかん／いかん expressions indicate strong, definite prohibition, it
may not be appropriate to use them when talking gently or requesting
not do something for your own sake. On these occasions, the negative
request 〜んといて（polite 〜んといてください) should be used instead. (For more
information about negative request, refer to Grammar 5 in Ch3 Lesson 2.<br />
<br />
(At the home-stay)<br />
{addRubyText("靴","くつ")}{addRubyText("履","は")}いたまま、{addRubyText("家","うち")}に{addRubyText("上","あ")}がらんといてね。（＜上がったらあかんよ）{addAudio("4.1.1.6.mp3")}<br />
<small>(ST 靴を履いたまま、家に上がらないでね。)</small><small>{addAudio("4.1.1.6.s.mp3")}</small><br />
<br />
(At the work place)<br />
{addRubyText("朝礼","ちょうれい")}は{addRubyText("結構","けっこう")}{addRubyText("大事","だいじ")}な{addRubyText("話","はなし")}があるんで、{addRubyText("遅","おく")}れんといてくださいよ。（＜{addRubyText("遅","おく")}れたらあき
ませんよ。） {addAudio("4.1.1.7.mp3")}<br />
<small>(ST 朝礼は結構大事な話があるので、遅れないでくださいよ。)</small><small>{addAudio("4.1.1.7.s.mp3")}</small><br />
<small><br />
*朝礼 morning gathering ( at schools, and work place)</small><br />
<br />
<hr />
<h3>Extra 3:&nbsp; Gentle Negative Command 〜んと！</h3>
Kansai parents use V(negative short form)+と as negative commands as
commonly as negative requests 〜んといて and expressions of
prohibition〜たらあかん／いかん to discipline their children. Please pay
attention to the accent pattern.<br />
<br />
{addRubyText("邪魔","じゃま")}やし、そんなとこ{addRubyText("寝転","ねころ")}ばんと！（＞ {addRubyText("寝転","ねころ")}ばんといて） {addAudio("4.1.1.8.mp3")}<br />
<small>(ST 邪魔だからそんなところに寝転んじゃだめ／寝転ぶな！)</small><small>{addAudio("4.1.1.8.s.mp3")}</small><br />
<br />
Not only for scolding children, this pattern is also used as a gentle
negative command when emphasizing negative requests.<br />
<br />
みんな{addRubyText("真面目","まじめ")}に{addRubyText("考","かんが")}えてる{addRubyText("時","とき")}に、そんなしょーもない{addRubyText("洒落","しゃれ")}{addRubyText("言","い")}わんと。（＞{addRubyText("言","い")}わんといて）
{addAudio("4.1.1.9.mp3")}<br />
<small>(ST みんな真面目に考えている時に、そんなつまらないジョーク言わないで。)</small><small>{addAudio("4.1.1.9.s.mp3")}</small><br />
<br />
<small>* しょーもない(Kansai)=つまらない</small><br />
<br />
However, if this negative とconditional form takes a different accentual
pattern it could indicate the complete opposite, that is, a regular
command! Refer to Lesson 2 in Ch4.<br />
  </>;
}

export default Prohibition;
