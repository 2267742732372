import React from "react";
import { Link } from "react-router-dom";
import { addImage } from "common/util/generalUtil";
import { Page } from "home/page";

const LinguisticAspects = () => {
  return (
    <>
      <h2>Linguistic Aspects of Kansai Dialect</h2>
      <table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td colSpan="1" rowSpan="1" style={{verticalAlign: "top"}}>
            <ol>
              <ol type="a">
                <li>
                  <Link to={Page.Characteristics.LinguisticAspects.Sound}>Sound</Link>
                </li>
                <li>
                  <Link to={Page.Characteristics.LinguisticAspects.Accent}>Accent</Link>
                </li>
                <li>
                  <Link to={Page.Characteristics.LinguisticAspects.Words}>Words</Link>
                </li>
                <li>
                  <Link to={Page.Characteristics.LinguisticAspects.Conjugation}>Conjugation</Link>
                </li>
                <li>
                  <Link to={Page.Characteristics.LinguisticAspects.Particles}>Particles</Link>
                </li>
                <li>
                  <Link to={Page.Characteristics.LinguisticAspects.SpeechStyle}>Speech Style</Link>
                </li>
                <li>
                  <Link to={Page.Characteristics.LinguisticAspects.Honorifics}>Honorifics</Link>
                </li>
              </ol>
            </ol>
          </td>
          <td colSpan="1" rowSpan="1" style={{verticalAlign: "top", textAlign: "center"}}>
            {addImage("tsutenkaku.jpg")}<br />
            <small>通天閣 Tsutenkaku Tower (Osaka)</small><br />
          </td>
        </tr>
        </tbody>
      </table>
    </>
  );
};

export default LinguisticAspects;