import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";

const V16 = () => {
  const transcript = <>
    {addRubyText("行儀","ぎょうぎ")}{addRubyText("悪","わる")}かったとかあ，{addRubyText("特","とく")}にほらこう、なんて{addRubyText("言","ゆ")}うの、{addRubyText("人","ひと")}に{addRubyText("対","たい")}して{addRubyText("挨拶","あいさつ")}とか、<span
    style={{textDecoration: "underline"}}>
    しなあかん</span> (Grammar 3)
    よお、とか言うし，こう{addRubyText("一応","いちおう")}、{addRubyText("常識","じょうしき")}は{addRubyText("身","み")}に{addRubyText("付","つ")}けといてほしいから、こう言う{addRubyText("時","とき")}はごめんなさいね
    え、とか、{addRubyText("前","まえ")}{addRubyText("通","とお")}る時は前ごめんなさいねえ、とか、ちょっと{addRubyText("貸","か")}してなあ、とかっていうのはちゃんとは言うてるよ。そんで、ちゃ
    んと{addRubyText("出会","であ")}ったらこんにちは、とか、おはようございます、とか、ちゃんと<span
    style={{textDecoration: "underline"}}>言わなあかん</span>(Grammar 3) よ、とは言うてる。
  </>;

  const qaObjects = [
    {
      question: <span>Q1: What does she tell her children? Choose all that apply.</span>,
      answersCorrect: [
        <>Greet others appropriately.</>,
        <>Apologize when you have to.</>,
        <>Say “Excuse me” when passing in front of someone.</>,
        <>Say “Please lend it to me” when you want to borrow something.</>
      ],
      answersIncorrect: [
        <>Don’t’ talk with strangers.</>,
        <>Don’t easily apologize when you know you are right.</>,
        <>Say “please give it to me” when you want others’ belongings.</>
      ]
    },
    {
      question: <span>Q2: Why does she tell such things to her children?</span>,
      answersCorrect: [
        <>She wants her children to have common sense.</>
      ],
      answersIncorrect: [
        <>She wants her children to acquire high survival skills.</>,
        <>She wants her children to have a better future.</>
      ]
    }
  ];

  return <>
    <h2>ビデオ{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}１６: Authentic - medium<br />
    </h2>
    Watch the video clip and choose the answer that matches the content of
    the clip. Submit your answers after answering all the questions.<br />
    <small>*Click "SHOW" if you need to refer to the transcript.</small><br />
    <br />
    Sachie-san was talking about how she disciplines her children.<br />
    <table style={{textAlign: "left", width: "100%"}} border="0"cellPadding="2"
    cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>{addVideoPlayer("4.2.3.p.4.sachie.mp4")}</td>
          <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>Transcript
    <Hideable content={transcript}/>
    <br />
    ＊{addRubyText("行儀","ぎょうぎ")} behavior, manners <br />
    ＊あいさつ greetings <br />
    ＊{addRubyText("常識","じょうしき")} common sense <br />
    ＊{addRubyText("身","み")}に{addRubyText("付","つ")}ける acquire
    (skills)</td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>サチエさん (Shiga)</td>
        </tr>
      </tbody>
    </table>
    <br />
    <QandAForm qaObjects={qaObjects}/>
  </>;
}

export default V16;
