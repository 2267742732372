import React from "react";
import { Link } from "react-router-dom";
import { Page } from "home/page";
import { addRubyText, addImage } from "common/util/generalUtil";

const Video = () => {
  return <>
    <h2>ビデオ{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}(Video Comprehension Practice)</h2>
    <table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>
          <ul>
            <li>
              <Link to={Page.FunctionalGrammar.Imperatives.Video.V1}>
                ビデオ聞き取り練習１
              </Link>
            </li>
            <li>
              <Link to={Page.FunctionalGrammar.Imperatives.Video.V2}>
                ビデオ聞き取り練習２： Authentic – short
              </Link>
            </li>
            <li>
              <Link to={Page.FunctionalGrammar.Imperatives.Video.V3}>
                ビデオ聞き取り練習３： Authentic – short
              </Link>
            </li>
            <li>
              <Link to={Page.FunctionalGrammar.Imperatives.Video.V4}>
                ビデオ聞き取り練習４： Authentic – short
              </Link>
            </li>
            <li>
              <Link to={Page.FunctionalGrammar.Imperatives.Video.V5}>
                ビデオ聞き取り練習５： Authentic – short
              </Link>
            </li>
            <li>
              <Link to={Page.FunctionalGrammar.Imperatives.Video.V6}>
                ビデオ聞き取り練習６： Authentic – short
              </Link>
            </li>
            <li>
              <Link to={Page.FunctionalGrammar.Imperatives.Video.V7}>
                ビデオ聞き取り練習７： Authentic – short
              </Link>
            </li>
            <li>
              <Link to={Page.FunctionalGrammar.Imperatives.Video.V8}>
                ビデオ聞き取り練習８： Authentic – short
              </Link>
            </li>
          </ul>
          </td>
          <td style={{verticalAlign: "top"}}>
          <div style={{textAlign: "center"}}>{addImage("geta2.jpg")}<br />
          </div>
          <div style={{textAlign: "center"}}><small>下駄 <span style={{fontStyle: "italic"}}>Geta</span>: Wooden clogs (Kionsaki, Hyogo)</small><br />
          </div>
          </td>
        </tr>
      </tbody>
    </table>
  </>;
}

export default Video;
