import React from "react";
import { Link } from "react-router-dom";
import { addRubyText, addAudio } from "common/util/generalUtil";
import { Page } from "home/page";

const KansaiAdjectives = () => {
  return <>
    <h2>1. Kansai Adjectives <br />
    </h2>
    In casual conversation in the Kansai dialect, you often hear
    adjectives without い. Kansai people, when describing things with
    astonishment, tend to drop the last い in いadjective (adjectival) and
    lengthen the preceding vowel.
    <br />
    * な adjective conjugation is introduced under <Link to={Page.BasicGrammar.Copula.Copula}> 
    Lesson 3. Copula</Link> in Chapter 3.<br />
    <br />
    <small>*Mouse over or click on the speaker icon for sound</small><br />
    この{addRubyText("番組","ばんぐみ")}、<span style={{textDecoration: "underline"}}>ふる〜</span>。<small>
    </small>{addAudio("3.4.1.1.mp3")}<br />
    <small>（ST
    この番組、{addRubyText("古","ふる")}いね〜。）</small><small>{addAudio("3.4.1.1.s.mp3")}</small><br />
    <br />
    あいつ、{addRubyText("足","あし")}、<span style={{textDecoration: "underline"}}>はや〜</span>。<small>
    </small>{addAudio("3.4.1.2.mp3")}<br />
    <small>（ST
    あいつ、足{addRubyText("速","はや")}いね〜。）</small><small>{addAudio("3.4.1.2.s.mp3")}</small><br />
    <br />
    {addRubyText("何","なに")}？この{addRubyText("本","ほん")}。<span style={{textDecoration: "underline"}}>しょうもな〜</span>。<small>
    </small>{addAudio("3.4.1.3.mp3")}<br />
    <small>（ST 何？この本。つまんない〜。）</small><small>{addAudio("3.4.1.3.s.mp3")}</small><br />
    <br />
    Another thing we should note before going on to the conjugation
    is that there are some adjectives that are used mainly in the Kansai
    area. Here are some examples: {addAudio("3.4.1.4.mp3")}
    <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>Kansai <br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>Standard<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>Kansai<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>Standard<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>ええ<br />
          </td>
          <td style={{verticalAlign: "top"}}>いい<br />
          </td>
          <td style={{verticalAlign: "top"}}>ややこしい</td>
          <td style={{verticalAlign: "top"}}>{addRubyText("複雑","ふくざつ")}な　(complicated)</td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>おもろい<br />
          </td>
          <td style={{verticalAlign: "top"}}>{addRubyText("面白","おもしろ")}い</td>
          <td style={{verticalAlign: "top"}}>めんどくさい</td>
          <td style={{verticalAlign: "top"}}>{addRubyText("面倒","めんどう")}な（troublesome)</td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>ちっちゃい<br />
          </td>
          <td style={{verticalAlign: "top"}}>{addRubyText("小","ちい")}さい </td>
          <td style={{verticalAlign: "top"}}>{addRubyText("鈍","どん")}くさい</td>
          <td style={{verticalAlign: "top"}}> とろい　(slow)</td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>ずっこい／すこい<br />
          </td>
          <td style={{verticalAlign: "top"}}>ずるい (sly)<br />
          </td>
          <td style={{verticalAlign: "top"}}>ばばっちい</td>
          <td style={{verticalAlign: "top"}}>{addRubyText("汚","きた")}ない</td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>あほくさい<br />
          </td>
          <td style={{verticalAlign: "top"}}>ばからしい<br />
          </td>
          <td style={{verticalAlign: "top"}}>{addRubyText("忙","せわ")}しない</td>
          <td style={{verticalAlign: "top"}}>{addRubyText("落","お")}ち{addRubyText("着","つ")}かない(busy)</td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>しょうもない<br />
          </td>
          <td style={{verticalAlign: "top"}}>つまらない<br />
          </td>
          <td style={{verticalAlign: "top"}}>ごっつい</td>
          <td style={{verticalAlign: "top"}}>{addRubyText("大","おお")}きい、すごい</td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>しんどい<br />
          </td>
          <td style={{verticalAlign: "top"}}>{addRubyText("疲","つか")}れた<br />
          </td>
          <td style={{verticalAlign: "top"}}>みみっちい／せこい</td>
          <td style={{verticalAlign: "top"}}>けち　(tight in money)</td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>さぶい<br />
          </td>
          <td style={{verticalAlign: "top"}}>{addRubyText("寒","さむ")}い</td>
          <td style={{verticalAlign: "top"}}>かいらしい</td>
          <td style={{verticalAlign: "top"}}>かわいい </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>しんきくさい<br />
          </td>
          <td style={{verticalAlign: "top"}}>いらいらする (irritated)<br />
          </td>
          <td style={{verticalAlign: "top"}}>かしこい</td>
          <td style={{verticalAlign: "top"}}>しっかりした、{addRubyText("頭","あたま")}がいい</td>
        </tr>
      </tbody>
    </table>
  </>;
}

export default KansaiAdjectives;
