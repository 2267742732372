import React from "react";
import { Link } from "react-router-dom";
import { Page } from "home/page";
import { addRubyText, addImage } from "common/util/generalUtil";



const Endings = () => {
  return <>
    <h2>Lesson 1b:{addRubyText("文末表現","ぶんまつひょうげん")} (Sentence Endings)</h2>
    <h3>〜{addRubyText("今","いま")}、お{addRubyText("金","かね")}あらへんねんわぁ。 〜</h3>
    <table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td colSpan="1" rowSpan="1" style={{verticalAlign: "top"}}>
          <ul>
            <li>
              <Link to={Page.BasicGrammar.SentenceEndings.Conversations}>
                {addRubyText("基本会話","きほんかいわ")} (Core Conversations)
              </Link>
            </li>
            <li>
              <Link to={Page.BasicGrammar.SentenceEndings.Grammar.Grammar}>
                {addRubyText("文法","ぶんぽう")}ノート (Grammar Note)
              </Link>
            </li>
            <li>
              <Link to={Page.BasicGrammar.SentenceEndings.Practice.Practice}>
                {addRubyText("練習","れんしゅう")} (Practice)
              </Link>
            </li>
            <li>
              <Link to={Page.BasicGrammar.SentenceEndings.Listening.Listening}>
                {addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")} (Listening Practice)
              </Link>
            </li>
            <li>
              <Link to={Page.BasicGrammar.SentenceEndings.Video.Video}>
                ビデオ{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}(Video Comprehension Practice)
              </Link> 
            </li>
          </ul>
          </td>
          <td colSpan="1" rowSpan="1" style={{verticalAlign: "top", textAlign: "center"}}>
            {addImage("shoobooji.jpg")}
            <br/>
            <small>
              正法寺の庭 Garden, Shobo-ji Temple (Kyoto)
            </small><br/>
          </td>
        </tr>
        <tr align="center">
        </tr>
      </tbody>
    </table>
  </>;
}

export default Endings;




