import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";

const BlackbeanTea = () => {
  const vocabList = <ul style={{display: "block", overflow: "visible"}}>
    <li>
      {addRubyText("黒豆","くろまめ")} black beans
    </li>
    <li>
      {addRubyText("砕","く")}だく break, smash, crush
    </li>
    <li>
      お{addRubyText("酢","す")} vinegar (polite)
    </li>
    <li>
      いっぺんに (Kansai) ={addRubyText("一度","いちど")}に all at once
    </li>
    <li>
      {addRubyText("賞味期限","しょうみきげん")} expiration date
    </li>
    <li>
      {addRubyText("便秘","べんぴ")} constipation
    </li>
    <li>
      {addRubyText("血圧","けつあつ")} blood pressure
    </li>
    <li>
      {addRubyText("安定","あんてい")}する be stabilized
    </li>
    <li>
      お{addRubyText("肌","はだ")} skin (polite)
    </li>
    <li>
      {addRubyText("血流","けつりゅう")} blood flow
    </li>
    <li>
      さらさら smooth
    </li>
  </ul>;

  const qaObjects = [
    {
      question: <span>Q1: According to the sales lady, what do people do with whole black beans after making tea?</span>,
      answersIncorrect: [
        <span>Use the beans to make flavored vinegar.</span>,
        <span>Cook the beans with vinegar and eat.</span>
      ],
      answersCorrect: [
        <span>Steep the beans in vinegar and eat.</span>
      ]
    },
    {
      question: <span>Q2: What is the difference between crushed black beans and whole black beans?</span>,
      answersIncorrect: [
        <span>The tea made with the crushed beans tastes better than the one made with the whole beans.</span>,
        <span>The crushed bean package contains more beans than the one with the whole beans.</span>
      ],
      answersCorrect: [
        <span>You can make tea more quickly with the crushed beans than the whole beans.</span>
      ]
    },
    {
      question: <span>Q3: How much are the beans?</span>,
      answersIncorrect: [
        <span>It’s 600-yen each for the first two packages. Three or more come to 500-yen each.</span>,
        <span>It’s 600-yen each for the first two packages. But when you buy three, each will cost 500-yen.</span>
      ],
      answersCorrect: [
        <span>It’s 600-yen for the first package, 1100-yen for two. Three or more come to 500-yen each.</span>
      ]
    },
    {
      question: <span>Q4:What was トモちゃん worried about when deciding to buy the beans?</span>,
      answersIncorrect: [
        <span>She doesn’t know if her family likes beans.</span>,
        <span>Her family may have bought beans already.</span>
      ],
      answersCorrect: [
        <span>The amount of beans looks too much for her family size.</span>
      ]
    },
    {
      question: <span>Q5: What did トモちゃん buy after all?</span>,
      answersIncorrect: [
        <span>One package of whole beans.</span>,
        <span>One each of crushed and whole beans.</span>
      ],
      answersCorrect: [
        <span>One package of crushed beans.</span>
      ]
    },
    {
      question: <span>Q6: What did the sales lady mention about the effects of the black bean tea? Choose all that apply.</span>,
      answersIncorrect: [
        <span>It lowers blood cholesterol.</span>,
        <span>It makes one’s skin shiny.</span>,
        <span>It might cause constipation.</span>,
        <span>It’s not recommended for those with high blood pressure.</span>
      ],
      answersCorrect: [
        <span>It stabilizes one’s blood pressure.</span>,
        <span>It cures constipation.</span>,
        <span>It makes one’s skin beautiful.</span>
      ]
    }
  ];

  return (
    <>
      <h2>{addRubyText("黒豆茶","くろまめちゃ")} (Black bean tea at a shop in Kyoto)</h2>
      <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>
            [Context] トモちゃん just tried a
            sample of Black bean tea at a store in Kyoto that sells local
            specialties of <i>Tanba</i> area
            (located in the middle of Kyoto prefecture).
          </td>
        </tr>
        </tbody>
      </table>
      <br />
      <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addVideoPlayer("5.10.kuromame1.mp4")}<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            Vocabulary List <br />
            <Hideable content={vocabList} />
          </td>
        </tr>
        </tbody>
      </table>
      <h3>{addRubyText("内容質問","ないようしつもん")} (Content Questions)</h3>
      <QandAForm qaObjects={qaObjects} />
    </>
  );
};

export default BlackbeanTea;
