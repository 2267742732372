import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import { Page } from "home/page";
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Divider from "@material-ui/core/Divider";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import Collapse from "@material-ui/core/Collapse";
import { Image, Menu } from "semantic-ui-react";
import styled from 'styled-components';

const StyledBurger = styled.div`
  width: 2rem;
  height: 2rem;
  position: fixed;
  bottom: 15px;
  left: 20px;
  z-index: 30;
  display: none;

  @media (max-width: 1050px) {
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background-color: ${({ open }) => open ? '#ccc' : 'gray'};
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;
    &:nth-child(1) {
      transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
    }
    &:nth-child(2) {
      transform: ${({ open }) => open ? 'translateX(100%)' : 'translateX(0)'};
      opacity: ${({ open }) => open ? 0 : 1};
    }
    &:nth-child(3) {
      transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }
`;

const SidebarItem = ({depthStep=10, depth=0, expanded, item, redirect, ...rest}) => {
  const [collapsed, setCollapsed] = React.useState(true);
  const {label, items, Icon, link} = item;

  const onClick = (e) => {
    if (Array.isArray(items)) {
      setCollapsed(!collapsed);
    } 
    if (link) {
      e.stopPropagation(); 
      redirect(link);
    }
  }

  let expandIcon;

  if (Array.isArray(items) && items.length) {
    expandIcon = !collapsed ? (
      <ExpandLessIcon
        className={
          "sidebar-item-expand-arrow sidebar-item-expand-arrow-expanded"
        }
        style={{color: "white"}}
      />
    ) : (
      <ExpandMoreIcon className="sidebar-item-expand-arrow" style={{color: "white"}} />
    );
  }

  return (
    <>
      <ListItem
        className="sidebar-item"
        onClick={onClick}
        button
        dense
        {...rest}
      >
        <div style={{paddingLeft: depth*depthStep}} className="sidebar-item-content">
          {Icon && <Icon className="sidebar-item-text" fontSize="small"/>}
          <div className="sidebar-item-text">{label}</div>
        </div>
        {expandIcon}
      </ListItem>
      <Collapse in={!collapsed} timeout="auto" unmountOnExit>
        {Array.isArray(items) ? (
          <List disablePadding dense>
            {items.map((subItem, index) => (
              <React.Fragment key={`${subItem.name}${index}`}>
                {subItem === "divider" ? (
                  <Divider style={{margin : "6px 0"}} />
                ) : (
                  <SidebarItem
                    depth={depth+1}
                    depthStep={depthStep}
                    item={subItem}
                    redirect={redirect}
                  />
                )}
              </React.Fragment>
            ))}
          </List>
        ) : null}
      </Collapse>
    </>
  )
}

const StyledSidebar = styled.div`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  z-index: 20;
  transition: transform 0.3s ease-in-out;
  @media (max-width: 1050px) {
    flex-flow: column nowrap;
    position: fixed;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-100%)'};
    top: 0;
    left: 0;
    height: 100vh;
    width: 300px;
    transition: transform 0.3s ease-in-out;
  }
`;

const Sidebar = (props) => {
  const {items, depthStep, depth, expanded, open, redirect} = props;

  return (
    <StyledSidebar open={open}>
      <Menu fixed="left" size="large" inverted vertical>
        <Menu.Item header>
          <Image floated="left" size="mini" src="logo.png" />
          <NavLink to={Page.FirstRun} activeClassName="selected">
            <h2>Kansai Dialect</h2>
          </NavLink>
        </Menu.Item>
        <div className="sidebar">
          <List disablePadding dense>
            {items.map((sidebarItem, index) => (
              <React.Fragment key={`${index}`}>
                {sidebarItem === "divider" ? (
                  <Divider style={{ margin: "6px 0"}} />
                ) : (
                  <SidebarItem
                    depthStep={depthStep}
                    depth={depth}
                    expanded={expanded}
                    item={sidebarItem}
                    redirect={redirect}
                  />
                )}
              </React.Fragment>
            ))}
          </List>
        </div>
      </Menu>
    </StyledSidebar>
  )
}

const Navigation = ({open, setOpen}) => {
  let history = useHistory();
  const redirect = (e) => {
    console.log(e);
    history.push(e);
  };

  const items = [
    { 
      label: 'Ch1. Characteristics',
      link: Page.Characteristics.Characteristics,
      items: [
        { 
          label: '1. Standard VS Kansai-ben',
          link: Page.Characteristics.StandardVsKansai
        },
        { 
          label: '2. How Kansai-ben Sounds Like',
          link: Page.Characteristics.HowKansaibenSoundsLike
        },
        { 
          label: '3. Linguistic Aspects', 
          link: Page.Characteristics.LinguisticAspects.LinguisticAspects,
          items: [
            { 
              label: 'a. Sound',
              link: Page.Characteristics.LinguisticAspects.Sound
            },
            { 
              label: 'b. Accent',
              link: Page.Characteristics.LinguisticAspects.Accent
            },
            { 
              label: 'c. Words',
              link: Page.Characteristics.LinguisticAspects.Words
            },
            { 
              label: 'd. Conjugation',
              link: Page.Characteristics.LinguisticAspects.Conjugation
            },
            { 
              label: 'e. Particles',
              link: Page.Characteristics.LinguisticAspects.Particles
            },
            { 
              label: 'f. Speech style',
              link: Page.Characteristics.LinguisticAspects.SpeechStyle
            },
            { 
              label: 'g. Honorifics',
              link: Page.Characteristics.LinguisticAspects.Honorifics
            }
          ]
        }
      ]  
    },
    {
      label: 'Ch2. Expressions',
      link: Page.Expressions.Expressions,
      items: [
        {
          label: '1. Greetings',
          link: Page.Expressions.Greetings
        },
        {
          label: '2. Leave-takings',
          link: Page.Expressions.LeaveTakings
        },
        {
          label: '3. Gratitude & Apology',
          link: Page.Expressions.GratitudeApology
        },
        {
          label: '4. Other useful expressions',
          link: Page.Expressions.OtherExpressions
        }
      ]
    },
    {
      label: 'Ch3. Basic Grammar',
      link: Page.BasicGrammar.BasicGrammar,
      items: [
        {
          label: '1a. Verbs',
          link: Page.BasicGrammar.Verbs.Verbs,
          items: [
            {
              label: '1. Core conversations',
              link: Page.BasicGrammar.Verbs.Conversations
            },
            {
              label: '2. Grammar note',
              link: Page.BasicGrammar.Verbs.Grammar.Grammar,
              items: [
                {
                  label: '1. Non-past negative form',
                  link: Page.BasicGrammar.Verbs.Grammar.NonPastNegative
                },
                {
                  label: '2. Past negative form',
                  link: Page.BasicGrammar.Verbs.Grammar.PastNegative
                },
                {
                  label: '3. た form',
                  link: Page.BasicGrammar.Verbs.Grammar.TaForm
                },
                {
                  label: '4. よう + verb negative form',
                  link: Page.BasicGrammar.Verbs.Grammar.YouNegative
                }
              ]
            },
            {
              label: '3. Practice',
              link: Page.BasicGrammar.Verbs.Practice.Practice,
              items: [
                {
                  label: 'Practice 1',
                  link: Page.BasicGrammar.Verbs.Practice.P1
                },
                {
                  label: 'Practice 2',
                  link: Page.BasicGrammar.Verbs.Practice.P2
                },
                {
                  label: 'Practice 3',
                  link: Page.BasicGrammar.Verbs.Practice.P3
                }
              ]
            },
            {
              label: '4. Listening practice',
              link: Page.BasicGrammar.Verbs.Listening.Listening,
              items: [
                {
                  label: 'Listening 1',
                  link: Page.BasicGrammar.Verbs.Listening.L1
                },
                {
                  label: 'Listening 2',
                  link: Page.BasicGrammar.Verbs.Listening.L2
                },
                {
                  label: 'Listening 3',
                  link: Page.BasicGrammar.Verbs.Listening.L3
                }
              ]
            },
            {
              label: '5. Video comprehension',
              link: Page.BasicGrammar.Verbs.Video.Video,
              items: [
                {
                  label: 'Video 1',
                  link: Page.BasicGrammar.Verbs.Video.V1
                },
                {
                  label: 'Video 2',
                  link: Page.BasicGrammar.Verbs.Video.V2
                },
                {
                  label: 'Video 3',
                  link: Page.BasicGrammar.Verbs.Video.V3
                }
              ]
            }
          ]
        },
        {
          label: '1b. Sentence endings',
          link: Page.BasicGrammar.SentenceEndings.SentenceEndings,
          items: [
            {
              label: '1. Core conversations',
              link: Page.BasicGrammar.SentenceEndings.Conversations
            },
            {
              label: '2. Grammar note',
              link: Page.BasicGrammar.SentenceEndings.Grammar.Grammar,
              items: [
                {
                  label: '1. Sentence particles',
                  link: Page.BasicGrammar.SentenceEndings.Grammar.SentenceParticles
                },
                {
                  label: '2. Extended Predicate',
                  link: Page.BasicGrammar.SentenceEndings.Grammar.ExtendedPredicate
                },
                {
                  label: '3. やんか',
                  link: Page.BasicGrammar.SentenceEndings.Grammar.Yanka
                },
                {
                  label: '4. Notes on quotation marker',
                  link: Page.BasicGrammar.SentenceEndings.Grammar.QuotationMarker
                }
              ]
            },
            {
              label: '3. Practice',
              link: Page.BasicGrammar.SentenceEndings.Practice.Practice,
              items: [
                {
                  label: 'Practice 1',
                  link: Page.BasicGrammar.SentenceEndings.Practice.P1
                },
                {
                  label: 'Practice 2',
                  link: Page.BasicGrammar.SentenceEndings.Practice.P2
                },
                {
                  label: 'Practice 3',
                  link: Page.BasicGrammar.SentenceEndings.Practice.P3
                },
                {
                  label: 'Practice 4',
                  link: Page.BasicGrammar.SentenceEndings.Practice.P4
                },
                {
                  label: 'Practice 5',
                  link: Page.BasicGrammar.SentenceEndings.Practice.P5
                },
                {
                  label: 'Practice 6',
                  link: Page.BasicGrammar.SentenceEndings.Practice.P6
                },
                {
                  label: 'Practice 7',
                  link: Page.BasicGrammar.SentenceEndings.Practice.P7
                },
                {
                  label: 'Practice 8',
                  link: Page.BasicGrammar.SentenceEndings.Practice.P8
                }
              ]
            },
            {
              label: '4. Listening practice',
              link: Page.BasicGrammar.SentenceEndings.Listening.Listening,
              items: [
                {
                  label: 'Listening 1',
                  link: Page.BasicGrammar.SentenceEndings.Listening.L1
                }
              ]
            },
            {
              label: '5. Video comprehension',
              link: Page.BasicGrammar.SentenceEndings.Video.Video,
              items: [
                {
                  label: 'Video 1',
                  link: Page.BasicGrammar.SentenceEndings.Video.V1
                }
              ]
            }
          ]
        },
        {
          label: '2. Verb TE-form',
          link: Page.BasicGrammar.VerbTeForm.VerbTeForm,
          items: [
            {
              label: '1. Core conversations',
              link: Page.BasicGrammar.VerbTeForm.Conversations
            },
            {
              label: '2. Grammar note',
              link: Page.BasicGrammar.VerbTeForm.Grammar.Grammar,
              items: [
                {
                  label: '1. て form formation',
                  link: Page.BasicGrammar.VerbTeForm.Grammar.TeFormation
                },
                {
                  label: '2. て form contraction',
                  link: Page.BasicGrammar.VerbTeForm.Grammar.TeContraction
                },
                {
                  label: '3. Functions of て form',
                  link: Page.BasicGrammar.VerbTeForm.Grammar.TeFunctions
                },
                {
                  label: '4. Casual request',
                  link: Page.BasicGrammar.VerbTeForm.Grammar.CasualRequest
                },
                {
                  label: '5. Negative て form and negative request',
                  link: Page.BasicGrammar.VerbTeForm.Grammar.NegativeRequest
                }
              ]
            },
            {
              label: '3. Practice',
              link: Page.BasicGrammar.VerbTeForm.Practice.Practice,
              items: [
                {
                  label: 'Practice 1',
                  link: Page.BasicGrammar.VerbTeForm.Practice.P1
                },
                {
                  label: 'Practice 2',
                  link: Page.BasicGrammar.VerbTeForm.Practice.P2
                },
                {
                  label: 'Practice 3',
                  link: Page.BasicGrammar.VerbTeForm.Practice.P3
                },
                {
                  label: 'Practice 4',
                  link: Page.BasicGrammar.VerbTeForm.Practice.P4
                },
                {
                  label: 'Practice 5',
                  link: Page.BasicGrammar.VerbTeForm.Practice.P5
                }
              ]
            },
            {
              label: '4. Listening practice',
              link: Page.BasicGrammar.VerbTeForm.Listening.Listening,
              items: [
                {
                  label: 'Listening 1',
                  link: Page.BasicGrammar.VerbTeForm.Listening.L1
                },
                {
                  label: 'Listening 2',
                  link: Page.BasicGrammar.VerbTeForm.Listening.L2
                }
              ]
            },
            {
              label: '5. Video comprehension',
              link: Page.BasicGrammar.VerbTeForm.Video.Video,
              items: [
                {
                  label: 'Video 1',
                  link: Page.BasicGrammar.VerbTeForm.Video.V1
                }
              ]
            },
          ]
        },
        {
          label: '3. Copula',
          link: Page.BasicGrammar.Copula.Copula,
          items: [
            {
              label: '1. Core conversations',
              link: Page.BasicGrammar.Copula.Conversations
            },
            {
              label: '2. Grammar note',
              link: Page.BasicGrammar.Copula.Grammar.Grammar,
              items: [
                {
                  label: '1. Copula conjugation',
                  link: Page.BasicGrammar.Copula.Grammar.Conjugation
                },
                {
                  label: '2. ほうかあ',
                  link: Page.BasicGrammar.Copula.Grammar.Houkaa
                }
              ]
            },
            {
              label: '3. Practice',
              link: Page.BasicGrammar.Copula.Practice.Practice,
              items: [
                {
                  label: 'Practice 1',
                  link: Page.BasicGrammar.Copula.Practice.P1
                },
                {
                  label: 'Practice 2',
                  link: Page.BasicGrammar.Copula.Practice.P2
                },
                {
                  label: 'Practice 3',
                  link: Page.BasicGrammar.Copula.Practice.P3
                },
                {
                  label: 'Practice 4',
                  link: Page.BasicGrammar.Copula.Practice.P4
                },
                {
                  label: 'Practice 5',
                  link: Page.BasicGrammar.Copula.Practice.P5
                },
              ]
            },
            {
              label: '4. Listening practice',
              link: Page.BasicGrammar.Copula.Listening.Listening,
              items: [
                {
                  label: 'Listening 1',
                  link: Page.BasicGrammar.Copula.Listening.L1
                }
              ]
            }
          ]
        },
        {
          label: '4. Adjectives',
          link: Page.BasicGrammar.Adjectives.Adjectives,
          items: [
            {
              label: '1. Core conversations',
              link: Page.BasicGrammar.Adjectives.Conversations
            },
            {
              label: '2. Grammar note',
              link: Page.BasicGrammar.Adjectives.Grammar.Grammar,
              items: [
                {
                  label: '1. Kansai adjectives',
                  link: Page.BasicGrammar.Adjectives.Grammar.KansaiAdjectives
                },
                {
                  label: '2. Negative form',
                  link: Page.BasicGrammar.Adjectives.Grammar.Negative
                },
                {
                  label: '3. Adverbial form',
                  link: Page.BasicGrammar.Adjectives.Grammar.Adverbs
                },
                {
                  label: '4. て form',
                  link: Page.BasicGrammar.Adjectives.Grammar.TeForm
                }
              ]
            },
            {
              label: '3. Practice',
              link: Page.BasicGrammar.Adjectives.Practice.Practice,
              items: [
                {
                  label: 'Practice 1',
                  link: Page.BasicGrammar.Adjectives.Practice.P1
                },
                {
                  label: 'Practice 2',
                  link: Page.BasicGrammar.Adjectives.Practice.P2
                },
                {
                  label: 'Practice 3',
                  link: Page.BasicGrammar.Adjectives.Practice.P3
                },
                {
                  label: 'Practice 4',
                  link: Page.BasicGrammar.Adjectives.Practice.P4
                },
              ]
            },
            {
              label: '4. Listening practice',
              link: Page.BasicGrammar.Adjectives.Listening.Listening,
              items: [
                {
                  label: 'Listening 1',
                  link: Page.BasicGrammar.Adjectives.Listening.L1
                }
              ]
            },
            {
              label: '5. Video comprehension',
              link: Page.BasicGrammar.Adjectives.Video.Video,
              items: [
                {
                  label: 'Video 1',
                  link: Page.BasicGrammar.Adjectives.Video.V1
                },
                {
                  label: 'Video 2',
                  link: Page.BasicGrammar.Adjectives.Video.V2
                },
              ]
            },
          ]
        }
      ]
    },
    {
      label: 'Ch4. Functional Grammar',
      link: Page.FunctionalGrammar.FunctionalGrammar,
      items: [
        {
          label: '1. Permission & prohibition',
          link: Page.FunctionalGrammar.PermissionProhibition.PermissionProhibition,
          items: [
            {
              label: '1. Core conversations',
              link: Page.FunctionalGrammar.PermissionProhibition.Conversations
            },
            {
              label: '2. Grammar note',
              link: Page.FunctionalGrammar.PermissionProhibition.Grammar.Grammar,
              items: [
                {
                  label: '1. Prohibition',
                  link: Page.FunctionalGrammar.PermissionProhibition.Grammar.Prohibition
                },
                {
                  label: '2. Permission',
                  link: Page.FunctionalGrammar.PermissionProhibition.Grammar.Permission
                }
              ]
            },
            {
              label: '3. Practice',
              link: Page.FunctionalGrammar.PermissionProhibition.Practice.Practice,
              items: [
                {
                  label: 'Practice 1',
                  link: Page.FunctionalGrammar.PermissionProhibition.Practice.P1
                },
                {
                  label: 'Practice 2',
                  link: Page.FunctionalGrammar.PermissionProhibition.Practice.P2
                },
                {
                  label: 'Practice 3',
                  link: Page.FunctionalGrammar.PermissionProhibition.Practice.P3
                },
                {
                  label: 'Practice 4',
                  link: Page.FunctionalGrammar.PermissionProhibition.Practice.P4
                }
              ]
            },
            {
              label: '4. Listening practice',
              link: Page.FunctionalGrammar.PermissionProhibition.Listening.Listening,
              items: [
                {
                  label: 'Listening 1',
                  link: Page.FunctionalGrammar.PermissionProhibition.Listening.L1
                },
                {
                  label: 'Listening 2',
                  link: Page.FunctionalGrammar.PermissionProhibition.Listening.L2
                },
                {
                  label: 'Listening 3',
                  link: Page.FunctionalGrammar.PermissionProhibition.Listening.L3
                }
              ]
            },
            {
              label: '5. Video comprehension',
              link: Page.FunctionalGrammar.PermissionProhibition.Video.Video,
              items: [
                {
                  label: 'Video 1',
                  link: Page.FunctionalGrammar.PermissionProhibition.Video.V1
                },
                {
                  label: 'Video 2',
                  link: Page.FunctionalGrammar.PermissionProhibition.Video.V2
                }
              ]
            }
          ]
        },
        {
          label: '2. Negative permission & obligation',
          link: Page.FunctionalGrammar.NegativePermissionObligation.NegativePermissionObligation,
          items: [
            {
              label: '1. Core conversations',
              link: Page.FunctionalGrammar.NegativePermissionObligation.Conversations
            },
            {
              label: '2. Grammar note',
              link: Page.FunctionalGrammar.NegativePermissionObligation.Grammar.Grammar,
              items: [
                {
                  label: '1. Negative permission',
                  link: Page.FunctionalGrammar.NegativePermissionObligation.Grammar.NegativePermission
                },
                {
                  label: '2. Unless',
                  link: Page.FunctionalGrammar.NegativePermissionObligation.Grammar.Unless
                },
                {
                  label: '3. Obligation',
                  link: Page.FunctionalGrammar.NegativePermissionObligation.Grammar.Obligation
                }
              ]
            },
            {
              label: '3. Practice',
              link: Page.FunctionalGrammar.NegativePermissionObligation.Practice.Practice,
              items: [
                {
                  label: 'Practice 1',
                  link: Page.FunctionalGrammar.NegativePermissionObligation.Practice.P1
                },
                {
                  label: 'Practice 2',
                  link: Page.FunctionalGrammar.NegativePermissionObligation.Practice.P2
                },
                {
                  label: 'Practice 3',
                  link: Page.FunctionalGrammar.NegativePermissionObligation.Practice.P3
                },
                {
                  label: 'Practice 4',
                  link: Page.FunctionalGrammar.NegativePermissionObligation.Practice.P4
                },
                {
                  label: 'Practice 5',
                  link: Page.FunctionalGrammar.NegativePermissionObligation.Practice.P5
                }
              ]
            },
            {
              label: '4. Listening practice',
              link: Page.FunctionalGrammar.NegativePermissionObligation.Listening.Listening,
              items: [
                {
                  label: 'Listening 1',
                  link: Page.FunctionalGrammar.NegativePermissionObligation.Listening.L1
                },
                {
                  label: 'Listening 2',
                  link: Page.FunctionalGrammar.NegativePermissionObligation.Listening.L2
                }
              ]
            },
            {
              label: '5. Video comprehension',
              link: Page.FunctionalGrammar.NegativePermissionObligation.Video.Video,
              items: [
                {
                  label: 'Video 1',
                  link: Page.FunctionalGrammar.NegativePermissionObligation.Video.V1
                },
                {
                  label: 'Video 2',
                  link: Page.FunctionalGrammar.NegativePermissionObligation.Video.V2
                },
                {
                  label: 'Video 3',
                  link: Page.FunctionalGrammar.NegativePermissionObligation.Video.V3
                },
                {
                  label: 'Video 4',
                  link: Page.FunctionalGrammar.NegativePermissionObligation.Video.V4
                },
                {
                  label: 'Video 5',
                  link: Page.FunctionalGrammar.NegativePermissionObligation.Video.V5
                },
                {
                  label: 'Video 6',
                  link: Page.FunctionalGrammar.NegativePermissionObligation.Video.V6
                },
                {
                  label: 'Video 7',
                  link: Page.FunctionalGrammar.NegativePermissionObligation.Video.V7
                },
                {
                  label: 'Video 8',
                  link: Page.FunctionalGrammar.NegativePermissionObligation.Video.V8
                },
                {
                  label: 'Video 9',
                  link: Page.FunctionalGrammar.NegativePermissionObligation.Video.V9
                },
                {
                  label: 'Video 10',
                  link: Page.FunctionalGrammar.NegativePermissionObligation.Video.V10
                },
                {
                  label: 'Video 11',
                  link: Page.FunctionalGrammar.NegativePermissionObligation.Video.V11
                },
                {
                  label: 'Video 12',
                  link: Page.FunctionalGrammar.NegativePermissionObligation.Video.V12
                },
                {
                  label: 'Video 13',
                  link: Page.FunctionalGrammar.NegativePermissionObligation.Video.V13
                },
                {
                  label: 'Video 14',
                  link: Page.FunctionalGrammar.NegativePermissionObligation.Video.V14
                },
                {
                  label: 'Video 15',
                  link: Page.FunctionalGrammar.NegativePermissionObligation.Video.V15
                },
                {
                  label: 'Video 16',
                  link: Page.FunctionalGrammar.NegativePermissionObligation.Video.V16
                },
                {
                  label: 'Video 17',
                  link: Page.FunctionalGrammar.NegativePermissionObligation.Video.V17
                },
                {
                  label: 'Video 18',
                  link: Page.FunctionalGrammar.NegativePermissionObligation.Video.V18
                },
                {
                  label: 'Video 19',
                  link: Page.FunctionalGrammar.NegativePermissionObligation.Video.V19
                }
              ]
            }
          ]
        },
        {
          label: '3. Imperatives',
          link: Page.FunctionalGrammar.Imperatives.Imperatives,
          items: [
            {
              label: '1. Core conversations',
              link: Page.FunctionalGrammar.Imperatives.Conversations
            },
            {
              label: '2. Grammar note',
              link: Page.FunctionalGrammar.Imperatives.Grammar.Grammar,
              items: [
                {
                  label: '1. Imperatives',
                  link: Page.FunctionalGrammar.Imperatives.Grammar.Imperatives
                },
                {
                  label: '2. Negative imperatives',
                  link: Page.FunctionalGrammar.Imperatives.Grammar.NegativeImperatives
                }
              ]
            },
            {
              label: '3. Practice',
              link: Page.FunctionalGrammar.Imperatives.Practice.Practice,
              items: [
                {
                  label: 'Practice 1',
                  link: Page.FunctionalGrammar.Imperatives.Practice.P1
                },
                {
                  label: 'Practice 2',
                  link: Page.FunctionalGrammar.Imperatives.Practice.P2
                },
                {
                  label: 'Practice 3',
                  link: Page.FunctionalGrammar.Imperatives.Practice.P3
                },
                {
                  label: 'Practice 4',
                  link: Page.FunctionalGrammar.Imperatives.Practice.P4
                },
                {
                  label: 'Practice 5',
                  link: Page.FunctionalGrammar.Imperatives.Practice.P5
                }
              ]
            },
            {
              label: '4. Listening practice',
              link: Page.FunctionalGrammar.Imperatives.Listening.Listening,
              items: [
                {
                  label: 'Listening 1',
                  link: Page.FunctionalGrammar.Imperatives.Listening.L1
                },
                {
                  label: 'Listening 2',
                  link: Page.FunctionalGrammar.Imperatives.Listening.L2
                },
                {
                  label: 'Listening 3',
                  link: Page.FunctionalGrammar.Imperatives.Listening.L3
                },
                {
                  label: 'Listening 4',
                  link: Page.FunctionalGrammar.Imperatives.Listening.L4
                }
              ]
            },
            {
              label: '5. Video comprehension',
              link: Page.FunctionalGrammar.Imperatives.Video.Video,
              items: [
                {
                  label: 'Video 1',
                  link: Page.FunctionalGrammar.Imperatives.Video.V1
                },
                {
                  label: 'Video 2',
                  link: Page.FunctionalGrammar.Imperatives.Video.V2
                },
                {
                  label: 'Video 3',
                  link: Page.FunctionalGrammar.Imperatives.Video.V3
                },
                {
                  label: 'Video 4',
                  link: Page.FunctionalGrammar.Imperatives.Video.V4
                },
                {
                  label: 'Video 5',
                  link: Page.FunctionalGrammar.Imperatives.Video.V5
                },
                {
                  label: 'Video 6',
                  link: Page.FunctionalGrammar.Imperatives.Video.V6
                },
                {
                  label: 'Video 7',
                  link: Page.FunctionalGrammar.Imperatives.Video.V7
                },
                {
                  label: 'Video 8',
                  link: Page.FunctionalGrammar.Imperatives.Video.V8
                }
              ]
            }
          ]
        },
        {
          label: '4. Honorifics',
          link: Page.FunctionalGrammar.Honorifics.Honorifics,
          items: [
            {
              label: '1. Core conversations',
              link: Page.FunctionalGrammar.Honorifics.Conversations
            },
            {
              label: '2. Grammar note',
              link: Page.FunctionalGrammar.Honorifics.Grammar.Grammar,
              items: [
                {
                  label: '1. Honorifics',
                  link: Page.FunctionalGrammar.Honorifics.Grammar.Honorifics
                },
                {
                  label: '2. Pejoratives',
                  link: Page.FunctionalGrammar.Honorifics.Grammar.Pejoratives
                },
                {
                  label: '3. Third person action',
                  link: Page.FunctionalGrammar.Honorifics.Grammar.ThirdPersonAction
                }
              ]
            },
            {
              label: '3. Video comprehension',
              link: Page.FunctionalGrammar.Honorifics.Video.Video,
              items: [
                {
                  label: 'Video 1',
                  link: Page.FunctionalGrammar.Honorifics.Video.V1
                },
                {
                  label: 'Video 2',
                  link: Page.FunctionalGrammar.Honorifics.Video.V2
                },
                {
                  label: 'Video 3',
                  link: Page.FunctionalGrammar.Honorifics.Video.V3
                },
                {
                  label: 'Video 4',
                  link: Page.FunctionalGrammar.Honorifics.Video.V4
                },
                {
                  label: 'Video 5',
                  link: Page.FunctionalGrammar.Honorifics.Video.V5
                },
                {
                  label: 'Video 6',
                  link: Page.FunctionalGrammar.Honorifics.Video.V6
                },
                {
                  label: 'Video 7',
                  link: Page.FunctionalGrammar.Honorifics.Video.V7
                },
                {
                  label: 'Video 8',
                  link: Page.FunctionalGrammar.Honorifics.Video.V8
                },
                {
                  label: 'Video 9',
                  link: Page.FunctionalGrammar.Honorifics.Video.V9
                },
                {
                  label: 'Video 10',
                  link: Page.FunctionalGrammar.Honorifics.Video.V10
                },
                {
                  label: 'Video 11',
                  link: Page.FunctionalGrammar.Honorifics.Video.V11
                },
                {
                  label: 'Video 12',
                  link: Page.FunctionalGrammar.Honorifics.Video.V12
                }
              ]
            }
          ]
        },
        {
          label: '5. Even / Even if',
          link: Page.FunctionalGrammar.Even.Even,
          items: [
            {
              label: '1. Core conversations',
              link: Page.FunctionalGrammar.Even.Conversations
            },
            {
              label: '2. Grammar note',
              link: Page.FunctionalGrammar.Even.Grammar.Grammar,
              items: [
                {
                  label: '1. Even',
                  link: Page.FunctionalGrammar.Even.Grammar.Even
                },
                {
                  label: '2. Even if',
                  link: Page.FunctionalGrammar.Even.Grammar.EvenIf
                }
              ]
            },
            {
              label: '3. Video comprehension',
              link: Page.FunctionalGrammar.Even.Video.Video,
              items: [
                {
                  label: 'Video 1', 
                  link: Page.FunctionalGrammar.Even.Video.V1
                },
                {
                  label: 'Video 2', 
                  link: Page.FunctionalGrammar.Even.Video.V2
                }
              ]
            }
          ]
        }
      ]
    },
    {
      label: 'Ch5. Talk in Town',
      link: Page.TalkInTown.TalkInTown,
      items: [
        {
          label: '1. Fish shop',
          link: Page.TalkInTown.FishShop
        },
        {
          label: '2. Vegetable shop',
          link: Page.TalkInTown.VegetableShop
        },
        {
          label: '3. Eel shop',
          link: Page.TalkInTown.EelShop
        },
        {
          label: '4. Peddler',
          link: Page.TalkInTown.Peddler
        },
        {
          label: '5. Pickle store',
          link: Page.TalkInTown.PickleStore
        },
        {
          label: '6. Fruit shop',
          link: Page.TalkInTown.FruitShop
        },
        {
          label: '7. Raw oyster',
          link: Page.TalkInTown.RawOyster
        },
        {
          label: '8. Grilled oyster',
          link: Page.TalkInTown.GrilledOyster
        },
        {
          label: '9. Confection store',
          link: Page.TalkInTown.ConfectionStore
        },
        {
          label: '10. Black bean tea',
          link: Page.TalkInTown.BlackbeanTea
        },
        {
          label: '11. Black bean tea 2',
          link: Page.TalkInTown.BlackbeanTea2
        },
        {
          label: '12. Strawberry',
          link: Page.TalkInTown.Strawberry
        },
        {
          label: '13. Rice cake making',
          link: Page.TalkInTown.RiceCake
        },
        {
          label: '14. Fish shop in Kyoto',
          link: Page.TalkInTown.FishShopKyoto
        }
      ]
    },
    {
      label: 'Ch6. Interviews',
      link: Page.Interviews,
      items: [
        {
          label: '1. Interviewees',
          link: Page.Interviewees
        },
        {
          label: '2. Interview questions',
          link: Page.InterviewQuestions
        },
        {
          label: '3. Clips',
          link: Page.InterviewClips
        }
      ]
    },
    {
      label: 'Ch7. Other Resources',
      link: Page.OtherResources.OtherResources,
      items: [
        {
          label: '1. Kansai anime & manga',
          link: Page.OtherResources.AnimeManga
        },
        {
          label: '2. Kansai films',
          link: Page.OtherResources.Films
        },
        {
          label: '3. Kansai JPOP',
          link: Page.OtherResources.JPop
        },
        {
          label: '4. Links',
          link: Page.OtherResources.Links
        },
        {
          label: '5. Kansai foreigners',
          link: Page.OtherResources.Foreigners.Foreigners,
          items: [
            {
              label: '1. Lee in english',
              link: Page.OtherResources.Foreigners.LeeEnglish
            },
            {
              label: '2. Lee in kansai-ben',
              link: Page.OtherResources.Foreigners.LeeKansaiben
            },
            {
              label: '3. Eric in english',
              link: Page.OtherResources.Foreigners.EricEnglish
            },
            {
              label: '4. Eric in kansai-ben',
              link: Page.OtherResources.Foreigners.EricKansaiben
            }
          ]
        },
        {
          label: '6. Letters from users',
          link: Page.OtherResources.LettersFromUsers
        },
        {
          label: '7. How to use this site in class',
          link: Page.OtherResources.HowToUse
        }
      ]
    },
    {
      label: 'About',
      link: Page.About.About,
      items: [
        {
          label: '1. About this project',
          link: Page.About.AboutThisProject
        },
        {
          label: '2. About people',
          link: Page.About.AboutPeople
        },
        {
          label: '3. Contact information',
          link: Page.About.ContactInformation
        },
        {
          label: '4. References',
          link: Page.About.References
        }
      ]
    },
    {
      label: 'Index',
      link: Page.Index.Index,
      items: [
        {
          label: '1. Kansai glossary',
          link: Page.Index.Glossary
        },
        {
          label: '2. Kansai grammar index',
          link: Page.Index.GrammarIndex
        }
      ]
    },
    {
      label: 'Read First',
      link: Page.ReadFirst
    },
    {
      label: 'Sitemap',
      link: Page.Sitemap
    }
  ]

  return <>
    <StyledBurger open={open} onClick={() => setOpen(!open)}>
      <div/>
      <div/>
      <div/>
    </StyledBurger>
    <Sidebar open={open} items={items} redirect={redirect}/>
  </>
};

export default Navigation;