import React from "react";
import "css/common.scss";
import { addTableCol } from "common/util/generalUtil";

const AboutPeople = () => {

  return (
    <>
      <h2>About People</h2>
      <br />
      <table className="custom-table" border="1" cellSpacing="2" cellPadding="2">
        <tbody>
        {addTableCol(
          "Directors",
          ["Director: Ikue Shingu", "Collaborator: Junko Hatanaka", "Collaborator: Mayumi Ishida"]
        )}
        {addTableCol(
          "Programming and Technical Support",
          ["Andy Wang", "Mange Chen","Josh Aresty", "Cliff Myers", "Benjamin Gleitzman", "Mieszko Lis"]
        )}
        {addTableCol(
          "Advisory Group",
          ["Shigeru Miyagawa", "Ruth Trometer","Kurt Fendt", "Yoshitaka Yamashita"]
        )}
        {addTableCol(
          "Contributors",
          [
            "We would like to express our deep gratitude to all the people who agreed to appear in our video clips, people who kindly donated photos, illustrations and videos, and those who tried our prototype and gave us great insights!",
            "Ruth Trometer",
            "Kurt Fendt",
            "Yoshitaka Yamashita",
            <small>お忙しい所インタビューにご協力下さった皆様、写真やイラスト等寄付して下さった皆様、そしてプロトタイプを試用し貴 重な意見を下さった皆様、本当にどうもあり がとうございました！</small>
          ]
        )}
        {addTableCol(
          "Sponsor",
          [<a href="http://www.languageconsortium.org/">The Consortium for Language Teaching and Learning</a>]
        )}
        </tbody>
      </table>
    </>
  );
};

export default AboutPeople;