import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";

const V10 = () => {
  const transcript = <>
    あの、あれ、{addRubyText("冷蔵庫","れいぞうこ")}{addRubyText("入","い")}れたっさけ、なんやけど、{addRubyText("正月","しょうがつ")}、{addRubyText("暮","く")}れにもろた{addRubyText("白菜","はくさい")}が、やっと{addRubyText("小","ちい")}そうなってきたやと、まだ、こんなんが、まだ冷蔵庫、冷蔵庫で{addRubyText("元気","げんき")}に<span
    style={{textDecoration: "underline"}}>{addRubyText("生","い")}きとる</span>(Grammar
    2)。もう{addRubyText("炊","た")}かないかんな、と{addRubyText("思","おも")}うわ。ほんまになあ。いや、もう、こんな、暮れにこんな白菜、{addRubyText("親元","おやもと")}から、{addRubyText("二","ふた")}つ
    {addRubyText("持","も")}ってきて、ちょうど{addRubyText("娘","むすめ")}が{addRubyText("来","き")}たし、持って{addRubyText("帰","かえ")}って{addRubyText("言","ゆ")}うて，持って帰らしたけど、{addRubyText("一","ひと")}つは{addRubyText("食","た")}
    べよ思て{addRubyText("置","お")}いといたけどなあ。まだこんなん<span style={{textDecoration: "underline"}}>{addRubyText("残","のこ")}っとん</span>(=残っとる Grammar
    2)で。いや〜、{addRubyText("一人","ひとり")}ちゅうのは{addRubyText("不経済","ふけいざい")}やなあ。いや〜ほんで、、、
  </>;

  const qaObjects = [
    {
      question: <span>Q1:Choose all that apply regarding {addRubyText("白菜","はくさい")}in her story. </span>,
      answersCorrect: [
        <>She got them half a year ago.</>,
        <>One of them is still in her refrigerator.</>,
        <>She got them from her parents’ place.</>,
        <>She let her daughter take one home.</>,
        <>She got two of those. </>
      ],
      answersIncorrect: [
        <>She hasn’t eaten any of those since she got them.</>,
        <>She got them from her daughter.</>,
        <>She let her parents take one home.</>,
        <>She has no plan on eating the one she has.</>,
        <>She is healthy because of them.</>
      ]
    },
    {
      question: <span>Q2: What does she call {addRubyText("不経済","ふけいざい")}?</span>,
      answersCorrect: [
        <>Living alone</>
      ],
      answersIncorrect: [
        <>Getting too many Chinese cabbage</>,
        <>Having a big family</>,
      ]
    },
    {
      question: <span>Q3: For whom or what does she use とる?</span>,
      answersCorrect: [
        <li>Chinese cabbage</li>
      ],
      answersIncorrect: [
        <>Her daughter</>,
        <>Her parents</>,
        <>Interviewer</>,
        <>Herself </>
      ]
    }
  ];

  return <>
    <h2>ビデオ{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}１０: Authentic - long<br />
    </h2>
    Watch the video clip and choose the answer that matches the content of
    the clip.
    Submit your answers after answering all the questions.<br />
    <small>*Click "SHOW" if you need to refer to the transcript</small><br />
    Context: Ishioh-san was talking about cooking for herself. （This
    interview was conducted in <span style={{textDecoration: "underline"}}>June</span>.)<br />
    <table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>{addVideoPlayer("4.4.2.p.2.ishioh.mp4")} </td>
          <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>Transcript
    <Hideable content={transcript}/><br />
    ＊{addRubyText("冷蔵庫","れいぞうこ")} refrigerator<br />
    ＊{addRubyText("正月","しょうがつ")} New Year’s Day<br />
    ＊{addRubyText("暮","く")}れ the year-end<br />
    ＊{addRubyText("白菜","はくさい")} Chinese cabbage<br />
    ＊{addRubyText("炊","た")}く (Kansai) ={addRubyText("煮","に")}る cook, boil, simmer<br />
    ＊{addRubyText("親元","おやもと")} parents’ place, home<br />
    ＊{addRubyText("不経済","ふけいざい")} uneconomical<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>イシオウさん
    (Shiga)</td>
        </tr>
      </tbody>
    </table>
    <br />
    <QandAForm qaObjects={qaObjects}/>
  </>;
}

export default V10;
