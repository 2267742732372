import React from "react";
import { Link } from "react-router-dom";
import { addRubyText, addAudio } from "common/util/generalUtil";
import { Page } from "home/page";

const Houkaa = () => {
  return <>
    <h2>2. ほうかあ</h2>
    <h3>そう（だ）conjugation</h3>
    <small>*Move the mouse over the
    speaker icon for sound</small><br />
    <table style={{ textAlign: "left", width: "100%" }} border="1" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{ verticalAlign: "top" }}><br />
          </td>
          <td style={{ verticalAlign: "top", textAlign: "center" }}>Standard<br />
          </td>
          <td style={{ verticalAlign: "top", textAlign: "center" }}>Kansai<br />
          </td>
        </tr>
        <tr>
          <td style={{ verticalAlign: "top" }}>Non-past affirmative<br />
          </td>
          <td style={{ verticalAlign: "top" }}>そう（だ）<br />
          </td>
          <td style={{ verticalAlign: "top" }}><span style={{ fontWeight: "bold" }}>そ</span>う、<span
            style={{ fontWeight: "bold" }}>ほ</span>う<br />
            <span style={{ fontWeight: "bold" }}>そ</span>や、<span
              style={{ fontWeight: "bold" }}>せ</span>や、<span style={{ fontWeight: "bold" }}>ほ</span>や<br />
          </td>
        </tr>
        <tr>
          <td style={{ verticalAlign: "top" }}>Past affirmative<br />
          </td>
          <td style={{ verticalAlign: "top" }}>そうだった<br />
          </td>
          <td style={{ verticalAlign: "top" }}><span style={{ fontWeight: "bold" }}>そ</span>／<span
            style={{ fontWeight: "bold" }}>せ</span>／<span style={{ fontWeight: "bold" }}>ほ</span>やった<br />
          </td>
        </tr>
        <tr>
          <td style={{ verticalAlign: "top" }}>Non-past negative<br />
          </td>
          <td style={{ verticalAlign: "top" }}>そうじゃない<br />
          </td>
          <td style={{ verticalAlign: "top" }}><span style={{ fontWeight: "bold" }}>そ</span>／<span
            style={{ fontWeight: "bold" }}>せ</span>／<span style={{ fontWeight: "bold" }}>ほ</span>やあ<span style={{ fontWeight: "bold" }}>ら</span>へん
            <br />
            <span style={{ fontWeight: "bold" }}>そ</span>／<span
              style={{ fontWeight: "bold" }}>せ</span>／<span style={{ fontWeight: "bold" }}>ほ</span>やない
              <br />
              （<span style={{ fontWeight: "bold" }}>そう</span>／<span
              style={{ fontWeight: "bold" }}>ほう</span>）<span style={{ fontWeight: "bold" }}>ちゃう</span><br />
          </td>
        </tr>
        <tr>
          <td style={{ verticalAlign: "top" }}>Past negative<br />
          </td>
          <td style={{ verticalAlign: "top" }}>そうじゃなかった<br />
          </td>
          <td style={{ verticalAlign: "top" }}><span style={{ fontWeight: "bold" }}>そ</span>／<span
            style={{ fontWeight: "bold" }}>せ</span>／<span style={{ fontWeight: "bold" }}>ほ</span>やあ<span style={{ fontWeight: "bold" }}>ら</span>へんかった
            <br />
            <span style={{ fontWeight: "bold" }}>そ</span>／<span
              style={{ fontWeight: "bold" }}>せ</span>／<span style={{ fontWeight: "bold" }}>ほ</span>やなかった
              <br />
              （<span style={{ fontWeight: "bold" }}>そう</span>／<span
              style={{ fontWeight: "bold" }}>ほう</span>）<span style={{ fontWeight: "bold" }}>ち</span>ごた<br />
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    As explained in <Link to={Page.Characteristics.LinguisticAspects.Sound}>a.Sound</Link>in Chapter 2
    (Characteristics), Kansai people tend to
    pronounce consonants more weakly and vowels more strongly, hence,
    consonant S in some words is alternated by weaker consonant H.<small><br />
      <br />
    </small>
    <table style={{ textAlign: "left", width: "50%" }} border="1" cellPadding="2"
      cellSpacing="2">
      <tbody>
        <tr>
          <td style={{ verticalAlign: "top" }}><br />
          </td>
          <td style={{ verticalAlign: "top", textAlign: "center" }}>Standard<br />
          </td>
          <td style={{ verticalAlign: "top", textAlign: "center" }}>Kansai<br />
          </td>
        </tr>
        <tr>
          <td style={{ verticalAlign: "top" }}>"seven"<br />
          </td>
          <td style={{ verticalAlign: "top" }}>しち{addAudio("3.3.2.1.s.mp3")}<br />
          </td>
          <td style={{ verticalAlign: "top" }}><span style={{ fontWeight: "bold" }}>ひ</span>ち{addAudio("3.3.2.1.mp3")}</td>
        </tr>
        <tr>
          <td style={{ verticalAlign: "top" }}>"pawn shop"<br />
          </td>
          <td style={{ verticalAlign: "top" }}>しちや{addAudio("3.3.2.2.s.mp3")}<br />
          </td>
          <td style={{ verticalAlign: "top" }}><span style={{ fontWeight: "bold" }}>ひち</span>や{addAudio("3.3.2.2.mp3")}</td>
        </tr>
      </tbody>
    </table>
    <br />
    そう is pronounced ほう in Kansai traditionally, however, そう with
    Kansai-type conjugation ending (Neo-dialect)&nbsp; is becoming popular
    with the younger generations.&nbsp; Preference between そ and ほ also
    depends on the region. Note that そうだ tends to be shortened to そや／ほや／せや
    and そして／それで become そんで／ほんで／ほて.<br />
    <br />
    {addRubyText("雨", "あめ")}、{addRubyText("嫌", "いや")}やなあ。→ほやなあ。<small>&nbsp; </small>{addAudio("3.3.2.3.mp3")}<br />
    <small>（ST 雨は嫌だねえ。ー＞そうだねえ。）</small><small>{addAudio("3.3.2.3.s.mp3")}</small><br />
    <br />
    あの{addRubyText("人", "ひと")}、{addRubyText("好", "す")}きなんやろ！→ほんなことあらへんわ！<small>&nbsp; </small>{addAudio("3.3.2.4.mp3")}<br />
    <small>（ST あの人、好きなんでしょ！ー＞そんなことないよ！）</small><small>{addAudio("3.3.2.4.s.mp3")}</small><br />
    <br />
    {addRubyText("今日", "きょう")}、{addRubyText("田中", "たなか")}さんに{addRubyText("会", "お")}うてな、、、。→うん、ほんで？<small> </small>{addAudio("3.3.2.5.mp3")}<br />
    <small>（ST 今日、田中さんに会ってね、、、。ー＞うん、それで？）</small><small>{addAudio("3.3.2.5.s.mp3")}</small><br />
    <br />
    ほな、また。<small></small>{addAudio("3.3.2.6.mp3")}<br />
    <small>（ST それでは／じゃあ、また。） </small><small>{addAudio("3.3.2.6.s.mp3")}</small>
  </>;
}

export default Houkaa;
