import React from "react";
import { addRubyText, addAudio } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";

const L1 = () => {

  const qaObjects = [
    {
      superQuestion: <>
        (1){addAudio("4.3.pl0.1.1.mp3")} <Hideable content={<>
        こっちも{addRubyText("忙","いそが")}しんやから、そんなちょくちょく{addRubyText("電話","でんわ")}しなや。</>}/>
      </>
    },
    {
      question: <>____ call often.</>,
      answersCorrect: [
        <>Don't</>
      ],
      answersIncorrect: [
        <>Please</>
      ]
    },
    {
      superQuestion: <>
        (2){addAudio("4.3.pl0.1.2.mp3")} <Hideable content={<>
        せっかく{addRubyText("来","き")}たんやし、まあビールでも{addRubyText("飲","の")}みぃな。</>}/>
      </>
    },
    {
      question: <>____ drink beer.</>,
      answersCorrect: [
        <>Please</>
      ],
      answersIncorrect: [
        <>Do not</>
      ]
    },
    {
      superQuestion: <>
        (3){addAudio("4.3.pl0.1.3.mp3")} 
        <Hideable content={<>それ、いつのか{addRubyText("分","わ")}からへんから、{addRubyText("飲","の")}みな。</>}/>
      </>
    },
    {
      question: <>____ drink it.</>,
      answersCorrect: [
        <>Do not</>
      ],
      answersIncorrect: [
        <>Please</>
      ]
    },
    {
      superQuestion: <>
        (4){addAudio("4.3.pl0.1.4.mp3")} 
        <Hideable content={<>また{addRubyText("肉","にく")}か。{addRubyText("魚","さかな")}もたまには{addRubyText("料理","りょうり")}しぃや。</>}/>
      </>
    },
    {
      question: <>_____ cook fish.</>,
      answersCorrect: [
        <>Do</>
      ],
      answersIncorrect: [
        <>Do not</>
      ]
    },
    {
      superQuestion: <>
        (5){addAudio("4.3.pl0.1.5.mp3")} 
        <Hideable content={<>{addRubyText("何","なに")}してんの、そんなだらだら{addRubyText("歩","ある")}かんと。</>}/>
      </>
    },
    {
      question: <>_____walk slowly.</>,
      answersCorrect: [
        <>Do not</>
      ],
      answersIncorrect: [
        <>Do</>
      ]
    },
  ];

  return <>
    <h2>{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}１</h2>
    Listen to the supervisor, and choose the statement that best describes
    what he says. Submit your answers after finishing all the questions.<br />
    <small>*Click "SHOW" to refer to the transcript.<br />
    *Mouse over or click on the speaker icon for sound.<br />
    <br />
    </small>
    <QandAForm qaObjects={qaObjects}/>
  </>;
}

export default L1;
