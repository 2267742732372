import React from "react";
import { addRubyText, addAudio } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
const P1 = () => {
  const transcript = <>
    <ol>
      <li>A: これ、食べてもいい？</li>
      <li>B: それ？いや、食べちゃだめ。</li>
    </ol>
  </>;
  return <>
<h2>{addRubyText("練習","れんしゅう")}1: Permission &amp; Prohibition - Pattern Practice</h2>
<br />
Form a question (asking a permission) and an answer (prohibition) for
each cue according to the model.&nbsp; You may listen to the question,
try to answer it, and then check your answer. *Answers vary: the
recorded answers indicate those commonly used.<br />
<small>*Move the mouse over to the speaker icon for sound<br />
<br />
</small><span style={{fontWeight: "bold"}}>Model</span> (Cue: これ／{addRubyText("食","た")}べる）
) <br />
&nbsp;&nbsp;&nbsp; A: これ、食べてもええ？<br />
&nbsp;&nbsp;&nbsp; B: それ？いや、食べた（ら）あかん。／食べた（ら）いかん。{addAudio("4.1.p.1.mp3")}<br />
<br />
ST transcript 
<br />
<Hideable content={transcript} />
<br />
<table style={{textAlign: "left", width: "100%"}} border="1"cellPadding="2"
 cellSpacing="2">
  <tbody>
    <tr>
      <td style={{verticalAlign: "top", textAlign: "center"}}>Cue<br />
      </td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>A (Question)<br />
      </td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>B (Answer)<br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>このまんが／{addRubyText("読","よ")}む<br />
      </td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("4.1.p.1.1.a.mp3")}<br />
      </td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("4.1.p.1.1.b.mp3")}<br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>あのいす／すわる<br />
      </td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("4.1.p.1.2.a.mp3")}<br />
      </td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("4.1.p.1.2.b.mp3")}<br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>コンピューター／{addRubyText("使","つか")}う<br />
      </td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("4.1.p.1.3.a.mp3")}<br />
      </td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("4.1.p.1.3.b.mp3")}<br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>ゲーム／する<br />
      </td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("4.1.p.1.4.a.mp3")}<br />
      </td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("4.1.p.1.4.b.mp3")}<br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>ビール／{addRubyText("飲","の")}む </td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("4.1.p.1.5.a.mp3")}<br />
      </td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("4.1.p.1.5.b.mp3")}<br />
      </td>
    </tr>
  </tbody>
</table>
  </>;
}

export default P1;
