import React from "react";
import { addRubyText } from "common/util/generalUtil";
import { Page } from "home/page";
import { Link } from "react-router-dom";

const GrammarIndex = () => {
  return <>
<h2>Kansai Grammar Index <br />
</h2>
<small>*<span style={{fontWeight: "bold"}}>Adj.</span> = Adjective, <span
 style={{fontWeight: "bold"}}>A(adv) </span>= Kansai adverbial from of
Adj., <span style={{fontWeight: "bold"}}>A(root)</span> = Root of
Adj.&nbsp; <span style={{fontWeight: "bold"}}>N </span>= Noun/Na-Adj. <span
 style={{fontWeight: "bold"}}>V</span> =Verb, <span
 style={{fontWeight: "bold"}}>V(stem)</span> = stem of Verb ますform, <span
 style={{fontWeight: "bold"}}>V(neg)</span> = stem of Verb negative form,
<span style={{fontWeight: "bold"}}>Vた</span> = Verb たform (Past
affirmative form of V), <span style={{fontWeight: "bold"}}>Vて</span>=
Verb てform (Gerund), <span style={{fontWeight: "bold"}}>V(dic)</span> =
dictionary form of Verb<br />
*Each expression is introduced in the page indicated under "where to
find". "3.4.2.1" stands for "Ch3, Lesson4, 2(Grammar) - 1"</small><br />
<br />
<table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2"
 cellSpacing="2">
  <tbody>
    <tr>
      <td style={{verticalAlign: "top"}}><br />
      </td>
      <td style={{verticalAlign: "top"}}>Kansai expression<br />
      </td>
      <td style={{verticalAlign: "top"}}><br />
      </td>
      <td style={{verticalAlign: "top"}}>Standard<br />
      </td>
      <td style={{verticalAlign: "top"}}>Explanation<br />
      </td>
      <td style={{verticalAlign: "top"}}>where to find<br />
      </td>
    </tr>
    <tr>
      <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>あ<br />
      </td>
      <td style={{verticalAlign: "top"}}>〜あらへん&nbsp;&nbsp;&nbsp; <br />
      <br />
      </td>
      <td style={{verticalAlign: "top"}}>A(adv)あらへん&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>A(root)くない&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Non-past negative form of Adj.&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.Characteristics.LinguisticAspects.Conjugation}>1.2.d</Link>,
        <Link to={Page.BasicGrammar.Adjectives.Grammar.Negative}>3.4.2.2</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜あらへんかった &nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}> A(adv)あらへんかった <br />
      </td>
      <td style={{verticalAlign: "top"}}>A(root)くなかった&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Past negative form of Adj.
&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.Characteristics.LinguisticAspects.Conjugation}>1.2.d</Link>,
        <Link to={Page.BasicGrammar.Adjectives.Grammar.Negative}>3.4.2.2</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>え<br />
      </td>
      <td style={{verticalAlign: "top"}}>〜え&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Sentence え&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>よ&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Sentence particle (assertion, Kyoto, gentle)&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.SentenceEndings.Grammar.SentenceParticles}>3.1b.2.1</Link>,
        <Link to={Page.FunctionalGrammar.NegativePermissionObligation.Conversations}>4.2.1</Link>
      </td>
    </tr>
    <tr>
      <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>お<br />
      </td>
      <td style={{verticalAlign: "top"}}>〜おす&nbsp;&nbsp;&nbsp; <br />
      <br />
      </td>
      <td style={{verticalAlign: "top"}}>A(adv)おす&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>A(root)いです&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Polite non-past affirmative form of Adj. (traditional, Kyoto) &nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.Characteristics.LinguisticAspects.SpeechStyle}>1.2.f</Link>,
        <Link to={Page.FunctionalGrammar.NegativePermissionObligation.Conversations}>4.2.1</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜おます&nbsp;&nbsp;&nbsp; <br />
      <br />
      </td>
      <td style={{verticalAlign: "top"}}>A(adv)おます&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>A(root)いです&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Polite non-past affirmative form of Adj. (traditional, Osaka) &nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.Characteristics.LinguisticAspects.SpeechStyle}>1.2.f</Link>
      </td>
    </tr>
    <tr>
      <td colSpan="1" rowSpan="5" style={{verticalAlign: "top"}}>か<br />
      </td>
      <td style={{verticalAlign: "top"}}>〜かいな&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Sentence かいな&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}> 〜か？&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Sentence particle (doubt, rhetorical question, command) &nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.Expressions.OtherExpressions}>2.4</Link>, <Link to={Page.BasicGrammar.SentenceEndings.Grammar.SentenceParticles}>3.1b.2.1</Link>,
        <Link to={Page.FunctionalGrammar.Even.Conversations}>4.5.1</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜かって&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>A(root)かって&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>-&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Past gerund (てform) of Adj.&nbsp; (Kansai only)<br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.Adjectives.Grammar.TeForm}>3.4.2.4</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜かて&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Nかて&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Nでも、Nさえ&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>"even" &nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.FunctionalGrammar.Even.Grammar.Even}>4.5.2.1</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜かて&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Vたかて&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Vても、Vたって&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>"even if …&nbsp; " <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.FunctionalGrammar.Even.Grammar.EvenIf}>4.5.2.2</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜がな&nbsp;&nbsp;&nbsp; <br />
      <br />
      </td>
      <td style={{verticalAlign: "top"}}>Sentence がな&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>よ&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Sentence particle (assertion, blunt)&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.Characteristics.LinguisticAspects.Particles}>1.2.e</Link>,
        <Link to={Page.BasicGrammar.SentenceEndings.Grammar.SentenceParticles}>3.1b.2.1</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>け<br />
      </td>
      <td style={{verticalAlign: "top"}}>〜け&nbsp;&nbsp;&nbsp; <br />
      <br />
      </td>
      <td style={{verticalAlign: "top"}}>Sentence け&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>~?&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Sentence particle (Question, blunt)&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.SentenceEndings.Grammar.SentenceParticles}>3.1b.2.1</Link>
      </td>
    </tr>
    <tr>
      <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>こ<br />
      </td>
      <td style={{verticalAlign: "top"}}>〜こと&nbsp;&nbsp;&nbsp; <br />
      <br />
      </td>
      <td style={{verticalAlign: "top"}}>A(root)いこと&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>A(root)く&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Adverbial form of Adj. &nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.Adjectives.Grammar.Adverbs}>3.4.2.3</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜ことあらへん／ない&nbsp;&nbsp;&nbsp; <br />
      <br />
      </td>
      <td style={{verticalAlign: "top"}}>A(root)いことあらへん／ない&nbsp;&nbsp;
&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>A(root)くない&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Non-past negative form of Adj.
&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.Adjectives.Grammar.Negative}>3.4.2.2</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>し<br />
      </td>
      <td style={{verticalAlign: "top"}}>〜しまへん&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>V(neg)しまへん&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>V (stem)ません&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Polite non-past negative form of V (Traditional)&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.Verbs.Grammar.NonPastNegative}>3.1a.2.1</Link>
      </td>
    </tr>
    <tr>
      <td colSpan="1" rowSpan="15" style={{verticalAlign: "top"}}>た<br />
      </td>
      <td style={{verticalAlign: "top"}}>〜た（あ）る&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Vた（あ）る&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Viている、Vtてある <br />
      </td>
      <td style={{verticalAlign: "top"}}>"~ is in the state of Vi." "~has been Vt-ed. "&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.VerbTeForm.Grammar.TeContraction}>3.2.2.2</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜た（ら）あかん&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Vた（ら）あかん&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Vてはだめだ&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Prohibition “One most not do…”&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.FunctionalGrammar.PermissionProhibition.Grammar.Prohibition}>4.1.2.1</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜た（ら）いかん&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Vた（ら）いかん&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}> Vてはいけない&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Prohibition “One most not do…”&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.FunctionalGrammar.PermissionProhibition.Grammar.Prohibition}>4.1.2.1</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜たかてええ&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Vたかてええ&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Vてもいい&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Permission ‘You may…”&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.FunctionalGrammar.PermissionProhibition.Grammar.Permission}>4.1.2.2</Link>,
        <Link to={Page.FunctionalGrammar.Even.Grammar.Even}>4.5.2.1</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜たかてかまへん／かめへん&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Vたかてかまへん／かめへん&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Vてもかまわない&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Permission ‘You may…”&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.FunctionalGrammar.PermissionProhibition.Grammar.Permission}>4.1.2.2</Link>,
        <Link to={Page.FunctionalGrammar.Even.Grammar.Even}>4.5.2.1</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜たげて&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Vたげて&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}> Vてあげて&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Casual request “Please do…for me (ingroup).”&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.VerbTeForm.Grammar.CasualRequest}>3.2.2.4</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜たげる<br />
      </td>
      <td style={{verticalAlign: "top"}}> Vたげる&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Vてあげる&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>"I (ingroup) will do … for you."
&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.VerbTeForm.Grammar.TeContraction}>3.2.2.2</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜だす&nbsp;&nbsp;&nbsp; <br />
      <br />
      </td>
      <td style={{verticalAlign: "top"}}>Nだす&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Nです&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Polite non-past affirmative form of Copula (traditional, Osaka) &nbsp; </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.Characteristics.LinguisticAspects.SpeechStyle}>1.2.f</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜だっ&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Nだっ&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Nです&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>=だす Polite non-past affirmative form of Copula (traditional, Osaka) </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.Characteristics.LinguisticAspects.SpeechStyle}>1.2.f</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜だっせ&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Nだっせ&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Nですよ&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>だす＋え(traditional, Osaka) &nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.Characteristics.LinguisticAspects.SpeechStyle}>1.2.f</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜たって&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Vたって&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Vてあげて、Vてやって&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Casual request “Please do …for me (ingroup).”&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.VerbTeForm.Grammar.CasualRequest}>3.2.2.4</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜たない&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>V(stem)たない&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>V(stem)たくない&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>"I don’t want to … "&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.Adjectives.Grammar.Negative}>3.4.2.2</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜たはる／たある&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Vたはる&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>-&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Light honorific form of Vている(Kyoto)&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.FunctionalGrammar.Honorifics.Grammar.Honorifics}>4.4.2.1</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜たる&nbsp;&nbsp;&nbsp; <br />
      <br />
      </td>
      <td style={{verticalAlign: "top"}}>Vたる&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Vてあげる、Vてやる&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>"I will do …for you." (blunt)&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.VerbTeForm.Grammar.TeContraction}>3.2.2.2</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜だんな&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Nだんな&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Nですね&nbsp;&nbsp; </td>
      <td style={{verticalAlign: "top"}}>だす+ な (traditional, Osaka ) <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.Characteristics.LinguisticAspects.SpeechStyle}>1.2.f</Link>
      </td>
    </tr>
    <tr>
      <td colSpan="1" rowSpan="4" style={{verticalAlign: "top"}}>ち<br />
      </td>
      <td style={{verticalAlign: "top"}}>〜ちごた&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Nちごた&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Nじゃなかった&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>(＝ちゃうかった）Past negative form of Copula&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.Characteristics.LinguisticAspects.Conjugation}>1.2.d</Link>,
        <Link to={Page.BasicGrammar.Copula.Grammar.Conjugation}>3.3.2.1</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜ちゃう&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Nちゃう&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Nじゃない&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Non-past negative form of Copula&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.Characteristics.LinguisticAspects.Conjugation}>1.2.d</Link>,
        <Link to={Page.BasicGrammar.Copula.Grammar.Conjugation}>3.3.2.1</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜ちゃうかった&nbsp;&nbsp;&nbsp; <br />
      <br />
      </td>
      <td style={{verticalAlign: "top"}}>Nちゃうかった&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Nじゃなかった&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>(=ちごた）Past negative form of Copula&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.Characteristics.LinguisticAspects.Conjugation}>1.2.d</Link>,
        <Link to={Page.BasicGrammar.Copula.Grammar.Conjugation}>3.3.2.1</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜ちゅう&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>N/sentenceちゅう&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>N/sentenceと{addRubyText("言","い")}う <br />
      </td>
      <td style={{verticalAlign: "top"}}>"... is called N" " someone says ... " (quotation)&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.SentenceEndings.Grammar.QuotationMarker}>3.1b.2.4</Link>
      </td>
    </tr>
    <tr>
      <td colSpan="1" rowSpan="17" style={{verticalAlign: "top"}}>て<br />
      </td>
      <td style={{verticalAlign: "top"}}>〜で&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Sentence で&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>よ&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}> Sentence particle (assertion)&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.Characteristics.LinguisticAspects.Particles}>1.2.e</Link>,
        <Link to={Page.BasicGrammar.SentenceEndings.Grammar.SentenceParticles}>3.1b.2.1</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜てえな&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Vてえな&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Vてね／よ&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Casual request (stronger)&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.VerbTeForm.Grammar.CasualRequest}>3.2.2.4</Link>,
        <Link to={Page.FunctionalGrammar.Imperatives.Grammar.Imperatives}>4.3.2.1</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜てえや&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Vてえや&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Vてね／よ&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Casual request (stronger)&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.VerbTeForm.Grammar.CasualRequest}>3.2.2.4</Link>, <Link to={Page.FunctionalGrammar.Imperatives.Grammar.Imperatives}>4.3.2.1</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜てくれ（へ）ん（か）&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Vてくれ（へ）ん（か）&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Vてくれない？&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Casual request (favor) “Will you do..for me?”&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.VerbTeForm.Grammar.CasualRequest}>3.2.2.4</Link>, <Link to={Page.FunctionalGrammar.Imperatives.Grammar.Imperatives}>4.3.2.1</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜でっせ&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Nでっせ&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Nですよ&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>です+ え (traditional, Osaka)&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.Characteristics.LinguisticAspects.SpeechStyle}>1.2.f</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜てです&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Vてです&nbsp;&nbsp;&nbsp;&nbsp;</td>
      <td style={{verticalAlign: "top"}}>Vていらっしゃいます&nbsp;&nbsp;&nbsp;
&nbsp;</td>
      <td style={{verticalAlign: "top"}}>On-going action, state, habit<br />“~is doing / have been doing…” (Honorifics, Kobe)&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.VerbTeForm.Grammar.TeFunctions}>3.2.2.3</Link>, <Link to={Page.FunctionalGrammar.Honorifics.Grammar.Honorifics}>4.4.2.1</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜てな（あ）&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Vてな（あ）&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Vてね／よ&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Casual request&nbsp;&nbsp;&nbsp;
      <br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.BasicGrammar.VerbTeForm.Grammar.CasualRequest}>3.2.2.4</Link>, <Link to={Page.FunctionalGrammar.Imperatives.Grammar.Imperatives}>4.3.2.1</Link></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜てはる&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Vてはる&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>-&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Light honorific form of Vている (Osaka)&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.FunctionalGrammar.Honorifics.Grammar.Honorifics}>4.4.2.1</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜てまう&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Vてまう&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Vてしまう&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>"end up ~ing "&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.VerbTeForm.Grammar.TeContraction}>3.2.2.2</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜てもええ&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Vてもええ&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Vてもいい&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Permission “ You may…”&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.FunctionalGrammar.PermissionProhibition.Grammar.Permission}>4.1.2.2</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜てもかまへん／かめへん&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Vてもかまへん／かめへん&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Vてもかまわない&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Permission "You may…”&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.FunctionalGrammar.PermissionProhibition.Grammar.Permission}>4.1.2.2</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜てもた&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Vてもた&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Vてしまった&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>&nbsp;"ended up ~ing"&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.VerbTeForm.Grammar.TeContraction}>3.2.2.2</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜てや（あ）&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Vてや（あ）&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Vてね／よ&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Casual request&nbsp;&nbsp;&nbsp;
      <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.VerbTeForm.Grammar.CasualRequest}>3.2.2.4</Link>, <Link to={Page.FunctionalGrammar.Imperatives.Grammar.Imperatives}>4.3.2.1</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜てらる&nbsp;&nbsp;&nbsp; <br />
      <br />
      </td>
      <td style={{verticalAlign: "top"}}>Vてらる&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>-&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>＝Vてはる，らる= Light honorific auxiliary Verb (traditional, Shiga)&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.FunctionalGrammar.Honorifics.Grammar.Honorifics}>4.4.2.1</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜てん&nbsp;&nbsp;&nbsp; <br />
      <br />
      </td>
      <td style={{verticalAlign: "top"}}>Vてん&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Vたの 、Vたんだ&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>“It is that [past]”&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.SentenceEndings.Grammar.ExtendedPredicate}>3.1b.2.2</Link>,
        <Link to={Page.BasicGrammar.VerbTeForm.Grammar.TeFunctions}>3.2.2.3</Link>,
        <Link to={Page.BasicGrammar.Copula.Grammar.Conjugation}>3.3.2.1</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜てんか（あ） &nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Vてんか（あ）&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Vてくれない？&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Casual request (favor) “Will you do … for me?”&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.VerbTeForm.Grammar.CasualRequest}>3.2.2.4</Link>, <Link to={Page.FunctionalGrammar.Imperatives.Grammar.Imperatives}>4.3.2.1</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜でんな&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Nでんな&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Nですね&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>です+ な (Osaka)&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.Characteristics.LinguisticAspects.SpeechStyle}>1.2.f</Link>
      </td>
    </tr>
    <tr>
      <td colSpan="1" rowSpan="12" style={{verticalAlign: "top"}}>と<br />
      </td>
      <td style={{verticalAlign: "top"}}>〜とう／とお&nbsp;&nbsp;&nbsp; <br />
      <br />
      </td>
      <td style={{verticalAlign: "top"}}>V(てform stem)とう／とお&nbsp;&nbsp;
&nbsp;&nbsp;</td>
      <td style={{verticalAlign: "top"}}>Vている&nbsp;&nbsp;&nbsp;&nbsp;</td>
      <td style={{verticalAlign: "top"}}>On-going action, state, habit<br />
        “~is doing / have been doing…”&nbsp; (blunt, Kobe)&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.VerbTeForm.Grammar.TeFunctions}>3.2.2.3</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜とくれやす&nbsp;&nbsp;&nbsp; <br />
      <br />
      </td>
      <td style={{verticalAlign: "top"}}>V(てform stem)とくれやす&nbsp;&nbsp;
&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Vてください&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Polite request (traditional, Kyoto)&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.Characteristics.LinguisticAspects.SpeechStyle}>1.2.f</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜とくんなはれ&nbsp;&nbsp;&nbsp;&nbsp;</td>
      <td style={{verticalAlign: "top"}}>V(てform stem)とくんなはれ&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Vてください&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Polite request (traditional, Osaka) &nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.Characteristics.LinguisticAspects.SpeechStyle}>1.2.f</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜どす&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Nどす&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Nです&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Polite non-past affirmative form of Copula (traditional, Kyoto)<br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.Characteristics.LinguisticAspects.SpeechStyle}>1.2.f</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜どすえ&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Nどすえ&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Nですよ&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>どす+ え(traditional, Kyoto)<br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.Characteristics.LinguisticAspects.SpeechStyle}>1.2.f</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜どすな&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Nどすな&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Nですね&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>どす+ な (traditional, Kyoto)<br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.Characteristics.LinguisticAspects.SpeechStyle}>1.2.f</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜どっせ&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Nどっせ&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Nですよ&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>どす+ え (traditional, Kyoto)<br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.Characteristics.LinguisticAspects.SpeechStyle}>1.2.f</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜とない&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>V(stem)とない&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>V(stem)たくない&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>"I don’t want to … "&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.Adjectives.Grammar.Negative}>3.4.2.2</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜とる&nbsp;&nbsp;&nbsp;&nbsp;</td>
      <td style={{verticalAlign: "top"}}>V(てform stem)とる&nbsp;&nbsp;&nbsp;&nbsp;</td>
      <td style={{verticalAlign: "top"}}>Vている&nbsp;&nbsp;&nbsp;&nbsp;</td>
      <td style={{verticalAlign: "top"}}>On-going action, state, habit<br />
        “~is doing / have been doing…”&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.VerbTeForm.Grammar.TeContraction}>3.2.2.2</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜とる&nbsp;&nbsp;&nbsp;&nbsp;<br />
      </td>
      <td style={{verticalAlign: "top"}}>V(てform stem)とる&nbsp;&nbsp;&nbsp;&nbsp;</td>
      <td style={{verticalAlign: "top"}}>Vておる&nbsp;&nbsp;&nbsp;&nbsp;</td>
      <td style={{verticalAlign: "top"}}>On-going action, state, habit<br />
        “~is doing / have been doing…” (humble, pejoratives)&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.Characteristics.LinguisticAspects.Honorifics}>1.2.g</Link>,
        <Link to={Page.FunctionalGrammar.Honorifics.Grammar.Pejoratives}>4.4.2.2</Link>,
        <Link to={Page.BasicGrammar.VerbTeForm.Grammar.TeFunctions}>3.2.2.3</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜とん？&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>V(てform stem)とん？&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Vているの？&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Is it that ~ is doing… (Kobe)&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.VerbTeForm.Grammar.TeFunctions}>3.2.2.3</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜どんな&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Nどんな&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Nですね&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>どす+ な (Kyoto)<br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.Characteristics.LinguisticAspects.SpeechStyle}>1.2.f</Link>
      </td>
    </tr>
    <tr>
      <td colSpan="1" rowSpan="8" style={{verticalAlign: "top"}}>な<br />
      </td>
      <td style={{verticalAlign: "top"}}>〜な&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Sentence な&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>ね&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Sentence particle (conformation)&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.Characteristics.LinguisticAspects.Particles}>1.2.e</Link>,
        <Link to={Page.BasicGrammar.SentenceEndings.Grammar.SentenceParticles}>3.1b.2.1</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜な&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>V(stem) な&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>V(dic)な&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Negative imperatives “ Don’t…”&nbsp;&nbsp; (traditional) <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.FunctionalGrammar.Imperatives.Grammar.NegativeImperatives}>4.3.2.2</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜な&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>V(neg)な&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>V(neg)なければ&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>"Unless … "&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.FunctionalGrammar.NegativePermissionObligation.Grammar.Unless}>4.2.2.2</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜なあかん&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>V(neg)なあかん&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>V(neg)なければだめだ、V(neg)なくてはだめだ&nbsp;
      <br />
      </td>
      <td style={{verticalAlign: "top"}}>Obligation “ You must…”&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.FunctionalGrammar.NegativePermissionObligation.Grammar.Obligation}>4.2.2.3</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜ないかん&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>V(neg)ないかん&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>V(neg)なければだめだ、V(neg)なくてはだめだ&nbsp;
      </td>
      <td style={{verticalAlign: "top"}}>Obligation “ You must…”&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.FunctionalGrammar.NegativePermissionObligation.Grammar.Obligation}>4.2.2.3</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜ないな&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>V(stem)ないな&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>V(dic)な&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Negative imperatives “ Don’t…”&nbsp;&nbsp;&nbsp; (traditional)<br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.FunctionalGrammar.Imperatives.Grammar.NegativeImperatives}>4.3.2.2</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜なはれ&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>V(stem)なはれ&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>V(stem)なさい&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Polite command (traditional, Osaka) &nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.Characteristics.LinguisticAspects.SpeechStyle}>1.2.f</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜なんだ&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>V(neg)なんだ&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>V(neg)なかった&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Past negative form of Verb (traditional)&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.Verbs.Grammar.PastNegative}>3.1a.2.2</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>に<br />
      </td>
      <td style={{verticalAlign: "top"}}>〜にゃ／ねや／のや &nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Sentence にゃ ／ねや ／のや <br />
      </td>
      <td style={{verticalAlign: "top"}}>〜の、〜んだ &nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Extended predicate “It is that…”&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.SentenceEndings.Grammar.ExtendedPredicate}>3.1b.2.2</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>ね<br />
      </td>
      <td style={{verticalAlign: "top"}}>〜ねん&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Sentence ねん&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>〜の、〜んだ&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Extended predicate “It is that…”&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.SentenceEndings.Grammar.ExtendedPredicate}>3.1b.2.2</Link>
      </td>
    </tr>
    <tr>
      <td colSpan="1" rowSpan="4" style={{verticalAlign: "top"}}>の<br />
      </td>
      <td style={{verticalAlign: "top"}}>〜の／ん？&nbsp;&nbsp;&nbsp; <br />
      <br />
      </td>
      <td style={{verticalAlign: "top"}}>Sentence の／ん？&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>〜の？&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Extended predicate (question) “Is it that…?”&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.SentenceEndings.Grammar.ExtendedPredicate}>3.1b.2.2</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜のう&nbsp;&nbsp;&nbsp; <br />
      <br />
      </td>
      <td style={{verticalAlign: "top"}}>Sentence のう&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>ね&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Sentence particle (conformation, blunt)&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.Characteristics.LinguisticAspects.Particles}>1.2.e</Link>,
        <Link to={Page.BasicGrammar.SentenceEndings.Grammar.SentenceParticles}>3.1b.2.1</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜のん&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Sentence のん&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>の&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Nominalizer&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.SentenceEndings.Grammar.ExtendedPredicate}>3.1b.2.2</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜のん？&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Sentence のん？&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}> 〜の？&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Extended predicate (question) “Is it that…?”&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.SentenceEndings.Grammar.ExtendedPredicate}>3.1b.2.2</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>は<br />
      </td>
      <td style={{verticalAlign: "top"}}>〜はる&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>V(stem)はる&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>-&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Light honorific auxiliary Verb
&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.Characteristics.LinguisticAspects.Honorifics}>1.2.g</Link>,
        <Link to={Page.FunctionalGrammar.Honorifics.Grammar.Honorifics}>4.4.2.1</Link>
      </td>
    </tr>
    <tr>
      <td colSpan="1" rowSpan="4" style={{verticalAlign: "top"}}>ひ<br />
      </td>
      <td style={{verticalAlign: "top"}}>〜ひん&nbsp;&nbsp;&nbsp; <br />
      <br />
      </td>
      <td style={{verticalAlign: "top"}}>V(neg)ひん&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}> V(neg) ない&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Non-past negative form of Verb
&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.Characteristics.LinguisticAspects.Conjugation}>1.2.d</Link>,
        <Link to={Page.BasicGrammar.Verbs.Grammar.NonPastNegative}>3.1a.2.1</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜ひんかった&nbsp;&nbsp;&nbsp; <br />
      <br />
      </td>
      <td style={{verticalAlign: "top"}}>V(neg)ひんかった&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>V(neg)なかった&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Past negative form of Verb
&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.Characteristics.LinguisticAspects.Conjugation}>1.2.d</Link>,
        <Link to={Page.BasicGrammar.Verbs.Grammar.PastNegative}>3.1a.2.2</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜ひんかって<br />
      </td>
      <td style={{verticalAlign: "top"}}>V(neg)ひんかって<br />
      </td>
      <td style={{verticalAlign: "top"}}>-<br />
      </td>
      <td style={{verticalAlign: "top"}}>Past negative gerund (kansai only)<br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.VerbTeForm.Grammar.NegativeRequest}>3.2.2.5</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜ひんた<br />
      </td>
      <td style={{verticalAlign: "top"}}>V(neg)ひんた</td>
      <td style={{verticalAlign: "top"}}>V(neg)なかった <br />
      </td>
      <td style={{verticalAlign: "top"}}>Past negative form of Verb </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.Verbs.Grammar.PastNegative}>3.1a.2.2</Link>
      </td>
    </tr>
    <tr>
      <td colSpan="1" rowSpan="4" style={{verticalAlign: "top"}}>へ<br />
      </td>
      <td style={{verticalAlign: "top"}}>〜へん&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>V(neg)へん&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>V(neg) ない&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Non-past negative form of Verb
&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.Characteristics.LinguisticAspects.Conjugation}>1.2.d</Link>,
        <Link to={Page.BasicGrammar.Verbs.Grammar.NonPastNegative}>3.1a.2.1</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜へんかった&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>V(neg)へんかった&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>V(neg)なかった&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Past negative form of Verb
&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.Characteristics.LinguisticAspects.Conjugation}>1.2.d</Link>,
        <Link to={Page.BasicGrammar.Verbs.Grammar.PastNegative}>3.1a.2.2</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜へんかって<br />
      </td>
      <td style={{verticalAlign: "top"}}>V(neg)へんかって<br />
      </td>
      <td style={{verticalAlign: "top"}}>-<br />
      </td>
      <td style={{verticalAlign: "top"}}>Past negative gerund (kansai only)<br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.VerbTeForm.Grammar.NegativeRequest}>3.2.2.5</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜へんた&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>V(neg)へんた&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>V(neg)なかった&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Past negative form of Verb
&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.Verbs.Grammar.PastNegative}>3.1a.2.2</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>ま<br />
      </td>
      <td style={{verticalAlign: "top"}}>〜まへん&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>V(stem)まへん&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>V(stem)ません&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>&nbsp;Polite non-mast negative form of Verb (traditional)&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.Characteristics.LinguisticAspects.SpeechStyle}>1.2.f</Link>,
        <Link to={Page.BasicGrammar.Verbs.Grammar.NonPastNegative}>3.1a.2.1</Link>
      </td>
    </tr>
    <tr>
      <td colSpan="1" rowSpan="14" style={{verticalAlign: "top"}}>や<br />
      </td>
      <td style={{verticalAlign: "top"}}>〜や&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Nや&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Nだ&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Non-past affirmative form of Copula&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.Characteristics.LinguisticAspects.Conjugation}>1.2.d</Link>,
        <Link to={Page.BasicGrammar.Copula.Grammar.Conjugation}>3.3.2.1</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜やあらへん&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Nやあらへん&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Nじゃない&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Non-past negative form of Copula&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.Characteristics.LinguisticAspects.Conjugation}>1.2.d</Link>,
        <Link to={Page.BasicGrammar.Copula.Grammar.Conjugation}>3.3.2.1</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜やあらへんかった&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Nやあらへんかった&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Nじゃなかった&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Past negative form of Copula&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.Characteristics.LinguisticAspects.Conjugation}>1.2.d</Link>,
        <Link to={Page.BasicGrammar.Copula.Grammar.Conjugation}>3.3.2.1</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜やす&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>V(stem)やす&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>V(stem)なさい&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Polite command (traditional, Kyoto)<br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.Characteristics.LinguisticAspects.SpeechStyle}>1.2.f</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜やった&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Nやった&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Nだった&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Past affirmative form of Copula&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.Characteristics.LinguisticAspects.Conjugation}>1.2.d</Link>,
        <Link to={Page.BasicGrammar.Copula.Grammar.Conjugation}>3.3.2.1</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜やったら&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Nやったら&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Nだったら&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Conditional form of Copula&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.Characteristics.LinguisticAspects.Conjugation}>1.2.d</Link>,
        <Link to={Page.BasicGrammar.Copula.Grammar.Conjugation}>3.3.2.1</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜やって&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Nやって&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>-&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Past gerund (てform) of Adj. (Kansai only) &nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.Characteristics.LinguisticAspects.Conjugation}>1.2.d</Link>,
        <Link to={Page.BasicGrammar.Copula.Grammar.Conjugation}>3.3.2.1</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜やない&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Nやない&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Nじゃない&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Non-past negative form of Copula&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.Characteristics.LinguisticAspects.Conjugation}>1.2.d</Link>,
        <Link to={Page.BasicGrammar.Copula.Grammar.Conjugation}>3.3.2.1</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜やなかった&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Nやなかった&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Nじゃなかった&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Past negative form of Copula&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.Characteristics.LinguisticAspects.Conjugation}>1.2.d</Link>,
        <Link to={Page.BasicGrammar.Copula.Grammar.Conjugation}>3.3.2.1</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜やはる／やある&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>V(stem)やはる／やある &nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>-&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Light honorific auxiliary Verb (Kansai only) &nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.Characteristics.LinguisticAspects.Honorifics}>1.2.g</Link>,
        <Link to={Page.FunctionalGrammar.Honorifics.Grammar.Honorifics}>4.4.2.1</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜やろ&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Nやろ&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Nだろう&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Tentative form of Copula&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.Characteristics.LinguisticAspects.Conjugation}>1.2.d</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜やん&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Sentence やん<br />
      </td>
      <td style={{verticalAlign: "top"}}>〜じゃない&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>"Surely you agree that … " <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.SentenceEndings.Grammar.Yanka}>3.1b.2.3</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜やんか&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Sentence やんか&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>〜じゃない&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>"Surely you agree that … "<br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.SentenceEndings.Grammar.Yanka}>3.1b.2.3</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜やんかいさ&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Sentence やんかいさ&nbsp;&nbsp;&nbsp;
      <br />
      </td>
      <td style={{verticalAlign: "top"}}>〜じゃない&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>"Surely you agree that … "&nbsp;
      <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.SentenceEndings.Grammar.Yanka}>3.1b.2.3</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>ゆ<br />
      </td>
      <td style={{verticalAlign: "top"}}>〜ゆう&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>N/sentenceゆう&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>〜と言</td>
      <td style={{verticalAlign: "top"}}>"... is called N" " someone says ... " (quotation)&nbsp; </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.SentenceEndings.Grammar.QuotationMarker}>3.1b.2.4</Link>
      </td>
    </tr>
    <tr>
      <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>よ<br />
      </td>
      <td style={{verticalAlign: "top"}}>〜よし&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}> V(stem)よし&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>V( stem)なさい&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Imperatives (traditional, gentle, Kyoto)&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.FunctionalGrammar.Imperatives.Grammar.Imperatives}>4.3.2.1</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜よる&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>V(stem)よる&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>-&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Light pejorative auxiliary Verb
&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.Characteristics.LinguisticAspects.Honorifics}>1.2.g</Link>,
        <Link to={Page.FunctionalGrammar.Honorifics.Grammar.ThirdPersonAction}>4.4.2.3</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>わ<br />
      </td>
      <td style={{verticalAlign: "top"}}>〜わ&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Sentence わ&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>よ&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Sentence particle (light assertion)&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.Characteristics.LinguisticAspects.Particles}>1.2.e</Link>,
        <Link to={Page.BasicGrammar.SentenceEndings.Grammar.SentenceParticles}>3.1b.2.1</Link>
      </td>
    </tr>
    <tr>
      <td colSpan="1" rowSpan="15" style={{verticalAlign: "top"}}>ん<br />
      </td>
      <td style={{verticalAlign: "top"}}>〜ん&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Sentence ん&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>の&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Nominalizer&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.SentenceEndings.Grammar.ExtendedPredicate}>3.1b.2.2</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜ん&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>V(neg)ん&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>V(neg)ない&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Non-past negative form of Verb (short)&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.Characteristics.LinguisticAspects.Conjugation}>1.2.d</Link>,
        <Link to={Page.BasicGrammar.Verbs.Grammar.NonPastNegative}>3.1a.2.1</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜んかった&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>V(neg)んかった&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>V(neg)なかった&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Past negative form of Verb (short)&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.Characteristics.LinguisticAspects.Conjugation}>1.2.d</Link>,
        <Link to={Page.BasicGrammar.Verbs.Grammar.NonPastNegative}>3.1a.2.2</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜んかって&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>V(neg)んかって&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>- <br />
      </td>
      <td style={{verticalAlign: "top"}}>Past negative gerund&nbsp; (Kansai only)&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.VerbTeForm.Grammar.NegativeRequest}>3.2.2.5</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜んことには&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>V(neg)んことには&nbsp;&nbsp;&nbsp; </td>
      <td style={{verticalAlign: "top"}}>V(neg)なくては／なければ／ないと <br />
      </td>
      <td style={{verticalAlign: "top"}}>"Unless …" (Traditional)&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.FunctionalGrammar.NegativePermissionObligation.Grammar.Unless}>4.2.2.2</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜んと&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>V(neg)んと&nbsp;&nbsp;&nbsp;&nbsp;</td>
      <td style={{verticalAlign: "top"}}>V(neg)ないと／V(neg)ないで&nbsp;&nbsp;&nbsp;&nbsp;</td>
      <td style={{verticalAlign: "top"}}>1) "Without ~ing"<br />
        2) "Unless…"<br />
        3) Command<br />
        4) Negative command (gentle) "Don’t …" &nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.FunctionalGrammar.NegativePermissionObligation.Grammar.Unless}>4.2.2.2</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜んと&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>V(neg)んと&nbsp;&nbsp;&nbsp;&nbsp;</td>
      <td style={{verticalAlign: "top"}}>V(neg)ないで&nbsp;&nbsp;&nbsp;&nbsp;</td>
      <td style={{verticalAlign: "top"}}>1)"Without ~ing"<br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.VerbTeForm.Grammar.NegativeRequest}>3.2.2.5</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜んと&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>V(neg)んと&nbsp;&nbsp;&nbsp;&nbsp;</td>
      <td style={{verticalAlign: "top"}}>V(neg)なくては／なければ／ないと <br />
      </td>
      <td style={{verticalAlign: "top"}}>2) "Unless …&nbsp; " <br />
      <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.FunctionalGrammar.NegativePermissionObligation.Grammar.Unless}>4.2.2.2</Link><br />
      <br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜んと&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>V(neg)んと&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>(V(stem)なさい)</td>
      <td style={{verticalAlign: "top"}}>3) Command “Do…!”</td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.FunctionalGrammar.NegativePermissionObligation.Grammar.Unless}>4.2.2.2</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜んと&nbsp;&nbsp;&nbsp; <br />
      <br />
      </td>
      <td style={{verticalAlign: "top"}}>V(neg)んと&nbsp;&nbsp;&nbsp; </td>
      <td style={{verticalAlign: "top"}}>(V(neg)ないで)&nbsp;&nbsp;&nbsp;
&nbsp;&nbsp;</td>
      <td style={{verticalAlign: "top"}}>4)Negative command (gentle)
"Don’t …"&nbsp;<br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.FunctionalGrammar.PermissionProhibition.Grammar.Prohibition}>4.1.2.1</Link>,
        <Link to={Page.FunctionalGrammar.Imperatives.Grammar.NegativeImperatives}>4.3.2.2</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜んとあかん&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>V(neg)んとあかん&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>V(neg)ないとだめだ&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Obligation "You must…" &nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.FunctionalGrammar.NegativePermissionObligation.Grammar.Obligation}>4.2.2.3</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜んといかん&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>V(neg)んといかん&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>V(neg)ないといけない&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Obligation "You must…"
&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.FunctionalGrammar.NegativePermissionObligation.Grammar.Obligation}>4.2.2.3</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜んといて&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>V(neg)んといて&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>V(neg)ないでおいて／V(neg)ないでいて&nbsp;
&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Casual negative request “Please don’t …”&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.VerbTeForm.Grammar.NegativeRequest}>3.2.2.5</Link>, <Link to={Page.FunctionalGrammar.PermissionProhibition.Grammar.Prohibition}>4.1.2.1</Link>,
        <Link to={Page.FunctionalGrammar.Imperatives.Grammar.NegativeImperatives}>4.3.2.2</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜んとき&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>V(neg)んとき&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>V(neg)ないで／V(dic)な&nbsp;&nbsp;
&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Negative imperatives (gentle)&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.FunctionalGrammar.Imperatives.Grammar.NegativeImperatives}>4.3.2.2</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜んならん&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>V(neg)んならん&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>V (neg)なければならない&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Obligation for oneself&nbsp; "I must …" &nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.FunctionalGrammar.NegativePermissionObligation.Grammar.Obligation}>4.2.2.3</Link>
      </td>
    </tr>
  </tbody>
</table>
<br />
<h2>Kansai Grammar Index (English)</h2>
<table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
  <tbody>
    <tr>
      <td style={{verticalAlign: "top"}}><br />
      </td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>Kansai Grammar <br />
      </td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>Where to find<br />
      </td>
    </tr>
    <tr>
      <td colSpan="1" rowSpan="6" style={{verticalAlign: "top"}}>A<br />
      </td>
      <td style={{verticalAlign: "top"}}>Adjectives<br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.Adjectives.Adjectives}>3.4</Link><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>Adjectives (adverbial from)<br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.Adjectives.Grammar.Adverbs}>3.4.2.3</Link><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>Adjectives (Euphonic change in adverbial form)<br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.Characteristics.LinguisticAspects.Conjugation}>1.2.d</Link>,
        <Link to={Page.BasicGrammar.Adjectives.Grammar.Adverbs}>3.4.2.3</Link><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>Adjectives (Euphonic change in Gerund)<br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.Characteristics.LinguisticAspects.Conjugation}>1.2.d</Link>,
        <Link to={Page.BasicGrammar.Adjectives.Grammar.Negative}>3.4.2.2</Link><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>Adjectives (negative form)<br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.Adjectives.Grammar.Negative}>3.4.2.2</Link><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>Adjectives (て form=Gerund)</td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.Adjectives.Grammar.TeForm}>3.4.2.4</Link>
      </td>
    </tr>
    <tr>
      <td colSpan="1" rowSpan="5" style={{verticalAlign: "top"}}>C<br />
      </td>
      <td style={{verticalAlign: "top"}}>Causatives<br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.FunctionalGrammar.PermissionProhibition.Conversations}>4.1.1</Link><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>Command <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.FunctionalGrammar.NegativePermissionObligation.Grammar.Unless}>4.2.2.2</Link>,
        <Link to={Page.FunctionalGrammar.Imperatives.Grammar.Imperatives}>4.3.2.1</Link><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>Command (negative)<br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.FunctionalGrammar.PermissionProhibition.Grammar.Prohibition}>4.1.2.1</Link>,
        <Link to={Page.FunctionalGrammar.Imperatives.Grammar.NegativeImperatives}>4.3.2.2</Link><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>Consultative (Let’s form)<br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.Characteristics.LinguisticAspects.Conjugation}>1.2.d</Link><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>Copula<br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.Copula.Copula}>3.3</Link><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>D<br />
      </td>
      <td style={{verticalAlign: "top"}}>Describe third person action (〜やる)<br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.FunctionalGrammar.Honorifics.Grammar.ThirdPersonAction}>4.4.2.3</Link><br />
      </td>
    </tr>
    <tr>
      <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>E<br />
      </td>
      <td style={{verticalAlign: "top"}}>“Even” “Even if”（〜かて）<br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.FunctionalGrammar.Even.Even}>4.5</Link><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>Extended predicates</td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.SentenceEndings.Grammar.ExtendedPredicate}>3.1b.2.2</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>G<br />
      </td>
      <td style={{verticalAlign: "top"}}>Gion Dialect {addRubyText("祇園言葉","ぎおんことば")}(Traditional Kyoto dialect)<br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.Characteristics.LinguisticAspects.SpeechStyle}>1.2.f</Link><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>H<br />
      </td>
      <td style={{verticalAlign: "top"}}>Honorifics<br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.Characteristics.LinguisticAspects.Honorifics}>1.2.g</Link>,
        <Link to={Page.FunctionalGrammar.Honorifics.Grammar.Honorifics}>4.4.2.1</Link><br />
      </td>
    </tr>
    <tr>
      <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>I<br />
      </td>
      <td style={{verticalAlign: "top"}}>Imperatives<br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.Characteristics.LinguisticAspects.Conjugation}>1.2.d</Link>,
        <Link to={Page.FunctionalGrammar.Imperatives.Grammar.Imperatives}>4.3.2.1</Link><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>Imperatives (negative)<br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.FunctionalGrammar.Imperatives.Grammar.NegativeImperatives}>4.3.2.2</Link><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>O<br />
      </td>
      <td style={{verticalAlign: "top"}}>Obligation<br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.FunctionalGrammar.NegativePermissionObligation.Grammar.Obligation}>4.2.2.3</Link><br />
      </td>
    </tr>
    <tr>
      <td colSpan="1" rowSpan="4" style={{verticalAlign: "top"}}>P<br />
      </td>
      <td style={{verticalAlign: "top"}}>Pejoratives<br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.Characteristics.LinguisticAspects.Honorifics}>1.2.g</Link>,
        <Link to={Page.FunctionalGrammar.Honorifics.Grammar.Pejoratives}>4.4.2.2</Link><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>Permission<br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.FunctionalGrammar.PermissionProhibition.Grammar.Permission}>4.1.2.2</Link><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>Permission (negative)<br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.FunctionalGrammar.NegativePermissionObligation.Grammar.NegativePermission}>4.2.2.1</Link><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>Prohibition</td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.FunctionalGrammar.PermissionProhibition.Grammar.Prohibition}>4.1.2.1</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>Q<br />
      </td>
      <td style={{verticalAlign: "top"}}>Quotation marker<br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.SentenceEndings.Grammar.QuotationMarker}>3.1b.2.4</Link><br />
      </td>
    </tr>
    <tr>
      <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>R<br />
      </td>
      <td style={{verticalAlign: "top"}}>Request<br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.VerbTeForm.Grammar.CasualRequest}>3.2.2.4</Link>, <Link to={Page.FunctionalGrammar.Imperatives.Grammar.Imperatives}>4.3.2.1</Link><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>Request (negative)<br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.VerbTeForm.Grammar.NegativeRequest}>3.2.2.5</Link>, <Link to={Page.FunctionalGrammar.PermissionProhibition.Grammar.Prohibition}>4.1.2.1</Link>,
        <Link to={Page.FunctionalGrammar.Imperatives.Grammar.NegativeImperatives}>4.3.2.2</Link><br />
      </td>
    </tr>
    <tr>
      <td colSpan="1" rowSpan="3" style={{verticalAlign: "top"}}>S<br />
      </td>
      <td style={{verticalAlign: "top"}}>Senba Dialect ({addRubyText("船場言葉","せんばことば")}Traditional Osaka dialect)<br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.Characteristics.LinguisticAspects.SpeechStyle}>1.2.f</Link><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>Sentence Endings<br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.SentenceEndings.SentenceEndings}>3.1b</Link><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>Sentence particles<br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.Characteristics.LinguisticAspects.Particles}>1.2.e</Link>,
        <Link to={Page.BasicGrammar.SentenceEndings.Grammar.SentenceParticles}>3.1b.2.1</Link><br />
      </td>
    </tr>
    <tr>
      <td colSpan="1" rowSpan="7" style={{verticalAlign: "top"}}>V<br />
      </td>
      <td style={{verticalAlign: "top"}}>Verbs<br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.Verbs.Verbs}>3.1a</Link><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>Verbs (Euphonic change in Gerund)<br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.Characteristics.LinguisticAspects.Conjugation}>1.2.d</Link>,
        <Link to={Page.BasicGrammar.VerbTeForm.Grammar.TeFormation}>3.2.2.1</Link><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>Verbs (Euphonic change in た form)<br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.Characteristics.LinguisticAspects.Conjugation}>1.2.d</Link>,
        <Link to={Page.BasicGrammar.Verbs.Grammar.TaForm}>3.1a.2.3</Link><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>Verbs (negative form)<br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.Verbs.Grammar.NonPastNegative}>3.1a.2.1</Link>, <Link to={Page.BasicGrammar.Verbs.Grammar.PastNegative}>3.1a.2.2</Link><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>Verbs (short negative form)<br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.Verbs.Grammar.NonPastNegative}>3.1a.2.1</Link><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>Verbs (て form = Gerund)<br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.VerbTeForm.VerbTeForm}>3.2</Link><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>よう+ Verb negative from</td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.Verbs.Grammar.YouNegative}>3.1a.2.4</Link>
      </td>
    </tr>
  </tbody>
</table>

  </>;
}

export default GrammarIndex;
