import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";

const V9 = () => {
  const transcript = <>
    {addRubyText("外人","がいじん")}の{addRubyText("友達","ともだち")}がいるの。{addRubyText("高校生","こうこうせい")}の、いや、{addRubyText("日本人","にっぽんじん")}やで。{addRubyText("外国","がいこく")}に<span
    style={{textDecoration: "underline"}}>{addRubyText("住","す")}んだはる</span> (Grammar 1){addRubyText("人","ひと")}。<span
    style={{textDecoration: "underline"}}>いはる</span>の。あの、高校の友達で、あの、ね、えっと、サンゼエ
    ゴ。うん、{addRubyText("向","む")}こう{addRubyText("行","い")}って{addRubyText("長","なが")}いの。もう{addRubyText("旦那","だんな")}さんも、アメリカ{addRubyText("人","じん")}。うん、サンゼエゴに<span
    style={{textDecoration: "underline"}}>やはって</span>。せやけど、すんごくアメリカ{addRubyText("的","てき")}な{addRubyText("生活","せいかつ")}<span style={{textDecoration: "underline"}}>してはる</span>なあ、と{addRubyText("思","おも")}う。ふ
    ん。ほんでも、{addRubyText("手紙","てがみ")}{addRubyText("来","く")}んのは、あの、{addRubyText("単語","たんご")}で{addRubyText("英語","えいご")}と、ほんで{addRubyText("日本語","にほんご")}が、で、ダーッと{addRubyText("書","か")}
    いたるけど、日本語の手紙なんか、もう〜カタカナひらがな、いろいろ{addRubyText("混","ま")}じりの。せやけど{addRubyText("普通","ふつう")}のしゃべり{addRubyText("方","かた")}で書いてくる。うん。
  </>;

  const qaObjects = [
    {
      question: <span>Q1:Who is her foreign friend?</span>,
      answersCorrect: [
        <>A Japanese from her high school living in San Diego, whose husband is American</>
      ],
      answersIncorrect: [
        <>A high school student from San Diego, who will get married with an American</>,
        <>A Japanese she met in San Diego, whose husband is American</>,
        <>A high school student, living in San Diego, who will get married with an American </>
      ]
    },
    {
      question: <span>Q2: How are the letters from her friend written? Choose all that apply.</span>,
      answersCorrect: [
        <>In Japanese written in spoken style</>,
        <>English words are thrown in</>,
        <>Hiragana &amp; Katakana are mixed</>
      ],
      answersIncorrect: [
        <>In polite Japanese</>,
        <>No English words are thrown in</>,
        <>Hiragana &amp; Katakana are properly used </>
      ]
    }
  ];

  return <>
    <h2>ビデオ{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}９: Authentic - long<br />
    </h2>
    Watch the video clip and choose the answer that matches the content of
    the clip.
    Submit your answers after answering all the questions.<br />
    <small>*Click "SHOW" if you need to refer to the transcript
    </small><br />
    Context: Ishioh-san was asked if she had
    any foreign acquaintances.<br />
    <table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>{addVideoPlayer("4.4.1.p.9.ishioh.mp4")} <br />
          </td>
          <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>Transcript
    <Hideable content={transcript}/>
    <br />
          <br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>イシオウさん
    (Shiga)<br />
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    <QandAForm qaObjects={qaObjects}/>
  </>;
}

export default V9;
