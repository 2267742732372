import { Constants } from './constants';

var initialState = {
  interviewees: {
    isIntervieweesLoading: false,
    interviewees: []
  },
  interviewQuestions: {
    isCountLoading: false,
    isListLoading: false
  },
  interviewClips: {
    isCountLoading: false,
    isListLoading: false,
    list: []
  },
  interviewClipQandA: {
    isInterviewClipQandALoading: false,
    interviewClipQandA: []
  }
};

function mainReducer(state = initialState, action) {
  switch (action.type) {
    case Constants.CHANGE_INTERVIEWEES: 
      return {
        ...state,
        interviewees: {
          ...state.interviewees,
          ...action.data
        }
      }
    case Constants.CHANGE_INTERVIEW_QUESTION_COUNT:
      return {
        ...state,
        interviewQuestions: {
          ...state.interviewQuestions,
          ...action.data
        }
      };
    case Constants.CHANGE_INTERVIEW_QUESTION_LIST:
      return {
        ...state,
        interviewQuestions: {
          ...state.interviewQuestions,
          ...action.data
        }
      };
    case Constants.CHANGE_INTERVIEW_CLIP_COUNT:
      return {
        ...state,
        interviewClips: {
          ...state.interviewClips,
          ...action.data
        }
      };
      case Constants.CHANGE_INTERVIEW_CLIP_LIST:
        return {
          ...state,
          interviewClips: {
            ...state.interviewClips,
            ...action.data
          }
        };
      case Constants.CHANGE_INTERVIEW_CLIP_Q_AND_A:
        return {
          ...state,
          interviewClipQandA: {
            ...state.interviewClipQandA,
            ...action.data
          }
        }
    default:
      return state;
  }
}

export default mainReducer;
