import React from "react";

const ContactInformation = () => {
  return (
    <>
      <h2>Contact Information</h2>
      <br />

      <div>
        Please send all the comments and suggestions to:
      </div>
      <br />

      <div>
        Ikue Shingu (<a href="mailto:ikueshingu@gmail.com">ikueshingu@gmail.com</a>)
      </div>
      <div>
        Junko Hatanaka (<a href="mailto:Jhatanaka@austin.utexas.edu">Jhatanaka@austin.utexas.edu</a>)
      </div>
      <br />

      <div>
        ご意見ご質問等ございましたら、担当の新宮育枝または畑中淳子まで遠慮なくご連絡下さい。
      </div>
    </>
  );
};

export default ContactInformation;