import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";

const GrilledOyster = () => {
  const vocabList = <ul style={{display: "block", overflow: "visible"}}>
    <li>
      {addRubyText("帆立","ほたて")} scallop
    </li>
    <li>
      {addRubyText("天然","てんねん")}natural
    </li>
    <li>
      むっちゃ(Kansai) =とても、すごく
    </li>
    <li>
      でかい (Kansai, blunt) = すごく大きい
    </li>
    <li>
      {addRubyText("養殖","ようしょく")} farm raised
    </li>
    <li>
      サザエ turban shell, top shell
    </li>
    <li>
      {addRubyText("貝柱","かいばしら")} shell ligament ( of a scallop)
    </li>
    <li>
      {addRubyText("食","く")}うて行きよる (Kansai) = blunt / pejorative expression of 食べて行く(refer to Ch4.L4.G2)
    </li>
    <li>
      {addRubyText("食","く")}え (Kansai) “Eat!” (imperative, blunt expression, refer to Ch4.L3.G1)
    </li>
    <li>
      お{addRubyText("前","まえ")}ら blunt expression of あなた{addRubyText("達","たち")}
    </li>
    <li>
      {addRubyText("結構","けっこう")} quite
    </li>
    <li>
      {addRubyText("一口","ひとくち")} one bite
    </li>
    <li>
      {addRubyText("汁","しる")} juice, broth
    </li>
  </ul>;

  const qaObjects = [
    {
      question: <span>Q1: How much is the grilled oyster that トモちゃん ordered?</span>,
      answersIncorrect: [
        <span>81-yen.</span>,
        <span>108-yen.</span>,
        <span>810-yen.</span>
      ],
      answersCorrect: [
        <span>180-yen.</span>
      ]
    },
    {
      question: <span>Q2: Are the scallops they grill wild or farm raised?</span>,
      answersIncorrect: [
        <span>Farm raised.</span>
      ],
      answersCorrect: [
        <span>Wild.</span>
      ]
    },
    {
      question: <span>Q3: The video shooter was not familiar with the English equivalent of サザエ. Why?</span>,
      answersIncorrect: [
        <span>She hasn’t lived in America long enough to know the English equivalent of that kind of shell.</span>,
        <span>She sees them everywhere in the sea back in Boston (where she lives), but has never seen anybody eat them.</span>
      ],
      answersCorrect: [
        <span>She believes American do not eat them, since she has never seen them in back in Boston (where she lives).</span>
      ]
    },
    {
      question: <span>Q4: Do foreigners eat whole scallops, according to the fish dealer?</span>,
      answersIncorrect: [
        <span>Yes, when he tells them to try them. But they usually don’t like it.</span>,
        <span>There are some who never try even if he tells them to try.</span>,
        <span>No, they usually don’t try, even if he tells them.</span>,
        <span>Yes, when he tells them to try. But they praise only the ligament, not other parts.</span>
      ],
      answersCorrect: [
        <span>Yes, when he tells them to try them. Then they admit it’s delicious.</span>
      ]
    },
    {
      question: <span>Q5: The fish dealer murmured お{addRubyText("前","まえ")}らの{addRubyText("言","ゆ")}うことは分からん
              (I have no idea what you say!). To whom did he say it most likely?</span>,
      answersIncorrect: [
        <span>To トモちゃん and the video shooter.</span>,
        <span>To the Japanese customers who insisted he make sashimi for them.</span>
      ],
      answersCorrect: [
        <span>To the foreigners who tried talking to the dealer in a foreign language.</span>
      ]
    },
    {
      question: <span>Q6: Why did the video shooter say {addRubyText("結構","けっこう")}{addRubyText("時間","じかん")}かかる?</span>,
      answersIncorrect: [
        <span>She wanted the oyster to be cooked thoroughly so she wouldn’t get sick later.</span>,
        <span>She thought it might take too much time to grill the scallops.</span>
      ],
      answersCorrect: [
        <span>She did not expect that it would take such a long time to grill one oyster.</span>
      ]
    },
    {
      question: <span>Q7: What did トモちゃん say to the video shooter regarding the oyster that was being grilled?</span>,
      answersIncorrect: [
        <span>I will let you have one bite.</span>,
        <span>I will give you the rest if I cannot eat it in one bite.</span>
      ],
      answersCorrect: [
        <span>I suggest we share it.</span>
      ]
    }
  ];

  return (
    <>
      <h2>{addRubyText("焼","や")}き{addRubyText("牡蠣","がき")} (Grilled oyster at a fish shop in Kyoto)</h2>
      <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>
            [Context] トモちゃん decided to order a freshly-shucked-and-grilled oyster at the fish shop in Kyoto.
          </td>
        </tr>
        </tbody>
      </table>
      <br />
      <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addVideoPlayer("5.8.kaki2.mp4")}<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            Vocabulary List <br />
            <Hideable content={vocabList} />
          </td>
        </tr>
        </tbody>
      </table>
      <h3>{addRubyText("内容質問","ないようしつもん")} (Content Questions)</h3>
      <QandAForm qaObjects={qaObjects} />
    </>
  );
};

export default GrilledOyster;
