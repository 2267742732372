import React from "react";
import { addRubyText, addAudio } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";

const P4 = () => {
  const transcript = <>
    <ol className="transcript">
      <li>A: 朝ご飯食べてから、学校に行ったの？</li>
      <li>B: ううん、食べないで行ったんだ。</li>
    </ol>
  </>;
  return <>
    <h2>{addRubyText("練習","れんしゅう")}4 : Negative て form : 〜んと</h2>
    Form a question and an answer for each cue according to
    a model. You may listen to the question, try to answer it, and check
    your answer. *Answers vary; the recorded answers indicate those
    commonly used.
    <br />
    <small>*Mouse over or click on the speaker icon for sound</small>
    <br />
    <br />
    <span style={{fontWeight: "bold"}}>Model</span> 
    (Cue: {addRubyText("朝","あさ")}ご{addRubyText("飯","はん")}ー{addRubyText("食","た")}べる／{addRubyText("学校","がっこう")}ー{addRubyText("行","い")}く) 
    <br />
    &nbsp;&nbsp;&nbsp; A: 朝ご飯食べてから、学校に行ったん？
    <br />
    &nbsp;&nbsp;&nbsp; B: ううん、食べんと行ってん。{addAudio("3.2.p.4.mp3")}
    <br />
    <br />
    Point: 〜ないで "without ~ing" ー＞〜んと (refer to Grammar 5)
    <br />
    <br />
    ST transcript 
    <br />
    <Hideable content={transcript} />
    <br />
    <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Cue<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            A (Question)<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            B (Answer)<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addRubyText("朝","あさ")}ご{addRubyText("飯","はん")}ー{addRubyText("食","た")}べる／{addRubyText("学校","がっこう")}ー{addRubyText("行","い")}く<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.4.1.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.4.1.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addRubyText("新聞","しんぶん")}ー{addRubyText("読","よ")}む／{addRubyText("会社","かいしゃ")}ー{addRubyText("来","く")}る<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.4.2.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.4.2.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addRubyText("宿題","しゅくだい")}ーやる／{addRubyText("授業","じゅぎょう")}ー{addRubyText("出","で")}た
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.4.3.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.4.3.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addRubyText("花","はな")}ー{addRubyText("水","みず")}ーやる／{addRubyText("買","か")}い{addRubyText("物","もの")}ー{addRubyText("行","い")}く
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.4.4.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.4.4.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            お{addRubyText("風呂","ふろ")}ー{addRubyText("入","はい")}る／{addRubyText("寝","ね")}る 
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.4.5.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.4.5.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addRubyText("手","て")}ー{addRubyText("洗","あら")}う／{addRubyText("晩","ばん")}ご{addRubyText("飯","はん")}ー{addRubyText("食","た")}べる
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.4.6.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.4.6.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            テープー{addRubyText("聞","き")}く／テストー{addRubyText("受","う")}ける
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.4.7.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.4.7.b.mp3")}<br />
          </td>
        </tr>
      </tbody>
    </table>
  </>;
}

export default P4;
