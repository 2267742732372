import React from "react";
import { addRubyText, addAudio } from "common/util/generalUtil";

const NonPastNegative = () => {
  return <>
    <h2>Non-past Negative Form</h2>
    Basic structure of the negative verb in the Kansai dialect is
    V(negative-stem)+へん (e.g. {addRubyText("食","た")}べへん、{addRubyText("分","わ")}からへん、{addRubyText("飲","の")}まへん). 
    If the stem consists of one mora, it is usually lengthened as if it had two
    moras (e.g. {addRubyText("寝","ね")}る　→寝ぇへん, {addRubyText("来","く")}る　→{addRubyText("来","こ")}ぅへん). However, there
    are many varieties depending on the kind of verbs and also depending on
    the region. Note that nowadays standard type ない is also becoming
    common among young people influenced by the standard.
    <br />
    <br />
    Here is the summary of non-past negative forms.
    <br />
    <span style={{fontWeight: "bold"}}>&nbsp;&nbsp;&nbsp; </span>
    Comprehension: If the verb ends with へん／ひん／ん, it should be a non-past negative.
    <br />
    &nbsp;&nbsp;&nbsp; Production: If you want to form negative verb, try a commonly used one such as;&nbsp;&nbsp;&nbsp; 
    <br />
    <br />
    <small>
      *<span style={{fontWeight: "bold"}}>BOLD</span> type indicates high pitch
      <br />
      *Mouse over or click on the speaker icon for sound
    </small>
    <br />
    <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>
            る Verb<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            -E-RU -&gt;Stem+ へん （食<span style={{fontWeight: "bold"}}>べ</span>へん、
            <span style={{fontWeight: "bold"}}>寝</span>ぇへん）
            <br />
            -I-RU -&gt; Stem+ ひん（<span style={{fontWeight: "bold"}}>{addRubyText("借","か")}</span>りひん、
            <span style={{fontWeight: "bold"}}>{addRubyText("見","み")}</span>ぃひん）
            <br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>う Verb<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            -U-&gt; -A+ へん&nbsp; (あ<span style={{fontWeight: "bold"}}>ら</span>へん、{addRubyText("飲","の")}
            <span style={{fontWeight: "bold"}}>ま</span>へん、<span style={{fontWeight: "bold"}}></span>）
            <br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            Irregular Verb<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            するー＞<span style={{fontWeight: "bold"}}>し</span>ぃひん、<span style={{fontWeight: "bold"}}>せ</span>ぇへん、、
            <br />
            {addRubyText("来","く")}るー＞<span style={{fontWeight: "bold"}}>{addRubyText("来","き")}</span>ぃひん、
            <span style={{fontWeight: "bold"}}>{addRubyText("来","こ")}</span>ぅへん、、
            <br />
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    Detailed explanation will be provided below.
    <br />
    <br />
    <hr />
    <h4>る Verb (Vowel verbal, Class 2 Verb)<br /></h4>
    <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>
            <br />
          </td>
          <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>
            <div style={{textAlign: "center"}}>
              Standard Negative<br />
            </div>
            (listen all) {addAudio("3.1.a.1.1.s.mp3")}
            <br />
          </td>
          <td colSpan="6" rowSpan="1" style={{verticalAlign: "top", textAlign: "center"}}>
            Kansai Negative<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            (-e)-へん<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            (-i)-へん</td>
          <td style={{verticalAlign: "top"}}>
            (-i)-ひん<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            -やへん<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            -ん<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            (audio)<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            食べる</td>
          <td style={{verticalAlign: "top"}}>
            た<span style={{fontWeight: "bold"}}>べ</span>ない<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            た<span style={{fontWeight: "bold"}}>べ</span>へん<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <br />
          </td>
          <td style={{verticalAlign: "top"}}>
            たべん<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            {addAudio("3.1.a.1.1.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            寝る
          </td>
          <td style={{verticalAlign: "top"}}>
            &nbsp;ね<span style={{fontWeight: "bold"}}>ない</span><br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <span style={{fontWeight: "bold"}}>ね</span>ぇへん<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <span style={{fontWeight: "bold"}}>ね</span>やへん<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <span style={{fontWeight: "bold"}}>ねん</span><br />
          </td>
          <td style={{verticalAlign: "top"}}>
            {addAudio("3.1.a.1.2.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            借りる</td>
          <td style={{verticalAlign: "top"}}>
            か<span style={{fontWeight: "bold"}}>りない</span><br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <span style={{fontWeight: "bold", textDecoration: "underline"}}>か</span>
            <span style={{textDecoration: "underline"}}>れ</span>へん
            <br />
            (stem change)
            <br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <span style={{fontWeight: "bold"}}>か</span>りへん<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <span style={{fontWeight: "bold"}}>か</span>りひん<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <span style={{fontWeight: "bold"}}>かりん</span><br />
          </td>
          <td style={{verticalAlign: "top"}}>
            {addAudio("3.1.a.1.3.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            見る<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <span style={{fontWeight: "bold"}}>み</span>ない<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <span style={{textDecoration: "underline"}}><span style={{fontWeight: "bold"}}>め</span>ぇ</span>へん
            <br />
            (stem change)
            <br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <span style={{fontWeight: "bold"}}>み</span>ぃへん<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <span style={{fontWeight: "bold"}}>み</span>ぃひん<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <span style={{fontWeight: "bold"}}>み</span>やへん<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <span style={{fontWeight: "bold"}}>みん</span><br />
          </td>
          <td style={{verticalAlign: "top"}}>
            {addAudio("3.1.a.1.4.mp3")}<br />
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    As seen in above, if the dictionary form ends with 
    <span style={{fontStyle: "italitc"}}>–e-る</span>, then 
    <span style={{fontStyle: "italitc"}}>-e-へん</span> is
    the form most commonly used, and if it ends with 
    <span style={{fontStyle: "italitc"}}>–i-る</span>, then 
    <span style={{fontStyle: "italitc"}}>–i-ひん</span> is
    the most commonly used one in Kyoto/Shiga; however, 
    <span style={{fontStyle: "italitc"}}>-i-へん</span> and –e-へん
    (even the sound in the stem itself can change in Kansai-ben!) are also
    used in Osaka and some other regions. If the stem consists of one mora,
    make the stem sound longer or insert や after the stem. 
    <br />
    <br />
    <span style={{fontStyle: "italic", textDecoration: "underline"}}>The short
    negative form</span> that is formed by adding 
    <span style={{fontStyle: "italitc"}}>–ん</span> to the stem is also
    common. So you are confused?? Don’t worry. If you want to form the
    negative るVerb, stick to the most commonly used ones, such as 
    <span style={{fontStyle: "italitc"}}>–(e)-へん</span>
    and <span style={{fontStyle: "italitc"}}>–(i)-ひん</span>, and don't forget
    to lengthen the one-mora stem!
    <br />
    <br />
    <hr />
    <h4>うVerb (Consonant verbal, Class 1 verb) <br /> </h4>
    <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>
            <br />
          </td>
          <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>
            <div style={{textAlign: "center"}}>
              Standard Negative<br />
            </div>
            <div style={{textAlign: "center"}}>
              (listen all) {addAudio("3.1.a.1.5.s.mp3")}<br />
            </div>
          </td>
          <td colSpan="4" rowSpan="1" style={{verticalAlign: "top", textAlign: "center"}}>
            Kansai Negative<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            -(a)-へん<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            -(e)-へん<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            -(a)-ん<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "left"}}>
            (audio)<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            ある<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <span style={{fontWeight: "bold"}}>な</span>い<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            あ<span style={{fontWeight: "bold"}}>ら</span>へん<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            あ<span style={{fontWeight: "bold"}}>れ</span>へん<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            あらん<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            {addAudio("3.1.a.1.5.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            帰る<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            か<span style={{fontWeight: "bold"}}>えら</span>ない<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <span style={{fontWeight: "bold"}}>かえ</span>らへん<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <span style={{fontWeight: "bold"}}>かえ</span>れへん<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <span style={{fontWeight: "bold"}}>かえらん</span><br />
          </td>
          <td style={{verticalAlign: "top"}}>
            {addAudio("3.1.a.1.6.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            飲む<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            の<span style={{fontWeight: "bold"}}>ま</span>ない<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            の<span style={{fontWeight: "bold"}}>ま</span>へん<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            の<span style={{fontWeight: "bold"}}>め</span>へん<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            のまん<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            {addAudio("3.1.a.1.7.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addRubyText("吸","す")}う(smoke)<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            す<span style={{fontWeight: "bold"}}>わない</span><br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <span style={{fontWeight: "bold"}}>す</span>わへん<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <span style={{fontWeight: "bold"}}>す</span>えへん<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <span style={{fontWeight: "bold"}}>すわん</span><br />
          </td>
          <td style={{verticalAlign: "top"}}>
            {addAudio("3.1.a.1.8.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addRubyText("行","い")}く<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            い<span style={{fontWeight: "bold"}}>かない</span><br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <span style={{fontWeight: "bold"}}>い</span>かへん<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <span style={{fontWeight: "bold"}}>い</span>けへん<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <span style={{fontWeight: "bold"}}>いかん</span><br />
          </td>
          <td style={{verticalAlign: "top"}}>
            {addAudio("3.1.a.1.9.mp3")}<br />
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    The basic negative うVerb is formed by adding 
    <span style={{fontStyle: "italitc"}}>–へん</span> to the negative stem. So it is
    basically the same as Standard negative form and the only difference is
    replacing ない with へん. Short form ん is also common. 
    <br />
    <br />
    However, there is one more form especially common in Osaka, which is
    Stem<span style={{fontStyle: "italitc"}}>-e-へん</span>. Don’t you think
    this form is somehow familiar? Yes, it is the same as the Potential
    form of うVerb. Then, how can Osaka people distinguish simple negative
    form of うVerbs from those of potentials? Actually, they use “passive
    negative form” as potential negatives.
    <br />
    <br />
    これ、飲む？　<br />
    →　いや、飲めへん。(Osaka) {addAudio("3.1.a.1.10.mp3")}
    <br />
    →　いや、飲まへん。(Kyoto) {addAudio("3.1.a.1.11.mp3")}
    <br />
    <br />
    これ、飲める？
    <br />
    →　いや、<span style={{textDecoration: "underline"}}>飲</span>
    <span style={{textDecoration: "underline"}}>まれへん</span>。(Potential negative in
    Osaka) {addAudio("3.1.a.1.12.mp3")} <br />
    →　いや、飲めへん。(Potential negative in Kyoto) {addAudio("3.1.a.1.13.mp3")} 
    <br />
    <br />
    Now you want to know how to distinguish potential negative from passive
    negative in Osaka? Well, you have to rely on the context, of course.
    <br />
    <br />
    Please note that the negative form of ある is あらへん (how systematic!), and
    へん never stands alone unlike ない.
    <br />
    <br />
    <hr />
    <h4>Irregular Verb (Class 3 Verb)<br /></h4>
    <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>
            <br />
          </td>
          <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>
            <div style={{textAlign: "center"}}>
              Standard Negative<br />
            </div>
            <div style={{textAlign: "center"}}>
              (listen all) {addAudio("3.1.a.1.14.s.mp3")}<br />
            </div>
          </td>
          <td colSpan="6" rowSpan="1" style={{verticalAlign: "top", textAlign: "center"}}>
            Kansai Negative<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            -(e)-ぇへん<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            -(o)-ぅへん<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            -(i)-ぃひん<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            -(i)-やへん<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            -(e)-ん<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            (audio)<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            する<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            し<span style={{fontWeight: "bold"}}>ない</span><br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <span style={{fontWeight: "bold"}}>せ</span>ぇへん<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <span style={{fontWeight: "bold"}}>し</span>ぃひん<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <span style={{fontWeight: "bold"}}>し</span>やへん<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <span style={{fontWeight: "bold"}}>せん</span><br />
          </td>
          <td style={{verticalAlign: "top"}}>
            {addAudio("3.1.a.1.14.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addRubyText("来","く")}る<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <span style={{fontWeight: "bold"}}>こ</span>ない<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <span style={{fontWeight: "bold"}}>け</span>ぇへん<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <span style={{fontWeight: "bold"}}>こ</span>ぅへん<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <span style={{fontWeight: "bold"}}>き</span>ぃひん<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <span style={{fontWeight: "bold"}}>き</span>やへん<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <span style={{fontWeight: "bold"}}>こん</span><br />
          </td>
          <td style={{verticalAlign: "top"}}>
            {addAudio("3.1.a.1.15.mp3")}<br />
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    There are so many variety of forms used as a negative of する and
    {addRubyText("来","く")}る.&nbsp; It totally depends on each region and person which form
    is preferably used. In my region (Kyoto &amp; Shiga), しぃひん and {addRubyText("来","こ")}ぅへん
    seem most common but other forms are used as well. So, you can just
    pick whichever form you’d like when you want to say it. <br />
    <br />
    <hr />
    <h3>Extra 1: Polite negative form {addRubyText("飲","の")}みまへん</h3>
    Most Kansai people nowadays use 〜ません with Kansai accent as non-past
    negatives when speaking politely. However, Some traditional forms such
    as 〜まへん (e.g. 食べまへん) and [Negative-stem]+ しまへん (e.g.食べしまへん、帰らしまへん) are
    still used by elderly people.
  </>;
}

export default NonPastNegative;