import React from "react";
import { addRubyText, addImage } from "common/util/generalUtil";

const AnimeManga = () => {
  return (
    <>
      <h2>{addRubyText("関西弁","かんさいべん")}のアニメと{addRubyText("漫画","まんが")} Kansai-ben in Anime and Manga</h2>
      <table style={{textAlign: "center", width: "30%"}} border="0" cellSpacing="2" cellPadding="2" align="right">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>{addImage("7.anime.jpg")}</td>
        </tr>
        </tbody>
      </table>

      <p>
        There are quite a few anime and manga in which Kansai characters
        appear. If the story takes place in Kansai-region, there is no
        question as to why all the characters speak Kansai-ben. However,
        there are also some in which only one particular character speaks
        Kansai-ben. It may be because creators expect to set a laid-back,
        carefree, happy-go-lucky (and sly or sneaky) character in
        their story, since these qualities are considered typical of
        Kansai-jin. There is a case that Kansai non-native voice actors
        sometimes imitate Kansai-ben in anime. Such a fake dialect is called
        nise (or ese) kansai-ben. If you can distinguish nise kansai-ben
        from the authentic one, you are an honorary user of this site! Also,
        please note that Kansai characters tend to be given southern accents
        for their English voices.
      </p>

      <p>
        *We thank students from MIT and UTexas Austin who gave us
        information about Kansai Anime characters.
      </p>

      <p>
        (*The following list is based on our limited knowledge. If you have
        more information, please contact Shingu at <a
        href="mailto:ikueshingu@gmail.com">ikueshingu@gmail.com</a>. Your
        input would be greatly appreciated.)
      </p>

      <h3>関西弁アニメ Kansai Anime (with Kansai Characters)</h3>
      <p>*Most external links are their official sites.</p>
      <table style={{textAlign: "left", width: "100%"}} border="1" cellSpacing="2" cellPadding="2">
        <tbody>
        <tr>
          <td style={{textAlign: "center"}}>
            Title
          </td>
          <td style={{textAlign: "center"}}>
            Information
          </td>
        </tr>
        <tr>
          <td>
            <a href="http://www.futabasha.com/chie/">じゃりん{addRubyText("子","こ")}チエ</a>
            (Chie the Brat)
          </td>
          <td>
            It is about ten-year-old Chie, "the most unfortunate girl
            in Japan", who manages her family diner (Horumonyaki-ya) in
            Osaka while her unemployed yakuza father and her mother are
            separated. All the characters speak Kansai-ben. It is really
            funny. It is definitely a must-see classic.
          </td>
        </tr>
        <tr>
          <td>
            アベノ{addRubyText("橋","ばし")}{addRubyText("魔法","まほう")}☆{addRubyText("商店街","しょうてんがい")}
            (Magical Shopping Arcade Abenobashi)
          </td>
          <td>
            Childhood friends Arumi and Sasshi, living in the
            Abenobashi commercial district in Osaka, find themselves
            transported to an alternate sword and sorcery world after an
            accident. Arumi, Sasshi and all the characters in Abenobashi
            speak Kansai-ben in this anime.
          </td>
        </tr>
        <tr>
          <td>
            <a href="http://www.toei-anim.co.jp/tv/lovecom/">ラブ★コン</a>(Lovely Complex)
          </td>
          <td>
            A complicated love story between a 172 cm tall girl and a
            156 cm boy in a high school in Osaka. Their talk
            sounds so comical to others and classmates think of them as
            All Hanshin-Kyojin (a famous Yoshimoto manzai duo in Osaka -
            Hanshin is a small guy where as Kyojin is very tall).
          </td>
        </tr>
        <tr>
          <td>
            <a href="http://www.tv-tokyo.co.jp/anime/hikaru/">ヒカルの{addRubyText("碁","ご")} </a>(Hikaru no Go)

          </td>
          <td>
            社清春 (Kiyoharu Yashiro) as well as all other people in 関西棋院 (Kansai-Kiin) speak Kansai-ben.
          </td>
        </tr>
        <tr>
          <td>
            あずまんが{addRubyText("大王","だいおう")}(Azumanga Daioh)
          </td>
          <td>
            春日歩 (Ayumu “Osaka” Kasuga) is a Kansai-ben speaker.
          </td>
        </tr>
        <tr>
          <td>
            <a href="http://www.tbs.co.jp/anime/air/">AIR</a>
          </td>
          <td>
            神尾晴子 (Haruko Kamio) speaks Kansai-ben.
          </td>
        </tr>
        <tr>
          <td>
            <a href="http://www.tv-tokyo.co.jp/anime/yakitate-japan/">{addRubyText("焼","や")}きたて！！ジャぱん</a> (Yakitate!! Japan)
          </td>
          <td>
            河内恭介 (Kyosuke Kawachi) speaks Kansai-ben.
          </td>
        </tr>
        <tr>
          <td>
            <a href="http://www.mxtv.co.jp/lum/">うる{addRubyText("星","せい")}やつら</a>
            (Urusei Yatura)
          </td>
          <td>
            テン (TEN), a toddler alien, speaks Kansai-ben.
          </td>
        </tr>
        <tr>
          <td>
            エクセル サーガ (Excel Saga)
          </td>
          <td>
            住吉大丸 (Daimaru Sumiyoshi) speaks Kansai-ben.
          </td>
        </tr>
        <tr>
          <td>
            <a href="http://www.evangelion.co.jp/">{addRubyText("新世紀","しんせいき")}エバンゲリオン</a>
            (Neon Genesis Evangelion)
          </td>
          <td>
            鈴原トウジ (Toji Suzuhara) speaks kansai-ben(?) *Tomokazu Seki,
            the voice actor for this character, is from Tokyo.
          </td>
        </tr>
        <tr>
          <td>
            カードキャプ ターさくら (Card Captor Sakura)
          </td>
          <td>
            ケロちゃん (Kero-chan) speaks Kansai-ben.
          </td>
        </tr>
        <tr>
          <td>
            ヤミと{addRubyText("帽子","ぼうし")}と{addRubyText("本","ほん")}の{addRubyText("旅人","たびびと")} (Yami to Boushi to Hon no Tabibito)
          </td>
          <td>
            ケンちゃん (Ken-chan) and メリリン (Meirin) speak Kansai-ben.
          </td>
        </tr>
        <tr>
          <td>
            <a href="http://www.sunrise-inc.co.jp/my-hime/web/index.html">{addRubyText("舞","まい")}-HIME</a> (Mai-HIME)
          </td>
          <td>
            藤乃静留 (Shizuru Fujino) speaks Kansai-ben.
          </td>
        </tr>
        <tr>
          <td>
            PEACE MAKER{addRubyText("鐵","くろがね")} (Peace Maker KURIGANE)
          </td>
          <td>
            明里 (Akesato) speaks Kansai-ben.
          </td>
        </tr>
        <tr>
          <td>
            <a href="http://www.tv-tokyo.co.jp/anime/saiyuki_rg/home.html">{addRubyText("最遊記","さいゆうき")}：RELOAD GUNLOCK</a> (Saiyuki Reload Gunlock)
          </td>
          <td>
            ヘイゼル・グロース (Hazel) speaks Kansai-ben.
          </td>
        </tr>
        <tr>
          <td>
            <a href="http://www.sunrise-inc.co.jp/keroro/">ケロロ{addRubyText("軍曹","ぐんそう")}</a> (Keroro Gunsou)
          </td>
          <td>
            In episode 18, Kururu invents a machine to change one's accent to Kansai-ben in order to
            make them better at a "<i>Boke</i> And <i>Tsukkomi</i> Routine contest."
          </td>
        </tr>
        <tr>
          <td>
            ラブひな (Love Hina) & <a href="http://www.starchild.co.jp/special/ova_hina/index.html">ラブひなAgain</a> (Love Hina Again)
          </td>
          <td>
            紺野みつね (Konno Mitsune), also known as きつね(Kitsune), speaks Kansai-ben.
          </td>
        </tr>
        <tr>
          <td>
            {addRubyText("学園","がくえん")}アリス (Alice Academy)
          </td>
          <td>
            佐倉蜜柑 ( Sakura Mikan) speaks Kansai-ben.
          </td>
        </tr>
        <tr>
          <td>
            <a href="http://www.lucky-ch.com">ら き☆すた</a> (Lucky ☆ Star)
          </td>
          <td>
            黒井ななこ先生 (Nanako Kuroi, the teacher) speaks Kansai-ben.
          </td>
        </tr>
        <tr>
          <td>
            <a href="http://hayatenogotoku.com/">ハヤテのごとく! </a>(Hayate the Combat Butler)
          </td>
          <td>
            愛沢 咲夜 (Sakuya Aizawa) speaks Kansai-ben.
          </td>
        </tr>
        <tr>
          <td>
            <a href="http://www.tv-tokyo.co.jp/anime/bleach/">ブリーチ(Bleach)</a>
          </td>
          <td>
            市丸ギン (Gin Ichimaru) speaks Kyoto-ben.
          </td>
        </tr>
        <tr>
          <td valign="top">
            {addRubyText("名探偵","めいたんてい")}コナン (Detective Conan; Case Closed)
          </td>
          <td valign="top">
            服部平次(Heiji Hattori) speaks Osaka-ben.
          </td>
        </tr>
        </tbody>
      </table>
      <br />

      <div>
        * Some of our uses recommend this site to get information in English.
      </div>
      <div>
        <a href="http://tvtropes.org/pmwiki/pmwiki.php/Main/TheIdiotFromOsaka">TV Tropes Wiki: The Idiots from Osaka </a>
      </div>
      <div>
        <b>関西弁の漫画 Manga in Kansai-ben</b>
      </div>
      <div>
        Here are some examples in which most characters speak Kansai-ben. (* Most animes introduced above originated in manga.)
      </div>
      <br />
      <table style={{textAlign: "left"}} border="1" cellSpacing="2" cellPadding="2" width="830" height="277">
        <tbody>
        <tr>
          <td style={{textAlign: "center"}}>
            Title
          </td>
          <td style={{textAlign: "center"}}>
            Author(s)
          </td>
          <td style={{textAlign: "center"}}>
            Publisher
          </td>
        </tr>
        <tr>
          <td>じゃりン{addRubyText("子","こ")}チエ (Jarinko Chie) </td>
          <td>はるき{addRubyText("悦巳","えつみ")}</td>
          <td>{addRubyText("双葉社","ふたばしゃ")}</td>
        </tr>
        <tr>
          <td>{addRubyText("大阪","おおさか")}{addRubyText("豆","まめ")}ゴハン (Osaka Mame-gohan)</td>
          <td>サラ・イイネス</td>
          <td>{addRubyText("講談社","こうだんしゃ")}</td>
        </tr>
        <tr>
          <td>アベノ{addRubyText("橋魔法","ばしまほう")}☆{addRubyText("商店街","しょうてんがい")} (Magical Shopping Arcade
            Abenobashi)</td>
          <td>GAINAX・{addRubyText("鶴田謙二","つるたけんじ")}</td>
          <td>{addRubyText("講談社","こうだんしゃ")}</td>
        </tr>
        <tr>
          <td>ラブ★コン (Lovely Complex) </td>
          <td>{addRubyText("中原","なかはら")}アヤ</td>
          <td>{addRubyText("集英社","しゅうえいしゃ")}</td>
        </tr>
        <tr>
          <td>ちゃぶだいケンタ (Chabudai Kenta)</td>
          <td>うめ</td>
          <td>{addRubyText("講談社","こうだんしゃ")}</td>
        </tr>
        <tr>
          <td valign="top">
            ナニワ{addRubyText("金融道","きんゆうどう")}(The Way of Osaka Financing)
          </td>
          <td valign="top">
            青木雄二{addRubyText("あおき","ゆうじ")}
          </td>
          <td valign="top">
            {addRubyText("講談社","こうだんしゃ")}
          </td>
        </tr>
        </tbody>
      </table>
      <br />

      <b>関西弁のゲーム Games in Kansai-ben</b>
      <table border="1" cellSpacing="2" cellPadding="2" width="100%">
        <tbody>
        <tr>
          <td valign="top" align="center">
            Title
          </td>
          <td valign="top" align="center">
            Information
          </td>
        </tr>
        <tr>
          <td valign="top">スーパーストリートファイターIV (
            <font face="Times New Roman">Super Street Fighter IV)</font>
          </td>
          <td valign="top">
            <font face="Times New Roman">ハカン (Hakan) is seen speaking to
              E. Honda in Kansai-ben during a cutscene before his battle
              with the portly sumo wrestler.</font>
          </td>
        </tr>
        <tr>
          <td valign="top">
            ドリームクラブ ZERO (Dream Club Zero)
          </td>
          <td valign="top">
            Mio, one of the hostesses, speaks Kyoto-ben.
          </td>
        </tr>
        </tbody>
      </table>
    </>
  );
};

export default AnimeManga;