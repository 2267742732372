import React from "react";
import { connect } from 'react-redux';
import { Header } from "semantic-ui-react";
import { Constants } from '../../constants';
import { sortObjects } from 'common/util/generalUtil';

const fields = ['Name', 'Gender', 'Age', 'Relation', 'Occupation', 'Residency'];

const IntervieweesComponent = (props) => {
  const [fieldOrder, setFieldOrder] = React.useState({
    'field': null,
    'order': 0 // asc
  });

  if (props.isIntervieweesLoading) {
    return <h2>Interviewees</h2>
  }
  
  const interviewees = sortObjects(props.interviewees, fieldOrder);

  return (
    <div>
      <Header as='h2'>
        Interviewees ({interviewees.length})
      </Header>
      <table cellSpacing="3" cellPadding="5" border="1">
        <tbody>
          <tr>
            {fields.map( (columnHeader, index) => {
              return <th key={index} onClick={() => 
                  setFieldOrder({
                    'field': fields[index],
                    'order': 1 - fieldOrder['order']
                  })
                }>
                {columnHeader} 
                {fields[index] === fieldOrder.field && fieldOrder.order === 0 && 
                  <img alt="" src="http://kansaiben.com/script/exhibit/webapp/api/images/up-arrow.png" />}
                {fields[index] === fieldOrder.field && fieldOrder.order === 1 && 
                  <img alt="" src="http://kansaiben.com/script/exhibit/webapp/api/images/down-arrow.png" />}
              </th>
            })}
          </tr>
          {interviewees.map( (interviewee, index) => {
            const { IntervieweeID, Name, Gender, Age, Occupation, Relation, Residency } = interviewee;
            return <tr key={index}>
              <td>{Name}</td>
              <td>{Gender}</td>
              <td>{Age}</td>
              <td>{Occupation}</td>
              <td>{Relation}</td>
              <td>{Residency}</td>
            </tr>
          })}
        </tbody>
      </table>
      
    </div>
  )
};

function mapStateToProps(state) {
  return state.interviewees;
}

function mapDispatchToProps(dispatch) {
  return {
    fetchInterviewees: () => dispatch({ 
      type: Constants.FETCH_INTERVIEWEES
    })
  };
}

const Interviewees = connect(mapStateToProps, mapDispatchToProps)(IntervieweesComponent);

export default Interviewees;