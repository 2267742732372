import React from "react";
import { addImage } from "common/util/generalUtil";

const AboutThisProject = () => {

  return (
    <>
      <h2>About This Project</h2>
      <br />
      <div>
        It was 2005 when Shingu came up with the idea of establishing a
        Kansai-ben self-study website. After some preparatory research and
        after involving Hatanaka as a collaborator, this project
        officially started in 2006 supported by
        <a href="http://www.languageconsortium.org/">The Consortium of Language
        Teaching and Learning</a>. In this section, we will explain what
        is behind this project; why we, ordinary Japanese instructors in the US,
        decided to work on this project in addition to our school teaching
        duties, and where we currently stand in terms of progress on this
        project.
      </div>
      <br />
      <div>
        Being native speakers of the Kansai dialect, we have been asked by a
        number of intermediate level students of Japanese, who are ready to
        participate in exchange/internship programs in the Kansai area, for
        help in preparing them for their experience by teaching them the
        dialect. Some students say their interest in the dialect grew from
        watching animation and movies in which some characters speak the
        Kansai dialect or from their Japanese friends who are native speakers of
        the dialect.
      </div>
      <br />

      <table style={{width: "40%"}} border="0" cellSpacing="2" cellPadding="2" align="right">
        <tbody>
        <tr align="center">
          <td style={{verticalAlign: "top"}}>{addImage("kyotonooka.jpg")}</td>
        </tr>
        <tr align="center">
          <td style={{verticalAlign: "top"}}>
            <small>野菜畑 Vegetable field (Oharano, Kyoto)</small>
          </td>
        </tr>
        </tbody>
      </table>

      <div>
        As explained in Chapter 1, Kansai-ben is the most powerful regional
        dialect in Japan nowadays, spoken by over twenty million people
        including those in Osaka, Kobe, Nara and Kyoto. Though the Kansai
        dialect has linguistically distinctive features from Standard Tokyo
        dialect, native speakers of Japanese are used to hearing it and
        usually have little problem understanding it because the dialect is widely
        used in the entertainment industry and in the media (by Kansai comedians,
        for example). However, it is a challenge for foreign learners of
        Japanese to comprehend it since only "standard" Japanese is taught
        in the U.S. Most students never have a chance to realize the fact
        that there are big regional differences in how the Japanese language is
        spoken; they tend to find out only after they go to Japan and
        experience difficulty understanding what the local people are saying.
      </div>
      <br />

      <div>
        In the case of the <a href="https://misti.mit.edu/mit-japan">MIT
        Japan program</a> (MIT has a strong internship program, and most
        students interested in going to Japan choose this over studying abroad),
        approximately one fourth of the interns are sent to the Kansai area
        each year. They do not receive detailed instruction on the regional
        dialect during their preparation period. In the summer of 2005,
        Shingu conducted an online survey targeting 21 interns who were sent to the
        Kansai area between 2003 to 2005 to learn how they see the dialect
        and if they feel studying the dialect beforehand would have been useful
        to their internship. Their opinions varied depending on their
        background, the work environment they were in and the amount of contact they had
        with local people. Those who had a significant amount of contact
        with local people, especially with elderly people, experienced difficulties
        in proper understanding and felt they would have appreciated formal
        instruction of the dialect beforehand. The result positively
        supports our supposition regarding the need to study the dialect among the
        participants.
      </div>
      <br />

      <div>
        Up to now, it has only been possible to offer a seminar or an
        extra-curricular activity on the basics of the Kansai dialect. At
        the Japanese School at Middlebury College, VT, Shingu conducted weekly
        Kansai-ben seminars as an extra-curricular activity in the summers
        of 2001 to 2003 due to the demand from the students. As much as twenty
        percent of the Japanese students participated in the seminar each
        summer. At the University of Texas at Austin, Hatanaka spends one
        hour of the third year Japanese course introducing the dialect to
        increase the awareness of this regional language and culture in Japan.
        However, these are very limited examples at a limited number of
        institutions. This amount of instruction is far from adequate
        for answering the students’ need to learn the dialect in a systematic
        and practical manner.
      </div>
      <br />

      <div>
        Textbooks and web resources related to Kansai-ben are also presently
        insufficient. There is a textbook on the Kansai dialect written by
        Japanese teachers in Japan accompanied by tapes. However, there are
        a few problems: 1) its medium language is Japanese; hence, while it
        may be good for advanced learners, it is not suitable for most students
        who have only 2-3 years of Japanese study, and 2) the order of
        introducing each structure is not systematic and, therefore, it is very hard to
        find what is introduced where. The biggest problem is that the book
        has recently gone out-of-print! There are also a couple books on the
        Kansai dialect published in the U.S. However, these are not written
        by Japanese teachers who are familiar with teaching Japanese as a
        foreign language, and there is no audio resource to go along with them. We
        also found a few English websites and some Japanese websites focusing on
        the Kansai dialects. However, none have been created/maintained by
        professional Japanese teachers. These websites may be useful to gain
        knowledge of the overall feature of the dialect or some idioms, but
        not for practical comprehension of or communication with the dialect.
        Japanese websites are meant for Japanese natives, not for Japanese
        learners, so they are out of the question as far as comprehensive
        learning for non-native speakers is concerned.
      </div>
      <br />

      <table style={{textAlign: "left", width: "30%"}} border="0"
             cellSpacing="2" cellPadding="2" align="right">
        <tbody>
        <tr align="center">
          <td style={{verticalAlign: "top"}}>
            {addImage("shooboojifudoo.jpg")}
          </td>
        </tr>
        <tr align="center">
          <td style={{verticalAlign: "top"}}>
            <small>正法寺の不動尊 Fudo at Shobo-ji Temple (Kyoto)</small>
          </td>
        </tr>
        </tbody>
      </table>
      <br />

      <div>
        Therefore,
        it would be reasonable for Japanese teachers as pedagogical
        specialists to create an accessible and practical online tool to
        learn
        the dialect, specifically for Intermediate Japanese learners and in
        the
        medium of English. There are three reasons why a website would be
        ideal
        to conduct this project: 1) Websites can be accessible to any
        student
        practically anywhere and anytime, 2) Websites are capable of
        embedding
        sound and visual information in addition to the factual texts and
        written exercises, and 3) Websites can be an open-end resource to
        which
        we can add and edit exercises and materials whenever necessary.
      </div>
      <br />

      <div>
        Websites can also make use of video-clips featuring natural
        conversation by native dialect speakers for listening comprehension
        and as cultural resources. Since the study of the dialect is something
        students would do in addition to their standard Japanese study, it
        should be relatively non-demanding; that is, it has to be something
        they could have fun with and something that could enhance their
        curiosity to continue working on it. The video-clips of “real
        people” should contribute to make the exploration of the Kansai dialect and
        culture more realistic and enjoyable. Also, video-clips can show the
        diversity within the dialect – for instance, Kansai people of
        different gender, generations, and in different regions speak differently
        within the category of the dialect. Furthermore, video-clips can provide
        rich visual information in addition to the sounds, such as the situation,
        location, scenery and appearance and manner of the people. Not only
        can the users learn and develop a strong feeling for the cultural
        practice of the spoken language in Kansai but they can also cultivate their
        own idea on what “Kansai culture and local identity” is.
      </div>
      <br />

      <div>
        As of July 2007, we have uploaded chapters 1, 2, 3 and a part of
        Chapter 6. Tremendous efforts have been made to come this far, since
        all the materials (English texts, example sentences, exercises,
        audios and video clips) were created by the two of us--Shingu and
        Hatanaka--with little knowledge of web publishing. There are still
        many things to be done: site-design and uploading the rest of the
        chapters, to name a couple. Through the survey to pilot users in
        June 2007, we received many encouraging comments, suggestions and
        new ideas that we can certainly adapt to make the site more
        user-friendly. Fortunately, we received additional funding from
        the Consortium of Language Teaching and Education in 2007,
        and this will allow us to continue making the website more complete
        and updated.
      </div>
      <br />

      <div>
        *As of April 2008, we have added some contents of chapters 4, 5 and
        7. We also added sound files for standard Japanese phrases in chapter 2
        so users can compare the sound difference between standard and kansai
        (many thanks to Mayumi Ishida, our collaborator,
        for her assistance as a naive Tokyonite)
      </div>
      <br />

      <div>
        *As of March 2020, we are still updating contents when necessary.
        Any input on Kansai-related media resources will be welcome.
      </div>
      <br />
    </>
  );
};

export default AboutThisProject;