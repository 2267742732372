import React from "react";
import { Link } from "react-router-dom";
import { Page } from "home/page";
import { addRubyText, addAudio } from "common/util/generalUtil";

const Particles = () => {
  return (
    <>
      <h2>Particles</h2>
      <div>
      The functions of phrase particles が, を, は, も and so on in the Kansai
      dialect are the same as in the standard, except the fact that Kansai
      people tend to use “zero” particles (no use of particles, omittion of
      particles) more often, which means, they tend
      to drop phrase particles more frequently unless necessary.
      </div>
      <br />
      {addRubyText("七月","なながつ")}{addRubyText("東京","とうきょう")}{addRubyText("行","い")}こ{addRubyText("言","ゆ")}うてたん、{addRubyText("誰","だれ")}や{addRubyText("思","おも")}う？ {addAudio("1.2.e.1.mp3")}<br />
      <small>(ST {addRubyText("七月","しちがつ")}<u>に</u>東京<u>に</u>行こう<u>って</u>言ってたの<u>は</u>誰だ<u>と</u>思う？）{addAudio("1.2.e.1.s.mp3")}</small><br />
      <small>*We will use "ST" to indicate the standard equivalent.</small><br />
      <br />
      The major differences are observed in interactional (sentence)
      particles such as ね, よ etc. Below is a list of major sentence
      particles.<br />
      <br />
      <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
        <tbody>
          <tr>
            <td style={{verticalAlign: "top"}}>Sentence particle<br />
            </td>
            <td style={{verticalAlign: "top"}}>Standard<br />
            </td>
            <td style={{verticalAlign: "top"}}>Neo-dialect<br />
            </td>
            <td style={{verticalAlign: "top"}}>Kansai<br />
            </td>
          </tr>
          <tr>
            <td style={{verticalAlign: "top"}}>ね<br />
            </td>
            <td style={{verticalAlign: "top"}}>ね；な(blunt)<br />
            </td>
            <td style={{verticalAlign: "top"}}>ね；な<br />
            </td>
            <td style={{verticalAlign: "top"}}>な；のう(blunt)<br />
            </td>
          </tr>
          <tr>
            <td style={{verticalAlign: "top"}}>よ<br />
            </td>
            <td style={{verticalAlign: "top"}}>よ<br />
            </td>
            <td style={{verticalAlign: "top"}}>よ；わ*<br />
            </td>
            <td style={{verticalAlign: "top"}}>*わ；で；がな<br />
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      *Note that the わ used in the Kansai dialect is different from the
      feminine particle わ in the Standard. Kansai-specific わ is a sentence
      final particle that lightly emphasizes the sentence and is used by both
      males and females. <br />
      <br />
      For detailed information about sentence particles, please refer to <Link to={Page.BasicGrammar.SentenceEndings}>Ch3. L1b. Sentence Endings</Link>
      <br />
      <br />
    </>
  );
}


export default Particles;