import React from "react";
import { addRubyText, addAudio } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";

const P5 = () => {
  return <>
    <h2>{addRubyText("練習","れんしゅう")}5: Utilization</h2>
    What would you say in the following situations in Kansai-ben? Try to
    ask
    in several ways. <br />
    *Answers vary; the recorded answers indicate those commonly used.<br />
    <small>* Click "SHOW" if you need to refer to the transcript of the
    example answers.&nbsp; <br />
    *Move the mouse over to the speaker icon for sound<br />
    <br />
    </small>
    <table style={{textAlign: "left", width: "100%"}} border="1"cellPadding="2"
    cellSpacing="2">
    <tbody>
    <tr>
      <td style={{verticalAlign: "top", textAlign: "center"}}>Situation<br />
      </td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>Example
    Answer<br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>1. Tell your classmate, who has
    missed many classes so far, to not skip the class anymore<br />
      </td>
      <td style={{verticalAlign: "top"}}>{addAudio("4.3.p.5.1.mp3")} <Hideable content={<>
    もうクラス{addRubyText("休","やす")}みなや。／休まんとき。</>}/><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>2. Tell your classmate, who is
    always late to submit homework, to finish his homework within the day.<br />
      </td>
      <td style={{verticalAlign: "top"}}>{addAudio("4.3.p.5.2.mp3")} <Hideable content={<>
    {addRubyText("今日中","きょうじゅう")}に{addRubyText("宿題","しゅくだい")}してしまいや。</>}/></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>3. Your neighbor’s child is
    about to eat snacks right after playing with mud. Tell him to wash his
    hands before eating. <br />
      </td>
      <td style={{verticalAlign: "top"}}>{addAudio("4.3.p.5.3.mp3")}
    <Hideable content={<>{addRubyText("食","た")}べる{addRubyText("前","まえ")}に{addRubyText("手","て")}ぇ{addRubyText("洗","あら")}い。</>}/> <br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>4. Your friend is disappointed
    in his test score. Tell him not to be disappointed that much. Also tell
    him to cheer up. <br />
      </td>
      <td style={{verticalAlign: "top"}}>&nbsp;{addAudio("4.3.p.5.4.mp3")}&nbsp;
    <Hideable content={<>そんながっかりしなや／せんと。{addRubyText("元気","げんき")}{addRubyText("出","だ")}しぃ。</>}/><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>5. Your friend got dead drunk.
    Tell him not to drink anymore.<br />
      </td>
      <td style={{verticalAlign: "top"}}>&nbsp;{addAudio("4.3.p.5.5.mp3")}&nbsp;
    <Hideable content={<>あんた、もう{addRubyText("飲","の")}みなや。</>}/></td>
    </tr>
    </tbody>
    </table>
  </>;
}

export default P5;
