import React from "react";
import { addRubyText, addAudio, addVideoPlayer } from "common/util/generalUtil";

const Greetings = () => {
  return (
    <>
      <h2>Greetings</h2>
      <p>
        <small>*<b>BOLD</b> type indicates high pitch</small><br />
        <small>*Mouse over or click on the speaker icon for sound</small>
      </p>
      <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Standard
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Kansai
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            おはよう {addAudio("2.1.1.s.mp3")}
          </td>
          <td style={{verticalAlign: "top"}}>
            おは<b>よ</b>う {addAudio("2.1.1.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            おはようございます {addAudio("2.1.2.s.mp3")}
          </td>
          <td style={{verticalAlign: "top"}}>
            おはよう<b>ございますぅ</b> {addAudio("2.1.2.mp3")}<br />
            おは<b>よ</b>うさん (used by older generation) <br />
            おは<b>よ</b>うさんどす　(used by older generation in Kyoto/Shiga)
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            こんにちは {addAudio("2.1.3.s.mp3")}
          </td>
          <td style={{verticalAlign: "top"}}>
            こ<b>ん</b>にち<b>は</b> {addAudio("2.1.3.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            こんばんは {addAudio("2.1.4.s.mp3")}
          </td>
          <td style={{verticalAlign: "top"}}>
            こ<b>ん</b>ばん<b>は</b> {addAudio("2.1.4.mp3")}
          </td>
        </tr>
        </tbody>
      </table>
      <br />
      <p>
        ＊おきばり<b>や</b>す＝{addRubyText("頑張","がんば")}ってください
        "Hang on, Do your best." This expression
        is used by older generation in Kyoto/Shiga, being added after
        おはよう/こんにちは。
      </p>
      <hr />

      <h3>ビデオ１</h3>
      <p>
        Let’s watch how Kansai people greet in the following situations!
      </p>
      <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top"}} />
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Standard
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Kansai
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            a. In the morning (to neighbors or at work)
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addVideoPlayer("2.1.a.1.mp4")}
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addVideoPlayer("2.1.a.2.mp4")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            b. In the morning (to kids and friends)
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addVideoPlayer("2.1.b.1.mp4")}
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addVideoPlayer("2.1.b.2.mp4")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            c. During daytime
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addVideoPlayer("2.1.c.1.mp4")}
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addVideoPlayer("2.1.c.2.mp4")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            d. In the evening
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addVideoPlayer("2.1.d.1.mp4")}
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addVideoPlayer("2.1.d.2.mp4")}
          </td>
        </tr>
        </tbody>
      </table>
      <br />

      <h3>ビデオ２</h3>
      <p>
        Let's watch a couple video clips that show how Kansai people greet each other.
      </p>

      <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            In the morning (between friends)
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            During daytime (between friends)
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addVideoPlayer("2.1.e.mp4")}
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addVideoPlayer("2.1.f.mp4")}
          </td>
        </tr>
        </tbody>
      </table>
    </>
  );
};

export default Greetings;