import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";

const V2 = () => {
  const transcript = <>
    {addRubyText("分","わ")}かりやすいこと<span style={{textDecoration: "underline"}}>{addRubyText("言","い")}うてくれな</span>
(Grammar 2)，{addRubyText("僕","ぼく")}、あの、分からへんで。<br />
  </>;

  const qaObjects = [
    {
      question: <span>Q: In which situation does he think he will have trouble understanding?</span>,
      answersCorrect: [
        <>When the interviewer asks him questions that are difficult to understand.</>
      ],
      answersIncorrect: [
        <>When the interviewer asks him too many questions.</>,
        <>When the interviewer asks him any questions.</>,
        <>When the interviewer asks him questions too fast.</>
      ]
    }
  ];

  return <>
<h2>ビデオ{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}２: Authentic - short<br />
</h2>
Watch the video clip and choose the answer that matches the content of
the clip.<br />
<small>*Click "SHOW" if you need to refer to the transcript<br />
<br />
</small>
Matsuda-san is just about to beinterviewed.
<table style={{textAlign: "left", width: "100%"}} border="0"cellPadding="2"
 cellSpacing="2">
  <tbody>
    <tr>
      <td style={{verticalAlign: "top", textAlign: "left"}}>&nbsp;{addVideoPlayer("4.2.2.p.1.matsuda.mp4")} </td>
      <td colSpan="1" rowSpan="2"
 style={{verticalAlign: "top", textAlign: "center"}}>
      <div style={{textAlign: "left"}}>Transcript <Hideable content={transcript}/>
      </div>
      <br />
      </td>
    </tr>
    <tr align="center">
      <td style={{verticalAlign: "top", textAlign: "left"}}>マツダさん(Shiga)</td>
    </tr>
  </tbody>
</table>
<br />
<QandAForm qaObjects={qaObjects}/>
  </>;
}

export default V2;
