import React from "react";
import { Link } from "react-router-dom";
import { Page } from "home/page";
import { addRubyText, addImage } from "common/util/generalUtil";

const Practice = () => {
  return <>
    <h2>{addRubyText("練習","れんしゅう")} (Practice)</h2>
    <table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>
          <ul>
            <li>
              <Link to={Page.FunctionalGrammar.PermissionProhibition.Practice.P1}>
                {addRubyText("練習","れんしゅう")}1: Permission &amp; Prohibition - Pattern Practice
              </Link>
            </li>
            <li>
              <Link to={Page.FunctionalGrammar.PermissionProhibition.Practice.P2}>
                {addRubyText("練習","れんしゅう")}2: Negative request – Pattern Practice
              </Link>
            </li>
            <li>
              <Link to={Page.FunctionalGrammar.PermissionProhibition.Practice.P3}>
                {addRubyText("練習","れんしゅう")}3: Situational Practice
              </Link>
            </li>
            <li>
              <Link to={Page.FunctionalGrammar.PermissionProhibition.Practice.P4}>
                {addRubyText("練習","れんしゅう")}4: Utilization
              </Link>
            </li>
          </ul>
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addImage("tetsugakunomichi.jpg")}<br />
          <small>哲学の道 Philosopher's Road (Kyoto)</small><br />
          </td>
        </tr>
      </tbody>
    </table>
  </>;
}

export default Practice;
