import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { ShowHideableTranscript } from "components/common/hideable";
import { QandAForm } from "common/util/QandA";

const renderTitle = () => {
  return <h2>ビデオ{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}１: Authentic - short</h2>
}

const videoWithTranscript = (props) => {
  const videoFile = props.videoFile;
  const transcript = props.transcript;
  const footnote = props.footNote;
  return <table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2" cellSpacing="2">
    <tbody>
      <tr>
        <td style={{verticalAlign: "top", textAlign: "left"}}>
          {addVideoPlayer(videoFile)}
        </td>
        <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>
          {ShowHideableTranscript({
            title: "Transcript", 
            transcript: transcript
          })}
        </td>
      </tr>
      <tr align="center">
        <td style={{verticalAlign: "top", textAlign: "left"}}>
          {footnote}
        </td>
      </tr>
    </tbody>
  </table>
}

const V1 = () => {
  const qaObjects = [
    {
      question: <>Q: What is his least favorite food?</>,
      answersIncorrect: [
        <>There are many foods he dislikes.</>
      ],
      answersCorrect: [
        <>There are not many foods he doesn’t like.</>
      ]
    }
  ];

  return <>
    {renderTitle()}
    Watch the video clip and choose the answer that matches the content of the clip.
    <br />
    <small>*Click "SHOW" if you need to refer to the transcript</small>
    <br />
    <br />
    {videoWithTranscript({
      videoFile: "3.1.a.pl0.1.mp4",
      transcript: [
        <>{addRubyText("嫌","きら")}いな{addRubyText("食","た")}べ{addRubyText("物","もの")}は〜、あんまり<span style={{textDecoration: "underline"}}>あらへん</span>(Grammar 1) ねえ。</>
      ],
      footNote: <>アキラ (Osaka)</>
    })}
    <br/>
    <QandAForm qaObjects={qaObjects} />
  </>;
}

export default V1;
