import React from "react";
import { addAudio } from "common/util/generalUtil";

const Sound = () => {
  return (
    <>
      <h2>Sound (Sound-related feature)</h2>
      In the Kansai dialect, vowels tend to be pronounced clearly, rather
      long and strong, and consonants tend to be pronounced lightly, short
      and weak, which lead to the characteristics listed below.<small><br />
      *<b>BOLD TYPE</b> indicates high pitch
      <br />
      *Mouse over or click on the speaker icon for sound</small><br />
      <ul>
        <li>One-*mora (syllable-like unit of Japanese) words tend to be lengthened with tone.</li>
      </ul>
      <table style={{textAlign: "left", width: "60%", minWidth: "500px"}} border="1" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>
            歯 (teeth)
          </td>
          <td style={{verticalAlign: "top"}}>
            は(HA) →<b>は</b>ぁ(HAA) {addAudio("1.2.a.3.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            木 (tree)
          </td>
          <td style={{verticalAlign: "top"}}>
            き(KI) →き<b>ぃ</b>(KII) {addAudio("1.2.a.1.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            目 (eye)
          </td>
          <td style={{verticalAlign: "top"}}>
            め(ME) →め<b>ぇ</b>(MEE) {addAudio("1.2.a.2.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            戸 (door)
          </td>
          <td style={{verticalAlign: "top"}}>
            と(TO) → <b>とぉ</b>(TOO) {addAudio("1.2.a.4.mp3")}
          </td>
        </tr>
        </tbody>
      </table>
      <br />
      <ul>
        <li>Voiceless (whispered) vowels rarely occur. </li>
      </ul>
      <table style={{textAlign: "left", width: "60%", minWidth: "500px"}} border="1" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>
            聞く (listen; ask)
          </td>
          <td style={{verticalAlign: "top"}}>
            K(i)KU→<b>きく</b> (KIKU) {addAudio("1.2.a.5.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            月 (moon)
          </td>
          <td style={{verticalAlign: "top"}}>
            TS(u)KI →<b>つ</b>き (TSUKI) {addAudio("1.2.a.6.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            しますよ
          </td>
          <td style={{verticalAlign: "top"}}>
            SH(i)MAS(u) YO →<b>します</b>よ (SHIMASU YO) {addAudio("1.2.a.7.mp3")}
          </td>
        </tr>
        </tbody>
      </table>
      <br />
      <ul>
        <li>Consonants tend to be pronounced much more weakly, which result
          in the sound change such as [so] to [ho]. [da] to [ya].</li>
      </ul>
      <table style={{textAlign: "left", width: "60%", minWidth: "500px"}} border="1" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>
            それで (sorede) →ほんで (honde) {addAudio("1.2.a.8.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            そうだ (soo da) →ほや (hoya) {addAudio("1.2.a.9.mp3")}
          </td>
        </tr>
        </tbody>
      </table>
      <br />
      <div>
        *The occurrence of the change from [so] to [ho] depends on the region and generation.
      </div>
    </>
  );
};

export default Sound;
