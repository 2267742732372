import React, { Component } from "react";
import { connect } from 'react-redux';
import { Constants } from '../../constants';
import { Hideable } from "common/util/hideable";
import { addImage, addVideoPlayer, toRubyText } from "common/util/generalUtil";
import { QandAForm } from "common/util/QandA";
import { Modal, Button, Header } from 'semantic-ui-react';

const InterviewClipVocabulary = props => {
  if (!props.VocabList || 0 === props.VocabList.length) {
    return <></>;
  }

  const VocabList = <ul style={{display: "block", overflow: "visible"}}>
    {props.VocabList.split(/\s*[;]\s*/).map((vocabItem, index) => {
      return <li key={index}>
        {toRubyText(vocabItem)}
      </li>
    })}
  </ul>;

  return <>
    <h4>Vocabulary</h4>
    <Hideable content={VocabList}/>
  </>;
}

const InterviewClipModal = (props) => {
  const [open, setOpen] = React.useState(false)
  const ClipID = props.ClipID;
  const InterviewQuestion = props.InterviewQuestion;
  const fetchInterviewClipQandA = props.fetchInterviewClipQandA;
  const isInterviewClipQandALoading = props.isInterviewClipQandALoading;
  const interviewClipQandA = props.interviewClipQandA;
  
  if (isInterviewClipQandALoading) {
    return <Modal trigger={addImage("6." + ClipID + ".jpg")}></Modal>
  } else { 
  }

  const qaObjects = interviewClipQandA.map((question, index) => {
    const { Question, QuestionChoices, Answers } = question;
    const answersIncorrect = QuestionChoices.split(/[;]+/).filter(Boolean).map(answer => <>{toRubyText(answer.trim())}</>);
    const answersCorrect = Answers.split(/[;]+/).filter(Boolean).map(answer => <>{toRubyText(answer.trim())}</>);
    return {
      question: <span>{toRubyText(Question.trim())}</span>,
      answersIncorrect: answersIncorrect,
      answersCorrect: answersCorrect
    }
  });

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => {fetchInterviewClipQandA(ClipID); setOpen(true)}}
      open={open}
      trigger={addImage("6." + ClipID + ".jpg")}
    >
      <Modal.Header>{InterviewQuestion}</Modal.Header>
      <Modal.Content image>
        { addVideoPlayer("6." + ClipID + ".mp4") }
        <Modal.Description>
          <QandAForm qaObjects={qaObjects} />
          <InterviewClipVocabulary VocabList={props.VocabList}/>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color='black' onClick={() => setOpen(false)}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

function mapStateToPropsClipQandA(state) {
  return state.interviewClipQandA;
}

function mapDispatchToPropsClipQandA(dispatch) {
  return {
    fetchInterviewClipQandA: (clipID) => dispatch({
      type: Constants.FETCH_INTERVIEW_CLIP_Q_AND_A,
      payload: {
        clipID: clipID
      }
    })
  };
}

const InterviewClipQandA = connect(mapStateToPropsClipQandA, mapDispatchToPropsClipQandA)(InterviewClipModal);

class InterviewClipsClass extends Component {
  
  renderInterviewClipCount() {
    if (this.props.isCountLoading) {
      return 'Loading...';
    } else {
      return <span>{this.props.list.length} out of {this.props.count}</span>;
    }
  }

  renderInterviewClipList() {
    
    if (this.props.isListLoading) {
      return (
        <tr>
          <td>
            <span>
              Loading...
            </span>
          </td>
        </tr>
      );
    } else {
      return this.props.list.map((interviewClip, index) => {
        // ClipVocab -> (ClipID, *InterviewQuestion, *VocabList)
        // ClipQuestions -> (ClipQuestionID, ClipID, *Question, *QuestionChoices, *Answers)
        // Interviewees -> (IntervieweeID, *Name, *Gender, *Age, *Occupation, *Relation, *Residency)
        // InterviewClips -> (ClipID, QuestionID, IntervieweeID, Name, *Difficulty, *KansaiBenID)
        const { ClipID, InterviewQuestion, VocabList, 
                Name, Gender, Age, Occupation, Relation, Residency, 
                Difficulty, KansaiBenID } = interviewClip;
        
        return (
          <tr key={index}>
            <td>
              <span>
                <InterviewClipQandA ClipID={ClipID}
                                    VocabList={VocabList} 
                                    InterviewQuestion={InterviewQuestion}/>
              </span>
            </td>
            <td>
              <table border="1">
                <tbody>
                  <tr>
                    <th colSpan="3">
                      <span>{InterviewQuestion}</span>
                    </th>
                  </tr>
                  <tr>
                    <th>Difficulty:</th>
                    <td>{Difficulty}</td>
                    <th>Interviewee</th>
                  </tr>
                  <tr>
                    <th>Degree of Kansai-ben</th>
                    <td>{KansaiBenID}</td>
                    <td rowSpan="2">
                      <div>
                        <span style={{fontWeight: "bold"}}>Name: </span> 
                        <span>{Name}</span> <br/>
                      </div>
                      <div>
                        <span style={{fontWeight: "bold"}}>Gender: </span> 
                        <span>{Gender}</span><br/>
                      </div>
                      <div>
                        <span style={{fontWeight: "bold"}}>Age: </span> 
                        <span>{Age}</span><br/>
                      </div>
                      <div>
                        <span style={{fontWeight: "bold"}}>Relation: </span> 
                        <span>{Relation}</span><br/>
                      </div>
                      <div>
                        <span style={{fontWeight: "bold"}}>Occupation: </span> 
                        <span>{Occupation}</span><br/>
                      </div>
                      <div>
                        <span style={{fontWeight: "bold"}}>Residency: </span> 
                        <span>{Residency}</span><br/>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        );
      });
    }
  }

  renderMoreButton() {
    if (this.props.isListLoading || this.props.isCountLoading) {
      return <div>Loading...</div>;
    } else {
      return (
        <button onClick={() => this.props.changeInterviewClipList(0, this.props.list.length + Constants.INTERVIEW_CLIP_PAGE_SIZE)}>
          Show {Constants.INTERVIEW_CLIP_PAGE_SIZE} more results
        </button>
      );
    }
  }

  render() {
    return (
      <div>
        <Header as='h2'>
          Interview Clips
        </Header>
        <h3>
          INTERVIEW CLIPS
        </h3>
        <hr/>
        <ul>
          <li>
            Number of clips displayed: {this.renderInterviewClipCount()}
          </li>
        </ul>
        <div>
          <table border="1">
            <tbody>
              {this.renderInterviewClipList()}
            </tbody>
          </table>
        </div>
        {this.renderMoreButton()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return state.interviewClips;
}

function mapDispatchToProps(dispatch) {
  return {
    fetchInterviewClipCount: () => dispatch({ 
      type: Constants.FETCH_INTERVIEW_CLIP_COUNT 
    }),
    fetchInterviewClipList: (start, n) => dispatch({ 
      type: Constants.FETCH_INTERVIEW_CLIP_LIST, 
      payload: {
        start: start,
        n: n 
      }
    }),
    changeInterviewClipList: (start, n) => dispatch({
      type: Constants.FETCH_MORE_INTERVIEW_CLIP_LIST,
      payload: {
        start: start,
        n: n
      }
    })
  };
}

const InterviewClips = connect(mapStateToProps, mapDispatchToProps)(InterviewClipsClass);

export default InterviewClips;