import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";

const Conversations = () => {
  const kansaiTranscript1 = <>
          <ol class="transcript">
            <li><small>A: このCD、<span style={{fontWeight: "bold"}}>か</span>りてもえ
    え？</small></li>
            <li><small>B: ええ<span style={{fontWeight: "bold"}}>け</span>ど、<span
    style={{fontWeight: "bold"}}>よ</span>ごしたら<span style={{fontWeight: "bold"}}>あ
    かん</span>で。</small></li>
            <li><small>A:もう<span style={{fontWeight: "bold"}}>よごれてる</span>わ。<br />
              </small></li>
            <li><small>B: え<span style={{fontWeight: "bold"}}>えっ</span>、う<span
    style={{fontWeight: "bold"}}>そ</span>ぉ！</small></li>
          </ol>
  </>;

  const standardTranscript1 = <>
          <ol class="transcript">
            <li><small>A: このCD{addRubyText("借","か")}りてもいい？</small></li>
            <li><small>B: いいけど、{addRubyText("汚","よご")}しちゃだめだよ。</small></li>
            <li><small>A: もう汚れてるよ。</small></li>
            <li><small>B: ええっ、うそ！</small><br />
            </li>
          </ol>
  </>;

  const kansaiTranscript2 = <>
          <ol class="transcript">
            <li><small>A: <span style={{fontWeight: "bold"}}>この</span>おかず、おいし<span
    style={{fontWeight: "bold"}}>そ</span>〜。<span style={{fontWeight: "bold"}}>ちょ
    こ</span>っとた<span style={{fontWeight: "bold"}}>べ</span>てもいい？<br />
              </small></li>
            <li><small>B:&nbsp; <span style={{fontWeight: "bold"}}>そのだいこんのた</span>い
    たん？<span style={{fontWeight: "bold"}}>あ</span>あ、え<span
    style={{fontWeight: "bold"}}>え</span>よ。</small></li>
            <li><small>A: こっ<span style={{fontWeight: "bold"}}>ち</span>の<span
    style={{fontWeight: "bold"}}>てんぷらは</span>？<span
    style={{fontWeight: "bold"}}>あじみし</span>ても<span
    style={{fontWeight: "bold"}}>か</span>ま<span style={{fontWeight: "bold"}}>へん</span>や
    んな。</small></li>
            <li><small>B: <span style={{fontWeight: "bold"}}>あ〜それは</span>た<span
    style={{fontWeight: "bold"}}>べ</span>た<span style={{fontWeight: "bold"}}>いか
    ん、いかん</span>。</small></li>
            <li><small>A:え〜？なんで<span style={{fontWeight: "bold"}}>あかん</span>の？</small></li>
            <li><small>B: さっ<span style={{fontWeight: "bold"}}>き</span>ぃ<span
    style={{fontWeight: "bold"}}>したにお</span>としたし、<span
    style={{fontWeight: "bold"}}>ね</span>こに<span style={{fontWeight: "bold"}}>や
    ろおもて</span>てん。</small></li>
            <li><small>A:げ〜、<span style={{fontWeight: "bold"}}>もう</span>たべ<span
    style={{fontWeight: "bold"}}>て</span>もた。</small></li>
          </ol>
  </>;

  const standardTranscript2 = <>
          <ol class="transcript">
            <li><small>A: このおかず、おいしそう。ちょっと{addRubyText("食","た")}べてもいい？<br />
              </small></li>
            <li><small>B: その{addRubyText("大根","だいこん")}の{addRubyText("煮","に")}たの？うん、いいよ。</small></li>
            <li><small>A: こっちの{addRubyText("天","てん")}ぷらは？{addRubyText("味見","あじみ")}してもいいじゃない、ね。</small></li>
            <li><small>B: あ〜それは食べちゃいけないよ。</small></li>
            <li><small>A: え？どうしていけないの？</small></li>
            <li><small>B: さっき{addRubyText("下","した")}に{addRubyText("落","お")}としたから、ねこにやろうと{addRubyText("思","おも")}ってたの。</small></li>
            <li><small>A: え〜、もう食べちゃった。</small> </li>
          </ol>
  </>;

  const kansaiTranscript3 = <>
          <ol class="transcript">
            <li><small>A: すんま<span style={{fontWeight: "bold"}}>せん</span>。ここの<span
    style={{fontWeight: "bold"}}>せ</span>き、<span style={{fontWeight: "bold"}}>す
    わらしても</span>うても<span style={{fontWeight: "bold"}}>か</span>ま<span
    style={{fontWeight: "bold"}}>しませんか</span>。<br />
              </small></li>
            <li><small>B: <span style={{fontWeight: "bold"}}>あ</span>、ごめん。そこは<span
    style={{fontWeight: "bold"}}>すわらんといて</span>。<span
    style={{fontWeight: "bold"}}>うちのひ</span>とく<span
    style={{fontWeight: "bold"}}>ん</span>ねん。</small></li>
            <li><small>A: あ、<span style={{fontWeight: "bold"}}>ほ</span>な、そっち<span
    style={{fontWeight: "bold"}}>が</span>わ、<span style={{fontWeight: "bold"}}>い
    かしても</span>うても<span style={{fontWeight: "bold"}}>よ</span>ろしですか。</small></li>
            <li><small>B: <span style={{fontWeight: "bold"}}>せっかくひ</span>とが<span
    style={{fontWeight: "bold"}}>え</span>いが<span style={{fontWeight: "bold"}}>み
    てん</span>のに、ま<span style={{fontWeight: "bold"}}>え</span>ぇ<span
    style={{fontWeight: "bold"}}>と</span>おったら<span style={{fontWeight: "bold"}}>い
    かん</span>がな。</small></li>
            <li><small>A: <span style={{fontWeight: "bold"}}>ほ</span>したら、<span
    style={{fontWeight: "bold"}}>ひと</span>つつめて<span
    style={{fontWeight: "bold"}}>も</span>ろてもいいです<span
    style={{fontWeight: "bold"}}>か</span>。</small></li>
            <li><small>B: <span style={{fontWeight: "bold"}}>ひゃくまんえんく</span>れ
    たらつめた<span style={{fontWeight: "bold"}}>る</span>で。</small></li>
            <li><small>A: <span style={{fontWeight: "bold"}}>おばちゃん</span>。ええか
    げんに<span style={{fontWeight: "bold"}}>して</span>えや！</small></li>
          </ol>
  </>;

  const standardTranscript3 = <>
          <ol class="transcript">
            <li><small>A: すみません。ここの{addRubyText("席","せき")}に{addRubyText("座","すわ")}らせてもらってもかまいませんか。</small></li>
            <li><small>B: あ、ごめんね。そこは座らないで。うちの{addRubyText("人","ひと")}が{addRubyText("来","く")}るの。</small></li>
            <li><small>A: あ、じゃあ、そちら{addRubyText("側","がわ")}に{addRubyText("行","い")}かせてもらってもよろしいですか。</small></li>
            <li><small>B:&nbsp;
    せっかく人が{addRubyText("映画","えいが")}{addRubyText("見","み")}てるのに、{addRubyText("前","まえ")}を{addRubyText("通","とお")}っちゃいけないでしょ。</small></li>
            <li><small>A: それじゃあ、{addRubyText("一","ひと")}つ{addRubyText("詰","つ")}めてもらってもいいですか。</small></li>
            <li><small>B: {addRubyText("百万円","ひゃくまんえん")}くれたら詰めてあげるわよ。</small></li>
            <li><small>A:おばさん、いい{addRubyText("加減","かげん")}にしてよ！</small><br />
            </li>
          </ol>
  </>;

  const kansaiTranscript4 = <>
          <ol class="transcript">
            <li><small>A: お<span style={{fontWeight: "bold"}}>か</span>あさん、た<span
    style={{fontWeight: "bold"}}>だ</span>い<span style={{fontWeight: "bold"}}>ま</span>。<span
    style={{fontWeight: "bold"}}>あ〜</span>しん<span style={{fontWeight: "bold"}}>ど</span>。</small></li>
            <li><small>B: おかえ<span style={{fontWeight: "bold"}}>り</span>。あ、<span
    style={{fontWeight: "bold"}}>そんなと</span>こ、<span
    style={{fontWeight: "bold"}}>ねころば</span>んと。</small></li>
            <li><small>A: いま<span style={{fontWeight: "bold"}}>かえってき</span>た<span
    style={{fontWeight: "bold"}}>と</span>こやん。<span style={{fontWeight: "bold"}}>ちょ</span>っ
    と<span style={{fontWeight: "bold"}}>やすまして</span>えや。</small></li>
            <li><small>B: そこ、<span style={{fontWeight: "bold"}}>ねころ</span>んだ<span
    style={{fontWeight: "bold"}}>あかん</span>ねん！</small></li>
            <li><small>A: <span style={{fontWeight: "bold"}}>ちょっとだけ</span>え<span
    style={{fontWeight: "bold"}}>え</span>やん。あれ？こ<span
    style={{fontWeight: "bold"}}>こ</span>、びちょび<span
    style={{fontWeight: "bold"}}>ちょ</span>や！</small></li>
            <li><small>B:そ<span style={{fontWeight: "bold"}}>こ</span>、さっ<span
    style={{fontWeight: "bold"}}>き</span>ぃ<span style={{fontWeight: "bold"}}>みず
    こ</span>ぼしてん。</small></li>
            <li><small>A: なんで<span style={{fontWeight: "bold"}}>それさ</span>き<span
    style={{fontWeight: "bold"}}>ゆうてく</span>れ<span style={{fontWeight: "bold"}}>へ
    ん</span>かったん！</small></li>
          </ol>
  </>;

  const standardTranscript4 = <>
          <ol class="transcript">
            <li><small>A:お{addRubyText("母","かあ")}さん、ただいま。あ〜{addRubyText("疲","つか")}れた。</small></li>
            <li><small>B:お{addRubyText("帰","かえ")}り。あ、そんなところで{addRubyText("寝転","ねころ")}んじゃだめ。</small></li>
            <li><small>A: {addRubyText("今","いま")}{addRubyText("帰","かえ")}ってきた{addRubyText("所","ところ")}じゃない。ちょっと{addRubyText("休","やす")}ませてよ。</small></li>
            <li><small>B: そこに寝転んじゃだめなの！</small></li>
            <li><small>A: ちょっとだけいいじゃない。あれ、ここ、びしょびしょ！</small></li>
            <li><small>B: そこ、さっき{addRubyText("水","みず")}こぼしたの。</small></li>
            <li><small>A:どうしてそれを{addRubyText("先","さき")}に{addRubyText("言","い")}ってくれなかったの！</small><br />
            </li>
          </ol>
  </>;

  return <>
    <h2>{addRubyText("基本会話","きほんかいわ")} (Core Conversations)</h2>
    <hr />
    <h3>{addRubyText("会話","かいわ")}１ : You may borrow this but… <br />
    </h3>
    *A=first speaker, B=second speaker<br />
    *Click "SHOW" for transcripts<br />
    <br />
    (A and B are friends)<br />
    <table style={{textAlign: "left", width: "100%"}} border="1"cellPadding="2"
    cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}} class="video"
    colSpan="1" rowSpan="2">{addVideoPlayer("4.1.cc.1.mp4")}</td>
          <td style={{verticalAlign: "top"}} class="transcriptcell">Kansai
    transcript 
            <Hideable content={kansaiTranscript1}/>
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}} class="transcriptcell">Standard
    transcript
            <Hideable content={standardTranscript1}/>
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    <small>*{addRubyText("汚","よご")}す soil, stain, make things dirty</small>
    <h3>{addRubyText("会話","かいわ")}2&nbsp; : What shouldn’t A have tasted? <br />
    </h3>
    (A is a daughter, B is A’s mother. )<br />
    <table style={{textAlign: "left", width: "100%"}} border="1"cellPadding="2"
    cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}} class="video"
    colSpan="1" rowSpan="2">{addVideoPlayer("4.1.cc.2.mp4")}</td>
          <td style={{verticalAlign: "top"}} class="transcriptcell">Kansai
    transcript 
            <Hideable content={kansaiTranscript2}/>
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}} class="transcriptcell">Standard
    transcript 
            <Hideable content={standardTranscript2}/>
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    <small>* おかず side dish<br />
    * ちょこっと(Kansai) = ちょっと<br />
    * {addRubyText("炊","た")}く (Kansai) ={addRubyText("煮","に")}る boil, cook, simmer<br />
    * {addRubyText("味見","あじみ")}する taste, take a taste<br />
    * {addRubyText("落","お")}とす drop, let fall<br />
    </small><br />
    <h3>{addRubyText("会話","かいわ")}3 : “Osaka no Oba-chan” at the movie theater <br />
    </h3>
    (A is looking for a seat at the movie theater.)<br />
    <table style={{textAlign: "left", width: "100%"}} border="1"cellPadding="2"
    cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}} class="video"
    colSpan="1" rowSpan="2">{addVideoPlayer("4.1.cc.3.mp4")}</td>
          <td style={{verticalAlign: "top"}} class="transcriptcell">Kansai
    transcript 
            <Hideable content={kansaiTranscript3}/>
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}} class="transcriptcell">Standard
    transcript 
            <Hideable content={standardTranscript3}/>
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    <small>* {addRubyText("詰","つ")}める stuff, make room (for another)<br />
    * ええ{addRubyText("加減","かげん")}にする (Kansai) ＝ いい{addRubyText("加減","かげん")}にする leave (a thing) half-way<br />
    </small><br />
    <h3>{addRubyText("会話","かいわ")}４ : Where can A lie down? <br />
    </h3>
    (A is a daughter. B is A’s mother)<br />
    <table style={{textAlign: "left", width: "100%"}} border="1"cellPadding="2"
    cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}} class="video"
    colSpan="1" rowSpan="2">{addVideoPlayer("4.1.cc.4.mp4")}</td>
          <td style={{verticalAlign: "top"}} class="transcriptcell">Kansai
    transcript
            <Hideable content={kansaiTranscript4}/>
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}} class="transcriptcell">Standard
    transcript 
            <Hideable content={standardTranscript4}/>
          </td>
        </tr>
      </tbody>
    </table>
    <small><br />
    * しんど（い）(Kansai)={addRubyText("疲","つか")}れた<br />
    * {addRubyText("寝転","ねころ")}ぶ lie down<br />
    * びちょびちょ (Kansai)=びしょびしょ dripping, heavily wet<br />
    * こぼす split<br />
    </small><br />
    <hr />
    <h3>{addRubyText("理解度","りかいど")}チェック (Check-up)&nbsp; <br />
    </h3>
    Figure out the Kansai-specific grammar and expressions that appear in
    the
    video clips. Refer to the standard and Kansai transcripts if necessary.<br />
    <br />
    <table style={{textAlign: "left", width: "100%"}} border="1"cellPadding="2"
    cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>Check-up<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>Answer<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>1) When you prohibit somebody
    informally from eating, what do you say in Kansai-ben? (list up four
    patterns)<br />
          </td>
          <td style={{verticalAlign: "top"}}><Hideable content={<>
    {addRubyText("食","た")}べた（ら）あかん・食べた（ら）いかん・食べんといて・食べんと！</>}/><br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>2) How do you ask for permission
    to eat in informal conversation in Kansai?&nbsp;<br />
          </td>
          <td style={{verticalAlign: "top"}}><Hideable content={<>{addRubyText("食","た")}べてもいい／ええ／かまへん？</>}/><br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>3) What about when asking
    politely in Kansai-ben?<br />
          </td>
          <td style={{verticalAlign: "top"}}><Hideable content={<>
    {addRubyText("食","た")}べてもいいですか／よろしですか／かましませんか</>}/><br />
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    <hr />
    <h3>{addRubyText("関西","かんさい")}{addRubyText("語彙","ごい")}チェック (Kansai Vocabulary Check-up) <br />
    </h3>
    What are the standard equivalent expression of the following Kansai
    words that are introduced in the core conversations of this lesson?<br />
    <br />
    <table style={{textAlign: "left", width: "60%"}} border="1"cellPadding="2"
    cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>Kansai<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>Standard<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>ちょこっと<br />
          </td>
          <td style={{verticalAlign: "top"}}><Hideable content={<>ちょっと</>}/><br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>ええ{addRubyText("加減","かげん")}にする<br />
          </td>
          <td style={{verticalAlign: "top"}}><Hideable content={<>いい{addRubyText("加減","かげん")}にする leave
    (a thing) half-way</>}/><br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>しんど（い）<br />
          </td>
          <td style={{verticalAlign: "top"}}><Hideable content={<>{addRubyText("疲","つか")}れた</>}/><br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>びちょびちょ</td>
          <td style={{verticalAlign: "top"}}><Hideable content={<>びしょびしょ dripping,
    heavily wet</>}/><br />
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    <hr />
    <h3>ノート (Miscellaneous note)</h3>
    1) As seen in cc2, Kansai-people prefer repeating the same expressions
    twice, especially in commands and when expressing volition such as
    あかんあかん・{addRubyText("食","た")}べよ食べよ！・{addRubyText("行","い")}こ行こ！・はよはよ！<br />
    <br />
    2) As seen in cc2, Kansai people, especially Kyoto people, tend to use
    ~しexpression to indicate only one reason, whereas this expression is
    usually used to state more than one reason in Standard.&nbsp; <br />
    <br />
    3) As seen in cc2 and cc3, short forms of causatives are preferred in
    Kansai-ben.<br />
    {addRubyText("食","た")}べるー＞食べさせるー＞食べさす<br />
    {addRubyText("行","い")}くー＞行かせるー＞行かす<br />
    {addRubyText("休","やす")}むー＞休ませるー＞休ます<br />
    するー＞させるー＞さす<br />
    {addRubyText("来","く")}るー＞{addRubyText("来","こ")}させるー＞{addRubyText("来","こ")}さす<br />
    <br />
  </>;
}

export default Conversations;
