import React from "react";
import { addRubyText, addImage, addAudio } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";

const P4 = () => {
  return <>
<h2>{addRubyText("練習","れんしゅう")}4: Utilization</h2>
What would you say in the following situations in Kansai-ben? Try to
ask
in several ways. <br />
*Answers vary; the recorded answers indicate those commonly used.<br />
<small>* Click "SHOW" if you need to refer to the transcript of the
example answers.&nbsp; <br />
*Move the mouse over to the speaker icon for sound<br />
<br />
</small>
<table style={{textAlign: "left", width: "100%"}} border="1"cellPadding="2"
 cellSpacing="2">
  <tbody>
    <tr>
      <td style={{verticalAlign: "top", textAlign: "center"}}>Situation<br />
      </td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>Example
Answer<br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>
      <table style={{textAlign: "center", width: "50%"}} align="right" border="0" cellPadding="2" cellSpacing="2">
        <tbody>
          <tr>
            <td style={{verticalAlign: "top"}}>{addImage("4.2.p.4.1.jpg")}</td>
          </tr>
        </tbody>
      </table>
1.&nbsp; Your host mother served <span style={{fontStyle: "italic"}}>nattoo
      </span>(fermented
soybeans) that you don’t like. Ask her (politely) if
it’s all right that not to eat n<span style={{fontStyle: "italic"}}>attoo</span>.<br />
      </td>
      <td style={{verticalAlign: "top"}}>
        {addAudio("4.2.p.4.1.mp3")} <Hideable content={<><small>{addRubyText("納豆","なっとう")}、
        {addRubyText("実","じつ")}はあんまり{addRubyText("好","す")}きちゃうんですけど、{addRubyText("食","た")}べんでもかまいませんか？／食べんでも
      いいですか？</small></>}/> <br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>2. You just ate dinner with your
friend, and your friend offered to pay for both of you. Make sure if
it’s really all right that you don’t pay anything.<br />
      </td>
      <td style={{verticalAlign: "top"}}>{addAudio("4.2.p.4.2.mp3")} <Hideable content={<><small>ほ
んまになんも{addRubyText("払","はら")}わんでええの？／{addRubyText("払","はら")}わんでかまへんの？</small></>}/></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>3.&nbsp; You haven’t finished
reading a magazine you have borrowed from your friend. Ask her if you
don’t need to return it to her by the end of the day.<br />
      </td>
      <td style={{verticalAlign: "top"}}>{addAudio("4.2.p.4.3.mp3")} <Hideable content={<><small>こ
の{addRubyText("雑誌","ざっし")}、{addRubyText("今日中","きょうじゅう")}に{addRubyText("返","かえ")}さんでもええやんな？／今日中に返さんでもかまへんやんな？</small></>}/><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>4. Ask your colleague until when
you have to make a copy of this document (<span
 style={{fontStyle: "italic"}}>shorui</span>).<br />
      </td>
      <td style={{verticalAlign: "top"}}>{addAudio("4.2.p.4.4.mp3")} <Hideable content={<>
こ<small>の{addRubyText("書類","しょるい")}いつまでにコピーせなあきませんの？／いつまでにコピーせんとあきませんの？</small></>}/><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>5. You want to go to
Nishinomiya, but you are not sure if the train you are on goes
there.&nbsp; Tell the conductor where you want to go, and ask him if
you have to transfer. <br />
      </td>
      <td style={{verticalAlign: "top"}}>{addAudio("4.2.p.4.5.mp3")} <Hideable content={<><small>
{addRubyText("西宮","にしのみや")}まで{addRubyText("行","い")}きたいんですけど、{addRubyText(" 乗","の")}り{addRubyText("換","か")}えなあきませんか？／乗り換えんとあきませんか？</small></>}/></td>
    </tr>
  </tbody>
</table>
  </>;
}

export default P4;
