import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";

const V11 = () => {
  const transcript = <>
    {addRubyText("孫","まご")}やらかて{addRubyText("下宿","げしゅく")}<span style={{textDecoration: "underline"}}>しとる</span>(Grammar 2)
    けど、あんなん{addRubyText("男","おとこ")}の{addRubyText("子","こ")}やったら，もう{addRubyText("邪魔","じゃま")}{addRubyText("臭","くさ")}かったら、どっかで<span
    style={{textDecoration: "underline"}}>{addRubyText("食","た")}べとる</span>(Grammar 2)
    やろなて。{addRubyText("学生","がくせい")}やったら。、、、そうそうそう、{addRubyText("今","いま")}{addRubyText("売","う")}ったるさかいなあ。ほしてな、バイト。ちょこっとしたとこバイトし
    て、そこでちょっとお{addRubyText("金","かね")}{addRubyText("出","だ")}して、ほんで{addRubyText("帰","かえ")}りしなに、食べさしてもうたり。、、うん、バイト、なんか、{addRubyText("飯代","めしだい")}{addRubyText("言","ゆ")}うて、{addRubyText("百円","ひゃくえん")}か二百円出すと{addRubyText("晩","ばん")}ご{addRubyText("飯","はん")}<span
    style={{textDecoration: "underline"}}>食べさしてくれはる</span>(Grammar 1)
    にゃて。ほと、その{addRubyText("方","ほう")}がな、その方がましか，言うて。なんしてんにゃ。ほんでも、男の子{addRubyText("一人","ひとり")}、なんとか下宿<span
    style={{textDecoration: "underline"}}>しとる</span>
    (Grammar 2) けどな。
  </>;


  const qaObjects = [
    {
      question: <span>Q1:What is her grandson’s living situation?</span>,
      answersCorrect: [
        <>Living by himself</>
      ],
      answersIncorrect: [
        <>Living with his family</>,
        <>Living with his roommates </>
      ]
    },
    {
      question: <span>Q2: What did she mention regarding the place at which her grandson works part-time? </span>,
      answersCorrect: [
        <>They feed her grandson if he pays 100 to 200-yen per meal.</>
      ],
      answersIncorrect: [
        <>They give him 100 to 200-yen for each meal.</>,
        <>They pay him only 100 to 200-yen per hour, which is not enough to buy food. </>
      ]
    },
    {
      question: <span>Q3: For whom did she use はる and とる respectively?</span>,
      answersCorrect: [
        <>はる for her grandson’s employer, とる for her grandson</>
      ],
      answersIncorrect: [
        <>はる for her grandson, とる for her grandson’s employer</>,
        <>はる for herself, とる for her grandson</>,
        <>はる for her grandson,とる for herself </>
      ]
    }
  ];

  return <>
    <h2>ビデオ{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}１１: Authentic - long<br />
    </h2>
    Watch the video clip and choose the answer that matches the content of
    the clip.
    Submit your answers after answering all the questions.<br />
    <small>*Click "SHOW" if you need to refer to the transcript
    </small><br />
    Context: Ishioh-san was talking about what her grandson eats.<br />
    <table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2"
    cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>{addVideoPlayer("4.4.2.p.3.ishioh.mp4")} </td>
          <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>Transcript
    <Hideable content={transcript}/>
    <br />
    ＊ 〜かて even … （refer to Ch4
    Lesson 5)<br />
    ＊{addRubyText("孫","まご")} grandchild<br />
    ＊{addRubyText("下宿","げしゅく")}lodge, board, room<br />
    ＊{addRubyText("邪魔臭","じゃまくさ")}い (Kansai) bothersome<br />
    ＊〜しな on one’s way to …<br />
    ＊{addRubyText("飯代","めしだい")} fee for meals<br />
    ＊〜{addRubyText("方","ほう")}がましだ&nbsp; better, I would rather …<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>イシオウさん
    (Shiga)</td>
        </tr>
      </tbody>
    </table>
    <br />
    <QandAForm qaObjects={qaObjects}/>
  </>;
}

export default V11;
