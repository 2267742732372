import React from "react";
import { addRubyText, addImage } from "common/util/generalUtil";
import { Link } from "react-router-dom";
import { Page } from "home/page";

const Practice = () => {
  return <>
    <h2>{addRubyText("練習","れんしゅう")} (Practice)</h2>
    <br/>
    <table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td colSpan="1" rowSpan="1" style={{verticalAlign: "top"}}>
            <ul>
              <li>
                <Link to={Page.BasicGrammar.SentenceEndings.Practice.P1}>
                  {addRubyText("練習","れんしゅう")}1: Assertion で
                </Link>
              </li>
              <li>
                <Link to={Page.BasicGrammar.SentenceEndings.Practice.P2}>
                  {addRubyText("練習","れんしゅう")}2: Conformation な
                </Link>
              </li>
              <li>
                <Link to={Page.BasicGrammar.SentenceEndings.Practice.P3}>
                  {addRubyText("練習","れんしゅう")}3: Light Assertion わ
                </Link>
              </li>
              <li>
                <Link to={Page.BasicGrammar.SentenceEndings.Practice.P4}>
                  {addRubyText("練習","れんしゅう")}4: Extended Predicate (Non-past)
                </Link>
              </li>
              <li>
                <Link to={Page.BasicGrammar.SentenceEndings.Practice.P5}>
                  {addRubyText("練習","れんしゅう")}5: Extended Predicate (Past)
                </Link>
              </li>
              <li>
                <Link to={Page.BasicGrammar.SentenceEndings.Practice.P6}>
                  {addRubyText("練習","れんしゅう")}6: 〜やんか
                </Link>
              </li>
              <li>
                <Link to={Page.BasicGrammar.SentenceEndings.Practice.P7}>
                  {addRubyText("練習","れんしゅう")}7: Quotation
                </Link>
              </li>
              <li>
                <Link to={Page.BasicGrammar.SentenceEndings.Practice.P8}>
                  {addRubyText("練習","れんしゅう")}8: XいうY
                </Link>
              </li>
            </ul>
            <br/>
          </td>
          <td colSpan="1" rowSpan="1" style={{verticalAlign: "top", textAlign: "center"}}>
            {addImage("heianjema.jpg")}
            <br/>
            <small>
              平安神宮の絵馬 Ema, Heian Jingu (Kyoto)
            </small>
            <br/>
          </td>
        </tr>
        <tr>
        </tr>
      </tbody>
    </table>
  </>;
}

export default Practice;