import React from "react";
import { addRubyText, addImage, addAudio } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";

const L2 = () => {
  const qaObjects = [
    {
      superQuestion: <>
        <span style={{fontWeight: "bold"}}>
        SET 1 </span>{addAudio("4.2.pl0.2.1.mp3")}<br />
        <Hideable content={<>
          <br />
          <ol>
            <li>A: {addRubyText("明日","あした")}のまゆみちゃんの{addRubyText("披露宴","ひろうえん")}、{addRubyText("行","い")}かんでもええやろか？</li>
            <li>B: そら、{addRubyText("友達","ともだち")}やし、行かんとあかんやろ。</li>
            <li>A: やっぱり、 行かんならんか。じゃーないな。</li>
          </ol>
        </>}/>
      </>
    },
    {
      question: <>Q1: What will A most likely to do tomorrow?</>,
      answersCorrect: [
        <>She will attend Mayumi’s reception reluctantly.</>
      ],
      answersIncorrect: [
        <>She will be absent from Mayumi’s wedding reception.</>,
        <>She will attend Mayumi’s wedding reception happily as her best friend.</>
      ]
    },
    {
      superQuestion: <>
        <span style={{fontWeight: "bold"}}>SET 2</span> {addAudio("4.2.pl0.2.2.mp3")}
        <br />
        <Hideable content={<><br />
          <ol>
            <li>A:せやけど{addRubyText("風邪","かぜ")}{addRubyText("引","ひ")}いた{addRubyText("言","ゆ")}うたら{addRubyText("休","やす")}んでもかまへんやろか？</li>
            <li>B: 、、そんな{addRubyText("行","い")}きとないの？</li>
            <li>A:うん、、、、{addRubyText("実","じつ")}は{addRubyText("彼女","かのじょ")}の{addRubyText("旦那","だんな")}さん、{addRubyText("私","わたし")}の{addRubyText("元彼","もとかれ")}やねん。</li>
            <li>B: うっ、、、それやったら、行かんでもええんちゃう？</li>
          </ol>
        </>}/>
      </>
    },
    {
      question: <>Q2: Now, what is B’s suggestion? </>,
      answersCorrect: [
        <>A does not have to attend the wedding reception.</>
      ],
      answersIncorrect: [
        <>A should attend the reception as Mayumi’s friend.</>,
        <>A should not show up at the wedding reception.</>
      ]
    },
    {
      question: <>Q3: Why did B suggest that to A?</>,
      answersCorrect: [
        <>The bridegroom is A’s ex-boyfriend.</>
      ],
      answersIncorrect: [
        <>A’s ex-boyfriend is also invited to the reception.</>,
        <>The bridegroom and A’s ex-boyfriend are good friends.</>
      ]
    }
  ]

  return <>
    <table align="right" border="0"cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>{addImage("4.2.pl0.2.1.jpg")}</td>
        </tr>
      </tbody>
    </table>
    <h2>{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}２</h2>
    Ms.A and Ms. B are talking about their friend Mayumi’s wedding
    reception. Listen to two sets of conversations between Ms. A and Ms. B,
    and choose the most appropriate answer
    from the following statements.<br />
    <small>* Click "SHOW" if you need to refer to the transcript.</small> <br />
    <br />
    <br />
    <QandAForm qaObjects={qaObjects}/>
  </>;
}

export default L2;
