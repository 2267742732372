import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";

const Conversations = () => {
  const kansaiTranscript1 = <>
    <br />
    <ol className="transcript">
      <li>
        <small>
          A: お<span style={{fontWeight: "bold"}}>か</span>あちゃん、<span style={{fontWeight: "bold"}}>このく</span>つ<span style={{fontWeight: "bold"}}>こうて</span>。&nbsp;&nbsp;&nbsp; 
        </small>
      </li>
      <li>
        <small>
          B: <span style={{fontWeight: "bold"}}>あかん</span>。
        </small>
      </li>
      <li>
        <small>
          A: <span style={{fontWeight: "bold"}}>こ</span>うてえや。
        </small>
      </li>
      <li>
        <small>
          B: あんた、<span style={{fontWeight: "bold"}}>よ</span>う<span style={{fontWeight: "bold"}}>に</span>たんもって<span style={{fontWeight: "bold"}}>る</span>やろ？。
        </small>
      </li>
      <li>
        <small>
          A: う<span style={{fontWeight: "bold"}}>う</span>ん、もっ<span style={{fontWeight: "bold"}}>て</span>へん。
        </small>
      </li>
      <li>
        <small>
          B: <span style={{fontWeight: "bold"}}>ほんで</span>も、<span style={{fontWeight: "bold"}}>あかん</span>。
        </small>
      </li>
      <li>
        <small>
          A: なんで<span style={{fontWeight: "bold"}}>こうてく</span>れへんの？
        </small>
      </li>
      <li>
        <small>
          B: <span style={{fontWeight: "bold"}}>さいふ</span>、<span style={{fontWeight: "bold"}}>わす</span>れてん。
        </small>
      </li>
      <li>
        <small>
          A: え？<span style={{fontWeight: "bold"}}>わす</span>れたん？
          ほん<span style={{fontWeight: "bold"}}>ま</span>？
        </small>
      </li>
      <li>
        <small>
          B: ま、こ<span style={{fontWeight: "bold"}}>ん</span>どこうたげるわ。
        </small>
      </li>
    </ol>
  </>;

  const standardTranscript1 = <>
    <br />
    <ol className="transcript">
      <li>
        <small>
          A: お{addRubyText("母","かあ")}さん、この{addRubyText("靴","くつ")}{addRubyText("買","か")}って。
        </small>
      </li>
      <li>
        <small>
          B: だめ。
        </small>
      </li>
      <li>
        <small>
          A: 買ってよ。
        </small>
      </li>
      <li>
        <small>
          B: あなた、よく{addRubyText("似","に")}たの、{addRubyText("持","も")}ってるでしょ？
        </small>
      </li>
      <li>
        <small>
          A: ううん、持ってない。
        </small>
      </li>
      <li>
        <small>
          B: それでも、だめ。
        </small>
      </li>
      <li>
        <small>
          A: どうして買ってくれないの？
        </small>
      </li>
      <li>
        <small>
          B: {addRubyText("財布","さいふ")}、{addRubyText("忘","わす")}れたの。
        </small>
      </li>
      <li>
        <small>
          A: え？忘れたの？ほんとに？
        </small>
      </li>
      <li>
        <small>
          B: ま、{addRubyText("今度","こんど")}買ってあげるわよ。
        </small>
      </li>
    </ol>
  </>;

  const kansaiTranscript2 = <>
    <br />
    <ol className="transcript">
      <li>
        <small>
          A: コー<span style={{fontWeight: "bold"}}>ヒ</span>ーの<span
          style={{fontWeight: "bold"}}>ま</span>へん？
        </small>
      </li>
      <li>
        <small>
          B: え？い<span style={{fontWeight: "bold"}}>ま</span>あ<span
          style={{fontWeight: "bold"}}>ん</span>の？
        </small>
      </li>
      <li>
        <small>
          A: <span style={{fontWeight: "bold"}}>う</span>ん、<span
          style={{fontWeight: "bold"}}>み</span>かちゃん、の<span
          style={{fontWeight: "bold"}}>ま</span>んと<span style={{fontWeight: "bold"}}>か</span>えってん。
        </small>
      </li>
      <li>
        <small>
          B: へえ。<span style={{fontWeight: "bold"}}>それ</span>まだ<span
          style={{fontWeight: "bold"}}>ぬ</span>くい？
        </small>
      </li>
      <li>
        <small>
          A: <span style={{fontWeight: "bold"}}>う</span>ん。<span
          style={{fontWeight: "bold"}}>ぬ</span>くい<span style={{fontWeight: "bold"}}>ぬ</span>く
          い。<span style={{fontWeight: "bold"}}>さ</span>とういれよ<span
          style={{fontWeight: "bold"}}>か</span>。
        </small>
      </li>
      <li>
        <small>
          B: うん、いれとい<span style={{fontWeight: "bold"}}>て</span>。
        </small>
      </li>
      <li>
        <small>
          A: <span style={{fontWeight: "bold"}}>ミ</span>ルクは？
        </small>
      </li>
      <li>
        <small>
          B: あ、<span style={{fontWeight: "bold"}}>ミ</span>ルクはいれんと
          い<span style={{fontWeight: "bold"}}>て</span>。
        </small>
      </li>
      <li>
        <small>
          A: <span style={{fontWeight: "bold"}}>わ</span>かった。<span
          style={{fontWeight: "bold"}}>はい</span>、<span style={{fontWeight: "bold"}}>ど
          うぞ〜</span>。
        </small>
      </li>
      <li>
        <small>
          B: ありが<span style={{fontWeight: "bold"}}>と</span>う、<span
          style={{fontWeight: "bold"}}>いただきま〜す</span>。<span
          style={{fontWeight: "bold"}}>うわっ</span>、めっちゃ<span
          style={{fontWeight: "bold"}}>あ</span>まいやん、これ。
        </small>
      </li>
      <li>
        <small>
          A: ごめ<span style={{fontWeight: "bold"}}>ん</span>、<span
          style={{fontWeight: "bold"}}>さ</span>とういれすぎ<span
          style={{fontWeight: "bold"}}>て</span>もた。
        </small>
      </li>
    </ol>
  </>;

  const standardTranscript2 = <>
    <br />
    <ol className="transcript">
      <li>
        <small>
          A: コーヒー{addRubyText("飲","の")}まない？
        </small>
      </li>
      <li>
        <small>
          B: え？{addRubyText("今","いま")}、あるの？
        </small>
      </li>
      <li>
        <small>
          A: うん、みかちゃん、飲まないで{addRubyText("帰","かえ")}ったの。
        </small>
      </li>
      <li>
        <small>
          B: へえ。それ、まだ{addRubyText("温","あたた")}かい？
        </small>
      </li>
      <li>
        <small>
          A: うん、温かいよ。{addRubyText("砂糖","さとう")}、{addRubyText("入","い")}れようか？
        </small>
      </li>
      <li>
        <small>
          B: うん、入れといて。
        </small>
      </li>
      <li>
        <small>
          A: ミルクは？
        </small>
      </li>
      <li>
        <small>
          B: あ、ミルクは入れないで。
        </small>
      </li>
      <li>
        <small>
          A: わかった。はい、どうぞ。
        </small>
      </li>
      <li>
        <small>
          B: ありがとう、いただきま〜す。うわっ、すごく{addRubyText("甘","あま")}いじゃん、これ。
        </small>
      </li>
      <li>
        <small>
          A: ごめん、砂糖入れ{addRubyText("過","す")}ぎちゃった。
        </small>
      </li>
    </ol>
  </>;

  const kansaiTranscript3 = <>
    <br />
    <ol className="transcript">
      <li>
        <small>
          A: お<span style={{fontWeight: "bold"}}>ね</span>えちゃん、きょう<span
          style={{fontWeight: "bold"}}>デ</span>ートやねんけど<span
          style={{fontWeight: "bold"}}>この</span>ワン<span style={{fontWeight: "bold"}}>ピ</span>ー
          ス<span style={{fontWeight: "bold"}}>かして</span>ぇや。
        </small>
      </li>
      <li>
        <small>
          B: <span style={{fontWeight: "bold"}}>しゃ</span>あない<span
          style={{fontWeight: "bold"}}>な</span>あ。<span style={{fontWeight: "bold"}}>か
          したる</span>わ。<span style={{fontWeight: "bold"}}>け</span>ど、しわし<span
          style={{fontWeight: "bold"}}>わ</span>や<span style={{fontWeight: "bold"}}>で</span>。
        </small>
      </li>
      <li>
        <small>
          A: <span style={{fontWeight: "bold"}}>ほ</span>な、アイロ<span
          style={{fontWeight: "bold"}}>ン</span>かけ<span style={{fontWeight: "bold"}}>て</span>ぇな。
        </small>
      </li>
      <li>
        <small>
          B: <span style={{fontWeight: "bold"}}>しゃ</span>あない<span
          style={{fontWeight: "bold"}}>な</span>あ。かけた<span
          style={{fontWeight: "bold"}}>る</span>わ。
        </small>
      </li>
      <li>
        <small>
          A: ありが<span style={{fontWeight: "bold"}}>と</span>う、、、う
          わ〜、<span style={{fontWeight: "bold"}}>ど</span>きどき<span
          style={{fontWeight: "bold"}}>してき</span>た。
        </small>
      </li>
      <li>
        <small>
          B: <span style={{fontWeight: "bold"}}>しゃ</span>あない<span
          style={{fontWeight: "bold"}}>な</span>あ、<span style={{fontWeight: "bold"}}>ほ</span>な、
          あたしが<span style={{fontWeight: "bold"}}>デ</span>ート<span
          style={{fontWeight: "bold"}}>いったる</span>わ。
        </small>
      </li>
    </ol>
  </>;

  const standardTranscript3 = <>
    <br />
    <ol className="transcript">
      <li>
        <small>
          A: お{addRubyText("姉","ねえ")}ちゃん、{addRubyText("今日","きょう")}デートなんだけど、このワンピース{addRubyText("貸","か")}してね。
        </small>
      </li>
      <li>
        <small>
          B: {addRubyText("仕方","しかた")}ないわねえ。{addRubyText("貸","か")}したげるよ。でも、しわくちゃよ。
        </small>
      </li>
      <li>
        <small>
          A: じゃあ、アイロンかけてよ。
        </small>
      </li>
      <li>
        <small>
          B:仕方ないわねえ。かけたげるよ。
        </small>
      </li>
      <li>
        <small>
          A: ありがとう、、、うわ〜、どきどきしてきた。
        </small>
      </li>
      <li>
        <small>
          B: 仕方ないわねえ、じゃ、{addRubyText("私","わたし")}がデートに{addRubyText("行","い")}ってあげるよ。
        </small>
      </li>
    </ol>
  </>;


  return <>
    <h2>{addRubyText("基本会話","きほんかいわ")} (Core Conversations)</h2>
    <hr />
    <h3>{addRubyText("会話","かいわ")}１ : Was A succesful to have her mother buy a new pair of shoes?</h3>
    *A=first speaker, B=second sepaker<br />
    *Click "SHOW" for transcripts<br />
    <br />
    (A is a daughter of B. They are at a shoe store...)<br />
    <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}} className="video" colSpan="1" rowSpan="2">
            {addVideoPlayer("3.2.cc.1.mp4")}<br />
          </td>
          <td style={{verticalAlign: "top"}} className="transcriptcell">
            Kansai transcript 
            <Hideable content={kansaiTranscript1} />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}} className="transcriptcell">
            Standard transcript
            <Hideable content={standardTranscript1} />
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    <h3>{addRubyText("会話","かいわ")}２ : How does B like her coffee?</h3>
    (A=younger sister, B=older sister.&nbsp; A's friend visited her and just left...)<br />
    <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}} className="video" colSpan="1" rowSpan="2">
            {addVideoPlayer("3.2.cc.2.mp4")}<br />
          </td>
          <td style={{verticalAlign: "top"}} className="transcriptcell">
            Kansai transcript 
            <Hideable content={kansaiTranscript2} />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}} className="transcriptcell">
            Standard transcript 
            <Hideable content={standardTranscript2} />
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    <h3>{addRubyText("会話","かいわ")}３ : What is an older sister for??</h3>
    (A=younger sister, B=older sister. Today is a big day for A...)<br />
    <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}} className="video" colSpan="1" rowSpan="2">
            {addVideoPlayer("3.2.cc.3.mp4")}<br />
          </td>
          <td style={{verticalAlign: "top"}} className="transcriptcell">
            Kansai transcript 
            <Hideable content={kansaiTranscript3} />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}} className="transcriptcell">
            Standard transcript 
            <Hideable content={standardTranscript3} />
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    <small>＊ しわしわ：しわくちゃ terribly wrinkled</small><br />
    <br />
    <hr />
    <h3>{addRubyText("理解度","りかいど")}チェック&nbsp; (Check-up) <br /></h3>
    <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Check-up<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Answer<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>1) What is the TE-form of {addRubyText("買","か")}う&nbsp; in Kansai-ben? <br />
          </td>
          <td style={{verticalAlign: "top"}}><Hideable content={<>{addRubyText("買","こ")}うて</>} /><br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            2) What are the Kansai equivalents of {addRubyText("持","も")}っている／{addRubyText("持","も")}っていない ？<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <Hideable content={<>{addRubyText("持","も")}ってる／{addRubyText("持","も")}ってへん</>} /><br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            3) What is the difference between {addRubyText("忘","わす")}れてん and {addRubyText("忘","わす")}れたん&nbsp; in terms of their use?<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <Hideable content={<>{addRubyText("忘","わす")}れてん for the statement,{addRubyText("忘","わす")}れたん for the question </>} /><br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            4) How do you say {addRubyText("飲","の")}まないで{addRubyText("帰","かえ")}る in Kansai-ben?<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <Hideable content={<>{addRubyText("飲","の")}まんと{addRubyText("帰","かえ")}る</>} /><br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            5) What form is used as Negative TE-form in Kansai-ben? <br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <Hideable content={<>Verb negative (short from) + と</>} /><br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            6) How do you ask someone not to pour (milk in the coffee) in Kansai-ben?<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <Hideable content={<>{addRubyText("入","い")}れんといて</>} /><br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            7) What is the Kansai equivalent of 〜てしまった? <br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <Hideable content={<>〜てもた</>} /><br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            8) What is the Kansai equivalent of 〜てあげる？<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <Hideable content={<>〜たげる／〜たる</>} /><br />
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    <br />
    <hr />
    <h3>{addRubyText("関西","かんさい")}{addRubyText("語彙","ごい")}チェック&nbsp; (Kansai Vocabulary check-up)&nbsp;</h3>
    What are the standard equivalent expressions of the following Kansai
    words that are introduced in the core conversations of this lesson?<br />
    <br />
    <table style={{textAlign: "left", width: "60%"}} border="1" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Kansai<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Standard<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            あかん<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <Hideable content={<>だめ</>} /><br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            よう<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <Hideable content={<>よく</>} /><br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            ぬくい<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <Hideable content={<>{addRubyText("温","あたた")}かい</>} /><br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            めっちゃ<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <Hideable content={<>すごく</>} /><br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            しゃあない<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <Hideable content={<>{addRubyText("仕方","しかた")}がない（しょうがない）</>} /><br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            しわしわ<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <Hideable content={<>しわくちゃ</>} /><br />
          </td>
        </tr>
      </tbody>
    </table>
  </>;
}

export default Conversations;
