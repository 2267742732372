import React from "react";
import { addRubyText, addAudio } from "common/util/generalUtil";

const PastNegative = () => {
  return <>
    <h2>Past Negative From</h2>
    Once you acquired the basic formation of non-past negative verb, it is
    fairly easy to form past negatives: all you have to do is add かった after
    the non-past negatives such as;
    <br />
    <small>
      <br />
      *<span style={{fontWeight: "bold"}}>BOLD</span> type indicates high pitch
      <br />
      *Mouse over or click on the speaker icon for sound
    </small>
    <br />
    <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>
            <br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Non-past negative
            <br />
            (example)
            <br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Past negative<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            (audio)<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addRubyText("食","た")}べる<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            た<span style={{fontWeight: "bold"}}>べ</span>へん<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            た<span style={{fontWeight: "bold"}}>べ</span>へんかった<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            {addAudio("3.1.a.2.1.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addRubyText("寝","ね")}る<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <span style={{fontWeight: "bold"}}>ね</span>ぇへん<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <span style={{fontWeight: "bold"}}>ね</span>ぇへんかった<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            {addAudio("3.1.a.2.2.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addRubyText("借","か")}りる<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <span style={{fontWeight: "bold"}}>か</span>りひん<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <span style={{fontWeight: "bold"}}>か</span>りひんかった<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            {addAudio("3.1.a.2.3.mp3")}
            <br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addRubyText("見","み")}る<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <span style={{fontWeight: "bold"}}>み</span>ぃひん<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <span style={{fontWeight: "bold"}}>み</span>ぃひんかった<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            {addAudio("3.1.a.2.4.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            ある<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            あ<span style={{fontWeight: "bold"}}>ら</span>へん<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            あ<span style={{fontWeight: "bold"}}>ら</span>へんかった<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            {addAudio("3.1.a.2.5.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addRubyText("飲","の")}む<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            の<span style={{fontWeight: "bold"}}>ま</span>へん<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            の<span style={{fontWeight: "bold"}}>ま</span>へんかった<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            {addAudio("3.1.a.2.6.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            する<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <span style={{fontWeight: "bold"}}>し</span>ぃひん<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <span style={{fontWeight: "bold"}}>し</span>ぃひんかった<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            {addAudio("3.1.a.2.7.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addRubyText("来","く")}る<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <span style={{fontWeight: "bold"}}>こ</span>ぅへん<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <span style={{fontWeight: "bold"}}>こ</span>ぅへんかった<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            {addAudio("3.1.a.2.8.mp3")}<br />
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    *The short form 〜ん+かった (c.f. 食べんかった、見んかった)&nbsp; is also
    commonly used as the equivalent to 〜へんかった ／〜ひんかった. <br />
    <br />
    <hr />
    <h3>Extra 1: {addRubyText("食","た")}べへんた／{addRubyText("食","た")}べなんだ <br /></h3>
    There are some variations on past negatives. Some use 〜へんた／〜ひんた, an
    abbreviation of 〜へんかった／〜ひんかった as past negatives, such as <br />
    <br />
    食<span style={{fontWeight: "bold"}}>べ</span>へんた；あ
    <span style={{fontWeight: "bold"}}>ら</span>へんた；飲
    <span style={{fontWeight: "bold"}}>ま</span>へんた；<span style={{fontWeight: "bold"}}>せ</span>え
    へんた；<span style={{fontWeight: "bold"}}>{addRubyText("来","こ")}</span>うへんた {addAudio("3.1.a.2.9.mp3")}
    <br />
    <br />
    You might hear elderly people say 〜なんだ such as<br />
    <br />
    食<span style={{fontWeight: "bold"}}>べ</span>なんだ；
    <span style={{fontWeight: "bold"}}>寝</span>なんだ；あ
    <span style={{fontWeight: "bold"}}>ら</span>なんだ；飲
    <span style={{fontWeight: "bold"}}>ま</span>なんだ；<span style={{fontWeight: "bold"}}>せ</span>なんだ；
    <span style={{fontWeight: "bold"}}>{addRubyText("来","こ")}</span>なんだ {addAudio("3.1.a.2.10.mp3")}
    <br />
    <br />
    &nbsp;Don’t confuse this with extended predicate expression(〜んです／〜んだ）
    in Standard, such as in {addRubyText("今日","きょう")}デート
    <span style={{textDecoration: "underline"}}>なんだ</span>よ. Actually, this
    [Negative stem]+なんだ is also a past negative form commonly used by elderly people.
    <br />
    <br />
    {addRubyText("朝","あさ")}のニュース、{addRubyText("聞","き")}いた？
    <br />
    →　(20 years old)ううん、聞かへんかった。 {addAudio("3.1.a.2.11.mp3")}
    <br />
    →　(80 years old) いや、聞かなんだ。 {addAudio("3.1.a.2.12.mp3")}
    <br />
    <small>
      &nbsp;&nbsp; (ST 朝のニュース、聞いた？ー＞ううん、聞かなかった。) {addAudio("3.1.a.2.11.s.mp3")}
    </small>
  </>;
}

export default PastNegative;