import React from "react";

export const addHrefLink = (link: string, name?: string) => (
  <a href={link}>{name != null ? name : link}</a>
);

export const addTableCol = (headerText: string, bodyTextList: any[]) => {
  return (
    <tr>
      <td>
        <b>{headerText}</b>
      </td>
      <td>
        {bodyTextList.map(
          (bodyText: any) => <div>{bodyText}</div>
        )}
      </td>
    </tr>
  )
};

export const addRubyText = (jpText: string, annotation: string) => {
  if (jpText != null && annotation != null) {
    return (
      <ruby>
        <rb>{jpText}</rb>
        <rp>(</rp>
        <rt>{annotation}</rt>
        <rp>)</rp>
      </ruby>
    )
  }
};

// TODO: Handle scenario where no | is found between inner + outer quotes
export const toRubyText = (text) => {
  var newText = [];
  for (var i=0, nextI; i>=0 && i < text.length; ) {
    nextI = text.indexOf('「', i);
    if (nextI < 0) {
      newText.push(text.substring(i, text.length));
      break;
    }
    if (nextI > 0) {
      newText.push(text.substring(i, nextI));
    }
    i = nextI + 1;
    nextI = text.indexOf('｜', i);
    const jpText = text.substring(i, nextI);
    i = nextI + 1;
    nextI = text.indexOf('」', i);
    const annotation = text.substring(i, nextI);
    newText.push(addRubyText(jpText, annotation));
    i = nextI + 1;
  }
  newText = <>
    {newText.map((word) => {
      return <>
        {word}
      </>
    })}
  </>;
  console.log(newText);
  return newText;
}

export const addImage = (filename: string) => {
  return (
    <img src={"http://kansaiben.com/image/" + filename}  alt={filename}/>
  )
};

export const addAudio = (filename) => {
  let resetAudio = e => {
    let audioElement = e.target.firstChild;
    audioElement.pause();
    audioElement.currentTime = 0;
  };

  let playAudio = e => {
    resetAudio(e);
    let audioElement = e.target.firstChild;
    var promise = audioElement.play();

    if (promise !== undefined) {
      promise.catch(error => {
        // auto-play prevented
        // TODO: show a UI element to allow user to manually start playback
      }).then(() => {
        // auto-play started
      });
    }
  };

  return (
    <div className="sound" onMouseOver={playAudio} onClick={playAudio} onMouseOut={resetAudio}>
      <audio src={"http://kansaiben.com/sound/" + filename} />
    </div>
  )
};

export const addVideoPlayer = (filename: string) => {
  return (
    <div style={{border: "thick solid grey"}}>
      <video width="320" height="262" controls>
        <source src={"http://kansaiben.com/video_mp4/" + filename} type="video/mp4" />
        Your browser does not support HTML5 video.
      </video>
    </div>
  )
};

export const sortObjects = (objects, fieldOrder) => {
  const field = fieldOrder.field;
  const asc = fieldOrder.order;
  if (field === null) {
    return objects;
  }
  const comparator = (a, b) => {
    if (a[field] !== b[field]) {
      const gt = a[field] < b[field];
      return asc ^ gt ? -1 : 1;
    }
  }
  return [...objects].sort(comparator);
}