import React from "react";
import { addRubyText, addAudio } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";

const ExtendedPredicate = () => {
  return <>
    <h2>Extended Predicate <br />
    </h2>
    <small>*Move the mouse over the speaekr icon for sound</small><br />
    <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>
            Extended Predicate<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            Standard<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            Kansai<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            Statement<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            の (feminine)<br />
            んだ<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            ねん<br />
            にゃ（ねや／のや）<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            Question<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            の？<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            の？／のん？<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            WH quastion<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            の？<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            の？／のん？／ねん？／にゃ？<br />
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    Extended predicates in the Kansai dialect is の／のん？ in question, and
    ねん／んや (sometimes shortened to にゃ , consisting of one mora) is used for
    statements by both male and female.
    <br />
    <br />
    {addRubyText("今日","きょう")}{addRubyText("行","い")}く<span style={{textDecoration: "underline"}}>ん</span>／<span
    style={{textDecoration: "underline"}}>のん</span>？→うん、行く<span
    style={{textDecoration: "underline"}}>ねん</span>。<small></small>{addAudio("3.1.b.2.1.mp3")}
    <br />
    <small>(ST 今日行くの？ー＞うん、行くんだよ。）</small><small>{addAudio("3.1.b.2.1.s.mp3")}</small>
    <br />
    <br />
    {addRubyText("寒","さむ")}い<span style={{textDecoration: "underline"}}>ん</span>／<span
    style={{textDecoration: "underline"}}>のん</span>？→うん、寒い<span
    style={{textDecoration: "underline"}}>ねん</span>。<small></small>{addAudio("3.1.b.2.2.mp3")}
    <br />
    <small>(ST 寒いの？ー＞うん、寒いんだよ。）</small><small>{addAudio("3.1.b.2.2.s.mp3")}</small>
    <br />
    <br />
    {addRubyText("友達","ともだち")}な<span style={{textDecoration: "underline"}}>ん</span>／や<span
    style={{textDecoration: "underline"}}>のん</span>？→うん、友達や<span
    style={{textDecoration: "underline"}}>ねん</span>。{addAudio("3.1.b.2.3.mp3")}
    <br />
    <small>(ST 友達なの？ー＞うん、友達なんだよ。）</small><small>{addAudio("3.1.b.2.3.s.mp3")}</small>
    <br />
    <br />
    You can use the same expression for です／ます style conversation. (This expression is often used by older generation.)
    <br />
    <br />
    今日、{addRubyText("何","なに")}します<span style={{textDecoration: "underline"}}>のん</span>？→
    {addRubyText("図書館","としょかん")}に行きます<span style={{textDecoration: "underline"}}>ねん</span>。<small></small>{addAudio("3.1.b.2.4.mp3")}
    <br />
    <small>(ST
    今日何をするんですか。ー＞図書館に行くんですよ。）</small><small>{addAudio("3.1.b.2.4.s.mp3")}</small>
    <br />
    <br />
    When it comes to an interrogative question (WH question) using a
    question word such as なに／どこ／なんぼ (how much), any extended predicate can
    be used. In this case ねん／にゃ expresses stronger emotion of the speaker than ん／のん.
    <br />
    <br />
    ちょっと、何すん<span style={{textDecoration: "underline"}}>の</span>＜すん<span
    style={{textDecoration: "underline"}}>ねん</span>！？<small> </small>{addAudio("3.1.b.2.8.mp3")}
    <br />
    <small>（ST ちょっと、何するの！？）</small><small>{addAudio("3.1.b.2.5.s.mp3")}</small>
    <br />
    <br />
    <hr />
    <h3>Extra 1: したん？うん、してん<br /></h3>
    When adding extended predicate on the past statement, a sound change occurs such as ~Aん→~Eん.
    <br />
    <br />
    何{addRubyText("作","つく")}っ<span style={{textDecoration: "underline"}}>たん</span>？→ケーキ作 っ<span
    style={{textDecoration: "underline"}}>てん</span>。<small></small>{addAudio("3.1.b.2.5.mp3")}
    <br />
    <small>(ST 何作ったの？ー＞ケーキ作ったの／んだ。）</small><small>{addAudio("3.1.b.2.6.s.mp3")}</small>
    <br />
    <br />
    {addRubyText("昨日","きのう")}の{addRubyText("晩","ばん")}、こっち、寒かっ<span style={{textDecoration: "underline"}}>てん</span>で。
    →へえ、そうかあ。<small></small>{addAudio("3.1.b.2.6.mp3")}
    <br />
    <small>(ST 昨日の晩、こっちは寒かったんだよ。ー＞へえ、そう。）</small><small>{addAudio("3.1.b.2.7.s.mp3")}</small>
    <br />
    <br />
    昨日テレビ{addRubyText("見","み")}まし<span style={{textDecoration: "underline"}}>たん</span>？→い
    や、見られませんでし<span style={{textDecoration: "underline"}}>てん</span>。*Usually used by older generation {addAudio("3.1.b.2.7.mp3")}
    <br />
    <small>(ST 昨日テレビ見たんですか。ー＞いえ、見られなかったんです。）</small><small>{addAudio("3.1.b.2.8.s.mp3")}</small>
    <br />
    <br />
    When it is a past interrogative question (WH question), 〜てん（〜たねん） can
    be used besides 〜たん. In this case, 〜てん&nbsp; expresses stronger emotion
    of the speaker than 〜たん.
    <br />
    <br />
    昨日、だれに{addRubyText("会","お")}う<span style={{textDecoration: "underline"}}>たん</span>？
    ＜会う<span style={{textDecoration: "underline"}}>てん</span>？<small> </small>{addAudio("3.1.b.2.9.mp3")}
    <br />
    <small>（ST 昨日、だれに{addRubyText("会","あ")}ったの？）</small><small>{addAudio("3.1.b.2.9.s.mp3")}</small>
    <br />
    <br />
    <hr />
    <h3>Extra 2: {addRubyText("寝","ね")}んねん<br /></h3>
    When の／ねん／にゃ follow a verb that ends with る, a sound change from る to ん
    tends to occur. Can you figure out which verbs the followings are?
    <br />
    <br />
    {addAudio("3.1.b.2.10.mp3")}<br />
    <table style={{textAlign: "left", width: "60%"}} border="1" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>a. たべんねん。<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <Hideable content={<>{addRubyText("食","た")}べるの。</>} /><br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>b. ねんの？<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <Hideable content={<>{addRubyText("寝","ね")}るの？</>} /></td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>c. あげんねん。<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <Hideable content={<>あげるの。</>} />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>d. あんにゃろ？<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <Hideable content={<>あるんだろ？</>} />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>e. あけんのん？<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <Hideable content={<>{addRubyText("開","あ")}けるの？</>} />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>f. とんにゃわ。<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            <Hideable content={<>{addRubyText("取","と")}るんだよ。</>} /><br />
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    <hr />
    <h3>Extra 3: Nominalizer ん／のん <br /></h3>
    As you know, Extended predicate の is a noun (nominal), so as ん／のん.
    Actually, の as “the one” and nominalizer の also tend to become ん／のん in
    Kansai.<br />
    <br />
    かばんな、ちっちゃい<span style={{textDecoration: "underline"}}>のん</span>{addRubyText("買","こ")}うてん。{addAudio("3.1.b.2.11.mp3")} 
    <br />
    <small>(ST かばんね、{addRubyText("小","ちい")}さいのを買ったの。)</small>
    <small>{addAudio("3.1.b.2.11.s.mp3")}</small>
    <br />
    <br />
    {addRubyText("料理","りょうり")}する<span style={{textDecoration: "underline"}}>ん</span>は{addRubyText("時間","じかん")}かか
    るけど、{addRubyText("食","た")}べん<span style={{textDecoration: "underline"}}>の</span>はいっつも{addRubyText("五分","ごふん")}。{addAudio("3.1.b.2.12.mp3")}
    <br />
    <small>&nbsp;
    (ST 料理するのは時間がかか
    るけど、食べるのはいつも五分。) </small><small>{addAudio("3.1.b.2.12.s.mp3")}</small>
    <br />
    <br />
  </>;
}

export default ExtendedPredicate;
