import React from "react";
import { Link } from "react-router-dom";
import { Page } from "home/page";
import { addRubyText, addImage } from "common/util/generalUtil";

const Grammar = () => {
  return <>
    <h2>{addRubyText("文法","ぶんぽう")}ノート (Grammar Note) </h2>
    <table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>
          <ol>
            <li>
              <Link to={Page.FunctionalGrammar.PermissionProhibition.Grammar.Prohibition}>
                <span style={{fontWeight: "normal"}}>〜たらいかん／あかん (Prohibition)</span>
              </Link>
            </li>
            <li>
              <Link to={Page.FunctionalGrammar.PermissionProhibition.Grammar.Permission}>
                〜てもいい／ええ (Permission)
              </Link>
            </li>
          </ol>
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addImage("osakapeople.jpg")}
            <br />
            <small>大阪人 Osakan</small>
            <br />
          </td>
        </tr>
      </tbody>
    </table>
  </>;
}

export default Grammar;
