import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";

const V8 = () => {
  const transcript = <>
    ま、{addRubyText("日本","にほん")}に{addRubyText("帰","かえ")}ったときに{addRubyText("言","い")}ってやりたいことは、ま、{addRubyText("電車","でんしゃ")}ん{addRubyText("中","なか")}で<span
    style={{textDecoration: "underline"}}>{addRubyText("化粧","けしょう")}すんな</span>
    (Grammar 2)、と。、ハハハ、、電車ん中でねえ。あれはちょっと{addRubyText("変","へん")}やなあ、と。
  </>;

  const qaObjects = [
    {
      question: <span>Q: What would she want to say to young Japanese people nowadays?</span>,
      answersCorrect: [
        <>Don’t apply makeup on the train! </>
      ],
      answersIncorrect: [
        <>Apply makeup if you ride on the train (no makeup is strange in public)!</>,
        <>Don’t talk about others’ makeup on the train! </>,
        <>Don’t fix their makeup on the train!</>
      ]
    }
  ]

  return <>
    <h2>ビデオ{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}８: Authentic – short</h2>
    Watch the video clip and choose the answer that matches the content
    ofthe clip.
    <br />
    <small>*Click "SHOW" if you need to refer to the transcript.
    </small><br />
    <br />
    Hatanaka is asked what she would like to say to young Japanese
    people nowadays.<br />
    <table style={{textAlign: "left", width: "100%"}} border="0"cellPadding="2"
    cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>{addVideoPlayer("4.3.2.p.1.hatanaka.mp4")} </td>
          <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>Transcript
    <Hideable content={transcript}/><br />
    ＊{addRubyText("化粧","けしょう")} makeup </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>ハタナカ (Nara)</td>
        </tr>
      </tbody>
    </table>
    <QandAForm qaObjects={qaObjects}/>
  </>;
}

export default V8;
