import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";

const BlackbeanTea2 = () => {
  const vocabList = <ul style={{display: "block", overflow: "visible"}}>
    <li>
      {addRubyText("梅干","うめぼ")}し pickled plum, pickled Japanese apricot
    </li>
    <li>
      {addRubyText("干","ほ")}し梅干し dried pickled plum
    </li>
    <li>
      {addRubyText("牡蠣","かき")} oyster
    </li>
    <li>
      サザエ turban shell, top shell
    </li>
    <li>
      いちじく fig
    </li>
    <li>
      {addRubyText("来","き")}はった (Kansai honorific – refer to Ch4.L4.G1)
    </li>
    <li>
      {addRubyText("干","ほ")}しみかん dried mandarin orange
    </li>
    <li>
      {addRubyText("携帯","けいたい")} cell phone
    </li>
    <li>
      {addRubyText("瓶詰","びんづ")}め bottled
    </li>
    <li>
      {addRubyText("煮","に")}る boil, cook, simmer
    </li>
    <li>
      {addRubyText("黒豆煮","くろまめに")} boiled black beans
    </li>
    <li>
      {addRubyText("脳","のう")} brain
    </li>
    <li>
      {addRubyText("活性化","かっせいか")}する activate, vitalize
    </li>
    <li>
      {addRubyText("肌","はだ")}skin
    </li>
    <li>
      つやつや smooth and shiny
    </li>
    <li>
      {addRubyText("荒","あ")}れる get rough
    </li>
    <li>
      {addRubyText("便秘","べんぴ")} constipation
    </li>
    <li>
      {addRubyText("正常化","せいじょうか")} normalize
    </li>
    <li>
      スマート slender, slim
    </li>
  </ul>;

  const qaObjects = [
    {
      question: <span>Q1: Why did the sales lady want to know how to say {addRubyText("梅干","うめぼ")}し in English?</span>,
      answersIncorrect: [
        <span>Not many foreigners like 梅干し, so she wants to convince them to buy it.</span>,
        <span>She knows its English equivalent, but she wanted to test the video shooter’s knowledge.</span>
      ],
      answersCorrect: [
        <span>A number of foreigners ask what 梅干し is, so she wants to make sure.</span>
      ]
    },
    {
      question: <span>Q2: What do foreigners usually buy, according to the sales lady?</span>,
      answersIncorrect: [
        <span>Dried fig only.</span>,
        <span>Various kinds, except for dried fig.</span>
      ],
      answersCorrect: [
        <span>Various kinds, including dried fig.</span>
      ]
    },
    {
      question: <span>Q3: The video shooter said 今言うてたら来はった. Who came?</span>,
      answersIncorrect: [
        <span>The store owner.</span>,
        <span>Other Japanese customers.</span>
      ],
      answersCorrect: [
        <span>Foreigners.</span>
      ]
    },
    {
      question: <span>Q4: Why did トモちゃん do to find out the English equivalent of いちじく？</span>,
      answersIncorrect: [
        <span>She text-messaged somebody to ask what it is.</span>,
        <span>She searched it online using her cell phone.</span>
      ],
      answersCorrect: [
        <span>She checked it using the dictionary software on her cell phone.</span>
      ]
    },
    {
      question: <span>Q5: How did the sales lady find out the English equivalent of {addRubyText("黒豆煮","くろまめに")}?</span>,
      answersIncorrect: [
        <span>She looked it up in the dictionary.</span>,
        <span>トモちゃん looked it up for her using a cell phone.</span>
      ],
      answersCorrect: [
        <span>She asked a foreign customer.</span>
      ]
    },
    {
      question: <span>Q6: How old is the sales lady?</span>,
      answersIncorrect: [
        <span>Almost 80.</span>,
        <span>Almost 60.</span>
      ],
      answersCorrect: [
        <span>Almost 70.</span>
      ]
    },
    {
      question: <span>Q7: What kind of benefit did the sales lady most appreciate by
    drinking black bean tea?</span>,
      answersIncorrect: [
        <span>Her skin has rejuvenated.</span>,
        <span>Her brain got vitalized.</span>
      ],
      answersCorrect: [
        <span>She isn’t constipated anymore.</span>
      ]
    },
    {
      question: <span>Q8: The sales lady mentioned some positive effect of black bean
              tea on weight control, and also pointed out that トモちゃん is slender. What
              was トモちゃん’s comment?</span>,
      answersIncorrect: [
        <span>My skirt makes me look slender!</span>,
        <span>The part hidden under my skirt is the only slender part!</span>,
        <span>I love being slender because I can enjoy wearing this skirt!</span>
      ],
      answersCorrect: [
        <span>The part hidden under my skirt is not slender at all!</span>
      ]
    }
  ];

  return (
    <>
      <h2>{addRubyText("黒豆茶","くろまめちゃ")}２ (Black bean tea at a shop in Kyoto, part 2)</h2>
      <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>
            [Context] After purchasing black
            beans for tea, トモちゃん and the video shooter keep chatting with the sales
            lady of the store in Kyoto that sells local specialties of <i>Tanba</i> area (located in the middle
            of Kyoto prefecture).
          </td>
        </tr>
        </tbody>
      </table>
      <br />
      <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addVideoPlayer("5.11.kuromame2.mp4")}<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            Vocabulary List <br />
            <Hideable content={vocabList} />
          </td>
        </tr>
        </tbody>
      </table>
      <h3>{addRubyText("内容質問","ないようしつもん")} (Content Questions)</h3>
      <QandAForm qaObjects={qaObjects} />
    </>
  );
};

export default BlackbeanTea2;
