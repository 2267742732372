import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";

const Conversations = () => {
  const kansaiTranscript1 = <>
    <br />
    <ol className="transcript">
      <li>
        <small>
          A: <span style={{ fontWeight: "bold" }}>あした</span>、<span
            style={{ fontWeight: "bold" }}>デ</span>ートやねん。
        </small>
      </li>
      <li>
        <small>
          B: う<span style={{ fontWeight: "bold" }}>そ</span>やろ。
        </small>
      </li>
      <li>
        <small>
          A: う<span style={{ fontWeight: "bold" }}>そ</span>やな<span style={{ fontWeight: "bold" }}>い</span>わ。ほん<span style={{ fontWeight: "bold" }}>ま</span>やで。
        </small>
      </li>
    </ol>
  </>;

  const standardTranscript1 = <>
    <br />
    <ol className="transcript">
      <li>
        <small>
          A: {addRubyText("明日", "あした")}、デートなんだ。
        </small>
      </li>
      <li>
        <small>
          B: うそでしょ。
        </small>
      </li>
      <li>
        <small>
          A: うそじゃないよ。{addRubyText("本当", "ほんよう")}だよ。
        </small>
      </li>
    </ol>
  </>;

  const kansaiTranscript2 = <>
    <br />
    <ol className="transcript">
      <li>
        <small>
          A: きょ<span style={{ fontWeight: "bold" }}>う</span>、がっこ<span
            style={{ fontWeight: "bold" }}>う</span>や<span style={{ fontWeight: "bold" }}>す</span>みぃ？
        </small>
      </li>
      <li>
        <small>
          B: や<span style={{ fontWeight: "bold" }}>す</span>みやあ<span
            style={{ fontWeight: "bold" }}>ら</span>へんで。
        </small>
      </li>
      <li>
        <small>
          A: あ、<span style={{ fontWeight: "bold" }}>ほうかあ</span>。
        </small>
      </li>
    </ol>
  </>;

  const standardTranscript2 = <>
    <br />
    <ol className="transcript">
      <li>
        <small>
          A: {addRubyText("今日", "きょう")}、{addRubyText("学校", "がっこう")}{addRubyText("休", "やす")}み？
        </small>
      </li>
      <li>
        <small>
          B: 休みじゃないよ。
        </small>
      </li>
      <li>
        <small>
          A: あ、そう。
        </small>
      </li>
    </ol>
  </>;

  const kansaiTranscript3 = <>
    <br />
    <ol className="transcript">
      <li>
        <small>
          A: <span style={{ fontWeight: "bold" }}>アルバ</span>イト、<span
            style={{ fontWeight: "bold" }}>いきと</span>な<span style={{ fontWeight: "bold" }}>い</span>わあ。
        </small>
      </li>
      <li>
        <small>
          B: <span style={{ fontWeight: "bold" }}>しごと</span>、<span
            style={{ fontWeight: "bold" }}>す</span>き<span style={{ fontWeight: "bold" }}>ちゃう</span>かったん？
        </small>
      </li>
      <li>
        <small>
          A: <span style={{ fontWeight: "bold" }}>まえ</span>は<span
            style={{ fontWeight: "bold" }}>す</span>きやっ<span style={{ fontWeight: "bold" }}>て</span>ん
            けど、い<span style={{ fontWeight: "bold" }}>ま</span>は<span
            style={{ fontWeight: "bold" }}>そんなす</span>き<span style={{ fontWeight: "bold" }}>ちゃ
            う</span>ねん。
        </small>
      </li>
    </ol>
  </>;

  const standardTranscript3 = <>
    <br />
    <ol className="transcript">
      <li>
        <small>
          A: アルバイト、{addRubyText("行", "い")}きたくないなあ。
        </small>
      </li>
      <li>
        <small>
          B: {addRubyText("仕事", "しごと")}、{addRubyText("好", "す")}きじゃなかったの？
        </small>
      </li>
      <li>
        <small>
          A: {addRubyText("前", "まえ")}は好きだったんだけど、{addRubyText("今", "いま")}はそんなに好きじゃないの。
        </small>
      </li>
    </ol>
  </>;

  const kansaiTranscript4 = <>
    <br />
    <ol className="transcript">
      <li>
        <small>
          A: おたく<span style={{ fontWeight: "bold" }}>の</span>おこさん、<span
            style={{ fontWeight: "bold" }}>げ</span>んきや<span style={{ fontWeight: "bold" }}>ね</span>え。
        </small>
      </li>
      <li>
        <small>
          B: <span style={{ fontWeight: "bold" }}>は</span>あ、<span
          style={{ fontWeight: "bold" }}>おとこ</span>のこやっ<span
          style={{ fontWeight: "bold" }}>た</span>ら<span style={{ fontWeight: "bold" }}>よ</span>かっ
          たんや<span style={{ fontWeight: "bold" }}>け</span>ど<span
          style={{ fontWeight: "bold" }}>ね</span>え。
        </small>
      </li>
      <li>
        <small>
          A: え<span style={{ fontWeight: "bold" }}>え</span>？<span
          style={{ fontWeight: "bold" }}>おんな</span>のこやっ<span
          style={{ fontWeight: "bold" }}>た</span>ん？
        </small>
      </li>
    </ol>
  </>;

  const standardTranscript4 = <>
    <br />
    <ol className="transcript">
      <li>
        <small>
          A: お{addRubyText("宅", "たく")}のお{addRubyText("子", "こ")}さん、{addRubyText("元気", "げんき")}ねえ。
        </small>
      </li>
      <li>
        <small>
          B: ええ、{addRubyText("男", "おとこ")}の{addRubyText("子", "こ")}だったらよかったんだけどねえ。
        </small>
      </li>
      <li>
        <small>
          A: ええ？{addRubyText("女", "おんな")}の子だったの？
        </small>
      </li>
    </ol>
  </>;

  const kansaiTranscript5 = <>
    <br />
    <ol className="transcript">
      <li>
        <small>
          A: <span style={{ fontWeight: "bold" }}>でんわ</span>、<span
          style={{ fontWeight: "bold" }}>だれから</span>やっ<span
          style={{ fontWeight: "bold" }}>た</span>ん？
        </small>
      </li>
      <li>
        <small>
          B: お<span style={{ fontWeight: "bold" }}>と</span>うちゃんからやってん。
        </small>
      </li>
      <li>
        <small>
          A: な〜<span style={{ fontWeight: "bold" }}>ん</span>や。<span
          style={{ fontWeight: "bold" }}>か</span>れ<span style={{ fontWeight: "bold" }}>ち</span>ごたんか。
        </small>
      </li>
    </ol>
  </>;

  const standardTranscript5 = <>
    <br />
    <ol className="transcript">
      <li>
        <small>
          A: {addRubyText("電話", "でんわ")}、{addRubyText("誰", "だれ")}からだったの？
        </small>
      </li>
      <li>
        <small>
          B: お{addRubyText("父", "とう")}さんからだったの。
        </small>
      </li>
      <li>
        <small>
          A: な〜んだ。{addRubyText("彼", "かれ")}じゃなかったの。
        </small>
      </li>
    </ol>
  </>;

  return <>
    <h2>{addRubyText("基本会話", "きほんかいわ")} (Core Conversations)</h2>
    <hr />
    <h3>{addRubyText("会話", "かいわ")}１ : "Are you sure you're going to...,&nbsp; A?"</h3>
    *A=first speaker, B=second speaker
    <br />
    *Click "SHOW" for transcripts
    <br />
    <br />
    (A and B are friends)
    <br />
    <table style={{ textAlign: "left", width: "100%" }} border="1" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{ verticalAlign: "top", textAlign: "center" }} className="video" colSpan="1" rowSpan="2">
            {addVideoPlayer("3.3.cc.1.mp4")}<br />
          </td>
          <td style={{ verticalAlign: "top" }} className="transcriptcell">
            Kansai transcript
            <Hideable content={kansaiTranscript1} />
          </td>
        </tr>
        <tr>
          <td style={{ verticalAlign: "top" }} className="transcriptcell">
            Standard transcript
            <Hideable content={standardTranscript1} />
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    <h3>{addRubyText("会話", "かいわ")}２ : "No class today, B?"</h3>
    (A and B are friends)<br />
    <table style={{ textAlign: "left", width: "100%" }} border="1" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{ verticalAlign: "top", textAlign: "center" }} className="video" colSpan="1" rowSpan="2">
            {addVideoPlayer("3.3.cc.2.mp4")}<br />
          </td>
          <td style={{ verticalAlign: "top" }} className="transcriptcell">
            Kansai transcript
            <Hideable content={kansaiTranscript2} />
          </td>
        </tr>
        <tr>
          <td style={{ verticalAlign: "top" }} className="transcriptcell">
            Standard transcript
            <Hideable content={standardTranscript2} />
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    <h3>{addRubyText("会話", "かいわ")}３ : Does A like her part-time job?</h3>
    (A and B are friends)<br />
    <table style={{ textAlign: "left", width: "100%" }} border="1" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{ verticalAlign: "top", textAlign: "center" }} className="video" colSpan="1" rowSpan="2">
            {addVideoPlayer("3.3.cc.3.mp4")}<br />
          </td>
          <td style={{ verticalAlign: "top" }} className="transcriptcell">
            Kansai transcript
            <Hideable content={kansaiTranscript3} />
          </td>
        </tr>
        <tr>
          <td style={{ verticalAlign: "top" }} className="transcriptcell">
            Standard transcript
            <Hideable content={standardTranscript3} />
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    <h3>{addRubyText("会話", "かいわ")}４ : Is B's child a boy or a girl???</h3>
    (A and B are neighbors)<br />
    <table style={{ textAlign: "left", width: "100%" }} border="1" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{ verticalAlign: "top", textAlign: "center" }} className="video" colSpan="1" rowSpan="2">
            {addVideoPlayer("3.3.cc.4.mp4")}<br />
          </td>
          <td style={{ verticalAlign: "top" }} className="transcriptcell">
            Kansai transcript
            <Hideable content={kansaiTranscript4} />
          </td>
        </tr>
        <tr>
          <td style={{ verticalAlign: "top" }} className="transcriptcell">
            Standard transcript
            <Hideable content={standardTranscript4} />
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    <h3>{addRubyText("会話", "かいわ")}５ : Who just called?</h3>
    (A and B are sisters)<br />
    <table style={{ textAlign: "left", width: "100%" }} border="1" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{ verticalAlign: "top", textAlign: "center" }} className="video" colSpan="1" rowSpan="2">
            {addVideoPlayer("3.3.cc.5.mp4")}<br />
          </td>
          <td style={{ verticalAlign: "top" }} className="transcriptcell">
            Kansai transcript
            <Hideable content={kansaiTranscript5} />
          </td>
        </tr>
        <tr>
          <td style={{ verticalAlign: "top" }} className="transcriptcell">
            Standard transcript
            <Hideable content={standardTranscript5} />
          </td>
        </tr>
      </tbody>
    </table>
    <small><br />
    ＊な〜んや：な〜んだ&nbsp; Oh! (slight disappointment)</small><br />
    <br />
    <hr />
    <h3>{addRubyText("理解度", "りかいど")}チェック (Check-up)&nbsp; <br />
    </h3>
    Figure out the Kansai-peculiar grammar and expressions appeared in the
    video clips. Refer to the standard and Kansai transcripts if necessary.<br />
    <table style={{ textAlign: "left", width: "100%" }} border="1" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{ verticalAlign: "top", textAlign: "center" }}>
            Check-up<br />
          </td>
          <td style={{ verticalAlign: "top", textAlign: "center" }}>
            Answer<br />
          </td>
        </tr>
        <tr>
          <td style={{ verticalAlign: "top" }}>
            1) What is the Kansai-equivalent of copula だ? <br />
          </td>
          <td style={{ verticalAlign: "top" }}>
            <Hideable content={<>や</>} /><br />
          </td>
        </tr>
        <tr>
          <td style={{ verticalAlign: "top" }}>
            2) How would you say {addRubyText("好", "す")}きだった in Kansai-ben? <br />
          </td>
          <td style={{ verticalAlign: "top" }}>
            <Hideable content={<>{addRubyText("好", "す")}きやった</>} /><br />
          </td>
        </tr>
        <tr>
          <td style={{ verticalAlign: "top" }}>
            3) What is ちゃう in standard?<br />
          </td>
          <td style={{ verticalAlign: "top" }}>
            <Hideable content={<>{addRubyText("違", "ちが")}う</>} /><br />
          </td>
        </tr>
        <tr>
          <td style={{ verticalAlign: "top" }}>
            4) How do you say X(Noun) じゃない in Kansai-ben? (There are variations)<br />
          </td>
          <td style={{ verticalAlign: "top" }}>
            &nbsp;<Hideable content={<>Xやない／Xやあらへん／Xちゃう</>} /><br />
          </td>
        </tr>
        <tr>
          <td style={{ verticalAlign: "top" }}>
            5) How do you say X(Noun) じゃなかった in Kansai-ben? (There are variations)<br />
          </td>
          <td style={{ verticalAlign: "top" }}>
            <Hideable content={<>Xやなかった／Xちゃうかった／Xちごた／（Xやあらへんかった）</>} /> <br />
          </td>
        </tr>
      </tbody>
    </table>
  </>;
}

export default Conversations;
