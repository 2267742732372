import React from "react";
import { addRubyText, addAudio } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";

const P4 = () => {
  const standardTranscript = <>
    <ol className="transcript">
      <li>A: 美香ちゃん、まだ{addRubyText("小","ちい")}さいね。</li>
      <li>B: うん、{addRubyText("早","はや")}く大きくならないかな。</li>
    </ol>
  </>;

  return <>
    <h2>{addRubyText("練習","れんしゅう")}4 : Adverbial form</h2>
    Form a question and an answer for each cue according to
    a model. You may listen to the question, tryto answer it, and check
    your answer. *Answers vary; the recorded answers indicate those
    commonly
    used.<br />
    <small>*Mouse over or click on the speaker icon for sound</small><br />
    <br />
    <span style={{fontWeight: "bold"}}>Model</span> (Cue:
    {addRubyText("美香","みか")}ちゃんーちっちゃい／{addRubyText("大","おお")}きい)<br />
    &nbsp;&nbsp;&nbsp; A: 美香ちゃん、まだちっちゃいなあ。<br />
    &nbsp;&nbsp;&nbsp; B: うん、はよ大きならんかな。{addAudio("3.4.p.4.mp3")}<br />
    <br />
    Point: 〜くなる"become ~"ー＞Kansai Adverbial + なる. No need to add く. (refer
    to Grammar 3)<br />
    <br />
    ST transcript 
    <br />
    <Hideable content={standardTranscript} />
    
    <br />
    <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>Cue<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>A (Question)<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>B (Answer)<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>{addRubyText("美香","みか")}ちゃんーちっちゃい／{addRubyText("大","おお")}きい<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.4.p.4.1.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.4.p.4.1.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>{addRubyText("野菜","やさい")}ー{addRubyText("高","たか")}い／{addRubyText("安","やす")}い<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.4.p.4.2.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.4.p.4.2.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>ここー{addRubyText("寒","さぶ")}い／{addRubyText("暖","あった")}かい</td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.4.p.4.3.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.4.p.4.3.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>ヒロ{addRubyText("君","くん")}ー{addRubyText("具合","ぐあい")}{addRubyText("悪","わる")}い／いい<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.4.p.4.4.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.4.p.4.4.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>このトマトー{addRubyText("青","あお")}い／{addRubyText("赤","あか")}い</td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.4.p.4.5.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.4.p.4.5.b.mp3")}<br />
          </td>
        </tr>
      </tbody>
    </table>
  </>;
}

export default P4;
