import React from "react";
import { Link } from "react-router-dom";
import { Page } from "home/page";
import { addRubyText, addImage } from "common/util/generalUtil";

const Video = () => {
  return <>
    <h2>ビデオ{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}(Video Comprehension Practice)</h2>
    <table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>
          <ul>
            <li>
              <Link to={Page.FunctionalGrammar.Even.Video.V1}>
                ビデオ聞き取り練習１: Authentic - medium
              </Link>
            </li>
            <li>
              <Link to={Page.FunctionalGrammar.Even.Video.V2}>
                ビデオ聞き取り練習２: Authentic - medium
              </Link>
            </li>
          </ul>
          </td>
          <td style={{verticalAlign: "top"}}>
          <div style={{textAlign: "center"}}>{addImage("kyotomachiyatuboniwa.jpg")}<br />
          <small>京町家の坪庭 courtyard garden in a traditional townhouse (Kyoto)</small><br />
          </div>
          </td>
        </tr>
      </tbody>
    </table>
  </>;
}

export default Video;
