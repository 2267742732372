import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";

const V5 = () => {
  const transcript = <>
    {addRubyText("今","いま")}の{addRubyText("子","こ")}はもう、な、{addRubyText("勉強","べんきょう")}ばっかりやわな。もう<span
    style={{textDecoration: "underline"}}>勉強せんことには</span>(Grammar 2 Extra
    2)、ええとこには{addRubyText("行","い")}けへんわな。今の{addRubyText("子供","こども")}わな。
  </>;

  const qaObjects = [
    {
      question: <span>Q: What does he think is the reason why children nowadays study so hard?</span>,
      answersCorrect: [
        <>To be admitted to good schools</>
      ],
      answersIncorrect: [
        <>To receive good grades</>,
        <>To be praised as good children</>,
        <>To move to a better area</>
      ]
    }
  ];

  return <>
    <h2>ビデオ{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}５: Authentic - short<br />
    </h2>
    Watch the video clip and choose the answer that matches the content of
    the clip.<br />
    <small>*Click "SHOW" if you need to refer to the transcript.</small><br />
    <br />
    Matsuda-san is talking about the children nowadays compared with his
    childhood.<br />
    <table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2"
    cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>{addVideoPlayer("4.2.2.p.2.matsuda.mp4")} </td>
          <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>Transcript
    <Hideable content={transcript}/></td>
        </tr>
        <tr align="center">
          <td style={{verticalAlign: "top", textAlign: "left"}}>マツダさん (Shiga)</td>
        </tr>
      </tbody>
    </table>
    <br />
    <QandAForm qaObjects={qaObjects}/>
  </>;
}

export default V5;
