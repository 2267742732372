import React from "react";
import { addRubyText, addAudio, addVideoPlayer } from "common/util/generalUtil";

const Accent = () => {
  return (
    <>
      <h2>Accent (Accentuation and pitch pattern)</h2>
      <div>
        What makes the Kansai dialect distinct is its characteristic pitch
        pattern. You will see the actual pitch pattern difference when
        comparing the Kansai dialect with the Standard in the conversation
        video clips in the following chapter. However, the most particular
        characteristic feature of the Kansai dialect pitch pattern is the H-H
        (plain high/flat high) pitch pattern. In the Standard, the first mora
        and the second mora of a word should have different pitch levels;
        however, this is not the case in the Kansai dialect. The following
        difference between the Standard and the Kansai dialect illustrates the
        point. (H: high pitch, L: low pitch)
      </div>
      <div>
        <small>*Mouse over or click on the speaker icon for sound</small>
      </div>
      <br />

      <table style={{textAlign: "left", width: "60%", minWidth:"600px"}} border="1" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top"}} />
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Tokyo {addAudio("1.2.b.1.mp3")}
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Kansai {addAudio("1.2.b.2.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            箸 (はし: chopsticks)
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            HL
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            LH
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            橋 (はし: bridge)
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            LH
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            HL
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            端 (はし: edge)
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            LH
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            HH
          </td>
        </tr>
        </tbody>
      </table>
      <br />

      <div>
        In this way, the Kansai dialect has a three-way distinction with a
        variety of H-H pitch patterns while the standard has a two-way
        distinction. Even sentences with all-high flat pitch pattern occur such
        as:
      </div>
      <br />
      <table style={{textAlign: "left", width: "80%", minWidth:"600px"}} border="1" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Tokyo
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Kansai
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addRubyText("買", "か")}ってあげようか？(Shall I buy it for you?) {addAudio("1.2.b.4.mp3")}
          </td>
          <td style={{verticalAlign: "top"}}>
            <b>こうたろかあ</b>。{addAudio("1.2.b.3.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addRubyText("邪魔", "じゃま")}しないで。(Don't disturb me!){addAudio("1.2.b.6.mp3")}
          </td>
          <td style={{verticalAlign: "top"}}>
            <b>じゃませんといてぇ</b>。{addAudio("1.2.b.5.mp3")}
          </td>
        </tr>
        </tbody>
      </table>
      <br />
      <hr />

      <h3>Note</h3>
      <div>
        Professor Yoshitaka Yamashita in Hokkaido University
        claims that these sound / pitch characteristics (such as the high flat
        pitch pattern and tones in one-mora words in Kansai dialect) are
        inherited characteristics from the Chinese language that was brought
        thousands of years ago. According to Professor Yamashita, the ancient
        Japanese language had been very monotonous with no accent, tone or
        pitch, until some Chinese migrated to the Kansai area, which is
        believed to have been the center of Japan at that time. Only the
        accentual characteristics spread to the Eastern Japan; however, the
        tonal characteristics continued to affect the language in the Kansai
        area. This might be the reason why the sound of Kansai dialect
        resembles that of Chinese!
      </div>
      <br />
      <hr />

      <h3>Experiment</h3>
      <div>
        Let’s examine how Kansai people count one to ten! What do you think
        makes the Kansai-ben versions different from Tokyo version? When
        counting numbers, one-mora numbers such as 'two (に) ' and 'five (ご)'
        are lengthened even in standard, but can you find any difference in
        tones between Tokyo version and Kansai versions? Also, pay attention to
        the pitch patterns and the sound characteristics (how they pronounce vowels such as
        い and う, how they pronounce 'seven (しち)' etc.). Lastly, can you find a
        common 'musical' pitch pattern in Kansai heavy version?
      </div>
      <br />

      <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>Tokyo</td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>Kansai</td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}} className="video">
            {addVideoPlayer("1.2.b.1.mp4")}
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}} className="video">
            {addVideoPlayer("1.2.b.3.mp4")}
          </td>
        </tr>
        </tbody>
      </table>
    </>
  );
};


export default Accent;