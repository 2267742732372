import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";

const V3 = () => {
  const transcript = <>
    そやねえ，あんまり{addRubyText("怒","おこ")}らへんけどね，やっぱりあかんことをした{addRubyText("時","とき")}とかね，<span
    style={{textDecoration: "underline"}}>{addRubyText("言","い")}わなきゃいかん</span>(Grammar 3)
    時はびしっと怒るね。<br />
  </>;

  const qaObjects = [
    {
      question:   <span>Q: When does he scold his daughter?</span>,
      answersCorrect: [
        <>When he has to say something to his daughter due to her misbehavior.</>
      ],
      answersIncorrect: [
        <>He doesn’t scold her no matter what.</>,
        <>When his daughter said something she shouldn’t have said to him.</>,
        <>Whenever he wants to scold her.</>
      ]
    }
  ];

  return <>
    <h2>ビデオ{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}３:Authentic - short<br />
    </h2>
    Watch the video clip and choose the answer that matches the content of
    the clip.<br />
    <small>*Click "SHOW" if you need to refer to the transcript<br />
    <br />
    </small>
    Akira was asked when he scolds his daughter.<br />
    <table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2"
    cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>{addVideoPlayer("4.2.3.p.2.akira.mp4")}</td>
          <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>Transcript:<Hideable content={transcript}/>
          </td>
        </tr>
        <tr align="center">
          <td style={{verticalAlign: "top", textAlign: "left"}}>&nbsp;アキラ
    (Osaka)</td>
        </tr>
      </tbody>
    </table>
    <br />
    <QandAForm qaObjects={qaObjects}/>
  </>;
}

export default V3;
