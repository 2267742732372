import React from "react";
import { Link } from "react-router-dom";
import { Page } from "home/page";
import { addRubyText, addImage } from "common/util/generalUtil";

const Interviews = () => {
  console.log();
  return <>
    <h2>Chapter 6: Interviews to Locals</h2>
    <br/>
    <h3>〜{addRubyText("関西人","かんさいじん")}って？: Explore the Kansai local identity through interview videos 〜</h3>
    <br/>
    <table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td colSpan="1" rowSpan="2" style={{verticalAlign: "top", textAlign: "left"}}>This
    chapter consists of a collection of interviews with a variety of
    local Kansai people on various topics. The interview topics vary from
    regular personal questions (e.g. favorite restaurant) to those that are
    meant to explore local pride and identity in comparison to mainstream
    Tokyo culture (e.g. opinion about Tokyo people). We hope that you
    notice and appreciate the diversity in the way people talk, and at the
    same time, construct your own idea about how people think of themselves
    as Kansai locals and how the language they speak connects to their
    local identity.</td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addImage("takoyakiya2.jpg")}
            <br/>
          </td>
        </tr>
        <tr align="center">
          <td style={{verticalAlign: "top"}}><small>たこ焼き屋 Takoyaki shop (Abiko, Osaka)</small><br/>
          </td>
        </tr>
      </tbody>
    </table>
    <hr/>
    <br/>
    <h3>a. About the interview clips:</h3>
    -&nbsp;&nbsp;&nbsp; The interviews were conducted in the summer of 2006
    and in the winter of 2006-2007 <br/>
    -&nbsp;&nbsp;&nbsp; Kansai locals of various generations from various
    locations were selected as interviewees.<br/>
    -&nbsp;&nbsp;&nbsp; Each person was given up to 38 questions. Given
    questions vary depending on the age of each interviewee.<br/>
    <br/>
    <h3>b. How to search the clips:</h3>
    You can start the search of interview clips from the list of
    interviewees, from the list of the interview questions, or directly
    start sorting clips by all the information - by interviewees, by
    interview questions, by the difficulty levels for comprehension* and by
    the degree of Kansai-ben** (what degree of Kansai
    features each clip holds) .<br/>
    <br/>
    <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}><span style={{fontWeight: "bold"}}>1.&nbsp;
    Search from the list of interviewees</span></td>
          <td style={{verticalAlign: "top"}}><span style={{fontWeight: "bold"}}></span>If
    you would like to follow the footsteps of a
    particular interviewee, go to <Link to={Page.Interviewees}>1. Interviewees</Link>, 
    and choose one person
    from the interviewee list with their background information. (Prior to
    choosing one, you can filter interviewees by interview questions given
    to them, their gender, age, residency, occupation, relationship with
    the interviewer, and the difficulty levels* of clips in which they
    appear.) The
    clips of the chosen interviewee will appear according to the order of
    interview questions given to the person.</td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}><span style={{fontWeight: "bold"}}>2.&nbsp;
    Search
    from the list of
    interview questions</span></td>
          <td style={{verticalAlign: "top"}}><span style={{fontWeight: "bold"}}></span>If
    you would like to compare the answers
    of various people to a particular question, go to <Link to={Page.InterviewQuestions}>2. Interview Questions</Link>,
     and choose one question of your interest from the list of
    interview questions. (Prior to choosing one, you can filter interview
    questions by interviewees, their gender, age, residency, occupation,
    relationship with the interviewer, and the difficulty levels* of
    clips.) The
    clips of the chosen question will appear according to the order of the
    list of interviewees. </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}><span style={{fontWeight: "bold"}}>3.
    General Search</span></td>
          <td style={{verticalAlign: "top"}}>If you
    are not sure which clips to view, start from <Link to={Page.InterviewClips}>3. Clips</Link> page. You can
    filter clips by interview questions, interviewees, their background,
    and clip difficulty level depending on your interest. Clips can be
    sorted by interview questions, interviews, difficulty levels* and the
    degrees of Kansai-ben** for your convenience.</td>
        </tr>
      </tbody>
    </table>
    <br/>
    <span style={{fontStyle: "italic"}}>Warning: Please avoid clicking on a
    different subchapter/sorting item before each loading finishes so as
    not to generate an error!<br/>
    <br/>
    </span>
    <h3>c. How to work on the clips:</h3>
    When you click the interviewee image of the clip you want to view, a
    pop-up window appears, which includes the clip, content questions and a
    hidden vocabulary list. View the clip and answer the attached questions
    to check your comprehension. You may refer to the vocabulary list if
    necessary.<br/>
    <br/>
    <hr/>
    <br/>
    <div style={{textAlign: "left"}}>*<span style={{fontWeight: "bold"}}>The
    difficulty levels</span> are determined depending on the difficulty of
    subject, content and vocabulary, speed of speech, and the degree of
    Kansai dialect features. They are described as follows:<br/>
    </div>
    
    <table style={{textAlign: "left", width: "60%", marginLeft: "auto", marginRight: "auto"}} border="1" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>1<br/>
          </td>
          <td style={{verticalAlign: "top"}}>For Kansai Novice<br/>
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>2<br/>
          </td>
          <td style={{verticalAlign: "top"}}>For Kansai Novice to Intermediate<br/>
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>3<br/>
          </td>
          <td style={{verticalAlign: "top"}}>For Kansai Intermediate<br/>
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>4<br/>
          </td>
          <td style={{verticalAlign: "top"}}>For Kansai Advanced<br/>
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>5<br/>
          </td>
          <td style={{verticalAlign: "top"}}>For Kansai native like!!<br/>
          </td>
        </tr>
      </tbody>
    </table>
    <br/>
    <div style={{textAlign: "left"}}>**<span style={{fontWeight: "bold"}}>The
    degrees of Kansai-ben</span> are determined as follows:<br/>
    </div>
    <table
    style={{textAlign: "left", width: "60%", marginLeft: "auto", marginRight: "auto"}}
    border="1" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>1<br/>
          </td>
          <td style={{verticalAlign: "top"}}>Kansai accentual feature only <br/>
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>2<br/>
          </td>
          <td style={{verticalAlign: "top"}}>A couple of Kansai basic
    features involved<br/>
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>3<br/>
          </td>
          <td style={{verticalAlign: "top"}}>Some Kansai basic structures and
    expressions involved<br/>
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>4<br/>
          </td>
          <td style={{verticalAlign: "top"}}>More complicated (traditional)
    Kansai features involved <br/>
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>5<br/>
          </td>
          <td style={{verticalAlign: "top"}}>If you understand this, you must
    be a native Kansai-jin.<br/>
          </td>
        </tr>
      </tbody>
    </table>
  </>;
}

export default Interviews;