import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";

const Conversations = () => {
  const kansaiTranscript1 = <>
      <ol class="transcript">
        <li><small>A: わたし、<span style={{fontWeight: "bold"}}>す</span>きな<span
 style={{fontWeight: "bold"}}>おとこのひ</span>と<span
 style={{fontWeight: "bold"}}>で</span>きてん。<br />
          </small></li>
        <li><small>B: <span style={{fontWeight: "bold"}}>す</span>きな<span
 style={{fontWeight: "bold"}}>お</span>とこ？そんなん、わたし<span
 style={{fontWeight: "bold"}}>か</span>てまえ<span style={{fontWeight: "bold"}}>か</span>ら<span
 style={{fontWeight: "bold"}}>お</span>るわ。<br />
          </small></li>
        <li><small>A: え〜ちっ<span style={{fontWeight: "bold"}}>と</span>も<span
 style={{fontWeight: "bold"}}>しらん</span>かった。<span
 style={{fontWeight: "bold"}}>だれだれ</span>？<span style={{fontWeight: "bold"}}>な
まえ</span>な<span style={{fontWeight: "bold"}}>ん</span>ちゅうの？<br />
          </small></li>
        <li><small>B: ピョ、<span style={{fontWeight: "bold"}}>ピョ</span>ンタくん。<br />
          </small></li>
        <li><small>A:<span style={{fontWeight: "bold"}}> そら</span>、あんた<span
 style={{fontWeight: "bold"}}>と</span>この<span style={{fontWeight: "bold"}}>オ
スの</span>うさ<span style={{fontWeight: "bold"}}>ぎ</span>やんか！<br />
          </small></li>
        <li><small>B: うさぎ<span style={{fontWeight: "bold"}}>か</span>て，<span
 style={{fontWeight: "bold"}}>お</span>とこは<span style={{fontWeight: "bold"}}>お</span>と
こやん！</small></li>
      </ol>
  </>;

  const standardTranscript1 = <>
      <ol class="transcript">
        <li><small>A: {addRubyText("私","わたし")}、{addRubyText("好","す")}きな{addRubyText("男","おとこ")}の{addRubyText("人","ひと")}ができたの。<br />
          </small></li>
        <li><small>B: 好きな男？そんなの、私だって{addRubyText("前","まえ")}からいるよ。<br />
          </small></li>
        <li><small>A: え〜{addRubyText("全然","ぜんぜん")}{addRubyText("知","し")}らなかった。だれ〜？{addRubyText("名前","なまえ")}は{addRubyText("何","なん")}って{addRubyText("言","い")}う
の？<br />
          </small></li>
        <li><small>B: ピョ、ピョンタくん。<br />
          </small></li>
        <li><small>A: それは、あなたんち（あなたのうち）のオスのうさぎじゃない！<br />
          </small></li>
        <li><small>B: うさぎでも、男は男じゃない！</small><br />
        </li>
      </ol>
  </>;

  const kansaiTranscript2 = <>
      <ol class="transcript">
        <li><small>A: <span style={{fontWeight: "bold"}}>あした</span>、あ<span
 style={{fontWeight: "bold"}}>め</span>やて。<span style={{fontWeight: "bold"}}>せっ
かくのキャ</span>ンプやのに<span style={{fontWeight: "bold"}}>ざんねん</span>や<span
 style={{fontWeight: "bold"}}>な</span>。<br />
          </small></li>
        <li><small>B: あ<span style={{fontWeight: "bold"}}>め</span>やった<span
 style={{fontWeight: "bold"}}>か</span>て、あ<span style={{fontWeight: "bold"}}>め</span>や<span
 style={{fontWeight: "bold"}}>な</span>かったかて、<span
 style={{fontWeight: "bold"}}>いく</span>で。<br />
          </small></li>
        <li><small>A: <span style={{fontWeight: "bold"}}>そ</span>やかて、あ<span
 style={{fontWeight: "bold"}}>め</span>やったら<span style={{fontWeight: "bold"}}>さ</span>む
いかも<span style={{fontWeight: "bold"}}>し</span>れ<span
 style={{fontWeight: "bold"}}>へん</span>で。<br />
          </small></li>
        <li><small>B: さ<span style={{fontWeight: "bold"}}>む</span>かったかて、<span
 style={{fontWeight: "bold"}}>さ</span>む<span style={{fontWeight: "bold"}}>な</span>かっ
たかて<span style={{fontWeight: "bold"}}>かんけい</span>な<span
 style={{fontWeight: "bold"}}>い</span>やん。<br />
          </small></li>
        <li><small>A: ほかの<span style={{fontWeight: "bold"}}>ひ</span>と，だ<span
 style={{fontWeight: "bold"}}>れ</span>も<span style={{fontWeight: "bold"}}>き</span>は
らへん<span style={{fontWeight: "bold"}}>か</span>も<span
 style={{fontWeight: "bold"}}>しれん</span>し。<br />
          </small></li>
        <li><small>B: だ<span style={{fontWeight: "bold"}}>れ</span>も<span
 style={{fontWeight: "bold"}}>あつまらん</span>かて、<span
 style={{fontWeight: "bold"}}>たいふ</span>う<span style={{fontWeight: "bold"}}>き</span>た
かて、いくで。<br />
          </small></li>
        <li><small>A: <span style={{fontWeight: "bold"}}>たいふ</span>うやっ<span
 style={{fontWeight: "bold"}}>た</span>ら<span style={{fontWeight: "bold"}}>いけ
るか</span>いな！</small></li>
      </ol>
  </>;

  const standardTranscript2 = <>
      <ol class="transcript">
        <li><small>A: {addRubyText("明日","あした")}、{addRubyText("雨","あめ")}だって。せっかくのキャンプなのに{addRubyText("残念","ざんねん")}だね。<br />
          </small></li>
        <li><small>B: 雨でも雨じゃなくても{addRubyText("行","い")}くよ。<br />
          </small></li>
        <li><small>A: でも、雨だったら{addRubyText("寒","さむ")}いかもしれないよ。<br />
          </small></li>
        <li><small>B: 寒くても寒くなくても{addRubyText("関係","かんけい")}ないじゃん。<br />
          </small></li>
        <li><small>A: {addRubyText("他","ほか")}の{addRubyText("人","ひと")}、{addRubyText("誰","だれ")}も{addRubyText("来","こ")}ないかもしれないよ。<br />
          </small></li>
        <li><small>B: 誰も{addRubyText("集","あつ")}らなくても、{addRubyText("台風","たいふう")}が来ても行くよ。<br />
          </small></li>
        <li><small>A: 台風だったら行けるわけないでしょ！</small> </li>
      </ol>
  </>;

  return <>
<h2>{addRubyText("基本会話","きほんかいわ")} (Core Conversations)</h2>
<hr />
<h3>{addRubyText("会話","かいわ")}１ : Whom does B have feeling for?<br />
</h3>
*A=first speaker, B=second speaker<br />
*Click "SHOW" for transcripts<br />
<br />
(A and B are friends.)<br />
<table style={{textAlign: "left", width: "100%"}} border="1"cellPadding="2"
 cellSpacing="2">
  <tbody>
    <tr>
      <td style={{verticalAlign: "top", textAlign: "center"}} class="video"
 colSpan="1" rowSpan="2">{addVideoPlayer("4.5.cc.1.mp4")}</td>
      <td style={{verticalAlign: "top"}} class="transcriptcell">Kansai
transcript
        <Hideable content={kansaiTranscript1}/>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}} class="transcriptcell">Standard
transcript
        <Hideable content={standardTranscript1}/>
      </td>
    </tr>
  </tbody>
</table>
<br />
<small>* オスmale (used for animals) &lt;-&gt; メス(female animal)<br />
* うさぎ rabbit<br />
* ちっとも-neg. (Kansai)={addRubyText("少","すこ")}しも／{addRubyText("全然","ぜんぜん")} -neg</small><br />
<br />
<h3>{addRubyText("会話","かいわ")}2&nbsp; : In what condition would B give up camping?<br />
</h3>
(A and B are friends.)<br />
<table style={{textAlign: "left", width: "100%"}} border="1"cellPadding="2"
 cellSpacing="2">
  <tbody>
    <tr>
      <td style={{verticalAlign: "top", textAlign: "center"}} class="video"
 colSpan="1" rowSpan="2">{addVideoPlayer("4.5.cc.2.mp4")}</td>
      <td style={{verticalAlign: "top"}} class="transcriptcell">Kansai
transcript 
        <Hideable content={kansaiTranscript2}/>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}} class="transcriptcell">Standard
transcript 
        <Hideable content={standardTranscript2}/>
      </td>
    </tr>
  </tbody>
</table>
<br />
<br />
<hr />
<h3>{addRubyText("理解度","りかいど")}チェック (Check-up)&nbsp; <br />
</h3>
Figure out the Kansai-specific grammar and expressions that appear in
the
video clips. Refer to the standard and Kansai transcripts if necessary.<br />
<br />
<table style={{textAlign: "left", width: "100%"}} border="1"cellPadding="2"
 cellSpacing="2">
  <tbody>
    <tr>
      <td style={{verticalAlign: "top", textAlign: "center"}}>Check-up<br />
      </td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>Answer<br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>1) What does N＋かて mean? What are
the equivalent expressions in the standard?<br />
      </td>
      <td style={{verticalAlign: "top"}}><Hideable content={<>-&gt; “Even N” ＝Nでも、Nだって</>}/><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>2) List up the Kansai
expressions from cc2 that are equivalent to “even if…”<br />
      </td>
      <td style={{verticalAlign: "top"}}><Hideable content={<>
雨やったかて、雨やなかったかて、寒かったかて、寒なかったかて、集まらんかて、来たかて</>}/><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>3) How can you form the Kansai
expression of “even if…?” </td>
      <td style={{verticalAlign: "top"}}><Hideable content={<>Affirmative-
Nやったかて、Adjかったかて、V（たform)かて, Negative- Nやなかったかて、Adjなかったかて、V(neg.
stem)んかて</>}/> </td>
    </tr>
  </tbody>
</table>
<br />
<hr />
<h3>{addRubyText("関西","かんさい")}{addRubyText("語彙","ごい")}チェック (Kansai Vocabulary Check-up) <br />
</h3>
What are the standard equivalent expression of the following Kansai
words that are introduced in the core conversations of this lesson?<br />
<br />
<table style={{textAlign: "left", width: "60%"}} border="1"cellPadding="2"
 cellSpacing="2">
  <tbody>
    <tr>
      <td style={{verticalAlign: "top", textAlign: "center"}}>Kansai<br />
      </td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>Standard<br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>ちっとも-neg.<br />
      </td>
      <td style={{verticalAlign: "top"}}><Hideable content={<>
{addRubyText("少","すこ")}しも／{addRubyText("全然","ぜんぜん")}-neg. </>}/><br />
      </td>
    </tr>
  </tbody>
</table>
<br />
<hr />
<h3>ノート (Miscellaneous note)<br />
</h3>
1) In cc 1, A asked B{addRubyText("名前","なまえ")}{addRubyText("何","なん")}ちゅうの to find out the name of the
person (?) B has feelings for. Nちゅう is a Kansai specific abbreviation
of Nと{addRubyText("言","い")}う”… is called N”. Please refer to the Note on Quotation
makers
in Kansai (Lesson 1b in Ch3) for details.<br />
<br />
2) あんたとこin cc 1 means あなたのところ／あなたのうち in standard. あんた is a casual/blunt
expression to indicate the second person, and is widely used in Kansai.
However, some people think it is too blunt (not as blunt as おまえ／われ
though), so you might want to stick to calling the second person’s name
rather than あんた, just to be on the safe side.&nbsp; For your reference,
うっとこ（うちとこ） means わたしのところ. うち indicates not only ‘house; inside;
in-group’, but it can indicate the first person (usually female) in
Kansai-ben, although less Kansai females use this to address themselves
nowadays.<br />
<br />
3) In cc 2, A said{addRubyText("誰","だれ")}も{addRubyText("来","き")}はらへんかもしれん, which means 誰も来ないかもしれない. Why
did she use はる as in 来はらへん? Are other people hierarchically higher than
A and she has to exalt them? Not really. She just wanted to show
courtesy to 他の人 (other people), to indicate other people belong to
out-group and they are psychologically distant from her. For details of
はる, go back to the former lesson (Lesson 4 in Ch4).<br />
<br />
4) In cc 2, A said {addRubyText("台風","たいふう")}来たら{addRubyText("行","い")}けるかいな. What is かいな? Its original
meaning is “I wonder …”, however, it can be used as a rhetorical
question. If that is the case it means “Is it…? No!” “ No way that...”
Please refer to the further explanation in Ch3 Lesson 1b Grammar 1.
  </>;
}

export default Conversations;
