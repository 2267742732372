import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";

const V12 = () => {
  const transcript = <>
    {addRubyText("子供","こども")}らはなんか、{addRubyText("学食","がくしょく")}、{addRubyText("昼","ひる")}、{addRubyText("弁当","べんとう")}{addRubyText("持","も")}っていかんと、
    学食の{addRubyText("方","ほう")}が{addRubyText("安","やす")}い{addRubyText("言","ゆ")}うて。うん、のや、なんか、{addRubyText("女","おんな")}の{addRubyText("子","こ")}、{addRubyText("外大","がいだい")}<span
    style={{textDecoration: "underline"}}>{addRubyText("行","い")}きよった</span>(Grammar 2)
    けどな，昼が{addRubyText("二百","にひゃく")}なんぼで、なんかしらん、{addRubyText("食","た")}べられる言うて。うん，安いて。ほて、{addRubyText("男","おとこ")}の子でも、なんか、
    {addRubyText("券","けん")}、{addRubyText("一年間","いちねんかん")}の、なんやら、{addRubyText("何万","なんまん")}たら、何万<span
    style={{textDecoration: "underline"}}>言いよった</span> (Grammar 2)
    やいな、{addRubyText("払","はろ")}とくんやて。ほと、そんで昼、ずーっと昼、学食で食べられるんやて。その方が安い言うて。、、、そうそうそうそうそうそう、
    {addRubyText("一人","ひとり")}で、な、そう、弁当なんかな男の子、<span style={{textDecoration: "underline"}}>しよらへん</span>(Grammar 2)
    し。ほんでちゅうて、なんやら、なんぼか、は、{addRubyText("親","おや")}は{addRubyText("金","かね")}{addRubyText("要","い")}るけど、ほんでもそうして、してると、ほと、
    そんで{addRubyText("安心","あんしん")}。うん、食べられるさけ、うん、言うて。朝はパン<span style={{textDecoration: "underline"}}>食べよる</span>
    (Grammar 2)べよるか何<span style={{textDecoration: "underline"}}>食べよる</span>
    (Grammar 2)か、[おおきに〜] 、まあ、昼は学食食べて、バイト<span style={{textDecoration: "underline"}}>行きよる </span>(Grammar 2)時は、バイトで、ほれ、ご{addRubyText("飯","はん")}<span style={{textDecoration: "underline"}}>食べよる</span> (Grammar 2)さかいな。
  </>;

  const qaObjects = [
    {
      question: <span>Q1:What do her grandchildren prefer eating for lunch?</span>,
      answersCorrect: [
        <>They prefer eating at the school cafeteria, because it’s cheaper than making lunch by themselves.</>
      ],
      answersIncorrect: [
        <>They prefer eating at the school cafeteria, because it more nutritious than lunch they make by themselves</>,
        <>They prefer bringing their own lunch, because it’s cheaper than eating at the cafeteria.</>,
        <>They prefer bringing their own lunch because it’s more nutritious than the cafeteria lunch.</>
      ]
    },
    {
      question: <span>Q2: What do their parents think about the school cafeteria lunch?</span>,
      answersCorrect: [
        <>They have to pay some money, but they feel safer this way because their children have something to eat.</>
      ],
      answersIncorrect: [
        <>They have to pay some money, so they prefer their children make their own lunch.</>,
        <>They feel the school cafeteria is too expensive, but they feel safer this way because their children have something to eat.</>,
        <>They feel the school cafeteria is too expensive, so they prefer their children make their own lunch. </>
      ]
    },
    {
      question: <span>Q3: For whom did Ishioh-san use よる?</span>,
      answersCorrect: [
        <>Both of her grandchildren</>
      ],
      answersIncorrect: [
        <>Her grandchildren’s parents</>,
        <>Her grandson</>,
        <>Her granddaughter </>
      ]
    }
  ];

  return <>
    <h2>ビデオ{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}１２: Authentic - long<br />
    </h2>
    Watch the video clip and choose the answer that matches the content of
    the clip.
    Submit your answers after answering all the questions.<br />
    <small>*Click "SHOW" if you need to refer to the transcript
    </small><br />
    Context: Ishioh-san was talking about her grandchildren’s eating
    habits. (Both of her grandchildren – one male and the other female -
    were college students at the time of interview.)<br />
    <table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>{addVideoPlayer("4.4.2.p.4.ishioh.mp4")} </td>
          <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>Transcript
            <Hideable content={transcript}/>
            <br />
            ＊{addRubyText("学食","がくしょく")}school cafeteria<br />
            ＊{addRubyText("弁当","べんとう")} box lunch<br />
            ＊{addRubyText("外大","がいだい")} university of foreign studies<br />
            ＊なんぼ (Kansai) how much<br />
            ＊〜なんぼ (Kansai) …and some yen<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>イシオウさん
    (Shiga)</td>
        </tr>
      </tbody>
    </table>
    <br />
    <QandAForm qaObjects={qaObjects}/>
  </>;
}

export default V12;
