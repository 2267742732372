import React from "react";
import { addRubyText, addAudio } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";

const L1 = () => {
  const qaObjects = [
    {
      superQuestion: <>
        <big style={{fontWeight: "bold"}}>(1) </big>{addAudio("3.3.pl0.1.1.mp3")}
        <Hideable content={<>
          <ol className="transcript">
            <li>A: {addRubyText("外","そと")}、{addRubyText("雨","あめ")}{addRubyText("降","ふ")}ってる？</li>
            <li>B: {addRubyText("見","み")}てみよか。あ、{addRubyText("雨","あめ")}やあらへんで。{addRubyText("雪","ゆき")}や。</li>
            <li>A: {addRubyText("雪","ゆき")}かいな。{addRubyText("寒","さむ")}いと思たわ。</li>
          </ol>  
        </>} />
      </>
    },
    {
      question: <>{addRubyText("今日","きょう")}の{addRubyText("天気","てんき")}は{addRubyText("雨","あめ")}だ。</>,
      answersCorrect: [
        <>FALSE</>
      ],
      answersIncorrect: [
        <>TRUE</>
      ]
    },
    {
      superQuestion: <>
        <big style={{fontWeight: "bold"}}>(2) </big>{addAudio("3.3.pl0.1.2.mp3")}
        <Hideable content={<> 
          <ol className="transcript">
            <li>A: はい、{addRubyText("天","てん")}ぷらうどん、お{addRubyText("待","ま")}ちどうさん。</li>
            <li>B: あれ、おばちゃんん。うどんとちゃうで、そばやで。</li>
            <li>A: ええっ？うどんとちゃうかったん？すまんなあ。ほしたら、ちょっと{addRubyText("待","ま")}ってな。</li>
          </ol>
        </>} />
      </>
    },
    {
      question: <>この{addRubyText("人","ひと")}は{addRubyText("天","てん")}ぷらそばを{addRubyText("注文","ちゅうもん")}した。</>,
      answersCorrect: [
        <>TRUE</>
      ],
      answersIncorrect: [
        <>FALSE</>
      ] 
    },
    {
      superQuestion: <>
        <big style={{fontWeight: "bold"}}>(3) </big>{addAudio("3.3.pl0.1.3.mp3")}
        <Hideable content={<>
          <ol className="transcript">
            <li>A (mother) : あれ、あんた{addRubyText("今日","きょう")}からアルバイトちゃうかったん？</li>
            <li>B (daughter) : うん。せやけど、{addRubyText("風邪","かぜ")}{addRubyText("引","ひ")}いたから、{addRubyText("休","やす")}んでん。</li>
            <li>A (mother) : そうか。しゃーないなあ。{addRubyText("明日","あした")}から、ちゃんと{addRubyText("行","い")}きや。</li>
          </ol>
        </>} />
      </>
    },
    {
      question: <>アルバイトの{addRubyText("約束","やくそく")}は{addRubyText("明日","あした")}からだ。</>,
      answersCorrect: [
        <>FALSE</>
      ],
      answersIncorrect: [
        <>TRUE</>
      ]
    },
    {
      superQuestion: <>
        <big><span style={{fontWeight: "bold"}}>For Fun</span> </big>{addAudio("3.3.pl0.1.4.mp3")}
        <Hideable content={<>
          <ol className="transcript">
            <li>A: なあ、あの{addRubyText("犬","いぬ")}、チャウチャウちゃう？</li>
            <li>B: チャウチャウとちゃうで。</li>
            <li>A: そやかて、チャウチャウみたいやで。</li>
            <li>B: よう{addRubyText("見","み")}てみぃ。{addRubyText("全然","ぜんぜん")}チャウチャウとちゃうで。</li>
            <li>A: そうか。チャウチャウやと{addRubyText("思","おも")}ってんけどなあ。</li>
          </ol>
        </>} />
      </>
    },
    {
      question: <>あの{addRubyText("犬","いぬ")}は、チャウチャウ{addRubyText("犬","けん")}だ。</>,
      answersCorrect: [
        <>FALSE</>
      ],
      answersIncorrect: [
        <>TRUE</>
      ]
    },
  ];

  return <>
    <h2>{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}1 </h2>
    Listen to the dialogues and decide whether the following statements
    match the content. Submit your answers after finishing all the
    questions.<br />
    <small>
      * Click "SHOW" if you need to refer to the transcript
      <br />
      * Move the mouse over the speaker icon for sound
    </small>
    <br />
    <QandAForm qaObjects={qaObjects}/>
  </>;
}

export default L1;
