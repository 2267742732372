import React from "react";
import { addRubyText, addAudio } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";

const P2 = () => {
  const transcript = <>
    <ol className="transcript">
      <li>A: 晩ご飯、カレー？</li>
      <li>B: カレーじゃないよ、すき焼きだよ。</li>
    </ol>
  </>;

  return <>
    <h2>{addRubyText("練習","れんしゅう")}2 : N+Copula (non-past negative and affirmative)</h2>
    Form a question and an answer for each cue according to
    a model. You may listen to the question, tryto answer it, and check
    your answer. *Answers vary; the recorded answers indicate those
    commonly
    used.<br />
    <small>*Mouse over or click on the speaker icon for sound</small><br />
    <br />
    <span style={{fontWeight: "bold"}}>Model </span>(Cue:
    {addRubyText("晩","ばん")}ご{addRubyText("飯","はん")}、カレー／すき{addRubyText("焼","や")}き)<br />
    &nbsp;&nbsp;&nbsp; A: 晩ご飯、カレー？<br />
    &nbsp;&nbsp;&nbsp; B: カレー<span style={{textDecoration: "underline"}}>ちゃう</span>。
    すき焼き<span style={{textDecoration: "underline"}}>や</span>で。{addAudio("3.3.p.2.mp3")}<br />
    <br />
    Point: Nだー＞Nや (refer to Grammar 1)<br />
    <br />
    ST transcript 
    <br />
    <Hideable content={transcript} />
    <br />
    <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>Cue<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>A (Question)<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>B (Answer)<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>{addRubyText("晩","ばん")}ご{addRubyText("飯","はん")}、カレー／すき{addRubyText("焼","や")}き<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.3.p.2.1.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.3.p.2.1.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>{addRubyText("明日","あした")}、{addRubyText("雪","ゆき")}／{addRubyText("雨","あめ")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.3.p.2.2.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.3.p.2.2.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>{addRubyText("明日","あした")}、{addRubyText("日曜日","にちようび")}／{addRubyText("月曜日","げつようび")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.3.p.2.3.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.3.p.2.3.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>{addRubyText("薬","くすり")}、{addRubyText("好","す")}き／{addRubyText("嫌","きら")}い<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.3.p.2.4.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.3.p.2.4.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>アパート、{addRubyText("便利","べんり")}／{addRubyText("不便","ふべん")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.3.p.2.5.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.3.p.2.5.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>{addRubyText("車","くるま")}、{addRubyText("黒","くろ")}いの／{addRubyText("赤","あか")}いの<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.3.p.2.6.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.3.p.2.6.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>あの{addRubyText("本","ほん")}、{addRubyText("英語","えいご")}／{addRubyText("中国語","ちゅうごくご")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.3.p.2.7.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.3.p.2.7.b.mp3")}<br />
          </td>
        </tr>
      </tbody>
    </table>
  </>;
}

export default P2;
