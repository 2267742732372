import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";

const FishShop = () => {
  const vocabList = <ul style={{display: "block", overflow: "visible"}}>
    <li>
      にしん herring
    </li>
    <li>
      {addRubyText("盛","も")}り{addRubyText("合","あ")}わせ assorted dish
    </li>
    <li>
      {addRubyText("商店街","しょうてんがい")}shopping street
    </li>
    <li>
      はまち young yellowtail
    </li>
    <li>
      さわら Spanish mackerel
    </li>
    <li>
      {addRubyText("味噌漬","みそづ")}け fish/vegetables preserved in miso
    </li>
    <li>
      {addRubyText("漬","つ")}ける preserve; marinade; soak
    </li>
    <li>
      ぶり yellow tail
    </li>
    <li>
      ぐじ＝あまだい snapper, Japanese tilefish
    </li>
  </ul>;

  const qaObjects = [
    {
      question: <span>Q1: How much is the assorted sashimi they recommend?</span>,
      answersIncorrect: [
        <span>200-yen.</span>,
        <span>800-yen.</span>
      ],
      answersCorrect: [
        <span>600-yen.</span>
      ]
    },
    {
      question: <span>Q2: Why is the fish shop not busy?</span>,
      answersIncorrect: [
        <span>The shop is not popular on Wednesday.</span>,
        <span>Most of fish was sold out earlier of the day.</span>
      ],
      answersCorrect: [
        <span>It’s Wednesday and not many shops on the shopping street are open.</span>
      ]
    },
    {
      question: <span>Q3: Why did ミツエさん ask about big fish?</span>,
      answersIncorrect: [
        <span>She wanted to buy {addRubyText("味噌漬","みそづ")}け of a big fish for lunch today.</span>,
        <span>She wanted to buy {addRubyText("味噌漬","みそづ")}け of a big fish since this shop usually sells delicious ones.</span>,
        <span>She wanted to make {addRubyText("味噌漬","みそづ")}け since she bought good <i>miso</i>.</span>
      ],
      answersCorrect: [
        <span>She wanted to make {addRubyText("味噌漬","みそづ")}け since somebody gave her good <i>miso</i>.</span>
      ]
    },
    {
      question: <span>Q4: Does the fish shop sell big fish?</span>,
      answersIncorrect: [
        <span>Never.</span>,
        <span>They used to, but not anymore.</span>
      ],
      answersCorrect: [
        <span>Not today.</span>
      ]
    },
    {
      question: <span>Q5: What kind of fish is the best for 味噌漬け according to the shop owner?</span>,
      answersIncorrect: [
        <span>ぶり(yellowtail).</span>,
        <span>はまち (young yellowtail).</span>,
        <span>ぐじ (snapper).</span>
      ],
      answersCorrect: [
        <span>さわら (Spanish mackerel).</span>
      ]
    },
    {
      question: <span>Q6: What did she buy?</span>,
      answersIncorrect: [
        <span>Two packs each of herring and assorted sashimi.</span>,
        <span>Two packs of herring, one Spanish mackerel and one pack of sashimi.</span>
      ],
      answersCorrect: [
        <span>Two packs of herring and one pack of assorted sashimi.</span>
      ]
    },
    {
      question: <span>Q7: How much are they in total?</span>,
      answersIncorrect: [
        <span>1,200-yen.</span>,
        <span>2,400-yen.</span>
      ],
      answersCorrect: [
        <span>1,400-yen.</span>
      ]
    },
    {
      question: <span>Q8: What is ミツエさん going to do with what she bought that day?</span>,
      answersIncorrect: [
        <span>She will eat them for dinner tonight.</span>,
        <span>She will stock them up for later.</span>
      ],
      answersCorrect: [
        <span>She will eat them for lunch of the day.</span>
      ]
    }
  ];

  return (
    <>
      <h2>{addRubyText("魚屋","さかなや")}で (At a fish shop in Otsu, Shiga)</h2>
      <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>
            [Context] ミツエさん is in a fish
            shop on the shopping street in her
            neighborhood. She just decided to buy two packs of にしん (herring). <br />
          </td>
        </tr>
        </tbody>
      </table>
      <br />
      <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addVideoPlayer("5.1.mp4")}<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            Vocabulary List <br />
            <Hideable content={vocabList} />
          </td>
        </tr>
        </tbody>
      </table>
      <h3>{addRubyText("内容質問","ないようしつもん")} (Content Questions)</h3>
      <QandAForm qaObjects={qaObjects} />
    </>
  );
};

export default FishShop;
