import React from "react";
import { addRubyText, addAudio } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";

const P3 = () => {
  const transcript = <>
    <ol className="transcript">
      <li>A: 牛乳、買ってきた？</li>
      <li>B: 牛乳？まだ。悪いけど買ってきてくれない？</li>
    </ol>
  </>;
  return <>
    <h2>{addRubyText("練習","れんしゅう")}3 : Request: 〜てくれへん</h2>
    Form a question and an answer for each cue according to
    a model. You may listen to the question, try to answer it, and check
    your answer. *Answers vary; the recorded answers indicate those
    commonly used.
    <br />
    <small>*Mouse over or click on the speaker icon for sound</small>
    <br />
    <br />
    <span style={{fontWeight: "bold"}}>Model</span> (Cue: {addRubyText("牛乳","ぎゅうにゅう")}／{addRubyText("買","か")}う)
    <br />
    &nbsp;&nbsp;&nbsp; A: 牛乳、{addRubyText("買","こ")}うてきた？
    <br />
    &nbsp;&nbsp;&nbsp; B: 牛乳？まだ。{addRubyText("悪","わる")}いけど{addRubyText("買","こ")}うてきてくれへん？{addAudio("3.2.p.3.mp3")}
    <br />
    <br />
    Point: 〜てくれない？ "Won't you do... for me?"ー＞〜てくれへん？／〜てんか (refer to Grammar 4)
    <br />
    <br />
    ST transcript
    <br />
    <Hideable content={transcript} />
    <br />
    <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Cue<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            A (Question)<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            B (Answer)<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addRubyText("牛乳","ぎゅうにゅう")}／{addRubyText("買","か")}ってくる<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.3.1.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.3.1.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addRubyText("新聞","しんぶん")}／{addRubyText("取","と")}ってくる<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.3.2.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.3.2.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addRubyText("本","ほん")}／{addRubyText("借","か")}りてくる<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.3.3.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.3.3.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            ゴミ／ほかす(throw away)<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.3.4.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.3.4.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addRubyText("部屋","へや")}／{addRubyText("掃除","そうじ")}する<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.3.5.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.3.5.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addRubyText("自転車","じてんしゃ")}／{addRubyText("洗","あら")}う<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.3.6.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.3.6.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            レポート／{addRubyText("書","か")}く<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.3.7.a.mp3")}</td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.3.7.b.mp3")}</td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addRubyText("犬","いぬ")}／{addRubyText("散歩","さんぽ")}／{addRubyText("連","つ")}れて{addRubyText("行","い")}く<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.3.8.a.mp3")}</td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.3.8.b.mp3")}</td>
        </tr>
      </tbody>
    </table>
  </>;
}

export default P3;
