import React from "react";
import { addRubyText, addAudio } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";

const P5 = () => {
  const transcript = <ol className="transcript">
    <li>A: 京都、いつ行ったの？</li>
    <li>B: 京都？昨日行ったの／んだ。</li>
  </ol>
  return <>
    <h2>{addRubyText("練習", "れんしゅう")}5: Extended Predicate (Past)</h2>
    Form a question and an answer for each cue according to
    a model. You may listen to the question,
    try to answer it, and check your answer. *Answers vary; the recorded
    answers indicate those commonly used.
    <br />
    <small>*Mouse over or click on the speaker icon for sound</small>
    <br />
    <br />
    <span style={{ fontWeight: "bold" }}>Model</span> (Cue: {addRubyText("京都", "きょうと")}／{addRubyText("行", "い")}く)
    <br />
    &nbsp;&nbsp;&nbsp; A: 京都、いつ行ったん？
    <br />
    &nbsp;&nbsp;&nbsp; B: 京都？{addRubyText("昨日", "きのう")}行ってん。{addAudio("3.1.b.p.5.mp3")}
    <br />
    <br />
    Point: question -Aん, statement -Eん (refer to Grammar 2 Extra 1)
    <br />
    <br />
    ST transcript
    <br />
    <Hideable content={transcript} />
    <br />
    <table style={{ textAlign: "left", width: "100%" }} border="1" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{ verticalAlign: "top", textAlign: "center" }}>
            Cue<br />
          </td>
          <td style={{ verticalAlign: "top", textAlign: "center" }}>
            A (Question)<br />
          </td>
          <td style={{ verticalAlign: "top", textAlign: "center" }}>
            B (Answer)<br />
          </td>
        </tr>
        <tr>
          <td style={{ verticalAlign: "top" }}>
            {addRubyText("京都", "きょうと")}／{addRubyText("行", "い")}く<br />
          </td>
          <td style={{ verticalAlign: "top", textAlign: "center" }}>
            {addAudio("3.1.b.p.5.1.a.mp3")}<br />
          </td>
          <td style={{ verticalAlign: "top", textAlign: "center" }}>
            {addAudio("3.1.b.p.5.1.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{ verticalAlign: "top" }}>
            そうじ／する<br />
          </td>
          <td style={{ verticalAlign: "top", textAlign: "center" }}>
            {addAudio("3.1.b.p.5.2.a.mp3")}<br />
          </td>
          <td style={{ verticalAlign: "top", textAlign: "center" }}>
            {addAudio("3.1.b.p.5.2.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{ verticalAlign: "top" }}>
            {addRubyText("宿題", "しゅくだい")}／やる<br />
          </td>
          <td style={{ verticalAlign: "top", textAlign: "center" }}>
            {addAudio("3.1.b.p.5.3.a.mp3")}<br />
          </td>
          <td style={{ verticalAlign: "top", textAlign: "center" }}>
            {addAudio("3.1.b.p.5.3.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{ verticalAlign: "top" }}>
            レポート／{addRubyText("書", "か")}く<br />
          </td>
          <td style={{ verticalAlign: "top", textAlign: "center" }}>
            {addAudio("3.1.b.p.5.4.a.mp3")}<br />
          </td>
          <td style={{ verticalAlign: "top", textAlign: "center" }}>
            {addAudio("3.1.b.p.5.4.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{ verticalAlign: "top" }}>
            {addRubyText("本", "ほん")}／{addRubyText("借", "か")}りに{addRubyText("行", "い")}く<br />
          </td>
          <td style={{ verticalAlign: "top", textAlign: "center" }}>
            {addAudio("3.1.b.p.5.5.a.mp3")}<br />
          </td>
          <td style={{ verticalAlign: "top", textAlign: "center" }}>
            {addAudio("3.1.b.p.5.5.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{ verticalAlign: "top" }}>
            テスト／ある<br />
          </td>
          <td style={{ verticalAlign: "top", textAlign: "center" }}>
            {addAudio("3.1.b.p.5.6.a.mp3")}<br />
          </td>
          <td style={{ verticalAlign: "top", textAlign: "center" }}>
            {addAudio("3.1.b.p.5.6.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{ verticalAlign: "top" }}>
            {addRubyText("車", "くるま")}／{addRubyText("買", "か")}う<br />
          </td>
          <td style={{ verticalAlign: "top", textAlign: "center" }}>
            {addAudio("3.1.b.p.5.7.a.mp3")}<br />
          </td>
          <td style={{ verticalAlign: "top", textAlign: "center" }}>
            {addAudio("3.1.b.p.5.7.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{ verticalAlign: "top" }}>
            ねこ／もらう<br />
          </td>
          <td style={{ verticalAlign: "top", textAlign: "center" }}>
            {addAudio("3.1.b.p.5.8.a.mp3")}<br />
          </td>
          <td style={{ verticalAlign: "top", textAlign: "center" }}>
            {addAudio("3.1.b.p.5.8.b.mp3")}<br />
          </td>
        </tr>
      </tbody>
    </table>
  </>;
}

export default P5;
