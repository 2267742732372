import React from "react";
import { addRubyText, addAudio } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";

const P4 = () => {
  const transcript = <>
    <ol className="transcript">
      <li>A: 宿題、明日まで／今日までなんだよね？</li>
      <li>B: え？今日までじゃなかった？</li>
    </ol>
  </>;

  return <>
    <h2>{addRubyText("練習","れんしゅう")}4 : N+Copula (negative-past)</h2>
    Form a question and an answer for each cue according to
    a model. You may listen to the question, tryto answer it, and check
    your answer. *Answers vary; the recorded answers indicate those
    commonly
    used.<br />
    <small>*Mouse over or click on the speaker icon for sound</small><br />
    <br />
    <span style={{fontWeight: "bold"}}>Model </span>(Cue:
    {addRubyText("宿題","しゅくだい")}、{addRubyText("明日","あした")}まで／{addRubyText("今日","きょう")}まで)<br />
    &nbsp;&nbsp;&nbsp; A: 宿題、明日までやんな？<br />
    &nbsp;&nbsp;&nbsp; B: え？今日まで<span style={{textDecoration: "underline"}}>ちご
    た</span>（<span style={{textDecoration: "underline"}}>やあらへんかった</span>）？
    {addAudio("3.3.p.4.mp3")}<br />
    <br />
    Point: Nじゃなかったー＞Nやあらへんかった／やなかった／ちごた (refer to Grammar 1)<br />
    <br />
    ST transcript
    <br />
    <Hideable content={transcript} />
    <br />
    <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>Cue<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>A (Question)<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>B (Answer)<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>{addRubyText("宿題","しゅくだい")}、{addRubyText("明日","あした")}まで／{addRubyText("今日","きょう")}まで<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.3.p.4.1.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.3.p.4.1.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>{addRubyText("駅","えき")}、{addRubyText("右","みぎ")}の{addRubyText("方","ほう")}／{addRubyText("左","ひだり")}の{addRubyText("方","ほう")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.3.p.4.2.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.3.p.4.2.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>{addRubyText("会議","かいぎ")}、{addRubyText("九時","くじ")}から／{addRubyText("十時","じゅうじ")}から<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.3.p.4.3.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.3.p.4.3.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>{addRubyText("部長","ぶちょう")}、{addRubyText("東京","とうきょう")}／{addRubyText("京都","きょうと")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.3.p.4.4.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.3.p.4.4.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>このジュース、{addRubyText("百円","ひゃくえん")}／{addRubyText("二百円","にひゃくえん")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.3.p.4.5.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.3.p.4.5.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>{addRubyText("田中","たなか")}さんの{addRubyText("車","くるま")}、ホンダ／トヨタ<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.3.p.4.6.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.3.p.4.6.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>あの{addRubyText("本","ほん")}、{addRubyText("借","か")}りたの／{addRubyText("買","か")}ったの<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.3.p.4.7.a.mp3")}</td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.3.p.4.7.b.mp3")}</td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>{addRubyText("降","お")}りる{addRubyText("駅","えき")}、{addRubyText("京都","きょうと")}／{addRubyText("名古屋","なごや")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.3.p.4.8.a.mp3")}</td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.3.p.4.8.b.mp3")}</td>
        </tr>
      </tbody>
    </table>
  </>;
}

export default P4;
