import React from "react";
import { addHrefLink, addImage } from "common/util/generalUtil";

const Links = () => {
  return (
    <>
      <h2>Kansai-related Links <br />
      </h2>
      <h3>General:</h3>
      <div>
        {addHrefLink(
          "http://internet.watch.impress.co.jp/www/article/980601/special.htm",
          "Internet Watch インターネットで関西を体験"
        )} : A number of useful links related to Kansai are included
      </div>
      <div>
        {addHrefLink(
          "http://www.kansai.gr.jp/",
          "Kansai Window"
        )} : Kansai Information in Japanese, English, Chinese and Korean. Kansai Time Out : Monthly magazine (in English) - closed down in 2009
      </div>
      <div>
        {addHrefLink(
          "http://www.kansaiscene.com/",
          "Kansai Scene:"
        )} Monthly magazine (in English)
      </div>
      <div>
        {addHrefLink(
          "http://www.kfm.to/",
          "Kansai Flea Market"
        )}: Information exchange among Kansai foreigners (in English)
      </div>
      <div>
        {addHrefLink(
          "http://www.bento.com/kansai/index.html",
          "Kansai Food page"
        )}: Kansai area restaurant and market guide (in English)
      </div>

      <h3>Kansai Newspapers:</h3>
      <table style={{textAlign: "center", width: "30%"}} align="right" border="0" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>{addImage("7.news.jpg")}<br />
          </td>
        </tr>
        </tbody>
      </table>
      <div>
        {addHrefLink(
          "http://www.asahi.com/area/osaka/",
          "朝日新聞：大阪"
        )} (Asahi Shimbun: Osaka)
      </div>
      <div>
        {addHrefLink(
          "http://www.kyoto-np.co.jp/",
          "京都新聞"
        )} (Kyoto Shimbun)
      </div>
      <div>
        {addHrefLink(
          "http://www.kyoto-np.co.jp/kp/english/index.html",
          "The Kyoto Shimbun News"
        )} (in English)
      </div>
      <div>
        {addHrefLink(
          "http://www.sankei.com/",
          "産経新聞"
        )} (Sankei Shimbun)
      </div>
      <div>
        {addHrefLink(
          "http://www.nara-np.co.jp/",
          "奈良新聞"
        )} (Nara Shimbun)
      </div>
      <div>
        {addHrefLink(
          "http://mainichi.jp/kansai/",
          "毎日新聞：めっちゃ関西"
        )} (Mainichi Shimbun: Meccha Kansai)
      </div>
      <div>
        {addHrefLink(
          "http://www.yomiuri.co.jp/osaka/",
          "読売新聞：関西発"
        )} (Yomiuri Shimbun: Kansai-hatsu)
      </div>
      <div>
        {addHrefLink(
          "http://www.kobe-np.co.jp/",
          "神戸新聞"
        )} (Kobe Shimbun)
      </div>

      <h3>Kansai TV Stations & Programs:</h3>
      <table style={{textAlign: "center", width: "30%"}} align="right" border="0" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>{addImage("7.tv.jpg")}</td>
        </tr>
        </tbody>
      </table>
      <a href="http://www.ktv.co.jp/">関西テレビ</a> (KTV: Kansai Telecasting Co., Ltd.) in Osaka<br />
      <a href="http://www.asahi.co.jp/">朝日放送</a> (ABC: Asahi Broadcasting Co., Ltd.) in Osaka<br />
      <a href="http://www.mbs.jp/">毎日放送 </a>(MBS: Mainichi Broadcasting System, Inc.) in Osaka<br />
      <a href="http://www.ytv.co.jp/">読売放送</a> (YTV: Yomiuri Telecasting Co., Ltd.) in Osaka<br />
      <a href="http://www.tv-osaka.co.jp/">テレビ大阪</a> (Television Osaka, Inc.) in Osaka<br />
      <a href="http://www.kbs-kyoto.co.jp/">KBS京都</a> (KBS: Kyoto Broadcasting Co., Ltd.) in Kyoto<br />
      <a href="http://www.bbc-tv.co.jp/">びわ湖放送</a> (BBC: Biwako Broadcasting Co., Ltd.) in Shiga<br />
      <a href="http://www.sun-tv.co.jp/">サンテレビ</a>(Sun TV: Sun Television Co. Ltd.) in Hyogo<br />
      <a href="http://www.tv-wakayama.co.jp/">テレビ和歌山</a> (WTV: Wakayama Telecasting Co., Ltd.) in Wakayama<br />
      <a href="http://www.mietv.com/">三重テレビ放送</a> (MTV: Mie Television Broadcasting Co., Ltd.) in Mie<br />
      <a href="http://www.asahi.co.jp/knight-scoop/">探偵！ナイトスクープ 公式サイト</a>: Popular TV program in Kansai – Kansai comedians pursue on investigation to answer genuine but funny questions and requests from the audience: <br />

      <h3>City/Prefectural Official Websites (in English):</h3>
      <div>
        京都府 <a href="http://www.pref.kyoto.jp/visitkyoto/en/">Welcome to Kyoto</a>
      </div>
      <div>
        京都市 <a href="http://www.city.kyoto.jp/koho/eng/">Kyoto City Web</a>
      </div>
      <div>
        大阪府/大阪市 <a href="http://www.osaka-info.jp/en/">Osaka Tourist Guide</a>
      </div>
      <div>
        兵庫県 <a href="http://www.hyogo-tourism.jp/english/">Hyogo International Tourism site</a>
      </div>
      <div>
        神戸市 <a href="http://www.city.kobe.lg.jp/foreign/english/index.html">Kobe City</a>
      </div>
      <div>
        滋賀県 <a href="http://www.pref.shiga.lg.jp/chiji/index.html">Welcome to Shiga</a>
      </div>
      <div>
        大津市 <a href="http://www.city.otsu.lg.jp/shisei/koho/hp/honyaku/1390489487448.html#http://www.city.otsu.lg.jp/">Otsu City (Automatic Translation) </a>
      </div>
      <div>
        和歌山県 <a href="http://www.pref.wakayama.lg.jp/english/">Wakayama Prefecture</a> ,
      </div>
      <div>
        和歌山市 <a href="http://www.city.wakayama.wakayama.jp/site/languages/index.html">Wakayama City</a> <br />
      </div>
      <div>
        奈良県 <a href="http://www.pref.nara.jp/item/79931.htm#itemid79931">Nara Prefecture</a> ,
      </div>
      <div>
        奈良市 <a href="http://www15.j-server.com/LUCNARAC/ns/tl.cgi/http%3a//www.city.nara.lg.jp/www/toppage/0000000000000/APM03000.html?SLANG=ja&amp;TLANG=en&amp;XMODE=0&amp;XCHARSET=utf-8&amp;XJSID=0">Nara City (Automatic Translation)</a><br />
      </div>
      <div>
        三重県 <a href="http://www.pref.mie.lg.jp/ENGLISH/index.htm">Mie Prefecture</a>
      </div>

      <h3>Kansai Dialect Websites:</h3>
      <a href="http://www.nihongoresources.com/language/dialects/kansaiben.html">Nihongo Resources</a> : Japan-related resources (EJ-JE Dictionaries etc)
      including grammar and kansaiben in English.<br />
      In the Kansaiben-site; Intro, to the differences in Syntax, grammar,
      particles and Vocabulary List of kansaiben are included.<br />
      <a href="https://web.archive.org/web/20150411074114/http://www.osakaben.jp/">全国大阪弁普及協会</a>: (The national Association for the Promotion of the Osaka
      Dialect) Conclusitve information on Osaka-ben. The site no longer
      available (as of 2015), however, most pages are available on
      internet archive.<br />
      <a href="http://www.akenotsuki.com/kyookotoba/">京言葉</a>: Grammar and accent explanation on Kyoto-ben.<br />
    </>
  );
};

export default Links;