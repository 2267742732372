import React, { useState } from "react";
import { messages } from "i18n/lang/en";

/*
 Expects props of the form:

 QandA({
    question: JSX symbol,
    answersIncorrect: [
      ...JSX symbol
    ],
    answersCorrect: [
      ...JSX symbol
    ],
    showAnswer: true/false,
    setShowAnswer: function,
    selection: true/false,
    setSelection: function
  });
*/
export const QandA = (props) => {
  const showAnswer = props.showAnswer;
  const setShowAnswer = props.setShowAnswer;
  const selection = props.selection;
  const setSelection = props.setSelection;
  const question = props.question;
  const answersIncorrect = props.answersIncorrect;
  const answersCorrect = props.answersCorrect;

  // Is there a better way? I just want to set this state on the first render
  const [order,] = useState(Array.from({length: answersIncorrect.length + answersCorrect.length}, () => Math.random())); 

  const answer2Obj = (answer, id, isCorrect) => {
    const correctnessClass = (isCorrect ? "correct" : "incorrect");
    const selected = selection.has(id);
    const clickHandler = () => {
      if (selected) {
        let copy = new Set(selection);
        copy.delete(id);
        setSelection(copy);
      } else if (answersCorrect.length > 1) {
        let copy = new Set(selection);
        copy.add(id);
        setSelection(copy);
      } else {
        setSelection(new Set([id]));
      }
      setShowAnswer(false);
    };
    let className = "answer";
    if (showAnswer && selected) {
      className += " " + correctnessClass;
    }
    if (selected) {
      className += " selected";
    }
    return <div className={className} key={id} onClick={clickHandler}>
      <input type="radio" checked={selected} readOnly />
      <span style={{paddingLeft: "5px"}}>{answer}</span>  
    </div>
  };

  const renderFeedback = (idsCorrect) => {
    var backgroundColor;
    var text;
    if (showAnswer) {
      const intersection = idsCorrect.filter(id => selection.has(id));
      if (intersection.length === idsCorrect.length && idsCorrect.length === selection.size) {
        // selected set == correct set 
        backgroundColor = "green";
        text = messages.talkInTown.QandA.correct;
      } else if (intersection.length > 0) {
        // selected set \intersect correct set \neq \emptyset
        backgroundColor = "blue";
        text = messages.talkInTown.QandA.almost;
      } else {
        // no correctly selected answers
        return null;
      }
      return (
        <div className="feedback" style={{textAlign: "center", background: backgroundColor}}>
          {text}
        </div>
      );
    } else {
      return null;
    }
  };

  const renderQandA = (question, ansIncorrect, ansCorrect) => {
    ansIncorrect = ansIncorrect.map((a,i) => answer2Obj(a, i.toString(), false));
    ansCorrect = ansCorrect.map((a,i) => answer2Obj(a, (ansIncorrect.length + i).toString(), true));
    let idsCorrect = ansCorrect.map(a => a.key);
    let answers = ansIncorrect.concat(ansCorrect);
    answers.sort((o1,o2) => order[parseInt(o1.key)] - order[parseInt(o2.key)]); 
    return (
      <>
        <div className="checkupqanda">
          {renderFeedback(idsCorrect)}
          <div className="question">
            <span>{question}</span>
          </div>
          <div className="answers">
            {answers}
          </div>
        </div>
      </>
    );
  };

  return renderQandA(question, answersIncorrect, answersCorrect);
};

export const QandAForm = (props) => {
  const resetCallbacks = [];
  const submitCallbacks = [];

  const QandAHelper = (props) => {
    const [selection, setSelection] = useState(new Set());
    const [showAnswer, setShowAnswer] = useState(false);
    submitCallbacks.push(() => {
      setShowAnswer(true);
    });
    resetCallbacks.push(() => {
      setSelection(new Set());
      setShowAnswer(false);
    });
    return QandA({
      ...props.contents,
      showAnswer: showAnswer,
      setShowAnswer: setShowAnswer,
      selection: selection,
      setSelection: setSelection
    });
  };

  return <form className="checkupquestion">
    {props.qaObjects.map((obj, i) => {
      if ("superQuestion" in obj) {
        return <div key={i}>
          {obj.superQuestion} 
        </div>;
      }
      return <div key={i}>
        <QandAHelper contents={obj}/>
      </div>
    })}

    <button className="formbutton" onClick={e => {e.preventDefault(); submitCallbacks.forEach(f => f());}}>Submit</button>
    <button className="formbutton" onClick={e => {e.preventDefault(); resetCallbacks.forEach(f => f());}}>Reset</button>
  </form>
}
