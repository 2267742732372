import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";

const Conversations = () => {
  const kansaiTranscript1 = <>
      <ol class="transcript">
        <li><small>A: <span style={{fontWeight: "bold"}}>せ</span>んせい、や<span
 style={{fontWeight: "bold"}}>す</span>みのひ、なに<span
 style={{fontWeight: "bold", textDecoration: "underline"}}>しはります</span>?<br />
          </small></li>
        <li><small>B:<span style={{fontWeight: "bold"}}>そ</span>うや<span
 style={{fontWeight: "bold"}}>ね</span>え。<span style={{fontWeight: "bold"}}>え</span>い
が<span style={{fontWeight: "bold"}}>み</span>たり、<span
 style={{fontWeight: "bold"}}>りょ</span>うり<span style={{fontWeight: "bold"}}>し</span>た
り。</small></li>
        <li><small>A:へえ、な<span style={{fontWeight: "bold"}}>に</span><span
 style={{textDecoration: "underline"}}>つくらは</span><span
 style={{fontWeight: "bold", textDecoration: "underline"}}>る</span>んです<span
 style={{fontWeight: "bold"}}>か</span>？</small><small> </small></li>
        <li><small>B: えっ、、、た<span style={{fontWeight: "bold"}}>と</span>えば<span
 style={{fontWeight: "bold"}}>サ</span>ラダとか、、。</small></li>
        <li><small>A:<span style={{fontWeight: "bold"}}>それ</span>、やさ<span
 style={{fontWeight: "bold"}}>い</span><span
 style={{textDecoration: "underline"}}>きったはる</span><span
 style={{fontWeight: "bold"}}>だけです</span>やん。</small></li>
        <li><small>B: <span style={{fontWeight: "bold"}}>その</span>きりかたが<span
 style={{fontWeight: "bold"}}>むつか</span>しいん<span
 style={{fontWeight: "bold"}}>や</span>て。</small></li>
      </ol>
  </>;

  const standardTranscript1 = <>
      <ol class="transcript">
        <li><small>A:{addRubyText("先生","せんせい")}、{addRubyText("休","やす")}みの{addRubyText("日","ひ")}は{addRubyText("何","なに")}をなさいますか。<br />
          </small></li>
        <li><small>B: そうねえ。{addRubyText("映画","えいが")}{addRubyText("見","み")}たり、{addRubyText("料理","りょうり")}したり。</small><small><br />
          </small></li>
        <li><small>A: へえ、何をお{addRubyText("作","つく")}りになるんですか。</small></li>
        <li><small>B: えっ、、、たとえばサラダとか、、、。</small></li>
        <li><small>A: それって{addRubyText("野菜","やさい")}{addRubyText("切","き")}ってらっしゃるじゃないですか！</small></li>
        <li><small>B: その切り{addRubyText("方","かた")}が{addRubyText("難","むずか")}しいんだって</small>。<br />
        </li>
      </ol>
  </>;

  const kansaiTranscript2 = <>
      <ol class="transcript">
        <li><small>A: <span style={{fontWeight: "bold"}}>どないしょう</span>。こ<span
 style={{fontWeight: "bold"}}>ん</span>ばん<span style={{fontWeight: "bold"}}>しゅ</span>じ
んの<span style={{fontWeight: "bold"}}>おとうとさん</span>、<span
 style={{fontWeight: "bold"}}>とまりに<span
 style={{textDecoration: "underline"}}>きはる</span></span>ねん。<br />
          </small></li>
        <li><small>B: へえ、<span style={{fontWeight: "bold"}}>どんなひ</span>とな
ん？</small></li>
        <li><small>A: <span style={{fontWeight: "bold"}}>ちょ</span>っと<span
 style={{fontWeight: "bold", textDecoration: "underline"}}>かわったはる</span>ねん。
にん<span style={{fontWeight: "bold"}}>に</span>く、<span
 style={{fontWeight: "bold"}}>りょ</span>うりに<span style={{fontWeight: "bold"}}>つ</span>こ
たら<span style={{fontWeight: "bold"}}>ぜんぜん</span><span
 style={{textDecoration: "underline"}}>たべ</span><span
 style={{fontWeight: "bold", textDecoration: "underline"}}>は</span><span
 style={{textDecoration: "underline"}}>らへん</span>し、いっ<span
 style={{fontWeight: "bold"}}>つ</span>もトマト<span style={{fontWeight: "bold"}}>ジュ</span>ー
スばっ<span style={{fontWeight: "bold"}}>か</span>り<span
 style={{textDecoration: "underline"}}>のまは</span><span
 style={{fontWeight: "bold", textDecoration: "underline"}}>る</span>し。</small></li>
        <li><small>B: トマト<span style={{fontWeight: "bold"}}>ジュ</span>ースばっ<span
 style={{fontWeight: "bold"}}>か</span>り<span
 style={{textDecoration: "underline"}}>のみは</span><span
 style={{fontWeight: "bold", textDecoration: "underline"}}>る</span>ん？<span
 style={{fontWeight: "bold"}}>そら</span>、<span style={{fontWeight: "bold"}}><span
 style={{textDecoration: "underline"}}>かわってはん</span>な</span>あ。</small></li>
        <li><small>A: そんで、<span style={{fontWeight: "bold"}}>ひ</span>るはほ<span
 style={{fontWeight: "bold"}}>と</span>んど<span
 style={{fontWeight: "bold", textDecoration: "underline"}}>ねたはる</span>し、よ<span
 style={{fontWeight: "bold"}}>る</span>は<span
 style={{textDecoration: "underline"}}>あるきまわら</span><span
 style={{fontWeight: "bold", textDecoration: "underline"}}>はん</span>ねん。</small></li>
        <li><small>B: <span style={{fontWeight: "bold"}}>ひ</span>る<span
 style={{fontWeight: "bold", textDecoration: "underline"}}>ねては</span><span
 style={{textDecoration: "underline"}}>って</span>、よ<span
 style={{fontWeight: "bold"}}>る</span>あ<span
 style={{textDecoration: "underline"}}>るきまわり</span><span
 style={{fontWeight: "bold", textDecoration: "underline"}}>はん</span>の？<span
 style={{fontWeight: "bold"}}>そのおとうとさん</span>、ドラキュラ<span
 style={{fontWeight: "bold"}}>ちゃうか</span>？？！</small></li>
      </ol>
  </>;

  const standardTranscript2 = <>
      <ol class="transcript">
        <li><small>A:どうしよう。{addRubyText("今晩","こんばん")}{addRubyText("主人","しゅじん")}の{addRubyText("弟","おとうと")}さん、{addRubyText("泊","と")}まりに{addRubyText("来","く")}る
の。<br />
          </small></li>
        <li><small>B: へえ、どんな{addRubyText("人","ひと")}なの。<br />
          </small></li>
        <li><small>A:
ちょっと{addRubyText("変","か")}わってるの。ニンニクを{addRubyText("料理","りょうり")}に{addRubyText("使","つか")}ったら{addRubyText("全然","ぜんぜん")}{addRubyText("食","た")}べないし、いつもトマトジュースばっかり
{addRubyText("飲","の")}むし。<br />
          </small></li>
        <li><small>B: トマトジュースばかり飲むの？それは変わってるわね。<br />
          </small></li>
        <li><small>A: それで{addRubyText("昼","ひる")}はほとんど{addRubyText("寝","ね")}ているし、{addRubyText("夜","よる")}は{addRubyText("歩","ある")}き{addRubyText("回","まわ")}るの。<br />
          </small></li>
        <li><small>B: 昼寝ていて、夜歩き回るの？その弟さん、ドラキュラじゃないの？？！</small> </li>
      </ol>
  </>;

  const kansaiTranscript3 = <>
      <ol class="transcript">
        <li><small>A:ちょっと<span style={{fontWeight: "bold"}}>き</span>いた？<span
 style={{fontWeight: "bold"}}>け</span>いこちゃん、<span
 style={{fontWeight: "bold"}}>けっこん<span
 style={{textDecoration: "underline"}}>しはる</span></span>んやって。</small></li>
        <li><small>B:へえ？<span style={{fontWeight: "bold"}}>けっこん<span
 style={{textDecoration: "underline"}}>し</span></span><span
 style={{textDecoration: "underline"}}>よん</span>の？<span
 style={{fontWeight: "bold"}}>け</span>いこちゃんって、<span
 style={{fontWeight: "bold"}}>か</span>れし<span
 style={{fontWeight: "bold", textDecoration: "underline"}}>い</span><span
 style={{textDecoration: "underline"}}>よった</span>んや。</small></li>
        <li><small>A:<span style={{fontWeight: "bold"}}>さいきん</span>、<span
 style={{fontWeight: "bold"}}>インターネ</span>ットで<span
 style={{fontWeight: "bold", textDecoration: "underline"}}>しりあわ</span><span
 style={{textDecoration: "underline"}}>はってん</span><span
 style={{fontWeight: "bold"}}>て</span>。</small></li>
        <li><small>B:ネットで<span
 style={{fontWeight: "bold", textDecoration: "underline"}}>であい</span><span
 style={{textDecoration: "underline"}}>よった</span>んかあ〜。ええ<span
 style={{fontWeight: "bold"}}>な</span>あ。お<span style={{fontWeight: "bold"}}>れ</span>も<span
 style={{fontWeight: "bold"}}>やろ</span>かなあ。</small></li>
        <li><small>A:&nbsp; <span style={{fontWeight: "bold"}}>ほ</span>な、<span
 style={{fontWeight: "bold"}}>ま</span>ずじぶん<span style={{fontWeight: "bold"}}>の</span>コ
ン<span style={{fontWeight: "bold"}}>ピュ</span>ータ<span
 style={{fontWeight: "bold"}}>かわんか</span>いな。</small></li>
      </ol>
  </>;

  const standardTranscript3 = <>
      <ol class="transcript">
        <li><small>A: ちょっと{addRubyText("聞","き")}いた？けいこちゃん、{addRubyText("結婚","けっこん")}するんだって。</small></li>
        <li><small>B: へえ？結婚するの？けいこちゃんって、{addRubyText("彼氏","かれし")}いたんだ。<br />
          </small></li>
        <li><small>A{addRubyText("最近","さいきん")}インターネットで{addRubyText("知","し")}り{addRubyText("合","あ")}ったんだって。<br />
          </small></li>
        <li><small>B: ネットで{addRubyText("出会","であ")}ったのかあ。いいなあ。{addRubyText("俺","おれ")}もやろうかなあ。<br />
          </small></li>
        <li><small>A: じゃあ、まず{addRubyText("自分","じぶん")}のコンピュータ{addRubyText("買","か")}ったら？</small><br />
        </li>
      </ol>
  </>;

  const kansaiTranscript4 = <>
      <ol class="transcript">
        <li><small>A: <span style={{fontWeight: "bold"}}>このへ</span>や、<span
 style={{fontWeight: "bold"}}>このご</span>ろ、ゴキブリ<span
 style={{fontWeight: "bold"}}>よ</span>うけ<span style={{fontWeight: "bold"}}>で</span>よ
るねん。<br />
          </small></li>
        <li><small>B: へえ、く<span style={{fontWeight: "bold"}}>す</span>り<span
 style={{fontWeight: "bold"}}>おいと</span>いた<span style={{fontWeight: "bold"}}>ら</span>？<br />
          </small></li>
        <li><small>A: たべ<span style={{fontWeight: "bold"}}>よ</span>るか<span
 style={{fontWeight: "bold"}}>な</span>あ。<br />
          </small></li>
        <li><small>B:<span style={{fontWeight: "bold"}}> だいじょ</span>うぶ<span
 style={{fontWeight: "bold"}}>や</span>て。たべ<span style={{fontWeight: "bold"}}>も</span>ん
や<span style={{fontWeight: "bold"}}>お</span>もて、たべ<span
 style={{fontWeight: "bold"}}>よ</span>るて。<br />
          </small></li>
        <li><small>A: <span style={{fontWeight: "bold"}}>しぃ</span>！こ<span
 style={{fontWeight: "bold"}}>え</span>が<span style={{fontWeight: "bold"}}>おお</span>き
い。ゴキブリが<span style={{fontWeight: "bold"}}>きいと</span>るかも<span
 style={{fontWeight: "bold"}}>しれん</span>がな。<br />
          </small></li>
        <li><small>B: <span style={{fontWeight: "bold"}}>んな</span>あ<span
 style={{fontWeight: "bold"}}>ほ</span>な。。</small></li>
      </ol>
  </>;

  const standardTranscript4 = <>
      <ol class="transcript">
        <li><small>A: この{addRubyText("部屋","へや")}、この{addRubyText("頃","ごろ")}ゴキブリがたくさん{addRubyText("出","で")}るの。<br />
          </small></li>
        <li><small>B: へえ、{addRubyText("薬","くすり")}{addRubyText("置","お")}いておいたら？<br />
          </small></li>
        <li><small>A{addRubyText("食","た")}べるかなあ。<br />
          </small></li>
        <li><small>B:{addRubyText("大丈夫","だいじょうぶ")}だって。食べ{addRubyText("物","もの")}だと{addRubyText("思","おも")}って食べるって。<br />
          </small></li>
        <li><small>A: しぃ！{addRubyText("声","こえ")}が{addRubyText("大","おお")}きい。ゴキブリが{addRubyText("聞","き")}いてるかもしれないじゃない。<br />
          </small></li>
        <li><small>B: そんなばかな。。。</small><br />
        </li>
      </ol>
  </>;

  const kansaiTranscript5 = <>
      <ol class="transcript">
        <li><small>A: きょ<span style={{fontWeight: "bold"}}>う</span>、エアロビの<span
 style={{fontWeight: "bold"}}>ひ</span>と<span style={{fontWeight: "bold"}}>ちゃ
う</span>のん？<br />
          </small></li>
        <li><small>B: うん、<span style={{fontWeight: "bold"}}>せ</span>やけど、<span
 style={{fontWeight: "bold"}}>せ</span>んせぇ<span style={{fontWeight: "bold"}}>か
ぜひか</span>はって、や<span style={{fontWeight: "bold"}}>す</span>みやねん。<br />
          </small></li>
        <li><small>A: <span style={{fontWeight: "bold"}}>せ</span>んせぇも<span
 style={{fontWeight: "bold"}}>ひきは</span>ったん？<span
 style={{fontWeight: "bold"}}>うちのおとうと</span>も<span
 style={{fontWeight: "bold"}}>かぜひき</span>よってん。<br />
          </small></li>
        <li><small>B:へえ、<span style={{fontWeight: "bold"}}>おとうとさん</span>も<span
 style={{fontWeight: "bold"}}>かぜひか</span>はったん？<span
 style={{fontWeight: "bold"}}>このご</span>ろ<span style={{fontWeight: "bold"}}>か
ぜはやってる</span>もん<span style={{fontWeight: "bold"}}>な</span>あ。かいしゃの<span
 style={{fontWeight: "bold"}}>ひ</span>とも<span style={{fontWeight: "bold"}}>よ</span>う
け<span style={{fontWeight: "bold"}}>ひいたはる</span>もん。<br />
          </small></li>
        <li><small>A:<span style={{fontWeight: "bold"}}>ほん</span>だら、わた<span
 style={{fontWeight: "bold"}}>し</span>らなんで<span style={{fontWeight: "bold"}}>げ</span>ん
きなん？<br />
          </small></li>
        <li><small>B:『あ<span style={{fontWeight: "bold"}}>ほ</span>は<span
 style={{fontWeight: "bold"}}>かぜひかん</span>』<span
 style={{fontWeight: "bold"}}>ゆう</span>やん。<br />
          </small></li>
        <li><small>A: 、、<span style={{fontWeight: "bold"}}>ハ</span>ックション！<span
 style={{fontWeight: "bold"}}>あ</span>、わたし<span style={{fontWeight: "bold"}}>か
ぜひいて</span>もた！</small></li>
      </ol>
  </>;

  const standardTranscript5 = <>
      <ol class="transcript">
        <li><small>A:{addRubyText("今日","きょう")}エアロビの{addRubyText("日","ひ")}じゃないの？<br />
          </small></li>
        <li><small>B: うん、だけど{addRubyText("先生","せんせい")}が{addRubyText("風邪","かぜ")}おひきになって、{addRubyText("休","やす")}みなの。<br />
          </small></li>
        <li><small>A:&nbsp; えっ？先生も風邪おひきになったの？うちの{addRubyText("弟","おとうと")}も風邪ひいたのよ。<br />
          </small></li>
        <li><small>B:へえ、弟さんも風邪ひいたの？このごろ風邪はやってるもんね。{addRubyText("会社","かいしゃ")}の{addRubyText("人","ひと")}もたくさんひ
いてるもん。<br />
          </small></li>
        <li><small>A: じゃあ、どうして{addRubyText("私","わたし")}たち{addRubyText("元気","げんき")}なの？<br />
          </small></li>
        <li><small>B: 『バカは風邪ひかない』って{addRubyText("言","い")}うじゃない。<br />
          </small></li>
        <li><small>A: 、、ハックション！あ、私、風邪ひいちゃった！</small><br />
        </li>
      </ol>
  </>;

  return <>
<h2>{addRubyText("基本会話","きほんかいわ")} (Core Conversations)</h2>
<hr />
<h3>{addRubyText("会話","かいわ")}１ : What&nbsp; does A’s teacher do on Sundays? <br />
</h3>
*A=first speaker, B=second speaker<br />
*Click "SHOW" for transcripts<br />
<br />
(A is a student from Kyoto.&nbsp; B is A’s teacher.)<br />
<table style={{textAlign: "left", width: "100%"}} border="1"cellPadding="2"
 cellSpacing="2">
  <tbody>
    <tr>
      <td style={{verticalAlign: "top", textAlign: "center"}} class="video"
 colSpan="1" rowSpan="2">{addVideoPlayer("4.4.cc.1.mp4")}</td>
      <td style={{verticalAlign: "top"}} class="transcriptcell">Kansai
transcript
        <Hideable content={kansaiTranscript1}/>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}} class="transcriptcell">Standard
transcript
        <Hideable content={standardTranscript1}/>
      </td>
    </tr>
  </tbody>
</table>
<br />
<h3>{addRubyText("会話","かいわ")}2&nbsp; : That’s why he is mysterious? <br />
</h3>
(A and B are friends. A is from Kyoto and B is from Osaka. A is talking
about her brother-in-law.)<br />
<table style={{textAlign: "left", width: "100%"}} border="1"cellPadding="2"
 cellSpacing="2">
  <tbody>
    <tr>
      <td style={{verticalAlign: "top", textAlign: "center"}} class="video"
 colSpan="1" rowSpan="2">{addVideoPlayer("4.4.cc.2.mp4")}</td>
      <td style={{verticalAlign: "top"}} class="transcriptcell">Kansai
transcript
        <Hideable content={kansaiTranscript2}/>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}} class="transcriptcell">Standard
transcript 
        <Hideable content={standardTranscript2}/>
      </td>
    </tr>
  </tbody>
</table>
<br />
<small>* にんにく garlic</small><br />
<br />
<h3>{addRubyText("会話","かいわ")}3 :&nbsp; How did Keiko-chan meet her future husband? <br />
</h3>
(A, B and Keiko-chan are friends)<br />
<table style={{textAlign: "left", width: "100%"}} border="1"cellPadding="2"
 cellSpacing="2">
  <tbody>
    <tr>
      <td style={{verticalAlign: "top", textAlign: "center"}} class="video"
 colSpan="1" rowSpan="2">{addVideoPlayer("4.4.cc.3.mp4")}</td>
      <td style={{verticalAlign: "top"}} class="transcriptcell">Kansai
transcript 
        <Hideable content={kansaiTranscript3}/>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}} class="transcriptcell">Standard
transcript 
        <Hideable content={standardTranscript3}/>
      </td>
    </tr>
  </tbody>
</table>
<br />
<h3>{addRubyText("会話","かいわ")}４ : Who might be eavesdropping on their conversation? <br />
</h3>
(A and B are friends)<br />
<table style={{textAlign: "left", width: "100%"}} border="1"cellPadding="2"
 cellSpacing="2">
  <tbody>
    <tr>
      <td style={{verticalAlign: "top", textAlign: "center"}} class="video"
 colSpan="1" rowSpan="2">{addVideoPlayer("4.4.cc.4.mp4")}</td>
      <td style={{verticalAlign: "top"}} class="transcriptcell">Kansai
transcript 
        <Hideable content={kansaiTranscript4}/>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}} class="transcriptcell">Standard
transcript 
        <Hideable content={standardTranscript4}/>
      </td>
    </tr>
  </tbody>
</table>
<br />
<small>* ゴキブリ cockroach</small><br />
<br />
<h3>{addRubyText("会話","かいわ")}5 : Who caught a cold, and who hasn’t?<br />
</h3>
(A is from Osaka, and B is from Kyoto. They are friends.)<br />
<table style={{textAlign: "left", width: "100%"}} border="1"cellPadding="2"
 cellSpacing="2">
  <tbody>
    <tr>
      <td style={{verticalAlign: "top", textAlign: "center"}} class="video"
 colSpan="1" rowSpan="2">{addVideoPlayer("4.4.cc.5.mp4")}</td>
      <td style={{verticalAlign: "top"}} class="transcriptcell">Kansai
transcript
        <Hideable content={kansaiTranscript5}/>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}} class="transcriptcell">Standard
transcript 
        <Hideable content={standardTranscript5}/>
      </td>
    </tr>
  </tbody>
</table>
<br />
<small>* エアロビ&nbsp; aerobics</small><br />
<br />
<hr />
<h3>{addRubyText("理解度","りかいど")}チェック (Check-up)&nbsp; <br />
</h3>
Figure out the Kansai-specific grammar and expressions that appear in
the
video clips. Refer to the standard and Kansai transcripts if necessary.<br />
<br />
<table style={{textAlign: "left", width: "100%"}} border="1"cellPadding="2"
 cellSpacing="2">
  <tbody>
    <tr>
      <td style={{verticalAlign: "top", textAlign: "center"}}>Check-up<br />
      </td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>Answer<br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>1) What expression is used to
exalt a person in Kansai-ben?<br />
      </td>
      <td style={{verticalAlign: "top"}}><Hideable content={<>はる</>}/><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>2) What do you say the following
honorific polite expression in Kansai-ben (in Kyoto): なさる,
お{addRubyText("作","つく")}りになる、{addRubyText("切","き")}っていらっしゃる?<br />
      </td>
      <td style={{verticalAlign: "top"}}><Hideable content={<>
しはる、{addRubyText("作","つく")}らはる、{addRubyText("切","き")}ったはる</>}/><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>3) In cc 2, to whom do they use
はる? Is the function of はる the same as that in cc1?<br />
      </td>
      <td style={{verticalAlign: "top"}}><Hideable content={<>They use はる to
describe A’s husband’s little brother. Its
function is not to exalt him, but to show psychological distance from
the person or just to be polite.</>}/><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>4) Are there any differences as
to what form of verb はる follows between Kyoto and Osaka speakers?
(refer to cc 2)<br />
      </td>
      <td style={{verticalAlign: "top"}}><Hideable content={<>&nbsp; Yes.</>}/></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>5) How do Kyoto and Osaka
speakers add はる to {addRubyText("飲","の")}む、{addRubyText("歩","ある")}き{addRubyText("回","まわ")}る、{addRubyText("変","か")}わっている、{addRubyText("寝","ね")}ている
respectively?</td>
      <td style={{verticalAlign: "top"}}><Hideable content={<>Kyoto-
{addRubyText("飲","の")}まはる、{addRubyText("歩","ある")}き{addRubyText("回","まわ")}らはる、{addRubyText("変","か")}わったはる、{addRubyText("寝","ね")}たはる,
Osaka-{addRubyText("飲","の")}みはる、{addRubyText("歩","ある")}き{addRubyText("回","まわ")}りはる、{addRubyText("変","か")}わってはる、{addRubyText("寝","ね")}てはる</>}/></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>6) In cc 3, why does the female
speaker use はる to her friend けいこちゃん?<br />
      </td>
      <td style={{verticalAlign: "top"}}><Hideable content={<>&nbsp; probably just
to be gentle, feminine and polite</>}/></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>7) In cc 3, what does the male
speaker use to talk about けいこちゃん’s action?<br />
      </td>
      <td style={{verticalAlign: "top"}}><Hideable content={<>よる, not はる</>}/></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>8) How does it (answer of 7)
sound compared to the female speaker’s use of はる?</td>
      <td style={{verticalAlign: "top"}}><Hideable content={<>よる seems to
indicate bluntness of the male speaker.</>}/></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>9) In cc 3, do they use はる／よる
expression to each other’s action?</td>
      <td style={{verticalAlign: "top"}}><Hideable content={<>No. はる／よる are used
only for けいこちゃん, the third person.</>}/><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>10) In cc 4, to whom do they use
よる? Why do you think they use the
expression? If you use はる instead ofよるin this situation, how would it
sound like?</td>
      <td style={{verticalAlign: "top"}}><Hideable content={<>To cockroaches, in
order to show disgust or look down on
them. If you used はる, it would sound as if you exalt/be polite to
cockroaches and would be quite awkward.</>}/><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>11) In cc 4, the speaker A once
used a different expression from よる to
talk about the subject. What did she use, and why do you think she used
it?</td>
      <td style={{verticalAlign: "top"}}><Hideable content={<>She used とる as in
{addRubyText("聞","き")}いとる, which means {addRubyText("聞","き")}いている in the
standard. It seems よる changes to とる when combined with&nbsp; 〜ている.</>}/></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>12) What functions do はる andよる
have respectively? Choose from the followings: <br />
      <br />
a. to exalt a person you are talking about. <br />
b. to show psychological distance from whom you are talking about.<br />
c. to show hierarchical superiority over whom you are talking about.<br />
d. to be blunt. <br />
e. to be gentle.<br />
f. to be polite.<br />
h. to look down on whom you are talking about.</td>
      <td style={{verticalAlign: "top"}}><Hideable content={<>はる- a, b, e,
f&nbsp; よる- c, d, h </>}/></td>
    </tr>
  </tbody>
</table>
<br />
<hr />
<h3>{addRubyText("関西","かんさい")}{addRubyText("語彙","ごい")}チェック (Kansai Vocabulary Check-up) <br />
</h3>
What are the standard equivalent expression of the following Kansai
words that are introduced in the core conversations of this lesson?<br />
<br />
<table style={{textAlign: "left", width: "60%"}} border="1"cellPadding="2"
 cellSpacing="2">
  <tbody>
    <tr>
      <td style={{verticalAlign: "top", textAlign: "center"}}>Kansai<br />
      </td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>Standard<br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>どない<br />
      </td>
      <td style={{verticalAlign: "top"}}><Hideable content={<>どう</>}/><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>ようけ<br />
      </td>
      <td style={{verticalAlign: "top"}}><Hideable content={<>たくさん</>}/><br />
      </td>
    </tr>
  </tbody>
</table>
  </>;
}

export default Conversations;
