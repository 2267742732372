import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";

const V17 = () => {
  const transcript = <>
    ほやけど、{addRubyText("今","いま")}の{addRubyText("若","わか")}い{addRubyText("人","ひと")}、{addRubyText("幸","しあわ")}せな{addRubyText("時代","じだい")}やろか。どっちやろ？わからん。{addRubyText("却","かえ")}ってかわいそうか
    な？{addRubyText("私","わたし")}らの時代の若い{addRubyText("時","とき")}の{addRubyText("方","ほう")}が、{addRubyText("物","もの")}の{addRubyText("不自由","ふじゆう")}さはあったけど、{addRubyText("今","いま")}ありすぎて却ってどう
    やろな？{addRubyText("思","おも")}うわ。ほんで、{addRubyText("孫","まご")}でもなんでも、{addRubyText("与","あた")}えていいか{addRubyText("悪","わる")}いか、{addRubyText("程度","ていど")}が{addRubyText("分","わ")}からへんけども。
    やっぱり、なんでもいつまでたっても、私らは物は{addRubyText("大事","だいじ")}、{addRubyText("粗末","そまつ")}にしたらあかん，そういうことを{addRubyText("教","おそ")}わってきたやん？そやけ
    ど，今は{addRubyText("割合","わりあい")}おおざっぱやわ。、、、なあ。ありすぎて。そう。ありがたさをやっぱりな<span
    style={{textDecoration: "underline"}}>{addRubyText("感","かん")}じなあかんな</span> (Grammar
    3)，と思うのと、、。
  </>;

  const qaObjects = [
    {
      question: <span>Q1: Does she think young people nowadays are living happily?</span>,
      answersCorrect: [
        <>She thinks plentiful goods might make them unhappy instead.</>
      ],
      answersIncorrect: [
        <>She believes they are happy since they are surrounded by plentiful goods.</>,
        <>She thinks they are not happy because there are plenty of goods nowadays but they cannot afford them.</>
      ]
    },
    {
      question: <span>Q2: What does she suggest to young people nowadays?</span>,
      answersCorrect: [
        <>They should learn to appreciate goods instead of taking it for granted.</>
      ],
      answersIncorrect: [
        <>They should lean which stuff is more important than others.</>,
        <>They should feel appreciative of the people who give goods to them. </>
      ]
    }
  ];

  return <>
    <h2>ビデオ{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}１７: Authentic - long<br />
    </h2>
    Watch the video clip and choose the answer that matches the content of
    the clip. Submit your answers after answering all the questions.<br />
    <small>*Click "SHOW" if you need to refer to the transcript.</small><br />
    <br />
    Ishioh-san asks herself whether her youth or the present is the
    happier time.<br />
    <table style={{textAlign: "left", width: "100%"}} border="0"cellPadding="2"
    cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>{addVideoPlayer("4.2.3.p.7.ishioh.mp4")} </td>
          <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>Transcript
            <Hideable content={transcript}/><br />
            ＊{addRubyText("時代","じだい")} era, period<br />
            ＊{addRubyText("不自由","ふじゆう")} constrained <br />
            ＊{addRubyText("孫","まご")} grandchildren <br />
            ＊{addRubyText("程度","ていど")} level <br />
            ＊{addRubyText("祖末","そまつ")}にする waste, handle carelessly <br />
            ＊{addRubyText("教","おそ")}わる learn, be taught <br />
    ＊おおざっぱ rough, loose</td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>イシオウさん
    (Shiga)</td>
        </tr>
      </tbody>
    </table>
    <br />
    <QandAForm qaObjects={qaObjects}/>
  </>;
}

export default V17;
