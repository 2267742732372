import React from "react";
import { addRubyText, addImage } from "common/util/generalUtil";
import { Link } from "react-router-dom";
import { Page } from "home/page";

const Grammar = () => {
  return <>
    <h2>{addRubyText("文法","ぶんぽう")}ノート (Grammar Note) <br/></h2>
    <span style={{fontWeight: "bold"}}>
      &lt; Summary of the Sentence Ending Expressions &gt;
    </span>
    <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>
            <br/>
          </td>
          <td style={{verticalAlign: "top"}}>
            Standard<br/>
          </td>
          <td style={{verticalAlign: "top"}}>
            Kansai<br/>
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            Conformation<br/>
          </td>
          <td style={{verticalAlign: "top"}}>
            ね<br/>
          </td>
          <td style={{verticalAlign: "top"}}>
            な／（のう）<br/>
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            Assertion<br/>
          </td>
          <td style={{verticalAlign: "top"}}>
            よ<br/>
          </td>
          <td style={{verticalAlign: "top"}}>
            わ／で／（がな）<br/>
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            Question<br/>
          </td>
          <td style={{verticalAlign: "top"}}>
            ？(raising tone)<br/>
          </td>
          <td style={{verticalAlign: "top"}}>
            ？／か／（け）／（かいな）<br/>
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            Extended predicate (Question)<br/>
          </td>
          <td style={{verticalAlign: "top"}}>
            の？<br/>
          </td>
          <td style={{verticalAlign: "top"}}>
            ん／のん？<br/>
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            Extended predicate (Statement)<br/>
          </td>
          <td style={{verticalAlign: "top"}}>
            の (feminine)<br/>
            んだ<br/>
          </td>
          <td style={{verticalAlign: "top"}}>
            ねん<br/>
            （んや／にゃ）<br/>
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            Strong conformation/conviction/assertion<br/>
          </td>
          <td style={{verticalAlign: "top"}}>
            じゃない（か）<br/>
            じゃん（か）<br/>
          </td>
          <td style={{verticalAlign: "top"}}>
            やん（か／かいさ）<br/>
            <br/>
          </td>
        </tr>
      </tbody>
    </table>
    <br/>
    <hr/>
    <table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td colSpan="1" rowSpan="1" style={{verticalAlign: "top"}}>
            <ol>
              <li>
                <Link to={Page.BasicGrammar.SentenceEndings.Grammar.SentenceParticles}>
                  Sentence Particles
                </Link>
              </li>
              <li>
                <Link to={Page.BasicGrammar.SentenceEndings.Grammar.ExtendedPredicate}>
                  Extended Predicates
                </Link>
              </li>
              <li>
                <Link to={Page.BasicGrammar.SentenceEndings.Grammar.Yanka}>
                  やんか
                </Link>
              </li>
            </ol>
            <ul>
              <li>
                <Link to={Page.BasicGrammar.SentenceEndings.Grammar.QuotationMarker}>
                  Notes on Quotation Marker
                </Link>
              </li>
            </ul>
          </td>
          <td colSpan="1" rowSpan="1" style={{verticalAlign: "top", textAlign: "center"}}>
            {addImage("heianjomikuji.jpg")}
            <br/>
            <small>
              平安神宮のおみくじ　Fortune slips tied to the tree, Heian Jingu (Kyoto)
            </small>
            <br/>
          </td>
        </tr>
        <tr align="center">
        </tr>
      </tbody>
    </table>
  </>;
}

export default Grammar;