import React from "react";
import { addRubyText, addImage } from "common/util/generalUtil";

const JPop = () => {
  return (
    <>
      <h2>関西弁の歌 (JPOP in Kansai-ben/ about Kansai)</h2>
      <table style={{textAlign: "center", width: "30%"}} align="right" border="0" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addImage("7.jpop.jpg")}
          </td>
        </tr>
        </tbody>
      </table>
      <p>
        We were actually impressed to find out that there are many more
        songs written in Kansai-ben than we had originally expected. Most
        are, of course, written or sung by native Kansai artists, probably
        in order to express their true voice, to show their local identity
        through their work to be true to themselves. You will be surprised
        to see even some familiar JPOP artists such as SMAP have produced
        songs in Kansai-ben. We cannot list all the songs, but here are some
        examples to get a taste.
      </p>
      <p>
        *We added links to the lyrics as much as possible. (as of October 2016)
      </p>

      <h3>Songs in Kansai-ben (or including Kansai-ben)</h3>
      <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td colSpan="2" rowSpan="1" style={{textAlign: "center"}}>
            Artist
          </td>
          <td style={{textAlign: "center"}}>Song title</td>
        </tr>
        <tr>
          <td colSpan="1" rowSpan="13">あ</td>
          <td>ASAS</td>
          <td>
            <a href="http://j-lyric.net/artist/a055a2d/l0268a0.html">めっ ちゃ{addRubyText("好","す")}きやねん</a>
          </td>
        </tr>
        <tr>
          <td valign="top">IKECHAN</td>
          <td valign="top">
            <a href="http://j-lyric.net/artist/a04ad6b/l005e2c.html">祭 花(SAIKA) </a>
          </td>
        </tr>
        <tr>
          <td>WEST END × YUKI（from O. P. D. )</td>
          <td>
            <a href="http://www.utamap.com/showkasi.php?surl=55610">
              SO.YA.NA（そやな）
            </a>
          </td>
        </tr>
        <tr>
          <td>
            {addRubyText("上田正樹","うえだまさき")}
          </td>
          <td>
            <a href="http://www.utamap.com/showkasi.php?surl=35575">{addRubyText("悲","かな")}しい{addRubyText("色","いろ")}やね </a>
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addRubyText("植村花菜","うえむらかな")}
          </td>
          <td style={{verticalAlign: "top"}}>
            <a href="http://j-lyric.net/artist/a0453d8/l01e8cb.html">トイレの{addRubyText("神様","かみさ ま")}</a>
          </td>
        </tr>
        <tr />
        <tr />
        <tr>
          <td colSpan="1" rowSpan="3">
            ウルフルズ (Ulfuls)
          </td>
          <td>
            <a href="http://j-lyric.net/artist/a0006e9/l00237a.html">ア ホでケッコー</a>
          </td>
        </tr>
        <tr>
          <td>
            <a href="http://www.utamap.com/showkasi.php?surl=B05261">
              え えねん
            </a>
          </td>
        </tr>
        <tr>
          <td>
            <a href="http://www.utamap.com/showkasi.php?surl=57919">{addRubyText("大阪","おお さか")}ストラット</a>
          </td>
        </tr>
        <tr>
          <td colSpan="1" rowSpan="3">Et-King</td>
          <td>
            <a href="http://j-lyric.net/artist/a04cbce/l009d8d.html">{addRubyText("愛","いと")}しい{addRubyText("人","ひと")}へ</a>
          </td>
        </tr>
        <tr>
          <td valign="top">
            <a href="http://j-lyric.net/artist/a04cbce/l0379b2.html">
              ええやん け{addRubyText("阪神","はんしん")}
            </a>
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            <a href="http://j-lyric.net/artist/a04cbce/l00b4de.html">ギフト</a>
          </td>
        </tr>
        <tr>
          <td valign="top" />
          <td valign="top">{addRubyText("大塚愛","おおつかあい")}</td>
          <td valign="top">
            <a href="http://www.uta-net.com/song/18882/">{addRubyText("石川","いしかわ")}大阪{addRubyText("友好条約","ゆうこうじょうやく")}</a>
          </td>
        </tr>
        <tr>
          <td valign="top" />
          <td valign="top">{addRubyText("尾崎和行","おざきかずゆき")}
          </td>
          <td valign="top">{addRubyText("涙","なみだ")}が{addRubyText("止","と")}まれへん
          </td>
        </tr>
        <tr>
          <td valign="top" />
          <td valign="top">オール{addRubyText("巨人","きょじん")}
          </td>
          <td valign="top">
            <a href="%E3%81%82%E3%82%93%E3%81%98%E3%82%87%E3%81%86%E3%82%84%E3%82%8A%E3%82%84++%E3%82%AA%E3%83%BC%E3%83%AB%E5%B7%A8%E4%BA%BA">
              あ んじょうやりや
            </a>
          </td>
        </tr>
        <tr>
          <td colSpan="1" rowSpan="20">か</td>
          <td colSpan="1" rowSpan="5">{addRubyText("嘉門達夫","かもんたつお")}</td>
          <td>
            <a href="http://j-lyric.net/artist/a00078f/l0113de.html">SHOW TIME 1 </a>
          </td>
        </tr>
        <tr />
        <tr>
          <td>
            <a href="http://j-lyric.net/artist/a00078f/l0113d7.html">こ の{addRubyText("中","なか")}にひとり</a>
          </td>
        </tr>
        <tr>
          <td>
            <a href="http://j-lyric.net/artist/a00078f/l0113cc.html">Mr.{addRubyText("説教","せっきょう")}MANやっちゅうてんねん！2 </a>
          </td>
        </tr>
        <tr>
          <td>
            <a href="http://j-lyric.net/artist/a00078f/l0113fa.html">ハ ンバーガーショップ </a>
          </td>
        </tr>
        <tr>
          <td valign="top">
            {addRubyText("笠置","かさぎ")}シヅ{addRubyText("子","こ")}
          </td>
          <td valign="top">
            <a href="http://j-lyric.net/artist/a001af0/l016d76.html">{addRubyText("買","か")}い{addRubyText("物","もの")}ブギ</a>
          </td>
        </tr>
        <tr>
          <td valign="top">
            {addRubyText("河島英五","かわしまえいご")}
          </td>
          <td valign="top">
            <a href="http://www.kasi-time.com/item-14118.html">
              {addRubyText("生","う")}まれる
              {addRubyText("前","まえ")}から
              {addRubyText("好","す")}きやった
            </a>
          </td>
        </tr>
        <tr>
          <td colSpan="1" rowSpan="3">{addRubyText("関","かん")}ジャニ８ (Eight)</td>
          <td>
            <a href="http://www.utamap.com/showkasi.php?surl=A01285">{addRubyText("好","す")} きやねん、大阪</a>
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            <a href="http://j-lyric.net/artist/a04ab16/l009006.html">∞SAKA おばちゃん ROCK</a>
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            <a href="http://j-lyric.net/artist/a04ab16/l00a43f.html">F・T・ O</a>
          </td>
        </tr>
        <tr>
          <td colSpan="1" rowSpan="2">KinKi Kids</td>
          <td>
            <a href="http://j-lyric.net/artist/a0015e5/l00f2ae.html">た よりにしてまっせ</a>
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            <a href="http://j-lyric.net/artist/a0015e5/l00f2af.html">ボーダー ライン</a>
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>グレート・チキン・パワーズ</td>
          <td style={{verticalAlign: "top"}}>
            <a href="http://j-lyric.net/artist/a04abb3/l0036a8.html">んなこと あるか</a>
          </td>
        </tr>
        <tr>
          <td>{addRubyText("桑名正博","くわなまさひろ")}＆ファニー・カンパニー</td>
          <td>
            <a href="http://j-lyric.net/artist/a003c68/l02abe1.html">ス ウィートホーム大阪</a>
          </td>
        </tr>
        <tr>
          <td>{addRubyText("桂銀淑","ケイウンスク")}</td>
          <td>
            <a href="https://mojim.com/twy112469x4x1.htm">大 阪{addRubyText("暮色","ぼしょく")}</a>
          </td>
        </tr>
        <tr>
          <td>ゲイシャ・ガールズ</td>
          <td>
            <a href="http://petitlyrics.com/lyrics/17470">キック＆ラウド</a>
          </td>
        </tr>
        <tr>
          <td valign="top">
            ケラケラ
          </td>
          <td valign="top">
            <a href="http://j-lyric.net/artist/a05751d/l02eb63.html">たこ
              {addRubyText("焼","や")}きソング〜大阪で
              {addRubyText("生","う")}まれたからって〜
            </a>
          </td>
        </tr>
        <tr>
          <td valign="top">
            Kenty Gross
          </td>
          <td valign="top">
            <a href="http://petitlyrics.com/lyrics/1155736">浪花乃ラガ男”G" (ナニワノラガダンジ)</a>
          </td>
        </tr>
        <tr>
          <td colSpan="1" rowSpan="2">
            {addRubyText("倖田來未","こうだくみ")}
          </td>
          <td>
            <a href="http://j-lyric.net/artist/a0006b2/l008fe1.html">
              {addRubyText("恋","こい")}のつぼみ
            </a>
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            <a href="http://j-lyric.net/artist/a0006b2/l008d2e.html">LOVE HOLIC</a>
          </td>
        </tr>
        <tr>
          <td colSpan="1" rowSpan="8">
            さ
          </td>
          <td>SAKURA</td>
          <td>
            <a href="http://www.utamap.com/showkasi.php?surl=B02154">LOVE 4 REAL</a>
          </td>
        </tr>
        <tr>
          <td>
            {addRubyText("桜庭雄一郎","さくらばゆういちろう")}／{addRubyText("長瀬智也","ながせともや")}
          </td>
          <td>
            <a href="http://j-lyric.net/artist/a001081/l001e6b.html">
              お{addRubyText("前","まえ")}やないとあかんねん
            </a>
          </td>
        </tr>
        <tr>
          <td valign="top">
            "SHINGO★西成, GAZZILA & BIG BEAR"
          </td>
          <td valign="top">
            <a href="https://www.kkbox.com/hk/tc/song/84jZT0s5GHm2HY582HY580PL-index.html">
              {addRubyText("正念場","しょうねんば")} TRIBUTE (Original)
            </a>
          </td>
        </tr>
        <tr>
          <td valign="top">
            スマイレージ
          </td>
          <td valign="top">
            <a href="http://j-lyric.net/artist/a052bda/l021c49.html">○○ がんばらなくてもええねんで</a>
          </td>
        </tr>
        <tr>
          <td>
            SMAP
          </td>
          <td>
            <a href="http://www.utamap.com/showkasi.php?surl=39775">
              Hey Hey おおきに{addRubyText("毎度","まいど")}あり
            </a>
          </td>
        </tr>
        <tr>
          <td colSpan="1" rowSpan="1">7 HOUSE（セブンハウス / つんく）</td>
          <td colSpan="1" rowSpan="1">
            <a href="http://www.kasi-time.com/item-17807.html">
              なんでやねん{addRubyText("心配","しんぱい")}せんでもええ
            </a>
          </td>
        </tr>
        <tr />
        <tr>
          <td>Soul Powers</td>
          <td>
            <a href="http://j-lyric.net/artist/a04abc3/l003726.html">
              お{addRubyText("好","この")}み{addRubyText("焼","や")}きファンキーソウル
            </a>
          </td>
        </tr>
        <tr>
          <td colSpan="1" rowSpan="4">
            た
          </td>
          <td>トミーズ{addRubyText("雅","まさ")} </td>
          <td>
            <a href="http://www.uta-net.com/movie/43482/">いじめんといてや</a>
          </td>
        </tr>
        <tr>
          <td valign="top">
            ティーナ・カリーナ
          </td>
          <td valign="top">
            <a href="http://www.uta-net.com/song/133851/">あんた</a>
          </td>
        </tr>
        <tr>
          <td valign="top">10-FEET</td>
          <td valign="top">
            <a href="http://j-lyric.net/artist/a0026ab/l011025.html">
              VIBES BY VIBES
            </a>
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            DREAMS COME TRUE
          </td>
          <td style={{verticalAlign: "top"}}>
            <a href="http://www.uta-net.com/movie/52084/">大阪LOVER</a>
          </td>
        </tr>
        <tr>
          <td valign="top">
            な
          </td>
          <td valign="top">
            {addRubyText("長渕剛","ながぶちつよし")}
          </td>
          <td valign="top">
            <a href="http://j-lyric.net/artist/a000675/l00f6d2.html">
              ほんまに うち{addRubyText("寂","さび")}しかったんよ
            </a>
          </td>
        </tr>
        <tr>
          <td colSpan="1" rowSpan="11">
            は
          </td>
          <td>{addRubyText("萩原健一","はぎわらけんいち")}／BORO </td>
          <td>
            <a href="http://www.utamap.com/showkasi.php?surl=S00530">
              大 阪で{addRubyText("生","う")}まれた{addRubyText("女","おんな")}
            </a>
          </td>
        </tr>
        <tr>
          <td>はなわ</td>
          <td>
            <a href="http://j-lyric.net/artist/a000737/l0021e3.html">大 阪{addRubyText("府","ふ")}</a>
          </td>
        </tr>
        <tr>
          <td>パフィー</td>
          <td>
            <a href="http://j-lyric.net/artist/a00079f/l004c00.html">な んなりとなるでしょう</a>
          </td>
        </tr>
        <tr>
          <td>{addRubyText("浜田雅功","はまだまさよし")}</td>
          <td>
            <a href="https://www.joysound.com/web/search/song/210050">えっ！ さよなら</a>
          </td>
        </tr>
        <tr>
          <td valign="top">
            BEGIN with アホナスターズ
          </td>
          <td valign="top">
            <a href="http://www.utamap.com/showkasi.php?surl=A03594">
              {addRubyText("笑顔","えがお")}のまんま
            </a>
          </td>
        </tr>
        <tr>
          <td>B’z</td>
          <td>
            <a href="http://www.utamap.com/showkasi.php?surl=56377">もう かりまっ か </a>
          </td>
        </tr>
        <tr>
          <td valign="top">V6 (ComingCentury)</td>
          <td valign="top">
            <a href="http://j-lyric.net/artist/a04cf3d/l00b60a.html">スキさ すっきゃねん</a>
          </td>
        </tr>
        <tr>
          <td>
            ブギーマン (BOOGIEMAN)
          </td>
          <td>
            <a href="http://j-lyric.net/artist/a0026c2/l01aed4.html">I don’t wanna be your friend</a>
          </td>
        </tr>
        <tr>
          <td>THE BOOM</td>
          <td>
            <a href="http://j-lyric.net/artist/a001133/l013dd3.html">
              大 阪でもまれた{addRubyText("男","おとこ")}
            </a>
          </td>
        </tr>
        <tr>
          <td valign="top">
            Berryz{addRubyText("工房","こうぼう")}
          </td>
          <td valign="top">
            <a href="http://j-lyric.net/artist/a002e87/l010cc6.html">
              {addRubyText("笑","わら")}っちゃおうよ BOYFRIEND
            </a>
          </td>
        </tr>
        <tr>
          <td>VOICE</td>
          <td>
            <a href="http://j-lyric.net/artist/a00281b/l006e67.html">好 きやねん</a>
          </td>
        </tr>
        <tr>
          <td colSpan="1" rowSpan="8">
            ま
          </td>
          <td>{addRubyText("槇原敬之","まきはらのりゆき")}</td>
          <td>
            <a href="http://j-lyric.net/artist/a00281b/l006e67.html">
              {addRubyText("手","て")}をつないで{addRubyText("帰","かえ")}ろ　
            </a>
          </td>
        </tr>
        <tr>
          <td valign="top">
            {addRubyText("松浦亜弥","まつうらあみ")}
          </td>
          <td valign="top">
            <a href="http://j-lyric.net/artist/a000503/l0273e8.html">ふたり大 阪</a>
          </td>
        </tr>
        <tr>
          <td>
            {addRubyText("三木道三","みきどうざん")}
          </td>
          <td>
            <a href="http://j-lyric.net/artist/a016f7c/l000d96.html">LifeTime Respect</a>
          </td>
        </tr>
        <tr>
          <td>ミス{addRubyText("花子","はなこ")}</td>
          <td>
            <a href="http://j-lyric.net/artist/a021192/l00117d.html">
              {addRubyText("河内","かわち")}のオッサンの{addRubyText("歌","うた")}
            </a>
          </td>
        </tr>
        <tr>
          <td valign="top">
            {addRubyText("都","みやこ")}はるみ・{addRubyText("岡千秋","おかちあき")}
          </td>
          <td valign="top">
            <a href="http://j-lyric.net/artist/a04ac87/l00587f.html">
              {addRubyText("浪速","なにわ")}{addRubyText("恋","こい")}しぐれ
            </a>
          </td>
        </tr>
        <tr>
          <td valign="top">
            MINMI
          </td>
          <td valign="top">
            <a href="http://j-lyric.net/artist/a000720/l00b2">
              {addRubyText("真珠","しんじゅ")}の{addRubyText("涙","なみだ")} feet.KENTY-GROSS
            </a>
          </td>
        </tr>
        <tr>
        </tr>
        <tr>
          <td>モダンチョキチョキズ</td>
          <td><a href="http://www.uta-net.com/movie/190832/">らいか　いのしし</a></td>
        </tr>
        <tr>
          <td valign="top" />
          <td valign="top">モーニング娘</td>
          <td valign="top">
            <a href="http://j-lyric.net/artist/a000626/l0043b1.html">
              大阪{addRubyText("恋","こい")}の{addRubyText("歌","うた")}
            </a>
          </td>
        </tr>
        <tr>
          <td colSpan="1" rowSpan="7">や</td>
          <td colSpan="1" rowSpan="5">やしきたかじん </td>
          <td>
            <a href="http://j-lyric.net/artist/a0017cf/l005d53.html">やっ ぱ好きやねん</a>
          </td>
        </tr>
        <tr>
          <td>
            <a href="http://j-lyric.net/artist/a0017cf/l0058b8.html">な めとんか</a>
          </td>
        </tr>
        <tr>
          <td>
            <a href="http://j-lyric.net/artist/a0017cf/l005be7.html">あ んた</a>
          </td>
        </tr>
        <tr>
          <td>
            <a href="http://j-lyric.net/artist/a0017cf/l006765.html">
              大 阪恋{addRubyText("物語","ものがたり")}
            </a>
          </td>
        </tr>
        <tr>
          <td>
            <a href="http://j-lyric.net/artist/a0017cf/">{addRubyText("東 京","とうきょう")}</a>
          </td>
        </tr>
        <tr>
          <td valign="top">
            {addRubyText("山口百恵","やまぐちももえ")}
          </td>
          <td valign="top">
            <a href="http://j-lyric.net/artist/a0013c2/l006576.html">
              {addRubyText("愛染橋","あいぜんばし")}
            </a>
          </td>
        </tr>
        <tr>
          <td>{addRubyText("憂歌団","ゆうかだん")}</td>
          <td>
            <a href="http://j-lyric.net/artist/a001cfc/l0006cb.html">大 阪ビッグ・リバー・ブルース</a>
          </td>
        </tr>
        <tr>
          <td colSpan="1" rowSpan="2">ら</td>
          <td>Laugh and Peace</td>
          <td>
            <a href="http://www.sonymusic.co.jp/Music/Info/LandP/poem2.html">ちょっ ときいてな</a>
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>Rhymescientist</td>
          <td style={{verticalAlign: "top"}}>
            <a href="http://utaten.com/lyric/Rhymescientist/%E3%81%8A%E3%81%BE%E3%81%88%E3%82%84%E3%81%8B%E3%82%89/">
              おまえやから
            </a>
          </td>
        </tr>
        </tbody>
      </table>
      <br />
      <div>
        <b>＊</b>{addRubyText("子供","こども")}の{addRubyText("為","ため")}の{addRubyText("合唱曲","がっしょうきょく")}（Choral Suite for Children)
        <a href="http://www.mahoroba.ne.jp/%7Egonbe007/hog/shouka/chikotan.html">「チコタン」</a>
      </div>

      <h3>EXTRA: Songs about Kansai</h3>
      <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{textAlign: "center"}}>
            Artist
          </td>
          <td style={{textAlign: "center"}}>
            Song title
          </td>
        </tr>
        <tr>
          <td>
            シャ{addRubyText("乱","らん")}Q
          </td>
          <td>
            <a href="http://j-lyric.net/artist/a002617/l007335.html">大 阪エレジー</a>
          </td>
        </tr>
        <tr>
          <td>THE５０{addRubyText("回転","かいてん")}ズ</td>
          <td>
            <a href="http://www.uta-net.com/song/36863/">{addRubyText("天王寺","てんのうじ")}エレジー</a>
          </td>
        </tr>
        <tr>
          <td>
            SUPER BELL"Z
          </td>
          <td>
            <a href="http://petitlyrics.com/lyrics/226812">
              MOTOR MAN 大阪{addRubyText("環状線","かんじょうせん")}
            </a>
          </td>
        </tr>
        <tr>
          <td>{addRubyText("一発逆転","いっぱつぎゃくてん")}</td>
          <td>
            <a href="http://akakage886.blog50.fc2.com/blog-category-35.html">キ ンキのおまけ</a> (about Wakayama prefecture)
          </td>
        </tr>
        <tr>
          <td>ウインズ</td>
          <td>
            <a href="http://winds-wakayama.com/songs/h_17.html">{addRubyText("近畿","きんき")}のオマケ 2006</a> (about Wakayama prefecture)
          </td>
        </tr>
        </tbody>
      </table>
    </>
  );
};

export default JPop;