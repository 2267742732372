import React from "react";
import { addRubyText, addImage, addAudio } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { DraggableForm } from "common/util/draggableForm";

const L3 = () => {
  const transcriptLeft = <>
    <small>あさひ{addRubyText("寮","りょう")}ではねえ、タバコは、寮の{addRubyText("庭","にわ")}で{addRubyText("吸","す")}うのはかましま
    せんけど、{addRubyText("自分","じぶん")}の{addRubyText("部屋","へや")}ん{addRubyText("中","なか")}で吸うたらあきまへんで。ほて、ゴミは、自分の部屋の{addRubyText("前","まえ")}
    に{addRubyText("出","だ")}さんといてください。{addRubyText("台所","だいどころ")}の{addRubyText("横","よこ")}に{addRubyText("置","お")}いといてくれたら、
    ゴミの{addRubyText("日","ひ")}に出しまっさかい。ほれから、おふろはあらしませんけど、シャワーは{addRubyText("晩","ばん")}
    やったらゆっくり{addRubyText("使","つこ")}てもろてもええでっさかいな。{addRubyText("朝","あさ")}はあきまへんで！</small>
  </>;

  const transcriptRight = <><small>ここ、ふじみ{addRubyText("寮","りょう")}ではお{addRubyText("風呂","ふろ")}は{addRubyText("夜","よる")}は{addRubyText("遅","おそ")}う{addRubyText("入","はい")}ってもかましまへんけど、{addRubyText("朝","あさ")}入ったらあきまへんから、{addRubyText("気","き")}ィつけて下さい。あ、シャワーはあらしませんしな。たばこは、寮の{addRubyText("中","なか")}でも{addRubyText("外","そと")}でも{addRubyText("絶対","ぜったい")}{addRubyText("吸","す")}わんとい
  てくださいよ。ゴミは外のゴミ{addRubyText("置","お")}き{addRubyText("場","ば")}にしか{addRubyText("出","だ")}したあきまへんで。ほんで、{addRubyText("毎日","まいにち")}ここ{addRubyText("帰","かえ")}ってきはるのは、夜
  遅ならんとって{addRubyText("下","くだ")}さいよ。{addRubyText("表","おもて")}の{addRubyText("入","い")}り{addRubyText("口","ぐち")}は１１{addRubyText("時","じ")}には{addRubyText("閉","め")}ますさかい。よろしいな？</small></>;

  const draggableFormData1 = {
    prompts: {
      texts: [
        <>あさひ寮 OK</>,
        <>あさひ寮 Not OK</>,
        <>ふじみ寮 OK</>,
        <>ふじみ寮 Not OK</>
      ]
    },
    answers: {
      labels: [
        <>smoking in the yard</>,
        <>leaving your garbage next to the kitchen</>,
        <>taking a shower in the evening</>,

        <>smoking in your room</>,
        <>leaving your garbage in front of your room</>,
        <>taking a shower in the morning</>,

        <>putting your garbage on the dumping ground</>,
        <>taking a bath late at night</>,

        <>smoking</>,
        <>taking a bath in the morning</>,
        <>returning to the dorm after 11p.m.</>
      ]
    }, 
    matchGraph: [
      new Set([0,1,2]),
      new Set([3,4,5]),
      new Set([6,7]),
      new Set([8,9,10])
    ]
  }

  const draggableFormData2 = {
    prompts: {
      texts: [
        <>あさひ寮</>,
        <>ふじみ寮</>,
        <>Neither</>
      ]
    },
    answers: {
      labels: [
        <>タカシ</>,
        <>マモル</>,
        <>ヒロシ</>,
        <>トオル</>
      ],
      texts: [
        <><small>{addRubyText("俺","おれ")}、たばこは{addRubyText("絶対","ぜったい")}
          やめられんけど、{addRubyText("庭","にわ")}かどっかで{addRubyText("吸","す")}えたら、そんでええわ。</small></>,
        <><small>おれは{addRubyText("毎晩","まいばん")}ゆっくり{addRubyText("風呂","ふろ")}{addRubyText("入","はい")}りたい
          わ。{addRubyText("風呂","ふろ")}{addRubyText("要","い")}らんやつなんか、{addRubyText("日本人","にほんじん")}ちゃうで！</small></>,
        <><small>{addRubyText("僕","ぼく")}はヘビースモーカーやから{addRubyText("部屋","へや")}で{addRubyText("吸","す")}った
          あかんとこは{addRubyText("困","こま")}るわ。</small></>,
        <><small>おれは、{addRubyText("朝","あさ")}シャワー{addRubyText("使","つか")}いたいねん。{addRubyText("風呂","ふろ")}
          は{addRubyText("別","べつ")}に{addRubyText("要","い")}らんから。</small></>
      ],
      audioFiles: [
        "4.1.pl0.3.4.mp3",
        "4.1.pl0.3.6.mp3",
        "4.1.pl0.3.3.mp3",
        "4.1.pl0.3.5.mp3"
      ],
      images: [
        "4.1.pl0.3.4.jpg",
        "4.1.pl0.3.6.jpg",
        "4.1.pl0.3.3.jpg",
        "4.1.pl0.3.5.jpg"
      ]
    },
    matchGraph: [
      new Set([0]),
      new Set([1]),
      new Set([2,3])
    ]
  }

  return <>
    <h2>{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}3</h2>
    <span style={{fontWeight: "bold"}}>A. </span>Two dormitory managers are
    talking about the rules at their
    dormitories; あさひ{addRubyText("寮","りょう")} and ふじみ{addRubyText("寮","りょう")}. Listen to what they say, and
    drag each activity on the right to either the “OK” or “not OK” box of
    each dormitory. If you get the right answer, you will hear "Right!" and
    the illustration will stick to the box. If it is not the right answer,
    the activity will bounce back to the original location. <br />
    <small>*Click "show" if you need to refer to the transcript. <br />
    *Mouse over or click on the speaker icon for sound<br />
    <br />
    </small>
    <table style={{textAlign: "left", width: "100%"}} border="1"cellPadding="2"
    cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>あさひ{addRubyText("寮","りょう")}{addImage("4.1.pl0.3.1.jpg")}</td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>ふじみ{addRubyText("寮","りょう")}{addImage("4.1.pl0.3.2.jpg")}</td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addAudio("4.1.pl0.3.1.mp3")}
            <Hideable content={transcriptLeft}/>
          </td>
          <td style={{verticalAlign: "top"}}>
            {addAudio("4.1.pl0.3.2.mp3")}
            <Hideable content={transcriptRight}/>
            <br />
          </td>
        </tr>
      </tbody>
    </table>
    <DraggableForm prompts={draggableFormData1.prompts} answers={draggableFormData1.answers} matchGraph={draggableFormData1.matchGraph}/>
    <hr />
    <br />
    <span style={{fontWeight: "bold"}}>B.</span> Here four college students
    are talking about their future dorm.&nbsp; Listen to their
    comments and choose which dorm is more appropriate for each student. If
    a comment does not match either, drag the illustration to "Neither". <br />
    <DraggableForm prompts={draggableFormData2.prompts} answers={draggableFormData2.answers} matchGraph={draggableFormData2.matchGraph}/>
  </>;
}

export default L3;
