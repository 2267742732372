import React, { Component } from "react";
import { connect } from 'react-redux';
import { Header } from "semantic-ui-react";
import { Constants } from '../../constants';

class InterviewQuestionsClass extends Component {

  renderInterviewQuestionCount() {
    if (this.props.isCountLoading) {
      return 'Loading...';
    } else {
      return this.props.count;
    }
  }

  renderInterviewQuestionList() {
    if (this.props.isListLoading) {
      return (
        <tr>
          <td>
            <span>
              Loading...
            </span>
          </td>
        </tr>
      )
    } else {
      return this.props.list.map((interviewQuestion, index) => {
        return (
          <tr key={index}>
            <td>
              <span>
                <a href="https://www.google.com">{interviewQuestion}</a>
              </span>
            </td>
          </tr>
        );
      });
    }
  }

  render() {
    return (
      <div>
        <Header as='h2'>
          Interview Questions
        </Header>
        <h3>
          INTERVIEW QUESTIONS : CLIPS BY QUESTION
        </h3>
        <hr/>
        <ul>
          <li>
            Number of questions: {this.renderInterviewQuestionCount()}
          </li>
        </ul>
        <div>
          <table cellSpacing="3" cellPadding="5" border="1">
            <tbody>
              <tr>
                <th>
                  Interview Questions
                </th>
              </tr>
              {this.renderInterviewQuestionList()}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return state.interviewQuestions;
}

function mapDispatchToProps(dispatch) {
  return {
    fetchInterviewQuestionCount: () => dispatch({ type: Constants.FETCH_INTERVIEW_QUESTION_COUNT }),
    fetchInterviewQuestionList: () => dispatch({ type: Constants.FETCH_INTERVIEW_QUESTION_LIST })
  };
}

const InterviewQuestions = connect(mapStateToProps, mapDispatchToProps)(InterviewQuestionsClass);

export default InterviewQuestions;