import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";

const V13 = () => {
  const transcript = <>
    ま、{addRubyText("相手","あいて")}、やっぱ{addRubyText("言葉","ことば")}って、この、{addRubyText("会話","かいわ")}って{addRubyText("言","い")}うのは、相手によりけりだと{addRubyText("思","おも")}うんです、そのときの{addRubyText("話","はな")}し{addRubyText("方","かた")}っていうのは。、、{addRubyText("一番","いちばん")}うちの{addRubyText("得意","とくい")}の{addRubyText("先生","せんせい")}が，あの、
    お{addRubyText("客","きゃく")}さんで，あの先生が、{addRubyText("立派","りっぱ")}な先生がおられるんですけどね、{addRubyText("書道","しょどう")}の。そらやっぱり{addRubyText("行","い")}ったらちゃんと{addRubyText("正座","せいざ")}して話もするし、{addRubyText("失礼","しつれい")}のないような話し方<span style={{textDecoration: "underline"}}>せ
    んといかん</span> (Grammar 3) とも思うし。
  </>;

  const qaObjects = [
    {
      question: <span>Q: What is his opinion regarding speech styles?</span>,
      answersCorrect: [
        <>It depends on the addressee. If he speaks with his best patron he should sit uptight and speak in a way that is not rude to the patron.</>
      ],
      answersIncorrect: [
        <>It depends on the addressee. But he thinks speaking too politely with uptight sitting posture would be rather rude to his best patron. </>,
        <>It depends on the speaker, e.g. his best patron always sits uptight and speaks in a way that is not rude to the addressee.</>,
        <>It depends on the speaker, e.g. his best patron speaks to him really rudely, no matter how polite he is, sitting uptight.</>
      ]
    }
  ];

  return <>
    <h2>ビデオ{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}１３: Authentic - medium <br />
    </h2>
    Watch the video clip and choose the answer that matches the content of
    the clip.<br />
    <small>*Click "SHOW" if you need to refer to the transcript.</small><br />
    <br />
    Kusaka-san was talking about speech styles.<br />
    <table style={{textAlign: "left", width: "100%"}} border="0"cellPadding="2"
    cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>{addVideoPlayer("4.2.3.p.5.kusaka.mp4")}</td>
          <td colSpan="1" rowSpan="2" style={{verticalAlign: "top", textAlign: "left"}}>
            Transcript
            <Hideable content={transcript}/><br />
            ＊{addRubyText("相手","あいて")} the other party<br />
            ＊{addRubyText("会話","かいわ")} conversation<br />
            ＊〜けり(classical Japanese) =よ、なあ、ものだ<br />
            ＊{addRubyText("得意","とくい")}＝{addRubyText("得意先","とくいさき")} customer, patron<br />
            ＊{addRubyText("正座","せいざ")} sit uptight (with one’s legs folded under one)<br />
            ＊{addRubyText("書道","しょどう")} calligraphy<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>クサカさん
    (Shiga/Kyoto)</td>
        </tr>
      </tbody>
    </table>
    <br />
    <QandAForm qaObjects={qaObjects}/>
  </>;
}

export default V13;
