import React from "react";
import { Link } from "react-router-dom";
import { Page } from "home/page";
import { addRubyText, addImage } from "common/util/generalUtil";

const Imperatives = () => {
  return <>
    <h2>Lesson 3: {addRubyText("命令","めいれい")} Imperatives </h2>
    <h3>〜はよ{addRubyText("起","お")}きぃな。{addRubyText("会社","かいしゃ")}{addRubyText("遅","おく")}れなや。〜</h3>
    <table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2"
    cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>
          <ul>
            <li>
              <Link to={Page.FunctionalGrammar.Imperatives.Conversations}>
                {addRubyText("基本会話","きほんかいわ")} (Core Conversations)
              </Link>
            </li>
            <li>
              <Link to={Page.FunctionalGrammar.Imperatives.Grammar.Grammar}>
                {addRubyText("文法","ぶんぽう")}ノート (Grammar Note)
              </Link>
            </li>
            <li>
              <Link to={Page.FunctionalGrammar.Imperatives.Practice.Practice}>
                {addRubyText("練 習","れんしゅう")}(Practice)
              </Link>
            </li>
            <li>
              <Link to={Page.FunctionalGrammar.Imperatives.Listening.Listening}>
                {addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}(Listening Practice)
              </Link>
            </li>
            <li>
              <Link to={Page.FunctionalGrammar.Imperatives.Video.Video}>
                ビデオ{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}（Video Comprehension Practice)
              </Link>
            </li>
          </ul>
          </td>
          <td style={{verticalAlign: "top"}}>
          <div style={{textAlign: "center"}}>{addImage("tanabatatanzaku2.jpg")}<br />
          </div>
          <div style={{textAlign: "center"}}><small>七夕の短冊 Star festival decorations (Hyogo)</small><br />
          </div>
          </td>
        </tr>
      </tbody>
    </table>
  </>;
}

export default Imperatives;
