import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";

const V1 = () => {
  const transcript = <>
    {addRubyText("うっせ〜なあっ")}って<span style={{textDecoration: "underline"}}>言わはん</span>(=
    言わはる Grammar 1) の。
  </>;

  const qaObjects = [
    {
      question: <span>Q1: How does Ayumi’s mother scold her?</span>,
      answersCorrect: [
        <>“You are noisy!”</>
      ],
      answersIncorrect: [
        <>“I will throw you out!”</>,
        <>“I will sell you to another family!”</>,
        <>“You are troublesome!”</>
      ]
    },
    {
      question: <span>Q2: For whom did she use はる?</span>,
      answersCorrect: [
        <>Her mother</>
      ],
      answersIncorrect: [
        <>Interviewer</>,
        <>Herself</>
      ]
    }
  ];

  return <>
    <h2>ビデオ{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}1 : Authentic - short<br />
    </h2>
    Watch the video clip and choose the answer that matches the content of
    the clip.
    Submit your answers after answering all the questions.
    <br />
    <small>*Click "SHOW" if you need to refer to the transcript.
    </small><br />
    <br />
    Context: Ayumi was asked how her mother
    usually scolded her.<br />
    <table style={{textAlign: "left", width: "100%"}} border="0"cellPadding="2"
    cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>{addVideoPlayer("4.4.1.p.1.ayumi.mp4")}</td>
          <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>Transcript
    <Hideable content={transcript}/></td>
        </tr>
        <tr align="center">
          <td style={{verticalAlign: "top", textAlign: "left"}}>アユミ (Shiga) </td>
        </tr>
      </tbody>
    </table>
    <QandAForm qaObjects={qaObjects}/>
  </>;
}

export default V1;
