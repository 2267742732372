import React from "react";
import { addRubyText, addAudio } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";

const P1 = () => {
  const standardTranscript = <>
     <ol className="transcript">
      <li>A: そのかばん、高いの？</li>
      <li>B: このかばん？ううん、高くない、高くない。</li>
    </ol>
  </>;

  return <>
    <h2>{addRubyText("練習","れんしゅう")}1 : Adjectives (Non-past negative)</h2>
    Form a question and an answer for each cue according to
    a model. You may listen to the question, tryto answer it, and check
    your answer. *Answers vary; the recorded answers indicate those
    commonly
    used.<br />
    <small>*Mouse over or click on the speaker icon for sound</small><br />
    <br />
    <span style={{fontWeight: "bold"}}>Model</span> (Cue: そのかばん／{addRubyText("高","たか")}い)<br />
    &nbsp;&nbsp;&nbsp; A: そのかばん、高いん？<br />
    &nbsp;&nbsp;&nbsp; B: このかばん？ううん、たこないたこない。{addAudio("3.4.p.1.mp3")}<br />
    <br />
    Point: no need to add く in Adj negatives. If the Adj ends with -A-い, a
    sound change may occur. (refer to Grammar 2)<br />
    <br />
    ST transcript 
    <Hideable content={standardTranscript} />
    <br />
    <br />
    <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>Cue<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>A (Question)<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>B (Answer)<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>そのかばん／{addRubyText("高","たか")}い<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.4.p.1.1.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.4.p.1.1.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>{addRubyText("足","あし")}／{addRubyText("速","はや")}い</td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.4.p.1.2.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.4.p.1.2.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>その{addRubyText("本","ほん")}／{addRubyText("面白","おもしろ")}い</td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.4.p.1.3.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.4.p.1.3.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>{addRubyText("写真","しゃしん")}／{addRubyText("小","ちい")}さい</td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.4.p.1.4.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.4.p.1.4.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>{addRubyText("彼","かれ")}／{addRubyText("目","め")}／{addRubyText("大","おお")}きい</td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.4.p.1.5.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.4.p.1.5.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>あのレストラン／サービス／いい</td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.4.p.1.6.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.4.p.1.6.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>そのケータイ／{addRubyText("使","つか")}いやすい</td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.4.p.1.7.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.4.p.1.7.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>{addRubyText("田中","たなか")}さんの{addRubyText("家","うち")}／ごっつい（＝すごく{addRubyText("大","おお")}きい）<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.4.p.1.8.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.4.p.1.8.b.mp3")}<br />
          </td>
        </tr>
      </tbody>
    </table>
  </>;
}

export default P1;
