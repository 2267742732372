import React from "react";
import { addRubyText, addImage } from "common/util/generalUtil";
import { Link } from "react-router-dom";
import { Page } from "home/page";

const Grammar = () => {
  return <>
    <h2>{addRubyText("文法","ぶんぽう")}ノート (Grammar Note)</h2>
    <span style={{fontWeight: "bold"}}>
      &lt;Summary of Adjective Conjugation&gt;
      <br/>
    </span>
    <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>
            <br/>
          </td>
          <td style={{verticalAlign: "top"}}>
            Standard<br/>
          </td>
          <td style={{verticalAlign: "top"}}>
            Kansai<br/>
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            Non-past affirmative<br/>
          </td>
          <td style={{verticalAlign: "top"}}>
            (stem)+い<br/>
          </td>
          <td style={{verticalAlign: "top"}}>
            (stem)+い<br/>
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            Past negative<br/>
          </td>
          <td style={{verticalAlign: "top"}}>
            (stem)+かった<br/>
          </td>
          <td style={{verticalAlign: "top"}}>
            (stem)+かった<br/>
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            Non-past negative<br/>
          </td>
          <td style={{verticalAlign: "top"}}>
            (stem)+くない<br/>
          </td>
          <td style={{verticalAlign: "top"}}>
            (stem/adverbial)+ない／あらへん<br/>
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            Past negative<br/>
          </td>
          <td style={{verticalAlign: "top"}}>
            (stem)+くなかった<br/>
          </td>
          <td style={{verticalAlign: "top"}}>
            (stem/adverbial)+なかった／あらへんかった<br/>
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            TE-form<br/>
          </td>
          <td style={{verticalAlign: "top"}}>
            (stem)+くて<br/>
          </td>
          <td style={{verticalAlign: "top"}}>
            (stem/adverbial)+て<br/>
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            Past TE-form (Kansai only)<br/>
          </td>
          <td style={{verticalAlign: "top"}}>
            N/A<br/>
          </td>
          <td style={{verticalAlign: "top"}}>
            (stem)+かって<br/>
          </td>
        </tr>
      </tbody>
    </table>
    <br/>
    <hr/>
    <table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>
          <ol>
            <li>
              <Link to={Page.BasicGrammar.Adjectives.Grammar.KansaiAdjectives}>
                Kansai Adjectives
              </Link> 
            </li>
            <li>
              <Link to={Page.BasicGrammar.Adjectives.Grammar.Negative}>
                Negative Form of Adjectives
              </Link>
            </li>
            <li>
              <Link to={Page.BasicGrammar.Adjectives.Grammar.Adverbs}>
                Adverbial form of Adjectives
              </Link>
            </li>
            <li>
              <Link to={Page.BasicGrammar.Adjectives.Grammar.TeForm}>
                て form of Adjectives
              </Link>
            </li>
          </ol>
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addImage("Kamogawa2.jpg")}
            <br/>
            <small>
              鴨川 Kamo River (Kyoto)
            </small>
            <br/>
          </td>
        </tr>
      </tbody>
    </table>
  </>;
}

export default Grammar;