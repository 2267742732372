import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";

const V9 = () => {
  const transcript = <>
    ほんで、{addRubyText("今","いま")}みたい、その、{addRubyText("人","ひと")}が{addRubyText("殺","ころ")}されたりすっ{addRubyText("時","とき")}は、{addRubyText("送","おく")}り{addRubyText("迎","むか")}い<span
    style={{textDecoration: "underline"}}>せんならん</span>
    (Grammar 3)しね。{addRubyText("僕","ぼく")}らでも送り迎いしとるんですわ。その、その、{addRubyText("自分","じぶん")}の{addRubyText("子供","こども")}やのうてもね、{addRubyText("登校","とうこう")}んときに
    {addRubyText("当番","とうばん")}{addRubyText("決","き")}めて。まあ，そんな{addRubyText("時代","じだい")}ですんでね。
  </>;

  const qaObjects = [
    {
      question: <span>Q: According to him, what do parents do to protect their children on their way to and from school?</span>,
      answersCorrect: [
        <>Parents take turns to take children to and from school, even if they are not their own.</>
      ],
      answersIncorrect: [
        <>Parents are in charge of taking their own children to and from school.</>,
        <>Parents advise their own children not to follow strangers on their way to and from school.</>,
        <>Parents advise their children to take turns being in charge of taking other children to and from school.</>
      ]
    }
  ];

  return <>
    <h2>ビデオ{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}９: Authentic - short<br />
    </h2>
    Watch the video clip and choose the answer that matches the content of
    the clip.<br />
    <small>*Click "SHOW" if you need to refer to the transcript.</small><br />
    <br />
    Tobita-san is talking about child safety. (*The number of children
    who got kidnapped and murdered on their way home from school had
    increased, and that was a great concern among parents at the time of
    the interview in 2006.) <br />
    <table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>{addVideoPlayer("4.2.4.p.2.tobita.mp4")}</td>
          <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>Transcript
    <Hideable content={transcript}/>
    <br />
    ＊{addRubyText("登校","とうこう")}する go to (attend)
    school<br />
    ＊{addRubyText("当番","とうばん")}on duty, one’s turn in charge</td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>&nbsp;トビタさん
    (Shiga)</td>
        </tr>
      </tbody>
    </table>
    <br />
    <QandAForm qaObjects={qaObjects}/>
  </>;
}

export default V9;
