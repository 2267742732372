import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";

const V4 = () => {
  const transcript = <>
    いや、それは、まあ、そらさっき{addRubyText("言","ゆ")}ったようにね，{addRubyText("関西国","かんさいこく")}なんで，{addRubyText("別","べつ")}にあえて{addRubyText("標準語","ひょうじゅんご")}に<span
    style={{textDecoration: "underline"}}>ならんでも
    ええ</span> (Grammar 1) ような{addRubyText("気","き")}もしますけどね。
  </>;

  const qaObjects = [
    {
      question: <span>Q: What is his opinion? </span>,
      answersCorrect: [
        <>Kansai people do not need to take the trouble to speak Standard Japanese.</>
      ],
      answersIncorrect: [
        <>Kansai people should make an effort to able to speak Standard Japanese.</>,
        <>Kansai people will not be able to speak Standard Japanese.</>
      ]
    }
  ];

  return <>
    <h2>ビデオ{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}４: Authentic - short<br />
    </h2>
    Watch the video clip and choose the answer that matches the content of
    the clip.<br />
    <small>*Click "SHOW" if you need to refer to the transcript<br />
    <br />
    </small>
    Takayama-san is asked if Kansai people
    should be able to speak
    Standard Japanese.<br />
    <table style={{textAlign: "left", width: "100%"}} border="0"cellPadding="2"
    cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>{addVideoPlayer("4.2.1.p.2.takayama.mp4")} </td>
          <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>Transcript
    <Hideable content={transcript}/>
    <br />
    ＊{addRubyText("標準語","ひょうじゅんご")} Standard
    language<br />
    ＊{addRubyText("関西国","かんさいこく")} ‘Kansai Nation’ (He created this word)</td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>タカヤマさん
    (Kyoto)</td>
        </tr>
      </tbody>
    </table>
    <br />
    <QandAForm qaObjects={qaObjects}/>
  </>;
}

export default V4;
