import * as React from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import { Page } from "home/page";
import FirstRun from "../components/firstRun/firstRun";
import ReadFirst from "../components/readFirst/readFirst";

// Characteristics
import Characteristics from "components/characteristics/characteristics";
import HowKansaibenSoundsLike from "components/characteristics/howKansaibenSoundsLike";
import StandardVsKansai from "components/characteristics/standardVsKansai";
// - Linguistic Aspects
import LinguisticAspects from "components/characteristics/linguisticAspects/linguisticAspects";
import Sound from "components/characteristics/linguisticAspects/sound";
import Accent from "components/characteristics/linguisticAspects/accent";
import Words from "components/characteristics/linguisticAspects/words";
import Conjugation from "components/characteristics/linguisticAspects/conjugation";
import Particles from "components/characteristics/linguisticAspects/particles";
import SpeechStyle from "components/characteristics/linguisticAspects/speechStyle";
import Honorifics from "components/characteristics/linguisticAspects/honorifics";

// Expressions
import Expressions from "components/expressions/expressions";
import Greetings from "components/expressions/greetings";
import LeaveTakings from "components/expressions/leaveTakings";
import GratitudeApology from "components/expressions/gratitudeApology";
import OtherExpressions from "components/expressions/otherExpresssions";

// Basic Grammar
import BasicGrammar from "components/basicGrammar/basicGrammar";
// Basic Grammar . Verbs
import Verbs from "components/basicGrammar/verbs/verbs";
import Conversations_3_1a from "components/basicGrammar/verbs/conversations";
import Grammar from "components/basicGrammar/verbs/grammar/grammar";
import NonPastNegative from "components/basicGrammar/verbs/grammar/nonPastNegative";
import PastNegative from "components/basicGrammar/verbs/grammar/pastNegative";
import TaForm from "components/basicGrammar/verbs/grammar/taForm";
import YouNegative from "components/basicGrammar/verbs/grammar/youNegative";
import Practice from "components/basicGrammar/verbs/practice/practice";
import P1 from "components/basicGrammar/verbs/practice/p1";
import P2 from "components/basicGrammar/verbs/practice/p2";
import P3 from "components/basicGrammar/verbs/practice/p3";
import VideoComprehension from "components/basicGrammar/verbs/video/videoComprehension";
import V1 from "components/basicGrammar/verbs/video/v1";
import V2 from "components/basicGrammar/verbs/video/v2";
import V3 from "components/basicGrammar/verbs/video/v3";
import Listening from "components/basicGrammar/verbs/listening/listening";
import L1 from "components/basicGrammar/verbs/listening/l1";
import L2 from "components/basicGrammar/verbs/listening/l2";
import L3 from "components/basicGrammar/verbs/listening/l3";
// Basic Grammar . Sentence Endings
import SentenceEndings from "components/basicGrammar/endings/endings";
import Conversations_3_1b from "components/basicGrammar/endings/conversations";
import Grammar_3_1b from "components/basicGrammar/endings/grammar/grammar";
import SentenceParticles from "components/basicGrammar/endings/grammar/sentenceParticles";
import ExtendedPredicate from "components/basicGrammar/endings/grammar/extendedPredicate";
import Yanka from "components/basicGrammar/endings/grammar/yanka";
import QuotationMarker from "components/basicGrammar/endings/grammar/quotationMarker";
import Practice_3_1b from "components/basicGrammar/endings/practice/practice";
import P1_3_1b from "components/basicGrammar/endings/practice/p1";
import P2_3_1b from "components/basicGrammar/endings/practice/p2";
import P3_3_1b from "components/basicGrammar/endings/practice/p3";
import P4_3_1b from "components/basicGrammar/endings/practice/p4";
import P5_3_1b from "components/basicGrammar/endings/practice/p5";
import P6_3_1b from "components/basicGrammar/endings/practice/p6";
import P7_3_1b from "components/basicGrammar/endings/practice/p7";
import P8_3_1b from "components/basicGrammar/endings/practice/p8";
import Listening_3_1b from "components/basicGrammar/endings/listening/listening";
import L1_3_1b from "components/basicGrammar/endings/listening/l1";
import Video_3_1b from "components/basicGrammar/endings/video/video"; 
import V1_3_1b from "components/basicGrammar/endings/video/v1";
// Basic Grammar . Verb TE Form
import VerbTeForm from "components/basicGrammar/teForm/teForm";
import Conversations_3_2 from "components/basicGrammar/teForm/conversations";
import Grammar_3_2 from "components/basicGrammar/teForm/grammar/grammar";
import TeFormation from "components/basicGrammar/teForm/grammar/teFormation";
import TeContraction from "components/basicGrammar/teForm/grammar/teContraction";
import TeFunctions from "components/basicGrammar/teForm/grammar/teFunctions";
import CasualRequest from "components/basicGrammar/teForm/grammar/casualRequest";
import NegativeRequest from "components/basicGrammar/teForm/grammar/negativeRequest";
import Practice_3_2 from "components/basicGrammar/teForm/practice/practice";
import P1_3_2 from "components/basicGrammar/teForm/practice/p1";
import P2_3_2 from "components/basicGrammar/teForm/practice/p2";
import P3_3_2 from "components/basicGrammar/teForm/practice/p3";
import P4_3_2 from "components/basicGrammar/teForm/practice/p4";
import P5_3_2 from "components/basicGrammar/teForm/practice/p5";
import Listening_3_2 from "components/basicGrammar/teForm/listening/listening";
import L1_3_2 from "components/basicGrammar/teForm/listening/l1";
import L2_3_2 from "components/basicGrammar/teForm/listening/l2";
import Video_3_2 from "components/basicGrammar/teForm/video/video";
import V1_3_2 from "components/basicGrammar/teForm/video/v1";
// Basic Grammar . Copula
import Copula from "components/basicGrammar/copula/copula";
import Conversations_3_3 from "components/basicGrammar/copula/conversations";
import Grammar_3_3 from "components/basicGrammar/copula/grammar/grammar";
import CopulaConjugation from "components/basicGrammar/copula/grammar/conjugation";
import Houkaa from "components/basicGrammar/copula/grammar/houkaa";
import Practice_3_3 from "components/basicGrammar/copula/practice/practice";
import P1_3_3 from "components/basicGrammar/copula/practice/p1";
import P2_3_3 from "components/basicGrammar/copula/practice/p2";
import P3_3_3 from "components/basicGrammar/copula/practice/p3";
import P4_3_3 from "components/basicGrammar/copula/practice/p4";
import P5_3_3 from "components/basicGrammar/copula/practice/p5";
import Listening_3_3 from "components/basicGrammar/copula/listening/listening";
import L1_3_3 from "components/basicGrammar/copula/listening/l1";
// Basic Grammar . Adjectives
import Adjectives from "components/basicGrammar/adjectives/adjectives";
import Conversations_3_4 from "components/basicGrammar/adjectives/conversations";
import Grammar_3_4 from "components/basicGrammar/adjectives/grammar/grammar";
import KansaiAdjectives from "components/basicGrammar/adjectives/grammar/kansaiAdjectives";
import Negative from "components/basicGrammar/adjectives/grammar/negative";
import Adverbs from "components/basicGrammar/adjectives/grammar/adverbs";
import TeForm from "components/basicGrammar/adjectives/grammar/teForm";
import Practice_3_4 from "components/basicGrammar/adjectives/practice/practice";
import P1_3_4 from "components/basicGrammar/adjectives/practice/p1";
import P2_3_4 from "components/basicGrammar/adjectives/practice/p2";
import P3_3_4 from "components/basicGrammar/adjectives/practice/p3";
import P4_3_4 from "components/basicGrammar/adjectives/practice/p4";
import Listening_3_4 from "components/basicGrammar/adjectives/listening/listening";
import L1_3_4 from "components/basicGrammar/adjectives/listening/l1";
import Video_3_4 from "components/basicGrammar/adjectives/video/video";
import V1_3_4 from "components/basicGrammar/adjectives/video/v1";
import V2_3_4 from "components/basicGrammar/adjectives/video/v2";

// Functional Grammar
import FunctionalGrammar from "components/functionalGrammar/functionalGrammar";
// Functional Grammar . Permission & Prohibition
import PermissionProhibition from "components/functionalGrammar/permissionProhibition/permissionProhibition";
import Conversations_4_1 from "components/functionalGrammar/permissionProhibition/conversations";
import Grammar_4_1 from "components/functionalGrammar/permissionProhibition/grammar/grammar";
import Prohibition from "components/functionalGrammar/permissionProhibition/grammar/prohibition";
import Permission from "components/functionalGrammar/permissionProhibition/grammar/permission";
import Practice_4_1 from "components/functionalGrammar/permissionProhibition/practice/practice";
import P1_4_1 from "components/functionalGrammar/permissionProhibition/practice/p1";
import P2_4_1 from "components/functionalGrammar/permissionProhibition/practice/p2";
import P3_4_1 from "components/functionalGrammar/permissionProhibition/practice/p3";
import P4_4_1 from "components/functionalGrammar/permissionProhibition/practice/p4";
import Listening_4_1 from "components/functionalGrammar/permissionProhibition/listening/listening";
import L1_4_1 from "components/functionalGrammar/permissionProhibition/listening/l1";
import L2_4_1 from "components/functionalGrammar/permissionProhibition/listening/l2";
import L3_4_1 from "components/functionalGrammar/permissionProhibition/listening/l3";
import Video_4_1 from "components/functionalGrammar/permissionProhibition/video/video";
import V1_4_1 from "components/functionalGrammar/permissionProhibition/video/v1";
import V2_4_1 from "components/functionalGrammar/permissionProhibition/video/v2";
// Functional Grammar . Negative Permission & Obligation
import NegativePermissionObligation from "components/functionalGrammar/nPermissionObligation/nPermissionObligation";
import Conversations_4_2 from "components/functionalGrammar/nPermissionObligation/conversations";
import Grammar_4_2 from "components/functionalGrammar/nPermissionObligation/grammar/grammar";
import NegativePermission from "components/functionalGrammar/nPermissionObligation/grammar/negativePermission";
import Unless from "components/functionalGrammar/nPermissionObligation/grammar/unless";
import Obligation from "components/functionalGrammar/nPermissionObligation/grammar/obligation";
import Practice_4_2 from "components/functionalGrammar/nPermissionObligation/practice/practice";
import P1_4_2 from "components/functionalGrammar/nPermissionObligation/practice/p1";
import P2_4_2 from "components/functionalGrammar/nPermissionObligation/practice/p2";
import P3_4_2 from "components/functionalGrammar/nPermissionObligation/practice/p3";
import P4_4_2 from "components/functionalGrammar/nPermissionObligation/practice/p4";
import P5_4_2 from "components/functionalGrammar/nPermissionObligation/practice/p5";
import Listening_4_2 from "components/functionalGrammar/nPermissionObligation/listening/listening";
import L1_4_2 from "components/functionalGrammar/nPermissionObligation/listening/l1";
import L2_4_2 from "components/functionalGrammar/nPermissionObligation/listening/l2";
import Video_4_2 from "components/functionalGrammar/nPermissionObligation/video/video";
import V1_4_2 from "components/functionalGrammar/nPermissionObligation/video/v1";
import V2_4_2 from "components/functionalGrammar/nPermissionObligation/video/v2";
import V3_4_2 from "components/functionalGrammar/nPermissionObligation/video/v3";
import V4_4_2 from "components/functionalGrammar/nPermissionObligation/video/v4";
import V5_4_2 from "components/functionalGrammar/nPermissionObligation/video/v5";
import V6_4_2 from "components/functionalGrammar/nPermissionObligation/video/v6";
import V7_4_2 from "components/functionalGrammar/nPermissionObligation/video/v7";
import V8_4_2 from "components/functionalGrammar/nPermissionObligation/video/v8";
import V9_4_2 from "components/functionalGrammar/nPermissionObligation/video/v9";
import V10_4_2 from "components/functionalGrammar/nPermissionObligation/video/v10";
import V11_4_2 from "components/functionalGrammar/nPermissionObligation/video/v11";
import V12_4_2 from "components/functionalGrammar/nPermissionObligation/video/v12";
import V13_4_2 from "components/functionalGrammar/nPermissionObligation/video/v13";
import V14_4_2 from "components/functionalGrammar/nPermissionObligation/video/v14";
import V15_4_2 from "components/functionalGrammar/nPermissionObligation/video/v15";
import V16_4_2 from "components/functionalGrammar/nPermissionObligation/video/v16";
import V17_4_2 from "components/functionalGrammar/nPermissionObligation/video/v17";
import V18_4_2 from "components/functionalGrammar/nPermissionObligation/video/v18";
import V19_4_2 from "components/functionalGrammar/nPermissionObligation/video/v19";
// Functional Grammar . Imperatives
import Imperatives from "components/functionalGrammar/imperatives/imperatives";
import Conversations_4_3 from "components/functionalGrammar/imperatives/conversations";
import Grammar_4_3 from "components/functionalGrammar/imperatives/grammar/grammar";
import Imperatives_4_3 from "components/functionalGrammar/imperatives/grammar/imperatives";
import NegativeImperatives from "components/functionalGrammar/imperatives/grammar/negativeImperatives";
import Practice_4_3 from "components/functionalGrammar/imperatives/practice/practice";
import P1_4_3 from "components/functionalGrammar/imperatives/practice/p1";
import P2_4_3 from "components/functionalGrammar/imperatives/practice/p2";
import P3_4_3 from "components/functionalGrammar/imperatives/practice/p3";
import P4_4_3 from "components/functionalGrammar/imperatives/practice/p4";
import P5_4_3 from "components/functionalGrammar/imperatives/practice/p5";
import Listening_4_3 from "components/functionalGrammar/imperatives/listening/listening";
import L1_4_3 from "components/functionalGrammar/imperatives/listening/l1";
import L2_4_3 from "components/functionalGrammar/imperatives/listening/l2";
import L3_4_3 from "components/functionalGrammar/imperatives/listening/l3";
import L4_4_3 from "components/functionalGrammar/imperatives/listening/l4";
import Video_4_3 from "components/functionalGrammar/imperatives/video/video";
import V1_4_3 from "components/functionalGrammar/imperatives/video/v1";
import V2_4_3 from "components/functionalGrammar/imperatives/video/v2";
import V3_4_3 from "components/functionalGrammar/imperatives/video/v3";
import V4_4_3 from "components/functionalGrammar/imperatives/video/v4";
import V5_4_3 from "components/functionalGrammar/imperatives/video/v5";
import V6_4_3 from "components/functionalGrammar/imperatives/video/v6";
import V7_4_3 from "components/functionalGrammar/imperatives/video/v7";
import V8_4_3 from "components/functionalGrammar/imperatives/video/v8";
// Functional Grammar . Honorifics
import Honorifics_4 from "components/functionalGrammar/honorifics/honorifics";
import Conversations_4_4 from "components/functionalGrammar/honorifics/conversations";
import Grammar_4_4 from "components/functionalGrammar/honorifics/grammar/grammar";
import Honorifics_4_4 from "components/functionalGrammar/honorifics/grammar/honorifics";
import Pejoratives from "components/functionalGrammar/honorifics/grammar/pejoratives";
import ThirdPersonAction from "components/functionalGrammar/honorifics/grammar/thirdPersonAction";
import Video_4_4 from "components/functionalGrammar/honorifics/video/video";
import V1_4_4 from "components/functionalGrammar/honorifics/video/v1";
import V2_4_4 from "components/functionalGrammar/honorifics/video/v2";
import V3_4_4 from "components/functionalGrammar/honorifics/video/v3";
import V4_4_4 from "components/functionalGrammar/honorifics/video/v4";
import V5_4_4 from "components/functionalGrammar/honorifics/video/v5";
import V6_4_4 from "components/functionalGrammar/honorifics/video/v6";
import V7_4_4 from "components/functionalGrammar/honorifics/video/v7";
import V8_4_4 from "components/functionalGrammar/honorifics/video/v8";
import V9_4_4 from "components/functionalGrammar/honorifics/video/v9";
import V10_4_4 from "components/functionalGrammar/honorifics/video/v10";
import V11_4_4 from "components/functionalGrammar/honorifics/video/v11";
import V12_4_4 from "components/functionalGrammar/honorifics/video/v12";
// Functional Grammar . Even
import Even from "components/functionalGrammar/even/even";
import Conversations_4_5 from "components/functionalGrammar/even/conversations";
import Grammar_4_5 from "components/functionalGrammar/even/grammar/grammar";
import Even_4_5 from "components/functionalGrammar/even/grammar/even";
import EvenIf from "components/functionalGrammar/even/grammar/evenIf";
import Video_4_5 from "components/functionalGrammar/even/video/video";
import V1_4_5 from "components/functionalGrammar/even/video/v1";
import V2_4_5 from "components/functionalGrammar/even/video/v2";
// Talk in Town
import TalkInTown from "components/talkInTown/talkInTown";
import FishShop from "components/talkInTown/fishShop";
import VegetableShop from "components/talkInTown/vegetableShop";
import EelShop from "components/talkInTown/eelShop";
import Peddler from "components/talkInTown/peddler";
import PickleStore from "components/talkInTown/pickleStore";
import FruitShop from "components/talkInTown/fruitShop";
import RawOyster from "components/talkInTown/rawOyster";
import GrilledOyster from "components/talkInTown/grilledOyster";
import ConfectionStore from "components/talkInTown/confectionStore";
import BlackbeanTea from "components/talkInTown/blackbeanTea";
import BlackbeanTea2 from "components/talkInTown/blackbeanTea2";
import Strawberry from "components/talkInTown/strawberry";
import RiceCake from "components/talkInTown/riceCake";
import FishShopKyoto from "components/talkInTown/fishShopKyoto";

// Interviews
import Interviews from "components/interviews/interviews";
import Interviewees from "components/interviews/interviewees";
import InterviewQuestions from "components/interviews/interviewQuestions";
import InterviewClips from "components/interviews/interviewClips";

// Other Resources
import OtherResources from "components/otherResources/otherResources";
import AnimeManga from "components/otherResources/animeManga";
import Films from "components/otherResources/films";
import JPop from "components/otherResources/jpop";
import Links from "components/otherResources/links";
import Foreigners from "components/otherResources/foreigners/foreigners";
import LeeInEnglish from "components/otherResources/foreigners/leeEnglish";
import LeeInKansaiben from "components/otherResources/foreigners/leeKansaiben";
import EricInEnglish from "components/otherResources/foreigners/ericEnglish";
import EricInKansaiben from "components/otherResources/foreigners/ericKansaiben";
import LettersFromUsers from "components/otherResources/letters";
import HowToUse from "components/otherResources/howToUse";

// About
import About from "components/about/about";
import AboutThisProject from "components/about/aboutThisProject";
import AboutPeople from "components/about/aboutPeople";
import ContactInformation from "components/about/contactInformation";
import References from "components/about/references";

// Index
import Index from "components/index/index";
import Glossary from "components/index/glossary";
import GrammarIndex from "components/index/grammarIndex";

// Sitemap
import Sitemap from "components/sitemap";

const Main = () => {
  return (
    <Switch>
      <Route path={Page.FirstRun} component={FirstRun}/>
      
      {/* Chapter 1: Characteristics */}
      <Route exact path={Page.Characteristics.Characteristics} component={Characteristics}/>
      <Route exact path={Page.Characteristics.HowKansaibenSoundsLike} component={HowKansaibenSoundsLike}/>
      <Route exact path={Page.Characteristics.StandardVsKansai} component={StandardVsKansai}/>
      <Route exact path={Page.Characteristics.LinguisticAspects.LinguisticAspects} component={LinguisticAspects}/>
      <Route exact path={Page.Characteristics.LinguisticAspects.Sound} component={Sound}/>
      <Route exact path={Page.Characteristics.LinguisticAspects.Accent} component={Accent}/>
      <Route exact path={Page.Characteristics.LinguisticAspects.Words} component={Words}/>
      <Route exact path={Page.Characteristics.LinguisticAspects.Conjugation} component={Conjugation}/>
      <Route exact path={Page.Characteristics.LinguisticAspects.Particles} component={Particles}/>
      <Route exact path={Page.Characteristics.LinguisticAspects.SpeechStyle} component={SpeechStyle}/>
      <Route exact path={Page.Characteristics.LinguisticAspects.Honorifics} component={Honorifics}/>

      {/* Chapter 2: Expressions */}
      <Route exact path={Page.Expressions.Expressions} component={Expressions}/>
      <Route exact path={Page.Expressions.Greetings} component={Greetings}/>
      <Route exact path={Page.Expressions.LeaveTakings} component={LeaveTakings}/>
      <Route exact path={Page.Expressions.GratitudeApology} component={GratitudeApology}/>
      <Route exact path={Page.Expressions.OtherExpressions} component={OtherExpressions}/>

      {/* Chapter 3: Basic Grammar */}
      <Route exact path={Page.BasicGrammar.BasicGrammar} component={BasicGrammar}/>
      {/* Chapter 3: Basic Grammar . Verbs */}
      <Route exact path={Page.BasicGrammar.Verbs.Verbs} component={Verbs}/>
      <Route exact path={Page.BasicGrammar.Verbs.Conversations} component={Conversations_3_1a}/>
      <Route exact path={Page.BasicGrammar.Verbs.Grammar.Grammar} component={Grammar}/>
      <Route exact path={Page.BasicGrammar.Verbs.Grammar.NonPastNegative} component={NonPastNegative}/>
      <Route exact path={Page.BasicGrammar.Verbs.Grammar.PastNegative} component={PastNegative}/>
      <Route exact path={Page.BasicGrammar.Verbs.Grammar.TaForm} component={TaForm}/>
      <Route exact path={Page.BasicGrammar.Verbs.Grammar.YouNegative} component={YouNegative}/>
      <Route exact path={Page.BasicGrammar.Verbs.Practice.Practice} component={Practice}/>
      <Route exact path={Page.BasicGrammar.Verbs.Practice.P1} component={P1}/>
      <Route exact path={Page.BasicGrammar.Verbs.Practice.P2} component={P2}/>
      <Route exact path={Page.BasicGrammar.Verbs.Practice.P3} component={P3}/>
      <Route exact path={Page.BasicGrammar.Verbs.Listening.Listening} component={Listening}/>
      <Route exact path={Page.BasicGrammar.Verbs.Listening.L1} component={L1}/>
      <Route exact path={Page.BasicGrammar.Verbs.Listening.L2} component={L2}/>
      <Route exact path={Page.BasicGrammar.Verbs.Listening.L3} component={L3}/>
      <Route exact path={Page.BasicGrammar.Verbs.Video.Video} component={VideoComprehension}/>
      <Route exact path={Page.BasicGrammar.Verbs.Video.V1} component={V1}/>
      <Route exact path={Page.BasicGrammar.Verbs.Video.V2} component={V2}/>
      <Route exact path={Page.BasicGrammar.Verbs.Video.V3} component={V3}/>
      {/* Chapter 3: Basic Grammar . Sentence Endings */}
      <Route exact path={Page.BasicGrammar.SentenceEndings.SentenceEndings} component={SentenceEndings}/>
      <Route exact path={Page.BasicGrammar.SentenceEndings.Conversations} component={Conversations_3_1b}/>
      <Route exact path={Page.BasicGrammar.SentenceEndings.Grammar.Grammar} component={Grammar_3_1b}/>
      <Route exact path={Page.BasicGrammar.SentenceEndings.Grammar.SentenceParticles} component={SentenceParticles}/>
      <Route exact path={Page.BasicGrammar.SentenceEndings.Grammar.ExtendedPredicate} component={ExtendedPredicate}/>
      <Route exact path={Page.BasicGrammar.SentenceEndings.Grammar.Yanka} component={Yanka}/>
      <Route exact path={Page.BasicGrammar.SentenceEndings.Grammar.QuotationMarker} component={QuotationMarker}/>
      <Route exact path={Page.BasicGrammar.SentenceEndings.Practice.Practice} component={Practice_3_1b}/>
      <Route exact path={Page.BasicGrammar.SentenceEndings.Practice.P1} component={P1_3_1b}/>
      <Route exact path={Page.BasicGrammar.SentenceEndings.Practice.P2} component={P2_3_1b}/>
      <Route exact path={Page.BasicGrammar.SentenceEndings.Practice.P3} component={P3_3_1b}/>
      <Route exact path={Page.BasicGrammar.SentenceEndings.Practice.P4} component={P4_3_1b}/>
      <Route exact path={Page.BasicGrammar.SentenceEndings.Practice.P5} component={P5_3_1b}/>
      <Route exact path={Page.BasicGrammar.SentenceEndings.Practice.P6} component={P6_3_1b}/>
      <Route exact path={Page.BasicGrammar.SentenceEndings.Practice.P7} component={P7_3_1b}/>
      <Route exact path={Page.BasicGrammar.SentenceEndings.Practice.P8} component={P8_3_1b}/>
      <Route exact path={Page.BasicGrammar.SentenceEndings.Listening.Listening} component={Listening_3_1b}/>
      <Route exact path={Page.BasicGrammar.SentenceEndings.Listening.L1} component={L1_3_1b}/>
      <Route exact path={Page.BasicGrammar.SentenceEndings.Video.Video} component={Video_3_1b}/>
      <Route exact path={Page.BasicGrammar.SentenceEndings.Video.V1} component={V1_3_1b}/>
      {/* Chapter 3: Basic Grammar . Verb TE-Form */}
      <Route exact path={Page.BasicGrammar.VerbTeForm.VerbTeForm} component={VerbTeForm}/>
      <Route exact path={Page.BasicGrammar.VerbTeForm.Conversations} component={Conversations_3_2}/>
      <Route exact path={Page.BasicGrammar.VerbTeForm.Grammar.Grammar} component={Grammar_3_2}/>
      <Route exact path={Page.BasicGrammar.VerbTeForm.Grammar.TeFormation} component={TeFormation}/>
      <Route exact path={Page.BasicGrammar.VerbTeForm.Grammar.TeContraction} component={TeContraction}/>
      <Route exact path={Page.BasicGrammar.VerbTeForm.Grammar.TeFunctions} component={TeFunctions}/>
      <Route exact path={Page.BasicGrammar.VerbTeForm.Grammar.CasualRequest} component={CasualRequest}/>
      <Route exact path={Page.BasicGrammar.VerbTeForm.Grammar.NegativeRequest} component={NegativeRequest}/>
      <Route exact path={Page.BasicGrammar.VerbTeForm.Practice.Practice} component={Practice_3_2}/>
      <Route exact path={Page.BasicGrammar.VerbTeForm.Practice.P1} component={P1_3_2}/>
      <Route exact path={Page.BasicGrammar.VerbTeForm.Practice.P2} component={P2_3_2}/>
      <Route exact path={Page.BasicGrammar.VerbTeForm.Practice.P3} component={P3_3_2}/>
      <Route exact path={Page.BasicGrammar.VerbTeForm.Practice.P4} component={P4_3_2}/>
      <Route exact path={Page.BasicGrammar.VerbTeForm.Practice.P5} component={P5_3_2}/>
      <Route exact path={Page.BasicGrammar.VerbTeForm.Listening.Listening} component={Listening_3_2}/>
      <Route exact path={Page.BasicGrammar.VerbTeForm.Listening.L1} component={L1_3_2}/>
      <Route exact path={Page.BasicGrammar.VerbTeForm.Listening.L2} component={L2_3_2}/>
      <Route exact path={Page.BasicGrammar.VerbTeForm.Video.Video} component={Video_3_2}/>
      <Route exact path={Page.BasicGrammar.VerbTeForm.Video.V1} component={V1_3_2}/>
      {/* Chapter 3: Basic Grammar . Copula */}
      <Route exact path={Page.BasicGrammar.Copula.Copula} component={Copula}/>
      <Route exact path={Page.BasicGrammar.Copula.Conversations} component={Conversations_3_3}/>
      <Route exact path={Page.BasicGrammar.Copula.Grammar.Grammar} component={Grammar_3_3}/>
      <Route exact path={Page.BasicGrammar.Copula.Grammar.Conjugation} component={CopulaConjugation}/>
      <Route exact path={Page.BasicGrammar.Copula.Grammar.Houkaa} component={Houkaa}/>
      <Route exact path={Page.BasicGrammar.Copula.Practice.Practice} component={Practice_3_3}/>
      <Route exact path={Page.BasicGrammar.Copula.Practice.P1} component={P1_3_3}/>
      <Route exact path={Page.BasicGrammar.Copula.Practice.P2} component={P2_3_3}/>
      <Route exact path={Page.BasicGrammar.Copula.Practice.P3} component={P3_3_3}/>
      <Route exact path={Page.BasicGrammar.Copula.Practice.P4} component={P4_3_3}/>
      <Route exact path={Page.BasicGrammar.Copula.Practice.P5} component={P5_3_3}/>
      <Route exact path={Page.BasicGrammar.Copula.Listening.Listening} component={Listening_3_3}/>
      <Route exact path={Page.BasicGrammar.Copula.Listening.L1} component={L1_3_3}/>
      {/* Chapter 3: Basic Grammar . Adjectives */}
      <Route exact path={Page.BasicGrammar.Adjectives.Adjectives} component={Adjectives}/>
      <Route exact path={Page.BasicGrammar.Adjectives.Conversations} component={Conversations_3_4}/>
      <Route exact path={Page.BasicGrammar.Adjectives.Grammar.Grammar} component={Grammar_3_4}/>
      <Route exact path={Page.BasicGrammar.Adjectives.Grammar.KansaiAdjectives} component={KansaiAdjectives}/>
      <Route exact path={Page.BasicGrammar.Adjectives.Grammar.Negative} component={Negative}/>
      <Route exact path={Page.BasicGrammar.Adjectives.Grammar.Adverbs} component={Adverbs}/>
      <Route exact path={Page.BasicGrammar.Adjectives.Grammar.TeForm} component={TeForm}/>
      <Route exact path={Page.BasicGrammar.Adjectives.Practice.Practice} component={Practice_3_4}/>
      <Route exact path={Page.BasicGrammar.Adjectives.Practice.P1} component={P1_3_4}/>
      <Route exact path={Page.BasicGrammar.Adjectives.Practice.P2} component={P2_3_4}/>
      <Route exact path={Page.BasicGrammar.Adjectives.Practice.P3} component={P3_3_4}/>
      <Route exact path={Page.BasicGrammar.Adjectives.Practice.P4} component={P4_3_4}/>
      <Route exact path={Page.BasicGrammar.Adjectives.Listening.Listening} component={Listening_3_4}/>
      <Route exact path={Page.BasicGrammar.Adjectives.Listening.L1} component={L1_3_4}/>
      <Route exact path={Page.BasicGrammar.Adjectives.Video.Video} component={Video_3_4}/>
      <Route exact path={Page.BasicGrammar.Adjectives.Video.V1} component={V1_3_4}/>
      <Route exact path={Page.BasicGrammar.Adjectives.Video.V2} component={V2_3_4}/>

      {/* Chapter 4: Functional Grammar */}
      <Route exact path={Page.FunctionalGrammar.FunctionalGrammar} component={FunctionalGrammar}/>
      {/* Chapter 4: Functional Grammar . Permission and Prohibition */}
      <Route exact path={Page.FunctionalGrammar.PermissionProhibition.PermissionProhibition} component={PermissionProhibition}/>
      <Route exact path={Page.FunctionalGrammar.PermissionProhibition.Conversations} component={Conversations_4_1}/>
      <Route exact path={Page.FunctionalGrammar.PermissionProhibition.Grammar.Grammar} component={Grammar_4_1}/>
      <Route exact path={Page.FunctionalGrammar.PermissionProhibition.Grammar.Prohibition} component={Prohibition}/>
      <Route exact path={Page.FunctionalGrammar.PermissionProhibition.Grammar.Permission} component={Permission}/>
      <Route exact path={Page.FunctionalGrammar.PermissionProhibition.Practice.Practice} component={Practice_4_1}/>
      <Route exact path={Page.FunctionalGrammar.PermissionProhibition.Practice.P1} component={P1_4_1}/>
      <Route exact path={Page.FunctionalGrammar.PermissionProhibition.Practice.P2} component={P2_4_1}/>
      <Route exact path={Page.FunctionalGrammar.PermissionProhibition.Practice.P3} component={P3_4_1}/>
      <Route exact path={Page.FunctionalGrammar.PermissionProhibition.Practice.P4} component={P4_4_1}/>
      <Route exact path={Page.FunctionalGrammar.PermissionProhibition.Listening.Listening} component={Listening_4_1}/>
      <Route exact path={Page.FunctionalGrammar.PermissionProhibition.Listening.L1} component={L1_4_1}/>
      <Route exact path={Page.FunctionalGrammar.PermissionProhibition.Listening.L2} component={L2_4_1}/>
      <Route exact path={Page.FunctionalGrammar.PermissionProhibition.Listening.L3} component={L3_4_1}/>
      <Route exact path={Page.FunctionalGrammar.PermissionProhibition.Video.Video} component={Video_4_1}/>
      <Route exact path={Page.FunctionalGrammar.PermissionProhibition.Video.V1} component={V1_4_1}/>
      <Route exact path={Page.FunctionalGrammar.PermissionProhibition.Video.V2} component={V2_4_1}/>
      {/* Chapter 4: Functional Grammar . Negative Permission and Obligation */}
      <Route exact path={Page.FunctionalGrammar.NegativePermissionObligation.NegativePermissionObligation} component={NegativePermissionObligation}/>
      <Route exact path={Page.FunctionalGrammar.NegativePermissionObligation.Conversations} component={Conversations_4_2}/>
      <Route exact path={Page.FunctionalGrammar.NegativePermissionObligation.Grammar.Grammar} component={Grammar_4_2}/>
      <Route exact path={Page.FunctionalGrammar.NegativePermissionObligation.Grammar.NegativePermission} component={NegativePermission}/>
      <Route exact path={Page.FunctionalGrammar.NegativePermissionObligation.Grammar.Unless} component={Unless}/>
      <Route exact path={Page.FunctionalGrammar.NegativePermissionObligation.Grammar.Obligation} component={Obligation}/>
      <Route exact path={Page.FunctionalGrammar.NegativePermissionObligation.Practice.Practice} component={Practice_4_2}/>
      <Route exact path={Page.FunctionalGrammar.NegativePermissionObligation.Practice.P1} component={P1_4_2}/>
      <Route exact path={Page.FunctionalGrammar.NegativePermissionObligation.Practice.P2} component={P2_4_2}/>
      <Route exact path={Page.FunctionalGrammar.NegativePermissionObligation.Practice.P3} component={P3_4_2}/>
      <Route exact path={Page.FunctionalGrammar.NegativePermissionObligation.Practice.P4} component={P4_4_2}/>
      <Route exact path={Page.FunctionalGrammar.NegativePermissionObligation.Practice.P5} component={P5_4_2}/>
      <Route exact path={Page.FunctionalGrammar.NegativePermissionObligation.Listening.Listening} component={Listening_4_2}/>
      <Route exact path={Page.FunctionalGrammar.NegativePermissionObligation.Listening.L1} component={L1_4_2}/>
      <Route exact path={Page.FunctionalGrammar.NegativePermissionObligation.Listening.L2} component={L2_4_2}/>
      <Route exact path={Page.FunctionalGrammar.NegativePermissionObligation.Video.Video} component={Video_4_2}/>
      <Route exact path={Page.FunctionalGrammar.NegativePermissionObligation.Video.V1} component={V1_4_2}/>
      <Route exact path={Page.FunctionalGrammar.NegativePermissionObligation.Video.V2} component={V2_4_2}/>
      <Route exact path={Page.FunctionalGrammar.NegativePermissionObligation.Video.V3} component={V3_4_2}/>
      <Route exact path={Page.FunctionalGrammar.NegativePermissionObligation.Video.V4} component={V4_4_2}/>
      <Route exact path={Page.FunctionalGrammar.NegativePermissionObligation.Video.V5} component={V5_4_2}/>
      <Route exact path={Page.FunctionalGrammar.NegativePermissionObligation.Video.V6} component={V6_4_2}/>
      <Route exact path={Page.FunctionalGrammar.NegativePermissionObligation.Video.V7} component={V7_4_2}/>
      <Route exact path={Page.FunctionalGrammar.NegativePermissionObligation.Video.V8} component={V8_4_2}/>
      <Route exact path={Page.FunctionalGrammar.NegativePermissionObligation.Video.V9} component={V9_4_2}/>
      <Route exact path={Page.FunctionalGrammar.NegativePermissionObligation.Video.V10} component={V10_4_2}/>
      <Route exact path={Page.FunctionalGrammar.NegativePermissionObligation.Video.V11} component={V11_4_2}/>
      <Route exact path={Page.FunctionalGrammar.NegativePermissionObligation.Video.V12} component={V12_4_2}/>
      <Route exact path={Page.FunctionalGrammar.NegativePermissionObligation.Video.V13} component={V13_4_2}/>
      <Route exact path={Page.FunctionalGrammar.NegativePermissionObligation.Video.V14} component={V14_4_2}/>
      <Route exact path={Page.FunctionalGrammar.NegativePermissionObligation.Video.V15} component={V15_4_2}/>
      <Route exact path={Page.FunctionalGrammar.NegativePermissionObligation.Video.V16} component={V16_4_2}/>
      <Route exact path={Page.FunctionalGrammar.NegativePermissionObligation.Video.V17} component={V17_4_2}/>
      <Route exact path={Page.FunctionalGrammar.NegativePermissionObligation.Video.V18} component={V18_4_2}/>
      <Route exact path={Page.FunctionalGrammar.NegativePermissionObligation.Video.V19} component={V19_4_2}/>
      {/* Chapter 4: Functional Grammar . Imperatives */}
      <Route exact path={Page.FunctionalGrammar.Imperatives.Imperatives} component={Imperatives}/>
      <Route exact path={Page.FunctionalGrammar.Imperatives.Conversations} component={Conversations_4_3}/>
      <Route exact path={Page.FunctionalGrammar.Imperatives.Grammar.Grammar} component={Grammar_4_3}/>
      <Route exact path={Page.FunctionalGrammar.Imperatives.Grammar.Imperatives} component={Imperatives_4_3}/>
      <Route exact path={Page.FunctionalGrammar.Imperatives.Grammar.NegativeImperatives} component={NegativeImperatives}/>
      <Route exact path={Page.FunctionalGrammar.Imperatives.Practice.Practice} component={Practice_4_3}/>
      <Route exact path={Page.FunctionalGrammar.Imperatives.Practice.P1} component={P1_4_3}/>
      <Route exact path={Page.FunctionalGrammar.Imperatives.Practice.P2} component={P2_4_3}/>
      <Route exact path={Page.FunctionalGrammar.Imperatives.Practice.P3} component={P3_4_3}/>
      <Route exact path={Page.FunctionalGrammar.Imperatives.Practice.P4} component={P4_4_3}/>
      <Route exact path={Page.FunctionalGrammar.Imperatives.Practice.P5} component={P5_4_3}/>
      <Route exact path={Page.FunctionalGrammar.Imperatives.Listening.Listening} component={Listening_4_3}/>
      <Route exact path={Page.FunctionalGrammar.Imperatives.Listening.L1} component={L1_4_3}/>
      <Route exact path={Page.FunctionalGrammar.Imperatives.Listening.L2} component={L2_4_3}/>
      <Route exact path={Page.FunctionalGrammar.Imperatives.Listening.L3} component={L3_4_3}/>
      <Route exact path={Page.FunctionalGrammar.Imperatives.Listening.L4} component={L4_4_3}/>
      <Route exact path={Page.FunctionalGrammar.Imperatives.Video.Video} component={Video_4_3}/>
      <Route exact path={Page.FunctionalGrammar.Imperatives.Video.V1} component={V1_4_3}/>
      <Route exact path={Page.FunctionalGrammar.Imperatives.Video.V2} component={V2_4_3}/>
      <Route exact path={Page.FunctionalGrammar.Imperatives.Video.V3} component={V3_4_3}/>
      <Route exact path={Page.FunctionalGrammar.Imperatives.Video.V4} component={V4_4_3}/>
      <Route exact path={Page.FunctionalGrammar.Imperatives.Video.V5} component={V5_4_3}/>
      <Route exact path={Page.FunctionalGrammar.Imperatives.Video.V6} component={V6_4_3}/>
      <Route exact path={Page.FunctionalGrammar.Imperatives.Video.V7} component={V7_4_3}/>
      <Route exact path={Page.FunctionalGrammar.Imperatives.Video.V8} component={V8_4_3}/>
      {/* Chapter 4: Functional Grammar . Honorifics */}
      <Route exact path={Page.FunctionalGrammar.Honorifics.Honorifics} component={Honorifics_4}/>
      <Route exact path={Page.FunctionalGrammar.Honorifics.Conversations} component={Conversations_4_4}/>
      <Route exact path={Page.FunctionalGrammar.Honorifics.Grammar.Grammar} component={Grammar_4_4}/>
      <Route exact path={Page.FunctionalGrammar.Honorifics.Grammar.Honorifics} component={Honorifics_4_4}/>
      <Route exact path={Page.FunctionalGrammar.Honorifics.Grammar.Pejoratives} component={Pejoratives}/>
      <Route exact path={Page.FunctionalGrammar.Honorifics.Grammar.ThirdPersonAction} component={ThirdPersonAction}/>
      <Route exact path={Page.FunctionalGrammar.Honorifics.Video.Video} component={Video_4_4}/>
      <Route exact path={Page.FunctionalGrammar.Honorifics.Video.V1} component={V1_4_4}/>
      <Route exact path={Page.FunctionalGrammar.Honorifics.Video.V2} component={V2_4_4}/>
      <Route exact path={Page.FunctionalGrammar.Honorifics.Video.V3} component={V3_4_4}/>
      <Route exact path={Page.FunctionalGrammar.Honorifics.Video.V4} component={V4_4_4}/>
      <Route exact path={Page.FunctionalGrammar.Honorifics.Video.V5} component={V5_4_4}/>
      <Route exact path={Page.FunctionalGrammar.Honorifics.Video.V6} component={V6_4_4}/>
      <Route exact path={Page.FunctionalGrammar.Honorifics.Video.V7} component={V7_4_4}/>
      <Route exact path={Page.FunctionalGrammar.Honorifics.Video.V8} component={V8_4_4}/>
      <Route exact path={Page.FunctionalGrammar.Honorifics.Video.V9} component={V9_4_4}/>
      <Route exact path={Page.FunctionalGrammar.Honorifics.Video.V10} component={V10_4_4}/>
      <Route exact path={Page.FunctionalGrammar.Honorifics.Video.V11} component={V11_4_4}/>
      <Route exact path={Page.FunctionalGrammar.Honorifics.Video.V12} component={V12_4_4}/>
      {/* Chapter 4: Functional Grammar . Even */}
      <Route exact path={Page.FunctionalGrammar.Even.Even} component={Even}/>
      <Route exact path={Page.FunctionalGrammar.Even.Conversations} component={Conversations_4_5}/>
      <Route exact path={Page.FunctionalGrammar.Even.Grammar.Grammar} component={Grammar_4_5}/>
      <Route exact path={Page.FunctionalGrammar.Even.Grammar.Even} component={Even_4_5}/>
      <Route exact path={Page.FunctionalGrammar.Even.Grammar.EvenIf} component={EvenIf}/>
      <Route exact path={Page.FunctionalGrammar.Even.Video.Video} component={Video_4_5}/>
      <Route exact path={Page.FunctionalGrammar.Even.Video.V1} component={V1_4_5}/>
      <Route exact path={Page.FunctionalGrammar.Even.Video.V2} component={V2_4_5}/>

      {/* Chapter 5: Talk in Town */}
      <Route exact path={Page.TalkInTown.TalkInTown} component={TalkInTown}/>
      <Route exact path={Page.TalkInTown.FishShop} component={FishShop}/>
      <Route exact path={Page.TalkInTown.VegetableShop} component={VegetableShop}/>
      <Route exact path={Page.TalkInTown.EelShop} component={EelShop}/>
      <Route exact path={Page.TalkInTown.Peddler} component={Peddler}/>
      <Route exact path={Page.TalkInTown.PickleStore} component={PickleStore}/>
      <Route exact path={Page.TalkInTown.FruitShop} component={FruitShop}/>
      <Route exact path={Page.TalkInTown.RawOyster} component={RawOyster}/>
      <Route exact path={Page.TalkInTown.GrilledOyster} component={GrilledOyster}/>
      <Route exact path={Page.TalkInTown.ConfectionStore} component={ConfectionStore}/>
      <Route exact path={Page.TalkInTown.BlackbeanTea} component={BlackbeanTea}/>
      <Route exact path={Page.TalkInTown.BlackbeanTea2} component={BlackbeanTea2}/>
      <Route exact path={Page.TalkInTown.Strawberry} component={Strawberry}/>
      <Route exact path={Page.TalkInTown.RiceCake} component={RiceCake}/>
      <Route exact path={Page.TalkInTown.FishShopKyoto} component={FishShopKyoto}/>

      {/* Chapter 6: Interviews */}
      <Route exact path={Page.Interviews} component={Interviews}/>
      <Route exact path={Page.Interviewees} component={Interviewees}/>
      <Route exact path={Page.InterviewQuestions} component={InterviewQuestions}/>
      <Route exact path={Page.InterviewClips} component={InterviewClips}/>

      {/* Chapter 7: Other Resources */}
      <Route exact path={Page.OtherResources.OtherResources} component={OtherResources}/>
      <Route exact path={Page.OtherResources.AnimeManga} component={AnimeManga}/>
      <Route exact path={Page.OtherResources.Films} component={Films}/>
      <Route exact path={Page.OtherResources.JPop} component={JPop}/>
      <Route exact path={Page.OtherResources.Links} component={Links}/>
      <Route exact path={Page.OtherResources.Foreigners.Foreigners} component={Foreigners}/>
      <Route exact path={Page.OtherResources.Foreigners.LeeEnglish} component={LeeInEnglish}/>
      <Route exact path={Page.OtherResources.Foreigners.LeeKansaiben} component={LeeInKansaiben}/>
      <Route exact path={Page.OtherResources.Foreigners.EricEnglish} component={EricInEnglish}/>
      <Route exact path={Page.OtherResources.Foreigners.EricKansaiben} component={EricInKansaiben}/>
      <Route exact path={Page.OtherResources.LettersFromUsers} component={LettersFromUsers}/>
      <Route exact path={Page.OtherResources.HowToUse} component={HowToUse}/>

      {/* About */}
      <Route exact path={Page.About.About} component={About}/>
      <Route exact path={Page.About.AboutThisProject} component={AboutThisProject}/>
      <Route exact path={Page.About.AboutPeople} component={AboutPeople}/>
      <Route exact path={Page.About.ContactInformation} component={ContactInformation}/>
      <Route exact path={Page.About.References} component={References}/>

      {/* Index */}
      <Route exact path={Page.Index.Index} component={Index}/>
      <Route exact path={Page.Index.Glossary} component={Glossary}/>
      <Route exact path={Page.Index.GrammarIndex} component={GrammarIndex}/>

      {/* Read First */}
      <Route path={Page.ReadFirst} component={ReadFirst}/>

      {/* Sitemap */}
      <Route path={Page.Sitemap} component={Sitemap}/>

      {/* Default route */}
      <Redirect to={Page.FirstRun}/>
    </Switch>
  )
};

export default Main;