import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { ShowHideableContents, ShowHideableTranscript } from "components/common/hideable";

const ConversationsHeaders = () => {
  return <>
    <h2>{addRubyText("基本会話","きほんかいわ")} (Core Conversations)</h2>
    <hr />
  </>;
}

const ConversationSingle = (props) => {
  const title = props.title;
  const description = props.description;
  const kansaiTranscript = props.kansaiTranscript;
  const standardTranscript = props.standardTranscript;
  const videoFile = props.videoFile;
  const footNotes = props.footNotes;

  return <>
    <h3>{title}</h3>
    {description}
    <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}} colSpan="1" rowSpan="2">
            {addVideoPlayer(videoFile)}
          </td>
          <td style={{verticalAlign: "top"}}>
            {ShowHideableTranscript({
              title: <>Kansai transcript<br /></>,
              transcript: kansaiTranscript
            })}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {ShowHideableTranscript({
              title: <>Standard transcript<br /></>,
              transcript: standardTranscript
            })}
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    <small>
      {footNotes} 
    </small>
    <br />
  </>;
}

const Checkup = (props) => {
  const questions = props.questions;
  const answers = props.answers;

  return <>
    <h3>{addRubyText("理解度","りかいど")}チェック (Check-up)</h3>
    Figure out the Kansai-peculiar grammar and expressions appeared in the
    video clips. Refer to the standard and Kansai transcripts if necessary.<br />
    <br />
    <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Check-up
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Answer
          </td>
        </tr>
        {questions.map((question, i) => {
          const answer = answers[i];
          return <tr>
            <td style={{verticalAlign: "top"}}>
              {question}
            </td>
            <td style={{verticalAlign: "top"}}>
              {ShowHideableContents(answer)}
            </td>
          </tr>
        })}
      </tbody>
    </table>
  </>
}

const VocabCheckup = (props) => {
  const kansaiVocab = props.kansaiVocab;
  const standardDef = props.standardDef;

  return <>
    <h3>{addRubyText("関西","かんさい")}{addRubyText("語彙","ごい")}チェック (Kansai Vocabulary Check-up)</h3>
    What are the standard equivalent expression of the following Kansai
    words that are introduced in the core conversations of this lesson?

    <table style={{textAlign: "left", width: "50%"}} border="1" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Kansai
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Standard
          </td>
        </tr>
        {kansaiVocab.map((kansai, i) => {
          const standard = standardDef[i];
          return <tr>
            <td style={{verticalAlign: "top"}}>
              {kansai}
            </td>
            <td style={{verticalAlign: "top"}}>
              {ShowHideableContents(standard)}
            </td>
          </tr>
        })}
      </tbody>
    </table>
  </>;
}

const Conversations = () => {
  const propsConv1 = {
    title: <>{addRubyText("会話","かいわ")}１ : Does A have a class today?</>,
    description: <>
      *A=first speaker, B=second speaker<br />
      *Click "SHOW" for transcripts<br />
      <br />
      (A and B are friends)
    </>,
    kansaiTranscript: [
      <>A: きょう、<span style={{fontWeight: "bold"}}>ク</span>ラスあ<span style={{fontWeight: "bold"}}>るぅ</span>？</>,
      <>B: あ<span style={{fontWeight: "bold"}}>ら</span>へん。<span style={{fontWeight: "bold"}}>き</span>のうはあった<span style={{fontWeight: "bold"}}>け</span>ど。</>
    ],
    standardTranscript: [
      <>A: {addRubyText("今日","きょう")}、クラスある？</>,
      <>B: ない。{addRubyText("昨日","きのう")}はあったけど</>
    ],
    videoFile: "3.1.a.cc.1.mp4",
    footNotes: <></>
  }

  const propsConv2 = {
    title: <>{addRubyText("会話","かいわ")}2: Where will they drink today?</>,
    description: <>(A and B are friends)</>,
    kansaiTranscript: [
      <>A: コンビニ<span style={{fontWeight: "bold"}}>で</span>、おさけ<span style={{fontWeight: "bold"}}>こ</span>うた<span style={{fontWeight: "bold"}}>ぁ</span>？</>,
      <>B: う<span style={{fontWeight: "bold"}}>う</span>ん、<span style={{fontWeight: "bold"}}>か</span>わへんかった。</>,
      <>A: <span style={{fontWeight: "bold"}}>ほ</span>んなら、のみに<span style={{fontWeight: "bold"}}>い</span>かへ<span style={{fontWeight: "bold"}}>ん</span>？</>,
      <>B: <span style={{fontWeight: "bold"}}>う</span>ん、<span style={{fontWeight: "bold"}}>いこ</span>、<span style={{fontWeight: "bold"}}>いこ</span>。</>
    ],
    standardTranscript: [
      <>A: コンビニで、お{addRubyText("酒","さけ")}{addRubyText("買","か")}った？</>,
      <>B: ううん、買わなかった。</>,
      <>A: じゃ、{addRubyText("飲","の")}みに{addRubyText("行","い")}かない？</>,
      <>B: うん、行こう。</>
    ],
    videoFile: "3.1.a.cc.2.mp4",
    footNotes: <>＊コンビニ：コンビニエンス・ストア</>
  }

  const propsConv3 = {
    title: <>{addRubyText("会話","かいわ")}3 : What has Yu-chan done so far?</>,
    description: <>(A and B are sisters)</>,
    kansaiTranscript: [
        <>A: <span style={{fontWeight: "bold"}}>ゆ</span>うちゃん、<span style={{fontWeight: "bold"}}>ゴ</span>ミ<span style={{fontWeight: "bold"}}>ほ</span>かした？</>,
        <>B: う<span style={{fontWeight: "bold"}}>う</span>ん、ま<span style={{fontWeight: "bold"}}>だ</span>。</>,
        <>A: <span style={{fontWeight: "bold"}}>ほ</span>な、さっ<span style={{fontWeight: "bold"}}>き</span>よん<span style={{fontWeight: "bold"}}>だ</span>まんが、<span style={{fontWeight: "bold"}}>ほんだなにな</span>おした？</>,
        <>B: ごめ〜<span style={{fontWeight: "bold"}}>ん</span>。<span style={{fontWeight: "bold"}}>それ</span>もま<span style={{fontWeight: "bold"}}>だ</span>。</>,
        <>A: も〜。</>
    ],
    standardTranscript: [
        <>A: ゆうちゃん、ゴミ{addRubyText("捨","す")}てた？</>,
        <>B: ううん、まだ。</>,
        <>A: じゃあ、さっき{addRubyText("読","よ")}んだ{addRubyText("漫画","まんが")}、{addRubyText("本棚","ほんだな")}に{addRubyText("戻","もど")}した？</>,
        <>B: ごめ〜ん、それもまだ。</>,
        <>A: も〜。</>
    ],
    videoFile: "3.1.a.cc.3.mp4",
    footNotes: <>＊なおす：{addRubyText("戻","もど")}す　return X; put X back</>
  }

  const checkupProps = {
    questions: [
      <>1) What is the non-past negative form of verb in Kansai-ben?</>,
      <>2) What about the past negative form?</>,
      <>3) Are the たforms of verb in Kansai-ben the same as those of Standard?</>,
      <>4) What is the negative form of ある in Kansai-ben?</>
    ],
    answers: [
      <>へん</>,
      <>へんかった</>,
      <>Not necessarily,&nbsp; e.g. あった=あった, but{addRubyText("買","か")}った -&gt;{addRubyText("買","こ")}うた in Kansai</>,
      <>あらへん</>
    ]
  }

  const vocabCheckupProps = {
    kansaiVocab: [
      <>ほかす</>,
      <>{addRubyText("捨","す")}てる</>
    ],
    standardDef: [
      <>なおす</>,
      <>{addRubyText("戻","もど")}す</>
    ]
  }

  return (<>
    {ConversationsHeaders()}
    {ConversationSingle(propsConv1)}
    {ConversationSingle(propsConv2)}
    {ConversationSingle(propsConv3)}
    <hr />
    {Checkup(checkupProps)}
    <hr />
    {VocabCheckup(vocabCheckupProps)}
  </>);
}

export default Conversations;

