import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";

const V2 = () => {
  const transcript = <>
    ははは、、、ほな、{addRubyText("撮","と")}れへんで。この人が<span
    style={{textDecoration: "underline"}}>やはん</span>(= やはる Grammar 1 Extra 1)で。
  </>;

  const qaObjects = [
    {
      question: <span>Q1: Why does she think she cannot be videotaped?</span>,
      answersCorrect: [
        <>Because her grandson is around</> 
      ],
      answersIncorrect: [
        <>Because her grandson wouldn’t stop playing </>,
        <>Because the interviewer wouldn’t throw her grandson out</>,
        <>Because the interviewer tends to talk to her grandson</>
      ]
    },
    {
      question: <span>Q2:For whom does she use はる？</span>,
      answersCorrect: [
        <>Her grandson</>
      ],
      answersIncorrect: [
        <>Interviewer</>,
        <>Herself</>,
        <>Someone else</>
      ]
    }
  ];

  return <>
    <h2>ビデオ{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}２: Authentic - short<br />
    </h2>
    Watch the video clip and choose the answer that matches the content of
    the clip.
    Submit your answers after answering all the questions.<br />
    <small>*Click "SHOW" if you need to refer to the transcript</small><br />
    <br />
    Context: Her grandson Takashi was playing
    with {addRubyText("座布団","ざぶとん")} (cushion)
    behind Mitsue-san when she was about to be interviewed.<br />
    <table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>{addVideoPlayer("4.4.1.p.2.mitsue.mp4")}</td>
          <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>Transcript
    <Hideable content={transcript}/>
    <br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>ミツエさん (Shiga)</td>
        </tr>
      </tbody>
    </table>
    <br />
    <QandAForm qaObjects={qaObjects}/>
  </>;
}

export default V2;
