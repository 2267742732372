import React from "react";
import { addRubyText, addAudio } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";

const P2 = () => {
  const transcript = <>
  <ol>
    <li>A: これ、絶対食べない！</li>
    <li>B: {addRubyText("馬鹿","ばか")}なことを言ってないで、{addRubyText("早","はや")}く食べなさいよ！</li>
  </ol>
  </>;
  return <>
    <h2>{addRubyText("練習","れんしゅう")}2: Imperatives (stronger) - Pattern Practice<br />
    </h2>
    <br />
    According to the model, form a strong negative statement and a strong
    command as a reply for each cue. You may listen to the question, try to
    answer it, and then check your answer. Answers vary:
    the recorded answers indicate those commonly used.<br />
    <small>*Move the mouse over to the speaker icon for sound.</small><br />
    <br />
    <span style={{fontWeight: "bold"}}>Model</span>（Cue:これ／{addRubyText("食","た")}べる） <br />
    <br />
    &nbsp;&nbsp;&nbsp; A:これ、{addRubyText("絶対","ぜったい")}食べへん！<br />
    &nbsp;&nbsp;&nbsp; B:&nbsp; あほなこと{addRubyText("言","ゆ")}うてんと、はよ食べぇや！{addAudio("4.3.p.2.mp3")}<br />
    <br />
    ST transcript <br />
    <Hideable content={transcript} />
    <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>Cue<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>A (Question)<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>B (Answer)<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>この{addRubyText("薬","くすり")}／{addRubyText("飲","の")}む<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("4.3.p.2.1.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("4.3.p.2.1.b.mp3")}</td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>{addRubyText("宿題","しゅくだい")}／する</td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("4.3.p.2.2.a.mp3")}</td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("4.3.p.2.2.b.mp3")}</td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>テレビ／{addRubyText("消","け")}す</td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("4.3.p.2.3.a.mp3")}</td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("4.3.p.2.3.b.mp3")}</td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>{addRubyText("今晩","こんばん")}／{addRubyText("寝","ね")}る</td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("4.3.p.2.4.a.mp3")}</td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("4.3.p.2.4.b.mp3")}</td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>{addRubyText("学校","がっこう")}／{addRubyText("行","い")}く </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("4.3.p.2.5.a.mp3")}</td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("4.3.p.2.5.b.mp3")}</td>
        </tr>
      </tbody>
    </table>
  </>;
}

export default P2;
