import React from "react";
import { addRubyText, addAudio } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";

const P2 = () => {

  const standardTranscript = <>
    <ol className="transcript">
      <li>A: 切符、ちょっと高くなかった？</li>
      <li>B: そうだねえ、高かったねえ。</li>
    </ol>
  </>;

  return <>
    <h2>{addRubyText("練習","れんしゅう")}2 : Adjectives (Past negative)</h2>
    Form a question and an answer for each cue according to
    a model. You may listen to the question, tryto answer it, and check
    your answer. *Answers vary; the recorded answers indicate those
    commonly
    used.<br />
    <small>*Mouse over or click on the speaker icon for sound</small><br />
    <br />
    <span style={{fontWeight: "bold"}}>Model</span> (Cue:{addRubyText("切符","きっぷ")}／{addRubyText("高","たか")}い)<br />
    &nbsp;&nbsp;&nbsp; A: 切符、ちょっとたこなかった？<br />
    &nbsp;&nbsp;&nbsp; B: ほやなあ、高かったなあ。{addAudio("3.4.p.2.mp3")}
    <br />
    <br />
    Point: no need to add くin Adj negatives. If the Adj ends with -A-い, a
    soudn change may occur. (refer to Grammar 2)<br />
    <br />
    ST transcript 
    <Hideable content={standardTranscript} />
    <br />
    <br />
    <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>Cue<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>A (Question)<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>B (Answer)<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>{addRubyText("切符","きっぷ")}／{addRubyText("高","たか")}い<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.4.p.2.1.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.4.p.2.1.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>{addRubyText("電車","でんしゃ")}／{addRubyText("遅","おそ")}い</td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.4.p.2.2.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.4.p.2.2.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>サービス／{addRubyText("悪","わる")}い<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.4.p.2.3.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.4.p.2.3.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>ケーキ／{addRubyText("小","ちい")}さい</td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.4.p.2.4.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.4.p.2.4.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>{addRubyText("映画館","えいがかん")}／{addRubyText("見","み")}にくい</td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.4.p.2.5.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.4.p.2.5.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>ビール／{addRubyText("飲","の")}みたい<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.4.p.2.6.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.4.p.2.6.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>{addRubyText("道","みち")}／ややこしい</td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.4.p.2.7.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.4.p.2.7.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>{addRubyText("仕事","しごと")}／しんどい</td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.4.p.2.8.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.4.p.2.8.b.mp3")}<br />
          </td>
        </tr>
      </tbody>
    </table>
  </>;
}

export default P2;
