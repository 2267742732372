import React from "react";
import { addRubyText, addImage, addAudio } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { DraggableForm } from "common/util/draggableForm";

const L1 = () => {
  const transcriptA = <>
    <br />
    <ol className="transcript">
      <li>Y: {addRubyText("美香", "みか")}の{addRubyText("彼", "かれ")}って、どんな人なん？　{addRubyText("背", "せ")}ぇ{addRubyText("高", "たか")}いん？</li>
      <li>M: え〜、そうやなあ。背ぇは{addRubyText("高", "たこ")}ないなあ。</li>
      <li>Y: ふ〜ん。それで、かっこええの？</li>
      <li>M: {addRubyText("別", "べつ")}にかっこええことないし。{addRubyText("目", "め")}ぇなんか、こんなほっそいし。</li>
      <li>Y: へえ。でも、コンピューターの{addRubyText("仕事", "しごと")}してるんやろ。{addRubyText("頭", "あたま")}いいやん。</li>
      <li>M: 頭は{addRubyText("悪", "わる")}いことあらへんやろうけど、その頭がおっきいねん。</li>
      <li>Y: ふ〜ん。でも、すんごいお{addRubyText("金", "かね")}{addRubyText("持", "も")}ってるとか？</li>
      <li>M: そんなことないって。この{addRubyText("間", "あいだ")}なんか、お{addRubyText("茶代", "ちゃだい")}も{addRubyText("払", "はろ")}てくれへんねんで。せこいと{addRubyText("思", "おも")}わへん？</li>
      <li>Y: ほんだら、ええとこいっこもあらへんやん。</li>
      <li>M: ウ〜ン、、、でも、そこがかわいいねん。</li>
      <li>Y: あほらし。</li>
    </ol>
  </>;

  const transcriptB = <>
    <br />
    <ol className="transcript">
      <li>M: そしたら、{addRubyText("由里", "ゆり")}の彼はどうなん？</li>
      <li>Y: 背ぇは、高いよ。</li>
      <li>M: それから？</li>
      <li>Y: すっごいハンサムやし。</li>
      <li>M: ええ〜、ええなあ。</li>
      <li>Y: それに{addRubyText("足長", "あしなが")}いし。</li>
      <li>M: ええやん、ええやん。{addRubyText("最高", "さいこう")}やん。</li>
      <li>Y: せやけど、{addRubyText("私", "わたし")}の{addRubyText("他", "ほか")}にもう{addRubyText("一人", "ひとり")}、{addRubyText("彼女", "かのじょ")}おるみたいやねん。</li>
      <li>M: ええっ？{addRubyText("最低", "さいてい")}やん。</li>
    </ol>
  </>;

  const draggableFormData = {
    prompts: {
      texts: [
        <>{addRubyText("美香", "みか")}さんの{addRubyText("彼", "かれ")}(Mika's BF)</>,
        <>{addRubyText("由里", "ゆり")}さんの{addRubyText("彼", "かれ")}(Yuri's BF)</>,
        <>Neither</>
      ]
    },
    answers: {
      images: [
        "3.4.pl0.1.a.jpg",
        "3.4.pl0.1.e.jpg",
        "3.4.pl0.1.f.jpg",
        "3.4.pl0.1.g.jpg",
        "3.4.pl0.1.h.jpg",
        "3.4.pl0.1.j.jpg",

        "3.4.pl0.1.b.jpg",
        "3.4.pl0.1.c.jpg",
        "3.4.pl0.1.i.jpg",

        "3.4.pl0.1.d.jpg"
      ]
    },
    matchGraph: [
      new Set([0,1,2,3,4,5]),
      new Set([6,7,8]),
      new Set([9])
    ]
  }

  return <>
    <h2>{addRubyText("聞", "き")}き{addRubyText("取", "と")}り{addRubyText("練習", "れんしゅう")}1</h2>
    <table style={{ textAlign: "left", width: "100%" }} border="0" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{ verticalAlign: "top" }}>
            {addRubyText("美香", "みか")}さんと{addRubyText("由里", "ゆり")}さんの{addRubyText("彼", "かれ")}はどんな
            {addRubyText("人", "ひと")}でしょう。{addRubyText("次", "つぎ")}の{addRubyText("会話", "かいわ")}を{addRubyText("聞", "き")}いて、{addRubyText("下", "した")}
            のAからIの{addRubyText("絵", "え")}の{addRubyText("中", "なか")}から{addRubyText("選", "えら")}んで{addRubyText("下", "くだ")}さい。<br />
            (What are Mika-san and Yuri-san's boyfriends like respectively? Listen
            to the two sets of conversation and drag&nbsp; and drop the appropriate
            descriptions from illustrations A to I onto either "Mika's BF"or "Yuri's BF". If it
            does not match either, drag it to "Neither". If you get the right answer,
            you will hear "Right!!" and the illustration will stick to the chosen
            box. If it's wrong, the illustration will bounce back to the original location.)
            <br />
            <small>
              *Click "SHOW" if you need to refer to the transcripts<br />
              *Mouse over or click on the speaker icon for sound
            </small>
          </td>
          <td style={{ verticalAlign: "top" }}>
            {addImage("3.4.pl0.1.mikayuri.jpg")}
          </td>
        </tr>
      </tbody>
    </table>
    <small></small><br />
    <table style={{ textAlign: "left", width: "100%" }} border="1" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{ verticalAlign: "top", textAlign: "center" }}>
            <big style={{ fontWeight: "bold" }}>
              &lt;Set A&gt;
            </big>
            <br />
          </td>
          <td style={{ verticalAlign: "top", textAlign: "center" }}>
            <big style={{ fontWeight: "bold" }}>
              &lt;Set B&gt;
            </big>
          </td>
        </tr>
        <tr>
          <td style={{ verticalAlign: "top" }}>
            {addAudio("3.4.pl0.1.a.mp3")}
            <Hideable content={transcriptA} />
          </td>
          <td style={{ verticalAlign: "top" }}>
            {addAudio("3.4.pl0.1.b.mp3")}
            <Hideable content={transcriptB} />
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    <br />
    <DraggableForm prompts={draggableFormData.prompts} answers={draggableFormData.answers} matchGraph={draggableFormData.matchGraph}/>
  </>;
}

export default L1;
