import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";

const V4 = () => {
  const transcript = <>
    ほな、お{addRubyText("母","かあ")}ちゃん(=his
    wife)にあげよかな。はい、{addRubyText("今日","きょう")}はもう、あの、{addRubyText("子供","こども")}はな、<span
    style={{textDecoration: "underline"}}>{addRubyText("休","やす")}んどっ</span>(=休んどるGrammar
    2)さかいな。今日は休みやさかいゆうて。、、、
  </>;

  const qaObjects = [
    {
      question: <span>Q1: To whom is he most likely to give the chocolate?</span>,
      answersCorrect: [
        <>To his wife only</>
      ],
      answersIncorrect: [
        <>To his wife and child</>,
        <>To his child only</>,
        <>To noody</>
      ]
    },
    {
      question: <span>Q2: For whom did he use とる?</span>,
      answersCorrect: [
        <>His child</>
      ],
      answersIncorrect: [
        <>His wife</>,
        <>Himself</>,
        <>Interviewer</>
      ]
    }
  ];

  return <>
    <h2>ビデオ{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}４: Authentic - short<br />
    </h2>
    Watch the video clip and choose the answer that matches the content of
    the clip.
    Submit your answers after answering all the questions.<br />
    <small>*Click "SHOW" if you need to refer to the transcript</small><br />
    <br />
    Context: Matsuda-san just received
    chocolate as a gift from the
    interviewer.<br />
    <table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2"
    cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>{addVideoPlayer("4.4.2.p.1.matsuda.mp4")} <br />
          </td>
          <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>Transcript
    <Hideable content={transcript}/><br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>マツダさん
    (Shiga)</td>
        </tr>
      </tbody>
    </table>
    <br />
    <QandAForm qaObjects={qaObjects}/>
  </>;
}

export default V4;
