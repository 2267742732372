import React from "react";
import { addRubyText } from "common/util/generalUtil";
import { Page } from "home/page";
import { Link } from "react-router-dom";

const Glossary = () => {
  return <>
<h2>Kansai Glossary</h2>
The following list contains the Kansai vocabulary introduced in this
website (except those in Ch.6 Interviews). <br />
Kansai specific grammatical expressions/structural paterns are listed
in 
<Link to={Page.Index.GrammarIndex}>Kansai Grammar Index.</Link><br />
<br />
<table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2"
 cellSpacing="2">
  <tbody>
    <tr>
      <td style={{verticalAlign: "top"}}><br />
      </td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>Kansai&nbsp;&nbsp;&nbsp;
      <br />
      </td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>&nbsp;
Standard&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>English&nbsp;&nbsp;&nbsp;
      <br />
      </td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>introduced in<br />
      </td>
    </tr>
    <tr>
      <td colSpan="1" rowSpan="5" style={{verticalAlign: "top"}}>あ<br />
      </td>
      <td style={{verticalAlign: "top"}}>あかん&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}> いけない／だめ&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}> no good&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.Characteristics.LinguisticAspects.Words}>1.2.c</Link>, <Link to={Page.BasicGrammar.VerbTeForm.Conversations}>3.2.1</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>あほ&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>ばか&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>fool&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.FunctionalGrammar.Imperatives.Conversations}>4.3.1</Link></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>あほくさい&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>ばからしい&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>silly, ridiculous&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.Adjectives.Grammar.KansaiAdjectives}>3.4.2.1</Link></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>ありまっせ<br />
      </td>
      <td style={{verticalAlign: "top"}}>ありますよ<br />
      </td>
      <td style={{verticalAlign: "top"}}>I have it! (polite)<br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.TalkInTown.FishShopKyoto}>5.14</Link><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>あんた&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>あなた&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>you (casual) <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.FunctionalGrammar.Even.Conversations}>4.5.1</Link></td>
    </tr>
    <tr>
      <td colSpan="1" rowSpan="4" style={{verticalAlign: "top"}}>い<br />
      </td>
      <td style={{verticalAlign: "top"}}>{addRubyText("一個","いっこ")}も-neg&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>{addRubyText("全然","ぜんぜん")}-neg&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>not … at all&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.Adjectives.Conversations}>3.4.1</Link>, <Link to={Page.BasicGrammar.Adjectives.Grammar.KansaiAdjectives}>3.4.2.1</Link></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>いてる&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>いる&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>exist (animate) いて+ いる (Kansai
only)&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.VerbTeForm.Grammar.TeFunctions}>3.2.2.3</Link></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>いっかも<br />
      </td>
      <td style={{verticalAlign: "top"}}>{addRubyText("何日","なんにち")}も</td>
      <td style={{verticalAlign: "top"}}>many days<br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.TalkInTown.Peddler}>5.4</Link><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>いっぺんに<br />
      </td>
      <td style={{verticalAlign: "top"}}>{addRubyText("一度","いちど")}に</td>
      <td style={{verticalAlign: "top"}}>all at once</td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.TalkInTown.ConfectionStore}>5.9</Link><br />
      </td>
    </tr>
    <tr>
      <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>う<br />
      </td>
      <td style={{verticalAlign: "top"}}>うち&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>{addRubyText("私","わたし")}&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}> I/me (gentle)&nbsp;&nbsp;&nbsp;
      <br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.FunctionalGrammar.Even.Conversations}>4.5.1</Link></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>うっとこ／うちとこ&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>{addRubyText("私","わたし")}のところ&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}> my house,&nbsp; my
place&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.FunctionalGrammar.Even.Conversations}>4.5.1</Link></td>
    </tr>
    <tr>
      <td colSpan="1" rowSpan="5" style={{verticalAlign: "top"}}>え<br />
      </td>
      <td style={{verticalAlign: "top"}}>ええ&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>いい&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>good, OK, fine&nbsp;&nbsp;&nbsp;
      <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.Expressions.OtherExpressions}>2.4</Link>, <Link to={Page.BasicGrammar.Adjectives.Grammar.KansaiAdjectives}>3.4.2.1</Link></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>ええ{addRubyText("加減","かげん")}にする&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>いい{addRubyText("加減","かげん")}にする&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Leave (a thing)
half-way&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.FunctionalGrammar.PermissionProhibition.Conversations}>4.1.1</Link></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>ええ{addRubyText("年","とし")}こいて&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>いい{addRubyText("年","とし")}をして&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>In spite of one’s mature age,
ought to know better at one’s age&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.FunctionalGrammar.Imperatives.Conversations}>4.3.1</Link></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>えらい&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>すごい、とても、つかれた&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>splendid, tired, very
much&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.Expressions.GratitudeApology}>2.3</Link></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>えらいおおきに&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>どうもありがとう&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Thank you very
much&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.Expressions.GratitudeApology}>2.3</Link></td>
    </tr>
    <tr>
      <td colSpan="1" rowSpan="9" style={{verticalAlign: "top"}}>お<br />
      </td>
      <td style={{verticalAlign: "top"}}>おおきに&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>ありがとう&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Thank you&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.Expressions.GratitudeApology}>2.3</Link></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>おかいさん&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>お{addRubyText("粥","かゆ")}&nbsp;&nbsp;&nbsp;<br />
      </td>
      <td style={{verticalAlign: "top"}}> rice porridge&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.Characteristics.LinguisticAspects.Words}>1.2.c</Link>&nbsp;
      <br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>おきばりやす<br />
      </td>
      <td style={{verticalAlign: "top"}}>{addRubyText("頑張","がんば")}りなさい&nbsp;&nbsp;&nbsp;
&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Hang on, Do your best
(Kyoto)&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.Expressions.Greetings}>2.1</Link></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>お{addRubyText("造","つく")}り</td>
      <td style={{verticalAlign: "top"}}>さしみ<br />
      </td>
      <td style={{verticalAlign: "top"}}>Sashimi, slices of raw fish<br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.TalkInTown.FishShopKyoto}>5.14</Link><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>おはようさん&nbsp;&nbsp;&nbsp;&nbsp;
&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>おはよう&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Good morning&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.Expressions.Greetings}>2.1</Link></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>おまめさん<br />
      </td>
      <td style={{verticalAlign: "top"}}>まめ<br />
      </td>
      <td style={{verticalAlign: "top"}}>beans<br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.TalkInTown.Peddler}>5.4</Link><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>おもろい&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>{addRubyText("面白","おもしろ")}い&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>interesting,
funny&nbsp;&nbsp;&nbsp;&nbsp;<br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.BasicGrammar.Adjectives.Grammar.KansaiAdjectives}>3.4.2.1</Link>&nbsp;
      <br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>おやすみやす&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>おやすみなさい&nbsp;&nbsp;&nbsp;&nbsp;<br />
      </td>
      <td style={{verticalAlign: "top"}}>Good night
(Kyoto)&nbsp;&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.Expressions.LeaveTakings}>2.2</Link><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>おる&nbsp;&nbsp;&nbsp;&nbsp;<br />
      </td>
      <td style={{verticalAlign: "top"}}>おる／いる&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>humble / pejorative / blunt
expression of いる&nbsp;&nbsp;&nbsp;&nbsp;<br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.VerbTeForm.Grammar.TeFunctions}>3.2.2.3</Link>, <Link to={Page.FunctionalGrammar.Honorifics.Grammar.Pejoratives}>4.4.2.2</Link></td>
    </tr>
    <tr>
      <td colSpan="1" rowSpan="4" style={{verticalAlign: "top"}}>か<br />
      </td>
      <td style={{verticalAlign: "top"}}>〜かいな&nbsp;&nbsp;&nbsp; <br />
      <br />
      </td>
      <td style={{verticalAlign: "top"}}>〜かな&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>sentence particle <br />
1) I wonder...<br />
2) ~? No way! (rhetorical question, Kansai only)&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.Expressions.OtherExpressions}>2.4</Link></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>かいらしい&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>かわいい／かわいらしい&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>cute&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.BasicGrammar.Adjectives.Grammar.KansaiAdjectives}>3.4.2.1</Link></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>かしこい&nbsp;&nbsp;&nbsp; <br />
      <br />
      </td>
      <td style={{verticalAlign: "top"}}>しっかりした、{addRubyText("頭","あたま")}がいい&nbsp;&nbsp;
&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>wise, clever, intelligent,
bright&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.Adjectives.Grammar.KansaiAdjectives}>3.4.2.1</Link></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>かしわ&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>{addRubyText("鶏肉","とりにく")}&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>chicken&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.Characteristics.LinguisticAspects.Words}>1.2.c</Link>, <Link to={Page.FunctionalGrammar.NegativePermissionObligation.Video.V15}>4.2.5.15</Link></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>き<br />
      </td>
      <td style={{verticalAlign: "top"}}>ぎょうさん&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>たくさん&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>many, a lot&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.Characteristics.LinguisticAspects.Words}>1.2.c</Link>, <Link to={Page.TalkInTown.Peddler}>5.4</Link><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>く<br />
      </td>
      <td style={{verticalAlign: "top"}}>{addRubyText("食","く")}う&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>{addRubyText("食","た")}べる&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>eat (blunt)&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.FunctionalGrammar.Honorifics.Grammar.Pejoratives}>4.4.2.2</Link></td>
    </tr>
    <tr>
      <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>け<br />
      </td>
      <td style={{verticalAlign: "top"}}>けったいな&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>{addRubyText("奇妙","きみょう")}な&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>strange, queer&nbsp;&nbsp;&nbsp;
      <br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.Characteristics.LinguisticAspects.Words}>1.2.c</Link></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜けんど／けども&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>~けど&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>~ however, …&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.Expressions.OtherExpressions}>2.4</Link></td>
    </tr>
    <tr>
      <td colSpan="1" rowSpan="4" style={{verticalAlign: "top"}}>こ<br />
      </td>
      <td style={{verticalAlign: "top"}}>こける&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>ころぶ&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>stumble&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.Characteristics.LinguisticAspects.Words}>1.2.c</Link></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>ここら（{addRubyText("辺","へん")}）&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>この{addRubyText("辺","へん")}&nbsp;&nbsp;&nbsp;&nbsp;
      <br />
      </td>
      <td style={{verticalAlign: "top"}}>Around here&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.Adjectives.Video.V2}>3.4.5.2</Link></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>ごっつい／ごつい&nbsp;&nbsp;&nbsp;&nbsp;</td>
      <td style={{verticalAlign: "top"}}>とても{addRubyText("大","おお")}きい、すごい&nbsp;&nbsp;&nbsp;
      <br />
      </td>
      <td style={{verticalAlign: "top"}}>huge, very big,
tough&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.BasicGrammar.Adjectives.Grammar.KansaiAdjectives}>3.4.2.1</Link></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>こんなけ<br />
      </td>
      <td style={{verticalAlign: "top"}}>こんな／こんなに<br />
      </td>
      <td style={{verticalAlign: "top"}}>this much<br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.TalkInTown.Peddler}>5.4</Link><br />
      </td>
    </tr>
    <tr>
      <td colSpan="1" rowSpan="4" style={{verticalAlign: "top"}}>さ<br />
      </td>
      <td style={{verticalAlign: "top"}}>さいなら&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>さようなら&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Good bye&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.Expressions.LeaveTakings}>2.2</Link></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜さかい&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>〜から／ので&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>conjunctives “Because
…”&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.Expressions.OtherExpressions}>2.4</Link>, <Link to={Page.FunctionalGrammar.Imperatives.Conversations}>4.3.1</Link>, <Link to={Page.TalkInTown.Peddler}>5.4</Link><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜さけ&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>〜から／ので&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>conjunctives “Because
…”&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.Expressions.OtherExpressions}>2.4</Link>, <Link to={Page.FunctionalGrammar.Imperatives.Conversations}>4.3.1</Link><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>さぶい&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>{addRubyText("寒","さむ")}い&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>cold&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.Adjectives.Conversations}>3.4.1</Link></td>
    </tr>
    <tr>
      <td colSpan="1" rowSpan="7" style={{verticalAlign: "top"}}>し<br />
      </td>
      <td style={{verticalAlign: "top"}}>〜しな&nbsp;&nbsp;&nbsp; <br />
      <br />
      </td>
      <td style={{verticalAlign: "top"}}>〜{addRubyText("途中","とちゅう")}&nbsp;&nbsp;&nbsp;
&nbsp;</td>
      <td style={{verticalAlign: "top"}}>On one’s way to …<br />
（きしな、行きしな、帰りしな…)&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.FunctionalGrammar.Honorifics.Video.V11}>4.4.5.11</Link></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>{addRubyText("自分","じぶん")}ら<br />
      </td>
      <td style={{verticalAlign: "top"}}>{addRubyText("私","わたし")}たち<br />
      </td>
      <td style={{verticalAlign: "top"}}>we/us<br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.Verbs.Video.V3}>3.1a.5.3</Link><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>しゃあない&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>しようがない&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>It cannot be
helped&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.BasicGrammar.VerbTeForm.Conversations}>3.2.1</Link><br />
        <Link to={Page.FunctionalGrammar.Even.Video.V2}>4.5.5.2</Link><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>{addRubyText("邪魔臭","じゃまくさ")}い&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>{addRubyText("面倒","めんどう")}な&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>bothersome&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.FunctionalGrammar.Honorifics.Video.V11}>4.4.5.11</Link></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>しょうもない&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>つまらない&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>boring&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.Characteristics.LinguisticAspects.Words}>1.2.c</Link>, <Link to={Page.BasicGrammar.Adjectives.Grammar.KansaiAdjectives}>3.4.2.1</Link></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>しわしわ&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>しわくちゃ&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>terribly
wrinkled&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.BasicGrammar.VerbTeForm.Conversations}>3.2.1</Link></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>しんどい&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>{addRubyText("疲","つか")}れた&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>tired,
exhausted&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.Expressions.OtherExpressions}>2.4</Link>, <Link to={Page.BasicGrammar.Adjectives.Grammar.KansaiAdjectives}>3.4.2.1</Link>, <Link to={Page.FunctionalGrammar.PermissionProhibition.Conversations}>4.1.1</Link></td>
    </tr>
    <tr>
      <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>す<br />
      </td>
      <td style={{verticalAlign: "top"}}>ずっこい／すこい&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>ずるい&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>sly&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.BasicGrammar.Adjectives.Conversations}>3.4.1</Link>, <Link to={Page.BasicGrammar.Adjectives.Grammar.KansaiAdjectives}>3.4.2.1</Link></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>すんません&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>すみません、すいません&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Excuse me,
Sorry&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.Expressions.GratitudeApology}>2.3</Link></td>
    </tr>
    <tr>
      <td colSpan="1" rowSpan="6" style={{verticalAlign: "top"}}>せ<br />
      </td>
      <td style={{verticalAlign: "top"}}>せこい&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>けちな&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>tight in money&nbsp;&nbsp;&nbsp;
      <br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.BasicGrammar.Adjectives.Conversations}>3.4.1</Link>, <Link to={Page.BasicGrammar.Adjectives.Grammar.KansaiAdjectives}>3.4.2.1</Link></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>せや&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>そうだ&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>It is so&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.Expressions.OtherExpressions}>2.4</Link>, <Link to={Page.BasicGrammar.Copula.Grammar.Houkaa}>3.3.2.2</Link></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>せやかて&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>でも、だって&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>but, and yet,
still&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.FunctionalGrammar.Even.Grammar.EvenIf}>4.5.2.2</Link></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>せやけど&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>けど、でも&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>but, however&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.Expressions.OtherExpressions}>2.4</Link></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>せやし／せやから／せやさかい&nbsp;&nbsp;&nbsp;
      <br />
      </td>
      <td style={{verticalAlign: "top"}}>だから&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>therefore&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.Expressions.OtherExpressions}>2.4</Link></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>{addRubyText("忙","せわ")}しない&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>{addRubyText("落","お")}ち{addRubyText("着","つ")}かない&nbsp;&nbsp;&nbsp;
      <br />
      </td>
      <td style={{verticalAlign: "top"}}>restless, hurry,
fidgety&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.BasicGrammar.Adjectives.Grammar.KansaiAdjectives}>3.4.2.1</Link></td>
    </tr>
    <tr>
      <td colSpan="1" rowSpan="6" style={{verticalAlign: "top"}}>そ<br />
      </td>
      <td style={{verticalAlign: "top"}}>そや&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>そうだ&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>It is so&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.Expressions.OtherExpressions}>2.4</Link>, <Link to={Page.BasicGrammar.Copula.Grammar.Houkaa}>3.3.2.2</Link></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>そやかて／そうかて&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>でも、だって<br />
      </td>
      <td style={{verticalAlign: "top"}}>but, and yet,
still<br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.FunctionalGrammar.Even.Grammar.EvenIf}>4.5.2.2</Link></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>そやけど／そんでも&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>けど、でも<br />
      </td>
      <td style={{verticalAlign: "top"}}>but, however<br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.Expressions.OtherExpressions}>2.4</Link></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>そやし／そやから／そやさかい&nbsp;&nbsp;&nbsp;
      <br />
      </td>
      <td style={{verticalAlign: "top"}}>だから&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>therefore&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.Expressions.OtherExpressions}>2.4</Link></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>そんなら&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>じゃ、じゃあ&nbsp;&nbsp;&nbsp; </td>
      <td style={{verticalAlign: "top"}}>well then&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.Expressions.OtherExpressions}>2.4</Link></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>そうでっか<br />
      </td>
      <td style={{verticalAlign: "top"}}>そうですか<br />
      </td>
      <td style={{verticalAlign: "top"}}>I see, Is it so? (polite)<br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.TalkInTown.Peddler}>5.4</Link><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>た<br />
      </td>
      <td style={{verticalAlign: "top"}}>{addRubyText("炊","た")}く&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>{addRubyText("煮","に")}る&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>cook, simmer, boil&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.FunctionalGrammar.Honorifics.Video.V10}>4.4.5.10</Link></td>
    </tr>
    <tr>
      <td colSpan="1" rowSpan="6" style={{verticalAlign: "top"}}>ち<br />
      </td>
      <td style={{verticalAlign: "top"}}>ちっちゃい&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>{addRubyText("小","ちい")}さい&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>small, tiny&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.BasicGrammar.Adjectives.Grammar.KansaiAdjectives}>3.4.2.1</Link></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>ちっちゃいちっちゃい</td>
      <td style={{verticalAlign: "top"}}>とても{addRubyText("小","ちい")}さな</td>
      <td style={{verticalAlign: "top"}}>very tiny<br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.TalkInTown.RawOyster}>5.7</Link><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>ちっとも-neg&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>{addRubyText("少","すこ")}しも-neg&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}> Not … even a
little&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.FunctionalGrammar.Even.Conversations}>4.5.1</Link></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>ちゃう&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>{addRubyText("違","ちが")}う&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>different&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.Expressions.OtherExpressions}>2.4</Link></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>ちょい&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>ちょっと&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>a little&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.FunctionalGrammar.Honorifics.Grammar.Pejoratives}>4.4.2.2</Link></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>ちょこっと&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>ちょっと&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>a little&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.FunctionalGrammar.PermissionProhibition.Conversations}>4.1.1</Link></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>て<br />
      </td>
      <td style={{verticalAlign: "top"}}>でかい<br />
      </td>
      <td style={{verticalAlign: "top"}}>すごく{addRubyText("大","おお")}きい<br />
      </td>
      <td style={{verticalAlign: "top"}}>huge (blunt)<br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.TalkInTown.GrilledOyster}>5.8</Link><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}><br />
      </td>
      <td style={{verticalAlign: "top"}}>でっしゃろ?<br />
      </td>
      <td style={{verticalAlign: "top"}}>でしょう?<br />
      </td>
      <td style={{verticalAlign: "top"}}>Isn't it? (polite)<br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.TalkInTown.ConfectionStore}>5.9</Link><br />
      </td>
    </tr>
    <tr>
      <td colSpan="1" rowSpan="5" style={{verticalAlign: "top"}}>と<br />
      </td>
      <td style={{verticalAlign: "top"}}>どない&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>どう&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>how&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.FunctionalGrammar.Honorifics.Conversations}>4.4.1</Link></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>ど（う）もない／ど（う）もあらへん&nbsp;&nbsp;
&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>{addRubyText("大丈夫","だいじょうぶ")}&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>I’m ok. /It's ok.&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.FunctionalGrammar.Honorifics.Conversations}>4.4.1</Link></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>どうもおへん<br />
      </td>
      <td style={{verticalAlign: "top"}}>{addRubyText("大丈夫","だいじょうぶ")}です</td>
      <td style={{verticalAlign: "top"}}>I'm /It's ok. (polite)<br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.TalkInTown.Peddler}>5.4</Link><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>どうもならん</td>
      <td style={{verticalAlign: "top"}}>どうにもならない </td>
      <td style={{verticalAlign: "top"}}>helpless, futile</td>
      <td style={{verticalAlign: "top"}}><Link to={Page.TalkInTown.Peddler}>5.4</Link><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>{addRubyText("鈍","どん")}くさい&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>とろい&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>slow&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.BasicGrammar.Adjectives.Grammar.KansaiAdjectives}>3.4.2.1</Link></td>
    </tr>
    <tr>
      <td colSpan="1" rowSpan="6" style={{verticalAlign: "top"}}>な<br />
      </td>
      <td style={{verticalAlign: "top"}}>{addRubyText("直","なお")}す&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>{addRubyText("戻","もど")}す&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>put back&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.Verbs.Conversations}>3.1a.1</Link></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>{addRubyText("難儀","なんぎ")}&nbsp;&nbsp;&nbsp; <br />
      <br />
      </td>
      <td style={{verticalAlign: "top"}}>{addRubyText("大変","たいへん")}、{addRubyText("困","こま")}った、{addRubyText("難","むずか")}しい
&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>troublesome, difficult,
hard&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.Expressions.OtherExpressions}>2.4</Link></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>なんで&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>どんして、なぜ&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Why&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.SentenceEndings.Conversations}>3.1b.1</Link></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>なんぼ&nbsp;&nbsp;&nbsp; <br />
      <br />
      </td>
      <td style={{verticalAlign: "top"}}>いくら&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>how much&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.Copula.Practice.P5}>3.3.3.5</Link>, <Link to={Page.FunctionalGrammar.Honorifics.Video.V12}>4.4.5.12</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜なんぼ&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>〜と、いくらか&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>~ and some more
(yen)&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.FunctionalGrammar.Honorifics.Video.V12}>4.4.5.12</Link></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>なんや／な〜んや&nbsp;&nbsp;&nbsp; <br />
      <br />
      </td>
      <td style={{verticalAlign: "top"}}>なんだ、な〜んだ&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Oh! Ah ha! That's what it is
(with slight disappointment)&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.Copula.Conversations}>3.3.1</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>ぬ<br />
      </td>
      <td style={{verticalAlign: "top"}}>ぬくい&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>{addRubyText("暖","あたた")}かい、{addRubyText("温","あたた")}かい&nbsp;&nbsp;
&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>warm&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.BasicGrammar.VerbTeForm.Conversations}>3.2.1</Link></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>は<br />
      </td>
      <td style={{verticalAlign: "top"}}>ばばっちい&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>{addRubyText("汚","きたな")}い&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>dirty, filthy&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.BasicGrammar.Adjectives.Grammar.KansaiAdjectives}>3.4.2.1</Link></td>
    </tr>
    <tr>
      <td colSpan="1" rowSpan="3" style={{verticalAlign: "top"}}>ひ<br />
      </td>
      <td style={{verticalAlign: "top"}}>ひち&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>{addRubyText("七","しち")} &nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>seven&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.BasicGrammar.Copula.Grammar.Houkaa}>3.3.2.2</Link></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>ひちや&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>{addRubyText("質屋","しちや")}&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>pawn shop&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.BasicGrammar.Copula.Grammar.Houkaa}>3.3.2.2</Link></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>びちょびちょ&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>びしょびしょ、びしょぬれ&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>dripping, heavily
wet&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.FunctionalGrammar.PermissionProhibition.Conversations}>4.1.1</Link></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>へ<br />
      </td>
      <td style={{verticalAlign: "top"}}>ぺけ&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>X（ばつ） &nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>X (false in
true-false)&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.Characteristics.LinguisticAspects.Words}>1.2.c</Link>
      </td>
    </tr>
    <tr>
      <td colSpan="1" rowSpan="9" style={{verticalAlign: "top"}}>ほ<br />
      </td>
      <td style={{verticalAlign: "top"}}>ほかす&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>{addRubyText("捨","す")}てる&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}> throw away,
discard&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.BasicGrammar.Verbs.Conversations}>3.1a.1</Link></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>ほて／ほんで&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>そして、それで&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>And then&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.Expressions.OtherExpressions}>2.4</Link>, <Link to={Page.Characteristics.LinguisticAspects.Sound}>1.2.a</Link></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>ほと&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>そうすると&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>With that being the
case&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.Expressions.OtherExpressions}>2.4</Link></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>ほな／ほなら／ほんなら／ほんだら &nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>じゃ、じゃあ&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Well then &nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.Expressions.LeaveTakings}>2.2</Link>, <Link to={Page.Expressions.OtherExpressions}>2.4</Link>, <Link to={Page.BasicGrammar.Copula.Grammar.Houkaa}>3.3.2.2</Link></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>ほや&nbsp;<br />
      </td>
      <td style={{verticalAlign: "top"}}>そうだ&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>It is so&nbsp;&nbsp;&nbsp;
&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.Characteristics.LinguisticAspects.Sound}>1.2.a</Link>,
      <Link to={Page.BasicGrammar.Copula.Grammar.Houkaa}>3.3.2.2</Link></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>ほやかて&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>でも、だって&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>but, and yet,
still&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.FunctionalGrammar.Even.Grammar.EvenIf}>4.5.2.2</Link></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>ほやけど／ほんでも&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>けど、でも&nbsp;&nbsp;&nbsp;&nbsp;<br />
      </td>
      <td style={{verticalAlign: "top"}}>but, however&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.Expressions.OtherExpressions}>2.4</Link></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>ほやし／ほやから／ほやさかい／ほやさけ&nbsp;&nbsp;
&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>だから&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>therefore&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.Expressions.OtherExpressions}>2.4</Link></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>ほんま&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>&nbsp;
{addRubyText("本当","ほんとう")}&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>really&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.Expressions.OtherExpressions}>2.4</Link></td>
    </tr>
    <tr>
      <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>ま<br />
      </td>
      <td style={{verticalAlign: "top"}}>マクド&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>マック&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>McDonald&nbsp;&nbsp; (fast food
chain) <br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.Characteristics.LinguisticAspects.Words}>1.2.c</Link></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>{addRubyText("間","ま")}なしに<br />
      </td>
      <td style={{verticalAlign: "top"}}>まもなく&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>not long after
..&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.FunctionalGrammar.Honorifics.Video.V8}>4.4.5.8</Link>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>み<br />
      </td>
      <td style={{verticalAlign: "top"}}>みみっちい&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>けちな&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>tight in money, mean,
stingy&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.BasicGrammar.Adjectives.Conversations}>3.4.1</Link></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>む<br />
      </td>
      <td style={{verticalAlign: "top"}}>むっちゃ／むちゃ<br />
      </td>
      <td style={{verticalAlign: "top"}}>すごく、とても<br />
      </td>
      <td style={{verticalAlign: "top"}}>very,
tremendously <br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.TalkInTown.GrilledOyster}>5.8</Link><br />
      </td>
    </tr>
    <tr>
      <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>め<br />
      </td>
      <td style={{verticalAlign: "top"}}>めっちゃ／めちゃ &nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>すごく 、とても&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>very,
tremendously&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.BasicGrammar.VerbTeForm.Conversations}>3.2.1</Link>, <Link to={Page.TalkInTown.RawOyster}>5.7</Link><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>めんどくさい&nbsp;&nbsp;&nbsp; <br />
      <br />
      </td>
      <td style={{verticalAlign: "top"}}>{addRubyText("面倒","めんどう")}な&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>troublesome,
tiresome&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.BasicGrammar.Adjectives.Conversations}>3.4.1</Link>, <Link to={Page.BasicGrammar.Adjectives.Grammar.KansaiAdjectives}>3.4.2.1</Link>, <Link to={Page.FunctionalGrammar.NegativePermissionObligation.Video.V6}>4.2.5.6</Link>
      </td>
    </tr>
    <tr>
      <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>や<br />
      </td>
      <td style={{verticalAlign: "top"}}>やっぱし<br />
      </td>
      <td style={{verticalAlign: "top"}}>やはり、やっぱり<br />
      </td>
      <td style={{verticalAlign: "top"}}>after all, as expected<br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.BasicGrammar.Verbs.Video.V3}>3.1a.5.3</Link><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>ややこしい&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>{addRubyText("複雑","ふくざつ")}な&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>complicated&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.BasicGrammar.Adjectives.Conversations}>3.4.1</Link>, <Link to={Page.BasicGrammar.Adjectives.Grammar.KansaiAdjectives}>3.4.2.1</Link></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}><br />
      </td>
      <td style={{verticalAlign: "top"}}>やらかい&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>{addRubyText("柔","やわら")}かい&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>soft&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.BasicGrammar.Adjectives.Conversations}>3.4.1</Link>, <Link to={Page.TalkInTown.Peddler}>5.4</Link>, <Link to={Page.TalkInTown.FishShopKyoto}>5.14</Link><br />
      </td>
    </tr>
    <tr>
      <td colSpan="1" rowSpan="4" style={{verticalAlign: "top"}}>よ<br />
      </td>
      <td style={{verticalAlign: "top"}}>よう&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>よく&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>well&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.BasicGrammar.Verbs.Grammar.YouNegative}>3.1a.2.4</Link>, <Link to={Page.BasicGrammar.VerbTeForm.Conversations}>3.2.1</Link></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>よう-neg&nbsp;&nbsp;&nbsp;
&nbsp;&nbsp;&nbsp; <br />
      <br />
      </td>
      <td style={{verticalAlign: "top"}}>よく -neg<br />
      </td>
      <td style={{verticalAlign: "top"}}>1) not ...well<br />
2) no ability of ~ing&nbsp;&nbsp; (Kansai only) <br />
      </td>
      <td style={{verticalAlign: "top"}}>
        <Link to={Page.FunctionalGrammar.Even.Video.V2}>4.5.5.2</Link><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>ようけ&nbsp;&nbsp;&nbsp; <br />
      <br />
      </td>
      <td style={{verticalAlign: "top"}}>たくさん&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>many, a lot&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.Characteristics.LinguisticAspects.Words}>1.2.c</Link>,
      <Link to={Page.FunctionalGrammar.Imperatives.Conversations}>4.3.1</Link>,
      <Link to={Page.FunctionalGrammar.Honorifics.Conversations}>4.4.1</Link></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜よってに&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>〜から、〜ので&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>conjunctives
“Because,,,”&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.Expressions.OtherExpressions}>2.4</Link></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>わ<br />
      </td>
      <td style={{verticalAlign: "top"}}>われ&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>おまえ&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>you (very blunt,
rude)&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}><Link to={Page.FunctionalGrammar.Even.Conversations}>4.5.1</Link></td>
    </tr>
  </tbody>
</table>
  </>;
}

export default Glossary;