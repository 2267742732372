import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";

const V1 = () => {
  const transcript = <>
    {addRubyText("関西弁","かんさい")}は<span style={{textDecoration: "underline"}}>うまいこと</span>(Gramar
    3 Extra 1){addRubyText("発音","はつおん")}できたかな〜。{addRubyText("関西弁","かんさいべん")}{addRubyText("下手","へた")}なん
    ですよ。
  </>;

  const qaObjects = [
    {
      question: <>a) {addRubyText("関西弁","かんさいべん")}が{addRubyText("上手","じょうず")}に{addRubyText("発音","はつおん")}できてうれしい。</>,
      answersCorrect: [
        <>FALSE</>
      ],
      answersIncorrect: [
        <>TRUE</>
      ]
    },
    {
      question: <>b) {addRubyText("関西弁","かんさいべん")}がうまく{addRubyText("言","い")}えたかどうか{addRubyText("心配","しんぱい")}だ。 </>,
      answersCorrect: [
        <>TRUE</>
      ],
      answersIncorrect: [
        <>FALSE</>
      ]
    }
  ]

  return <>
    <h2>ビデオ{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}１: Authentic - short<br />
    </h2>
    <span style={{fontWeight: "bold"}}></span>Figure out if
    each statement is TRUE or FALSE. Submit your answers after finishing
    all the questions.<br />
    <small>*Click "SHOW" if you need to refer to the transcripts.</small><br />
    <br />
    <div className="checkupquestion">
      <big>This is a clip after the interview with the person is over. </big>
      <br />
      <table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2" cellSpacing="2">
        <tbody>
          <tr>
            <td style={{verticalAlign: "top"}}>
              {addVideoPlayer("3.4.pl0.2.mp4")}
            </td>
            <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>Transcript
              <Hideable content={transcript} />
              <br />
              ＊{addRubyText("発音","はつおん")} pronunciation
            </td>
          </tr>
          <tr>
            <td style={{verticalAlign: "top"}}>
              ウチダさん (Shiga)
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <QandAForm qaObjects={qaObjects} />
    </div>
  </>;
}

export default V1;
