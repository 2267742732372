import React, { useState } from "react";

export const ShowHideableContents = (props) => {
  const [hidden, setHidden] = useState(true);
  if (hidden === true) {
    return <div onClick={() => setHidden(false)}>
      SHOW
    </div>;
  } else {
    return <div onClick={() => setHidden(true)}>
      {props}
    </div>;
  }
}

export const ShowHideableTranscript = (props) => {
  const title = props.title;
  const transcript = props.transcript;

  const renderLine = (line) => {
    return <li>
      <small>
        {line}
      </small>
    </li>
  }

  return <>
    {title}
    {ShowHideableContents(
      <ol>
        {transcript.map(renderLine)}
      </ol>
    )}
  </>
}