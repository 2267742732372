import React from "react";
import { Link } from "react-router-dom";
import { addRubyText, addImage } from "common/util/generalUtil";
import { Page } from "home/page";
import "css/common.scss";

const Characteristics = () => {
  return (
    <>
      <h2>Chapter 1: Characteristics ({addRubyText("特徴", "とくちょう")})<br /></h2>
      <h3>「{addRubyText("関西弁", "かんさいべん")}」って？ : Kansai-ben at a Glance<br /></h3>
      <ol>
        <li>
          <Link to={Page.Characteristics.StandardVsKansai}>Standard VS Kansai-ben<br /></Link>
        </li>
        <li>
          <Link to={Page.Characteristics.HowKansaibenSoundsLike}>How Kansai-ben sounds like</Link>
        </li>
        <li>
          <Link to={Page.Characteristics.LinguisticAspects.LinguisticAspects}>Linguistic Aspects of Kansai Dialect</Link>
        </li>
      </ol>
      <br />

      <table className="custom-table">
        <tbody>
        <tr>
          <td>
            {addImage("todaiji.jpg")}
          </td>
        </tr>
        <tr>
          <td>
            <small>東大寺 Todai-ji temple (Nara)</small>
          </td>
        </tr>
        </tbody>
      </table>
    </>
  )
};

export default Characteristics;