import React from "react";

const References = () => {
  const addWebContent = (webName: string, webLink: string) => (
    <>
      <div>{webName}</div>
      <a href={webLink}>{webLink}</a>
    </>
  );

  return (
    <>
      <h2>References</h2>
      <h3>Publications</h3>
      <ul>
        <li>
          Fudano, K. (2006) <i>Osaka-ben Honmamon Kooza</i>, Tokyo: Shinchosha
        </li>
        <li>
          Gumperz, J. J. (1982) Discourse Strategies, NY: Cambridge University Press
        </li>
        <li>
          Hirayama, T. (1992) <i>Gendai nihongo hoogen daijiten 1</i>. Tokyo: Meiji Shoin
        </li>
        <li>
          Kawauchi, A. (1993) <i>Kansai-ben Tanken</i>, Osaka: Toho
        </li>
        <li>
          Jorden, E. with Noda, M. (1987) Japanese: The Spoken Language Part 1, New Haven and London: Yale University Press
        </li>
        <li>
          Jorden, E. with Noda, M. (1988) Japanese: The Spoken Language Part 2, New Haven and London: Yale University Press
        </li>
        <li>
          Jorden, E. with Noda, M. (1990) Japanese: The Spoken Language Part 3, New Haven and London: Yale University Press
        </li>
        <li>
          Nihon Haqkugaku Kurabu (2000) <i>Kanto to Kansai Konnani Chigau Jiten</i>, Tokyo: PHP
        </li>
        <li>
          Okamoto, M. et al (1998) <i>Kiite oboeru Kansai-ben nyuumon</i>, Tokyo: ALC press
        </li>
        <li>
          Onoe, S. (1999) <i>Osaka Kotoba-gaku</i>, Osaka: Sogensha
        </li>
        <li>
          Sanada, S. (1987) <i>Gengo seikatsu</i>, 429
        </li>
        <li>
          Sanada, S. et al (1992) <i>Shakai gengo-gaku</i>, Tokyo: Ohfu
        </li>
        <li>
          Sanada, S. (1996) <i>Chiiki-go no Dainamizumu Kansai</i>, Tokyo: Ohfu
        </li>
        <li>
          Sanada, S. and Shoji, H. (2005) <i>Nihon no Tagengo Shakai</i>. Tokyo: Iwanami
        </li>
        <li>
          Shingu, I. (1997) Dynamics of code-switching behavior in the context of the Kansai dialect and the standard variety in
          Japanese, Master’s thesis, University of Massachusetts at Amherst
        </li>
        <li>
          Tohsaku, Y. (1999) Yookoso! An Invitation to Comtemporary Japanese: Second Edition. Mcgraw-Hill
        </li>
        <li>
          Tohsaku, Y. (1999) Yookoso! Continuing with Comtemporary Japanese: Second Edition. Mcgraw-Hill
        </li>
        <li>
          Tsujimura, N. (1996) An Introduction to Japanese Linguistics, Oxford: Blackwell
        </li>
        <li>
          Umegaki, M. (1967) <i>Seibu hoogen gaisetsu</i>, In M, Tojo (ed.)
          <i>Hoogen-gaku kooza 3, Seibu hoogen</i>. Tokyo: Tokyodo
        </li>
        <li>
          Yamashita, Y. (2004) <i>Kansai-ben Koogi</i>, Tokyo: Kodansha
        </li>
      </ul>
      <br />

      <h3>Websites</h3>
      <ul>
        <li>
          {addWebContent(
            "全国大阪弁普及協会 (The National Association for the Promotion of the Osaka Dialect)",
            "https://web.archive.org/web/20150411074114/http://www.osakaben.jp/"
          )}
        </li>
        <li>
          {addWebContent(
            "Nihongo Resources",
            "http://www.nihongoresources.com/language/dialects/kansaiben.html"
          )}
        </li>
        <li>
          {addWebContent(
            "京言葉 (Kyoto Dialect)",
            "http://www.akenotsuki.com/kyookotoba/"
          )}
        </li>
      </ul>
    </>
  );
};

export default References;