import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";

const EelShop = () => {
  const vocabList = <ul style={{display: "block", overflow: "visible"}}>
    <li>
      うなぎ eel
    </li>
    <li>
      {addRubyText("太","ふと")}る become fat
    </li>
    <li>
      たれ sauce
    </li>
    <li>
      あゆ River sweetfish
    </li>
    <li>
      {addRubyText("追","お")}い{addRubyText("足","た")}し adding the loss, filling up
    </li>
  </ul>;

  const qaObjects = [
    {
      question: <span>Q1: What did ミツエさん mention about うなぎ?</span>,
      answersIncorrect: [
        <span>You get fat if you eat too much eel, but it tastes good!</span>,
        <span>I only buy tasty eels, not fat ones.</span>
      ],
      answersCorrect: [
        <span>Fat eels are tasty.</span>
      ]
    },
    {
      question: <span>Q2: How many packages of eel sauce did she get?</span>,
      answersIncorrect: [
        <span>2</span>,
        <span>3</span>
      ],
      answersCorrect: [
        <span>4</span>
      ]
    },
    {
      question: <span>Q3: How many あゆ did she buy?</span>,
      answersIncorrect: [
        <span>4</span>,
        <span>a lot</span>
      ],
      answersCorrect: [
        <span>6</span>
      ]
    },
    {
      question: <span>Q4: How long has have they been cooking eel sauce in the same pan? And how long has the eel shop been operating?</span>,
      answersIncorrect: [
        <span>Sauce - 35 years, shop – 300 years</span>,
        <span>Both the sauce and the shop are 300 yeas old</span>,
        <span>Both the sauce and the shop are 35 years old</span>
      ],
      answersCorrect: [
        <span>Sauce - 300 years, shop – 35 years</span>
      ]
    }
  ];

  return (
    <>
      <h2>{addRubyText("鰻","うなぎ")} (Eel at an eel shop in Otsu, Shiga)</h2>
      <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>
            [Context] ミツエさん is in a local eel shop near the shopping street.
          </td>
        </tr>
        </tbody>
      </table>
      <br />
      <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addVideoPlayer("5.3.mp4")}<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            Vocabulary List <br />
            <Hideable content={vocabList} />
          </td>
        </tr>
        </tbody>
      </table>
      <h3>{addRubyText("内容質問","ないようしつもん")} (Content Questions)</h3>
      <QandAForm qaObjects={qaObjects} />
    </>
  );
};

export default EelShop;