import React from "react";
import { Link } from "react-router-dom";
import { Page } from "home/page";
import { addRubyText, addAudio } from "common/util/generalUtil";

const Unless = () => {
  return <>
  <h2>2. Vな/Vんと “Unless…”</h2>
<h3>Summary of “Unless”</h3>
<table style={{textAlign: "left", width: "80%"}} border="1"cellPadding="2"
 cellSpacing="2">
  <tbody>
    <tr>
      <td style={{verticalAlign: "top", textAlign: "center"}}>Standard<br />
      </td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>Kansai<br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>V(negative stem) なくては（〜なくちゃ）<br />
V(negative stem) なければ（〜なきゃ）<br />
V(negative)と<br />
      </td>
      <td style={{verticalAlign: "top"}}>V(negative stem) + な<br />
V (short negative) + と<br />
      <br />
      </td>
    </tr>
  </tbody>
</table>
<br />
There are two ways to express “unless ~”&nbsp; in Kansai, V(negative
stem) + な and V (short negative) + と . (Remember that whether in Kansai
or in Standard,&nbsp; these patterns regularly occur only when the
clause that follows is negative – if not in form, at least in
connotation!)<br />
<br />
Vなければー＞V(negative stem) +な (pronounced with low pitch)
{addRubyText("食","た")}べな、{addRubyText("出","で")}な、{addRubyText("飲","の")}まな、{addRubyText("帰","かえ")}らな、{addRubyText("買","か")}わな、しな／せな、{addRubyText("来","こ")}な {addAudio("4.2.2.1.mp3")}<br />
<br />
はよ出な、{addRubyText("電車","でんしゃ")}に{addRubyText("間","ま")}に{addRubyText("合","あ")}わへんで。{addAudio("4.2.2.2.mp3")}<br />
<small>(ST&nbsp; {addRubyText("早","はや")}く出なきゃ、電車に間に合わないよ。）</small><small>{addAudio("4.2.2.2.s.mp3")}</small><br />
<br />
Vないとー＞Vん (V short negative) + と (pronounced with high pitch)
食べんと、出んと、飲まんと、帰らんと、買わんと、しんと／せんと、{addRubyText("来","こ")}んと {addAudio("4.2.2.3.mp3")}<br />
<br />
{addRubyText("英語","えいご")}も{addRubyText("長","なが")}いことしゃべらんと、{addRubyText("忘","わす")}れるもんやなあ。{addAudio("4.2.2.4.mp3")}<br />
<small>(ST 英語も長い間しゃべらないと、忘れるもんだねえ。) </small><small>{addAudio("4.2.2.4.s.mp3")}</small><br />
<small><br />
</small>When it comes to noun + copula and adjectives, either と/たら
conditional form is used for this purpose.<br />
{addRubyText("明日","あした")}スキーやし、{addRubyText("雪","ゆき")}やないと／雪ちゃうと{addRubyText("困","こま")}るねん。{addAudio("4.2.2.5.mp3")}<br />
<small>(ST 明日はスキーだから、雪じゃなくちゃ困るの。)</small><small>{addAudio("4.2.2.5.s.mp3")}</small><br />
<br />
{addRubyText("新","あたら")}し{addRubyText("家","うち")}は、もうちょっと{addRubyText("安","やす")}ないと買われへんわ。（買えへんわ in Kyoto) {addAudio("4.2.2.6.mp3")}<br />
<small>(ST 新しい家は、もうちょっと安くなくちゃ買えないよ。)</small><small>{addAudio("4.2.2.6.s.mp3")}</small><br />
<br />
<hr />
<h3>Extra 1:&nbsp; FOUR possible meanings of Vんと <br />
</h3>
When you hear the Vん (V
short negative) + と expression in Kansai, there are four possibilities
in meaning – (1)“Without ~ing”, (2) “Unless…”, (3) command, or (4)
negative command! To distinguish those, rely on the accent pattern as
well as the context.<br />
<br />
&nbsp;(1) “Without ~ing” (Negative-てform&nbsp; =Vないで) <br />
<span style={{fontWeight: "bold"}}>{addRubyText("勉強","べんきょう")}せ</span>んと{addRubyText("寝","ね")}てしもた。(と：low
pitch) {addAudio("4.2.2.7.mp3")}<br />
<small>（ST 勉強しないで寝ちゃった。）</small><small>{addAudio("4.2.2.7.s.mp3")}</small><br />
<br />
(2) “Unless….” （=Vなくては、Vなければ、Vないと)<br />
<span style={{fontWeight: "bold"}}>勉強せんと</span>{addRubyText("漢字","かんじ")}も{addRubyText("忘","わす")}れるもんやな。（と：
high pitch) {addAudio("4.2.2.8.mp3")}<br />
<small>（ST 勉強しなくちゃ漢字も忘れるものだね。）</small><small>{addAudio("4.2.2.8.s.mp3")}</small><br />
<br />
(3) Command (when used at the sentence ending) <br />
いつまで{addRubyText("起","お")}きてんの。はよ<span style={{fontWeight: "bold"}}>寝んと。</span>（と：high
pitch) {addAudio("4.2.2.9.mp3")}<br />
<small>（ST いつまで起きてるの？{addRubyText("早","はや")}く寝なさい。）</small><small>{addAudio("4.2.2.9.s.mp3")}</small><br />
<br />
(4) Negative Command ( refer to Extra 3 in Lesson 1 of Chapter 4)<br />
こたつで<span style={{fontWeight: "bold"}}>寝</span>んと！{addRubyText("風邪引","かぜひ")}くで！（と：low
pitch) {addAudio("4.2.2.10.mp3")}<br />
<small>（ST こたつで寝ないで！風邪引くよ。）</small> <small>{addAudio("4.2.2.10.s.mp3")}</small><br />
<br />
You probably realized that there are two groups of Nんと : those
pronounced with high pitch as in (1) and (4) mean “without~ (do
something else),” and those pronounced with low pitch as in (2) and (3)
mean “unless (you do it, something bad will happen).”<br />
<br />
Please refer to <Link to={Page.BasicGrammar.VerbTeForm.Grammar.NegativeRequest}>
Grammar 5 in Ch3 Lesson 2
</Link> for more explanations on (1).
<br />
<br />
<hr />
<h3>Extra 2: Vんことには</h3>
Vんことには is the same as Vんと that means “unless…” and is used mostly by
the older generation.<br />
<br />
{addRubyText("外国語","がいこくご")}は、やっぱし外国に{addRubyText("行","い")}かんことにはしゃべれるようにはならへんわ。{addAudio("4.2.2.11.mp3")}<br />
<small>(ST 外国語は、やっぱり外国に行かなくちゃしゃべれるようにはならないよ。)</small><small>{addAudio("4.2.2.11.s.mp3")}</small>
  </>;
}

export default Unless;
