import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";

const Strawberry = () => {
  const vocabList = <ul style={{display: "block", overflow: "visible"}}>
    <li>
      いちご strawberry
    </li>
    <li>
      {addRubyText("種類","しゅるい")}kind
    </li>
    <li>
      {addRubyText("日保","ひも")}ちする last long, preserve
    </li>
    <li>
      {addRubyText("小粒","こつぶ")}small grain, small pieces &lt;-&gt;{addRubyText("大粒","おおつぶ")}
    </li>
    <li>
      {addRubyText("潰","つぶ")}す squash
    </li>
    <li>
      {addRubyText("切","き")}らんでもいい (Kansai - refer to Ch4. L4. G1) you don’t have to cut it
    </li>
    <li>
      ごろごろ chunky
    </li>
  </ul>;

  const qaObjects = [
    {
      question: <span>Q1: What are the difference between the strawberries the shop
              has, according to the sales lady? （*brand name of each strawberry: ほのか-
              front left, {addRubyText("紅","べに")}ほっぺ – front right, ゆめのか – far back)</span>,
      answersIncorrect: [
        <span>紅ほっぺ=picked this morning, good to eat right away, ほのか and ゆめのか = last long.</span>,
        <span>ゆめのか=picked this morning, good to eat right away, ほのか and 紅ほっぺ = last long.</span>
      ],
      answersCorrect: [
        <span>ほのか=picked this morning, good to eat right away, 紅ほっぺ and ゆめのか = last long.</span>
      ]
    },
    {
      question: <span>Q2: Which size of 紅ほっぺ berries suit for jam making? Why?</span>,
      answersIncorrect: [
        <span>Large berries – People enjoy the large whole berries in the jam.</span>,
        <span>Small berries - It doesn’t take much time to cut berries when making jam.</span>,
        <span>Large berries – It’s fun to take time to cut berries when making jam.</span>
      ],
      answersCorrect: [
        <span>Small berries – you don’t have to cut them when making jam.</span>
      ]
    },
    {
      question: <span>Q3: Which strawberries did トモちゃん decide to buy?</span>,
      answersIncorrect: [
        <span>ほのか.</span>,
        <span>ゆめのか.</span>
      ],
      answersCorrect: [
        <span>紅ほっぺ.</span>
      ]
    }
  ];

  return (
    <>
      <h2>いちご (Strawberries at a vegetable and fruit shop in Kyoto)</h2>
      <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>
            [Context] トモちゃん is at a vegetable and fruit shop in Kyoto.
          </td>
        </tr>
        </tbody>
      </table>
      <br />
      <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addVideoPlayer("5.12.ichigo.mp4")}<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            Vocabulary List <br />
            <Hideable content={vocabList} />
          </td>
        </tr>
        </tbody>
      </table>
      <h3>{addRubyText("内容質問","ないようしつもん")} (Content Questions)</h3>
      <QandAForm qaObjects={qaObjects} />
    </>
  );
};

export default Strawberry;
