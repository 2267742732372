import React from "react";
import { Link } from "react-router-dom";
import { Page } from "home/page";
import { addRubyText, addImage } from "common/util/generalUtil";

const Expressions = () => {
  return (
    <>
      <h2>Chapter 2: Expressions ({addRubyText("表現","ひょうげん")})</h2>
      <h3>
        〜{addRubyText("挨拶","あいさつ")}とよく{addRubyText("使","つか")}う{
        addRubyText("言葉","ことば")}：Greetings and useful expressions 〜
      </h3>
      <table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td colSpan="1" rowSpan="1" style={{verticalAlign: "top"}}>
            <ol>
              <li>
                <Link to={Page.Expressions.Greetings}>Greetings</Link>
              </li>
              <li>
                <Link to={Page.Expressions.LeaveTakings}>Leave-takings</Link>
              </li>
              <li>
                <Link to={Page.Expressions.GratitudeApology}>Gratitude and Apology</Link>
              </li>
              <li>
                <Link to={Page.Expressions.OtherExpressions}>Other Useful Expressions</Link>
              </li>
            </ol>
          </td>
          <td colSpan="1" rowSpan="1" style={{verticalAlign: "top", textAlign: "center"}}>
            {addImage("sanjoohashi.jpg")}<br />
            <small>三条大橋 Sanjo Bridge (Kyoto)</small><br />
          </td>
        </tr>
        </tbody>
      </table>
    </>
  );
};

export default Expressions;
