import React from "react";
import { Link } from "react-router-dom";
import { Page } from "home/page";
import { addRubyText, addImage } from "common/util/generalUtil";

const Verbs = () => {
  return <>
    <h2>Lesson 1a :{addRubyText("動詞","どうし")} (Verbs)</h2>
    <h3>〜{addRubyText("買","か")}い{addRubyText("物","もん")}{addRubyText("行","い")}かへん？ 〜</h3>
    <table style={{textAlign: "left", width: "100%"}} cellSpacing="2" cellPadding="2" border="0">
      <tbody>
        <tr>
          <td colSpan="1" rowSpan="1" style={{verticalAlign: "top"}}>
            <br />
            <ul>
              <li>
                <Link to={Page.BasicGrammar.Verbs.Conversations}>{addRubyText("基本会話","きほんかいわ")} (Core Conversations)</Link>
              </li>
              <li>
                <Link to={Page.BasicGrammar.Verbs.Grammar.Grammar}>{addRubyText("文法","ぶんぽう")}ノート (Grammar Note)</Link>
              </li>
              <li>
                <Link to={Page.BasicGrammar.Verbs.Practice.Practice}>{addRubyText("練習","れんしゅう")} (Practice)</Link>
              </li>
              <li>
                <Link to={Page.BasicGrammar.Verbs.Listening.Listening}>{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")} (Listening Practice)</Link>
              </li>
              <li>
                <Link to={Page.BasicGrammar.Verbs.Video.Video}>ビデオ{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")} (Video Comprehension Practice)</Link>
              </li>
            </ul>
          </td>
          <td colSpan="1" rowSpan="1" style={{verticalAlign: "top", textAlign: "center"}}>
            {addImage("kanidooraku.jpg")}
            <br />
            <small>
              かに道楽 Kani-Dooraku Restaurant (Osaka)
            </small>
            <br />
          </td>
        </tr>
        <tr align="center"></tr>
      </tbody>
    </table>
    <br />
    <br />
  </>;
}

export default Verbs;
