import React from "react";
import { addRubyText, addAudio } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";

const P3 = () => {
  const transcriptST = <ol className="Transcript">
    <li>A: パーティー、行った？</li>
    <li>B: パーティー？ううん、行かなかった。</li>
  </ol>;
  return <>
    <h2>{addRubyText("練習","れんしゅう")}3: Past Negative Formation</h2>
    Form a question and an answer for each cue according to
    a model. You may listen to the question, try to answer it, and then
    check your answer. *Answers vary; the recorded answers indicate those
    commonly
    used.<br />
    <small>*Move the mouse over to the speaker icon for sound<br />
    <br />
    </small><span style={{fontWeight: "bold"}}>Model</span> (Cue:パーティー／{addRubyText("行","い")}く）<br />
    &nbsp;&nbsp;&nbsp; A: パーティー、行った？<br />
    &nbsp;&nbsp;&nbsp; B: パーティー？ううん、行かへんかった。{addAudio("3.1.a.p.3.mp3")}<br />
    <br />
    ST transcript 
    <br />
    <Hideable content={transcriptST}/>
    <br />
    <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>Cue<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>A (Question)<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>B (Answer)<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>パーティー／{addRubyText("行","い")}く<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.1.a.p.3.1.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.1.a.p.3.1.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>すし／{addRubyText("食","た")}べる<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.1.a.p.3.2.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.1.a.p.3.2.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>テスト／できる<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.1.a.p.3.3.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.1.a.p.3.3.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>あの{addRubyText("映画","えいが")}／{addRubyText("見","み")}る<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.1.a.p.3.4.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.1.a.p.3.4.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>{addRubyText("友達","ともだち")}／いる<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.1.a.p.3.5.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.1.a.p.3.5.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>ニュース／{addRubyText("聞","き")}く<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.1.a.p.3.6.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.1.a.p.3.6.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>ビール／{addRubyText("飲","の")}む<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.1.a.p.3.7.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.1.a.p.3.7.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>{addRubyText("友達","ともだち")}に／{addRubyText("言","い／ゆ")}う<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.1.a.p.3.8.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.1.a.p.3.8.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>お{addRubyText("風呂","ふろ")}（に）／{addRubyText("入","はい")}る</td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.1.a.p.3.9.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.1.a.p.3.9.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>{addRubyText("雑誌","ざっし")}／{addRubyText("買","か")}う</td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.1.a.p.3.10.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.1.a.p.3.10.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>プレゼント／もらう</td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.1.a.p.3.11.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.1.a.p.3.11.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>このコンピューター／{addRubyText("使","つか")}う</td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.1.a.p.3.12.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.1.a.p.3.12.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>{addRubyText("宿題","しゅくだい")}／する<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.1.a.p.3.13.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.1.a.p.3.13.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>{addRubyText("彼氏","かれし")}／{addRubyText("来","く")}る<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.1.a.p.3.14.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.1.a.p.3.14.b.mp3")}<br />
          </td>
        </tr>
      </tbody>
    </table>
  </>;
}

export default P3;
