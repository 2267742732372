import React from "react";
import { Link } from "react-router-dom";
import { Page } from "home/page";
import { addRubyText, addImage } from "common/util/generalUtil";

const BasicGrammar = () => {
  return <>
    <h2>Chapter 3: Basic Kansai Grammar ({addRubyText("基本関西文法","きほんかんさいぶんぽう")})</h2>
    <table style={{textAlign: "left", width: "100%"}} cellSpacing="2" cellPadding="2" border="0">
      <tbody>
        <tr>
          <td colSpan="1" rowSpan="1" style={{verticalAlign: "top"}}>
            <br />
            <ul>
              <li>
                <Link to={Page.BasicGrammar.Verbs.Verbs}>Lesson 1a: {addRubyText("動詞","どうし")} (Verbs)</Link>
              </li>
              <li>
                <Link to={Page.BasicGrammar.SentenceEndings.SentenceEndings}>Lesson 1b: {addRubyText("文末表現","ぶんまつひょうげん")} (Sentence Endings)</Link>
              </li>
              <li>
                <Link to={Page.BasicGrammar.VerbTeForm.VerbTeForm}>Lesson 2:{addRubyText("動詞","どうし")}のて{addRubyText("形","けい")} (Verb TE-form)</Link>
              </li>
              <li>
                <Link to={Page.BasicGrammar.Copula.Copula}>Lesson 3: Copula</Link>
              </li>
              <li>
                <Link to={Page.BasicGrammar.Adjectives.Adjectives}>Lesson 4: {addRubyText("形容詞","けいようし")} (Adjectives)</Link>
              </li>
            </ul>
          </td>
          <td colSpan="1" rowSpan="1" style={{verticalAlign: "top", textAlign: "center"}}>
            {addImage("biwako.jpg")}<br />
            <small>琵琶湖 Lake Biwa (Shiga)</small><br />
          </td>
        </tr>
        <tr align="center"></tr>
      </tbody>
    </table>
    <br />
    <br />
  </>;
}

export default BasicGrammar;
