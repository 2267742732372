import React from "react";
import { addRubyText, addAudio } from "common/util/generalUtil";

const TeContraction = () => {
  return <>
    <h2>2. Verb て form contraction <br />
    </h2>
    As you see in relaxed speech in the standard Japanese, contractions
    frequently occur for commonly used sequences such as
    in{addRubyText("食","た")}べてる (食べている) and {addRubyText("待","ま")}ってて（待っていて). Similar patterns occur
    in Kansai-ben but some are not the same as in Standard. We will
    introduce some important contract forms in てform sequences, which are
    necessary to know for comprehensive purposes.<br />
    <small><br />
    *Mouse over or click on the speaker icon for sound</small><br />
    <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>Original<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>Standard
    contraction<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>Kansai
    contraction<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>Kansai
    Examples<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>Vt ている<br />
    〜ていない<br />
          </td>
          <td style={{verticalAlign: "top"}}>〜てる<br />
    〜てない<br />
          </td>
          <td style={{verticalAlign: "top"}}>〜てる／とる<br />
    〜てへん<br />
          </td>
          <td style={{verticalAlign: "top"}}>ここで{addRubyText("待","ま")}ってるわ。{addAudio("3.2.2.1.mp3")}<br />
          <small>(ST ここで待ってるよ。）</small><small>{addAudio("3.2.2.1.s.mp3")}</small> </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>Vi ている<br />
    〜ていない<br />
          <br />
          </td>
          <td style={{verticalAlign: "top"}}>〜てる<br />
    〜てない<br />
          </td>
          <td style={{verticalAlign: "top"}}>〜てる／とる／た（あ）る<br />
    〜てへん／とらへん／たらへん<br />
          </td>
          <td style={{verticalAlign: "top"}}>{addRubyText("電気","でんき")}、ついたったよ。<small></small>{addAudio("3.2.2.2.mp3")}<br />
          <small>(ST
    電気がついてたよ。）</small><small>{addAudio("3.2.2.2.s.mp3")}</small> </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>Vt てある<br />
    〜てない<br />
          </td>
          <td style={{verticalAlign: "top"}}>ー<br />
          </td>
          <td style={{verticalAlign: "top"}}>〜てる／た（あ）る<br />
    〜てへん／たらへん<br />
          </td>
          <td style={{verticalAlign: "top"}}>ペン、かばんに{addRubyText("入","い")}れたるで。<small></small>{addAudio("3.2.2.3.mp3")}<br />
          <small>(ST
    ペンはかばんに入れてあるよ。）</small><small>{addAudio("3.2.2.3.s.mp3")}</small> </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>〜ておく<br />
          </td>
          <td style={{verticalAlign: "top"}}>〜とく<br />
          </td>
          <td style={{verticalAlign: "top"}}>〜とく<br />
          </td>
          <td style={{verticalAlign: "top"}}>{addRubyText("窓","まど")}、{addRubyText("閉","し")}めといて。<small></small>{addAudio("3.2.2.4.mp3")}<br />
          <small>(ST
    窓、閉めといて。）</small><small>{addAudio("3.2.2.4.s.mp3")}</small> </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>〜てあげる<br />
          </td>
          <td style={{verticalAlign: "top"}}>〜たげる<br />
          </td>
          <td style={{verticalAlign: "top"}}>〜たげる／たる<br />
          </td>
          <td style={{verticalAlign: "top"}}>かばん、{addRubyText("持","も")}ったげるわ。<small></small>{addAudio("3.2.2.5.mp3")}<br />
          <small>(ST
    かばん、持ったげるよ。）</small><small>{addAudio("3.2.2.5.s.mp3")}</small> </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>〜てやる<br />
          </td>
          <td style={{verticalAlign: "top"}}>ー<br />
          </td>
          <td style={{verticalAlign: "top"}}>〜たる<br />
          </td>
          <td style={{verticalAlign: "top"}}>うちの{addRubyText("子","こ")}に{addRubyText("買","こ")}うたった。<small></small>{addAudio("3.2.2.6.mp3")}<br />
          <small>(ST
    うちの子に買ってやった。）</small><small>{addAudio("3.2.2.6.s.mp3")}</small> </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>〜てしまう<br />
    〜てしまった<br />
          </td>
          <td style={{verticalAlign: "top"}}>〜ちゃう<br />
    〜ちゃった<br />
          </td>
          <td style={{verticalAlign: "top"}}>〜てまう<br />
    〜てもた<br />
          <br />
          </td>
          <td style={{verticalAlign: "top"}}>ケーキ{addRubyText("全部","ぜんぶ")}食べてまうで。<small></small>{addAudio("3.2.2.7.mp3")}<br />
          <small>(ST
    ケーキ、全部食べちゃうよ。）</small><small>{addAudio("3.2.2.7.s.mp3")}</small><br />
    お{addRubyText("母","かあ")}さんのも食べてもた。<small></small>{addAudio("3.2.2.8.mp3")}<br />
          <small>(ST お母さんのも食べちゃった。）</small><small>{addAudio("3.2.2.8.s.mp3")}</small>
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>〜ておる<br />
    (Kansai Pejorative)<br />
          </td>
          <td style={{verticalAlign: "top"}}>ー<br />
          </td>
          <td style={{verticalAlign: "top"}}>〜とる<br />
          </td>
          <td style={{verticalAlign: "top"}}>{addRubyText("犬","いぬ")}、{addRubyText("今","いま")}{addRubyText("何","なに")}しとる？<small></small>{addAudio("3.2.2.9.mp3")}<br />
          <small>(ST
    犬、今何している／してる？）</small><small>{addAudio("3.2.2.9.s.mp3")}</small> </td>
        </tr>
      </tbody>
    </table>
  </>;
}

export default TeContraction;
