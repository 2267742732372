import React from "react";
import { addRubyText } from "common/util/generalUtil";
import { DraggableForm } from "common/util/draggableForm";


const L2 = () => {
  const draggableFormData1 = {
    prompts: {
      images: [
        "4.3.pl0.2.1.a.jpg",
        "4.3.pl0.2.1.b.jpg",
        "4.3.pl0.2.1.c.jpg",
        "4.3.pl0.2.1.d.jpg"
      ]
    },
    answers: {
      texts: [
        <>そんなとこ{addRubyText("座","すわ")}りなや。</>,
        <>{addRubyText("座","すわ")}らんとき。</>,
        <>あ、そこ{addRubyText("座","すわ")}んなよ。</>,

        <>はよ、ここ{addRubyText("座","すわ")}りや。</>,

        <>あそこ{addRubyText("座","すわ")}ろな。</>,

        <>ちょっとはよ{addRubyText("座","すわ")}らな。</>
      ],
      audioFiles: [
        "4.3.pl0.2.1.a.mp3",
        "4.3.pl0.2.1.b.mp3",
        "4.3.pl0.2.1.c.mp3",

        "4.3.pl0.2.1.d.mp3",

        "4.3.pl0.2.1.e.mp3",

        "4.3.pl0.2.1.f.mp3"
      ]
    },
    matchGraph: [
      new Set([0,1,2]),
      new Set([3]),
      new Set([4]),
      new Set([5])
    ]
  };

  const draggableFormData2 = {
    prompts: {
      images: [
        "4.3.pl0.2.2.a.jpg",
        "4.3.pl0.2.2.b.jpg",
        "4.3.pl0.2.2.c.jpg",
        "4.3.pl0.2.2.d.jpg"
      ]
    },
    answers: {
      texts: [
        <>そんなん{addRubyText("買","か")}いな。</>,
        <>{addRubyText("買","か")}わんとき。</>,

        <>それ{addRubyText("買","か")}いぃな。</>,

        <>あれ{addRubyText("買","か")}おな。</>,

        <>あれ{addRubyText("買","か")}わな！</>,
      ],  
      audioFiles: [
        "4.3.pl0.2.2.a.mp3",
        "4.3.pl0.2.2.b.mp3",

        "4.3.pl0.2.2.c.mp3",

        "4.3.pl0.2.2.d.mp3",

        "4.3.pl0.2.2.e.mp3"
      ]
    },
    matchGraph: [
      new Set([0,1]),
      new Set([2]),
      new Set([3]),
      new Set([4]) 
    ]
  };

  const draggableFormData3 = {
    prompts: {
      images: [
        "4.3.pl0.2.3.a.jpg",
        "4.3.pl0.2.3.b.jpg",
        "4.3.pl0.2.3.c.jpg",
        "4.3.pl0.2.3.d.jpg"
      ]
    },
    answers: {
      texts: [
        <>お{addRubyText("風呂","ふろ")}{addRubyText("入","はい")}りな。</>,
        <>お{addRubyText("風呂","ふろ")}{addRubyText("入","はい")}らんとき。</>,
        <>お{addRubyText("風呂","ふろ")}{addRubyText("入","はい")}んなよ。</>,

        <>お{addRubyText("風呂","ふろ")}{addRubyText("入","はい")}りぃな。</>,

        <>お{addRubyText("風呂","ふろ")}{addRubyText("入","はい")}ろな。</>,

        <>お{addRubyText("風呂","ふろ")}{addRubyText("入","はい")}らな。</>
      ],
      audioFiles: [
        "4.3.pl0.2.3.a.mp3",
        "4.3.pl0.2.3.b.mp3",
        "4.3.pl0.2.3.c.mp3",

        "4.3.pl0.2.3.d.mp3",

        "4.3.pl0.2.3.e.mp3",

        "4.3.pl0.2.3.f.mp3",
      ]
    },
    matchGraph: [
      new Set([0,1,2]),
      new Set([3]),
      new Set([4]),
      new Set([5])
    ]
  }

  return <>
    <h2>{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}２</h2>
    Listen to your host-mother’s request/suggestion, and drag and drop the
    audio onto the illustration that most appropriately describes what she
    wants/does not want you to do. If you get the right answer, you will
    hear "Right!!" and the audio will stick to the illustration. If it's
    wrong, the audio will bounce back to the original location. <small><big>You
    may drag and drop <span style={{textDecoration: "underline"}}>more than
    two requests/suggestions</span> onto one illustration.</big><br />
    *Click “SHOW” if you want to refer to the transcript. <br />
    <br />
    </small>
    <h4>(1)</h4>
    <DraggableForm prompts={draggableFormData1.prompts} answers={draggableFormData1.answers} matchGraph={draggableFormData1.matchGraph}/>
    <br />
    <h4>(2)</h4>
    <DraggableForm prompts={draggableFormData2.prompts} answers={draggableFormData2.answers} matchGraph={draggableFormData2.matchGraph}/>
    <br />
    <h3>(3)</h3>
    <DraggableForm prompts={draggableFormData3.prompts} answers={draggableFormData3.answers} matchGraph={draggableFormData3.matchGraph}/>
  </>;
}

export default L2;
