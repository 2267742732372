import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";

const V6 = () => {
  const transcript = <>
    もうちょっ、<span style={{textDecoration: "underline"}}>しっかりせえ</span>(Grammar
    1)って{addRubyText("言","ゆ")}うかなあ。もうちょっと{addRubyText("人","ひと")}のこと{addRubyText("考","かんが")}えたらどうか、とかねえ。うん。あまりにも{addRubyText("自分勝手","じぶんかって")}なことばっかやってんの{addRubyText("違","ちが")}う、ていうみたいな。}
  </>;

  const qaObjects = [
    {
      question: <span>Q: What does Kusaka-san would like to say to young people nowadays?</span>,
      answersCorrect: [
        <>He would tell them to brace themselves up so they can afford to care about others.</>
      ],
      answersIncorrect: [
        <>He would tell them to think about themselves first, not worrying about others too much.</>,
        <>He would tell them to pay attention to people rather than being materialistic. </>
      ]
    }
  ]

  return <>
    <h2>ビデオ{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}６: Authentic – short</h2>
    Watch the video clip and choose the answer that matches the content
    ofthe clip.
    <br />
    <small>*Click "SHOW" if you need to refer to the transcript.
    </small><br />
    <br />
    Kusaka-san was asked what he would say to young people nowadays. <br />
    <table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>{addVideoPlayer("4.3.1.p.4.kusaka.mp4")}</td>
          <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>Transcript
            <Hideable content={transcript}/>
            <br />
            ＊{addRubyText("自分勝手","じぶんかって")} selfish
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>&nbsp;クサカさん
    (Shiga)</td>
        </tr>
      </tbody>
    </table>
    <QandAForm qaObjects={qaObjects}/>
  </>;
}

export default V6;
