import React from "react";
import { Link } from "react-router-dom";
import { Page } from "home/page";
import { addRubyText, addAudio } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";

const NegativeImperatives = () => {
  return <>
<h2>2. Negative Imperatives “Don’t…!”</h2>
You already know that you can use either a negative request or
imperatives depending on the addressee and situation when you don’t
want someone to do a certain thing just as when you make a positive
request. Here is the list of negative requests and imperatives.<br />
(For negative requests, refer to 
<Link to={Page.BasicGrammar.VerbTeForm.Grammar.NegativeRequest}>
Grammar 5 in Lesson 2 of Ch3
</Link> for details)<br />
<h3><span style={{fontWeight: "bold"}}></span>Negative requests and
imperatives</h3>
<table style={{textAlign: "left", width: "100%"}} border="1"cellPadding="2"
 cellSpacing="2">
  <tbody>
    <tr>
      <td style={{verticalAlign: "top"}}><br />
      </td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>Standard<br />
      </td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>(example)<br />
      </td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>Kansai<br />
      </td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>(example)<br />
      </td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>(sound)<br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>Request (favor)<br />
      </td>
      <td style={{verticalAlign: "top"}}>〜ないでくれない？<br />
      </td>
      <td style={{verticalAlign: "top"}}>{addRubyText("飲","の")}まないでくれない？<br />
      </td>
      <td style={{verticalAlign: "top"}}>〜んといてくれへん？<br />
      </td>
      <td style={{verticalAlign: "top"}}>飲まんといて<span
 style={{fontWeight: "bold"}}>く</span>れへん？<br />
      </td>
      <td style={{verticalAlign: "top"}}>{addAudio("4.3.2.1.mp3")}<br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>Request</td>
      <td style={{verticalAlign: "top"}}>〜ないで（ね／よ）。</td>
      <td style={{verticalAlign: "top"}}>飲まないで。<br />
      </td>
      <td style={{verticalAlign: "top"}}>〜んといて（な／や）。<br />
      <br />
      </td>
      <td style={{verticalAlign: "top"}}>飲まんとい<span
 style={{fontWeight: "bold"}}>て</span>。<br />
飲まんといて<span style={{fontWeight: "bold"}}>な</span>。<br />
飲まんといて<span style={{fontWeight: "bold"}}>や</span>。</td>
      <td style={{verticalAlign: "top"}}>{addAudio("4.3.2.2.mp3")}</td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>Request (stronger)</td>
      <td style={{verticalAlign: "top"}}><br />
      </td>
      <td style={{verticalAlign: "top"}}><br />
      </td>
      <td style={{verticalAlign: "top"}}>〜んといてぇな／や。</td>
      <td style={{verticalAlign: "top"}}>飲まんとい<span
 style={{fontWeight: "bold"}}>て</span>ぇな。<br />
飲まんとい<span style={{fontWeight: "bold"}}>て</span>ぇや。</td>
      <td style={{verticalAlign: "top"}}>{addAudio("4.3.2.3.mp3")}</td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>Negative imperatives</td>
      <td style={{verticalAlign: "top"}}>V(dictionary form) +な</td>
      <td style={{verticalAlign: "top"}}>飲むな！<br />
      <br />
      <br />
      </td>
      <td style={{verticalAlign: "top"}}>V(Masu stem) + な／ないな(older
generation)</td>
      <td style={{verticalAlign: "top"}}>飲<span style={{fontWeight: "bold"}}>み</span>な！<br />
飲み<span style={{fontWeight: "bold"}}>な</span>いな！</td>
      <td style={{verticalAlign: "top"}}>{addAudio("4.3.2.4.mp3")}</td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>Negative command (softer)<br />
      </td>
      <td style={{verticalAlign: "top"}}><br />
      </td>
      <td style={{verticalAlign: "top"}}><br />
      </td>
      <td style={{verticalAlign: "top"}}>V(negative stem)んと。</td>
      <td style={{verticalAlign: "top"}}>飲<span style={{fontWeight: "bold"}}>ま</span>ん
と</td>
      <td style={{verticalAlign: "top"}}>{addAudio("4.3.2.5.mp3")}</td>
    </tr>
  </tbody>
</table>
<br />
Let’s examine the formation of negative imperatives more closely.<br />
<h3>Negative Imperatives</h3>
<table style={{textAlign: "left", width: "100%"}} border="1"cellPadding="2"
 cellSpacing="2">
  <tbody>
    <tr>
      <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}><br />
      </td>
      <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}><br />
      </td>
      <td style={{verticalAlign: "top"}}>Standard {addAudio("4.3.2.6.s.mp3")}&nbsp;
      <br />
      </td>
      <td style={{verticalAlign: "top"}}>Kansai {addAudio("4.3.2.6.mp3")}&nbsp;&nbsp;&nbsp; </td>
      <td style={{verticalAlign: "top"}}>Kansai (traditional) {addAudio("4.3.2.7.mp3")}</td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>V(dictionary form) + な&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>&nbsp; V(Masu-stem) +な<br />
(When ending with る, V(dic.)+んな is used too)&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>V(Mau stem) + ないな</td>
    </tr>
    <tr>
      <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>Ru-verb&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <br />
      </td>
      <td style={{verticalAlign: "top"}}>{addRubyText("食","た")}べる</td>
      <td style={{verticalAlign: "top"}}>食べるな&nbsp;&nbsp;&nbsp; </td>
      <td style={{verticalAlign: "top"}}>食<span style={{fontWeight: "bold"}}>べ</span>な&nbsp;&nbsp;&nbsp;
      </td>
      <td style={{verticalAlign: "top"}}>食べ<span
 style={{fontWeight: "bold"}}>な</span>いな</td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>{addRubyText("着","き")}る&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>着るな&nbsp;&nbsp;&nbsp;&nbsp; </td>
      <td style={{verticalAlign: "top"}}><span style={{fontWeight: "bold"}}>着</span>な
      <br />
      </td>
      <td style={{verticalAlign: "top"}}><span style={{fontWeight: "bold"}}>着
な</span>いな</td>
    </tr>
    <tr>
      <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>U-verb&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <br />
      </td>
      <td style={{verticalAlign: "top"}}>飲む <br />
      </td>
      <td style={{verticalAlign: "top"}}>飲むな&nbsp;&nbsp;&nbsp; </td>
      <td style={{verticalAlign: "top"}}>飲<span style={{fontWeight: "bold"}}>み</span>な&nbsp;&nbsp;&nbsp;
      </td>
      <td style={{verticalAlign: "top"}}>飲み<span
 style={{fontWeight: "bold"}}>な</span>いな</td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>{addRubyText("帰","かえ")}る <br />
      </td>
      <td style={{verticalAlign: "top"}}>帰るな&nbsp;&nbsp;&nbsp; </td>
      <td style={{verticalAlign: "top"}}><span style={{fontWeight: "bold"}}>帰
り</span>な&nbsp;&nbsp;&nbsp; </td>
      <td style={{verticalAlign: "top"}}><span style={{fontWeight: "bold"}}>帰
りな</span>いな</td>
    </tr>
    <tr>
      <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>Irregular&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <br />
      </td>
      <td style={{verticalAlign: "top"}}>する</td>
      <td style={{verticalAlign: "top"}}>するな&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </td>
      <td style={{verticalAlign: "top"}}><span style={{fontWeight: "bold"}}>し</span>な
      <br />
      </td>
      <td style={{verticalAlign: "top"}}><span style={{fontWeight: "bold"}}>し
な</span>いな</td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>{addRubyText("来","く")}る&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>くるな&nbsp;&nbsp;&nbsp; </td>
      <td style={{verticalAlign: "top"}}><span style={{fontWeight: "bold"}}>き</span>な&nbsp;
      </td>
      <td style={{verticalAlign: "top"}}>&nbsp; <span
 style={{fontWeight: "bold"}}>きな</span>いな</td>
    </tr>
  </tbody>
</table>
<br />
As seen in the chart above, the standard negative imperatives are
formed by adding the sentence final particle な to V(dictionary form),
whereas the Kansai version requires Masu-form. Also please note that
the Kansai negative imperatives are more gentle than the standard
versions and are used both by males and females as are the Kansai
(affirmative) imperatives.<br />
<br />
そこ、ツルツルやさかい、<span style={{fontWeight: "bold"}}>{addRubyText("走","はし")}り</span>な／<span
 style={{fontWeight: "bold"}}>走りな</span>いな！{addAudio("4.3.2.8.mp3")}<br />
<small>（ST そこ、ツルツルだから、走るな！）{addAudio("4.3.2.8.s.mp3")}<br />
<br />
* ツルツル : slippery, smooth</small><br />
<br />
Even though they have their own style of negative imperatives, Kansai
people (especially younger generation) have come to use standard style
negative imperatives more commonly nowadays. But, when the verb
dictionary form ends with る (whether Ru-verb, U-verb or Irregular),
るtends to be changed to ん as follows;<br />
<br />
食べ<span style={{fontWeight: "bold"}}>ん</span>な、<span
 style={{fontWeight: "bold"}}>着ん</span>な、<span style={{fontWeight: "bold"}}>帰
ん</span>な、<span style={{fontWeight: "bold"}}>すん</span>な、来<span
 style={{fontWeight: "bold"}}>ん</span>な {addAudio("4.3.2.9.mp3")}<br />
<br />
いっぺん{addRubyText("落","お")}ちたもん、{addRubyText("拾","ひろ")}て食べんなや！きたな〜！{addAudio("4.3.2.10.mp3")}<br />
<small>（ST 一回落ちたものは拾って食べるなよ！汚い！）</small><small>{addAudio("4.3.2.10.s.mp3")}</small><br />
<br />
<hr />
<h3>Extra 1: そんなこと、せんとき。</h3>
As described in 
<Link to={Page.BasicGrammar.VerbTeForm.Grammar.NegativeRequest}>
Grammar 5 (in Lesson 2 of Ch3),
</Link> Kansai people use 〜んといて(
ST 〜ないでおいて) as a negative request. 〜んとき, the Kansai imperative form of
〜んといて, is frequently used as negative imperatives in Kansai. This
expression is more gentle than Ｖ(Masu-stem) + な form.<br />
<br />
(To a friend) そんな{addRubyText("近","ちか")}くでテレビ{addRubyText("見","み")}んとき。＜見な／見んな {addAudio("4.3.2.11.mp3")}<br />
<small>(ST そんなに近くでテレビを見ないで。)</small><small>{addAudio("4.3.2.11.s.mp3")}</small><br />
<br />
(Mother to her child)
{addRubyText("大事","だいじ")}なお{addRubyText("客","きゃく")}さんの{addRubyText("前","まえ")}で{addRubyText("行儀ぎょうぎ")}{addRubyText("悪","わる")}いことせんときや。＜しな／すんな{addAudio("4.3.2.12.mp3")}<br />
<small>(ST 大事なお客さんの前で行儀の悪いことはしないでね！)</small><small>{addAudio("4.3.2.12.s.mp3")}</small><br />
<br />
<small>* {addRubyText("行儀","ぎょうぎ")}（が）{addRubyText("悪","わる")}い: ill mannered</small><br />
<br />
<hr />
<h3>Extra 2: {addRubyText("買","か")}いな、買いぃな、買うな、買わな！？</h3>
Most of you have already realized that Kansai imperatives and Kansai
negative imperatives share the same formation pattern, and the forms
look exactly the same when assertive particle な is attached to the
affirmative imperatives! But most of them are actually distinguishable
if you
pay attention to the accentual patterns.<br />
<br />
食べ<span style={{fontWeight: "bold"}}>な</span>（食べなさいよ）、食<span
 style={{fontWeight: "bold"}}>べ</span>ぇな（食べろよ） VS 食<span
 style={{fontWeight: "bold"}}>べ</span>な（食べるな negative) {addAudio("4.3.2.13.mp3")}<br />
飲み<span style={{fontWeight: "bold"}}>な</span>（飲みなさいよ）、飲<span
 style={{fontWeight: "bold"}}>み</span>ぃな（飲めよ）VS 飲<span
 style={{fontWeight: "bold"}}>み</span>な（飲むな negative）{addAudio("4.3.2.14.mp3")}<br />
<span style={{fontWeight: "bold"}}>{addRubyText("乗","の")}りな</span>（乗りなさいよ）、<span
 style={{fontWeight: "bold"}}>乗り</span>ぃな（乗れよ）VS <span
 style={{fontWeight: "bold"}}>乗り</span>な（乗るな negative） {addAudio("4.3.2.15.mp3")} <br />
<br />
Also, do you remember another function of な was introduced in the
former lesson?&nbsp; Yes, な can be the abbreviated version of
~ないかん／あかん( ~なければいけない) “You must …” in Kansai-ben&nbsp; (Refer to
<Link to={Page.FunctionalGrammar.NegativePermissionObligation.Grammar.Unless}>
Grammar 2 in Lesson 2 of Ch4
</Link>). <br />
<br />
Vなければー＞V(negative stem) +な (pronounced with low pitch)
<br />
<div style={{textAlign: "left"}}>食<span style={{fontWeight: "bold"}}>べ</span>な、
飲<span style={{fontWeight: "bold"}}>ま</span>な、<span
 style={{fontWeight: "bold"}}>乗ら</span>な、<span style={{fontWeight: "bold"}}>し</span>な
／<span style={{fontWeight: "bold"}}>せ</span>な、<span
 style={{fontWeight: "bold"}}>{addRubyText("来","こ")}</span>な {addAudio("4.3.2.16.mp3")}<br />
</div>
<br />
The accentual patterns of "You must.." form and "Don't.." form of
RU-verbs and する happen to be identical: if that is the case, you will
have to rely on the context.<br />
<br />
Let’s see if you can actually distinguish V + な! Can you tell the
English equivalent of the following?<br />
<table style={{textAlign: "left", width: "100%"}} border="1"cellPadding="2"
 cellSpacing="2">
  <tbody>
    <tr>
      <td style={{verticalAlign: "top"}}><span style={{fontWeight: "bold"}}>{addRubyText("休","やす")}ま</span>な&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <br />
      </td>
      <td style={{verticalAlign: "top"}}><span style={{fontWeight: "bold"}}>休
みな&nbsp;&nbsp;</span> <br />
      </td>
      <td style={{verticalAlign: "top"}}><span style={{fontWeight: "bold"}}>休
み</span>ぃな</td>
      <td style={{verticalAlign: "top"}}><span style={{fontWeight: "bold"}}>休
み</span>な <br />
      </td>
      <td style={{verticalAlign: "top"}}><span style={{fontWeight: "bold"}}>休
む</span>な</td>
      <td style={{verticalAlign: "top"}}><span style={{fontWeight: "bold"}}>休
もな </span><br />
      </td>
      <td style={{verticalAlign: "top"}}> {addAudio("4.3.2.17.mp3")}</td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}><Hideable content={<>“Unless you rest…”
“You must rest!”</>}/>&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}> <Hideable content={<>“Take a
rest.”</>}/>&nbsp;&nbsp;&nbsp;&nbsp; </td>
      <td style={{verticalAlign: "top"}}><Hideable content={<>“Take a
rest!!”</>}/>&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}><Hideable content={<>“Don’t
rest!”</>}/>&nbsp;&nbsp;&nbsp;&nbsp; </td>
      <td style={{verticalAlign: "top"}}><Hideable content={<>“Don’t rest!”
(standard version)</>}/>&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}><Hideable content={<>“Let’s take a
rest!”</>}/></td>
      <td style={{verticalAlign: "top"}}><br />
      </td>
    </tr>
  </tbody>
</table>
<br />
Did you get them? Alright,&nbsp; here is one more set.<br />
<table style={{textAlign: "left", width: "100%"}} border="1"cellPadding="2"
 cellSpacing="2">
  <tbody>
    <tr>
      <td style={{verticalAlign: "top"}}>食べ<span
 style={{fontWeight: "bold"}}>な&nbsp;</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <br />
      </td>
      <td style={{verticalAlign: "top"}}> 食<span
 style={{fontWeight: "bold"}}>べ</span>ぇな&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>食<span style={{fontWeight: "bold"}}>べ</span>な</td>
      <td style={{verticalAlign: "top"}}>食べよ<span
 style={{fontWeight: "bold"}}>な</span></td>
      <td style={{verticalAlign: "top"}}> 食べ<span
 style={{fontWeight: "bold"}}>ん</span>な <br />
      </td>
      <td style={{verticalAlign: "top"}}>{addAudio("4.3.2.18.mp3")}</td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}><Hideable content={<>
Eat.</>}/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}><Hideable content={<>Eat!!</>}/> <br />
      </td>
      <td style={{verticalAlign: "top"}}><Hideable content={<>(1) Don't eat! (2)
You must eat! –depending on the context</>}/>&nbsp;&nbsp; </td>
      <td style={{verticalAlign: "top"}}> <Hideable content={<>Let’s
eat!</>}/>&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}><Hideable content={<>Don’t eat!
(standard-like version) </>}/></td>
      <td style={{verticalAlign: "top"}}><br />
      </td>
    </tr>
  </tbody>
</table>
  </>;
}

export default NegativeImperatives;
