import React from "react";
import { addRubyText, addAudio } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";

const P3 = () => {
  const transcript = <>
    <ol className="transcript">
      <li>A: 宿題、今日までだよ。</li>
      <li>B: え？そう、今日までだったの。知らなかったよ。</li>
    </ol>
  </>
  return <>
    <h2>{addRubyText("練習","れんしゅう")}3 : N+Copula (past)</h2>
    Form a question and an answer for each cue according to
    a model. You may listen to the question, tryto answer it, and check
    your answer. *Answers vary; the recorded answers indicate those
    commonly
    used.<br />
    <small>*Mouse over or click on the speaker icon for sound</small><br />
    <br />
    <span style={{fontWeight: "bold"}}>Model </span>(Cue:
    {addRubyText("宿題","しゅくだい")}／{addRubyText("今日","きょう")}まで)<br />
    &nbsp;&nbsp;&nbsp; A: 宿題、今日までやで。<br />
    &nbsp;&nbsp;&nbsp; B: え？そうかあ、今日までやったんかあ。知らんかったわ。{addAudio("3.3.p.3.mp3")}<br />
    <br />
    Point: Nだったー＞Nやった (refer to Grammar 1)<br />
    <br />
    ST transcript
    <br />
    <Hideable content={transcript} />
    <br />
    <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>Cue<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>A (Question)<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>B (Answer)<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>{addRubyText("宿題","しゅくだい")}／{addRubyText("今日","きょう")}まで<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.3.p.3.1.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.3.p.3.1.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>あの{addRubyText("人","ひと")}／{addRubyText("男","おとこ")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.3.p.3.2.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.3.p.3.2.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>そのお{addRubyText("寿司","すし")}／{addRubyText("昨日","きのう")}の<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.3.p.3.3.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.3.p.3.3.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>{addRubyText("電話","でんわ")}／{addRubyText("先生","せんせい")}から<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.3.p.3.4.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.3.p.3.4.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>その{addRubyText("木","き")}／{addRubyText("桜","さくら")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.3.p.3.5.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.3.p.3.5.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>あのバイク／イタリアの<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.3.p.3.6.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.3.p.3.6.b.mp3")}<br />
          </td>
        </tr>
      </tbody>
    </table>
  </>;
}

export default P3;
