import React from "react";
import { Link } from "react-router-dom";
import { Page } from "home/page";
import { addRubyText, addImage } from "common/util/generalUtil";

const Grammar = () => {
  return <>
    <h2>{addRubyText("文法","ぶんぽう")}ノート (Grammar Note)</h2>
    <br />
    <table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>
          <ol>
            <li>
              <Link to={Page.FunctionalGrammar.Imperatives.Grammar.Imperatives}>
                Kansai Imperatives “Do…!”
              </Link>
            </li>
            <li>
              <Link to={Page.FunctionalGrammar.Imperatives.Grammar.NegativeImperatives}>
                Negative Imperatives “Don’t…!”
              </Link>
            </li>
          </ol>
          </td>
          <td style={{verticalAlign: "top"}}>
          <div style={{textAlign: "center"}}>{addImage("kinosakiashiyu.jpg")}<br />
          </div>
          <div style={{textAlign: "center"}}><small>足湯 Patio foot Spa (Kinosaki, Hyogo)</small><br />
          </div>
          </td>
        </tr>
      </tbody>
    </table>
  </>;
}

export default Grammar;
