import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";

const V7 = () => {
  const transcript = <>
    … ま。がんばって、お{addRubyText("金","かね")}{addRubyText("貯","た")}めて。ははは、、{addRubyText("病気","びょうき")}<span
    style={{textDecoration: "underline"}}>せんときや</span> (Grammar 2 Extra 1)。{addRubyText("病気","びょうき")}せんように。そうそうそう、そうやで。
  </>;

  const qaObjects = [
    {
      question:   <span>Q: What does Ishioh-san advise the interviewer? Choose all that apply.</span>,
      answersCorrect: [
        <>Save money.</>,
        <>Don’t’ get sick.</>
      ],
      answersIncorrect: [
        <>Gain more money.</>,
        <>Don’t’ work too hard.</>,
        <>Get well (from illness).</>,
        <>Marry a rich man.</>
      ]
    }
  ]

  return <>
    <h2>ビデオ{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}７: Authentic – short</h2>
    Watch the video clip and choose the answer that matches the content
    ofthe clip.
    <br />
    <small>*Click "SHOW" if you need to refer to the transcript.
    </small><br />
    <br />
    The interviewer is talking about the life living abroad to
    Ishioh-san. <br />
    <table style={{textAlign: "left", width: "100%"}} border="0"cellPadding="2"
    cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>{addVideoPlayer("4.3.1.p.6.ishioh.mp4")} </td>
          <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>Transcript
    <Hideable content={transcript}/><br />
          </td>
        </tr>
        <tr align="center">
          <td style={{verticalAlign: "top", textAlign: "left"}}>イシオウさん (Shiga)</td>
        </tr>
      </tbody>
    </table>
    <QandAForm qaObjects={qaObjects}/>
  </>;
}

export default V7;
