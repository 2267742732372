import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";

const V3 = () => {
  const transcript = <>
    この人，<span style={{textDecoration: "underline"}}>{addRubyText("映","うつ")}ったはって</span>(Grammar
    1 Extra 1)もいいの？映、<span style={{textDecoration: "underline"}}>映ったはって</span>も
    いいの？
  </>;

  const qaObjects = [
    {
      question: <span>Q1: What is her question?</span>,
      answersCorrect: [
        <>If her daughter can be in the video shoot</>
      ],
      answersIncorrect: [
        <>If her daughter can speak during the interview</>,
        <>If the interviewer wants her daughter to be in the video shoot</>,
        <>If the interviewer wants her daughter to speak during the interview</>
      ]
    },
    {
      question: <span>Q2: For whom did she use はる?</span>,
      answersCorrect: [
        <>Her daughter</>
      ],
      answersIncorrect: [
        <>Interviewer</>,
        <>Herself</>,
        <>Someone else</>
      ]
    }
  ];

  return <>
    <h2>ビデオ{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}３: Authentic - short<br />
    </h2>
    Watch the video clip and choose the answer that matches the content of
    the clip.
    Submit your answers after answering all the questions.<br />
    <small>*Click "SHOW" if you need to refer to the transcript</small><br />
    <br />
    Context: Her daughter came in when
    Sachie-san was about to be
    interviewed.<br />
    <table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2"
    cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>{addVideoPlayer("4.4.1.p.3.sachie.mp4")}</td>
          <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>Transcript
    <Hideable content={transcript}/><br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>サチエさん
    (Shiga)</td>
        </tr>
      </tbody>
    </table>
    <br />
    <QandAForm qaObjects={qaObjects}/>
  </>;
}

export default V3;
