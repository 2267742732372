import React from "react";
import { addRubyText, addAudio } from "common/util/generalUtil";

const Negative = () => {
  return <>
    <h2>2. Negative form of Adjectives</h2>
    Forming the negative of adjectives is quite easy;
    simply add ない to the stem.<br />
    <small>*Mouse over or click on the speaker icon for sound<br />
    </small><br />
    {addRubyText("寒","さむ")}い→(stem) さむ+ない→{addRubyText("寒","さむ")}ない<br />
    {addRubyText("白","しろ")}い→(stem) しろ+ない→{addRubyText("白","しろ")}ない<br />
    {addRubyText("大","おお")}きい→(stem) おおき+ない→{addRubyText("大","おお")}きない　{addAudio("3.4.2.1.mp3")}<br />
    <br />
    ここの{addRubyText("雑誌","ざっし")}、{addRubyText("全然","ぜんぜん")}<span style={{textDecoration: "underline"}}>{addRubyText("新","あたら")}しな
    い</span>やん。もっと新し（い）のないん？<small>&nbsp; </small>{addAudio("3.4.2.2.mp3")}<br />
    <small>（ST この雑誌、全然新しくないじゃん。もっと新しいのはないの？）</small><small>{addAudio("3.4.2.2.s.mp3")}</small><br />
    <br />
    However, an euphonic change of the stem traditionally occurs when an
    adjective stem ends with "-A" such as {addRubyText("早","はや")}い (hay-A-i), {addRubyText("赤","あか")}い
    (ak-A-i), {addRubyText("高","たか")}い (tak-A-i).&nbsp; In this case, "-A" at the end of the
    stem changes to "-O", followed by ない. Therefore, there are two possible
    forms for negatives of this type of adjective.<br />
    <br />
    {addRubyText("早","はや")}い (hay-A-i)→はや(hay-A)／はよ(hay-O)→はやない／はよない　{addAudio("3.4.2.3.mp3")}<br />
    <br />
    {addRubyText("行","い")}きたい (ikit-A-I)→行きた (ikit-A)／行きと
    (ikit-O)→行きたない／行きとない　{addAudio("3.4.2.4.mp3")}<br />
    <br />
    {addRubyText("今日","きょう")}テストあるし、{addRubyText("学校","がっこう")}<span style={{textDecoration: "underline"}}>行き
    たない</span>わ〜。{addAudio("3.4.2.5.mp3")}<br />
    <small>(ST 今日はテストがあるから、学校に行きたくないよ。) </small><small>{addAudio("3.4.2.5.s.mp3")}</small><br />
    <br />
    このジュース、{addRubyText("冷","つめ")}とないし、{addRubyText("飲","の")}みとない。{addAudio("3.4.2.6.mp3")}<br />
    <small>(ST このジュースは冷たくないから飲みたくない。) </small><small>{addAudio("3.4.2.6.s.mp3")}</small><br />
    <br />
    * The negatives of いい（ええ）is ようない<br />
    * The younger generation use the same form as the
    standard with Kansai accent (Neo-dialect).<br />
    * As seen in negative form of Copula, あらへん and あらへんかった
    are also used for negation of adjectives; however, the majority of
    Kansai people nowadays prefer using standard type negation ない／なかった.<br />
    * Another way to negate adjective is [Dictionary form]
    + こと（は）ない／あらへん<br />
    <br />
    あそこのコーヒー、あんまり<span style={{textDecoration: "underline"}}>おいしいことなかった</span>な
    あ。 {addAudio("3.4.2.7.mp3")}
    <br />
    <small>(ST あそこのコーヒー、あんまりおいしくなかったなあ。）</small><small>{addAudio("3.4.2.7.s.mp3")}</small>
  </>;
}

export default Negative;
