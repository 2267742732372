import React from "react";
import { Link } from "react-router-dom";
import { Page } from "home/page";
import { addRubyText, addImage, addAudio } from "common/util/generalUtil";

const Honorifics = () => {
  return (
    <>
      <h2>Honorifics <br /></h2>
      <table style={{textAlign: "left", width: "40%"}} align="right" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td colSpan="1" rowSpan="1" style={{verticalAlign: "top", textAlign: "center"}}>
            <div>{addImage("takoyakiya.jpg")}</div>
            <div>
              <small>たこ焼き屋 Takoyaki shop (Abiko, Osaka)</small>
            </div>
          </td>
        </tr>
        </tbody>
      </table>

      <div>
        Honorific expression was originally integrated into the Kansai area
        where the capital was located and its complexity was increased because
        of feudalism. The modern Kansai dialect still retains the complex
        expressions that can be used depending on the interlocutor (the person
        to whom you talk) in addition to the regular honorific expressions. The clearest example is the
        existence of the light honorific auxiliary verbs はる／やはる, and the light
        pejorative auxiliary verbs おる／よる. Nowadays the Kansai speakers
        use the light honorifics はる／やはる quite often, replacing the regular
        honorific expressions. In that sense, the use of honorific expression
        is quite simplified in the Kansai dialect these days.
      </div>
      <br />

      <div>
        はる follows Negative-form stem (Kyoto, Nara and Shiga area) or Masu-from stem (Osaka)
        for るverbs and うverbs. It basically follows Masu-form stem for irregular
        verbs regardless the region, like the following:
      </div>
      <div>
        <small>*Mouse over or click on the speaker icon for sound</small>
      </div>
      <br />

      <div>
        {addRubyText("食","た")}べはる；
        {addRubyText("聞","き")}かはる(Kyoto)／聞きはる(Osaka)；しはる（しやはる）；
        {addRubyText("来","き")}はる（来やはる）
        {addAudio("1.2.g.1.mp3")}
      </div>
      <br />

      <div>
        {addRubyText("先生","せんせい")}、もう
        {addRubyText("昼","ひる")}ご
        {addRubyText("飯","はん")}食べ<u>はり</u>ましたか？
        {addAudio("1.2.g.2.mp3")}
        <br />

        <div>
          <small>（ST {addRubyText("召","め")}し{addRubyText("上","あ")}がりましたか）{addAudio("1.2.g.2.s.mp3")}</small>
        </div>
        <br />

        えんぴつ{addRubyText("貸","か")}してくれ<u>はり</u>ませんか？
        {addAudio("1.2.g.3.mp3")}
        <br />

        <small>（ST 貸してくださいませんか）{addAudio("1.2.g.3.s.mp3")}</small>
      </div>
      <br />

      <div>
        はる is used not only as an honorific expression but also just as a
        polite expression, or simply to be gentle and polite (usually by
        females). It can be used even for family members or animals!
        (especially in Kyoto / Shiga area)
      </div>
      <br />

      <div>
        (To one’s own mother) お{addRubyText("父","とう")}さん、
        {addRubyText("部屋","へや")}にい
        <u>はった</u>わ。
        {addAudio("1.2.g.4.mp3")}
      </div>
      <div>
        <small>（ST お父さん、部屋にいたよ）{addAudio("1.2.g.4.s.mp3")}</small>
      </div>
      <br />

      (About one’s own baby) この{addRubyText("人","ひと")}、{addRubyText("毎日","まいにち")}テレビ{addRubyText("見","み")}
      <u>はる</u>ねん。{addAudio("1.2.g.5.mp3")}<br />
      <small>(ST この{addRubyText("子","こ")}、毎日テレビ見るのよ／見るんだよ。) {addAudio("1.2.g.5.s.mp3")} </small><br />
      <br />

      (Female talking to her child) ほら、{addRubyText("犬","いぬ")}がこっち{addRubyText("来","き")}
      <u>はる</u>で !{addAudio("1.2.g.6.mp3")}<br />
      <small>(ST ほら、犬がこっちに来るよ！) {addAudio("1.2.g.6.s.mp3")} </small><br />
      <br />

      <div>
        Humble expressions are used the same way as in the standard, however,
        note that おる, the humble form of いる, is also used as a light pejorative
        auxiliary verb, to look down on or to show hierarchical difference from
        the person, or just to be blunt. This is in opposition to the use of はる
        as a neutral polite.
      </div>
      <br />

      (Talking to Mom about younger brother) たかし、{addRubyText("何","なに")}し<u>とる</u>（=し<u>ておる</u>）？ {addAudio("1.2.g.7.mp3")}<br />
      <small>(ST たかし、何してる？）{addAudio("1.2.g.7.s.mp3")}</small><br />
      <br />

      (About a stray cat) あの{addRubyText("猫","ねこ")}、{addRubyText("寝","ね")}<u>とん</u>（=寝<u>ておる</u>）で。 {addAudio("1.2.g.8.mp3")}<br />
      <small>(ST あの猫、寝ているよ。）{addAudio("1.2.g.8.s.mp3")}</small><br />
      <br />

      <div>
        Further explanations and examples as well as aural exercises are
        provided under <Link to={Page.FunctionalGrammar.Honorifics}>Ch4.4 Honorifics</Link>
      </div>
      <br />
    </>
  );
};

export default Honorifics;
