import React from "react";
import { addRubyText, addAudio, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";

const Conversations = () => {
  const kansaiTranscript1 = <>
      <ol class="transcript">
        <li><small>A: は<span style={{fontWeight: "bold"}}>よ</span>お<span
 style={{fontWeight: "bold"}}>き</span>！<span style={{fontWeight: "bold"}}>もう</span>ひ<span
 style={{fontWeight: "bold"}}>ち</span>じやで！</small></li>
        <li><small>B: は〜い。</small></li>
        <li><small>A: いつまで<span style={{fontWeight: "bold"}}>ねまききてん</span>の！
はよ<span style={{fontWeight: "bold"}}>きがえや</span>！</small></li>
        <li><small>B: は〜い。</small></li>
        <li><small>A: はよ<span style={{fontWeight: "bold"}}>しぃや</span>！<span
 style={{fontWeight: "bold"}}>ま</span>たがっこ<span style={{fontWeight: "bold"}}>お
くれ</span>んで。</small></li>
        <li><small>B: あ<span style={{fontWeight: "bold"}}>れ</span>？お<span
 style={{fontWeight: "bold"}}>か</span>あさん、、、きょ<span
 style={{fontWeight: "bold"}}>う</span>にちよう<span style={{fontWeight: "bold"}}>ちゃ
う</span>ん。</small></li>
        <li><small>A: 、、<span style={{fontWeight: "bold"}}>ほ</span>な、は<span
 style={{fontWeight: "bold"}}>よ</span>、お<span style={{fontWeight: "bold"}}>か</span>あ
さんの<span style={{fontWeight: "bold"}}>かわりに</span>あさ<span
 style={{fontWeight: "bold"}}>ご</span>はんでもつく<span
 style={{fontWeight: "bold"}}>り</span>！</small></li>
        <li><small>B: <span style={{fontWeight: "bold"}}>そんな</span>あ<span
 style={{fontWeight: "bold"}}>ほ</span>な。</small></li>
      </ol>
  </>;

  const standardTranscript1 = <>
      <ol class="transcript">
        <li><small>A: {addRubyText("早","はや")}く{addRubyText("起","お")}きなさい！もう{addRubyText("七時","しちじ")}よ！</small></li>
        <li><small>B: は〜い。</small></li>
        <li><small>A: いつまでパジャマ{addRubyText("着","き")}てるの？早く{addRubyText("着替","きが")}えなさいよ！</small></li>
        <li><small>B: は〜い。</small></li>
        <li><small>A: 早くしなさい！また{addRubyText("学校","がっこう")}に{addRubyText("遅","おく")}れるわよ。</small></li>
        <li><small>B: あれ？お{addRubyText("母","かあ")}さん、、、{addRubyText("今日","きょう")}{addRubyText("日曜","にちよう")}じゃないの？</small></li>
        <li><small>A: 、、じゃ、早くお母さんの{addRubyText("代","か")}わりに{addRubyText("朝","あさ")}ご{addRubyText("飯","はん")}でも{addRubyText("作","つく")}りなさい !</small></li>
        <li><small>B: そんなばかな。</small><br />
        </li>
      </ol>
  </>;

  const kansaiTranscript2 = <>
      <ol class="transcript">
        <li><small>A: （ゴホッゴホッ）</small></li>
        <li><small>B: な<span style={{fontWeight: "bold"}}>ん</span>や、<span
 style={{fontWeight: "bold"}}>かぜか</span>いな。<span
 style={{fontWeight: "bold"}}>この</span>く<span style={{fontWeight: "bold"}}>す</span>り
の<span style={{fontWeight: "bold"}}>み</span>。</small></li>
        <li><small>A: え〜く<span style={{fontWeight: "bold"}}>す</span>り<span
 style={{fontWeight: "bold"}}>い</span>？<span style={{fontWeight: "bold"}}>いや</span>や。</small></li>
        <li><small>B: <span style={{fontWeight: "bold"}}>ちゃん</span>とのみ<span
 style={{fontWeight: "bold"}}>な</span>。</small></li>
        <li><small>A: <span style={{fontWeight: "bold"}}>に</span>がいやん。</small></li>
        <li><small>B: も<span style={{fontWeight: "bold"}}>ん</span>く<span
 style={{fontWeight: "bold"}}>いわ</span>んと、は<span
 style={{fontWeight: "bold"}}>よ</span>の<span style={{fontWeight: "bold"}}>み</span>ぃ
な！</small></li>
        <li><small>A: は〜い。</small></li>
        <li><small>B: <span style={{fontWeight: "bold"}}>ちょ</span>っと<span
 style={{fontWeight: "bold"}}>ちょ</span>っと。コー <span
 style={{fontWeight: "bold"}}>ヒ</span>ーでの<span style={{fontWeight: "bold"}}>み</span>な！</small></li>
      </ol>
  </>;

  const standardTranscript2 = <>
      <ol class="transcript">
        <li><small>A: （ゴホッゴホッ）</small></li>
        <li><small>B: {addRubyText("何","なん")}だ、{addRubyText("風邪","かぜ")}なの？この{addRubyText("薬","くすり")}{addRubyText("飲","の")}みなさい。</small></li>
        <li><small>A: え〜薬？いやだ。</small></li>
        <li><small>B: ちゃんと飲みなさいよ。</small></li>
        <li><small>A: {addRubyText("苦","にが")}いじゃん。</small></li>
        <li><small>B: {addRubyText("文句","もんく")}{addRubyText("言","い")}わないで、早く飲みなさいよ！</small></li>
        <li><small>A: は〜い。</small></li>
        <li><small>B: ちょっと！コーヒーで飲むな（飲んじゃだめ）！</small> </li>
      </ol>
  </>;

  const kansaiTranscript3 = <>
      <ol class="transcript">
        <li><small>A: こ<span style={{fontWeight: "bold"}}>ん</span>ばんは<span
 style={{fontWeight: "bold"}}>よ</span>うけのん<span style={{fontWeight: "bold"}}>だ</span>わ〜。<span
 style={{fontWeight: "bold"}}>だいジョ</span>ッキ<span
 style={{fontWeight: "bold"}}>ごはい</span>。</small></li>
        <li><small>B: <span style={{fontWeight: "bold"}}>そら</span>、のみす<span
 style={{fontWeight: "bold"}}>ぎ</span>やわ。<span style={{fontWeight: "bold"}}>く
るまうんてんし</span>なや。</small></li>
        <li><small>A: え？<span style={{fontWeight: "bold"}}>うんてんせ</span>な、<span
 style={{fontWeight: "bold"}}>かえら</span>れ<span style={{fontWeight: "bold"}}>へ
ん</span>がな。</small></li>
        <li><small>B: <span style={{fontWeight: "bold"}}>あかん、あかん</span>、<span
 style={{fontWeight: "bold"}}>のり</span>な。ほら、『のん<span
 style={{fontWeight: "bold"}}>だ</span>ら<span style={{fontWeight: "bold"}}>のり</span>な』<span
 style={{fontWeight: "bold"}}>ゆう</span>や<span style={{fontWeight: "bold"}}>ろ</span>。</small></li>
        <li><small>A: <span style={{fontWeight: "bold"}}>ほ</span>な、<span
 style={{fontWeight: "bold"}}>どないし</span>て<span style={{fontWeight: "bold"}}>う</span>ち<span
 style={{fontWeight: "bold"}}>かえ</span>んの。</small></li>
        <li><small>B: あ<span style={{fontWeight: "bold"}}>る</span>いて<span
 style={{fontWeight: "bold"}}>かえり</span>ぃな。</small></li>
        <li><small>A: え〜しん<span style={{fontWeight: "bold"}}>ど</span>いやん。<span
 style={{fontWeight: "bold"}}>よう</span>あるか<span style={{fontWeight: "bold"}}>ん</span>わ。<span
 style={{fontWeight: "bold"}}>な</span>あ〜<span style={{fontWeight: "bold"}}>お
くって</span>えや。</small></li>
        <li><small>B: ええ<span style={{fontWeight: "bold"}}>と</span>しこいて、<span
 style={{fontWeight: "bold"}}>あまえ</span>なや！</small></li>
      </ol>
  </>;

  const standardTranscript3 = <>
      <ol class="transcript">
        <li><small>A: {addRubyText("今晩","こんばん")}はたくさん{addRubyText("飲","の")}んだよ〜。{addRubyText("大","だい")}ジョッキ{addRubyText("五杯","ごはい")}。</small></li>
        <li><small>B: そりゃ飲み{addRubyText("過","す")}ぎだよ。{addRubyText("車","くるま")}{addRubyText("運転","うんてん")}するなよ（しちゃだめだよ）。</small></li>
        <li><small>A: え？運転しなかったら{addRubyText("帰","かえ")}れないよ。</small></li>
        <li><small>B: だめ、乗るな！『飲んだら乗るな』って言うだろ。</small></li>
        <li><small>A: じゃ、どうやって{addRubyText("家","うち")}に帰るの？</small></li>
        <li><small>B: {addRubyText("歩","ある")}いて帰れよ。（帰ったら？）</small></li>
        <li><small>A: え〜{addRubyText("疲","つか")}れるじゃない。歩けないよ。ねえ〜{addRubyText("送","おく")}ってよ。</small></li>
        <li><small>B: いい{addRubyText("年","とし")}して、{addRubyText("甘","あま")}えるなよ！</small><br />
        </li>
      </ol>
  </>;

  return <>
<h2>{addRubyText("基本会話","きほんかいわ")} (Core Conversations)</h2>
<hr />
<h3>{addRubyText("会話","かいわ")}１ : Does B have to go to school today?<br />
</h3>
*A=first speaker, B=second speaker<br />
*Click "SHOW" for transcripts<br />
<br />
(A is B’s mother)<br />
<table style={{textAlign: "left", width: "100%"}} border="1"cellPadding="2"
 cellSpacing="2">
  <tbody>
    <tr>
      <td style={{verticalAlign: "top", textAlign: "center"}} class="video"
 colSpan="1" rowSpan="2">{addVideoPlayer("4.3.cc.1.mp4")}</td>
      <td style={{verticalAlign: "top"}} class="transcriptcell">Kansai
transcript 
        <Hideable content={kansaiTranscript1}/>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}} class="transcriptcell">Standard
transcript 
        <Hideable content={standardTranscript1}/>
      </td>
    </tr>
  </tbody>
</table>
<br />
<small>* {addRubyText("寝間着","ねまき")}＝ traditional term of パジャマ</small>
<h3>{addRubyText("会話","かいわ")}2&nbsp; : A will take a cold medicine with…?<br />
</h3>
(A is B’s younger sister)<br />
<table style={{textAlign: "left", width: "100%"}} border="1"cellPadding="2"
 cellSpacing="2">
  <tbody>
    <tr>
      <td style={{verticalAlign: "top", textAlign: "center"}} class="video"
 colSpan="1" rowSpan="2">{addVideoPlayer("4.3.cc.2.mp4")}</td>
      <td style={{verticalAlign: "top"}} class="transcriptcell">Kansai
transcript
        <Hideable content={kansaiTranscript2}/>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}} class="transcriptcell">Standard
transcript <Hideable content={standardTranscript2}/>
      </td>
    </tr>
  </tbody>
</table>
<br />
<h3>{addRubyText("会話","かいわ")}3 : How does A go home tonight?<br />
</h3>
(A and B are friends from Osaka. They just drank together at a <span
 style={{fontStyle: "italic"}}>Izaka-ya</span>.) <br />
<table style={{textAlign: "left", width: "100%"}} border="1"cellPadding="2"
 cellSpacing="2">
  <tbody>
    <tr>
      <td style={{verticalAlign: "top", textAlign: "center"}} class="video"
 colSpan="1" rowSpan="2">{addVideoPlayer("4.3.cc.3.mp4")}</td>
      <td style={{verticalAlign: "top"}} class="transcriptcell">Kansai
transcript 
        <Hideable content={kansaiTranscript3}/>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}} class="transcriptcell">Standard
transcript 
        <Hideable content={standardTranscript3}/>
      </td>
    </tr>
  </tbody>
</table>
<br />
<small>*{addRubyText("甘","あま")}える: behave like a spoiled child<br />
* ええ{addRubyText("年","とし")}こいて (Kansai) ＝いい年こいて (colloquial) ＞いい年をして : in spite of one’s
mature age</small><span style={{fontWeight: "bold"}}><br />
</span><br />
<hr />
<h3>{addRubyText("理解度","りかいど")}チェック (Check-up)&nbsp; <br />
</h3>
Figure out the Kansai-specific grammar and expressions that appear in
the
video clips. Refer to the standard and Kansai transcripts if necessary.<br />
<br />
<table style={{textAlign: "left", width: "100%"}} border="1"cellPadding="2"
 cellSpacing="2">
  <tbody>
    <tr>
      <td style={{verticalAlign: "top", textAlign: "center"}}>Check-up<br />
      </td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>Answer<br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>1) How do you say
{addRubyText("起","お")}きなさい／{addRubyText("飲","の")}みなさい／{addRubyText("作","つく")}りなさい／しなさい in Kansai-ben? <br />
      </td>
      <td style={{verticalAlign: "top"}}><Hideable content={<>
{addRubyText("起","お")}き／{addRubyText("飲","の")}み／{addRubyText("作","つく")}り／しぃ</>}/><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>2) How can you form imperatives
in Kansai-ben?<br />
      </td>
      <td style={{verticalAlign: "top"}}><Hideable content={<>&nbsp; use Masu-form
stem</>}/> </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>3) What are the Kansai
equivalents of the following negative imperatives
{addRubyText("甘","あま")}えるな／{addRubyText("飲","の")}むな／{addRubyText("乗","の")}るな／するな?<br />
      </td>
      <td style={{verticalAlign: "top"}}><Hideable content={<>
{addRubyText("甘","あま")}えな／{addRubyText("飲","の")}みな／{addRubyText("乗","の")}りな／しな•すんな</>}/><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>4) Is there any difference
between standard and Kansai negative imperatives? <br />
      </td>
      <td style={{verticalAlign: "top"}}><Hideable content={<>Both require な,
however, な seems to follow the Masu-form
stem in Kansai-ben whereas it follows the dictionary form in Standard.</>}/><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>5) What is the difference
between {addRubyText("飲","の")}み／飲みな／飲みぃな／飲みな? (Pay attention to the accent and the
function of な.)<br />
      </td>
      <td style={{verticalAlign: "top"}}><Hideable content={<>
飲み＝飲みなさい／飲め、飲みな＝飲みなさいよ／{addRubyText("飲","の")}めよ、飲みぃな＝飲みなさいよ！／飲めよ！、飲みな＝飲むな(Don’t drink!).
Sentence final particle な can indicate assertion or negative
imperatives, and you can tell the difference only by the pitch
pattern/accent.</>}/><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>6) Which is a stronger
imperative, {addRubyText("飲","の")}みな or 飲みぃな？<br />
      </td>
      <td style={{verticalAlign: "top"}}><Hideable content={<>飲みぃな is stronger
than 飲みな.</>}/></td>
    </tr>
  </tbody>
</table>
<br />
<hr />
<h3>{addRubyText("関西","かんさい")}{addRubyText("語彙","ごい")}チェック (Kansai Vocabulary Check-up) <br />
</h3>
What are the standard equivalent expression of the following Kansai
words that are introduced in the core conversations of this lesson?<br />
<br />
<table style={{textAlign: "left", width: "60%"}} border="1"cellPadding="2"
 cellSpacing="2">
  <tbody>
    <tr>
      <td style={{verticalAlign: "top", textAlign: "center"}}>Kansai<br />
      </td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>Standard<br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>あほ<br />
      </td>
      <td style={{verticalAlign: "top"}}><Hideable content={<>ばか idiot</>}/><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>ようけ（＝ぎょうさん） </td>
      <td style={{verticalAlign: "top"}}><Hideable content={<>たくさん</>}/> </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>ええ{addRubyText("年","とし")}こいて </td>
      <td style={{verticalAlign: "top"}}><Hideable content={<>いい{addRubyText("年","とし")}こいて／をして in
spite of one’s mature age</>}/> </td>
    </tr>
  </tbody>
</table>
<br />
<hr />
<h3>ノート (Miscellaneous note)</h3>
1) Osaka people use the passive negative form such as
{addRubyText("帰","かえ")}られへん／{addRubyText("歩","ある")}かれへん (as seen in cc3) to indicate negative potential
form 帰れない／歩けない, since they use potential negative form 帰れへん／歩けへん to
indicate regular negatives 帰らない／歩かない. However, people in the Kyoto area
use standard-like patterns with へん replacing ない. Therefore, you can
tell whether the speaker is from the Osaka region or Kyoto region by
close observation of this form. For details, please refer to Grammar 1
in Ch3 Lesson1a.<br />
<br />
2) As seen in cc3, Kansai people use し even if there is only one reason
to state, whereas し is usually used to infer more than one reason in
the standard. Other Kansai-typical ways to indicate reasons are to use
さけ／さかい (usually used by elder generation、さけ is more blunt than さかい).<br />
{addRubyText("家遠","うちとお")}いし、歩かれへんわ。{addAudio("4.3.0.1.mp3")}<br />
家遠いさかい／さけ、歩かれへんわ。{addAudio("4.3.0.2.mp3")}<br />
(ST 家が遠いから，歩けないよ。)
  </>;
}

export default Conversations;
