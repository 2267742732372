import React from "react";
import { addRubyText, addImage, addAudio } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
const P4 = () => {
  return <>
<h2>{addRubyText("練習","れんしゅう")}4: Utilization</h2>
What would you say in the following situations in Kansai-ben? Try to
ask in several ways. *Answers vary: the recorded answers indicate those
commonly used.<br />
<small>* Click "SHOW" if you need to refer to the transcript of the
example answers.&nbsp; <br />
*Move the mouse over to the speaker icon for sound</small><br />
<br />
<table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
  <tbody>
    <tr>
      <td style={{verticalAlign: "top"}}>Situation<br />
      </td>
      <td style={{verticalAlign: "top"}}>Example Answer<br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>1. Ask your friend if it’s all
right to borrow his/her pen which is near you. <br />
{addImage("4.1.p.4.1.jpg")}<br />
      </td>
      <td style={{verticalAlign: "top"}}>{addAudio("4.1.p.4.1.mp3")} <Hideable content={<>
このペン、{addRubyText("借","か")}りてもかまへん？／借りてもええ？</>}/><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>2. Ask a stranger if it’s all
right to have a seat right next to him/her. <br />
      <br />
      </td>
      <td style={{verticalAlign: "top"}}>{addAudio("4.1.p.4.2.mp3")}
<Hideable content={<>{addRubyText("隣","となり")}、{addRubyText("座","すわ")}ってもかましません？／座ってもいいですか／座ってもかまいませんか？／座らしてもろてもいいです
か？</>}/></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>3. Ask a stranger if it’s all
right to smoke here. <br />
{addImage("4.1.p.4.3.jpg")}<br />
      </td>
      <td style={{verticalAlign: "top"}}>{addAudio("4.1.p.4.3.mp3")} <Hideable content={<>
ここでたばこ、{addRubyText("吸","す")}うてもかましません？／吸うてもいいですか？／吸うてもかまいませんか？</>}/></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>4. You found out that your
friend has a comic book that you have wanted to read. Ask your friend
if it’s all right to read it.<br />
      </td>
      <td style={{verticalAlign: "top"}}>{addAudio("4.1.p.4.4.mp3")} <Hideable content={<>
この{addRubyText("漫画","まんが")}{addRubyText("読","よ")}みたかってんけど、読んでもかまへん？／読んでもええ？</>}/><br />
      <br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>5. Your friend bought a DVD that
looks interesting to you. Tell him that it looks interesting, and ask
him if it’s all right to watch it.&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>{addAudio("4.1.p.4.5.mp3")} <Hideable content={<>
このDVD、おもしろそうやん！{addRubyText("見","み")}てもかまへん？／見てもええ？</>}/></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>6. Somebody is about to park his
car in your driveway. Tell him not to park his car there. <br />
{addImage("4.1.p.4.6.jpg")}<br />
      </td>
      <td style={{verticalAlign: "top"}}> {addAudio("4.1.p.4.6.mp3")} <Hideable content={<>
{addRubyText("車","くるま")}、そこ{addRubyText("止","と")}めんといて下さい。／そこ止めたらあきませんよ。</>}/></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>7. Tell the children in the
neighborhood not to play on the street.<br />
      </td>
      <td style={{verticalAlign: "top"}}>{addAudio("4.1.p.4.7.mp3")} <Hideable content={<>
{addRubyText("道","みち")}で{addRubyText("遊","あそ")}んだ（ら）あかんで。／遊んだ（ら）いかんで。／遊ばんときや。／遊ばんと。</>}/><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>8. You just ordered iced coffee
in a coffee shop. Ask the waitress not to put ice in your coffee. <br />
{addImage("4.1.p.4.8.jpg")}<br />
      </td>
      <td style={{verticalAlign: "top"}}>{addAudio("4.1.p.4.8.mp3")} <Hideable content={<>
すいません。コーヒーに{addRubyText("氷","こおり")}、{addRubyText("入","い")}れんといて{addRubyText("下","くだ")}さいね。</>}/><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>9. Your friend drank too much.
Tell him not to drink any more.<br />
      <br />
      </td>
      <td style={{verticalAlign: "top"}}>{addAudio("4.1.p.4.9.mp3")} <Hideable content={<>
もう、{addRubyText("飲","の")}んだ（ら）あかんて。／もう飲んだ（ら）いかん。／もう飲まんとき。／もう飲むんやめとき。</>}/><br />
      </td>
    </tr>
  </tbody>
</table>
  </>;
}

export default P4;
