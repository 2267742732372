import React from "react";
import { addRubyText, addImage, addAudio } from "common/util/generalUtil";

const SpeechStyle = () => {
  return (
    <>
      <h2>Speech Style</h2>
      <div>
        As you know, the style switching between polite style (distal style)
        and casual style (direct style) is very important in maintaining the
        human relationships in Japanese society. The grammatical
        characteristics described so far have been those of the casual style.
        How do Kansai people talk on formal occasions? People nowadays,
        especially younger generation, most likely adapt standard-like
        structures as polite speech（です／ます style) with a dash of Kansai-peculiar
        structure and heavy Kansai accentual features, as a result of influence
        from the standard. For example, euphonic changes seen in adjectives and
        verbs, and Kansai-peculiar expressions such as ちゃう "different" and あかん
        "no good" usually remain even inです／ます style, such as:
      </div>
      <small>
        <br />
        *Mouse over or click on the speaker icon for sound
      </small>
      <br />
      <br />
      この{addRubyText("電車","でんしゃ")}、<u>はよ</u>&gt;ないですねえ。
      {addAudio("1.2.f.1.mp3")}<br />
      <small>（ST <u>{addRubyText("速","はや")}く</u>&gt;ないです
        ねえ）{addAudio("1.2.f.1.s.mp3")}</small><br />
      <br />
      おかしは<u>{addRubyText("買","こ")}うて</u>&gt;きませんでしたわ。
      {addAudio("1.2.f.2.mp3")}<br />
      <small>（ST <u>{addRubyText("買","か")}って</u>&gt;きません
        でしたよ）{addAudio("1.2.f.2.s.mp3")}</small><br />
      <br />
      パーティーは{addRubyText("今日","きょう")}<u>ちゃいます</u>かあ？
      {addAudio("1.2.f.3.mp3")}<br />
      <small>（ST 今日<u>じゃないです</u>か？）
        {addAudio("1.2.f.3.s.mp3")}</small><br />
      <br />
      {addRubyText("水曜日","すいようび")}は<u>あきません</u>か？{addAudio("1.2.f.4.mp3")}<br />
      <small>（ST 水曜日は<u>だめです</u>か ／<u>いけません</u>か？）{addAudio("1.2.f.4.s.mp3")}</small><br />
      <br />
      <table style={{textAlign: "center", width: "20%"}} align="right" border="0" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addImage("maiko2.jpg")}
          </td>
        </tr>
        </tbody>
      </table>

      <div>
        However, the Kansai dialect has its own polite style different from the
        standard です／ますstyle, as in {addRubyText("船場","せんば")}ことば (<i>Senba</i> dialect),
        a kind of Osaka dialect, and {addRubyText("祇園","ぎおん")}ことば (<i>Gion</i>
        dialect) of Kyoto dialect.
      </div>

      <h4>Expressions in <i>Senba</i>and <i>Gion</i>dialect</h4>
      <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Standard (listen all) {addAudio("1.2.f.5.s.mp3")}
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            <i>Senba</i> (listen all) {addAudio("1.2.f.5.mp3")}
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            <i>Gion</i> (listen all) {addAudio("1.2.f.6.mp3")}
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            (Kansai audio)
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            Xです
          </td>
          <td style={{verticalAlign: "top"}}>
            Xだす；Xだっ
          </td>
          <td style={{verticalAlign: "top"}}>
            Xどす
          </td>
          <td style={{verticalAlign: "top"}}>
            {addAudio("1.2.f.7.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            Xですよ
          </td>
          <td style={{verticalAlign: "top"}}>
            Xでっせ；Xだっせ
          </td>
          <td style={{verticalAlign: "top"}}>
            Xどすえ；Xどっせ
          </td>
          <td style={{verticalAlign: "top"}}>
            {addAudio("1.2.f.8.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            Xですね
          </td>
          <td style={{verticalAlign: "top"}}>
            Xでんな；Xだんな
          </td>
          <td style={{verticalAlign: "top"}}>
            Xどすな；Xどんな
          </td>
          <td style={{verticalAlign: "top"}}>
            {addAudio("1.2.f.9.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            します
          </td>
          <td style={{verticalAlign: "top"}}>
            しまっ
          </td>
          <td style={{verticalAlign: "top"}}>
            します（ぅ）
          </td>
          <td style={{verticalAlign: "top"}}>
            {addAudio("1.2.f.10.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            しますか
          </td>
          <td style={{verticalAlign: "top"}}>
            しまっか
          </td>
          <td style={{verticalAlign: "top"}}>
            しはりまっか
          </td>
          <td style={{verticalAlign: "top"}}>
            {addAudio("1.2.f.11.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            しますよ
          </td>
          <td style={{verticalAlign: "top"}}>
            しまっせ
          </td>
          <td style={{verticalAlign: "top"}}>
            しますえ
          </td>
          <td style={{verticalAlign: "top"}}>
            {addAudio("1.2.f.12.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            しますね
          </td>
          <td style={{verticalAlign: "top"}}>
            しまんな
          </td>
          <td style={{verticalAlign: "top"}}>
            しはりまんな
          </td>
          <td style={{verticalAlign: "top"}}>
            {addAudio("1.2.f.13.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            しません
          </td>
          <td style={{verticalAlign: "top"}}>
            しまへん
          </td>
          <td style={{verticalAlign: "top"}}>
            しまへん
          </td>
          <td style={{verticalAlign: "top"}}>
            {addAudio("1.2.f.14.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            してください
          </td>
          <td style={{verticalAlign: "top"}}>
            しとくんなはれ
          </td>
          <td style={{verticalAlign: "top"}}>
            しとくれやす
          </td>
          <td style={{verticalAlign: "top"}}>
            {addAudio("1.2.f.15.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            はやいです
          </td>
          <td style={{verticalAlign: "top"}}>
            はよおます
          </td>
          <td style={{verticalAlign: "top"}}>
            はよおす
          </td>
          <td style={{verticalAlign: "top"}}>
            {addAudio("1.2.f.16.mp3")}
          </td>
        </tr>
        </tbody>
      </table>
      <br />
      <table style={{textAlign: "left", width: "15%"}} align="right" border="0" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addImage("maiko1.jpg")}
          </td>
        </tr>
        </tbody>
      </table>

      <div>
        In general, polite speech by the older generation holds the traditional
        features of the Kansai dialect observed in <i>Senba</i> dialect and <i>Gion</i> dialect.
        <i>Senba</i> type dialect is spoken by elderly people in the Osaka area as well as in the
        entertainment field and on local business occasions, whereas <i>Gion</i> type dialect is
        spoken in the <i>Gion</i> district, and by elderly people in the Kyoto area.
      </div>
      <br />
      <div>
        However, these features tend to be over emphasized in media and among
        people outside the Kansai area, which result in some people believing
        “every Osaka person speaks like an Osaka businessman and Kyoto people
        speak like geishas in the <i>Gion</i> district!” Please keep in mind that this is not the case.
      </div>
    </>
  );
};


export default SpeechStyle;