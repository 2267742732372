import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";

const V15 = () => {
  const transcript = <>
    今，あの、かしわのなあ。かしわかてなあ、一人やったらもう{addRubyText("面倒","めんど")}くさいねん。い、あんなん{addRubyText("手羽","てば")}{addRubyText("一枚","いちまい")}{addRubyText("買","こ")}うてき
    ても、{addRubyText("余分","よぶん")}やろ。{addRubyText("唐揚","からあ")}げしても。もう。せや〜。ほんでなあ、ちょこっと。あの、お{addRubyText("魚","さかな")}{addRubyText("焼","や")}いたりやったらなあ、
    お魚{addRubyText("一切","ひとき")}れ焼いたらええから、なんやしな。{addRubyText("昨日","きのう")}は{addRubyText("肉","にく")}じゃが。ちょびっちょびっと。もう〜{addRubyText("何","なん")}にも{addRubyText("残","のこ")}
    らんように{addRubyText("思","おも")}て。まだ{addRubyText("今晩","こんばん")}もっぺん、{addRubyText("夕","ゆう")}べ食べて{addRubyText("今朝","けさ")}食べて、今晩もっぺん<span
    style={{textDecoration: "underline"}}>食べんと</span>(Grammar
    2)。今晩食べても{addRubyText("終","お")}わらへん。
  </>;

  const qaObjects = [
    {
      question: <span>Q1: What is her opinion regarding cooking meals for herself?</span>,
      answersCorrect: [
        <>The amount of most meals are too much for cooking and eating by herself.</>
      ],
      answersIncorrect: [
        <>She feels lonely cooking just for herself without sharing meals with others.</>,
        <>She would rather eat out than cook a small amount for herself.</>
      ]
    },
    {
      question: <span>Q2: What does she mention regarding {addRubyText("肉","にく")}じゃが?</span>,
      answersCorrect: [
        <>She has been eating it since yesterday. She has to eat it tonight again but there would still be leftovers.</>
      ],
      answersIncorrect: [
        <>She has been eating it since yesterday. She is tired of it and she does not plan on eating it tonight anymore.</>,
        <>She has been eating it since yesterday. She will finish it finally tonight.</>
      ]
    }
  ];

  return <>
    <h2>ビデオ{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}１５: Authentic - medium<br />
    </h2>
    Watch the video clip and choose the answer that matches the content of
    each clip. Submit your answers after answering all the questions.<br />
    <small>*Click "SHOW" if you need to refer to the transcript.</small><br />
    <br />
    Ishioh-san is talking about meals for herself who lives alone.<br />
    <table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2"
    cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>{addVideoPlayer("4.2.2.p.4.ishioh.mp4")}</td>
          <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>
            Transcript
            <Hideable content={transcript}/><br />
            ＊ かしわ(Kansai) = {addRubyText("鶏肉","とりにく")} chicken<br />
            ＊{addRubyText("面倒","めんど")}くさい(Kansai) = めんどうくさい troublesome, tiresome<br />
            ＊{addRubyText("手羽","てば")}chicken wing<br />
            ＊{addRubyText("余分","よぶん")} extra, excess
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>&nbsp;イシオウさん (Shiga) </td>
        </tr>
      </tbody>
    </table>
    <br />
    <QandAForm qaObjects={qaObjects}/>
  </>;
}

export default V15;
