import React from "react";
import { addRubyText, addAudio } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";

const P5 = () => {
  const transcript = <>
    <ol>
      <li>A: このクッキー、食べるよ。</li>
      <li>B: あ！食べないで、食べないで！</li>
    </ol>
  </>;
  return <>
    <h2>{addRubyText("練習","れんしゅう")}5 : Negative Request: 〜んといて</h2>
    Form a question and an answer for each cue according to
    a model. You may listen to the question, try to answer it, and check
    your answer. *Answers vary; the recorded answers indicate those
    commonly
    used.<br />
    <small>*Mouse over or click on the speaker icon for sound</small><br />
    <br />
    <span style={{fontWeight: "bold"}}>Model</span> (Cue: このクッキー／{addRubyText("食","た")}べる) 
    <br />
    &nbsp;&nbsp;&nbsp; A: このクッキー、食べるで。
    <br />
    &nbsp;&nbsp;&nbsp; B: あ！食べんといて食べんといて！{addAudio("3.2.p.5.mp3")}
    <br />
    <br />
    Point: 〜ないで "Please don't ~" ー＞〜んといて (refer to Grammar 5)
    <br />
    <br />
    ST transcript
    <br />
    <Hideable content={transcript} />
    <br />
    <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Cue<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            A (Question)<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            B (Answer)<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            このクッキー／{addRubyText("食","た")}べる<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.5.1.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.5.1.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            この{addRubyText("手紙","てがみ")}／{addRubyText("読","よ")}む</td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.5.2.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.5.2.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            このビール／{addRubyText("飲","の")}む</td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.5.3.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.5.3.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addRubyText("花","はな")}／{addRubyText("水","みず")}／やる</td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.5.4.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.5.4.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addRubyText("鍵","かぎ")}／かばん／{addRubyText("入","い")}れる</td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.5.5.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.5.5.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            テレビ／{addRubyText("消","け")}す</td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.5.6.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.5.6.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addRubyText("電気","でんき")}／つける</td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.5.7.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.5.7.b.mp3")}<br />
          </td>
        </tr>
      </tbody>
    </table>
  </>;
}

export default P5;
