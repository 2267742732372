import React from "react";
import { addRubyText, addImage } from "common/util/generalUtil";
import { Link } from "react-router-dom";
import { Page } from "home/page";

const Listening = () => {
  return <>
    <h2>{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")} (Listening Practice)</h2>
    <br/>
    <table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td colSpan="1" rowSpan="1" style={{verticalAlign: "top"}}>
            <ul>
              <li>
                <Link to={Page.BasicGrammar.VerbTeForm.Listening.L1}>
                  {addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}1
                </Link>
              </li>
              <li>
                <Link to={Page.BasicGrammar.VerbTeForm.Listening.L2}>
                  {addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}2
                </Link>
              </li>
            </ul>
          </td>
          <td colSpan="1" rowSpan="1" style={{verticalAlign: "top", textAlign: "center"}}>
            {addImage("kiyomizudera.jpg")}
            <br/>
            <small>
              清水寺 Kiyomizu Temple (Kyoto)
            </small>
            <br/>
          </td>
        </tr>
        <tr align="center">
        </tr>
      </tbody>
    </table>
  </>;
}

export default Listening;