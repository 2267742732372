import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";

const V1 = () => {
  const transcript = <>
    なんや、{addRubyText("難","むつか")}しこと、<span style={{textDecoration: "underline"}}>{addRubyText("言","い")}わんといて(</span>Grammar 5)や。
  </>;

  const qaObjects = [
    {
      question: <>Q: What did the elderly woman mean?</>,
      answersCorrect: [
        <>Please do not say difficult things.</>
      ],
      answersIncorrect: [
        <>Please say difficult things.</>,
        <>I am not going to say anything difficult.</>,
        <>Why don't you say difficult things?</>
      ]
    }
  ]

  return <>
    <h2>ビデオ{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}１: Authentic - short<br /></h2>
    <big>
    <span style={{fontWeight: "bold"}}></span></big>This is
    a video clip at the beginning of an interview. Watch the clip and
    answer the following question. <br />
    <small>
      * Click "SHOW" if you need to refer to the transcript.
    </small>
    <br />
    <br />
    <div className="checkupquestion">
      <table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2" cellSpacing="2">
        <tbody>
          <tr>
            <td style={{verticalAlign: "top"}}>
              {addVideoPlayer("3.2.pl0.1.mp4")}
            </td>
            <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>
              Transcript
              <Hideable content={transcript} />
            </td>
          </tr>
          <tr>
            <td style={{verticalAlign: "top"}}>
              イシオウさん (Shiga)
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <QandAForm qaObjects={qaObjects} />
    </div>
  </>;
}

export default V1;
