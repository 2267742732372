import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";

const Peddler = () => {
  const vocabList = <ul style={{display: "block", overflow: "visible"}}>
    <li>
      〜さかい (Kansai) = 〜から／ので
    </li>
    <li>
      そうでっか (Kansai) = そうですか
    </li>
    <li>
      ぎょうさん (Kansai) = たくさん
    </li>
    <li>
      おまめさん (Kansai) = {addRubyText("豆","まめ")} beans
    </li>
    <li>
      {addRubyText("便利菜","べんりな")} a kind of leaf vegetable
    </li>
    <li>
      {addRubyText("消毒","しょうどく")} sterilization
    </li>
    <li>
      {addRubyText("虫","むし")} insect
    </li>
    <li>
      {addRubyText("食","く")}う eat (blunt)
    </li>
    <li>
      どうもおへん (Kansai, polite) = {addRubyText("別","べつ")}に{addRubyText("大丈夫","だいじょうぶ")}です
    </li>
    <li>
      {addRubyText("小松菜","こまつな")} a kind of leaf vegetable, Japanese mustard spinach
    </li>
    <li>
      {addRubyText("見","み")}てみ (Kansai) = 見てみなさい (imperative, gentle - refer to Ch4.L3.G1)
    </li>
    <li>
      こんなけ (Kansai) = こんなに
    </li>
    <li>
      {addRubyText("農薬","のうやく")} pesticide
    </li>
    <li>
      ほうれん{addRubyText("草","そう")} spinach
    </li>
    <li>
      やらかい (Kansai) = {addRubyText("柔","やわ")}らかい soft, tender
    </li>
    <li>
      いっかも (Kansai) = {addRubyText("何日","なんにち")}も many days
    </li>
    <li>
      おい！= Hey! (blunt)
    </li>
    <li>
      どうもならん (Kansai) = どうにもならない helpless, futile
    </li>
  </ul>;

  const qaObjects = [
    {
      question: <span>Q1: What did the farmer ask the video shooter?</span>,
      answersIncorrect: [
        <span>Are you going to America?</span>,
        <span>Are you American?</span>
      ],
      answersCorrect: [
        <span>Are you from America?</span>
      ]
    },
    {
      question: <span>Q2: Why did the video shooter say "ええですよ" to the farmer?</span>,
      answersIncorrect: [
        <span>She didn’t want the farmer to be in the shot.</span>,
        <span>She flattered the farmer, praising her looks.</span>
      ],
      answersCorrect: [
        <span>She pretended she had finished taking a still picture.</span>
      ]
    },
    {
      question: <span>Q3: Why were there not many vegetables?</span>,
      answersIncorrect: [
        <span>The farmer didn’t harvest many vegetables on the day.</span>
      ],
      answersCorrect: [
        <span>The farmer had sold quite a lot by the time.</span>
      ]
    },
    {
      question: <span>Q4: What was ミツエさん’s comment on broccoli?</span>,
      answersIncorrect: [
        <span>It was delicious.</span>,
        <span>It was hard.</span>,
        <span>It was not bad.</span>
      ],
      answersCorrect: [
        <span>It was tender.</span>
      ]
    },
    {
      question: <span>Q5: Why were there so many holes on the leafy vegetables?</span>,
      answersIncorrect: [
        <span>The farmer didn’t use pesticide strong enough to kill insects.</span>,
        <span>The farmer got an unusual amount of insects this year.</span>
      ],
      answersCorrect: [
        <span>The farmer didn’t use any pesticide.</span>
      ]
    },
    {
      question: <span>Q6: What was ミツエさん’s comment on the vegetables full of holes? Choose all that apply.</span>,
      answersIncorrect: [
        <span>Such vegetables are not appetizing!</span>,
        <span>You cannot sell such vegetables at the super market!</span>
      ],
      answersCorrect: [
        <span>Such vegetables are rare!</span>,
        <span>You cannot find such vegetables at the super market!</span>
      ]
    },
    {
      question: <span>Q7: When ミツエさん approached her neighbor’s puppy, why was the puppy looking away from her?</span>,
      answersIncorrect: [
        <span>The puppy does not like ミツエさん.</span>,
        <span>The puppy had to pee.</span>
      ],
      answersCorrect: [
        <span>The puppy took notice of a cat nearby.</span>
      ]
    }
  ];

  return (
    <>
      <h2>{addRubyText("野菜","やさい")}の{addRubyText("行商","ぎょうしょう")} (Produce Peddler in Otsu, Shiga)</h2>
      <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>
            [Context] A local farmer arrived in front of the house of ミツエさん and her daughter (who shot the video).
          </td>
        </tr>
        </tbody>
      </table>
      <br />
      <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addVideoPlayer("5.4.peddler.mp4")}<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            Vocabulary List <br />
            <Hideable content={vocabList} />
          </td>
        </tr>
        </tbody>
      </table>
      <h3>{addRubyText("内容質問","ないようしつもん")} (Content Questions)</h3>
      <QandAForm qaObjects={qaObjects} />
    </>
  );
};

export default Peddler;
