import React from "react";
import { Link } from "react-router-dom";
import { Page } from "home/page";
import { addRubyText, addAudio } from "common/util/generalUtil";

const Conjugation = () => {
  return (
    <>
      <h2>Conjugation (Morpho-Syntactic Feature)</h2>
      <div>
        Here the major grammatical differences of the Kansai dialect are
        described (For detailed explanation and examples, please refer
        to <Link to={Page.BasicGrammar}>Chapter 3. Basic Grammar</Link> and <Link to={Page.FunctionalGrammar}>
        Chapter 4. Functional Grammar</Link> sections). What we are dealing with here
        is the grammar pattern in direct style (casual speech) used among
        family, friends and close acquaintances. We will also see the use of
        speech style and the characteristics in distal style (polite speech) in
        <Link to={Page.Characteristics.LinguisticAspects.SpeechStyle}> Speech Style</Link>.
        In addition, there are a wide variety of minor
        differences even among Kansai dialect speakers depending on region,
        gender and age. We will focus on the most noticeable features observed
        in Kyoto and Osaka.
      </div>
      <div>
        <small>*Mouse over or click on the speaker icon for sound</small>
      </div>
      <hr />

      <h3>1) Verbs (Verbals)</h3>
      <h4>Verbal conjugation examples on {addRubyText("食","た")}べる,
        {addRubyText("買","か")}う, する and {addRubyText("来","く")}る</h4>
      <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top"}} />
          <td colSpan="2" rowSpan="1" style={{verticalAlign: "top", textAlign: "center"}}>
            {addRubyText("食","た")}べる
          </td>
          <td colSpan="2" rowSpan="1" style={{verticalAlign: "top", textAlign: "center"}}>
            {addRubyText("買","か")}う
          </td>
          <td colSpan="2" rowSpan="1" style={{verticalAlign: "top", textAlign: "center"}}>
            する
          </td>
          <td colSpan="2" rowSpan="1" style={{verticalAlign: "top", textAlign: "center"}}>
            {addRubyText("来","く")}る
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>Verb Classification <br />
          </td>
          <td colSpan="2" rowSpan="1" style={{verticalAlign: "top", textAlign: "center"}}>
            るVerb (Class 2 Verb, Vowel Verbal)
          </td>
          <td colSpan="2" rowSpan="1" style={{verticalAlign: "top", textAlign: "center"}}>
            うVerb (Class 1 Verb, Consonant Verbal)
          </td>
          <td colSpan="2" rowSpan="1" style={{verticalAlign: "top", textAlign: "center"}}>
            Irregular Verb (Class 3 Verb)
          </td>
          <td colSpan="2" rowSpan="1" style={{verticalAlign: "top", textAlign: "center"}}>
            Irregular Verb (Class 3 Verb)
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}} />
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Tokyo<br />
            (Listen all)<br />
            {addAudio("1.2.d.1.11.s.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Kansai <br />
            (Listen all) <br />
            {addAudio("1.2.d.1.11.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Tokyo<br />
            (Listen all)<br />
            {addAudio("1.2.d.1.1.s.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Kansai<br />
            (Listen all)<br />
            {addAudio("1.2.d.1.1.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Tokyo<br />
            (Listen all)<br />
            {addAudio("1.2.d.1.20.s.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Kansai<br />
            (Listen all)<br />
            {addAudio("1.2.d.1.20.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Tokyo<br />
            (Listen all)<br />
            {addAudio("1.2.d.1.29.s.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Kansai<br />
            (Listen all)<br />
            {addAudio("1.2.d.1.29.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>
            Imperative (Non-past)<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            たべる
          </td>
          <td style={{verticalAlign: "top"}}>
            たべる {addAudio("1.2.d.1.12.mp3")}
          </td>
          <td style={{verticalAlign: "top", textAlign: "left"}}>
            かう
          </td>
          <td style={{verticalAlign: "top", textAlign: "left"}}>
            かう {addAudio("1.2.d.1.2.mp3")}
          </td>
          <td style={{verticalAlign: "top"}}>
            する
          </td>
          <td style={{verticalAlign: "top"}}>
            する {addAudio("1.2.d.1.21.mp3")}</td>
          <td style={{verticalAlign: "top"}}>
            くる<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            くる {addAudio("1.2.d.1.30.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>
            Negative<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            たべない<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            たべへん {addAudio("1.2.d.1.13.mp3")}
          </td>
          <td style={{verticalAlign: "top", textAlign: "left"}}>
            かわない<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "left"}}>
            かわへん(Kyoto) {addAudio("1.2.d.1.3.mp3")}<br />
            かえへん(Osaka) {addAudio("1.2.d.1.4.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            しない
          </td>
          <td style={{verticalAlign: "top"}}>
            しいひん；せえへん
            {addAudio("1.2.d.1.22.mp3")}
          </td>
          <td style={{verticalAlign: "top"}}>
            こない
          </td>
          <td style={{verticalAlign: "top"}}>
            きいひん；こうへん
            {addAudio("1.2.d.1.31.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>
            Perfective (Past)
          </td>
          <td style={{verticalAlign: "top"}}>
            たべた
          </td>
          <td style={{verticalAlign: "top"}}>
            たべた {addAudio("1.2.d.1.14.mp3")}
          </td>
          <td style={{verticalAlign: "top", textAlign: "left"}}>
            かった
          </td>
          <td style={{verticalAlign: "top", textAlign: "left"}}>
            こうた　{addAudio("1.2.d.1.5.mp3")}
          </td>
          <td style={{verticalAlign: "top"}}>
            した
          </td>
          <td style={{verticalAlign: "top"}}>
            した {addAudio("1.2.d.1.23.mp3")}
          </td>
          <td style={{verticalAlign: "top"}}>
            きた
          </td>
          <td style={{verticalAlign: "top"}}>
            きた {addAudio("1.2.d.1.32.mp3")}</td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>
            Perfective-Negative (Past-Negative)
          </td>
          <td style={{verticalAlign: "top"}}>
            たべなかった
          </td>
          <td style={{verticalAlign: "top"}}>
            たべへんかった {addAudio("1.2.d.1.15.mp3")}</td>
          <td style={{verticalAlign: "top", textAlign: "left"}}>
            かわなかった
          </td>
          <td style={{verticalAlign: "top", textAlign: "left"}}>
            かわへんかった{addAudio("1.2.d.1.6.mp3")}
          </td>
          <td style={{verticalAlign: "top"}}>
            しなかった
          </td>
          <td style={{verticalAlign: "top"}}>
            しいひんかった；せえへんかった {addAudio("1.2.d.1.24.mp3")}
          </td>
          <td style={{verticalAlign: "top"}}>
            こなかった
          </td>
          <td style={{verticalAlign: "top"}}>
            きいひんかった；こうへんかった {addAudio("1.2.d.1.33.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>
            Provisional;Conditional
          </td>
          <td style={{verticalAlign: "top"}}>
            たべれば；たべたら
          </td>
          <td style={{verticalAlign: "top"}}>
            たべたら {addAudio("1.2.d.1.16.mp3")}</td>
          <td style={{verticalAlign: "top", textAlign: "left"}}>
            かえば；かったら
          </td>
          <td style={{verticalAlign: "top", textAlign: "left"}}>
            こうたら　{addAudio("1.2.d.1.7.mp3")}
          </td>
          <td style={{verticalAlign: "top"}}>
            すれば；したら
          </td>
          <td style={{verticalAlign: "top"}}>
            したら {addAudio("1.2.d.1.25.mp3")}</td>
          <td style={{verticalAlign: "top"}}>
            くれば；きたら
          </td>
          <td style={{verticalAlign: "top"}}>
            きたら {addAudio("1.2.d.1.34.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>
            Gerund(TE-form)
          </td>
          <td style={{verticalAlign: "top"}}>
            たべて
          </td>
          <td style={{verticalAlign: "top"}}>
            たべて {addAudio("1.2.d.1.17.mp3")}</td>
          <td style={{verticalAlign: "top", textAlign: "left"}}>
            かって
          </td>
          <td style={{verticalAlign: "top", textAlign: "left"}}>
            こうて　{addAudio("1.2.d.1.8.mp3")}
          </td>
          <td style={{verticalAlign: "top"}}>
            して
          </td>
          <td style={{verticalAlign: "top"}}>
            して {addAudio("1.2.d.1.26.mp3")}
          </td>
          <td style={{verticalAlign: "top"}}>
            きて
          </td>
          <td style={{verticalAlign: "top"}}>
            きて {addAudio("1.2.d.1.35.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>
            Imperative
          </td>
          <td style={{verticalAlign: "top"}}>
            たべろ
          </td>
          <td style={{verticalAlign: "top"}}>
            たべ（え） {addAudio("1.2.d.1.18.mp3")}
          </td>
          <td style={{verticalAlign: "top", textAlign: "left"}}>
            かえ
          </td>
          <td style={{verticalAlign: "top", textAlign: "left"}}>
            かい（い） {addAudio("1.2.d.1.9.mp3")}
          </td>
          <td style={{verticalAlign: "top"}}>
            しろ
          </td>
          <td style={{verticalAlign: "top"}}>
            しい {addAudio("1.2.d.1.27.mp3")}
          </td>
          <td style={{verticalAlign: "top"}}>
            こい
          </td>
          <td style={{verticalAlign: "top"}}>
            きい {addAudio("1.2.d.1.36.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>
            Consultative
          </td>
          <td style={{verticalAlign: "top"}}>
            たべよう
          </td>
          <td style={{verticalAlign: "top"}}>
            たべよ {addAudio("1.2.d.1.19.mp3")}
          </td>
          <td style={{verticalAlign: "top", textAlign: "left"}}>
            かおう
          </td>
          <td style={{verticalAlign: "top", textAlign: "left"}}>
            かお {addAudio("1.2.d.1.10.mp3")}
          </td>
          <td style={{verticalAlign: "top"}}>
            しよう
          </td>
          <td style={{verticalAlign: "top"}}>
            しよ {addAudio("1.2.d.1.28.mp3")}
          </td>
          <td style={{verticalAlign: "top"}}>
            こよう
          </td>
          <td style={{verticalAlign: "top"}}>
            こよ {addAudio("1.2.d.1.37.mp3")}
          </td>
        </tr>
        </tbody>
      </table>
      <br />

      <div>
        As seen in the example above, the most noticable differences are
        observed in negative endings. Negative endings of verbs take the forms
        of –n or –hen (hin) instead of the standard –nai. Even the negative
        form of ある comes to あらへん, following the formation rule. (*There are
        wide range of variations in する and {addRubyText("来","く")}る nagatives - we will discuss
        this later in Chapter 3.)
      </div>
      <br />

      <div>
        Imperative forms have an euphonic (agreeable sound) feature as
        in かい（い）instead of かえ. The euphonic change is also observed in some う
        verbs of the Kansai dialect as
        in the perfective form こうた instead of かった, and gerund form こうて instead
        of かって. Also provisional (-ba form) is rarely used
        in the Kansai dialect, and instead, conditional (~tara form) is widely
        used for most of the occasion.
      </div>
      <br />
      <hr />

      <h3>2) Adjectives (Adjectivals)</h3>
      <h4>Adjectival conjugation examples on {addRubyText("早","はや")}い／{addRubyText("速","はや")}い </h4>
      <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top"}} />
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Tokyo (Listen all) {addAudio("1.2.d.2.1.s.mp3")}
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Kansai (Listen all) {addAudio("1.2.d.2.1.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            Imperfective (Non-past)
          </td>
          <td style={{verticalAlign: "top"}}>
            はやい
          </td>
          <td style={{verticalAlign: "top"}}>
            はやい {addAudio("1.2.d.2.2.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            Negative
          </td>
          <td style={{verticalAlign: "top"}}>
            はやくない
          </td>
          <td style={{verticalAlign: "top"}}>
            はよ（う）ない {addAudio("1.2.d.2.3.mp3")}
            はよ（う）あらへん {addAudio("1.2.d.2.4.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            Perfective (Past)
          </td>
          <td style={{verticalAlign: "top"}}>
            はやかった
          </td>
          <td style={{verticalAlign: "top"}}>
            はやかった {addAudio("1.2.d.2.5.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            Perfective-Negative (Past-Negative)
          </td>
          <td style={{verticalAlign: "top"}}>
            はやくなかった
          </td>
          <td style={{verticalAlign: "top"}}>
            はよ（う）なかった {addAudio("1.2.d.2.6.mp3")}<br />
            はよ（う）あらへんかった {addAudio("1.2.d.2.7.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            Provisional; Conditional
          </td>
          <td style={{verticalAlign: "top"}}>
            はやければ；はやかったら
          </td>
          <td style={{verticalAlign: "top"}}>
            はやかったら {addAudio("1.2.d.2.8.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            Gerund (TE-form)
          </td>
          <td style={{verticalAlign: "top"}}>
            はやくて
          </td>
          <td style={{verticalAlign: "top"}}>
            はよ（う）て {addAudio("1.2.d.2.9.mp3")}<br />
            (or はやかって in the past)<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            Adverbial
          </td>
          <td style={{verticalAlign: "top"}}>
            はやく
          </td>
          <td style={{verticalAlign: "top"}}>
            はよ（う） {addAudio("1.2.d.2.10.mp3")}
          </td>
        </tr>
        </tbody>
      </table>

      <div>
        *Refer to 3) Noun (Nominal) & な Adjectives (な Nominal) + Copula.
      </div>
      <div>
        As you can see in the example above, an euphonic change is observed in
        -Ku form, as in はよ（う）instead of はやく. Other examples are;
      </div>
      <table style={{textAlign: "left", width: "50%"}} border="1" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Standard {addAudio("1.2.d.2.11.s.mp3")}
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Kansai {addAudio("1.2.d.2.11.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addRubyText("高","たか")}く
          </td>
          <td style={{verticalAlign: "top"}}>
            たこ（う）
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            よく
          </td>
          <td style={{verticalAlign: "top"}}>
            よう
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addRubyText("小","ちい")}さく</td>
          <td style={{verticalAlign: "top"}}>
            ちいそ（う）
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addRubyText("大","おお")}きく
          </td>
          <td style={{verticalAlign: "top"}}>
            おおき（ゅう）
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addRubyText("悪","わる")}く
          </td>
          <td style={{verticalAlign: "top"}}>
            わる（う）
          </td>
        </tr>
        </tbody>
      </table>
      <br />

      <div>
        ない in negative form is replaced with あらへん; however, the use of ない is
        also common among young people who speak "neo-dialect".
      </div>
      <br />
      <hr />

      <h3>3) Noun (Nominal) な Adjectives (な Nominal) + Copula</h3>
      <h4>Copula Conjugation example on {addRubyText("雨","あめ")}だ "It’s rain/rainy."</h4>
      <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top"}} />
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Tokyo (Listen all)
            {addAudio("1.2.d.3.1.s.mp3")}
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Kansai (Listen all)
            {addAudio("1.2.d.3.1.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            Imperfective (Past)
          </td>
          <td style={{verticalAlign: "top"}}>
            雨だ
          </td>
          <td style={{verticalAlign: "top"}}>
            雨や {addAudio("1.2.d.3.2.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            Negative
          </td>
          <td style={{verticalAlign: "top"}}>
            雨じゃない
          </td>
          <td style={{verticalAlign: "top"}}>
            雨やあらへん {addAudio("1.2.d.3.3.mp3")}<br />
            雨やない {addAudio("1.2.d.3.4.mp3")}<br />
            雨 (と)ちゃう {addAudio("1.2.d.3.5.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            Perfective (Past)
          </td>
          <td style={{verticalAlign: "top"}}>
            雨だった
          </td>
          <td style={{verticalAlign: "top"}}>
            雨やった {addAudio("1.2.d.3.6.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            Perfective-Negative (Past-Negative)
          </td>
          <td style={{verticalAlign: "top"}}>
            雨じゃなかった
          </td>
          <td style={{verticalAlign: "top"}}>
            雨やあらへんかった {addAudio("1.2.d.3.7.mp3")}<br />
            雨やなかった {addAudio("1.2.d.3.8.mp3")}<br />
            雨（と)ちごた {addAudio("1.2.d.3.9.mp3")}<br />
            雨（と） ちゃうかった {addAudio("1.2.d.3.10.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            Providional; Conditional
          </td>
          <td style={{verticalAlign: "top"}}>
            雨なら；雨であれば；雨だったら
          </td>
          <td style={{verticalAlign: "top"}}>
            雨やったら {addAudio("1.2.d.3.11.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            Tentative
          </td>
          <td style={{verticalAlign: "top"}}>
            雨だろう
          </td>
          <td style={{verticalAlign: "top"}}>
            雨やろ {addAudio("1.2.d.3.12.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            Gerund (TE-form)
          </td>
          <td style={{verticalAlign: "top"}}>
            雨で
          </td>
          <td style={{verticalAlign: "top"}}>
            雨で{addAudio("1.2.d.3.13.mp3")}<br />
            (or 雨やって in the past)<br />
          </td>
        </tr>
        </tbody>
      </table>
      <br />

      <div>
        As seen above, the copula だ in the Standard Japanese is replaced with や
        in the Kansai dialect. N(と)ちゃう "different from N" , and
        N(と)ちごた"was different from N" are widely used as replacements of
        Nやあらへん and Nやあらへんかった respectively.
      </div>
      <br />
    </>
  );
};


export default Conjugation;