import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";

const V6 = () => {
  const transcript = <>
    まあまあそれはほんまにその{addRubyText("土地土地","とちとち")}の、あの、{addRubyText("歴史","れきし")}がある{addRubyText("言葉","ことば")}やから、あえてそれを{addRubyText("標準語","ひょうじゅんご")}に<span
    style={{textDecoration: "underline"}}>
    {addRubyText("話","はな")}せなだめだ</span> (Grammar 3)、みたいのはないと思いますけどねえ。
  </>;

  const qaObjects = [
    {
      question: <span>Q: What is his opinion?</span>,
      answersCorrect: [
        <>They don’t need to be able to speak Standard, as dialects are heritage languages rich in history.</>
      ],
      answersIncorrect: [
        <>They need to be able to speak Standard, as dialects are already ancient languages.</>,
        <>They should be able to speak Standard even though it is true that dialects are historically important.</>,
        <>They don’t have to be able to speak Standard, as Standard is historically not as important as dialects.</>
      ]
    }
  ];

  return <>
    <h2>ビデオ{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}６: Authentic - short<br />
    </h2>
    Watch the video clip and choose the answer that matches the content of
    the clip.<br />
    <small>*Click "SHOW" if you need to refer to the transcript.</small><br />
    <br />
    Takayama-san was asked if he thinks Kansai people should be able to
    speak Standard Japanese.<br />
    <table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2"
    cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>{addVideoPlayer("4.2.3.p.1.takayama.mp4")} <br />
          </td>
          <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>Transcript
            <Hideable content={transcript}/>
            <br />
            ＊{addRubyText("土地","とち")} land, local area<br />
            ＊{addRubyText("歴史","れきし")} history
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>&nbsp;&nbsp;タカヤマさん (Kyoto)</td>
        </tr>
      </tbody>
    </table>
    <br />
    <QandAForm qaObjects={qaObjects}/>
  </>;
}

export default V6;
