import React from "react";
import { addRubyText, addAudio } from "common/util/generalUtil";

const QuotationMarker = () => {
  return <>
    <h2>Notes on Quotation Marker</h2>
    Kansai people tend to drop the quotation marker と (Note that dropping って／と is ungrammatical in the standard.)
    <br />
    <br />
    パパに{addRubyText("宿題","しゅくだい")}もうした、{addRubyText("言","ゆ")}うて。{addAudio("3.1.b.n.1.mp3")}<br />
    <small>（ST パパに宿題はもうした<span style={{textDecoration: "underline"}}>って</span>{addRubyText("言","い")}って。）</small>
    <small>{addAudio("3.1.b.n.1.s.mp3")}</small><br />
    <br />
    しよ{addRubyText("思","おも")}たけどせえへんかった。<small>&nbsp;</small>{addAudio("3.1.b.n.2.mp3")}
    <br />
    <small>（ST しよう<span style={{textDecoration: "underline"}}>と</span>思ったけどしなかった。）</small><small>{addAudio("3.1.b.n.2.s.mp3")}</small>
    <br />
    <br />
    Therefore, XというY tends to become Xいう／ゆうY in Kansai. (XちゅうY is also
    commonly used. ちゅう is a concentrated form of という.)
    <br />
    <br />
    あの{addRubyText("会社","かいしゃ")}、{addRubyText("何","なん")}ちゅうたかなあ。{addAudio("3.1.b.n.3.mp3")}
    <br />
    <small> (ST あの会社は何<span style={{textDecoration: "underline"}}>て</span>言ったかなあ。）</small><small>{addAudio("3.1.b.n.3.s.mp3")}</small>
    <br />
    <br />
    さやさちゃん言う{addRubyText("子","こ")}から{addRubyText("電話","でんわ")}あったで。{addAudio("3.1.b.n.4.mp3")}
    <br />
    <small>(ST さやさちゃん<span style={{textDecoration: "underline"}}>って</span>言う子から電話があったよ。）</small><small>{addAudio("3.1.b.n.4.s.mp3")}</small>
    <br />
    <br />
  </>;
}

export default QuotationMarker;
