import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";

const V2 = () => {
  const transcript = <>
    {addRubyText("何","なん")}でしょうね。そんなこれとゆうものもないんですけども、「<span
    style={{textDecoration: "underline"}}>そんなことしたあかんやろ</span>(Grammar
    1)」って。、、ま、いろいろあるんですけども、{addRubyText("親父","おやじ")}が{addRubyText("結構","けっこう")}{addRubyText("酒","さけ")}が{addRubyText("好","す")}きなんですよ。ほんでさ、{addRubyText("騒","さわ")}ぐ、、、騒ぐってゆうのかそんなひどい騒ぎ{addRubyText("方","かた")}じゃない
    んですけど，{addRubyText("僕","ぼく")}はそれがあんま{addRubyText("好","す")}きじゃないんですよ、、、{addRubyText("飲","の")}み{addRubyText("過","す")}ぎやって。
    <br />
  </>;

  const qaObjects = [
    {
      question: <span>Q1: How does he scold his father?</span>,
      answersCorrect: [
        <>He says “You must not do such a thing!”</>,
      ],
      answersIncorrect: [
        <>He says “You must be quiet!”</>,
        <>He says “You must follow the way it’s supposed to be!”</>,
        <>He says “You must not be noisy like that!”</>
      ]
    },
    {
      question: <span>Q2: Why does he scold his father?</span>,
      answersCorrect: [
        <>Because his father gets noisy when he drinks too much</>
      ],
      answersIncorrect: [
        <>Because his father tends to make a big slurping noise when drinking</>,
        <>Because his father speaks laud when he buys alcohol</>,
        <>Because his father drinks too much whenever people get together</>
      ]
    }
  ]

  return <>
    <h2>ビデオ{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}２: Authentic - medium<br />
    </h2>
    Watch the video clip and choose the answer that matches the content of
    the clip.
    Submit your answers after answering all the questions.<br />
    <small>*Click "SHOW" if you need to refer to the transcript</small><br />
    <br />
    Context: Umemoto-san often scolds his father instead of being scolded.
    He was asked how he scolds his father.<br />
    <table style={{textAlign: "left", width: "100%"}} border="0"cellPadding="2"
    cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>{addVideoPlayer("4.1.1.p.3.umemoto.mp4")}</td>
          <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>
            Transcript
            <Hideable content={transcript}/>
            ＊{addRubyText("親父","おやじ")} my father (used mainly by male)<br />
            ＊{addRubyText("騒","さわ")}ぐ be noisy, make a disturbance
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>
            ウメモトさん (Shiga)
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    <QandAForm qaObjects={qaObjects}/>
  </>;
}

export default V2;
