import React from "react";
import { addRubyText, addAudio } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";

const P5 = () => {
  const transcript = <>
    <ol className="transcript">
      <li>A: その魚、いくらだったの？</li>
      <li>B: この魚？百円だったんだ。</li>
      <li>A: あ、そう。<br />
      </li>
    </ol>
  </>;
  return <>
    <h2>{addRubyText("練習","れんしゅう")}5 : N+Copula + extended predicate</h2>
    Form a question and an answer for each cue according to
    a model. You may listen to the question, tryto answer it, and check
    your answer. *Answers vary; the recorded answers indicate those
    commonly
    used.<br />
    <small>*Mouse over or click on the speaker icon for sound</small><br />
    <br />
    <span style={{fontWeight: "bold"}}>Model </span>(Cue:
    その{addRubyText("魚","さかな")}、なんぼ／{addRubyText("百円","ひゃくえん")})<br />
    &nbsp;&nbsp;&nbsp; A: その魚、なんぼ<span style={{textDecoration: "underline"}}>やっ
    たん</span>？<br />
    &nbsp;&nbsp;&nbsp; B: この魚？百円<span style={{textDecoration: "underline"}}>やっ
    てん</span>。<br />
    &nbsp;&nbsp;&nbsp; A: あ、ほうか。（そうか）{addAudio("3.3.p.5.mp3")}<br />
    <br />
    Point: When adding extended predicate on a past question-&gt; -Aん, in a
    past statement-&gt; -Eん (refer to Grammar 1 Extra 2)<br />
    <br />
    ST transcript
    <br />
    <Hideable content={transcript} />
    <br />
    <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>Cue<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>A (Question)<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>B (Answer)<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>その{addRubyText("魚","さかな")}、なんぼ／{addRubyText("百円","ひゃくえん")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.3.p.5.1.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.3.p.5.1.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>{addRubyText("電話","でんわ")}、{addRubyText("誰","だれ")}から／{addRubyText("先生","せんせい")}から<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.3.p.5.2.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.3.p.5.2.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>その{addRubyText("学会","がっかい")}、どこ／{addRubyText("神戸","こうべ")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.3.p.5.3.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.3.p.5.3.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>そのかばん、なんぼ／{addRubyText("千円","せんえん")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.3.p.5.4.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.3.p.5.4.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>テスト、いつ／{addRubyText("今日","きょう")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.3.p.5.5.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.3.p.5.5.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>その{addRubyText("番組","ばんぐみ")}、{addRubyText("何","なん")}チャンネル／{addRubyText("六","ろく")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.3.p.5.6.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.3.p.5.6.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>あの{addRubyText("車","くるま")}、だれの／{addRubyText("社長","しゃちょう")}の<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.3.p.5.7.a.mp3")}</td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.3.p.5.7.b.mp3")}</td>
        </tr>
      </tbody>
    </table>
  </>;
}

export default P5;
