import React from "react";
import { addRubyText, addImage, addAudio } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
const P3 = () => {
  return <>
    <h2>{addRubyText("練習","れんしゅう")}3: Situational practice</h2>
    <br />
    What would you say in the following situations in Kansai-ben?&nbsp;
    Listen to the question and reply to it in three different ways
    considering the nuance of each illustration. *Answers vary: the
    recorded answers indicate those commonly used.<br />
    <small>* Click "SHOW" if you need to refer to the transcript.&nbsp; <br />
    *Move the mouse over to the speaker icon for sound<br />
    <br />
    </small>
    <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
    <tbody>
    <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>Question <br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>Answer 1<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>Answer 2<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>Answer 3<br />
          </td>
    </tr>
    <tr>
          <td style={{verticalAlign: "top"}}>{addImage("4.1.p.3.1.jpg")}<br />
    {addAudio("4.1.p.3.1.mp3")} <Hideable content={<>このケーキ、{addRubyText("食","た")}べてもええ？</>}/><br />
          </td>
          <td style={{verticalAlign: "top"}}>{addImage("4.1.p.3.2.jpg")}<br />
    {addAudio("4.1.p.3.2.mp3")} <Hideable content={<>うん、食べてもかまへんよ。／食べてもええよ。</>}/><br />
          </td>
          <td style={{verticalAlign: "top"}}>{addImage("4.1.p.3.3.jpg")}<br />
    {addAudio("4.1.p.3.3.mp3")} <Hideable content={<>いや、食べた（ら）あかん！</>}/><br />
          </td>
          <td style={{verticalAlign: "top"}}>{addImage("4.1.p.3.4.jpg")}<br />
    {addAudio("4.1.p.3.4.mp3")} <Hideable content={<>ううん、食べんといて〜。</>}/><br />
          </td>
    </tr>
    <tr>
          <td style={{verticalAlign: "top"}}>{addImage("4.1.p.3.5.jpg")}<br />
    {addAudio("4.1.p.3.5.mp3")} <Hideable content={<>この{addRubyText("部屋","へや")}、{addRubyText("入","はい")}ってもかまへん？</>}/><br />
          </td>
          <td style={{verticalAlign: "top"}}>{addImage("4.1.p.3.6.jpg")}<br />
    {addAudio("4.1.p.3.6.mp3")} <Hideable content={<>ううん、入らんといて〜。</>}/><br />
          </td>
          <td style={{verticalAlign: "top"}}>{addImage("4.1.p.3.7.jpg")}<br />
    {addAudio("4.1.p.3.7.mp3")} <Hideable content={<>うん、入ってもかまへんよ。／入ってもええよ。</>}/><br />
          </td>
          <td style={{verticalAlign: "top"}}>{addImage("4.1.p.3.8.jpg")}<br />
    {addAudio("4.1.p.3.8.mp3")} <Hideable content={<>いや、入った（ら）あかん！</>}/><br />
          </td>
    </tr>
    </tbody>
    </table>
  </>;
}

export default P3;
