import React from "react";
import { Link } from "react-router-dom";
import { Page } from "home/page";
import { addRubyText, addImage } from "common/util/generalUtil";

const OtherResources = () => {
  return (
    <>
      <h2>Chapter 7: その{addRubyText("他","た")}(Other Resources)</h2>
      <table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>
            <ul>
              <li>
                <Link to={Page.OtherResources.AnimeManga}>Kansai anime and manga</Link> <br />
              </li>
              <li>
                <Link to={Page.OtherResources.Films}>Kansai films</Link>
              </li>
              <li>
                <Link to={Page.OtherResources.JPop}>Kansai JPOP</Link>
              </li>
              <li>
                <Link to={Page.OtherResources.Links}>Links</Link>
              </li>
              <li>
                <Link to={Page.OtherResources.Foreigners.Foreigners}>Interviews to Kansai foreigners</Link>
              </li>
              <li>
                <Link to={Page.OtherResources.LettersFromUsers}>Letters from users</Link>
              </li>
              <li>
                <Link to={Page.OtherResources.HowToUse}>How to use this site in class (for Teachers)</Link>
              </li>
            </ul>
          </td>
          <td style={{verticalAlign: "top"}}>
            <div style={{textAlign: "center"}}>
              {addImage("koyotofukuji.jpg")}<br />
            </div>
            <div style={{textAlign: "center"}}>
              <div>
                <small>東福寺の紅葉 </small>
              </div>
              <div>
                <small>Autumn foliage (Tofuku-ji temple, Kyoto)</small>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </>
  );
};

export default OtherResources;