import React from "react";
import { addRubyText, addImage } from "common/util/generalUtil";
import { Link } from "react-router-dom";
import { Page } from "home/page";

const Listening = () => {
  return <>
    <h2>{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")} (Listening Practice)</h2>
    <table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>
          <ul>
            <li>
              <Link to={Page.BasicGrammar.Copula.Listening.L1}>
                {addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}1
              </Link>
            </li>
          </ul>
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addImage("ginkakujiGarden8.jpg")}
            <br/>
            <small>
              銀閣寺 Ginkaku-ji Temple (Kyoto)
            </small>
            <br/>
          </td>
        </tr>
      </tbody>
    </table>
  </>;
}

export default Listening;