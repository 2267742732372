import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";

const V2 = () => {
  const transcript = <>
    こらって{addRubyText("感","かん")}じかなあ、ハハハ
    ハ、、、<span style={{textDecoration: "underline"}}>いい{addRubyText("加減","かげん")}にしとけ</span>
    (Grammar 1 Extra 2) って感じかなあ。
  </>;

  const qaObjects = [
    {
      question: <span>Q: How does he usually scold his daughter?</span>,
      answersCorrect: [
        <>Hey, Kid! Stop being selfish!</>
      ],
      answersIncorrect: [
        <>Hey, kid! Don’t eat everything by yourself (leave some for me)!</>,
        <>Hey, kid! Speak up!</>
      ]
    }
  ]
  return <>
    <h2>ビデオ{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}２：Authentic – short </h2>
    Watch the video clip and choose the answer that matches the content of
    the clip. <br />
    <small>*Click "SHOW" if you need to refer to the transcript.</small>
    <br />
    <br />
    Akira is asked how he usually scolds his daughter.
    <table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>{addVideoPlayer("4.3.1.p.1.akira.mp4")} </td>
          <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>Transcript
    <Hideable content={transcript}/>
    <br />
    &nbsp;＊ いい{addRubyText("加減","かげん")}にする leave (a thing) a half-way<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>アキラ (Osaka) </td>
        </tr>
      </tbody>
    </table>
    <QandAForm qaObjects={qaObjects}/>
  </>;
}

export default V2;
