import React from "react";
import { addRubyText, addImage, addAudio } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
const P4 = () => {
  const transcript = <>
    <ol>
      <li>A: ここで食べてもいいのかな。</li>
      <li>B: 食べるなって書いてるから、食べないでおいて（食べるな）。</li>
    </ol>
  </>;
  return <>
    <h2>{addRubyText("練習","れんしゅう")}4: Imperatives and Negative imperatives</h2>
    Your Kansai friend wonders if she is allowed to do certain things while
    driving. Listen to what she says, and suggest what she should do or not
    do, conveying the message of the sign (as a command) according to the
    model. Answers
    vary: the recorded answers indicate those commonly used.<br />
    <small>*Move the mouse over to the speaker icon for sound.</small>
    <br />
    <br />
    <table style={{textAlign: "center", width: "30%"}} align="right" border="0" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>{addImage("4.3.p.4.jpg")} </td>
        </tr>
      </tbody>
    </table>
    <span style={{fontWeight: "bold"}}>Model</span><br />
    &nbsp;&nbsp;&nbsp; A: ここで<span style={{textDecoration: "underline"}}>{addRubyText("食","た")}
    べてもええ</span>かな？<br />
    &nbsp;&nbsp;&nbsp; B: <span style={{textDecoration: "underline"}}>食べな</span>っ
    て{addRubyText("書","か")}いたるし、<span style={{textDecoration: "underline"}}>食べんとき</span>。{addAudio("4.3.p.4.mp3")}<br />
    <br />
    ST Transcript<br />
    <Hideable content={transcript} />
    <br />
    <table style={{textAlign: "left", width: "100%"}} border="1"cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>Sign<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>Your Friend(Question)<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>You (Reply)<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addImage("4.3.p.4.1.jpg")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "left"}}>{addAudio("4.3.p.4.1.a.mp3")} <Hideable content={<>ここに{addRubyText("車","くるま")}{addRubyText("止","と")}めてもええかな。</>}/><br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "left"}}>{addAudio("4.3.p.4.1.b.mp3")} <Hideable content={<>ここに{addRubyText("車","くるま")}{addRubyText("止","と")}めなって書いたるし、止めんとき。</>}/><br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addImage("4.3.p.4.2.jpg")}</td>
          <td style={{verticalAlign: "top", textAlign: "left"}}>{addAudio("4.3.p.4.2.a.mp3")} <Hideable content={<>ここ、スピード{addRubyText("出","だ")}してもかまへんの？</>}/><br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "left"}}>{addAudio("4.3.p.4.2.b.mp3")} <Hideable content={<>{addRubyText("時速","じそく")}３０キロ{addRubyText("以上","いじょう")}{addRubyText("出","だ")}しなって書いたるし、出さんとき。</>}/></td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addImage("4.3.p.4.3.jpg")}</td>
          <td style={{verticalAlign: "top", textAlign: "left"}}>{addAudio("4.3.p.4.3.a.mp3")} <Hideable content={<>{addRubyText("海","うみ")}きれいやな。ここで{addRubyText("泳","およ")}いでもかまへんかな。</>}/><br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "left"}}>{addAudio("4.3.p.4.3.b.mp3")} <Hideable content={<>ここで{addRubyText("泳","およ")}ぎなって書いたるし、泳がんとき。</>}/><br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addImage("4.3.p.4.4.jpg")} </td>
          <td style={{verticalAlign: "top", textAlign: "left"}}>{addAudio("4.3.p.4.4.a.mp3")} <Hideable content={<>ここって、たばこ{addRubyText("吸","す")}うてもええんかな。</>}/><br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "left"}}>{addAudio("4.3.p.4.4.b.mp3")} <Hideable content={<>たばこ{addRubyText("吸","す")}いなって書いたるし、吸わんとき。</>}/></td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addImage("4.3.p.4.5.jpg")}</td>
          <td style={{verticalAlign: "top", textAlign: "left"}}>{addAudio("4.3.p.4.5.a.mp3")} <Hideable content={<>この{addRubyText("交差点","こうさてん")}で{addRubyText("止","と")}まらんでもええんやんな。</>}/></td>
          <td style={{verticalAlign: "top", textAlign: "left"}}>{addAudio("4.3.p.4.5.b.mp3")} <Hideable content={<>あ、{addRubyText("止","と")}まりぃって書いたるし、止まり！</>}/><br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addImage("4.3.p.4.6.jpg")}</td>
          <td style={{verticalAlign: "top", textAlign: "left"}}>{addAudio("4.3.p.4.6.a.mp3")} <Hideable content={<>この{addRubyText("辺","へん")}でUターン、しよかな。</>}/></td>
          <td style={{verticalAlign: "top", textAlign: "left"}}>{addAudio("4.3.p.4.6.b.mp3")} <Hideable content={<>Uターンしなって書いたるし、せんとき。</>}/></td>
        </tr>
      </tbody>
    </table>
  </>;
}

export default P4;
