import React from "react";
import { addRubyText, addAudio } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";

const P7 = () => {
  const transcript = <>
    <ol className="transcript">
      <li>A: ねえ、映画、見ない？</li>
      <li>B: 映画？見るって言ったじゃない！</li>
    </ol>
  </>;

  return <>
    <h2>{addRubyText("練習","れんしゅう")}7: Quotation</h2>
    Form a question and an answer for each cue according to
    a model. You may listen to the question,
    try to answer it, and check your answer. *Answers vary; the recorded
    answers indicate those commonly
    used.<br />
    <small>*Mouse over or click on the speaker icon for sound</small><br />
    <br />
    <span style={{fontWeight: "bold"}}>Model</span> (Cue: {addRubyText("映画","えいが")}／{addRubyText("見","み")}る）<br />
    &nbsp;&nbsp;&nbsp; A: なあ、映画、見いひん？<br />
    &nbsp;&nbsp;&nbsp; B: 映画？見る言うたやん！{addAudio("3.1.b.p.7.mp3")}<br />
    <br />
    Point: no need of quotation marker (refer to "Notes on Quotation Marker")<br />
    <br />
    ST transcript
    <br />
    <Hideable contents={transcript} />
    <br />
    <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>Cue<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>A (Question)<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>B (Answer)<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>{addRubyText("映画","えいが")}／{addRubyText("見","み")}る<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.1.b.p.7.1.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.1.b.p.7.1.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>そうじ／する<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.1.b.p.7.2.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.1.b.p.7.2.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>{addRubyText("宿題","しゅくだい")}／やる<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.1.b.p.7.3.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.1.b.p.7.3.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>レポート／{addRubyText("書","か")}く<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.1.b.p.7.4.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.1.b.p.7.4.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>{addRubyText("本","ほん")}／{addRubyText("借","か")}りに{addRubyText("行","い")}く<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.1.b.p.7.5.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.1.b.p.7.5.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>{addRubyText("車","くるま")}／{addRubyText("買","か")}う<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.1.b.p.7.6.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.1.b.p.7.6.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>ねこ／もらう<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.1.b.p.7.7.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("3.1.b.p.7.7.b.mp3")}<br />
          </td>
        </tr>
      </tbody>
    </table>
  </>;
}

export default P7;
