import React from "react";
import { Link } from "react-router-dom";
import { Page } from "home/page";
import { addRubyText, addImage } from "common/util/generalUtil";

const TalkInTown = () => {
  return (
    <>
      <h2>Chapter 5: {addRubyText("街","まち")}の{addRubyText("関西弁","かんさいべん")} (Talk in Town)</h2>
      <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>
            This chapter contains a collection of video clips of interactions between local
            people at various locations, especially at the local stores in Kansai.
            All the video clips have comprehension questions and answers so you can
            answer the questions and check your understanding. The video clips in
            this chapter enable you to observe the communication styles,
            strategies, and cultural practices in Kansai. It also introduces
            vocabulary and expressions related to daily life - those you cannot
            usually learn at school - such as the name of fish and vegetables.<br />
          </td>
        </tr>
        </tbody>
      </table>
      <br />
      <table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>
            <ol>
              <li>
                <Link to={Page.TalkInTown.FishShop}>{addRubyText("魚屋","さかなや")}で (At a fish shop)</Link>
              </li>
              <li>
                <Link to={Page.TalkInTown.VegetableShop}>{addRubyText("八百屋","やおや")}で (At a vegetable shop)</Link>
              </li>
              <li>
                <Link to={Page.TalkInTown.EelShop}>{addRubyText("鰻屋","うなぎおや")} (Eel)</Link>
              </li>
              <li>
                <Link to={Page.TalkInTown.Peddler}>{addRubyText("野菜","やさい")}の{addRubyText("行商","ぎょうしょう")} (Produce Peddler)</Link>
              </li>
              <li>
                <Link to={Page.TalkInTown.PickleStore}>{addRubyText("漬け物屋","つけものや")}で (At a pickle store)</Link>
              </li>
              <li>
                <Link to={Page.TalkInTown.FruitShop}>{addRubyText("果物屋","くだものや")}で (At a fruit shop)</Link>
              </li>
              <li>
                <Link to={Page.TalkInTown.RawOyster}>{addRubyText("生牡蠣","なまがき")} (Raw oyster)</Link>
              </li>
              <li>
                <Link to={Page.TalkInTown.GrilledOyster}>{addRubyText("焼","や")}き{addRubyText("牡蠣","がき")} (Grilled oyster)</Link>
              </li>
              <li>
                <Link to={Page.TalkInTown.ConfectionStore}>{addRubyText("和菓子屋","わがしや")}で (At a Japanese-style confection store)</Link>
              </li>
              <li>
                <Link to={Page.TalkInTown.BlackbeanTea}>{addRubyText("黒豆茶","くろまめちゃ")} (Black bean tea)</Link>
              </li>
              <li>
                <Link to={Page.TalkInTown.BlackbeanTea2}>{addRubyText("黒豆茶","くろまめちゃ")}２ (Black bean tea - part 2)</Link>
              </li>
              <li>
                <Link to={Page.TalkInTown.Strawberry}>いちご(Strawberries)</Link>
              </li>
              <li>
                <Link to={Page.TalkInTown.RiceCake}>もちつき (Rice cake making)</Link>
              </li>
              <li>
                <Link to={Page.TalkInTown.FishShopKyoto}>{addRubyText("京都","きょうと")}の{addRubyText("魚屋","さかなや")}で (At a fish shop in Kyoto)</Link>
              </li>
            </ol>
          </td>
          <td style={{verticalAlign: "top"}}>
            <div style={{textAlign: "center"}}>
              {addImage("anmitsu.jpg")}<br />
            </div>
            <div style={{textAlign: "center"}}>
              <small>あんみつ <i>Anmitsu</i> : a Japanese desert (Kyoto)</small><br />
            </div>
          </td>
        </tr>
        </tbody>
      </table>
      <br />
      <br />
    </>
  );
};

export default TalkInTown;
