import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";

const V7 = () => {
  const transcript = <>
    ほんなもん、{addRubyText("勝手","かって")}に、{addRubyText("学校","がっこう")}{addRubyText("行","い")}
    かしてもらうことがなんやったのに、{addRubyText("喜","よろこ")}んでたのに、{addRubyText("家","うち")}の{addRubyText("仕事","しごと")}<span style={{textDecoration: "underline"}}>
    せんならん</span> (Grammar 3)のが、かなんさかいしね。
  </>;

  const qaObjects = [
    {
      question: <span>Q: Why did he used to enjoy going to school as a child?</span>,
      answersCorrect: [
        <>ecause he would have had to do chores had he stayed at home.</>
      ],
      answersIncorrect: [
        <>Because he didn’t like to stay home while his parents were out working.</>,
        <>Because he wanted to study hard so he could start helping his with the family business soon.</>
      ]
    }
  ];

  return <>
    <h2>ビデオ{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}７: Authentic - short<br />
    </h2>
    Watch the video clip and choose the answer that matches the content of
    the clip.<br />
    <small>*Click "SHOW" if you need to refer to the transcript.</small><br />
    <br />
    Tobita-san laments that the children nowadays don’t like to go to
    school.<br />
    <table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>{addVideoPlayer("4.2.4.p.3.tobita.mp4")} </td>
          <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>Transcript
            <Hideable content={transcript}/>
            <br />
          </td>
        </tr>
        <tr align="center">
          <td style={{verticalAlign: "top", textAlign: "left"}}>トビタさん
    (Shiga)&nbsp;&nbsp;&nbsp; <br />
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    <QandAForm qaObjects={qaObjects}/>
  </>;
}

export default V7;
