import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";

const V2 = () => {
  const transcript = <>
    う〜ん、まあ、そら、あの{addRubyText("向","む")}こうの{addRubyText("人","ひと")}は{addRubyText("標準語","ひょうじゅんご")}でないと、ようしゃべらあらへんやろうしなあ、{addRubyText("関西語","かんさいご")}
    {addRubyText("言","ゆ")}うた<span style={{textDecoration: "underline"}}>かて</span>(Grammar
    2)、{addRubyText("関西","かんさい")}でしゃべってください{addRubyText("言","ゆ")}うたって、ようしゃべらあらへんやろうしなあ、そらしゃあないなあ。
  </>;

  const qaObjects = [
    {
      question: <span>
        Q: Having been asked which language she wants foreigners to speak to her, standard or Kansai-ben,
        which one is the closest to her opinion?
      </span>,
      answersCorrect: [
        <>Foreigners probably cannot speak Kansai-ben even if we ask.</>
      ],
      answersIncorrect: [
        <>Foreigners probably cannot speak Standard Japanese even if we ask.</>,
        <>Foreigners probably do not want to speak Kansai-ben.</>
      ]
    }
  ]

  return <>
    <h2>ビデオ{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}2 : Authentic - medium<br />
    </h2>
    Watch the video clip and choose the answer that matches the content of
    the clip.<br />
    *<small>Please be advised that the sound quality of this clip is not
    high.</small><br />
    <small>*Click "SHOW" if you need to refer to the transcript</small><br />
    <br />
    <table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>
          <div style={{textAlign: "left"}}> </div>
    {addVideoPlayer("3.1.a.pl0.3.mp4")}</td>
          <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>&nbsp;Transcript
    <Hideable content={transcript}/><br />
    ＊よう-V(neg) (Kansai - refer to Ch3.L1a.G4) no ability of doing...<br />
    ＊しゃべらあらへん＝しゃべらはらへん (Kansai honorific -refer to C4.L3.G1)<br />
    ＊{addRubyText("標準語","ひょうじゅんご")} Standard
    Japanese<br />
    ＊しゃあない(Kansai) = しようがない it cannot be helped<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>ニシカワさん (Shiga)</td>
        </tr>
      </tbody>
    </table>
    <br />
    <QandAForm qaObjects={qaObjects}/>
  </>;
}

export default V2;
