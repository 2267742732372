import React from "react";
import { Link } from "react-router-dom";
import { Page } from "home/page";
import { addRubyText, addImage } from "common/util/generalUtil";

const VideoComprehension = () => {
  return <>
    <h2>ビデオ{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")} (Video Comprehension Practice)</h2>
    <table style={{textAlign: "left", width: "100%"}} cellSpacing="2" cellPadding="2" border="0">
      <tbody>
        <tr>
          <td colSpan="1" rowSpan="1" style={{verticalAlign: "top"}}>
            <br />
            <ul>
              <li>
                <Link to={Page.BasicGrammar.Verbs.Video.V1}>ビデオ聞き取り練習１: Authentic - short</Link>
              </li>
              <li>
                <Link to={Page.BasicGrammar.Verbs.Video.V2}>ビデオ聞き取り練習２: Authentic - short</Link>
              </li>
              <li>
                <Link to={Page.BasicGrammar.Verbs.Video.V3}>ビデオ聞き取り練習３: Authentic -medium</Link>
              </li>
            </ul>
          </td>
          <td colSpan="1" rowSpan="1" style={{verticalAlign: "top", textAlign: "center"}}>
            {addImage("AoCho3.jpg")}<br />
            <small>田んぼ Rice Field (Hyogo)</small><br />
          </td>
        </tr>
        <tr align="center"></tr>
      </tbody>
    </table>
    <br />
    <br />
  </>;
}

export default VideoComprehension;
