import React from "react";
import { Link } from "react-router-dom";
import { addRubyText, addAudio } from "common/util/generalUtil";
import { Page } from "home/page";

const NegativeRequest = () => {
  return <>
    <h2>5. Negative て form and Negative Request</h2>
    In standard Vないで form "without ~ing" functions as negative て form and
    is used to connect sequential actions/events. Its Kansai equivalent is
    negative と conditional; Vんと (Verb negative short form + と).<br />
    (Vんで form is also used, but its occurance seems less frequent.) <br />
    <br />
    <small>*Mouse over or click on the speaker icon for sound.</small><br />
    <table style={{ textAlign: "left", width: "60%" }} border="1" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{ verticalAlign: "top", fontWeight: "bold" }}>Vんと</td>
          <td style={{ verticalAlign: "top" }}>
            {addRubyText("食", "た")}べんと <br />
            {addRubyText("見", "み")}んと<br />
            {addRubyText("聞", "き")}かんと<br />
            {addRubyText("買", "か")}わんと<br />
            せんと<small> (ST しないと)</small><br />
            {addRubyText("来", "こ")}んと <small>(ST 来ないと) </small><br />
          </td>
          <td style={{ verticalAlign: "top" }}>{addAudio("3.2.5.1.mp3")}</td>
        </tr>
      </tbody>
    </table>
    &nbsp;&nbsp; <br />
    A: {addRubyText("今日", "きょう")}はゲーセン{addRubyText("寄", "よ")}ってから{addRubyText("家", "うち")}{addRubyText("帰", "かえ")}るん？<small> </small><br />
    B: ううん、<span style={{ textDecoration: "underline" }}>寄らんと</span>帰るつも
    り。<small> </small>{addAudio("3.2.5.2.mp3")}<br />
    <small>（ST 今日はゲームセンターに寄ってから家に帰るの？</small><small>ー＞ううん、寄らないで帰るつもり。</small><small>)</small><small>{addAudio("3.2.5.2.s.mp3")}</small><br />
    <br />
    When making a negative request, you can use Vないで as a casual negative
    request in standard, but what about in Kansai-ben? Actually,&nbsp;
    Vんといて (=Vないでおいて／Vないでいて) form is used, not Vんと itself.<br />
    <br />
    <table style={{ textAlign: "left", width: "60%" }} border="1" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{ verticalAlign: "top", fontWeight: "bold" }}>Vんといて</td>
          <td style={{ verticalAlign: "top" }}>食べんといて <br />
            見んといて<br />
            聞かんといて<br />
            買わんといて<br />
            せんといて<small> (ST しないでおいて)</small><br />
            {addRubyText("来", "こ")}んといて<small> (ST 来ないでおいて）</small></td>
          <td style={{ verticalAlign: "top" }}>{addAudio("3.2.5.3.mp3")}</td>
        </tr>
      </tbody>
    </table>
    <br />
    コーヒーにミルク{addRubyText("入", "い")}れんといてな。<small> </small>{addAudio("3.2.5.4.mp3")} 
    <br />
    <small>(ST コーヒーにミルクを入れないでね。)&nbsp; </small><small>{addAudio("3.2.5.4.s.mp3")}</small>
    <br />
    <br />
    うちの{addRubyText("親", "おや")}にはテスト{addRubyText("悪", "わる")}かったこと、{addRubyText("言", "い")}わんといてや。{addAudio("3.2.5.5.mp3")}
    <br />
    <small>(ST うちの親にはテストが悪かったことを言わないでよ。) </small><small>{addAudio("3.2.5.6.s.mp3")}</small>
    <br />
    <br />
    {addRubyText("今日", "きょう")}は家{addRubyText("汚", "きた")}ないし、{addRubyText("来", "こ")}んといて。{addAudio("3.2.5.6.mp3")}
    <br />
    <small>(ST 今日は家が汚いから、来ないで。)</small> <small>{addAudio("3.2.5.5.s.mp3")}</small>
    <br />
    <br />
    Note that the following two requests of るverbs Vといて (ST: Vておいて) and
    Vんといて (ST: Vないでおいて) sound quite similar but the meanings are totally
    opposite!!<br />
    <br />
    食べといて VS 食べんといて {addAudio("3.2.5.7.mp3")}<br />
    {addRubyText("借", "か")}りといて VS 借りんといて {addAudio("3.2.5.8.mp3")}
    <br />
    見といて VS 見んといて {addAudio("3.2.5.9.mp3")}
    <br />
    <br />
    <hr />
    <h3>Extra 1: {addRubyText("宿題", "しゅくだい")}ちゃんとやらんと！
    <br />
    </h3>
    The original meaning of Vんと is "Unless you do (some consequence
    will happen)". Therefore, if somebody uses Vんと as it is, s/he implies
    something bad will happen unless you do it. Now you know that this
    expression is not a negative request but actually a command!
    <br />
    <br />
    ゆり！はよ{addRubyText("起", "お")}きんと。<small> </small>{addAudio("3.2.5.10.mp3")}
    <br />
    <small>(ST {addRubyText("早", "はや")}く起きないと [{addRubyText("学校", "がっこう")}に{addRubyText("遅", "おく")}れるわよ] =早く起きなさい！) </small><small>{addAudio("3.2.5.10.s.mp3")}</small>
    <br />
    <br />
    We will introduce more functions of Vんと form in <Link to={Page.FunctionalGrammar.NegativePermissionObligation.Grammar.Unless}>Ch4 Lesson 2 G2.</Link>
    <br />
    <br />
    <hr />
    <h3>Extra 2: PAST Negative て form<br /></h3>
    As you knowin Standard,&nbsp; Vないで (or Vなくて) describes a situation in
    which one activity takes place without an occurance of another, whether non-past
    or past; however, Vへんかって／Vひんかって／Vんかって (PAST negative てform) can be used
    in Kansai when talking about an non-occurance in the past. Please be
    careful there is no such form in Standard!
    <br />
    <br />
    Past negative form: Vへんかった／Vひんかった／Vんかった
    <br />
    →Past negativeて form: Vへんかって／Vひんかって／Vんかって
    <br />
    <br />
    {addRubyText("料理", "りょうり")}{addRubyText("全部", "ぜんぶ")}<span style={{ textDecoration: "underline" }}>食べられへんかって</span>、
    もったいなかったわ。<small> </small>{addAudio("3.2.5.11.mp3")}
    <br />
    <small>（ST 料理が全部は<span style={{ textDecoration: "underline" }}>食べられなくて</span>、もったいなかったよ。）</small><small>{addAudio("3.2.5.11.s.mp3")}</small>
  </>;
}

export default NegativeRequest;
