import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import "css/common.scss";

const HowKansaibenSoundsLike = () => {
  return (
    <>
      <h2>How Kansai-ben sounds like</h2>
      <div>
        Have you ever heard poeple speaking Kansai dialect (Kansai-ben)? You
        have heard it but are not sure how different it is from Standard
        Japanese? We have prepared two sets of sample video clips for you.
        Please compare each set of conversations; one conducted in Standard
        Japanese and the other in Kansai-ben.
      </div>
      <br />

      <b>A. Topic: {addRubyText("映画", "えいが")} (Movie)</b>
      <table className="custom-table" border="1" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td>Standard</td>
          <td>Kansai</td>
        </tr>
        <tr>
          <td>{addVideoPlayer("1.1.a.mp4")}</td>
          <td>{addVideoPlayer("1.1.b.mp4")}</td>
        </tr>
        </tbody>
      </table>
      <br />

      <b>B. Topic: たこ{addRubyText("焼", "や")}き (Tako-yaki: Octopus ball)</b>
      <table className="custom-table" border="1" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td>Standard</td>
          <td>Kansai</td>
        </tr>
        <tr>
          <td>{addVideoPlayer("1.1.c.mp4")}</td>
          <td>{addVideoPlayer("1.1.d.mp4")}</td>
        </tr>
        </tbody>
      </table>
      <br />

      <div>
        Now you know Standard Japanese and Kansai dialect sound quite different
        from each other. You may wonder "What aspects are different? Is the
        Kansai dialect just a regional dialect? What are the relationship
        between Standard Japanese and the Kansai dialect? ..." Please check out
        the next section!
      </div>
    </>
  )
};

export default HowKansaibenSoundsLike;