import React from "react";
import { addAudio, addVideoPlayer } from "common/util/generalUtil";

const LeaveTakings = () => {
  return (
    <>
      <h2>Leave-takings</h2>
      <p>
        <small>*<b>BOLD</b> type indicates high pitch *Mouse over or click on the speaker icon for sound</small>
      </p>
      <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Standard
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Kansai
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            おやすみ {addAudio("2.2.1.s.mp3")}
          </td>
          <td style={{verticalAlign: "top"}}>
            おやす<b>み</b>ぃ {addAudio("2.2.1.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            おやすみなさい {addAudio("2.2.2.s.mp3")}
          </td>
          <td style={{verticalAlign: "top"}}>
            おやすみ<b>な</b>さい {addAudio("2.2.2.mp3")}<br />
            おやすみ<b>や</b>す (Used in Kyoto/Shiga, by older generation)<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            さようなら {addAudio("2.2.3.s.mp3")}
          </td>
          <td style={{verticalAlign: "top"}}>
            <b>さ</b>よう<b>な</b>ら、<b>さいな</b>ら {addAudio("2.2.3.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            じゃ {addAudio("2.2.4.s.mp3")}
          </td>
          <td style={{verticalAlign: "top"}}>
            <b>ほ</b>な {addAudio("2.2.4.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            じゃあね {addAudio("2.2.5.s.mp3")}
          </td>
          <td style={{verticalAlign: "top"}}>
            <b>ほ</b>ななあ {addAudio("2.2.5.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            じゃ、また {addAudio("2.2.6.s.mp3")}
          </td>
          <td style={{verticalAlign: "top"}}>
            <b>ほ</b>な<b>ま</b>た {addAudio("2.2.6.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            じゃ、さようなら{addAudio("2.2.7.s.mp3")}
          </td>
          <td style={{verticalAlign: "top"}}>
            <b>ほ</b>な<b>さいな</b>ら {addAudio("2.2.7.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            バイバイ{addAudio("2.2.8.s.mp3")}
          </td>
          <td style={{verticalAlign: "top"}}>
            <b>バイ</b>バ〜イ {addAudio("2.2.8.mp3")}
          </td>
        </tr>
        </tbody>
      </table>
      <br />
      <hr />

      <h3>ビデオ 1</h3>
      <p>
        Let's watch how Kansai people say "Good bye" in the following situations!
      </p>
      <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top"}} />
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Standard
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Kansai
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            a. To neighbors /In general
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addVideoPlayer("2.2.a.1.mp4")}
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addVideoPlayer("2.2.a.2.mp4")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            b. To co-workers
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addVideoPlayer("2.2.b.1.mp4")}
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addVideoPlayer("2.2.b.2.mp4")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            c. To friends
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addVideoPlayer("2.2.c.1.mp4")}
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addVideoPlayer("2.2.c.2.mp4")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            d. To kids and close friends
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addVideoPlayer("2.2.d.1.mp4")}
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addVideoPlayer("2.2.d.2.mp4")}
          </td>
        </tr>
        </tbody>
      </table>
      <br />

      <p>What about the leave-takings at night or before going to bed?</p>
      <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top"}} />
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Standard
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Kansai
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            e. In general
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addVideoPlayer("2.2.e.1.mp4")}
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addVideoPlayer("2.2.e.2.mp4")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            f. To kids, family members and close friends
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addVideoPlayer("2.2.f.1.mp4")}
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addVideoPlayer("2.2.f.2.mp4")}
          </td>
        </tr>
        </tbody>
      </table>
      <br />

      <h3>ビデオ２</h3>
      <p>
        Here is a sample video clip of leave-taking: A Kansai woman was invited
        to a neighbor's for dinner, and she is about to leave.
      </p>
      <table style={{textAlign: "left", width: "50%"}} border="1" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td colSpan="1" rowSpan="1" style={{verticalAlign: "top", textAlign: "center"}}>
            {addVideoPlayer("2.2.g.mp4")}
          </td>
        </tr>
        <tr />
        </tbody>
      </table>
    </>
  );
};

export default LeaveTakings;