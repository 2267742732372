import React from "react";
import { addRubyText, addAudio } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";

const P2 = () => {
  const transcript = <>
    <ol className="transcript">
      <li>A: くつ買ってね。</li>
      <li>B: くつ？{addRubyText("仕方","しかた")}ないね。買ったげるよ。</li>
    </ol>
  </>;
  return <>
    <h2>{addRubyText("練習","れんしゅう")}2 : Request &amp; 〜たげる／たる
    </h2>
    Form a question and an answer for each cue according to
    a model. You may listen to the question, try to answer it, and check
    your answer. *Answers vary; the recorded answers indicate those
    commonly used.<br />
    <small>*Mouse over or click on the speaker icon for sound</small>
    <br />
    <br />
    <span style={{fontWeight: "bold"}}>Model</span> (Cue: くつ／{addRubyText("買","か")}う) 
    <br />
    &nbsp;&nbsp;&nbsp; A: くつ{addRubyText("買","こ")}うてな。
    <br />
    &nbsp;&nbsp;&nbsp; B: くつ？しゃーないな、{addRubyText("買","こ")}うたるわ。{addAudio("3.2.p.2.mp3")}
    <br />
    <br />
    Point: 〜てあげる(do...for you) ー＞〜たげる／たる (refer to Grammar 2)<br />
    <br />
    ST transcript
    <br />
    <Hideable content={transcript} />
    <br />
    <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            Cue<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            A (Question)<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            B (Answer)<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            くつ／{addRubyText("買","か")}う<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.2.1.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.2.1.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            シャツ／{addRubyText("貸","か")}す<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.2.2.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.2.2.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addRubyText("本","ほん")}／{addRubyText("借","か")}りてくる<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.2.3.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.2.3.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addRubyText("英語","えいご")}／{addRubyText("教","おし")}える<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.2.4.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.2.4.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addRubyText("晩","ばん")}ご{addRubyText("飯","はん")}／{addRubyText("作","つく")}る<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.2.5.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.2.5.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            ふとん／{addRubyText("敷","し")}く(spread)<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.2.6.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.2.6.b.mp3")}<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            かばん／{addRubyText("持","も")}つ<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.2.7.a.mp3")}
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.2.7.b.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            ここで／{addRubyText("待","ま")}っている<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.2.8.a.mp3")}
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.2.8.b.mp3")}
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            これ／{addRubyText("食","た")}べてしまう<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.2.9.a.mp3")}
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio("3.2.p.2.9.b.mp3")}
          </td>
        </tr>
      </tbody>
    </table>
  </>;
}

export default P2;
