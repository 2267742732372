import React from "react";
import { addRubyText, addAudio } from "common/util/generalUtil";
import { ShowHideableTranscript } from "components/common/hideable";

const renderTitle = () => {
  return <h2>{addRubyText("練習","れんしゅう")}1: Non-past Negative Formation</h2>
}

const renderModel = () => {
  return <>
    <span style={{fontWeight: "bold"}}>Model</span> (Cue: {addRubyText("行","い")}く) <br />
    &nbsp;&nbsp;&nbsp; A:もう行く？ <br />
    &nbsp;&nbsp;&nbsp; B:ううん、まだ行かへん。{addAudio("3.1.a.p.1.mp3")}<br />
    <br />
    
    {ShowHideableTranscript({
      title: <>ST transcript</>,
      transcript: [
        <>A:もう行く？</>,
        <>B:ううん、まだ行かない</>
      ]
    })}
  </>
}

const renderCueTable = (props) => {
  const cues = props.cues;
  const questions = props.questions;
  const answers = props.answers;
  return <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
    <tbody>
      <tr>
        <td style={{verticalAlign: "top", textAlign: "center"}}>
          Cue
        </td>
        <td style={{verticalAlign: "top", textAlign: "center"}}>
          A (Question)
        </td>
        <td style={{verticalAlign: "top", textAlign: "center"}}>
          B (Answer)
        </td>
      </tr>
      {cues.map((cue, i) => {
        return <tr>
          <td style={{verticalAlign: "top"}}>
            {cue}
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio(questions[i])}
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addAudio(answers[i])}
          </td>
        </tr>
      })}
    </tbody>
  </table>;
}

const Practice = () => {
  return <>
    {renderTitle()}
    Form a question and an answer for each cue according to a model. You
    may listen to the question, try to answer it, and then check your
    answer. *Answers vary; the recorded answers indicate those commonly
    used.
    <br />
    <small>*Move the mouse over to the speaker icon for sound</small>
    <br />
    <br />
    {renderModel()}
    <br />
    {renderCueTable({
      cues: [
        <>{addRubyText("行","い")}く</>,
        <>{addRubyText("寝","ね")}る</>,
        <>{addRubyText("借","か")}りる</>,
        <>{addRubyText("見","み")}る</>,
        <>できる</>,
        <>ある</>,
        <>{addRubyText("飲","の")}む</>,
        <>{addRubyText("買","か")}う</>,
        <>{addRubyText("帰","かえ")}る</>,
        <>{addRubyText("入","はい")}る</>,
        <>{addRubyText("言","い／ゆ")}う</>,
        <>{addRubyText("話","はな")}す</>,
        <>{addRubyText("勉強","べんきょう")}する</>,
        <>{addRubyText("来","く")}る</>,
      ],
      questions: [
        "3.1.a.p.1.1.a.mp3",
        "3.1.a.p.1.2.a.mp3",
        "3.1.a.p.1.3.a.mp3",
        "3.1.a.p.1.4.a.mp3",
        "3.1.a.p.1.5.a.mp3",
        "3.1.a.p.1.6.a.mp3",
        "3.1.a.p.1.7.a.mp3",
        "3.1.a.p.1.8.a.mp3",
        "3.1.a.p.1.9.a.mp3",
        "3.1.a.p.1.10.a.mp3",
        "3.1.a.p.1.11.a.mp3",
        "3.1.a.p.1.12.a.mp3",
        "3.1.a.p.1.13.a.mp3",
        "3.1.a.p.1.14.a.mp3"
      ],
      answers: [
        "3.1.a.p.1.1.b.mp3",
        "3.1.a.p.1.2.b.mp3",
        "3.1.a.p.1.3.b.mp3",
        "3.1.a.p.1.4.b.mp3",
        "3.1.a.p.1.5.b.mp3",
        "3.1.a.p.1.6.b.mp3",
        "3.1.a.p.1.7.b.mp3",
        "3.1.a.p.1.8.b.mp3",
        "3.1.a.p.1.9.b.mp3",
        "3.1.a.p.1.10.b.mp3",
        "3.1.a.p.1.11.b.mp3",
        "3.1.a.p.1.12.b.mp3",
        "3.1.a.p.1.13.b.mp3",
        "3.1.a.p.1.14.b.mp3"
      ]
    })}
  </>;
}

export default Practice;
