import React from "react";
import { addRubyText, addImage, addAudio } from "common/util/generalUtil";
import { DraggableForm } from "common/util/draggableForm";

const L1 = () => {
  const draggableFormData = {
    prompts: {
      images: [
        "3.1.a.pl0.1.1.jpg",
        "3.1.a.pl0.1.2.jpg",
        "3.1.a.pl0.1.3.jpg",
        "3.1.a.pl0.1.4.jpg",
        "3.1.a.pl0.1.5.jpg",
        "3.1.a.pl0.1.6.jpg"
      ]
    },
    answers: {
      audioFiles: [
        "3.1.a.pl0.1.1.mp3",
        "3.1.a.pl0.1.4.mp3",
        "3.1.a.pl0.1.5.mp3",
        "3.1.a.pl0.1.2.mp3",
        "3.1.a.pl0.1.3.mp3",
        "3.1.a.pl0.1.6.mp3"
      ],
      texts: [
        <>{addRubyText("来","け")}えへんなあ。</>,
        <>{addRubyText("寝","ね")}えへんなあ。</>,
        <>{addRubyText("飲","の")}まへんで。</>,
        <>なんや、つかへんなあ。</>,
        <>これ、{addRubyText("消","き")}えへんなあ。</>,
        <>せーへん、せーへん。</>
      ]
    }
  };

  return <>
    <h2>{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}1</h2>
    Listen to the audio, and drag it to the illustration that is most
    appropriate to describe the speaker’s situation. If you get a right
    answer, you will hear "Right!" and the audio will stick to the
    illustration. If it is not a right answer, the audio will bounce back
    to the original location.<br />
    * Click "SHOW" if you need to refer to the transcript.<br />
    <br />
    <table style={{textAlign: "left", width: "50%"}} border="0" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>
            {addRubyText("例","れい")}(example)
          </td>
          <td style={{verticalAlign: "top"}}>
            <br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            あ〜、{addRubyText("入","はい")}らへんわあ。-----&gt;<br />
            {addAudio("3.1.a.pl0.1.0.mp3")}</td>
          <td style={{verticalAlign: "top"}}>
            {addImage("3.1.a.pl0.1.0.jpg")}
          </td>
        </tr>
      </tbody>
    </table>
    <DraggableForm prompts={draggableFormData.prompts} answers={draggableFormData.answers} />
  </>;
}

export default L1;
