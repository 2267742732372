import React from "react";
import { addRubyText, addAudio } from "common/util/generalUtil";

const Even = () => {
  return <>
    <h2>1.&nbsp; Nかて “Even…”</h2>
    As you know, particle も signals “additionalness,” either neutral or out
    of the ordinary,&nbsp; thus ‘also’ or ‘even’ depending on the context.
    Gerund (てform)＋ particle も is frequently used as well to indicate
    “also/even if...” such as in {addRubyText("子供","こども")}でも{addRubyText("分","わ")}かる (Even a child
    understands). In spoken language た-form＋って pattern occurs as an
    alternate of てform+も such as in 子供だって分かる. The equivalent expression in
    Kansai-ben is 〜かて Let’s examine the case with Nouns (Regular nominals)
    to begin with.<br />
    <big style={{fontWeight: "bold"}}><br />
    N +かて “Even N”（ST Nでも、Nだって）</big><br />
    <br />
    そんなやさしい{addRubyText("計算","けいさん")}、{addRubyText("赤","あか")}ん{addRubyText("坊","ぼ")}<span style={{textDecoration: "underline"}}>か
    て</span>できるわ。{addAudio("4.5.1.1.mp3")}<br />
    <small>(ST そんなやさしい計算は赤ん坊だってできるよ。) {addAudio("4.5.1.1.s.mp3")}</small><br />
    <br />
    <small>*{addRubyText("赤","あか")}ん{addRubyText("坊","ぼ")}(colloquial) ＜{addRubyText("赤","あか")}ん{addRubyText("坊","ぼう")}＝{addRubyText("赤","あか")}ちゃん<br />
    </small><br />
    N＋に／で／から／まで etc. can be regarded as a Noun phrase, so it’s possible to
    combine such phrases with かてas in Nにかて、Nでかて、Nからかて and Nまでかて.<br />
    <br />
    ヨーロッパ？{addRubyText("何","なん")}べんも行ったわ。あ、トルコ<span style={{textDecoration: "underline"}}>にかて</span>{addRubyText("行","い")}ってんで。{addAudio("4.5.1.2.mp3")}<br />
    <small>(ST ヨーロッパ？{addRubyText("何回","なんかい")}も行ったよ。あ、トルコにだって行ったんだよ。) {addAudio("4.5.1.2.s.mp3")}<br />
    </small>
  </>;
}

export default Even;
