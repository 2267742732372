import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";

const Conversation = () => {
  const kansaiTranscript1 = <>
      <ol class="transcript">
        <li><small>A: <span style={{fontWeight: "bold"}}>ちょ</span>っとアイロン<span
 style={{fontWeight: "bold"}}>てつどうて</span>。</small></li>
        <li><small>B: ごめん、<span style={{fontWeight: "bold"}}>しゅくだいせ</span>な<span
 style={{fontWeight: "bold"}}>いかん</span>ねん。</small></li>
        <li><small>A:<span style={{fontWeight: "bold"}}>ほ</span>な、<span
 style={{fontWeight: "bold"}}>し</span>たあ<span style={{fontWeight: "bold"}}>と</span>で、<span
 style={{fontWeight: "bold"}}>てつどうてくれる？</span></small></li>
        <li><small>B: <span style={{fontWeight: "bold"}}>しゅくだいし</span>たら、<span
 style={{fontWeight: "bold"}}>ともだちにでんわせんとあかん</span>ねん。</small></li>
        <li><small>A:<span style={{fontWeight: "bold"}}>ほ</span>したら、<span
 style={{fontWeight: "bold"}}>その</span>あと<span style={{fontWeight: "bold"}}>は</span>。</small></li>
        <li><small>B: <span style={{fontWeight: "bold"}}>その</span>あとは、お<span
 style={{fontWeight: "bold"}}>や</span>つた<span style={{fontWeight: "bold"}}>べ</span>な<span
 style={{fontWeight: "bold"}}>あかん</span>ねん。</small></li>
        <li><small>A: お<span style={{fontWeight: "bold"}}>や</span>つは<span
 style={{fontWeight: "bold"}}>べつに</span>たべん<span
 style={{fontWeight: "bold"}}>で</span>もえ<span style={{fontWeight: "bold"}}>え</span>や
ろ！</small></li>
      </ol>
  </>;

  const standardTranscript1 = <>
      <ol class="transcript">
        <li><small>A: ちょっと{addRubyText("掃除","そうじ")}{addRubyText("手伝","てつだ")}って。</small></li>
        <li><small>B: ごめん、{addRubyText("宿題","しゅくだい")}しなくちゃいけないの。</small></li>
        <li><small>A: じゃあ、した{addRubyText("後","あと")}で、{addRubyText("手伝","てつだ")}ってくれる？</small></li>
        <li><small>B:宿題したら、{addRubyText("友達","ともだち")}に{addRubyText("電話","でんわ")}しないといけないの。</small></li>
        <li><small>A: それじゃあ、電話してからは？</small></li>
        <li><small>B: その後は、おやつ{addRubyText("食","た")}べなきゃだめなの。</small></li>
        <li><small>A: おやつは{addRubyText("別","べつ")}に食べなくてもいいでしょ！</small><br />
        </li>
      </ol>
  </>;

  const kansaiTranscript2 = <>
      <ol class="transcript">
        <li><small>A: <span style={{fontWeight: "bold"}}>この</span>レ<span
 style={{fontWeight: "bold"}}>ポ</span>ート、<span style={{fontWeight: "bold"}}>え
いご</span>やないと<span style={{fontWeight: "bold"}}>あかん</span>か<span
 style={{fontWeight: "bold"}}>な</span>あ。</small></li>
        <li><small>B:いや、<span style={{fontWeight: "bold"}}>えいごち</span>ごても<span
 style={{fontWeight: "bold"}}>か</span>ま<span style={{fontWeight: "bold"}}>へん</span>や
ろ。</small></li>
        <li><small>A: <span style={{fontWeight: "bold"}}>そ</span>やけど、やっ<span
 style={{fontWeight: "bold"}}>ぱ</span>し<span style={{fontWeight: "bold"}}>き</span>れ
いにかかんと<span style={{fontWeight: "bold"}}>いかん</span>よね。</small></li>
        <li><small>B: いや、<span style={{fontWeight: "bold"}}>き</span>れいにかかん<span
 style={{fontWeight: "bold"}}>で</span>もえ<span style={{fontWeight: "bold"}}>え</span>と<span
 style={{fontWeight: "bold"}}>おもうけ</span>ど。</small></li>
        <li><small>A: ふ〜ん。<span style={{fontWeight: "bold"}}>これ</span>やっぱ、
だ<span style={{fontWeight: "bold"}}>さ</span>な<span
 style={{fontWeight: "bold"}}>や</span>ばいやん<span style={{fontWeight: "bold"}}>な</span>あ。</small></li>
        <li><small>B:べつ<span style={{fontWeight: "bold"}}>に</span>だし<span
 style={{fontWeight: "bold"}}>て</span>もだ<span style={{fontWeight: "bold"}}>さ</span>ん
でもえ<span style={{fontWeight: "bold"}}>え</span>ん<span
 style={{fontWeight: "bold"}}>ちゃう</span>の。</small></li>
        <li><small>A: <span style={{fontWeight: "bold"}}>な</span>あ、わたし<span
 style={{fontWeight: "bold"}}>が</span>なん<span style={{fontWeight: "bold"}}>の</span>レ
ポートのこと<span style={{fontWeight: "bold"}}>ゆうてん</span>のか<span
 style={{fontWeight: "bold"}}>わかってん</span>の<span
 style={{fontWeight: "bold"}}>ん？</span></small></li>
        <li><small>B: いや<span style={{fontWeight: "bold"}}>。</span></small></li>
      </ol>
  </>;

  const standardTranscript2 = <>
      <ol class="transcript">
        <li><small>A: レポートは{addRubyText("英語","えいご")}じゃないとだめかな。</small></li>
        <li><small>B: ううん、英語じゃなくてもかまわないでしょ。</small></li>
        <li><small>A: だけど、やっぱりきれいに{addRubyText("書","か")}かないといけないよね。</small></li>
        <li><small>B: ううん、きれいに書かんでもいいと{addRubyText("思","おも")}うけど。</small></li>
        <li><small>A: ふ〜ん。これ、やっぱ{addRubyText("出","だ")}さなきゃやばいんだよね。</small></li>
        <li><small>B: {addRubyText("別","べつ")}に出しても出さなくてもいいんじゃないの。</small></li>
        <li><small>A: ねえ、{addRubyText("私","わたし")}が{addRubyText("何","なん")}のレポートの{addRubyText("事","こと")}を{addRubyText("言","い")}ってるのか、わかってるの？</small></li>
        <li><small>B: ううん。</small> </li>
      </ol>
  </>;

  const kansaiTranscript3 = <>
      <ol class="transcript">
        <li><small>A: <span style={{fontWeight: "bold"}}>あした、とうきょうしゅっちょう</span>や<span
 style={{fontWeight: "bold"}}>ろ</span>。はよ<span style={{fontWeight: "bold"}}>ね
んと</span>。</small></li>
        <li><small>B:<span style={{fontWeight: "bold"}}>ほ</span>やけど、まだ<span
 style={{fontWeight: "bold"}}>く</span>じやで。<span style={{fontWeight: "bold"}}>こ
んな</span>はよ<span style={{fontWeight: "bold"}}>ね</span>んでも<span
 style={{fontWeight: "bold"}}>か</span>ま<span style={{fontWeight: "bold"}}>へん</span>や
ろ。</small></li>
        <li><small>A: あ<span style={{fontWeight: "bold"}}>さ</span>は<span
 style={{fontWeight: "bold"}}>ち</span>じのでんしゃ<span
 style={{fontWeight: "bold"}}>のる</span>んやったら、<span
 style={{fontWeight: "bold"}}>う</span>ちろ<span style={{fontWeight: "bold"}}>く</span>じ
には<span style={{fontWeight: "bold"}}>でんとまにあ</span>わ<span
 style={{fontWeight: "bold"}}>へん</span>で。</small></li>
        <li><small>B: お<span style={{fontWeight: "bold"}}>か</span>あさんが<span
 style={{fontWeight: "bold"}}>え</span>きまで<span style={{fontWeight: "bold"}}>く
るまでおくってくれる</span>んやから、<span style={{fontWeight: "bold"}}>そんな</span>はよ<span
 style={{fontWeight: "bold"}}>で</span>んでもえ<span style={{fontWeight: "bold"}}>え</span>や
ん。</small></li>
        <li><small>A:<span style={{fontWeight: "bold"}}>え？</span>わたしが<span
 style={{fontWeight: "bold"}}>おくん</span>の？だ<span
 style={{fontWeight: "bold"}}>れがそんなこ</span>と<span
 style={{fontWeight: "bold"}}>き</span>めてん、、、</small></li>
      </ol>
  </>;

  const standardTranscript3 = <>
      <ol class="transcript">
        <li><small>A: {addRubyText("明日","あした")}、{addRubyText("東京出張","とうきょうしゅっちょう")}でしょ。{addRubyText("早","はや")}く{addRubyText("寝","ね")}なさい。</small></li>
        <li><small>B: だけど、まだ{addRubyText("九時","くじ")}だよ。こんなに早く寝なくてもかまわないでしょ。</small></li>
        <li><small>A:
{addRubyText("朝八時","あさはちじ")}の{addRubyText("電車","でんしゃ")}に{addRubyText("乗","の")}るんなら、{addRubyText("家","うち")}を{addRubyText("六時","ろくじ")}には{addRubyText("出","で")}ないと{addRubyText("間","ま")}に{addRubyText("合","あ")}わないわ
よ。</small></li>
        <li><small>B:
お{addRubyText("母","かあ")}さんが{addRubyText("駅","えき")}まで{addRubyText("車","くるま")}で{addRubyText("送","おく")}ってくれるんだから、そんなに早く出なくてもいいじゃない。</small></li>
        <li><small>A:え？{addRubyText("私","わたし")}が送るの？{addRubyText("誰","だれ")}がそんなこと{addRubyText("決","き")}めたの、、、？</small><br />
        </li>
      </ol>
  </>;

  const kansaiTranscript4 = <>
      <ol class="transcript">
        <li><small>A: お<span style={{fontWeight: "bold"}}>お</span>やさん、<span
 style={{fontWeight: "bold"}}>き</span>のう<span style={{fontWeight: "bold"}}>と
もだちよ</span>うけ<span style={{fontWeight: "bold"}}>きて</span>て、すんません<span
 style={{fontWeight: "bold"}}>で</span>した。</small></li>
        <li><small>B: <span style={{fontWeight: "bold"}}>い</span>やいや、<span
 style={{fontWeight: "bold"}}>わ</span>かい<span style={{fontWeight: "bold"}}>ひ
と</span>らやし、<span style={{fontWeight: "bold"}}>たまに</span>は<span
 style={{fontWeight: "bold"}}>うる</span>そうても<span
 style={{fontWeight: "bold"}}>か</span>ま<span style={{fontWeight: "bold"}}>しま
へん</span>え。</small></li>
        <li><small>A: すんま<span style={{fontWeight: "bold"}}>せん</span>。<span
 style={{fontWeight: "bold"}}>それから</span>、<span style={{fontWeight: "bold"}}>く
るまお</span>もてに<span style={{fontWeight: "bold"}}>と</span>めても<span
 style={{fontWeight: "bold"}}>か</span>ま<span style={{fontWeight: "bold"}}>しま
せん</span>？</small></li>
        <li><small>B: ああ、<span style={{fontWeight: "bold"}}>よろしおす</span>え。</small></li>
        <li><small>A: ええ<span style={{fontWeight: "bold"}}>ひ</span>とや<span
 style={{fontWeight: "bold"}}>な</span>あ。。。</small></li>
        <li><small>B: <span style={{fontWeight: "bold"}}>せ</span>やけど、や<span
 style={{fontWeight: "bold"}}>ち</span>ん<span style={{fontWeight: "bold"}}>ちゃ
ん</span>と<span style={{fontWeight: "bold"}}>はろてくれ</span>な<span
 style={{fontWeight: "bold"}}>あきまへん</span>え。</small></li>
        <li><small>A: <span style={{fontWeight: "bold"}}>はい〜</span>。</small></li>
      </ol>
  </>;

  const standardTranscript4 = <>
      <ol class="transcript">
        <li><small>A: {addRubyText("大家","おおや")}さん、{addRubyText("昨日","きのう")}{addRubyText("友","とも")}だちたくさん{addRubyText("来","き")}ていて、すみませんでした。</small></li>
        <li><small>B: いえいえ、{addRubyText("若","わか")}い{addRubyText("人","ひと")}たちだし、たまにはうるさくてもかまいませんよ。</small></li>
        <li><small>A: すみません。それから、{addRubyText("車","くるま")}おもてに{addRubyText("止","と")}めてもかまいませんか。</small></li>
        <li><small>B: ああ、いいですよ。</small></li>
        <li><small>A:いい人だなあ。</small></li>
        <li><small>B: だけど、{addRubyText("家賃","やちん")}は、ちゃんと{addRubyText("払","はら")}ってくれないといけませんよ。</small></li>
        <li><small>A:はい。</small><br />
        </li>
      </ol>
  </>;


  return <>
<h2>{addRubyText("基本会話","きほんかいわ")} (Core Conversations)</h2>
<hr />
<h3>{addRubyText("会話","かいわ")}１ : Why cannot B help her mother out?<br />
</h3>
*A=first speaker, B=second speaker<br />
*Click "SHOW" for transcripts<br />
<br />
(A is B’s mother.)<br />
<table style={{textAlign: "left", width: "100%"}} border="1"cellPadding="2"
 cellSpacing="2">
  <tbody>
    <tr>
      <td style={{verticalAlign: "top", textAlign: "center"}} class="video"
 colSpan="1" rowSpan="2">{addVideoPlayer("4.2.cc.1.mp4")}</td>
      <td style={{verticalAlign: "top"}} class="transcriptcell">Kansai
transcript 
        <Hideable content={kansaiTranscript1}/>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}} class="transcriptcell">Standard
transcript 
        <Hideable content={standardTranscript1}/>
      </td>
    </tr>
  </tbody>
</table>
<br />
<small>*おやつ snack</small>
<h3>{addRubyText("会話","かいわ")}2&nbsp; : A’s report <br />
</h3>
(A and B are friends. )<br />
<table style={{textAlign: "left", width: "100%"}} border="1"cellPadding="2"
 cellSpacing="2">
  <tbody>
    <tr>
      <td style={{verticalAlign: "top", textAlign: "center"}} class="video"
 colSpan="1" rowSpan="2">{addVideoPlayer("4.2.cc.2.mp4")}</td>
      <td style={{verticalAlign: "top"}} class="transcriptcell">Kansai
transcript
        <Hideable content={kansaiTranscript2}/>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}} class="transcriptcell">Standard
transcript
        <Hideable content={standardTranscript2}/>
      </td>
    </tr>
  </tbody>
</table>
<br />
<small>*やばい (young, spoken) risky, dubious</small><br />
<h3>{addRubyText("会話","かいわ")}3 : Does B have to go to bed early?<br />
</h3>
(A is B’s mother.)<br />
<table style={{textAlign: "left", width: "100%"}} border="1"cellPadding="2"
 cellSpacing="2">
  <tbody>
    <tr>
      <td style={{verticalAlign: "top", textAlign: "center"}} class="video"
 colSpan="1" rowSpan="2">{addVideoPlayer("4.2.cc.3.mp4")}</td>
      <td style={{verticalAlign: "top"}} class="transcriptcell">Kansai
transcript 
        <Hideable content={kansaiTranscript3}/>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}} class="transcriptcell">Standard
transcript 
        <Hideable content={standardTranscript3}/>
      </td>
    </tr>
  </tbody>
</table>
<br />
<small>* {addRubyText("出張","しゅっちょう")} business trip</small><br />
<h3>{addRubyText("会話","かいわ")}４ : Is A’s landlord strict or lenient?<br />
</h3>
(A is a student. B is a landlady of A’s apartment in Kyoto.)<br />
<table style={{textAlign: "left", width: "100%"}} border="1"cellPadding="2"
 cellSpacing="2">
  <tbody>
    <tr>
      <td style={{verticalAlign: "top", textAlign: "center"}} class="video"
 colSpan="1" rowSpan="2">{addVideoPlayer("4.2.cc.4.mp4")}</td>
      <td style={{verticalAlign: "top"}} class="transcriptcell">Kansai
transcript
        <Hideable content={kansaiTranscript4}/>
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}} class="transcriptcell">Standard
transcript 
        <Hideable content={standardTranscript4}/>
      </td>
    </tr>
  </tbody>
</table>
<small><br />
* {addRubyText("大家","おおや")} landlord <br />
* {addRubyText("家賃","やちん")} rent <br />
* {addRubyText("表","おもて")} front</small><br />
<br />
<hr />
<h3>{addRubyText("理解度","りかいど")}チェック (Check-up)&nbsp; <br />
</h3>
Figure out the Kansai-specific grammar and expressions that appear in
the
video clips. Refer to the standard and Kansai transcripts if necessary.<br />
<br />
<table style={{textAlign: "left", width: "100%"}} border="1"cellPadding="2"
 cellSpacing="2">
  <tbody>
    <tr>
      <td style={{verticalAlign: "top", textAlign: "center"}}>Check-up<br />
      </td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>Answer<br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>1) How do you say “One must go
to bed” in Kansai-ben? (four possibilities)<br />
      </td>
      <td style={{verticalAlign: "top"}}><Hideable content={<>
{addRubyText("寝","ね")}ないかん、寝なあかん，寝んといかん，寝んとあかん</>}/><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>2) Figure out the general
structure to express obligation from examples in the
conversations.&nbsp;<br />
      </td>
      <td style={{verticalAlign: "top"}}><Hideable content={<>（せな／たべな／ださな）-&gt;
V(negative stem) + ないかん／あかん<br />
（せんと／かかんと／でんと） -&gt; V (short negative) + と<br />
（{addRubyText("英語","えいご")}やないとあかん）-&gt; N+copula (negative) + と … possible</>}/><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>3) How do you say
{addRubyText("英語","えいご")}じゃないと（じゃなくちゃ）だめだ in Kansai-ben? <br />
      </td>
      <td style={{verticalAlign: "top"}}><Hideable content={<>英語やないとあかん</>}/> </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>4) How do you say “One doesn’t
have to write” in Kansai-ben?</td>
      <td style={{verticalAlign: "top"}}><Hideable content={<>{addRubyText("書","か")}かんでもいい／ええ／かまへん</>}/></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>5) Figure out the general
structure to express negative permission from examples in the
conversation.</td>
      <td style={{verticalAlign: "top"}}><Hideable content={<>（
かかんでも／ださんでも／ねんでも）-&gt; V (short negative) + でも／いい／ええ／かまへん<br />
（英語ちごても）-&gt; N+ copula negative TE-form + もいい／ええ／かまへん possible</>}/></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>6) What do ださな／でんと in
ださなやばい／でんとまにあわへん indicate? What are the Standard equivalents of ださん／でんと?</td>
      <td style={{verticalAlign: "top"}}><Hideable content={<>All indicate
“Unless you do… (something bad will happen)”<br />
ださな＝ださなきゃ<br />
でんと＝でないと</>}/></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>7) Is there any difference in
terms of forms when you ask/give
permission or prohibit something in polite conversation? (refer to cc 4)<br />
      </td>
      <td style={{verticalAlign: "top"}}><Hideable content={<>Yes.<br />
Permission: 〜てもかましません／かましまへん<br />
Prohibition: V(negative stem) な +あきまへん</>}/></td>
    </tr>
  </tbody>
</table>
<br />
<hr />
<h3>ノート (Miscellaneous note)</h3>
1) In cc 4, the landlady said よろしおすえ （ST よろしいですよ）“It’s ok.” This is a
typical Gion-kotoba traditionally used in Kyoto (Refer to Ch1.
2.f.speechstyle.) え is a Kyoto-specific feminine assertive particle. 
  </>;
}

export default Conversation;
