import React from "react";
import { addRubyText, addAudio } from "common/util/generalUtil";

const ThirdPersonAction = () => {
  return <>
    <h2>3. Vやる&nbsp;&nbsp; &nbsp;Third person action </h2>
    In the former section we talked about the use of おる（とる／よる）as a
    pejorative expression for the third person/animals/things. You also
    know that it is a blunt expression, hence is mainly used by male
    speakers. Then is there any gentle expression that female speakers use
    when talking about third person/animal/thing’s action or state? Yes,
    Vやる is the one. (Please note that this expression is not usually used
    in Kyoto/Shiga area, as they tend to use はる to be gentle and polite.)<br />
    <br />
    V(masu-form stem) + やる <br />
    {addRubyText("食","た")}<span style={{fontWeight: "bold"}}>べ</span>やる、<span
    style={{fontWeight: "bold"}}>い</span>やる、<span style={{fontWeight: "bold"}}>
    {addRubyText("見","み")}</span>やる、{addRubyText("飲","の")}<span style={{fontWeight: "bold"}}>み</span>やる、<span
    style={{fontWeight: "bold"}}>し</span>やる、<span style={{fontWeight: "bold"}}>き</span>や
    る {addAudio("4.4.3.1.mp3")} <br />
    <br />
    やる could hold some emotional / pejorative-like implications such as
    expressing anger /criticism as well as surprise, praise and such;
    however, still has a gentle/feminine feature compared to よる.<br />
    <br />
    あの{addRubyText("人","ひと")}あんなおっきいステーキ{addRubyText("全部","ぜんぶ")}<span style={{textDecoration: "underline"}}>{addRubyText("食","た")}べやった</span>で。すごいなあ。{addAudio("4.4.3.2.mp3")}<br />
    <small>(ST&nbsp; あの人、あんな大きなステーキを全部食べたよ。すごいねえ。)&nbsp; {addAudio("4.4.3.2.s.mp3")}</small><br />
    <br />
    お{addRubyText("母","かあ")}さ〜ん、かずきくんが{addRubyText("私","わたし")}のケーキ<span style={{textDecoration: "underline"}}>食べ
    やん</span>ねん！{addRubyText("怒","おこ")}って！{addAudio("4.4.3.3.mp3")}<br />
    <small>(ST お母さん、かずきくんが私のケーキ食べるんだよ。{addRubyText("叱","しか")}って！）{addAudio("4.4.3.3.s.mp3")}<br />
    </small><br />
    Vている becomes Vてやる.<br />
    <br />
    あそこに<span style={{textDecoration: "underline"}}>{addRubyText("座","すわ")}ってやる</span>{addRubyText("子","こ")}、誰か<span
    style={{textDecoration: "underline"}}>待ってやん</span>のん？{addAudio("4.4.3.4.mp3")}<br />
    <small>(ST あそこに座っている子、誰か待っているの？)&nbsp; {addAudio("4.4.3.4.s.mp3")}&nbsp; </small><br />
    <br />
    Now, let’s summarize who use はる／よる／やる in what situation. Who typically
    use it, male speakers or female speakers, or both? What is the
    hierarchical difference or psychological distance of the speaker from
    山田さん?<br />
    <br />
    ST 山田さん、会社辞めたよ。{addAudio("4.4.3.5.s.mp3")}<br />
    (1) 山田さん、会社辞め<span style={{textDecoration: "underline"}}>はった</span>で。
    {addAudio("4.4.3.5.mp3")}<br />
    (2) 山田さん、会社辞め<span style={{textDecoration: "underline"}}>よった</span>で。
    {addAudio("4.4.3.6.mp3")}<br />
    (3) 山田さん、会社辞め<span style={{textDecoration: "underline"}}>やった</span>で。
    {addAudio("4.4.3.7.mp3")}<br />
    <br />
    (1) can be used by both male and female. はる indicates either 山田さん is
    hierarchically higher than or is psychologically distant from the
    speaker. Or if the speaker is female from Kyoto/Shiga, she may just
    want to be polite.<br />
    <br />
    (2) is typically used by male. よる indicates either 山田さん is
    hierarchically lower than the speaker, or the speaker looks down on
    山田さん. Or the speaker is just blunt. When the speaker is female, it is a
    sign of bluntness or disgust toward 山田さん.<br />
    <br />
    (3) is mainly used by female speakers, except those in Kyoto/Shiga. It
    indicates 山田さん has equal status with or lower status than the speaker,
    or the speaker feels close to 山田さん. <br />
    <br />
    <hr />
    <h3>Extra 1: 読んでやる VS 読んでやる???</h3>
    Some of you may wonder, “Wait! やる can be the blunt expression of あげる,
    then how can one distinguish Kansai-specific 〜てやる that indicates a
    third person action from the blunt version of 〜てあげる?” Well, the key
    point is the difference in their accents.<br />
    <br />
    妹に本を<span style={{textDecoration: "underline"}}>読んで</span><span
    style={{fontWeight: "bold", textDecoration: "underline"}}>や</span><span
    style={{textDecoration: "underline"}}>った</span>。（＜読んであげた）{addAudio("4.4.3.8.mp3")}<br />
    ゆうちゃんが本を<span style={{textDecoration: "underline"}}>読ん</span><span
    style={{fontWeight: "bold", textDecoration: "underline"}}>で</span><span
    style={{textDecoration: "underline"}}>やった</span>。(third person
    action) (ST 読んでいた) {addAudio("4.4.3.9.mp3")}
  </>;
}

export default ThirdPersonAction;
