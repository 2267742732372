import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";

const V3 = () => {
  const transcript = <>
    （アユミ）これは〜？（サヤサ）ちょっと<span style={{textDecoration: "underline"}}>{addRubyText("置","お")}いとき </span>(Grammar 2 Extra 1)
    なあ。それ、{addRubyText("後","あと")}でやったげるから。
  </>;

  const qaObjects = [
    {
      question: <span>Q: How did Sayasa reply to Ayumi’s request?</span>,
      answersCorrect: [
        <>Leave it there for now. I will take care of it for you later.</>
      ],
      answersIncorrect: [
        <>Bring it here. I will take care of it for you later.</>,
        <>Don’t interrupt me now. I will take care of it for you later.</>
      ]
    }
  ]

  return <>
    <h2>ビデオ{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}３：Authentic – short </h2>
    Watch the video clip and choose the answer that matches the content of
    the clip. <br />
    <small>*Click "SHOW" if you need to refer to the transcript.</small>
    <br />
    Ayumi asked Sayasa about how to put clothes on her doll while Sayasa
    was interviewed.<br />
    <table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>{addVideoPlayer("4.3.1.p.2.sayasa.mp4")}</td>
          <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>Transcript
    <Hideable content={transcript}/>
    </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>&nbsp;サヤサ
    (Osaka)</td>
        </tr>
      </tbody>
    </table>
    <QandAForm qaObjects={qaObjects}/>
  </>;
}

export default V3;
