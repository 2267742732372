import { call, put, takeEvery, all } from 'redux-saga/effects';
import Client from './client';
import * as Actions from './actions';
import { Constants } from './constants';

function handleResult(result) {
  // if it is from a redux-action, we get an object with error set not a thrown error
  if (result !== undefined) {
    const { error } = result;
    if (error) {
      throw result;
    }
  }
}

function* fetchInterviewees() {
  yield put(Actions.changeInterviewees({
    isIntervieweesLoading: true
  }));
  const interviewees = yield call(Client.listInterviewees);
  const result = yield put(Actions.changeInterviewees({
    ...interviewees,
    isIntervieweesLoading: false
  }));
  handleResult(result);
  return result;
}

function* fetchInterviewQuestionCount() {
  yield put(Actions.changeInterviewQuestionCount({
    isCountLoading: true
  }));
  const interviewQuestionCount = yield call(Client.getInterviewQuestionCount);
  const result = yield put(Actions.changeInterviewQuestionCount({
    ...interviewQuestionCount,
    isCountLoading: false
  }));
  handleResult(result);
  return result;
}

function* fetchInterviewQuestionList() {
  yield put(Actions.changeInterviewQuestionCount({
    isListLoading: true
  }));
  const interviewQuestionList = yield call(Client.listInterviewQuestions);
  const result = yield put(Actions.changeInterviewQuestionList({
    ...interviewQuestionList,
    isListLoading: false
  }));
  handleResult(result);
  return result;
}

function* fetchInterviewClipCount() {
  yield put(Actions.changeInterviewClipsCount({
    isCountLoading: true
  }));
  const interviewClipCount = yield call(Client.getInterviewClipCount);
  const result = yield put(Actions.changeInterviewClipsCount({
    ...interviewClipCount,
    isCountLoading: false
  }));
  handleResult(result);
  return result;
}

function* fetchInterviewClipList(action) {
  yield put(Actions.changeInterviewClipList({
    isListLoading: true
  }));
  const interviewClipList = yield call(Client.listInterviewClips, action.payload.start, action.payload.n);
  const result = yield put(Actions.changeInterviewClipList({
    ...interviewClipList,
    isListLoading: false
  }));
  handleResult(result);
  return result;
}

function* fetchInterviewClipQandA(action) {
  yield put(Actions.changeInterviewClipQandA({
    isInterviewClipQandALoading: true
  }));
  const interviewClipQandA = yield call(Client.getInterviewClipQandA, action.payload.clipID);
  const result = yield put(Actions.changeInterviewClipQandA({
    ...interviewClipQandA,
    isInterviewClipQandALoading: false
  }));
  handleResult(result);
  return result;
}

function* watchAll() {
  yield all([
    yield takeEvery(Constants.FETCH_INTERVIEWEES, fetchInterviewees),

    yield takeEvery(Constants.FETCH_INTERVIEW_QUESTION_COUNT, fetchInterviewQuestionCount),
    yield takeEvery(Constants.FETCH_INTERVIEW_QUESTION_LIST, fetchInterviewQuestionList),

    yield takeEvery(Constants.FETCH_INTERVIEW_CLIP_COUNT, fetchInterviewClipCount),
    yield takeEvery(Constants.FETCH_INTERVIEW_CLIP_LIST, fetchInterviewClipList),

    yield takeEvery(Constants.FETCH_MORE_INTERVIEW_CLIP_LIST, fetchInterviewClipList),

    yield takeEvery(Constants.FETCH_INTERVIEW_CLIP_Q_AND_A, fetchInterviewClipQandA)
  ]);
}

export default watchAll;
