import React from "react";
import { addRubyText, addImage } from "common/util/generalUtil";
import { Link } from "react-router-dom";
import { Page } from "home/page";

const Grammar = () => {
  return <>
    <h2>
      {addRubyText("文法","ぶんぽう")}ノート (Grammar Note) 
    </h2>
    <h3>
      Summary of Kansai Verb Conjugation
    </h3>
    <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>
            Verb<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            Affirmative<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            Negative<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            Non-past (imperfective)<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            Dictionary form<br />
            ({addRubyText("見","み")}る、{addRubyText("書","か")}く、、）<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            -へん, -ひん, -ん (see Grammar 1)<br />
            （見いひん、書かへん、書かん、、、）<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>
            Past (perfective)<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            Kansai たform (see Grammar 3)<br />
            （見た、書いた、、）<br />
          </td>
          <td style={{verticalAlign: "top"}}>
            -へん/ひん/ん+かった, -へん/ひん+た, -なんだ
            (see Grammar 2)<br />
            (見いひんかった、書かへんかった、書かんかった、<br />
            書かへんた、書かなんだ、、）<br />
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    <hr /><br />
    <table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td colSpan="1" rowSpan="1" style={{verticalAlign: "top"}}>
          <ol>
            <li>
              <Link to={Page.BasicGrammar.Verbs.Grammar.NonPastNegative}>Non-past Negative Form</Link>
            </li>
            <li>
              <Link to={Page.BasicGrammar.Verbs.Grammar.PastNegative}>Past Negative Form</Link>
            </li>
            <li>
              <Link to={Page.BasicGrammar.Verbs.Grammar.TaForm}>たform (Past Affirmative Form)</Link>
            </li>
            <li>
              <Link to={Page.BasicGrammar.Verbs.Grammar.YouNegative}>よう+ Verb Negative Form&nbsp;</Link>
            </li>
          </ol>
          </td>
          <td colSpan="1" rowSpan="1" style={{verticalAlign: "top", textAlign: "center"}}>
            {addImage("daibutsu.jpg")}
            <br />
            <small>
              奈良の大仏 The Great Buddha (Todai-ji, Nara)
            </small>
          </td>
        </tr>
        <tr align="center">
        </tr>
      </tbody>
    </table>
  </>;
}

export default Grammar;
