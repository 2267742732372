import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";

const V2 = () => {
  const transcript = <>
    あらたまれ{addRubyText("言","ゆ")}うたって、<span style={{textDecoration: "underline"}}>よう</span>
    (Grammar 4)あらたまらんわ！
  </>;

  const qaObjects = [
    {
      question: <span>Q: What does this person say upon the start of the interview?</span>,
      answersCorrect: [
        <>I have no ability to be formal even if you tell me to.</>
      ],
      answersIncorrect: [
        <>I am not going to be that formal even if you tell me to.</>
      ]
    }
  ];

  return <>
    <h2>ビデオ{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}２: Authentic - short<br/></h2>
    Watch the video clip and choose the answer that matches the content of
    the clip. 
    <br/>
    <small>*Click "SHOW" if you need to refer to the transcript</small><br/>
    <br/>
    <div className="checkupquestion">
      <table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2" cellSpacing="2">
        <tbody>
          <tr>
            <td style={{verticalAlign: "top", textAlign: "left"}}>
              {addVideoPlayer("3.1.a.pl0.2.mp4")}</td>
            <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>
              Transcript
              <Hideable content={transcript} />
              <br/>
              ＊あらたまる be formal; be renewed
            </td>
          </tr>
          <tr>
            <td style={{verticalAlign: "top", textAlign: "left"}}>
              クサカさん (Kyoto/Shiga) 
            </td>
          </tr>
        </tbody>
      </table>
      <br/>
      <QandAForm qaObjects={qaObjects} />
    </div>
  </>;
}

export default V2;
