import React from "react";
import { Link } from "react-router-dom";
import { Page } from "home/page";
import { addRubyText, addAudio } from "common/util/generalUtil";

const Honorifics = () => {
  return <>
<h2>1. Vはる／やはる Honorifics<br />
</h2>
You already know what Kansai honorifics are like if you read 
<Link to={Page.Characteristics.LinguisticAspects.Honorifics}>
  Ch1. 2. g. Honorifics
</Link>&nbsp; prior to this
section. (If not, please
come back to this page after you read though it.) As in Ch1, light
honorific auxiliary verbs はる／やはる, originated in なさる(honorific polite
verb of する),&nbsp; are used, replacing the regular honorific
expressions in Kansai nowadays. * Kansai people do use れる／られる for
honorific polite expressions, but not as common as はる／やはる expressions.<br />
<br />
はる follows masu-form stem when the verb belongs to RU-verbs or
Irregular verbs.&nbsp; <br />
（ex. {addRubyText("食","た")}べはる、しはる）What form of U-Verb はる follows depends on the regions
– it follows the stem of negative form in Kyoto (including Shiga/Nara)
area, and masu-form stem in Osaka area. やはる(it sounds almost like やある)
is used as an alternative of はる for Irregular verbs and Ru-verbs with
one-mora stem such as {addRubyText("見","み")}る、{addRubyText("出","で")}る、{addRubyText("着","き")}る. やはる seems to be used both
in Kyoto and Osaka area.<br />
<br />
<table style={{textAlign: "left", width: "100%"}} border="1"cellPadding="2"
 cellSpacing="2">
  <tbody>
    <tr>
      <td style={{verticalAlign: "top"}}><br />
      </td>
      <td style={{verticalAlign: "top"}}><br />
      </td>
      <td style={{verticalAlign: "top"}}> Kyoto area {addAudio("4.4.1.1.mp3")}&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>Osaka area {addAudio("4.4.1.2.mp3")}&nbsp;&nbsp;&nbsp; <br />
      </td>
      <td style={{verticalAlign: "top"}}>〜やはる {addAudio("4.4.1.3.mp3")}</td>
    </tr>
    <tr>
      <td colSpan="1" rowSpan="4" style={{verticalAlign: "top"}}>Ru-verb<br />
      <br />
      <br />
      <br />
      </td>
      <td style={{verticalAlign: "top"}}>食べる<br />
      </td>
      <td style={{verticalAlign: "top"}}>食べは<span
 style={{fontWeight: "bold"}}>る</span><br />
      </td>
      <td style={{verticalAlign: "top"}}>食べは<span
 style={{fontWeight: "bold"}}>る</span><br />
      </td>
      <td style={{verticalAlign: "top"}}><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>見る</td>
      <td style={{verticalAlign: "top"}}><span style={{fontWeight: "bold"}}>見
はる</span><br />
      </td>
      <td style={{verticalAlign: "top"}}><span style={{fontWeight: "bold"}}>見
はる</span><br />
      </td>
      <td style={{verticalAlign: "top"}}><span style={{fontWeight: "bold"}}>見<span
 style={{textDecoration: "underline"}}>
や</span>はる</span><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>いる</td>
      <td style={{verticalAlign: "top"}}><span style={{fontWeight: "bold"}}>い
はる</span><br />
      </td>
      <td style={{verticalAlign: "top"}}><span style={{fontWeight: "bold"}}>い
はる</span><br />
      </td>
      <td style={{verticalAlign: "top"}}><span style={{fontWeight: "bold"}}>い<span
 style={{textDecoration: "underline"}}>
や</span>はる</span><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>食べている</td>
      <td style={{verticalAlign: "top"}}>食べ<span
 style={{textDecoration: "underline"}}>た</span>は<span
 style={{fontWeight: "bold"}}>る</span><br />
      </td>
      <td style={{verticalAlign: "top"}}>食べ<span
 style={{textDecoration: "underline"}}>て</span>は<span
 style={{fontWeight: "bold"}}>る</span><br />
      </td>
      <td style={{verticalAlign: "top"}}><br />
      </td>
    </tr>
    <tr>
      <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>U-verb<br />
      <br />
      </td>
      <td style={{verticalAlign: "top"}}>{addRubyText("飲","の")}む</td>
      <td style={{verticalAlign: "top"}}>飲<span
 style={{textDecoration: "underline"}}>ま</span>は<span
 style={{fontWeight: "bold"}}>る</span><br />
      </td>
      <td style={{verticalAlign: "top"}}>飲<span
 style={{textDecoration: "underline"}}>み</span>は<span
 style={{fontWeight: "bold"}}>る</span><br />
      </td>
      <td style={{verticalAlign: "top"}}><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>もらう</td>
      <td style={{verticalAlign: "top"}}><span style={{fontWeight: "bold"}}>も
ら<span style={{textDecoration: "underline"}}>わ</span>はる</span><br />
      </td>
      <td style={{verticalAlign: "top"}}><span style={{fontWeight: "bold"}}>も
ら<span style={{textDecoration: "underline"}}>い</span>はる</span><br />
      </td>
      <td style={{verticalAlign: "top"}}><br />
      </td>
    </tr>
    <tr>
      <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>Irregular
verb<br />
      <br />
      </td>
      <td style={{verticalAlign: "top"}}>する</td>
      <td style={{verticalAlign: "top"}}><span style={{fontWeight: "bold"}}>し
はる</span><br />
      </td>
      <td style={{verticalAlign: "top"}}><span style={{fontWeight: "bold"}}>し
はる</span><br />
      </td>
      <td style={{verticalAlign: "top"}}><span style={{fontWeight: "bold"}}>し<span
 style={{textDecoration: "underline"}}>
や</span>はる</span>／<span style={{fontWeight: "bold"}}>し<span
 style={{textDecoration: "underline"}}>ゃ</span>はる</span><br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>{addRubyText("来","く")}る</td>
      <td style={{verticalAlign: "top"}}><span style={{fontWeight: "bold"}}>き
はる</span><br />
      </td>
      <td style={{verticalAlign: "top"}}><span style={{fontWeight: "bold"}}>き
はる</span><br />
      </td>
      <td style={{verticalAlign: "top"}}><span style={{fontWeight: "bold"}}>き<span
 style={{textDecoration: "underline"}}>
や</span>はる</span>／<span style={{fontWeight: "bold"}}>き<span
 style={{textDecoration: "underline"}}>ゃ</span>はる</span><br />
      </td>
    </tr>
  </tbody>
</table>
<br />
As in the chart above, 〜ている +はる becomes 〜<span
 style={{textDecoration: "underline"}}>た</span>はる in Kyoto area, and 〜<span
 style={{textDecoration: "underline"}}>て</span>はる in
Osaka area.<br />
<br />
{addRubyText("先生","せんせい")}、{addRubyText("今","いま")}だれとしゃべっ<span style={{textDecoration: "underline"}}>た</span>は
るん？(Kyoto) {addAudio("4.4.1.4.mp3")} <br />
先生、今だれとしゃべっ<span style={{textDecoration: "underline"}}>て</span>はるん？(Osaka)
{addAudio("4.4.1.5.mp3")}<br />
<small>(ST 先生は今だれとしゃべっていらっしゃるの／お話しになっているの？) {addAudio("4.4.1.5.s.mp3")}</small><br />
<br />
〜はる／やはる behave as U-verb, therefore, they conjugate like the following.<br />
(Kyoto) 飲<span style={{textDecoration: "underline"}}>ま</span>はる、飲<span
 style={{textDecoration: "underline"}}>ま</span>は<span
 style={{textDecoration: "underline"}}>ら</span>へん、飲<span
 style={{textDecoration: "underline"}}>ま</span>はった、飲<span
 style={{textDecoration: "underline"}}>ま</span>はります {addAudio("4.4.1.6.mp3")}<br />
(Osaka) 飲<span style={{textDecoration: "underline"}}>み</span>はる、飲<span
 style={{textDecoration: "underline"}}>み</span>は<span
 style={{textDecoration: "underline"}}>れ</span>へん、飲<span
 style={{textDecoration: "underline"}}>み</span>はった、飲<span
 style={{textDecoration: "underline"}}>み</span>はります {addAudio("4.4.1.7.mp3")}<br />
<br />
Do you remember that negative stem of U-verb in Osaka is the same as
Potential form stem? (If not, go back to <Link to={Page.BasicGrammar.Verbs.Grammar.NonPastNegative}>
  Ch3 Lesson 1a
</Link>).
That’s why
飲む+はる+へん in Osaka comes to 飲みは<span style={{textDecoration: "underline"}}>れ</span>へ
ん, not は<span style={{textDecoration: "underline"}}>ら</span>へん.<br />
<br />
Now, when do Kansai people use はる／やはる?&nbsp; (1) First of all はる／やはる
can be used as regular honorific polite expressions to exalt out-group
person, but the use is so much broader than the standard honorifics –
(2) they use it even for neighbors and when talking about older family
members. That means, Kansai people express a sense of respect even in a
subtle in-group/out-group relationship or hierarchical relationship by
using はる／やはる in the occasions when honorific polite expressions are
normally applied in the standard. In addition, (3) Kansai people use
はる／やはる if the addressee or the person they are talking about is not
that close to them (psychological out-group).&nbsp; Please keep in mind
that, in the standard, there is no equivalent expression that functions
like はる／やはる in the case of (2) and (3).<br />
<br />
(To a section chief)
{addRubyText("部長","ぶちょう")}、{addRubyText("先週","せんしゅう")}はどこ{addRubyText("出張","しゅっちょう")}{addRubyText("行","い")}っ<span
 style={{textDecoration: "underline"}}>た</span>はりましたん？（行っ<span
 style={{textDecoration: "underline"}}>て</span>はりましたん？ in Osaka)
{addAudio("4.4.1.8.mp3")}<br />
<small>(ST 部長、先週はどこに出張にいらっしゃったんですか。)</small><small> {addAudio("4.4.1.8.s.mp3")}</small><br />
<br />
(To a close coworker) 部長、えらい{addRubyText("高","たか")}そうなスーツ着<span
 style={{textDecoration: "underline"}}>た</span>はったなあ。(着<span
 style={{textDecoration: "underline"}}>て</span>はった in Osaka) {addAudio("4.4.1.9.mp3")}<br />
<small>(ST 部長、えらく高そうなスーツを着ていらっしゃったねえ。) </small><small>{addAudio("4.4.1.9.s.mp3")}</small><br />
<br />
(To a close coworker, about other coworker)
{addRubyText("佐藤","さとう")}さんな、{addRubyText("先週","せんしゅう")}{addRubyText("病気","びょうき")}ちごて{addRubyText("旅行","りょこう")}で{addRubyText("休","やす")}<span
 style={{textDecoration: "underline"}}>ま</span>はったらしいで。むかつくな〜！(休<span
 style={{textDecoration: "underline"}}>み</span>はった
in Osaka) {addAudio("4.4.1.10.mp3")}<br />
<small>(ST 佐藤さんね、先週病気じゃなくて旅行で休んだらしいよ。むかつくね〜！) </small><small>{addAudio("4.4.1.10.s.mp3")}</small><br />
<br />
<small>*むかつく upsetting, sick to one’s stomach</small><br />
<br />
(To a close neighbor, about another neighbor)
{addRubyText("隣","となり")}の{addRubyText("西川","にしかわ")}さん、なんや{addRubyText("具合","ぐあい")}{addRubyText("悪","わる")}い{addRubyText("言","ゆ")}う<span
 style={{textDecoration: "underline"}}>た</span>はったけど、どもないやろか。（言う<span
 style={{textDecoration: "underline"}}>て</span>はった in
Osaka) {addAudio("4.4.1.11.mp3")}<br />
<small>(ST 隣の西川さん、なんだか具合が悪いって言ってらしたけど、{addRubyText("大丈夫","だいじょうぶ")}かしら。) </small><small>{addAudio("4.4.1.11.s.mp3")}</small><br />
<small><br />
* どもない＜どうもない(Kansai)＝大丈夫</small><br />
<br />
(To one’s little sister, about their big brother)
お{addRubyText("兄","にい")}ちゃん、{addRubyText("何時","なんじ")}の{addRubyText("電車","でんしゃ")}{addRubyText("乗","の")}<span
 style={{textDecoration: "underline"}}>ら</span>はるかしってる？（乗<span
 style={{textDecoration: "underline"}}>り</span>はる in Osaka) {addAudio("4.4.1.12.mp3")}<br />
<small>(ST お兄ちゃんが何時の電車に乗るかしってる？) </small><small>{addAudio("4.4.1.12.s.mp3")}</small><br />
&nbsp;<br />
(To a friend, about some guy)
あ、あっこにイケメンの{addRubyText("人","ひと")}{addRubyText("立","た")}っ<span style={{textDecoration: "underline"}}>た</span>は
る。こっち{addRubyText("来","き")}やはらへんかなあ。（立っ<span style={{textDecoration: "underline"}}>て</span>は
る in Osaka) {addAudio("4.4.1.13.mp3")}<br />
<small>(ST あ、あそこにイケメンの人が立ってる。こっちに来ないかなあ。) </small><small>{addAudio("4.4.1.13.s.mp3")}</small><br />
<br />
<small>* イケメン（＜—いかした{addRubyText("面","めん")}＝いかした{addRubyText("顔","かお")}） good-looking guy (used for males
by
young generation)</small><br />
<br />
(At an interview, about Foreigners)
{addRubyText("外国","がいこく")}の{addRubyText("方","かた")}は{addRubyText("何","なん")}でもぎょうさん食べはりますなあ。{addAudio("4.4.1.14.mp3")}<br />
<small>(ST 外国の方は何でもたくさん{addRubyText("召","め")}し{addRubyText("上","あ")}がりますねえ／食べられますねえ。) </small><small>{addAudio("4.4.1.14.s.mp3")}</small><br />
<br />
<hr />
<h3>Extra 1: Gentle, polite use of はる <br />
</h3>
One more notable use of はる／やはる is as just a polite expression, to be
gentle and polite (usually by females, especially in Kyoto area) toward
animate objects. So don’t be surprised if somebody uses はる／やはる when
talking about&nbsp; his/her close friends, his/her own children, or
even about animals. <br />
<br />
(About one’s own child)
うちの{addRubyText("子","こ")}、{addRubyText("毎日","まいにち")}{addRubyText("十時間","じゅうじかん")}ぐらい{addRubyText("寝","ね")}はるわ。{addAudio("4.4.1.15.mp3")}<br />
<small>(ST うちの子は毎日十時間ぐらい寝るわよ。) </small><small>{addAudio("4.4.1.15.s.mp3")}</small><br />
<br />
(To one’s own child, about fish)
ほら、{addRubyText("見","み")}てみ。お{addRubyText("魚","さかな")}さん、{addRubyText("泳","およ")}いだはんで。{addAudio("4.4.1.16.mp3")}<br />
<small>(ST ほらご{addRubyText("覧","らん")}なさい／見てみなさい。お魚さんが泳いでいるわよ。) </small><small>{addAudio("4.4.1.16.s.mp3")}</small><br />
<br />
<hr />
<h3>Extra 2: 飲まはらへん？飲ま〜らへん？</h3>
If the word involves-A-はる, it tends to be pronounced like
あある／あ〜る.&nbsp; It is prominent in Kyoto/Shiga area.<br />
<br />
&nbsp;(About one’s classmate)
ええ？{addRubyText("吉田","よしだ")}さん、{addRubyText("昨日","きのう")}{addRubyText("家","うち")}<span style={{textDecoration: "underline"}}>{addRubyText("帰","かえ")}ら〜らへんかった</span>ん？いや〜、どこ{addRubyText("泊","と")}まったはったんやろ。(帰りはらへん
かったん、泊まってはった in Osaka) {addAudio("4.4.1.17.mp3")}<br />
<small>(ST ええ？吉田さん、昨日家に帰らなかったの？あら〜、どこに泊まってたんでしょ？) </small><small>{addAudio("4.4.1.17.s.mp3")}</small><br />
<br />
<hr />
<h3>Extra 3: 先生、いたはりますか？</h3>
As explained in the former lesson (Extra 1 in <Link to={Page.BasicGrammar.VerbTeForm.Grammar.TeContraction}>
  G2 of Ch3 Lesson 2)
</Link>,
there are at least three ways to describe existence of animate object
in Kansai – use いる, いてる（いて＋いる, keep in mind that it is ungrammatical in
the standard!）or おる (blunt expression of いる, but is used as an
alternate of いる in Kobe area. It has also become less blunt in Osaka
area these days). <br />
<br />
&nbsp;&nbsp;&nbsp; (ST) {addRubyText("友達","ともだち")}はたくさん<span
 style={{textDecoration: "underline"}}>いる</span>けど、{addRubyText("会社","かいしゃ")}には<span
 style={{textDecoration: "underline"}}>いない</span>よ。{addAudio("4.4.1.18.s.mp3")}<br />
=友達はぎょうさん<span style={{textDecoration: "underline"}}>いる</span>けど、会社には<span
 style={{textDecoration: "underline"}}>いいひん</span>（いやへん・いん）わ。{addAudio("4.4.1.18.mp3")}<br />
=友達はぎょうさん<span style={{textDecoration: "underline"}}>いてる</span>けど、会社には<span
 style={{textDecoration: "underline"}}>いてへん</span>わ。{addAudio("4.4.1.19.mp3")}<br />
=友達はぎょうさん<span style={{textDecoration: "underline"}}>おる</span>けど、会社には<span
 style={{textDecoration: "underline"}}>おらん</span>（おらへん）わ。{addAudio("4.4.1.20.mp3")}<br />
<br />
Then What if you want to talk about an existence of somebody to whose
action you add はる? If it’s standard, いらっしゃる should be the only option
(again, いて＋いるpattern is not acceptable in the standard, hence you
cannot use this pattern using いらっしゃる either).&nbsp; However, there are
more than one choice in Kansai-ben.<br />
<br />
(ST) 先生は今日はお{addRubyText("宅","たく")}に<span style={{textDecoration: "underline"}}>いらっしゃいます</span>か。
{addAudio("4.4.1.21.s.mp3")}<br />
= 先生、今日はお宅に<span style={{textDecoration: "underline"}}>いはります</span>か（いやはりま
すか）。{addAudio("4.4.1.21.mp3")}<br />
= 先生、今日はお宅に<span style={{textDecoration: "underline"}}>いたはります</span>か（いてはる
in Osaka) {addAudio("4.4.1.22.mp3")} <br />
= 先生、今日はお宅に<span style={{textDecoration: "underline"}}>おられます</span>か。
{addAudio("4.4.1.23.mp3")}<br />
= 先生。今日はお宅に<span style={{textDecoration: "underline"}}>おってです</span>か。(in
Kobe) {addAudio("4.4.1.24.mp3")}<br />
<br />
You can easily guess the use of いはる／いやはる／いたはる（いてはる）, but what is おられますか
and おってですか??&nbsp; おられる is おる+ られる (light honorific auxiliary verb).
Strangely enough, おる is considered to be an honorific expression, not a
blunt/humble expression in Kansai, only in this case. Then what is
おってですか? Please move on to the Extra 3!<br />
<br />
<hr />
<h3>Extra 4: おってですか</h3>
おってですか is used in Kobe and western Hyogo area. Kobe dialect (Kobe-ben)
and the dialect used in the western Hyogo region (Banshu-ben) are more
closely connected to those of the Chugoku/Shikoku region than to the
main stream Kansai-ben. They use おる／〜ておる（とる／とお） as an equivalent of
いる／〜ている（てる).<br />
<br />
かさ、<span style={{textDecoration: "underline"}}>{addRubyText("持","も")}っとお</span>？（＝かさ，持ってる？
In Kyoto/Osaka) {addAudio("4.4.1.25.mp3")}<br />
<small>（ ST かさ、持ってる？）{addAudio("4.4.1.25.s.mp3")}</small><br />
<br />
Furthermore, はる expression is not common in that area, instead, they
use てform + だ（です）to exalt out-group or to be polite, such as in;<br />
<br />
先生、{addRubyText("北海道","ほっかいどう")}のこと、よう<span style={{textDecoration: "underline"}}>{addRubyText("知","し")}っとって
です</span>か。（知ったはりますか in Kyoto／知ってはりますか in
Osaka）{addAudio("4.4.1.26.mp3")}<br />
<small>(ST 先生は北海道のことをよくご{addRubyText("存知","ぞんじ")}ですか／知っていらっしゃいますか。) {addAudio("4.4.1.26.s.mp3")}</small><br />
<br />
Nowadays, however, even Kobe-ben and Bansyu-ben speakers have come to
use はる expression when talking with people from the other Kansai
regions, since it’s difficult to convey their sense of respect in their
own dialect. In this sense, はる is an honorific expression of the&nbsp;
“Standard/Common Kansai-ben.”<br />
<br />
<hr />
<h3><span style={{fontWeight: "bold"}}></span>Extra 5: らる in Shiga</h3>
You might hear elder people in southern Shiga region use らる instead of
はる. らる is considered to be the mixture of はる and られる.<br />
<br />
{addRubyText("近所","きんじょ")}の人ら、{addRubyText("用事","ようじ")}もないのによう{addRubyText("集","あつ")}まって<span
 style={{textDecoration: "underline"}}>しゃべってらる</span>。（しゃべったはる in
Kyoto／しゃべってはる
in Osaka) {addAudio("4.4.1.27.mp3")}<br />
<small>（ST 近所の人たちは用事もないのによく集まってしゃべってる。）{addAudio("4.4.1.27.s.mp3")}</small><br />
<br />
*The dialect people in the Shiga region speak is called Shiga-ben (its
old term is Gosyu-ben). It is very close to Kyoto dialect with slight
varieties in accent and vocabulary, depending on which area in Shiga
they live, that is, whether they live in the south/west/east/north of
the Lake Biwa. Traditional Shiga-ben characteristics can be observed
only in the utterance of older generations as in the case with most
regional dialects within Kansai-ben.
  </>;
}

export default Honorifics;
