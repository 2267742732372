import React from "react";
import { Link } from "react-router-dom";
import { Page } from "home/page";
import { addRubyText, addImage } from "common/util/generalUtil";

const Grammar = () => {
  return <>
    <h2>{addRubyText("文法","ぶんぽう")}ノート (Grammar Note)</h2>
    <table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>
          <ol>
            <li>
              <Link to={Page.FunctionalGrammar.Honorifics.Grammar.Honorifics}>
                Vはる／やはる Honorifics
              </Link>
            </li>
            <li>
              <Link to={Page.FunctionalGrammar.Honorifics.Grammar.Pejoratives}>
                Vよる／Vとる Pejoratives
              </Link>
            </li>
            <li>
              <Link to={Page.FunctionalGrammar.Honorifics.Grammar.ThirdPersonAction}>
                Vやる Third person action
              </Link>
            </li>
          </ol>
          </td>
          <td style={{verticalAlign: "top"}}>
          <div style={{textAlign: "center"}}>{addImage("shokudodisplay.jpg")}<br />
          </div>
          <div style={{textAlign: "center"}}><small>食堂の料理サンプル Food display outside a local restaurant (Hyogo)</small><br />
          </div>
          </td>
        </tr>
      </tbody>
    </table>
  </>;
}

export default Grammar;
