import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";

const V5 = () => {
  const transcript = <>
    あの、パンフレットとかもらった{addRubyText("時","とき")}にな、いってらっしゃい、て<span
    style={{textDecoration: "underline"}}>{addRubyText("言","い")}わはん</span>(Grammar
    1)ねんで。うん、ほんでに、{addRubyText("入","はい")}る時とな、{addRubyText("遊","あそ")}ぶ時あるやんか。だから、いってらっしゃい、て<span
    style={{textDecoration: "underline"}}>言わはん</span>ねん。、、、{addRubyText("夜","よる")}は、{addRubyText("人","ひと")}はショー
    {addRubyText("見","み")}に<span style={{textDecoration: "underline"}}>{addRubyText("行","い")}ったはる</span>から、<span
    style={{textDecoration: "underline"}}>おらん</span>(Grammar 1 Extra
    3)ねん。んと、ご{addRubyText("飯","はん")}とか<span style={{textDecoration: "underline"}}>{addRubyText("食","た")}べはる</span>から。
  </>;

  const qaObjects = [
    {
      question: <span>Q1: When did staff at Disneyland say いっていらっしゃい to him? Choose all that apply.</span>,
      answersCorrect: [
        <>When he received brochures</>,
        <>When he entered Disneyland</>,
        <>When he was going to enjoy (the attractions)</>
      ],
      answersIncorrect: [
        <>When he was going home</>,
        <>When he ate meals</>
      ]
    },
    {
      question: <span>Q2: Why did he think there were not many people when he was about to leave Disneyland in the evening? Choose all that apply.</span>,
      answersCorrect: [
        <>He thinks it’s because they were off to see the shows.</>,
        <>He thinks it’s because they were off to dinner.</>
      ],
      answersIncorrect: [
        <>He thinks it’s because they went home already.</>
      ]
    },
    {
      question: <span>Q3: For whom did he use はる? Choose all that apply.</span>,
      answersCorrect: [
        <>Staff at Disneyland</>,
        <>People visiting Disneyland </>
      ],
      answersIncorrect: [
        <>Interviewer</>,
        <>Himself</>,
        <>His parents</>
      ]
    }
  ];

  return <>
    <h2>ビデオ{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}５: Authentic - medium<br />
    </h2>
    Watch the video clip and choose the answer that matches the content of
    the clip.
    Submit your answers after answering all the questions.<br />
    <small>*Click "SHOW" if you need to refer to the transcript</small><br />
    <br />
    Context: Takashi was asked how staff at
    Tokyo Disneyland greeted him.<br />
    <table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>{addVideoPlayer("4.4.1.p.4.takashi.mp4")}&nbsp; <br />
          </td>
          <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>
            Transcript
            <Hideable content={transcript}/><br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>タカシ (Shiga)</td>
        </tr>
      </tbody>
    </table>
    <br />
    <QandAForm qaObjects={qaObjects}/>
  </>;
}

export default V5;
