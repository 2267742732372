import React from "react";
import { addRubyText, addAudio } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";

const P3 = () => {
  const transcript = <>
    <ol>
      <li>A: これ、食べるよ。</li>
      <li>B:ちょっと、そんなの食べるな（食べないでおいて）。</li>
    </ol>
  </>;
  return <>
<h2>{addRubyText("練習","れんしゅう")}3: Negative Imperatives (gentle) - Pattern Practice<br />
</h2>
According to the model, state that you will do a certain thing its
reply (advising not to do: gentle negative imperatives) for each cue
according to a model.&nbsp; You may listen to the question, try to
answer it, and then check your answer. Answers vary:
the recorded answers indicate those commonly used.<br />
<small>*Move the mouse over to the speaker icon for sound.</small><br />
<br />
<span style={{fontWeight: "bold"}}>Model</span>（Cue:これ／{addRubyText("食","た")}べる） <br />
<br />
&nbsp;&nbsp;&nbsp; A: これ、食べるわ。<br />
&nbsp;&nbsp;&nbsp; B: ちょっと、そんなん食べんとき。{addAudio("4.3.p.3.mp3")}<br />
<br />
ST Transcript 
<br />
<Hideable content={transcript} />
<table style={{textAlign: "left", width: "100%"}} border="1"cellPadding="2" cellSpacing="2">
  <tbody>
    <tr>
      <td style={{verticalAlign: "top", textAlign: "center"}}>Cue<br />
      </td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>A (Question)<br />
      </td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>B (Answer)<br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>このワイン／{addRubyText("飲","の")}む<br />
      </td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("4.3.p.3.1.a.mp3")}<br />
      </td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("4.3.p.3.1.b.mp3")}</td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>このセーター／{addRubyText("着","き")}る</td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("4.3.p.3.2.a.mp3")}</td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("4.3.p.3.2.b.mp3")}</td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>あのコート／{addRubyText("買","か")}う</td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("4.3.p.3.3.a.mp3")}</td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("4.3.p.3.3.b.mp3")}</td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>あのタクシー／{addRubyText("乗","の")}る</td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("4.3.p.3.4.a.mp3")}</td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("4.3.p.3.4.b.mp3")}</td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>このDVD／{addRubyText("見","み")}る </td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("4.3.p.3.5.a.mp3")}</td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("4.3.p.3.5.b.mp3")}</td>
    </tr>
  </tbody>
</table>
  </>;
}

export default P3;
