import React from "react";
import { addRubyText, addAudio } from "common/util/generalUtil";

const YouNegative = () => {
  return <>
    <h2>よう+ Verb Negative Form</h2>
    <small>*Mouse over or click on the speaker icon for sound</small>
    <br />
    <br />
    When よう(よく in standard) is used with a negative form of verb, it could
    mean either one of the followings;<br />
    <br />
    <big>
      <span style={{fontWeight: "bold"}}>a) “not well” “not clear” </span>
    </big>
    <br />
    &nbsp;&nbsp;&nbsp; (when used with perceptive verbs&nbsp; such as
    {addRubyText("分","わ")}かる,{addRubyText("見","み")}える, {addRubyText("知","し")}る)
    <br />
    <br />
    テレビの{addRubyText("音","おと")}{addRubyText("小","ちい")}さいから、
    <span style={{fontWeight: "bold", textDecoration: "underline"}}>よ</span>
    <span style={{textDecoration: "underline"}}>う</span>{addRubyText("聞","き")}こえへん。{addAudio("3.1.a.4.1.mp3")}
    <br />
    <small>
      （ST テレビの音が小さいから、よく聞こえない。）{addAudio("3.1.a.4.1.s.mp3")}
    </small>
    <br />
    <br />
    <big>
      <span style={{fontWeight: "bold"}}>b) “no ability of doing…”</span>
    </big>
    <br />
    &nbsp;&nbsp;&nbsp; (when used with verbs other than perceptive verbs)
    <br />
    <br />
    {addRubyText("外国語","がいこくご")}は<span style={{fontWeight: "bold", textDecoration: "underline"}}>よう</span>{addRubyText("話","はな")}さん。
    {addAudio("3.1.a.4.2.mp3")}<br />
    <small>
      (ST 外国語は話せない／話すことができない。)&nbsp;
    </small>
    <small>
      {addAudio("3.1.a.4.2.s.mp3")}
    </small>
    <br />
    &nbsp; &nbsp;&nbsp;&nbsp;
    &nbsp;&nbsp; <br />
    あんな{addRubyText("気持","きも")}ち{addRubyText("悪","わる")}い{addRubyText("魚","さかな")}、
    <span style={{textDecoration: "underline", fontWeight: "bold"}}>よう</span>{addRubyText("食","た")}べん。{addAudio("3.1.a.4.3.mp3")}<br />
    <small>
      (ST あんなに気持ち悪い魚、とても食べられない／食べることができない。) 
    </small>
    <small>
      {addAudio("3.1.a.4.3.s.mp3")}
    </small>
    <br />
    <br />
    (Did you recognize the accentual difference of よう between a and b??)
    <br />
    <br />
    However, if one cannot do something due to the situational factor, not
    one’s personal ability or psychological factor, potential form is
    usually used.
    <br />
    <br />
    {addRubyText("車","くるま")}{addRubyText("壊","こわ")}れて、{addRubyText("仕事","しごと")}{addRubyText("行","い")}かれへん／行けへん。
    <small> 
      *not よう行かん 
    </small>
    {addAudio("3.1.a.4.4.mp3")}
    <br />
    <small>
      (ST 車が壊れて、仕事に行けない。）
    </small>
    <small>
      {addAudio("3.1.a.4.4.s.mp3")}
    </small>
    <br />
    <br />
    <hr />
    <h3>Extra 1: よう {addRubyText("言","い")}わんわ <br /></h3>
    When someone say<span style={{fontWeight: "bold", textDecoration: "underline"}}>よう</span>言わんわ, 
    s/he does not literally mean it, but rather mean “I am so
    amazed/disgusted that I cannot find even one word to say to you!” 
    <br />
    <br />
    A:{addRubyText("僕","ぼく")}、いつか{addRubyText("女","おんな")}の{addRubyText("子","こ")}になりたい。
    <br />
    B: <span style={{fontWeight: "bold", textDecoration: "underline"}}>よう</span>言わんわ！{addAudio("3.1.a.4.5.mp3")}
    <small>
      <br />
      (ST 僕、いつか女の子になりたい。ー＞あきれて{addRubyText("何","なに")}も言うこと見つからないよ！）
    </small>
    <small>
      {addAudio("3.1.a.4.5.s.mp3")}
    </small>
  </>;
}

export default YouNegative;