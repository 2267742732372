import React from "react";
import { addRubyText, addAudio } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";

const P1 = () => {
  const transcript = <>
    <ol>
      <li>A: これ、食べたくないなあ。</li>
      <li>B: 何言ってるの。{addRubyText("早","はや")}く食べなさい！</li>
    </ol>
  </>;

  return <>
    <h2>{addRubyText("練習","れんしゅう")}1: Imperatives (gentle) - Pattern Practice<br /></h2>
    According to the model, form a statement (not-wanting to do something)
    and a command (gentle) as a reply for each cue.&nbsp; You may listen to
    the question, try to answer it, and then check your answer. Answers
    vary: the recorded answers indicate those commonly used.<br />
    <small>*Move the mouse over to the speaker icon for sound.</small><br />
    <br />
    <span style={{fontWeight: "bold"}}>Model</span>（Cue:これ／{addRubyText("食","た")}べる） <br />
    <br />
    　A: これ、食べたないわ。<br />
    　B: {addRubyText("何","なに")}{addRubyText("言","ゆ")}うてんの。はよ食べ！ {addAudio("4.3.p.1.mp3")}<br />
    <br />
    ST transcript
    <br />
    <Hideable content={transcript} />
    <table style={{textAlign: "left", width: "100%"}} border="1" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>Cue<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>A (Question)<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>B (Answer)<br />
          </td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>この{addRubyText("薬","くすり")}／{addRubyText("飲","の")}む<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("4.3.p.1.1.a.mp3")}<br />
          </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("4.3.p.1.1.b.mp3")}</td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>{addRubyText("宿題","しゅくだい")}／する</td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("4.3.p.1.2.a.mp3")}</td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("4.3.p.1.2.b.mp3")}</td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>テレビ／{addRubyText("消","け")}す</td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("4.3.p.1.3.a.mp3")}</td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("4.3.p.1.3.b.mp3")}</td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>まだ／{addRubyText("寝","ね")}る</td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("4.3.p.1.4.a.mp3")}</td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("4.3.p.1.4.b.mp3")}</td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top"}}>{addRubyText("学校","がっこう")}／{addRubyText("行","い")}く </td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("4.3.p.1.5.a.mp3")}</td>
          <td style={{verticalAlign: "top", textAlign: "center"}}>{addAudio("4.3.p.1.5.b.mp3")}</td>
        </tr>
      </tbody>
    </table>
  </>;
}

export default P1;
