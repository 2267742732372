import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";

const V18 = () => {
  const transcript = <>

    ただの{addRubyText("一度","いちど")}もないですね。{addRubyText("東京","とうきょう")}が{addRubyText("僕","ぼく")}{addRubyText("嫌","きら")}いやってね、ずっと。{addRubyText("人","ひと")}が{addRubyText("多","おお")}いし、なんかね、
    僕だけやなくて、それこそ，{addRubyText("関","かん")}、さっきから{addRubyText("何回","なんかい")}も{addRubyText("言","ゆ")}ってる、{addRubyText("関西人","かんさいじん")}はやっぱ
    {addRubyText("関西国","かんさいこく")}，なんで関西を<span style={{textDecoration: "underline"}}>{addRubyText("出","で")}なあかん</span> (Grammar 3)
    ねん？みたいなね，あの、のがあるような、みんな、{addRubyText("気","き")}がするんで、なんであえて東京<span
    style={{textDecoration: "underline"}}>{addRubyText("行","い")}かなあかん</span> (Grammar 3)
    ねん？みたいのがね、あるんちゃうかなっていう。{addRubyText("特","とく")}に{addRubyText("京都","きょうと")}の，京都の人は、京都人て、あんまりそのね、{addRubyText("閉鎖的","へいさてき")}と
    いうか、京都が{addRubyText("一番","いちばん")}や{addRubyText("思","おも")}てる{addRubyText("連中","れんちゅう")}ばっかりなんで、あえてなんでそんな東京に<span
    style={{textDecoration: "underline"}}>{addRubyText("行","い")}かなあかん</span>
    (Grammar 3) ねん？て思てる連中いっぱいいるんちゃうかなあ、てゆう。だから、東京行きたい，{addRubyText("住","す")}みたいとは思ったことないですねえ。}
  </>;

  const qaObjects = [
    {
      question: <span>Q: Why has he never thought of living in Tokyo? Select all applicable answers.</span>,
      answersCorrect: [
        <>Kyoto people like him especially believe Kyoto is the best.</>,
        <>Kansai people may never think of leaving the 'Kansai Nation.' </>,
        <>There are too many people in Tokyo.</>,
        <>He has always hated Tokyo.</>
      ],
      answersIncorrect: [
        <>He doesn’t like people in Tokyo.</>,
        <>Kansai people dare not leave Kansai even if they want to.</>,
        <>Tokyo people are closed minded and wouldn’t accept Kansai people.</>,
        <>Tokyo people hate Kansai people.</>,
        <>He understands Kansai people should leave Kansai, but he does not want to do so.</>
      ]
    }
  ];

  return <>
    <h2>ビデオ{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}１８: Authentic - long<br />
    </h2>
    Watch the video clip and choose the answer that matches the content of
    the clip.<br />
    <small>*Click "SHOW" if you need to refer to the transcript.</small><br />
    <br />
    Takayama-san was asked if he had ever thought about moving to Tokyo.<br />
    <table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2"
    cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>{addVideoPlayer("4.2.3.p.3.takayama.mp4")} </td>
          <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>Transcript
    <Hideable content={transcript}/><br />
    ＊{addRubyText("関西国","かんさいこく")} ‘Kansai Nation’
    (He created this word) <br />
    ＊{addRubyText("閉鎖的","へいさてき")}exclusive, closed-minded <br />
    ＊{addRubyText("連中","れんちゅう")}people, a party, a bunch</td>
        </tr>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "left"}}>タカヤマさん
    (Kyoto)</td>
        </tr>
      </tbody>
    </table>
    <br />
    <QandAForm qaObjects={qaObjects}/>
  </>;
}

export default V18;
