import React from "react";
import { addRubyText, addVideoPlayer } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
import { QandAForm } from "common/util/QandA";
const V2 = () => {
  const transcript = <>
    ないです。{addRubyText("全然","ぜんぜん")}ないです。そらやっぱり、こっちに<span style={{textDecoration: "underline"}}>{addRubyText("長","なが")}いこと</span>(Grammar 3 Extra 1)いる、なんとなく、なんとなく、その、{addRubyText("分","わ")}からない、こういう、
    ここら{addRubyText("辺","へん")}の{addRubyText("空気","くうき")}って{addRubyText("言","い")}う、{addRubyText("関西","かんさい")}の{addRubyText("空気","くうき")}の{addRubyText("方","ほう")}が、{addRubyText("性","しょう")}に{addRubyText("合","あ")}ってるって
    いうか、<span style={{textDecoration: "underline"}}>{addRubyText("長","なが")}いこと</span>いるからかもしれません。
  </>;

  const qaObjects = [
    {
      question: <>a) {addRubyText("東京","とうきょう")}に{addRubyText("住","す")}みたいと{addRubyText("思","おも")}ったことは{addRubyText("全然","ぜんぜん")}ない。</>,
      answersCorrect: [
        <>TRUE</>
      ],
      answersIncorrect: [
        <>FALSE</>
      ]
    },
    {
      question: <>b) {addRubyText("関西","かんさい")}に{addRubyText("来","き")}てからまだそれほど{addRubyText("長","なが")}くない。</>,
      answersCorrect: [
        <>FALSE</>
      ],
      answersIncorrect: [
        <>TRUE</>
      ]
    },
    {
      question: <>c) {addRubyText("東京","とうきょう")}の{addRubyText("空気","くうき")}は{addRubyText("汚","きたな")}いから{addRubyText("嫌","きら")}いだ。</>,
      answersCorrect: [
        <>FALSE</>
      ],
      answersIncorrect: [
        <>TRUE</>
      ]
    },
    {
      question: <>d) {addRubyText("関西","かんさい")}に{addRubyText("長","なが")}い{addRubyText("間","あいだ")}{addRubyText("住","す")}んでいるから、
                {addRubyText("関西","かんさい")}の{addRubyText("空気","くうき")}が{addRubyText("性","しょう")}に{addRubyText("合","あ")}っている。</>,
      answersCorrect: [
        <>TRUE</>
      ],
      answersIncorrect: [
        <>FALSE</>
      ]
    }
  ]

  return <>
    <h2>ビデオ{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}２: Authentic - medium<br /></h2>
    Figure out if each statement is TRUE or FALSE. Submit your answers after finishing
    all the questions.<br />
    <small>
      *Click "SHOW" if you need to refer to the transcripts.
    </small>
    <br />
    <br />
    <div className="checkupquestion">
      <big>
        This person has been aksed if he has ever thought of moving to Tokyo from Kansai.
      </big>
      <br />
      <table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2" cellSpacing="2">
        <tbody>
          <tr>
            <td style={{verticalAlign: "top"}}>{addVideoPlayer("3.4.pl0.1.mp4")}</td>
            <td colSpan="1" rowSpan="2" style={{verticalAlign: "top"}}>
              Transcript
              <Hideable content={transcript} />
              <br />
              ＊ここら{addRubyText("辺","へん")}: この{addRubyText("辺","へん")}<br />
              ＊{addRubyText("空気","くうき")}
              air<br />
              ＊{addRubyText("性","しょう")}に{addRubyText("合","あ")}ってる fits my
              nature/character
            </td>
          </tr>
          <tr>
            <td style={{verticalAlign: "top"}}>クサカさん (Kyoto/Shiga)</td>
          </tr>
        </tbody>
      </table>
      <br />
      <QandAForm qaObjects={qaObjects} />
    </div>
  </>;
}

export default V2;
