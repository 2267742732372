import React from "react";
import { addRubyText } from "common/util/generalUtil";
import { DraggableForm } from "common/util/draggableForm";

const L1 = () => {
  const draggableFormData = {
    prompts: {
      images: [
        "4.1.pl0.1.a.jpg",
        "4.1.pl0.1.b.jpg",
        "4.1.pl0.1.c.jpg",
        "4.1.pl0.1.d.jpg",
        "4.1.pl0.1.e.jpg",
        "4.1.pl0.1.f.jpg",
        "4.1.pl0.1.g.jpg",
        "4.1.pl0.1.h.jpg"
      ]
    },
    answers: {
      texts: [
        <>そこ、{addRubyText("座","すわ")}らんといて。</>,
        <>{addRubyText("食","た")}べてもええよ。</>,
        <>{addRubyText("飲","の")}んだらあかん。</>,
        <>{addRubyText("着","き")}てもかまへん。</>,
        <>あ、それ{addRubyText("食","た")}べんと！</>,
        <>それ{addRubyText("着","き")}たらいかんゆーてるやろ！</>,
        <>それ？{addRubyText("飲","の")}んでもええよ。</>,
        <>{addRubyText("座","すわ")}ってもうてもよろしおすえ。</>
      ],
      audioFiles: [
        "4.1.pl0.1.1.mp3",
        "4.1.pl0.1.2.mp3",
        "4.1.pl0.1.3.mp3",
        "4.1.pl0.1.4.mp3",
        "4.1.pl0.1.5.mp3",
        "4.1.pl0.1.6.mp3",
        "4.1.pl0.1.7.mp3",
        "4.1.pl0.1.8.mp3"
      ]
    }
  }
  return <>
    <h2>{addRubyText("聞","き")}き{addRubyText("取","と")}り{addRubyText("練習","れんしゅう")}1</h2>
    Listen to the audio, and drag the audio box to the illustration that is
    most appropriate to describe the speaker’s situation. If you get the
    right answer, you will hear "Right!" and the audio will stick to the
    illustration. If it is not the right answer, the audio will bounce back
    to the original location.<br />
    * Click "SHOW" if you need to refer to the transcript.&nbsp; <br />
    <br />
    <DraggableForm prompts={draggableFormData.prompts} answers={draggableFormData.answers}/>
  </>;
}

export default L1;
