import React from "react";
import { addRubyText, addAudio } from "common/util/generalUtil";

const Permission = () => {
  return <>
<h2>2. 〜てもいい／ええ (Permission)</h2>
<h3>Summary of expressions of permission</h3>
<table style={{textAlign: "left", width: "100%"}} border="1"cellPadding="2"
 cellSpacing="2">
  <tbody>
    <tr>
      <td style={{verticalAlign: "top", textAlign: "center"}}>Standard<br />
      </td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>Kansai<br />
      </td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>Kansai
(Polite)<br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜てもいい<br />
      </td>
      <td style={{verticalAlign: "top"}}>〜てもいい／ええ<br />
      </td>
      <td style={{verticalAlign: "top"}}>〜てもいいです／ええです／よろし（い）です／よろしおます
(Osaka)／よろしおす(Kyoto)<br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>〜てもかまわない</td>
      <td style={{verticalAlign: "top"}}>〜てもかまわん／かまへん／かめへん(Osaka)</td>
      <td style={{verticalAlign: "top"}}>〜てもかまいまへん／かましまへん／かめしまへん(Osaka)</td>
    </tr>
  </tbody>
</table>
<br />
When giving permission, Kansai people basically use the same type of
structure as that of Standard Japanese; however, the latter half of the
expression tends to have a traditional Kansai feature.<br />
Vてもいい／かまわないー＞Vてもいい／ええ／かまわん／かまへん（かめへん in Osaka）<br />
<br />
{addRubyText("見","み")}てもええ、もろてもかまへん、 してもええ、{addRubyText("来","き")}てもかまわん {addAudio("4.1.2.1.mp3")}<br />
<br />
{addRubyText("子供","こども")}だけで{addRubyText("遊","あそ")}びに{addRubyText("行","い")}ってもええけど、{addRubyText("知","し")}らん{addRubyText("人","ひと")}としゃべったあかんよ。{addAudio("4.1.2.2.mp3")}<br />
<small>(ST 子供だけで遊びにいってもいいけど、知らない人としゃべっちゃだめだよ。)</small><small>{addAudio("4.1.2.2.s.mp3")}</small><br />
<br />
Noun+でもええ and Adjective て form +もええ are used just like Standard,
however, Kansai て form tends to be used for adjectives.<br />
<br />
{addRubyText("字","じ")}、{addRubyText("下手","へた")}でも、きたのうてもええんやったら、{addRubyText("宿題","しゅくだい")}{addRubyText("手伝","てつど")}うたげるで。{addAudio("4.1.2.3.mp3")}<br />
<small>(ST 字が下手でも汚くてもいいんだったら、宿題、手伝ってあげるよ。)</small><small>{addAudio("4.1.2.3.s.mp3")}</small><br />
<br />
<hr />
<h3>Extra 1: Polite Permission</h3>
Polite expression of permission is 〜てもいいです／かまいません, exactly the same as
Standard, but elderly people tend to use the traditional Kansai polite
expressions such as <br />
〜てもええです・かまいまへん<br />
〜てもよろしおます・かめしまへん (Osaka)<br />
〜てもよろしおす・かましまへん (Kyoto)<br />
<br />
ここ、{addRubyText("座","すわ")}らしてもうてもかましまへんか。(Kyoto)／かめしまへんか(Osaka) {addAudio("4.1.2.4.mp3")}<br />
<small>(ST ここに座らせてもらってもかまいませんか。)</small><small>{addAudio("4.1.2.4.s.mp3")}</small><br />
<br />
<hr />
<h3>Extra 2: Traditional form of permission</h3>
The traditional Kansai expression for 〜ても is 〜かて; therefore,&nbsp; you
might hear local people say 〜かてええ／かまへん（かめへん Osaka) such as in,<br />
<br />
十分ぐらいやったら{addRubyText("立","た")}ち{addRubyText("読","よ")}みしたかてかまへんで。（＜立ち読みしてもかまへんで）{addAudio("4.1.2.5.mp3")}<br />
<small>(ST 十分ぐらいなら立読みしてもかまわないよ。)</small><small>{addAudio("4.1.2.5.s.mp3")}</small><br />
<small><br />
* 立ち読み browse in a bookshop<br />
<br />
</small>For detailed explanation on 〜かて, refer to Ch4 Lesson 5.

  </>;
}

export default Permission;
