import React from "react";
import { addRubyText, addAudio } from "common/util/generalUtil";
import { Hideable } from "common/util/hideable";
const P3 = () => {
  const transcript = <>
    <ol>
      <li>A: 日本ではご飯の前に{addRubyText("いただきます")}と言わなきゃだめだって聞いたけど{addRubyText("本当","ほんとう")}ですか？</li>
      <li>Q: そりゃあ、言った方がいいよね。</li>
    </ol>
  </>;
  return <>
<h2>{addRubyText("練習","れんしゅう")}3: Ask about Obligation in Japan<br />
</h2>
<br />
You have heard a series of things that you are supposed to do in Japan.
Ask your Kansai acquaintance if what you heard is true. <br />
*You can listen to their reply as to whether each statement is true or
not (but no need to practice!)<br />
<small>* Click SHOW if you need to refer to the transcript.</small><br />
<small>*Move the mouse over to the speaker icon for sound</small><br />
<br />
<span style={{fontWeight: "bold"}}>Model</span>（“You must say
“Itadakimasu” before the meal in Japan.”） <br />
&nbsp;&nbsp;&nbsp; Q:
{addRubyText("日本","にほん")}ではご{addRubyText("飯","はん")}の{addRubyText("前","まえ")}に{addRubyText("いただきます")}言わなあかんて{addRubyText("聞","き")}いたけど、ほんまですか？<br />
&nbsp;&nbsp;&nbsp; A: そら、{addRubyText("言","い")}うた{addRubyText("方","ほう")}がええわな。{addAudio("4.2.p.3.mp3")}<br />
<br />
ST transcript 
<br />
<Hideable content={transcript} />
<table style={{textAlign: "left", width: "100%"}} border="1"cellPadding="2" cellSpacing="2">
  <tbody>
    <tr>
      <td style={{verticalAlign: "top", textAlign: "center"}}>What you heard<br />
      </td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>Question<br />
      </td>
      <td style={{verticalAlign: "top", textAlign: "center"}}>Reply from kansai-jin<br />
      </td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>1. “In Japan, you must take off
your shoes at the entrance hall! “<br />
      </td>
      <td style={{verticalAlign: "top", textAlign: "left"}}>{addAudio("4.2.p.3.1.a.mp3")} <Hideable content={<><small>日本では{addRubyText("玄関","げんかん")}で{addRubyText("靴","くつ")}{addRubyText("脱","ぬ")}がなあかんて聞いたけどほん
まですか？</small></>}/><br />
      </td>
      <td style={{verticalAlign: "top", textAlign: "left"}}>{addAudio("4.2.p.3.1.b.mp3")} <Hideable content={<><small>ほんま、ほんま。脱いでや。靴のまま、{addRubyText("上","あ")}がらんといてや。</small></>}/></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>2. “In Japan, cars must travel
(lit. run) on the left side of the road!”</td>
      <td style={{verticalAlign: "top", textAlign: "left"}}>{addAudio("4.2.p.3.2.a.mp3")} <Hideable content={<><small>日本では{addRubyText("車","くるま")}は{addRubyText("道","みち")}の{addRubyText("左側","ひだりがわ")}{addRubyText("走","はし")}らなあ
かんて聞いたけどほんまですか？</small></>}/></td>
      <td style={{verticalAlign: "top", textAlign: "left"}}>{addAudio("4.2.p.3.2.b.mp3")} <Hideable content={<><small>{addRubyText("当","あ")}たり{addRubyText("前","まえ")}やん。{addRubyText("右側","みぎがわ")}走ったら{addRubyText("死","し")}ぬで！</small></>}/></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>3. “In Japan, you must enter
into the hot springs naked!”</td>
      <td style={{verticalAlign: "top", textAlign: "left"}}>{addAudio("4.2.p.3.3.a.mp3")} <Hideable content={<><small>日本では{addRubyText("温泉","おんせん")}は{addRubyText("裸","はだか")}で{addRubyText("入","はい")}らなあかんて聞いたけ
どほんまですか？</small></>}/></td>
      <td style={{verticalAlign: "top", textAlign: "left"}}>{addAudio("4.2.p.3.3.b.mp3")} <Hideable content={<><small>ほんま、ほんま。{addRubyText("水着","みずぎ")}{addRubyText("着","き")}て入らんときや。</small></>}/></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>4. “In Japan, you must say
‘Gochisoo-sama” after the meal!”</td>
      <td style={{verticalAlign: "top", textAlign: "left"}}>{addAudio("4.2.p.3.4.a.mp3")} <Hideable content={<><small>日本ではご{addRubyText("飯","はん")}{addRubyText("食","た")}べたら{addRubyText("ごちそうさま")}{addRubyText("言","い")}わなあかんて
聞いたけどほんまですか？</small></>}/></td>
      <td style={{verticalAlign: "top", textAlign: "left"}}>{addAudio("4.2.p.3.4.b.mp3")} <Hideable content={<><small>うんうん，{addRubyText("言","い")}わなあかん。ほやけど、{addRubyText("関西","かんさい")}では{addRubyText("ごちそうさん")}とか{addRubyText("ごっつぉ〜さん")}とか言うねんで。</small></>}/></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>5. “In Osaka, You must say funny
things all the time!”<br />
      </td>
      <td style={{verticalAlign: "top", textAlign: "left"}}>{addAudio("4.2.p.3.5.a.mp3")} <Hideable content={<><small>{addRubyText("大阪","おおさか")}ではいっつも{addRubyText("面白","おもしろ")}いことしゃべらないかんて聞いた
けどほんまですか？</small></>}/></td>
      <td style={{verticalAlign: "top", textAlign: "left"}}>{addAudio("4.2.p.3.5.b.mp3")} <Hideable content={<><small>あかんことはないけど、ほらおもろい{addRubyText("方","ほう")}がええがな。</small></>}/></td>
    </tr>
    <tr>
      <td style={{verticalAlign: "top"}}>6. “In Kyoto, you must wear a
kimono when you visit temples!”</td>
      <td style={{verticalAlign: "top", textAlign: "left"}}>{addAudio("4.2.p.3.6.a.mp3")} <Hideable content={<><small>{addRubyText("京都","きょうと")}ではお{addRubyText("寺","てら")}{addRubyText("行","い")}く{addRubyText("時","とき")}、{addRubyText("着物","きもの")}{addRubyText("着","き")}ていかなあかんて聞いたけどほんまですか？</small></>}/></td>
      <td style={{verticalAlign: "top", textAlign: "left"}}>{addAudio("4.2.p.3.6.b.mp3")} <Hideable content={<><small>そんな、アホな。{addRubyText("着","き")}ないかんことはあらしまへんえ〜</small></>}/></td>
    </tr>
  </tbody>
</table>
  </>;
}

export default P3;
