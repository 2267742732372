import React from 'react';
import { addImage } from "common/util/generalUtil";

const ReadFirst = () =>{
  return (
    <>
      <h2>READ FIRST</h2>
      <table style={{textAlign: "left", width: "100%", fontWeight: "bold"}} border="1" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>
            Anybody who wants to learn/understand the Kansai dialect can take
            advantage of this site, but
            <u>basic knowledge of the standard Japanese grammar is a MUST!</u>
          </td>
        </tr>
        </tbody>
      </table>
      <br />

      <table style={{textAlign: "left", width:"40%"}} align="right" border="0" cellPadding="2" cellSpacing="2">
        <tbody>
        <tr>
          <td style={{verticalAlign: "top", textAlign: "center"}}>
            {addImage("kuidaoreningyo.jpg")}
          </td>
        </tr>
        <tr align="center">
          <td style={{verticalAlign: "top"}}>
            <small>食いだおれ人形 Kuidaore-doll of Restaurant "Kuidaore" (Osaka)</small>
          </td>
        </tr>
        </tbody>
      </table>

      <p>
        If you visit the area outside Tokyo as an intern, as an exchange
        student or as a researcher, or just as a tourist, you will never fail
        to be exposed to the local dialect of the region. Yes, you have to face
        the fact that not everybody speaks the Japanese you were taught at
        school! The most powerful dialect in Japan is the “Kansai
        dialect” spoken by over 20 million people in Kansai area in Western
        Japan, including the major cities such as Osaka, Kyoto and Kobe. You
        may be shocked to see the differences in pitch, intonations and even
        grammar between these two language varieties, especially if you hear
        the talk of the local elderly people. Your Kansai native friends or
        coworkers may be helpful in teaching you the Kansai dialect in person
        and you might even acquire some slang and phrases in the dialect.
        However, you might wish to have had at least learned the
        characteristics of the dialect as preparation… Now, help is on its way!
      </p>

      <p>
        This site is designed for those who are in or are going to the Kansai
        area for work, study or travel as well as for those who are simply
        interested in the cultural, linguistic diversity in Japan. The site
        will help you to gain basic knowledge of structure and intonation
        patterns of the Kansai dialect, and to build up basic aural (and a
        touch of oral) communication skills with Kansai native speakers.
        By including video clips of interviews to various Kansai local people,
        this site can also be a helpful resource to examine how this local
        dialect relates to their local identity.
      </p>

      <p>
        We hope you will enjoy utilizing this site to learn the Kansai dialect
        and understand the culture.
      </p>

      <p>
        <small>
          *If you have not studied basic Japanese grammar, please study
          standard Japanese first, as this site requires the basic knowledge of standard
          Japanese necessary to compare the differences between the standard and
          this dialect.
        </small>
      </p>
      <hr />

      <h3>日本語の先生方へ</h3>
      <p>
        <small>
          このサイトは英米圏の日本語教育施設で教鞭をお取りになっている先生で、日本語のクラスで教える標準語とは別に自分の
          地方方言や地域 文化を 授業のカリキュラムに取り入れてみたいと思われている方にも是非役に立てていただきたいとの願いを込めて、開発致しました。<br />
          関西出身の先生は学生によく「関西弁を紹介してくれ」と頼まれる事があるかと思いますが、順序だった文法書や教科書がある訳でもなく自分の『ネイティ
          ブ』の勘に頼って教材を作ったりされた方は少なくないかと思われます。（私達自身がそうでした！）また、日本の文化イコール東京の文化のような紹介の仕
          方 に違和感を覚える方もあるでしょう。そこで、学生の関西弁自主学習教材というだけでなく、日本語教育に対応した適切な関西弁紹介教材を先生方に提供す
          ると いう目的も兼ねて、このサイトの開発に着手致しました。<br />
          このサイトの第一章から第四章には関西弁の特徴及び基本文法を標準語と対照した形で取り上げ、第五章と第六章には実際に関西圏で録画した会話及びインタ
          ビューのビデオクリップを聴解及び討論用の教材として収録致しました。話し方の多様性を示すため関西圏内の様々な地域／年代の人々の会話を収録し、インタ
          ビューでは関西の人々が関西人として自分たちをどう見ているか、地域人としてのアイデンティティーが推察できるような質問を設定致しました。日本文化、日
          本語の中での多様性を紹介する意味も含めて、是非、中上級のカリキュラムの中で「地方の文化、言葉、人々」を題材に取り上げる際に参考教材として使用して
          いただければ幸いかと存じます。また、関西圏の大学に留学生を送り出している教育機関の先生方には、学生に留学準備の一つとしてこのサイトを紹介してくだ
          さることを是非お勧め致します。<br />
          尚、試行錯誤で開発したサイトであるが故、至らない所も多々あるかと思われますが、是非一度ご覧、ご試用になった上でご意見ご感想などお寄せくださると
          今後の参考になるかと存じますので，何卒よろしくお願い申し上げます。
        </small>
      </p>
    </>
  )
};

export default ReadFirst;
