import React from "react";
import { Link } from "react-router-dom";
import { Page } from "home/page";
import { addRubyText, addImage } from "common/util/generalUtil";

const FunctionalGrammar = () => {
  return <>
    <h2>Chapter 4: Functional Grammar ({addRubyText("機能別文法","きのうべつぶんぽう")}）</h2>
    <table style={{textAlign: "left", width: "100%"}} border="0" cellPadding="2" cellSpacing="2">
      <tbody>
        <tr>
          <td style={{verticalAlign: "top"}}>
          <ul>
            <li>
              <Link to={Page.FunctionalGrammar.PermissionProhibition.PermissionProhibition}>
                Lesson 1: {addRubyText("許可","きょか")}と{addRubyText("禁止","きんし")} (Permission and Prohibition)
              </Link>
            </li>
            <li>
              <Link to={Page.FunctionalGrammar.NegativePermissionObligation.NegativePermissionObligation}>
                Lesson 2:{addRubyText("不必要","ふひつよう")}と{addRubyText("義務","ぎむ")} (Negative-permission and Obligation)
              </Link>
            </li>
            <li>
              <Link to={Page.FunctionalGrammar.Imperatives.Imperatives}>
                Lesson 3: {addRubyText("命令","めいれい")} (Imperatives)
              </Link>
            </li>
            <li>
              <Link to={Page.FunctionalGrammar.Honorifics.Honorifics}>
                Lesson 4: {addRubyText("敬語","けいご")} (Honorifics and Pejoratives)
              </Link>
            </li>
            <li>
              <Link to={Page.FunctionalGrammar.Even.Even}>
                Lesson 5: 〜かて ("Even/Even if...")
              </Link>
            </li>
          </ul>
          </td>
          <td style={{verticalAlign: "top"}}>
          <div style={{textAlign: "center"}}>{addImage("osakaFestival4.jpg")}<br />
          </div>
          <div style={{textAlign: "center"}}><small>祭り Festival (Osaka)</small><br />
          </div>
          </td>
        </tr>
      </tbody>
    </table>
  </>;
}

export default FunctionalGrammar;
